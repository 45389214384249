import { ReactNode } from "react";

import { Box, styled } from "@mui/material";

import Typography from "@components/Typography";

import { colorPalette } from "@utils/theme";

interface KeywordProps {
  label: string;
  startAdornment?: ReactNode;
  isClickable?: boolean;
  isSelected?: boolean;
}

const Keyword = ({
  label,
  startAdornment,
  isClickable = false,
  isSelected = false
}: KeywordProps) => {
  const StyledKeyword = styled(Box)(({ theme }) => {
    return {
      "display": "inline-flex",
      "alignItems": "center",
      "gap": 8,
      "textAlign": "center",
      "padding": "0.375rem 1rem",
      "borderWidth": "2px",
      "borderStyle": "solid",
      "borderColor": isSelected ? colorPalette.blue.base : "transparent",
      "borderRadius": "0.125rem",
      "backgroundColor": isSelected
        ? colorPalette.blue[20]
        : colorPalette.blue["04"],
      "color": theme.palette.primary.main,
      ":hover": {
        backgroundColor: isClickable
          ? colorPalette.blue[20]
          : colorPalette.blue["04"],
        cursor: isClickable ? "pointer" : "initial"
      }
    };
  });

  return (
    <StyledKeyword>
      {startAdornment}
      <Typography variant="subtitle5">{label}</Typography>
    </StyledKeyword>
  );
};

export default Keyword;
