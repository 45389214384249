import { KeyboardEvent } from "react";

import { Control, Controller } from "react-hook-form";

import {
  Box,
  FormHelperText,
  InputLabel,
  TextareaAutosize as MuiTextareaAutosize,
  TextareaAutosizeProps as MuiTextareaAutosizeProps,
  styled
} from "@mui/material";

import { colorPalette } from "@utils/theme";

interface TextareaAutosizeProps extends MuiTextareaAutosizeProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  label?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  maxRows?: number;
  error?: "true" | "false";
  helperText?: string;
  handleKeyDown?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
}

const StyledTextareaAutoSize = styled(
  MuiTextareaAutosize
)<TextareaAutosizeProps>((props) => {
  const { theme, error, disabled } = props;
  return {
    "resize": "none",
    "boxSizing": "border-box",
    "width": "100%",
    "fontSize": "1rem",
    "minHeight": "1.6rem",
    "fontWeight": 400,
    "fontFamily": "Poppins",
    "padding": "1rem 0.875rem",
    "borderRadius": "4px",
    "color": theme.palette.text.primary,
    "border": `1px solid ${
      error === "true" ? colorPalette.red.base : colorPalette.grey.base
    }`,

    "&:hover": {
      borderColor: disabled
        ? colorPalette.black.hover[50]
        : colorPalette.black.hover.base
    },

    "&:focus": {
      borderColor: colorPalette.blue.base,
      borderWidth: "1px",
      boxShadow: `0 0 0 2px ${colorPalette.blue[20]}`
    },
    "&:focus-visible": {
      outline: "0"
    },

    "::placeholder": {
      color: colorPalette.grey.placeholder
    },

    ...(disabled
      ? {
          borderColor: colorPalette.black.hover[50]
        }
      : false)
  };
});

const TextareaAutoSize = ({
  name,
  control,
  label,
  disabled = false,
  required = false,
  placeholder = "",
  maxRows = 6,
  helperText,
  handleKeyDown
}: TextareaAutosizeProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            {label ? (
              <InputLabel
                htmlFor={name}
                required={required}
                disabled={disabled}>
                {label}
              </InputLabel>
            ) : (
              false
            )}
            <Box my={1.5} width="100%">
              <StyledTextareaAutoSize
                id={name}
                name={name}
                control={control}
                maxRows={maxRows}
                // Note: Here we need to use a boolean value as a string otherwise mui will throw the warning in the console
                error={error ? "true" : "false"}
                onChange={onChange}
                value={value}
                onKeyDown={handleKeyDown}
                placeholder={
                  !label && required ? `${placeholder}*` : placeholder
                }
                disabled={disabled}
                required={required}
              />
              {error || helperText ? (
                <FormHelperText error={!!error} sx={{ px: "0.875rem" }}>
                  {error ? error.message : helperText}
                </FormHelperText>
              ) : (
                false
              )}
            </Box>
          </>
        );
      }}
    />
  );
};

export default TextareaAutoSize;
