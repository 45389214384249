import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import { Box, Divider, Stack } from "@mui/material";
import { deleteObject, ref } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDownloadURL, useUploadFile } from "react-firebase-hooks/storage";

import SkeletonBasicInformationCard from "@skeletons/SkeletonBasicInformationCard";
import SkeletonCertificationCard from "@skeletons/SkeletonCertificationCard";
import SkeletonEducationCard from "@skeletons/SkeletonEducationCard";
import SkeletonJobDescriptionCard from "@skeletons/SkeletonJobDescriptionCard";
import SkeletonJobExperienceOverviewCard from "@skeletons/SkeletonJobExperienceOverviewCard";
import SkeletonLanguageCard from "@skeletons/SkeletonLanguageCard";
import SkeletonResumeDownloadCard from "@skeletons/SkeletonResumeDownloadCard";
import SkeletonResumeProgressCard from "@skeletons/SkeletonResumeProgressCard";
import SkeletonSkillsCard from "@skeletons/SkeletonSkillsCard";
import SkeletonUploadResumeCard from "@skeletons/SkeletonUploadResumeCard";

import BasicInformationCard from "@components/BasicInformationCard";
import Button from "@components/Button";
import CertificationCard from "@components/CertificationCard";
import EducationCard from "@components/EducationCard";
import FTUIContainer, { CustomStep } from "@components/FTUIContainer";
import JobDescriptionCard from "@components/JobDescriptionCard";
import JobExperienceOverviewCard from "@components/JobExperienceOverviewCard";
import LanguageCard from "@components/LanguageCard";
import NoJobExperienceOverviewCard from "@components/NoJobExperienceOverviewCard";
import Paper from "@components/Paper";
import ResumeDownloadCard from "@components/ResumeDownloadCard";
import ResumeProgressCard from "@components/ResumeProgressCard";
import ResumeTab from "@components/ResumeTab";
import SkillsCard from "@components/SkillsCard";
import Typography from "@components/Typography";
import UploadResumeCard from "@components/UploadResumeCard";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import UploadedFileInformation from "@interfaces/database/UploadedFileInformation";

import {
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_EN_CV,
  FILES_LOCATION_INDIVIDUAL_EN_CV,
  FTUI_TOUR_NAME,
  LOCALE,
  MAX_CERTIFICATIONS,
  MAX_EDUCATIONS,
  MAX_LANGUAGES,
  MAX_SKILLS,
  MAX_WORK_EXPERIENCES
} from "@utils/config";
import { auth, storage } from "@utils/firebase";
import getFileMetaInformation from "@utils/getFileMetaInformation";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";
import { colorPalette } from "@utils/theme";
import Timestamp from "@utils/Timestamp";
import { intl, intlEn } from "@utils/translate";

const EnglishResume = () => {
  const tourSteps: Array<CustomStep> = [
    {
      target: "#candidate-resume-tab",
      title: intl.get("t_ftui_candidate_resume_page_documents_tab_title"),
      content: intl.getHTML(
        "t_ftui_candidate_resume_page_documents_tab_description"
      ),
      disableBeacon: true
    },
    {
      target: "#candidate-resume-upload-card",
      title: intl.get("t_ftui_candidate_resume_page_upload_resume_title"),
      content: intl.get(
        "t_ftui_candidate_resume_page_upload_resume_description"
      ),
      disableBeacon: true
    },
    {
      target: "#candidate-resume-fill-in-manually-card",
      title: intl.get("t_ftui_candidate_resume_page_fill_manually_title"),
      content: intl.get(
        "t_ftui_candidate_resume_page_fill_manually_description"
      ),
      disableBeacon: true
    }
  ];

  const navigate = useNavigate();
  const { loading, value, setValue } = useUserProfile();
  const toast = useToast();
  const [user] = useAuthState(auth);
  const [uploadFile] = useUploadFile();
  const [downloadResumeUrl] = useDownloadURL(
    ref(storage, `${FILES_LOCATION_INDIVIDUAL_EN_CV}/${user?.uid}.pdf`)
  );
  const [downloadAutoGeneratedCvUrl] = useDownloadURL(
    ref(
      storage,
      `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_EN_CV}/${user?.uid}.pdf`
    )
  );
  const [isResumeUploading, setIsResumeUploading] = useState<boolean>(false);

  const progressFlags = value?.progress_flags;
  const progressSteps = value?.progress_steps;
  const enCVFileInfo = resolveMultiLingual(
    value?.cv?.file_information,
    LOCALE.EN,
    true
  );
  const basicInformation = value?.summary?.basic_information;
  const jobExperienceOverviews = value?.summary?.job_experience_overview ?? [];
  const noJobExperienceOverview = value?.summary?.no_job_experience_overview;
  const jobDetails = value?.cv?.job_experience ?? [];
  const skills = value?.summary?.skills ?? [];
  const languages = value?.summary?.languages ?? [];
  const educations = value?.cv?.education ?? [];
  const certifications = value?.cv?.certification ?? [];

  useEffect(() => {
    if (
      progressSteps?.en_cv?.current &&
      progressSteps?.en_cv?.total &&
      progressSteps?.en_cv?.current <= progressSteps?.en_cv?.total
    ) {
      toast.kampai(intl.get("t_resumes_toast_info_upload_resume"), "info");
    }
  }, [progressSteps?.en_cv?.current]);

  const updateDeleteSuccess = () => {
    toast.kampai(intl.get("t_resumes_toast_success_delete"), "success");
  };

  const updateUploadSuccess = () => {
    toast.kampai(intl.get("t_resumes_toast_success_upload"), "success");
  };

  const updateFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  //Upload Resume
  const handleResumeFileUpload = async (resumeFile: File) => {
    setIsResumeUploading(true);
    try {
      const { fileSize, fileName, fileNameWithoutExt, fileExt, fileType } =
        getFileMetaInformation(resumeFile);

      if (user?.uid) {
        const storageRef = ref(
          storage,
          `${FILES_LOCATION_INDIVIDUAL_EN_CV}/${user?.uid}.${fileExt}`
        );

        await uploadFile(storageRef, resumeFile, {
          contentType: fileType,
          contentDisposition: `attachment; filename=${fileName}`
        });

        if (setValue && value) {
          if (!value?.cv) {
            value.cv = { updated_at: Timestamp.now() };
          }

          await setValue(
            {
              ...value,
              cv: {
                ...value?.cv,
                file_information: prepareMultiLingual(
                  {
                    name: fileNameWithoutExt,
                    extension: fileExt,
                    size: fileSize,
                    uploaded_at: Timestamp.now()
                  } as UploadedFileInformation,
                  LOCALE.EN,
                  value.cv.file_information
                )
              },
              updated_at: Timestamp.now()
            },
            updateUploadSuccess,
            updateFail
          );
        }
      }
    } catch (e) {
      updateFail();
    }
    setIsResumeUploading(false);
  };

  const handleResumeDelete = async () => {
    try {
      if (user?.uid) {
        const storageRef = ref(
          storage,
          `${FILES_LOCATION_INDIVIDUAL_EN_CV}/${user?.uid}.pdf`
        );

        // TODO: Might need to consider soft delete in future
        await deleteObject(storageRef);

        if (
          setValue &&
          value &&
          resolveMultiLingual(value?.cv?.file_information, LOCALE.EN, true)
        ) {
          if (!value?.cv) {
            value.cv = { updated_at: Timestamp.now() };
          }

          delete value?.cv?.file_information?.en;

          await setValue(
            {
              ...value,
              cv: {
                ...value?.cv
              },
              updated_at: Timestamp.now()
            },
            updateDeleteSuccess,
            updateFail
          );
        }
      }
    } catch (e) {
      updateFail();
    }
  };

  //Resume Progress
  const nextProgressStep = {
    label: "",
    to: ""
  };

  switch (true) {
    case !resolveMultiLingual(
      progressFlags?.basic_information,
      LOCALE.EN,
      true
    ):
      nextProgressStep.label = "t_resumes_update_basic_info";
      nextProgressStep.to = "edit/basic-info";
      break;
    case !progressFlags?.job_experience_overview:
      nextProgressStep.label = "t_resumes_update_job_overview";
      nextProgressStep.to = "edit/job-experience-overview";
      break;
    case !progressFlags?.en_cv?.job_experience_details:
      nextProgressStep.label = "t_resumes_update_job_detail";
      nextProgressStep.to = "edit/job-experience-details";
      break;
    case !progressFlags?.skills:
      nextProgressStep.label = "t_resumes_update_skill";
      nextProgressStep.to = "edit/skills";
      break;
    case !progressFlags?.language:
      nextProgressStep.label = "t_resumes_update_language";
      nextProgressStep.to = "edit/language";
      break;
    case !progressFlags?.en_cv?.education:
      nextProgressStep.label = "t_resumes_update_education";
      nextProgressStep.to = "edit/education";
      break;
  }

  //Basic Information
  //FIXME: Removed OR (||) values once type issue is fixed
  const basicInformationCardProps = {
    fullName: {
      firstName: resolveMultiLingual(
        basicInformation?.first_name,
        LOCALE.EN,
        true
      ),
      lastName: resolveMultiLingual(
        basicInformation?.last_name,
        LOCALE.EN,
        true
      )
    },
    phoneNumber: basicInformation?.phone_number,
    email: basicInformation?.email,
    countryOfOrigin: basicInformation?.immigration_details?.country,
    visaType: basicInformation?.immigration_details?.visa_type,
    location: {
      country: basicInformation?.current_location?.country ?? "",
      city: basicInformation?.current_location?.city ?? ""
    },
    noOfCompanies: basicInformation?.number_of_companies,
    //FIXME: Update once NaN issue is resolved
    expectedSalary: basicInformation?.expected_salary,
    noJobExperienceOverview,
    handleEdit: () => navigate("edit/basic-info")
  };

  //Job Overview
  const handleJobExperienceOverviewCardDelete = async (index: number) => {
    try {
      if (setValue && value && value?.summary?.job_experience_overview) {
        value?.summary?.job_experience_overview.splice(index, 1);

        if (
          index === value?.summary?.basic_information?.primary_job_title_index
        ) {
          value.summary.basic_information.primary_job_title_index = 0;
        }

        if (value?.summary?.job_experience_overview?.length === 0) {
          delete value?.summary?.basic_information?.primary_job_title_index;
        }

        await setValue(
          {
            ...value,
            summary: {
              ...value?.summary,
              job_experience_overview: value?.summary?.job_experience_overview
            },
            updated_at: Timestamp.now()
          },
          updateDeleteSuccess,
          updateFail
        );
      }
    } catch (e) {
      updateFail();
    }
  };

  const jobExperienceOverviewCardProps = jobExperienceOverviews?.map(
    (singleJobOverview) => {
      return {
        jobTitle:
          resolveMultiLingual(
            singleJobOverview.job_title ?? "",
            LOCALE.EN,
            true
          ) ?? "",
        yearsOfExperience: singleJobOverview.years_of_experience
      };
    }
  );

  //Job Detail
  const handleJobDetailCardDelete = async (index: number) => {
    try {
      if (setValue && value && value?.cv?.job_experience) {
        value.cv.job_experience.splice(index, 1);

        await setValue(
          {
            ...value,
            cv: {
              ...value?.cv,
              job_experience: value.cv.job_experience
            },
            updated_at: Timestamp.now()
          },
          updateDeleteSuccess,
          updateFail
        );
      }
    } catch (e) {
      updateFail();
    }
  };

  const jobDetailCardProps = jobDetails?.map((singleJob) => {
    return {
      companyName: resolveMultiLingual(singleJob.company_name, LOCALE.EN),
      contractType: singleJob.contract_type,
      jobTitle: resolveMultiLingual(singleJob.job_title, LOCALE.EN),
      location: singleJob.location,
      startDate: singleJob.timeline?.start?.toDate(),
      endDate: singleJob.timeline?.end?.toDate(),
      responsibilities: resolveMultiLingual(
        singleJob?.responsibilities,
        LOCALE.EN
      )
    };
  });

  //Skills
  const skillCardProps = skills?.map((singleSkill) => {
    return {
      name: singleSkill?.name,
      yearsOfExperience: singleSkill?.years_of_experience
    };
  });

  //Language
  const handleLanguageCardDelete = async (index: number) => {
    try {
      if (setValue && value && value?.summary?.languages) {
        value?.summary?.languages.splice(index, 1);

        await setValue(
          {
            ...value,
            summary: {
              ...value?.summary,
              languages: value?.summary?.languages
            },
            updated_at: Timestamp.now()
          },
          updateDeleteSuccess,
          updateFail
        );
      }
    } catch (e) {
      updateFail();
    }
  };

  const languageCardProps = languages?.map((singleLanguage) => {
    return {
      name: singleLanguage?.name,
      proficiency: singleLanguage?.proficiency,
      testName: singleLanguage?.test_name,
      testResult: singleLanguage?.test_result
    };
  });

  //Education
  const handleEducationCardDelete = async (index: number) => {
    try {
      if (setValue && value && value?.cv?.education) {
        value.cv.education.splice(index, 1);

        await setValue(
          {
            ...value,
            cv: {
              ...value?.cv,
              education: value.cv.education
            },
            updated_at: Timestamp.now()
          },
          updateDeleteSuccess,
          updateFail
        );
      }
    } catch (e) {
      updateFail();
    }
  };

  const educationCardProps = educations?.map((singleEducation) => {
    return {
      instituteName: resolveMultiLingual(
        singleEducation.institute_name,
        LOCALE.EN
      ),
      degreeType: singleEducation.degree_type,
      fieldOfStudy: resolveMultiLingual(
        singleEducation?.field_of_study,
        LOCALE.EN
      ),
      startDate: singleEducation.timeline?.start?.toDate(),
      endDate: singleEducation.timeline?.end?.toDate()
    };
  });

  //Certification
  const handleCertificationCardDelete = async (index: number) => {
    try {
      if (setValue && value && value?.cv?.certification) {
        value.cv.certification.splice(index, 1);

        await setValue(
          {
            ...value,
            cv: {
              ...value?.cv,
              certification: value.cv.certification
            },
            updated_at: Timestamp.now()
          },
          updateDeleteSuccess,
          updateFail
        );
      }
    } catch (e) {
      updateFail();
    }
  };

  const certificationCardProps = certifications?.map((singleCertification) => {
    return {
      title:
        resolveMultiLingual(singleCertification?.certificate_name, LOCALE.EN) ??
        "",
      issuingOrganization:
        resolveMultiLingual(singleCertification?.issuer_name, LOCALE.EN) ?? "",
      description: resolveMultiLingual(
        singleCertification?.description,
        LOCALE.EN
      ),
      certificationUrl: resolveMultiLingual(
        singleCertification?.certificate_url,
        LOCALE.EN
      ),
      issueDate: singleCertification?.timeline?.issue?.toDate(),
      expiryDate:
        singleCertification.timeline.expiry &&
        singleCertification?.timeline?.expiry.toDate()
    };
  });
  return (
    <>
      <Paper sx={{ mb: 3 }}>
        <ResumeTab />
        <br />
        <br />
        <Typography variant="h3" mb={2.5}>
          {intl.get("t_resumes_upload_resume_title")}
        </Typography>
        <br />
        <Typography variant="subtitle3" mb={3}>
          {intl.get("t_resumes_upload_resume_subtitle")}
        </Typography>
        {loading ? (
          <SkeletonUploadResumeCard />
        ) : (
          <Box id="candidate-resume-upload-card">
            <UploadResumeCard
              resumeDetails={enCVFileInfo}
              downloadResumeUrl={downloadResumeUrl}
              handleFileUpload={handleResumeFileUpload}
              handleDelete={handleResumeDelete}
              isLoading={isResumeUploading}
            />
          </Box>
        )}
      </Paper>

      <Box my={3.5} color={colorPalette.grey.base}>
        <Divider>{intl.get("t_general_or")}</Divider>
      </Box>

      <Paper>
        <Stack display="inline-block" spacing={3} width="100%">
          {progressSteps?.en_cv?.current &&
          progressSteps?.en_cv?.total &&
          progressSteps.en_cv.current > progressSteps.en_cv.total &&
          downloadAutoGeneratedCvUrl ? (
            <Typography variant="h3">
              {intl.get("t_general_english_cv")}
            </Typography>
          ) : (
            <Typography variant="h3">
              {intl.get("t_resumes_manual_title")}
            </Typography>
          )}

          <br />
          {loading ? (
            <SkeletonResumeProgressCard />
          ) : (
            <Box id="candidate-resume-fill-in-manually-card">
              <ResumeProgressCard
                totalProgressStep={progressSteps?.en_cv?.total}
                currentProgressStep={progressSteps?.en_cv?.current}
                nextProgressStep={nextProgressStep}
                isResumeUploaded={enCVFileInfo ? true : false}
              />
            </Box>
          )}
          {loading ? (
            <SkeletonResumeDownloadCard />
          ) : (
            <>
              {progressSteps?.en_cv?.current &&
              progressSteps?.en_cv?.total &&
              progressSteps.en_cv.current > progressSteps.en_cv.total &&
              downloadAutoGeneratedCvUrl ? (
                <>
                  <ResumeDownloadCard
                    downloadCvUrl={downloadAutoGeneratedCvUrl}
                  />
                </>
              ) : (
                false
              )}
            </>
          )}

          <Typography
            variant="h4"
            showBadge={
              !resolveMultiLingual(
                progressFlags?.basic_information,
                LOCALE.EN,
                true
              )
            }
            width="max-content">
            {intl.get("t_resumes_basic_info_title")}
          </Typography>
          {loading ? (
            <SkeletonBasicInformationCard mode="edit" />
          ) : (
            <BasicInformationCard
              intl={intlEn}
              mode="edit"
              {...basicInformationCardProps}
            />
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography
              variant="h4"
              showBadge={!progressFlags?.job_experience_overview}>
              {intl.get("t_resumes_job_overview_title")}
            </Typography>
            <Button
              data-testid="en_resume_job_overview_add_button"
              variant="text"
              size="small"
              startAdornment={<AddCircleIcon />}
              handleClick={() =>
                jobExperienceOverviews?.length >= MAX_WORK_EXPERIENCES
                  ? toast.kampai(
                      intl.get("t_toast_error_limit_exceed", {
                        fieldName: intl.get("t_resumes_job_overview_title"),
                        maxLimit: MAX_WORK_EXPERIENCES
                      }),
                      "error"
                    )
                  : navigate("edit/job-experience-overview")
              }>
              {intl.get("t_resumes_add_job_overview")}
            </Button>
          </Stack>
          {loading ? (
            <SkeletonJobExperienceOverviewCard />
          ) : (
            <>
              {jobExperienceOverviewCardProps.length === 0 &&
              noJobExperienceOverview !== undefined ? (
                <NoJobExperienceOverviewCard
                  mode="edit"
                  handleEdit={() => navigate("edit/job-experience-overview")}
                />
              ) : (
                <>
                  {jobExperienceOverviewCardProps?.map(
                    (singleJobOverview, index) => (
                      <Stack spacing={2} key={index}>
                        <JobExperienceOverviewCard
                          mode="edit"
                          intl={intlEn}
                          {...singleJobOverview}
                          handleEdit={() =>
                            navigate(`edit/job-experience-overview/${index}`)
                          }
                          handleDelete={() =>
                            handleJobExperienceOverviewCardDelete(index)
                          }
                        />
                      </Stack>
                    )
                  )}
                </>
              )}
            </>
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography
              variant="h4"
              showBadge={!progressFlags?.en_cv?.job_experience_details}>
              {intl.get("t_resumes_job_detail_title")}
            </Typography>
            <Button
              data-testid="en_resume_job_detail_add_button"
              variant="text"
              size="small"
              startAdornment={<AddCircleIcon />}
              handleClick={() =>
                jobDetails?.length >= MAX_WORK_EXPERIENCES
                  ? toast.kampai(
                      intl.get("t_toast_error_limit_exceed", {
                        fieldName: intl.get("t_resumes_job_detail_title"),
                        maxLimit: MAX_WORK_EXPERIENCES
                      }),
                      "error"
                    )
                  : navigate("edit/job-experience-details")
              }>
              {intl.get("t_resumes_add_job_detail")}
            </Button>
          </Stack>
          {loading ? (
            <SkeletonJobDescriptionCard mode="edit" />
          ) : jobDetailCardProps?.length ?? 0 ? (
            <Stack spacing={2}>
              {jobDetailCardProps?.map((singleJobDetail, index) => (
                <JobDescriptionCard
                  intl={intlEn}
                  key={index}
                  mode="edit"
                  {...singleJobDetail}
                  handleEdit={() =>
                    navigate(`edit/job-experience-details/${index}`)
                  }
                  handleDelete={() => handleJobDetailCardDelete(index)}
                />
              ))}
            </Stack>
          ) : (
            false
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="h4" showBadge={!progressFlags?.skills}>
              {intl.get("t_resumes_skills_title")}
            </Typography>
            <Button
              data-testid="en_resume_skill_add_button"
              variant="text"
              size="small"
              startAdornment={<AddCircleIcon />}
              handleClick={() =>
                skills?.length >= MAX_SKILLS
                  ? toast.kampai(
                      intl.get("t_toast_error_limit_exceed", {
                        fieldName: intl.get("t_resumes_skills_title"),
                        maxLimit: MAX_SKILLS
                      }),
                      "error"
                    )
                  : navigate("edit/skills")
              }>
              {intl.get("t_resumes_add_skill")}
            </Button>
          </Stack>
          {loading ? (
            <SkeletonSkillsCard mode="edit" />
          ) : (
            <SkillsCard
              intl={intlEn}
              mode="edit"
              skills={skillCardProps}
              handleEdit={() => navigate("edit/skills")}
            />
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="h4" showBadge={!progressFlags?.language}>
              {intl.get("t_resumes_language_title")}
            </Typography>
            <Button
              data-testid="en_resume_language_add_button"
              variant="text"
              size="small"
              startAdornment={<AddCircleIcon />}
              handleClick={() =>
                languages?.length >= MAX_LANGUAGES
                  ? toast.kampai(
                      intl.get("t_toast_error_limit_exceed", {
                        fieldName: intl.get("t_resumes_language_title"),
                        maxLimit: MAX_LANGUAGES
                      }),
                      "error"
                    )
                  : navigate("edit/language")
              }>
              {intl.get("t_resumes_add_language")}
            </Button>
          </Stack>
          {loading ? (
            <SkeletonLanguageCard />
          ) : languageCardProps?.length ?? 0 ? (
            <Stack spacing={2}>
              {languageCardProps?.map((singleLanguage, index) => (
                <LanguageCard
                  intl={intlEn}
                  key={index}
                  mode="edit"
                  handleEdit={() => navigate(`edit/language/${index}`)}
                  {...singleLanguage}
                  handleDelete={() => handleLanguageCardDelete(index)}
                />
              ))}
            </Stack>
          ) : (
            false
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography
              variant="h4"
              showBadge={!progressFlags?.en_cv?.education}>
              {intl.get("t_resumes_education_title")}
            </Typography>
            <Button
              data-testid="en_resume_education_add_button"
              variant="text"
              size="small"
              startAdornment={<AddCircleIcon />}
              handleClick={() =>
                educations?.length >= MAX_EDUCATIONS
                  ? toast.kampai(
                      intl.get("t_toast_error_limit_exceed", {
                        fieldName: intl.get("t_resumes_education_title"),
                        maxLimit: MAX_EDUCATIONS
                      }),
                      "error"
                    )
                  : navigate("edit/education")
              }>
              {intl.get("t_resumes_add_education")}
            </Button>
          </Stack>
          {loading ? (
            <SkeletonEducationCard mode="edit" />
          ) : educationCardProps?.length ?? 0 ? (
            <Stack spacing={2}>
              {educationCardProps?.map((singleEducation, index) => (
                <EducationCard
                  intl={intlEn}
                  key={index}
                  mode="edit"
                  {...singleEducation}
                  handleEdit={() => navigate(`edit/education/${index}`)}
                  handleDelete={() => handleEducationCardDelete(index)}
                />
              ))}
            </Stack>
          ) : (
            false
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="h4" mr={2}>
              {intl.get("t_resumes_certification_title")}
            </Typography>
            <Button
              data-testid="en_resume_certification_add_button"
              variant="text"
              size="small"
              startAdornment={<AddCircleIcon />}
              handleClick={() =>
                certifications?.length >= MAX_CERTIFICATIONS
                  ? toast.kampai(
                      intl.get("t_toast_error_limit_exceed", {
                        fieldName: intl.get("t_resumes_certification_title"),
                        maxLimit: MAX_CERTIFICATIONS
                      }),
                      "error"
                    )
                  : navigate("edit/certification")
              }>
              {intl.get("t_resumes_add_certification")}
            </Button>
          </Stack>
          {loading ? (
            <SkeletonCertificationCard mode="edit" />
          ) : certificationCardProps?.length ?? 0 ? (
            <Stack spacing={2}>
              {certificationCardProps?.map((singleCertification, index) => (
                <CertificationCard
                  intl={intlEn}
                  key={index}
                  mode="edit"
                  {...singleCertification}
                  handleEdit={() => navigate(`edit/certification/${index}`)}
                  handleDelete={() => handleCertificationCardDelete(index)}
                />
              ))}
            </Stack>
          ) : (
            false
          )}
        </Stack>
      </Paper>
      {/* FTUI */}
      <FTUIContainer
        tourSteps={tourSteps}
        tourName={FTUI_TOUR_NAME.CANDIDATE_RESUME_PAGE}
      />
    </>
  );
};

export default EnglishResume;
