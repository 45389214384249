import { Box } from "@mui/material";

import SkeletonAvatar from "@skeletons/SkeletonAvatar";
import SkeletonInput from "@skeletons/SkeletonInput";

import Typography from "@components/Typography";

import { intl } from "@utils/translate";

const SkeletonCompanyInformationForm = () => {
  return (
    <>
      <SkeletonInput />
      <Typography
        variant="subtitle4"
        mb={{ xs: 0.5, lg: 1.5 }}
        mt={{ xs: 2, lg: 1 }}>
        {intl.get("t_job_post_company_information_step_company_logo_label")}
      </Typography>
      <br />
      <Typography variant="body2" color="text.secondary" mb={1.5}>
        {intl.get(
          "t_job_post_company_information_step_company_logo_format_label"
        )}
      </Typography>
      <Box mb={2}>
        <SkeletonAvatar variant="rounded" size="large" />
      </Box>
      <SkeletonInput />
      <SkeletonInput />
    </>
  );
};

export default SkeletonCompanyInformationForm;
