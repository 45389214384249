import { useEffect, useState } from "react";

import { Box, DialogContent, Grid, Link, Stack } from "@mui/material";
import { ref } from "firebase/storage";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useDownloadURL } from "react-firebase-hooks/storage";

import SkeletonCVDrawerTab from "@skeletons/SkeletonCVDrawerTab";

import Avatar from "@components/Avatar";
import BasicInformationCard from "@components/BasicInformationCard";
import Button from "@components/Button";
import CertificationCard from "@components/CertificationCard";
import Dialog from "@components/Dialog";
import EducationCard from "@components/EducationCard";
import Icon from "@components/Icon";
import JobDescriptionCard from "@components/JobDescriptionCard";
import JobExperienceOverviewCard from "@components/JobExperienceOverviewCard";
import LanguageCard from "@components/LanguageCard";
import PdfViewerModal from "@components/PdfViewerModal";
import PdfViewerThumbnail from "@components/PdfViewerThumbnail";
import SkillsCard from "@components/SkillsCard";
import Typography from "@components/Typography";

import useToast from "@hooks/useToast";

import CompanyID from "@interfaces/database/CompanyID";
import UserID from "@interfaces/database/UserID";
import UserProfile from "@interfaces/database/UserProfile";
import CandidateDetails from "@interfaces/functions/CandidateDetails";

import {
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_EN_CV,
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_CV,
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_RIREKISHO,
  FILES_LOCATION_INDIVIDUAL_EN_CV,
  FILES_LOCATION_INDIVIDUAL_JA_CV,
  FILES_LOCATION_INDIVIDUAL_JA_RIREKISHO,
  LOCALE
} from "@utils/config";
import { functions, storage } from "@utils/firebase";
import { getFullLocation } from "@utils/location";
import { resolveMultiLingual } from "@utils/multiLingual";
import theme from "@utils/theme";
import { intl } from "@utils/translate";

interface ResumeTabProps {
  companyId: CompanyID;
  userId: UserID;
  user: UserProfile | null;
}

interface PdfThumbnailWithModalProps {
  pdfURL: string;
  pdfName?: string;
}

const PdfThumbnailWithModal = ({
  pdfURL,
  pdfName
}: PdfThumbnailWithModalProps) => {
  return (
    <Stack alignItems="center" gap={1}>
      <Dialog
        showCloseBtn={false}
        initiator={<PdfViewerThumbnail pdfURL={pdfURL} />}
        maxWidth="lg"
        height="80vh">
        {(_, handleCancel) => (
          <DialogContent sx={{ height: "calc(100% - 40px)" }}>
            <PdfViewerModal
              pdfURL={pdfURL}
              pdfName={pdfName}
              handleClose={handleCancel}
            />
          </DialogContent>
        )}
      </Dialog>
      <Link underline="none" href={pdfURL}>
        <Button variant="text" startAdornment={<Icon type="download" />}>
          {intl.get("t_general_download")}
        </Button>
      </Link>
    </Stack>
  );
};

const ResumeTab = ({
  companyId,
  userId: candidateId,
  user
}: ResumeTabProps) => {
  const toast = useToast();
  //TODO: During loading time email and phone number is set as "-" in UI. Handle that.
  const [getCandidateDetails] = useHttpsCallable(
    functions,
    "getCandidateDetails"
  );
  // FIXME: Fix by Aayush
  const [candidateDetails, setCandidateDetails] = useState<
    CandidateDetails | any // eslint-disable-line @typescript-eslint/no-explicit-any
  >({});

  const profilePicUrl = candidateDetails?.photo_url ?? "";
  const basicInformation = user?.summary?.basic_information;
  const noJobExperience = user?.summary?.no_job_experience_overview;
  const jobExperienceOverviews = user?.summary?.job_experience_overview ?? [];
  const jobTitleId =
    user?.summary?.basic_information?.primary_job_title_index ?? 0; //FIXME: Recheck index 0 logic
  const jobDetails = user?.cv?.job_experience;
  const skills = user?.summary?.skills ?? [];
  const languages = user?.summary?.languages ?? [];
  const educations = user?.cv?.education ?? [];
  const certifications = user?.cv?.certification ?? [];

  // Download URLs and File Information for English and Japanese CVs and Rirekisho

  const enCVFileInfo = resolveMultiLingual(
    user?.cv?.file_information,
    LOCALE.EN,
    true
  );
  const jaCVFileInfo = resolveMultiLingual(
    user?.cv?.file_information,
    LOCALE.JA,
    true
  );
  const jaRirekishoFileInfo = user?.ja_rirekisho?.file_information;

  const [downloadEnCvUrl] = useDownloadURL(
    ref(
      storage,
      `${FILES_LOCATION_INDIVIDUAL_EN_CV}/${candidateId}.${enCVFileInfo?.extension}`
    )
  );
  const [downloadJaCvUrl] = useDownloadURL(
    ref(
      storage,
      `${FILES_LOCATION_INDIVIDUAL_JA_CV}/${candidateId}.${jaCVFileInfo?.extension}`
    )
  );
  const [downloadJaRirekishoUrl] = useDownloadURL(
    ref(
      storage,
      `${FILES_LOCATION_INDIVIDUAL_JA_RIREKISHO}/${candidateId}.${jaRirekishoFileInfo?.extension}`
    )
  );
  const [downloadAutoGeneratedEnCvUrl] = useDownloadURL(
    ref(
      storage,
      `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_EN_CV}/${candidateId}.pdf`
    )
  );
  const [downloadAutoGeneratedJaCvUrl] = useDownloadURL(
    ref(
      storage,
      `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_CV}/${candidateId}.pdf`
    )
  );
  const [downloadAutoGeneratedJaRirekishoUrl] = useDownloadURL(
    ref(
      storage,
      `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_RIREKISHO}/${candidateId}.pdf`
    )
  );

  useEffect(() => {
    (async () => {
      try {
        if (companyId || candidateId) {
          const result = await getCandidateDetails({
            companyId,
            candidateIds: [candidateId]
          });
          const candidateDetails = result?.data as Record<
            string,
            CandidateDetails
          >;
          const singleCandidateDetail = candidateDetails[candidateId];
          setCandidateDetails(singleCandidateDetail as CandidateDetails);
        }
      } catch (error) {
        toast.kampai(intl.get("t_toast_fetch_candidate_data_error"), "error");
      }
    })();
  }, [companyId, candidateId]);

  const firstName = resolveMultiLingual(basicInformation?.first_name) ?? "";
  const lastName = resolveMultiLingual(basicInformation?.last_name) ?? "";
  const fullName = `${lastName} ${firstName}`.trim();

  const location = getFullLocation(
    basicInformation?.current_location?.city,
    basicInformation?.current_location?.country,
    true
  );

  // Basic Information
  const basicInformationCardProps = {
    phoneNumber: candidateDetails?.phone_number ?? "",
    email: candidateDetails?.email ?? "",
    countryOfOrigin: basicInformation?.immigration_details?.country,
    visaType: basicInformation?.immigration_details?.visa_type,
    noOfCompanies: basicInformation?.number_of_companies,
    // FIXME: Update once NaN issue is resolved
    expectedSalary: basicInformation?.expected_salary
  };

  // Job Overview
  const jobExperienceOverviewCardProps = !noJobExperience
    ? jobExperienceOverviews?.map((singleJobOverview) => {
        return {
          jobTitle: resolveMultiLingual(singleJobOverview.job_title) ?? "",
          yearsOfExperience: singleJobOverview.years_of_experience
        };
      })
    : [];

  // Job Detail
  const jobDetailCardProps = !noJobExperience
    ? jobDetails?.map((singleJob) => {
        return {
          companyName: resolveMultiLingual(singleJob?.company_name) ?? "",
          contractType: singleJob?.contract_type,
          jobTitle: resolveMultiLingual(singleJob?.job_title) ?? "",
          location: singleJob?.location,
          startDate: singleJob?.timeline?.start?.toDate(),
          endDate: singleJob.timeline.end && singleJob?.timeline?.end?.toDate(),
          responsibilities:
            resolveMultiLingual(singleJob?.responsibilities) ?? ""
        };
      })
    : [];

  // Skills
  const skillCardProps = skills?.map((singleSkill) => {
    return {
      name: singleSkill?.name,
      yearsOfExperience: singleSkill?.years_of_experience
    };
  });

  // Language
  const languageCardProps = languages?.map((singleLanguage) => {
    return {
      name: singleLanguage?.name,
      proficiency: singleLanguage?.proficiency,
      testName: singleLanguage?.test_name,
      testResult: singleLanguage?.test_result
    };
  });

  // Education
  const educationCardProps = educations?.map((singleEducation) => {
    return {
      instituteName: resolveMultiLingual(singleEducation?.institute_name) ?? "",
      degreeType: singleEducation?.degree_type,
      fieldOfStudy: resolveMultiLingual(singleEducation?.field_of_study) ?? "",
      startDate: singleEducation?.timeline.start?.toDate(),
      endDate:
        singleEducation.timeline.end && singleEducation?.timeline?.end.toDate()
    };
  });

  // Certification
  const certificationCardProps = certifications?.map((singleCertification) => {
    return {
      title: resolveMultiLingual(singleCertification?.certificate_name) ?? "",
      issuingOrganization:
        resolveMultiLingual(singleCertification?.issuer_name) ?? "",
      description: resolveMultiLingual(singleCertification?.description) ?? "",
      certificationUrl:
        resolveMultiLingual(singleCertification?.certificate_url) ?? "",
      issueDate: singleCertification?.timeline?.issue?.toDate(),
      expiryDate:
        singleCertification.timeline.expiry &&
        singleCertification?.timeline?.expiry.toDate()
    };
  });

  return (
    <Box mb={2}>
      {!user ? (
        <SkeletonCVDrawerTab />
      ) : (
        <>
          <Stack
            direction={{ xs: "column-reverse", md: "row" }}
            justifyContent="space-between"
            spacing={{ xs: 1, md: "unset" }}>
            {/* User details */}
            <Stack direction="row" alignItems="center" gap={1}>
              <Avatar imgSrc={profilePicUrl} fullName={fullName} />
              <Stack justifyContent="center">
                <Typography variant="subtitle4">{fullName ?? "-"}</Typography>
                <Typography variant="subtitle2">
                  {noJobExperience
                    ? intl.get("t_general_fresher")
                    : jobExperienceOverviewCardProps?.[jobTitleId]?.jobTitle ??
                      "-"}
                </Typography>
                <Typography
                  variant="body1"
                  color={theme.palette.text.secondary}
                  textTransform="capitalize">
                  {location}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack gap={2} my={2}>
            {/* Basic Information */}
            <BasicInformationCard {...basicInformationCardProps} />
            {/* Job Overview */}
            <Typography variant="h4">
              {intl.get("t_resumes_job_overview_title")}
            </Typography>
            {noJobExperience ? (
              <Typography variant="body1">
                {intl.get("t_general_fresher")}
              </Typography>
            ) : (
              <Grid container gap={1}>
                {jobExperienceOverviewCardProps?.map(
                  (singleJobOverview, index) => (
                    <Grid item xs={12} md={2.8} key={index}>
                      <JobExperienceOverviewCard {...singleJobOverview} />
                    </Grid>
                  )
                )}
              </Grid>
            )}
            {!noJobExperience ? (
              <>
                {/* Job Details */}
                <Typography variant="h4">
                  {intl.get("t_resumes_job_detail_title")}
                </Typography>
                <Stack spacing={2}>
                  {jobDetailCardProps?.map((singleJobDetail, index) => (
                    <JobDescriptionCard key={index} {...singleJobDetail} />
                  ))}
                </Stack>
              </>
            ) : (
              false
            )}
            {/* Skills */}
            {skillCardProps.length > 0 ? (
              <>
                <Typography variant="h4">
                  {intl.get("t_resumes_skills_title")}
                </Typography>
                <SkillsCard skills={skillCardProps} />
              </>
            ) : (
              false
            )}
            {/* Language */}
            {languageCardProps.length > 0 ? (
              <>
                <Typography variant="h4">
                  {intl.get("t_resumes_language_title")}
                </Typography>
                <Grid container gap={1}>
                  {languageCardProps?.map((singleLanguage, index) => (
                    <Grid item xs={12} md={2.8} key={index}>
                      <LanguageCard {...singleLanguage} />
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              false
            )}
            {/* Education */}
            {educationCardProps.length > 0 ? (
              <>
                <Typography variant="h4">
                  {intl.get("t_resumes_education_title")}
                </Typography>
                <Stack spacing={2}>
                  {educationCardProps?.map((singleEducation, index) => (
                    <EducationCard key={index} {...singleEducation} />
                  ))}
                </Stack>
              </>
            ) : (
              false
            )}
            {/* Certifications */}
            {certificationCardProps.length > 0 ? (
              <>
                <Typography variant="h4">
                  {intl.get("t_resumes_certification_title")}
                </Typography>
                <Stack spacing={2}>
                  {certificationCardProps?.map((singleCertification, index) => (
                    <CertificationCard key={index} {...singleCertification} />
                  ))}
                </Stack>
              </>
            ) : (
              false
            )}
            {/* Documents */}
            <Typography variant="h4">
              {intl.get("t_general_english_cv")}
            </Typography>
            {downloadEnCvUrl || downloadAutoGeneratedEnCvUrl ? (
              <Stack
                direction={{ xs: "column", md: "row" }}
                alignItems="flex-start"
                my={1}
                gap={3}
                width="100%">
                {downloadAutoGeneratedEnCvUrl ? (
                  <PdfThumbnailWithModal
                    pdfURL={downloadAutoGeneratedEnCvUrl}
                    pdfName="EnglishCV.pdf" // FIXME: Update name once it is available for auto generated resume
                  />
                ) : (
                  false
                )}

                {downloadEnCvUrl ? (
                  <PdfThumbnailWithModal
                    pdfURL={downloadEnCvUrl}
                    pdfName={`${enCVFileInfo?.name}.${enCVFileInfo?.extension}`}
                  />
                ) : (
                  false
                )}
              </Stack>
            ) : (
              <Typography variant="subtitle3" color="text.secondary">
                {intl.get("t_resumes_not_uploaded", {
                  resume: intl.get("t_general_english_cv")
                })}
              </Typography>
            )}

            <Typography variant="h4">
              {intl.get("t_general_japanese_cv")}
            </Typography>
            {downloadJaCvUrl || downloadAutoGeneratedJaCvUrl ? (
              <Stack
                direction={{ xs: "column", md: "row" }}
                alignItems="flex-start"
                my={1}
                gap={3}
                width="100%">
                {downloadAutoGeneratedJaCvUrl ? (
                  <PdfThumbnailWithModal
                    pdfURL={downloadAutoGeneratedJaCvUrl}
                    pdfName="JapaneseCV.pdf" // FIXME: Update name once it is available for auto generated resume
                  />
                ) : (
                  false
                )}

                {downloadJaCvUrl ? (
                  <PdfThumbnailWithModal
                    pdfURL={downloadJaCvUrl}
                    pdfName={`${jaCVFileInfo?.name}.${jaCVFileInfo?.extension}`}
                  />
                ) : (
                  false
                )}
              </Stack>
            ) : (
              <Typography variant="subtitle3" color="text.secondary">
                {intl.get("t_resumes_not_uploaded", {
                  resume: intl.get("t_general_japanese_cv")
                })}
              </Typography>
            )}

            <Typography variant="h4">
              {intl.get("t_rirekisho_title")}
            </Typography>
            {downloadJaRirekishoUrl || downloadAutoGeneratedJaRirekishoUrl ? (
              <Stack
                direction={{ xs: "column", md: "row" }}
                alignItems="flex-start"
                my={1}
                gap={3}
                width="100%">
                {downloadAutoGeneratedJaRirekishoUrl ? (
                  <PdfThumbnailWithModal
                    pdfURL={downloadAutoGeneratedJaRirekishoUrl}
                    pdfName="Rirekisho.pdf" // FIXME: Update name once it is available for auto generated resume
                  />
                ) : (
                  false
                )}

                {downloadJaRirekishoUrl ? (
                  <PdfThumbnailWithModal
                    pdfURL={downloadJaRirekishoUrl}
                    pdfName={`${jaRirekishoFileInfo?.name}.${jaRirekishoFileInfo?.extension}`}
                  />
                ) : (
                  false
                )}
              </Stack>
            ) : (
              <Typography variant="subtitle3" color="text.secondary">
                {intl.get("t_resumes_not_uploaded", {
                  resume: intl.get("t_rirekisho_title")
                })}
              </Typography>
            )}
          </Stack>
        </>
      )}
    </Box>
  );
};

export default ResumeTab;
