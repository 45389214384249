import { ReactNode } from "react";

import { Paper as MuiPaper, styled, SxProps } from "@mui/material";

interface PaperProps {
  children: ReactNode;
  elevation?: number;
  sx?: SxProps;
}

const StyledPaper = styled(MuiPaper)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: "1.25rem"
  },
  [theme.breakpoints.up("sm")]: {
    padding: "1.5rem"
  },
  [theme.breakpoints.up("md")]: {
    padding: "3.75rem"
  }
}));

const Paper = ({ children, elevation = 0, sx }: PaperProps) => {
  return (
    <StyledPaper elevation={elevation} sx={sx}>
      {children}
    </StyledPaper>
  );
};

export default Paper;
