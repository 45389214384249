import { Box, Stack } from "@mui/material";

import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonProgressBar from "@skeletons/SkeletonProgressBar";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Well from "@components/Well";

const SkeletonResumeProgressCard = () => {
  return (
    <Well>
      <Box width="100%">
        <SkeletonTypography
          variant="subtitle5"
          width="short"
          mb={1}
          ml="auto"
        />

        <SkeletonTypography variant="h4" width="medium" mb={2.5} />
        <SkeletonTypography variant="body1" width="medium" mb={1} />
        <SkeletonTypography variant="body2" width="medium" mb={4} />
        <SkeletonButton />

        <Stack direction="row" justifyContent="flex-end" spacing={1} mt={2}>
          <SkeletonProgressBar />
          <SkeletonTypography variant="body1" width="short" />
        </Stack>
      </Box>
    </Well>
  );
};

export default SkeletonResumeProgressCard;
