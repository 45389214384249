import { MouseEvent, ReactNode, useState } from "react";

import {
  Campaign as CampaignIcon,
  FiberManualRecord as FiberManualRecordIcon,
  Info as InfoIcon,
  MoreHoriz as MoreHorizIcon,
  Work as WorkIcon
} from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import Typography from "@components/Typography";

import { NOTIFICATION_TYPE, NOTIFICATION_TYPE_T_LABELS } from "@utils/config";
import { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

dayjs.extend(relativeTime);

interface SingleOption {
  label: string;
  onClick: (notificationId: string) => void;
}

interface NotificationBlockProps {
  notificationId: string;
  description: string;
  timestamp: Date;
  notificationType: typeof NOTIFICATION_TYPE[keyof typeof NOTIFICATION_TYPE];
  title: string;
  icon?: ReactNode;
  iconBgColor?: string;
  optionList: Array<SingleOption>;
  isNotificationRead?: boolean;
  handleClick?: () => void;
}

const NotificationBlock = ({
  notificationId,
  icon,
  title,
  notificationType,
  iconBgColor,
  description,
  timestamp,
  optionList,
  isNotificationRead = false,
  handleClick
}: NotificationBlockProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const notificationRelativeTime = dayjs(timestamp)
    .locale(translate.getCurrentLocaleShort())
    .fromNow();

  const handleMoreOptionClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let iconNode = null;
  let iconBackgroundColor = "";
  switch (notificationType) {
    case NOTIFICATION_TYPE.JOB_UPDATE:
      iconNode = <WorkIcon sx={{ fontSize: "small" }} color="primary" />;
      iconBackgroundColor = colorPalette.lighterBlue[20];
      break;

    case NOTIFICATION_TYPE.ALERT:
      iconNode = <InfoIcon sx={{ fontSize: "small" }} color="error" />;
      iconBackgroundColor = colorPalette.red.background.base;
      break;

    case NOTIFICATION_TYPE.PROMO:
      iconNode = <CampaignIcon sx={{ fontSize: "small" }} color="success" />;
      iconBackgroundColor = colorPalette.green.background.base;
      break;
  }

  return (
    <Box
      onClick={handleClick}
      p={2}
      bgcolor={
        isNotificationRead
          ? colorPalette.white.base
          : colorPalette.lightBlue.background.base
      }>
      <Stack direction="row" columnGap={1} alignItems="center">
        <Stack
          borderRadius="50%"
          p={0.5}
          justifyContent="center"
          alignItems="center"
          bgcolor={iconBgColor ? iconBgColor : iconBackgroundColor}>
          {icon ? icon : iconNode}
        </Stack>
        <Typography color="text.secondary" variant="caption">
          {intl.get(NOTIFICATION_TYPE_T_LABELS[notificationType])}
        </Typography>
        <Stack justifyContent="center" alignItems="center">
          <FiberManualRecordIcon
            sx={{ fontSize: "0.5rem", color: colorPalette.black.hover.base }}
          />
        </Stack>
        <Typography color="text.secondary" variant="caption">
          {notificationRelativeTime}
        </Typography>
        {!isNotificationRead ? (
          <FiberManualRecordIcon sx={{ fontSize: "small" }} color="secondary" />
        ) : (
          false
        )}
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mt={0.5}
        mb={0.4}>
        <Typography variant="subtitle5">{title}</Typography>
        <IconButton onClick={handleMoreOptionClick} sx={{ p: 0 }}>
          <MoreHorizIcon sx={{ cursor: "pointer" }} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}>
          {optionList?.map((singleOption: SingleOption, index: number) => {
            return (
              <MenuItem
                key={index}
                onClick={(event) => {
                  event.stopPropagation();
                  handleClose();
                  singleOption?.onClick?.(notificationId);
                }}>
                {singleOption?.label}
              </MenuItem>
            );
          })}
        </Menu>
      </Stack>
      <Typography variant="caption">{description}</Typography>
    </Box>
  );
};

export default NotificationBlock;
