import HiringProcessStepStatus from "@interfaces/components/HiringProcessStepStatus";
import HiringFlow from "@interfaces/database/HiringFlow";
import HiringFlowStep from "@interfaces/database/HiringFlowStep";

import {
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  JOB_HIRING_FLOW_INTERVIEW,
  JOB_HIRING_FLOW_INTERVIEW_T_LABELS,
  JOB_HIRING_FLOW_OFFER,
  JOB_HIRING_FLOW_OFFER_T_LABELS,
  JOB_HIRING_FLOW_RESUME_SCREENING,
  JOB_HIRING_FLOW_RESUME_SCREENING_T_LABELS,
  JOB_HIRING_FLOW_SECTIONS
} from "@utils/config";
import { intl } from "@utils/translate";

/**
 * Get the label for a specific section type.
 *
 * @param {keyof HiringFlow} section The section of the hiring flow
 * @param {string} sectionType The type of the section
 *
 * @returns {string} The label for the section type
 */
const getLabelBySectionType = (
  section: keyof HiringFlow,
  sectionType:
    | typeof JOB_HIRING_FLOW_RESUME_SCREENING[keyof typeof JOB_HIRING_FLOW_RESUME_SCREENING]
    | typeof JOB_HIRING_FLOW_INTERVIEW[keyof typeof JOB_HIRING_FLOW_INTERVIEW]
    | typeof JOB_HIRING_FLOW_OFFER[keyof typeof JOB_HIRING_FLOW_OFFER]
) => {
  let label = "";
  if (section === JOB_HIRING_FLOW_SECTIONS.resume_screening) {
    label = intl.get(
      JOB_HIRING_FLOW_RESUME_SCREENING_T_LABELS[
        sectionType as typeof JOB_HIRING_FLOW_RESUME_SCREENING[keyof typeof JOB_HIRING_FLOW_RESUME_SCREENING]
      ]
    );
  } else if (section === JOB_HIRING_FLOW_SECTIONS.interview) {
    label = intl.get(
      JOB_HIRING_FLOW_INTERVIEW_T_LABELS[
        sectionType as typeof JOB_HIRING_FLOW_INTERVIEW[keyof typeof JOB_HIRING_FLOW_INTERVIEW]
      ]
    );
  } else if (section === JOB_HIRING_FLOW_SECTIONS.offer) {
    label = intl.get(
      JOB_HIRING_FLOW_OFFER_T_LABELS[
        sectionType as typeof JOB_HIRING_FLOW_OFFER[keyof typeof JOB_HIRING_FLOW_OFFER]
      ]
    );
  } else {
    label = sectionType;
  }
  return label;
};

/**
 * Returns the hiring process steps with labels based on the section type.
 *
 * @param {HiringFlow} hiringFlowData The hiring flow data containing sections and steps
 *
 * @returns {Array<HiringProcessStepStatus>} The array of hiring process steps with labels
 */
const generateHiringProcessSteps = (
  hiringFlowData: HiringFlow
): Array<HiringProcessStepStatus> => {
  // get the sorted sections based on JOB_HIRING_FLOW_SECTIONS object
  const sortedSections = Object.keys(JOB_HIRING_FLOW_SECTIONS).map(
    (singleSectionKey) => {
      return Object.keys(hiringFlowData).find(
        (singleSection) =>
          JOB_HIRING_FLOW_SECTIONS[
            singleSectionKey as typeof JOB_HIRING_FLOW_SECTIONS[keyof typeof JOB_HIRING_FLOW_SECTIONS]
          ] === singleSection
      );
    }
  );

  const steps: Array<HiringProcessStepStatus> = [];
  (sortedSections as Array<keyof HiringFlow>).forEach((singleSection) => {
    hiringFlowData[singleSection].steps.forEach((step: HiringFlowStep) => {
      steps.push({
        key: step.key,
        label: getLabelBySectionType(singleSection, step.type),
        isCompleted: false
      });
    });
  });
  // add last step - Hired
  steps.push({
    key: JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED,
    label: intl.get("t_general_hired"),
    isCompleted: false
  });
  return steps;
};
export default generateHiringProcessSteps;
