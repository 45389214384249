import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { NavigateBefore as NavigateBeforeIcon } from "@mui/icons-material";
import { Box, Grid, Stack } from "@mui/material";
import * as yup from "yup";

import SkeletonCertificationForm from "@skeletons/SkeletonCertificationForm";

import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import DatePicker from "@components/DatePicker";
import Paper from "@components/Paper";
import TextField from "@components/TextField";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";

import {
  CERTIFICATION_EXPIRY_DATE_CHECKBOX_VALUES,
  FREE_TEXT_FIELD_MAX_LENGTH,
  LOCALE_SHORT,
  URL_REGEX
} from "@utils/config";
import { intl } from "@utils/translate";

interface CertificationForm {
  name?: string;
  noExpiry?: Array<number>;
  issueDate?: Date | string;
  expiryDate?: Date | string;
  issuingOrganization: string;
  certificationUrl?: string;
  description?: string;
}

interface CertificationFormProps {
  initialValues?: CertificationForm;
  handleFormSubmit: (formData: CertificationForm) => void;
  backBtnLink?: string;
  isDisabled?: boolean;
  formLanguage?: typeof LOCALE_SHORT[keyof typeof LOCALE_SHORT];
}

const CertificationForm = ({
  initialValues,
  handleFormSubmit,
  backBtnLink,
  isDisabled = false,
  formLanguage = LOCALE_SHORT.JA
}: CertificationFormProps) => {
  const navigate = useNavigate();
  const userData = useUserProfile();
  const [loading, setLoading] = useState<boolean>(true);

  const schema = yup.object({
    name: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get(
            "t_resumes_certification_form_label_certification_name"
          ),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get(
            "t_resumes_certification_form_label_certification_name"
          )
        })
      ),
    noExpiry: yup.array().test(
      "noExpiry",
      intl.get("t_error_invalid", {
        field: intl.get("t_resumes_certification_form_no_expiry_date")
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (value: any) => {
        if (
          value?.length === 0 ||
          value?.includes(
            CERTIFICATION_EXPIRY_DATE_CHECKBOX_VALUES.NO_EXPIRY_CHECKED
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    ),
    issueDate: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_resumes_certification_form_label_issue_date")
        })
      )
      .typeError(
        intl.get("t_error_invalid", {
          field: intl.get("t_resumes_certification_form_label_issue_date")
        })
      ),
    expiryDate: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .min(
        yup.ref("issueDate"),
        intl.get("t_error_minimum_date", {
          endDate: intl.get("t_resumes_certification_form_label_expiry_date"),
          startDate: intl.get("t_resumes_certification_form_label_issue_date")
        })
      )
      .when("noExpiry", {
        is: (noExpiry: Array<number>) => noExpiry?.includes(1),
        then: (schema) => {
          return schema.notRequired();
        },
        otherwise: (schema) => {
          return schema.required(
            intl.get("t_error_required", {
              field: intl.get("t_resumes_certification_form_label_expiry_date")
            })
          );
        }
      })
      .typeError(
        intl.get("t_error_invalid", {
          field: intl.get("t_resumes_certification_form_label_expiry_date")
        })
      ),
    issuingOrganization: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_resumes_certification_form_label_issuing_org"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_resumes_certification_form_label_issuing_org")
        })
      ),
    certificationUrl: yup.string().matches(URL_REGEX, {
      message: intl.get("t_error_required", {
        field: intl.get("t_resumes_certification_form_label_certificate_url")
      }),
      excludeEmptyString: true
    }),
    description: yup
      .string()
      .trim()
      .max(
        5000,
        intl.get("t_error_max_limit", {
          field: intl.get("t_resumes_certification_form_label_description"),
          maxLimit: 5000
        })
      )
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, setValue, watch } = methods;

  const hasNoExpiry = watch("noExpiry")?.includes(1); // array contain 1 => no expiry checkbox is checked else, unchecked

  useEffect(() => {
    if (hasNoExpiry) {
      setValue("expiryDate", "");
    }
  }, [hasNoExpiry]);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading || userData.loading) {
    return <SkeletonCertificationForm />;
  }

  return (
    <Paper>
      <Typography variant="h3">
        {intl.get("t_resumes_certification_title")}
      </Typography>
      <br />
      <Typography variant="body1" color="text.secondary" mt={2.5}>
        {intl.get("t_resumes_add_certification_form_subtitle")}
      </Typography>
      <br />
      <Typography mb={3} color="text.secondary">
        {formLanguage === LOCALE_SHORT.JA
          ? intl.get("t_resumes_form_subtitle_lang_japanese")
          : intl.get("t_resumes_form_subtitle_lang_english")}
      </Typography>
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container columnSpacing={3}>
          <Grid item xs={12}>
            <TextField
              data-testid="resumes_certification_name_input"
              disabled={isDisabled}
              name="name"
              label={intl.get(
                "t_resumes_certification_form_label_certification_name"
              )}
              placeholder={intl.get(
                "t_resumes_certification_form_label_certification_name"
              )}
              control={control}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Checkbox
              data-testid="resumes_certification_no_expiry_checkbox"
              disabled={isDisabled}
              size="medium"
              name="noExpiry"
              control={control}
              setValue={setValue}
              options={[
                {
                  label: intl.get(
                    "t_resumes_certification_form_label_expiry_check"
                  ),
                  value:
                    CERTIFICATION_EXPIRY_DATE_CHECKBOX_VALUES.NO_EXPIRY_CHECKED
                }
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              data-testid="resumes_certification_issue_date_input"
              disabled={isDisabled}
              name="issueDate"
              label={intl.get("t_resumes_certification_form_label_issue_date")}
              control={control}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              data-testid="resumes_certification_expiry_date_input"
              name="expiryDate"
              label={intl.get("t_resumes_certification_form_label_expiry_date")}
              control={control}
              required={!hasNoExpiry}
              disabled={hasNoExpiry || isDisabled}
              disableFuture={false}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              data-testid="resumes_certification_issuing_org_input"
              disabled={isDisabled}
              name="issuingOrganization"
              label={intl.get("t_resumes_certification_form_label_issuing_org")}
              placeholder={intl.get(
                "t_resumes_certification_form_label_issuing_org"
              )}
              control={control}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              data-testid="resumes_certification_certificate_url_input"
              disabled={isDisabled}
              name="certificationUrl"
              label={intl.get(
                "t_resumes_certification_form_label_certificate_url"
              )}
              placeholder={intl.get(
                "t_resumes_certification_form_label_certificate_url"
              )}
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              data-testid="resumes_certification_description_input"
              disabled={isDisabled}
              name="description"
              label={intl.get("t_resumes_certification_form_label_description")}
              placeholder={intl.get(
                "t_resumes_certification_placeholder_description"
              )}
              control={control}
              multiline
              maxLength={5000}
              rows={3}
            />
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="space-between">
          {backBtnLink ? (
            <Button
              data-testid="resumes_certification_back_button"
              variant="outlined"
              startAdornment={<NavigateBeforeIcon />}
              handleClick={() => navigate(backBtnLink)}>
              {intl.get("t_general_back")}
            </Button>
          ) : (
            false
          )}
          <Button
            type="submit"
            loading={isDisabled}
            data-testid="resumes_certification_save_button">
            {intl.get("t_general_save")}
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};

export default CertificationForm;
