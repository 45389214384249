import { useEffect, useState } from "react";

import { FirebaseError } from "firebase/app";
import {
  collection,
  DocumentData,
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter
} from "firebase/firestore";

import Message from "@interfaces/database/Message";

import { FIRESTORE_COLLECTIONS } from "@utils/config";
import { db } from "@utils/firebase";

interface LoadItemsResponse {
  loading: boolean;
  items: Message[];
  hasNextPage: boolean;
  error: FirebaseError;
  loadMore: () => void;
}

export const useLoadMessages = ({
  conversationId
}: {
  conversationId: string;
}): LoadItemsResponse => {
  const LIMIT = 10;
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<Message[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [error, setError] = useState<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any
  const [lastVisibleMessage, setLastVisibleMessage] = useState<DocumentData>();
  const [currentConversationId, setCurrentConversationId] =
    useState<string>(conversationId);

  const loadMore = async () => {
    setLoading(true);
    try {
      const conversationSubCollectionRef = collection(
        db,
        `${FIRESTORE_COLLECTIONS.CONVERSATIONS}/${conversationId}/${FIRESTORE_COLLECTIONS.MESSAGES}`
      );
      onSnapshot(
        lastVisibleMessage
          ? query(
              collection(
                db,
                `${FIRESTORE_COLLECTIONS.CONVERSATIONS}/${conversationId}/${FIRESTORE_COLLECTIONS.MESSAGES}`
              ),
              orderBy("created_at", "desc"),
              startAfter(lastVisibleMessage),
              limit(LIMIT)
            )
          : query(
              collection(
                db,
                `${FIRESTORE_COLLECTIONS.CONVERSATIONS}/${conversationId}/${FIRESTORE_COLLECTIONS.MESSAGES}`
              ),
              orderBy("created_at", "desc"),
              limit(LIMIT)
            ),
        async (querySnapshot) => {
          if (!querySnapshot.empty) {
            const updatedItems: Message[] = [];
            querySnapshot.forEach((doc) => {
              updatedItems.push(doc.data() as Message);
            });

            const totalMessagesCountData = await getCountFromServer(
              query(conversationSubCollectionRef)
            );

            const moreMessages: Message[] = [];
            querySnapshot.forEach((doc) => {
              moreMessages.push(doc.data() as Message);
            });

            if (
              !querySnapshot.empty &&
              currentConversationId === conversationId
            ) {
              setLastVisibleMessage(
                querySnapshot.docs[querySnapshot.docs.length - 1]
              );
            }

            const isMoreData =
              totalMessagesCountData.data().count >
              items.length + moreMessages.length;

            const data =
              currentConversationId === conversationId
                ? [...items, ...moreMessages]
                : moreMessages;
            setHasNextPage(isMoreData);
            setItems(data);
          }
        }
      );
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentConversationId(conversationId);
    setItems([]);
    setLastVisibleMessage(undefined);
    setHasNextPage(true);
  }, [conversationId]);

  return { loading, items, hasNextPage, error, loadMore };
};
