import { Stack, styled, TypographyTypeMap } from "@mui/material";

import Icon from "@components/Icon";
import Typography from "@components/Typography";

interface InformationFieldProps {
  "icon"?: string;
  "imgSrc"?: string;
  "imgAlt"?: string;
  "label"?: string | number;
  "variant"?: TypographyTypeMap["props"]["variant"];
  "iconVariant"?: TypographyTypeMap["props"]["variant"];
  "blurText"?: boolean;
  "data-testid"?: string;
}

const StyledTypography = styled(Typography)({
  wordBreak: "break-all"
});

const StyledImage = styled("img")({
  height: "1.5rem",
  width: "1.5rem"
});

const InformationField = ({
  icon,
  imgSrc,
  imgAlt,
  label = "-",
  variant = "body1",
  iconVariant = "subtitle1",
  blurText = false,
  "data-testid": dataTestId
}: InformationFieldProps) => {
  return (
    <Stack data-testid={dataTestId} direction="row" alignItems="center">
      <StyledTypography variant={variant} width="100%">
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography variant={iconVariant} lineHeight={0}>
            {icon ? (
              <Icon type={icon} filled />
            ) : (
              <StyledImage src={imgSrc} alt={imgAlt} />
            )}
          </Typography>
          <Typography
            variant="inherit"
            sx={
              blurText
                ? {
                    filter: "blur(4px)",
                    userSelect: "none"
                  }
                : null
            }>
            {label ? label : "-"}
          </Typography>
        </Stack>
      </StyledTypography>
    </Stack>
  );
};

export default InformationField;
