import { CheckCircleRounded as CheckCircleRoundedIcon } from "@mui/icons-material";
import { Stack, styled, Tooltip } from "@mui/material";

import Button from "@components/Button";
import Typography from "@components/Typography";

import {
  STRIPE_SUBSCRIPTION_PLAN,
  SUBSCRIPTION_PLAN_TERM
} from "@utils/config";
import { roundSalary } from "@utils/roundSalary";
import { colorPalette } from "@utils/theme";
import { intl } from "@utils/translate";

interface PlanCardProps {
  plan?: typeof STRIPE_SUBSCRIPTION_PLAN[keyof typeof STRIPE_SUBSCRIPTION_PLAN];
  term?: typeof SUBSCRIPTION_PLAN_TERM[keyof typeof SUBSCRIPTION_PLAN_TERM];
  isCurrentPlan?: boolean;
  isDisabled?: boolean;
  pricePerMonth?: number;
  pricePerYear?: number;
  handleSubscribe?: () => void;
  isPurchaseLoading?: boolean;
  isPriceLoading?: boolean;
}

const PlanCard = ({
  plan = STRIPE_SUBSCRIPTION_PLAN.FREE,
  term = SUBSCRIPTION_PLAN_TERM.MONTHLY,
  isCurrentPlan = false,
  isDisabled = false,
  pricePerYear = 0,
  pricePerMonth = 0,
  handleSubscribe,
  isPurchaseLoading = false,
  isPriceLoading = false
}: PlanCardProps) => {
  const StyledStack = styled(Stack)(({ theme }) => ({
    alignItems: "flex-start",
    background: theme.palette.common.white,
    borderRadius: 4,
    border: `${
      isCurrentPlan && !isDisabled
        ? `1px solid ${colorPalette.blue.base}`
        : `1px solid ${colorPalette.grey.base}`
    }`,
    filter: isPriceLoading ? "grayscale(1)" : "unset",
    padding: 24,
    maxWidth: "100%"
  }));

  const isButtonDisabled = isDisabled || isCurrentPlan || isPriceLoading;
  const planPrice = isPriceLoading
    ? "-"
    : term === SUBSCRIPTION_PLAN_TERM.MONTHLY
    ? roundSalary(pricePerMonth)
    : roundSalary(pricePerYear);

  let title = "";
  let description = "";

  switch (plan) {
    case STRIPE_SUBSCRIPTION_PLAN.FREE:
    default:
      title = intl.get("t_employer_plan_status_free");
      description = intl.get("t_employer_plan_card_basic_description");
      break;
    case STRIPE_SUBSCRIPTION_PLAN.STARTER:
      title = intl.get("t_employer_plan_status_starter");
      description = intl.get("t_employer_plan_card_standard_description");
      break;
    case STRIPE_SUBSCRIPTION_PLAN.PRO:
      title = intl.get("t_employer_plan_status_pro");
      description = intl.get("t_employer_plan_card_advanced_description");
      break;
  }

  const PlanDetails = () => {
    return (
      <StyledStack gap={1}>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
        <Stack direction="row">
          <Typography variant="h3">
            ¥&nbsp;
            {planPrice}
          </Typography>
          <Typography variant="subtitle5" alignSelf="flex-end">
            {intl.get("t_employer_plan_card_price_per_month")}
          </Typography>
        </Stack>

        <Stack my={1} gap={1}>
          {/* FIXME: Update with actual content */}
          {new Array(4).fill(null).map((_, index) => (
            <Stack key={index} gap={1} direction="row">
              <CheckCircleRoundedIcon color="primary" />
              <Typography variant="body1">Lorem ipsum</Typography>
            </Stack>
          ))}
        </Stack>

        <Button
          disabled={isButtonDisabled}
          fullWidth
          handleClick={handleSubscribe}
          loading={!isButtonDisabled && isPurchaseLoading}>
          {isCurrentPlan
            ? intl.get("t_employer_plan_card_current_plan")
            : intl.get("t_employer_plan_card_choose_plan")}
        </Button>
      </StyledStack>
    );
  };

  return isPriceLoading ? (
    <Tooltip
      arrow
      placement="top"
      enterTouchDelay={0}
      title={
        <Typography variant="body2">
          {intl.get("t_general_please_try_again_later")}
        </Typography>
      }>
      <div>
        <PlanDetails />
      </div>
    </Tooltip>
  ) : (
    <PlanDetails />
  );
};

export default PlanCard;
