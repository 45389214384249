import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Well from "@components/Well";

const SkeletonResumeProgressCard = () => {
  return (
    <Well>
      <SkeletonTypography variant="h4" color="text.primary" mb={2.5} />
      <SkeletonTypography
        variant="body1"
        color="text.secondary"
        mb={3}
        width="long"
      />
      <SkeletonButton />
    </Well>
  );
};

export default SkeletonResumeProgressCard;
