import { ReactIntlUniversal } from "react-intl-universal";

import { DialogActions, DialogContent, Stack, styled } from "@mui/material";
import dayjs from "dayjs";

import ActionButton from "@components/ActionButton";
import Button from "@components/Button";
import Dialog from "@components/Dialog";
import InformationField from "@components/InformationField";
import Typography from "@components/Typography";
import Well from "@components/Well";

import {
  DIALOG_ACTION,
  INDIVIDUAL_USER_DEGREE_TYPE,
  INDIVIDUAL_USER_DEGREE_TYPE_T_LABELS,
  LOCALE_SHORT
} from "@utils/config";
import translate, { intl as intlGlobal } from "@utils/translate";

interface EducationCardProps {
  instituteName?: string;
  degreeType?: typeof INDIVIDUAL_USER_DEGREE_TYPE[keyof typeof INDIVIDUAL_USER_DEGREE_TYPE];
  fieldOfStudy?: string;
  startDate?: Date;
  endDate?: Date;
  handleEdit?: () => void;
  handleDelete?: () => void;
  mode?: "edit" | "view";
  intl?: typeof intlGlobal | ReactIntlUniversal;
}

const StyledTypography = styled(Typography)({
  wordBreak: "break-all"
});

const EducationCard = ({
  instituteName,
  degreeType,
  fieldOfStudy,
  startDate,
  endDate,
  handleEdit,
  handleDelete,
  mode = "view",
  intl = intlGlobal
}: EducationCardProps) => {
  const handleClose = (reason: keyof typeof DIALOG_ACTION) => {
    if (reason === DIALOG_ACTION.AGREE && handleDelete) {
      handleDelete();
    }
  };

  return (
    <Well showBorder={mode === "edit" ? true : false}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height={40}
        width="100%">
        {/* Institute Name */}
        <InformationField
          icon="school"
          label={instituteName}
          variant="subtitle4"
        />

        {/* Actions */}
        {mode === "edit" ? (
          <Stack flexDirection="row">
            <ActionButton icon="edit" color="blue" handleAction={handleEdit} />
            <Dialog
              title={intlGlobal.get("t_resumes_remove_education")}
              maxWidth="sm"
              initiator={<ActionButton icon="delete" color="grey" />}
              onClose={handleClose}>
              {(handleAgree, handleCancel) => (
                <>
                  <DialogContent sx={{ py: 1 }}>
                    <Typography color="text.secondary">
                      {intlGlobal.get("t_resumes_dialog_delete_title", {
                        entry: intlGlobal.get("t_resumes_education_title")
                      })}
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button handleClick={handleCancel} variant="outlined">
                      {intlGlobal.get("t_resumes_dialog_delete_cancel_button")}
                    </Button>
                    <Button handleClick={handleAgree}>
                      {intlGlobal.get("t_resumes_dialog_delete_agree_button")}
                    </Button>
                  </DialogActions>
                </>
              )}
            </Dialog>
          </Stack>
        ) : (
          false
        )}
      </Stack>

      {/* Other details */}
      <Stack spacing={1} width="100%">
        <StyledTypography variant="subtitle4">
          {degreeType
            ? intl.get(INDIVIDUAL_USER_DEGREE_TYPE_T_LABELS[degreeType])
            : "-"}
        </StyledTypography>
        <StyledTypography variant="body1">
          {fieldOfStudy ?? "-"}
        </StyledTypography>
        <Typography variant="body1">
          {startDate ? (
            <>
              {`${dayjs(startDate).format(
                translate.getLocaleShort(
                  intl.getInitOptions().currentLocale
                ) === LOCALE_SHORT.JA
                  ? "YYYY年M月"
                  : "MMMM YYYY"
              )} - ${dayjs(endDate).format(
                translate.getLocaleShort(
                  intl.getInitOptions().currentLocale
                ) === LOCALE_SHORT.JA
                  ? "YYYY年M月"
                  : "MMMM YYYY"
              )}`}
            </>
          ) : (
            ""
          )}
        </Typography>
      </Stack>
    </Well>
  );
};

export default EducationCard;
