import { Box, Stack } from "@mui/material";

import Typography from "@components/Typography";

interface PieChartLegendProps {
  data: Array<{
    category: string;
    color: string;
  }>;
}

const PieChartLegend = ({ data }: PieChartLegendProps) => {
  return (
    <Stack flexDirection="row" justifyContent="center" flexWrap="wrap" gap={1}>
      {data?.map((singleCategory) => {
        return (
          <Stack
            key={singleCategory.category}
            flexDirection="row"
            alignItems="center"
            gap={1}>
            <Box
              component="span"
              height={8}
              width={8}
              borderRadius={0.5}
              bgcolor={singleCategory.color}
            />
            <Typography variant="helperText">
              {singleCategory.category}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default PieChartLegend;
