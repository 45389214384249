/**
 * Replaces newline characters in the given string with HTML break elements.
 *
 * @param {string} text - The input string with newline characters
 * @return {string} The updated string with break elements
 */

export const replaceNewlinesWithBreak = (text: string): string => {
  if (text) {
    // ref:- https://stackoverflow.com/a/784547/13285590
    return text?.replace(/(?:\r\n|\r|\n)/g, "<br/>") ?? "";
  } else {
    return "";
  }
};
