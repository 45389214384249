import {
  Skeleton,
  Stack,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from "@mui/material";

import SkeletonStatus from "@skeletons/SkeletonStatus";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import DataTableWrapper from "@components/DataTable/DataTableWrapper";
import Typography from "@components/Typography";

import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";
import { ENVIRONMENT, PAGINATION } from "@utils/config";
import { intl } from "@utils/translate";

interface SkeletonManageTeamTableProps {
  rows?: number;
}

interface Column {
  id: "fullName" | "email" | "teamMemberAccountType" | "joinedDate";
  label: string;
  align?: "left" | "right" | "center";
}

const COLUMNS: ReadonlyArray<Column> = [
  {
    id: "fullName",
    label: "t_employer_manage_team_table_column_full_name",
    align: "left"
  },
  {
    id: "email",
    label: "t_employer_manage_team_table_column_email",
    align: "left"
  },
  {
    id: "teamMemberAccountType",
    label: "t_employer_manage_team_table_column_account_type",
    align: "left"
  },
  {
    id: "joinedDate",
    label: "t_employer_manage_team_table_column_joined_date",
    align: "left"
  }
];

const SkeletonManageTeamTable = ({
  rows = PAGINATION.TABLE_ROWS_PER_PAGE[0]
}: SkeletonManageTeamTableProps) => {
  const ROWS = new Array(rows).fill({
    fullName: <SkeletonTypography variant="body2" width="short" />,
    email: <SkeletonTypography variant="body2" width="short" />,
    teamMemberAccountType: <SkeletonStatus />,
    joinedDate: <SkeletonTypography variant="body2" width="short" />
  });

  return (
    <DataTableWrapper>
      <TableHead>
        <TableRow>
          {COLUMNS?.map((singleColumn) => (
            <TableCell
              key={singleColumn.id}
              width="16.6%"
              align={singleColumn?.align ?? "center"}>
              <Typography variant="subtitle5">
                {singleColumn.label ? intl.get(singleColumn.label) : ""}
              </Typography>
            </TableCell>
          ))}
          {/* Note: Last TableCell has no title. Used to display actions like remove */}
          <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
            <TableCell></TableCell>
          </EnvironmentSpecific>
        </TableRow>
      </TableHead>

      <TableBody>
        {ROWS?.map((singleRow, index) => (
          <TableRow key={index}>
            {COLUMNS?.map((singleColumn) => (
              <TableCell
                key={singleColumn.id}
                align={singleColumn.align ?? "center"}>
                {singleRow[singleColumn.id]}
              </TableCell>
            ))}
            <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
              <TableCell align="center">
                <SkeletonTypography variant="body2" width="short" />
              </TableCell>
            </EnvironmentSpecific>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter sx={{ mr: 0 }}>
        <TableRow>
          <TableCell colSpan={5}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={3.25}
              height={48}>
              <Skeleton variant="rounded" height="1rem" width="6rem" />
              <Skeleton variant="rounded" height="1rem" width="4rem" />
              <Skeleton variant="rounded" height="1rem" width="2rem" />
              <Skeleton variant="rounded" height="1rem" width="2rem" />
            </Stack>
          </TableCell>
        </TableRow>
      </TableFooter>
    </DataTableWrapper>
  );
};

export default SkeletonManageTeamTable;
