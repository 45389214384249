import { useState } from "react";

import ReactJson, { InteractionProps } from "react-json-view";

import { Box } from "@mui/material";

import SkeletonTypography from "@skeletons/SkeletonTypography";

import Toast from "@components/Toast";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";

import ContextCompanyProfile from "@interfaces/components/ContextCompanyProfile";
import ContextUserProfile from "@interfaces/components/ContextUserProfile";

import { USER_TYPE } from "@utils/config";
import { colorPalette } from "@utils/theme";
import { intl } from "@utils/translate";

const ProfileData = () => {
  const [successOpen, setSuccessOpen] = useState<boolean>(false);
  const handleSuccessToast = () => setSuccessOpen(!successOpen);

  const [failOpen, setFailOpen] = useState<boolean>(false);
  const handleFailToast = () => setFailOpen(!failOpen);

  const handleOpenSuccessModal = () => {
    setSuccessOpen(true);
  };

  const handleOpenFailModal = () => {
    setFailOpen(true);
  };

  const data = useUserProfile();
  const editUserProfile = async (edit: InteractionProps) => {
    try {
      const newValue = edit.updated_src as ContextUserProfile;
      if (data.setValue !== undefined) {
        await data.setValue(
          newValue,
          handleOpenSuccessModal,
          handleOpenFailModal
        );
      }
    } catch (e) {
      handleOpenFailModal();
    }
  };

  const companyData = useCompanyDetails();
  const editCompanyData = async (edit: InteractionProps) => {
    try {
      const newValue = edit.updated_src as ContextCompanyProfile;
      if (companyData.setValue !== undefined) {
        await companyData.setValue(
          newValue,
          handleOpenSuccessModal,
          handleOpenFailModal
        );
      }
    } catch (e) {
      handleOpenFailModal();
    }
  };

  return (
    <>
      <Toast
        variant="success"
        message="Updated Profile Successfully"
        open={successOpen}
        setOpen={handleSuccessToast}
      />
      <Toast
        variant="error"
        message="Cannot update Profile"
        open={failOpen}
        setOpen={handleFailToast}
      />
      <Typography variant="h5">
        {intl.get("t_debugger_profile_information")}:
      </Typography>
      <br />
      {data.loading ? (
        <SkeletonTypography multiLine />
      ) : (
        <Box p={2} bgcolor={colorPalette.specific.debuggerJsonPreviewBG}>
          <ReactJson
            enableClipboard={(copy) =>
              navigator.clipboard.writeText(
                JSON.stringify({ [copy.name + ""]: copy.src })
              )
            }
            theme="chalk"
            src={data.value ?? {}}
            onEdit={editUserProfile}
            onAdd={editUserProfile}
            onDelete={editUserProfile}
            sortKeys
          />
        </Box>
      )}
      {data.value?.user_type === USER_TYPE.COMPANY ? (
        <>
          <br />
          <br />
          <Typography variant="h5">Company Data:</Typography>
          <br />
          {companyData.loading ? (
            <SkeletonTypography multiLine />
          ) : (
            <Box p={2} bgcolor={colorPalette.specific.debuggerJsonPreviewBG}>
              <ReactJson
                theme="chalk"
                src={companyData.value ?? {}}
                onEdit={editCompanyData}
                onAdd={editCompanyData}
                onDelete={editCompanyData}
                sortKeys
              />
            </Box>
          )}
        </>
      ) : (
        false
      )}
    </>
  );
};

export default ProfileData;
