import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { NavigateBefore as NavigateBeforeIcon } from "@mui/icons-material";
import { Box, Grid, Stack, Typography } from "@mui/material";
import * as yup from "yup";

import SkeletonLanguageForm from "@skeletons/SkeletonLanguageForm";

import Button from "@components/Button";
import NonTypeableSelect from "@components/NonTypeableSelect";
import Paper from "@components/Paper";
import TextField from "@components/TextField";
import TypeableSelect from "@components/TypeableSelect";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";

import KeyLabel from "@interfaces/components/KeyLabel";

import {
  FREE_TEXT_FIELD_MAX_LENGTH,
  JA_LANGUAGE_TEST,
  JA_LANGUAGE_TEST_T_LABELS,
  LANGUAGE_PROFICIENCY,
  LANGUAGE_PROFICIENCY_T_LABELS,
  LOCALE_SHORT,
  OTHER_LANGUAGE_PROFICIENCY,
  OTHER_LANGUAGE_PROFICIENCY_T_LABELS
} from "@utils/config";
import { jaTestLevelOptionsFinder } from "@utils/jaTestLevelOptionsFinder";
import { getDropdownLanguageList } from "@utils/language";
import { intl } from "@utils/translate";

interface LanguageForm {
  name?: KeyLabel;
  proficiency?: typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY];
  testName?: string;
  testResult?: string;
}

interface LanguageFormProps {
  initialValues: LanguageForm;
  handleFormSubmit: (formData: LanguageForm) => void;
  backBtnLink?: string;
  isDisabled?: boolean;
  formLanguage?: typeof LOCALE_SHORT[keyof typeof LOCALE_SHORT];
}

const LanguageForm = ({
  initialValues,
  handleFormSubmit,
  backBtnLink,
  isDisabled = false,
  formLanguage = LOCALE_SHORT.JA
}: LanguageFormProps) => {
  const userData = useUserProfile();
  const navigate = useNavigate();
  const { item_id } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const itemId = Number(item_id);
  const isFirstField = itemId === 0; // japanese language

  // validation schema
  const schema = yup.object().shape(
    {
      name: yup
        .object()
        .shape({
          key: yup.string(),
          label: yup.string()
        })
        .test(
          "name",
          intl.get("t_error_required", {
            field: intl.get("t_profile_language_heading")
          }),
          (value) => (value?.key && value?.label ? true : false)
        )
        .nullable(),
      proficiency: yup
        .string()
        .required(
          intl.get("t_error_required", {
            field: intl.get("t_profile_language_proficiency")
          })
        )
        .nullable(),
      testName: yup
        .string()
        .trim()
        .max(
          FREE_TEXT_FIELD_MAX_LENGTH,
          intl.get("t_error_max_limit", {
            field: intl.get("t_profile_language_test"),
            maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
          })
        )
        .when("testResult", {
          is: (testResult: string) => testResult?.trim() !== "",
          then: (schema) =>
            schema.required(
              intl.get("t_error_required", {
                field: intl.get("t_profile_language_test")
              })
            )
        }),
      testResult: yup
        .string()
        .trim()
        .max(
          FREE_TEXT_FIELD_MAX_LENGTH,
          intl.get("t_error_max_limit", {
            field: intl.get("t_profile_language_test_result"),
            maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
          })
        )
        .when("testName", {
          is: (testName: string) => testName?.trim() !== "",
          then: (schema) =>
            schema.required(
              intl.get("t_error_required", {
                field: intl.get("t_profile_language_test_result")
              })
            )
        })
    },
    [["testName", "testResult"]]
  );

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, setValue, watch, formState } = methods;

  const selectedTestName = watch("testName");

  useEffect(() => {
    setLoading(false);
  }, []);

  const JA_LANGUAGE_TEST_OPTIONS = useOptions(
    JA_LANGUAGE_TEST,
    JA_LANGUAGE_TEST_T_LABELS
  );

  const LANGUAGE_PROFICIENCY_OPTIONS = useOptions(
    LANGUAGE_PROFICIENCY,
    LANGUAGE_PROFICIENCY_T_LABELS
  );

  const OTHER_LANGUAGE_PROFICIENCY_OPTIONS = useOptions(
    OTHER_LANGUAGE_PROFICIENCY,
    OTHER_LANGUAGE_PROFICIENCY_T_LABELS
  );

  const ALL_LANGUAGES = getDropdownLanguageList();

  useEffect(() => {
    if (isFirstField) {
      if (formState.dirtyFields?.testName) {
        setValue("testResult", "");
      }
    }
  }, [selectedTestName]);

  const JA_TEST_SCORE_OPTIONS = jaTestLevelOptionsFinder(selectedTestName);

  if (loading || userData.loading) {
    return <SkeletonLanguageForm hasSingleField />;
  }

  return (
    <Box noValidate component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Paper>
        <Typography variant="h3">
          {intl.get("t_profile_language_heading")}
        </Typography>
        <Typography mt={2.5} color="text.secondary">
          {intl.get("t_profile_language_sub_heading")}
        </Typography>
        <Typography mb={3} color="text.secondary">
          {formLanguage === LOCALE_SHORT.JA
            ? intl.get("t_resumes_form_subtitle_lang_japanese")
            : intl.get("t_resumes_form_subtitle_lang_english")}
        </Typography>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={5.5}>
            {isFirstField ? (
              <TypeableSelect
                data-testid="profile_language_name_select"
                options={[]}
                disabled={isDisabled}
                control={control}
                name="name"
                label={intl.get("t_profile_language_heading")}
                placeholder={intl.get("t_profile_language_heading")}
                required
                readOnly
              />
            ) : (
              <TypeableSelect
                data-testid="profile_language_name_select"
                disabled={isDisabled}
                control={control}
                name="name"
                label={intl.get("t_profile_language_heading")}
                placeholder={intl.get("t_profile_language_heading")}
                required
                options={ALL_LANGUAGES}
              />
            )}
          </Grid>
          <Grid item xs={12} md={5.5}>
            <NonTypeableSelect
              data-testid="profile_language_proficiency_select"
              disabled={isDisabled}
              setValue={setValue}
              control={control}
              name="proficiency"
              label={intl.get("t_profile_language_proficiency")}
              placeholder={intl.get(
                "t_profile_language_proficiency_placeholder"
              )}
              required
              options={
                isFirstField
                  ? LANGUAGE_PROFICIENCY_OPTIONS
                  : OTHER_LANGUAGE_PROFICIENCY_OPTIONS
              }
            />
          </Grid>
          <Grid item xs={12} md={5.5}>
            {isFirstField ? (
              <NonTypeableSelect
                data-testid="profile_language_test_select"
                disabled={isDisabled}
                setValue={setValue}
                control={control}
                name="testName"
                label={intl.get("t_profile_language_test")}
                placeholder={intl.get("t_profile_language_test")}
                options={JA_LANGUAGE_TEST_OPTIONS}
                required={Boolean(selectedTestName)}
              />
            ) : (
              <TextField
                data-testid="profile_language_test_input"
                disabled={isDisabled}
                control={control}
                name="testName"
                label={intl.get("t_profile_language_test")}
                placeholder={intl.get("t_profile_language_test")}
                required={
                  Boolean(watch("testName")) || Boolean(watch("testResult"))
                }
              />
            )}
          </Grid>
          <Grid item xs={12} md={5.5}>
            {isFirstField ? (
              <NonTypeableSelect
                data-testid="profile_language_test_result_select"
                disabled={isDisabled || !selectedTestName}
                setValue={setValue}
                control={control}
                name="testResult"
                label={intl.get("t_profile_language_test_result")}
                placeholder={intl.get("t_profile_language_test_result")}
                options={JA_TEST_SCORE_OPTIONS}
                required={Boolean(selectedTestName)}
              />
            ) : (
              <TextField
                data-testid="profile_language_test_result_input"
                disabled={isDisabled}
                control={control}
                name="testResult"
                label={intl.get("t_profile_language_test_result")}
                placeholder={intl.get("t_profile_language_test_result")}
                required={
                  Boolean(watch("testName")) || Boolean(watch("testResult"))
                }
              />
            )}
          </Grid>
        </Grid>
      </Paper>
      <Stack justifyContent="space-between" direction="row" mt={5.5}>
        {backBtnLink ? (
          <Button
            data-testid="profile_language_back_button"
            variant="outlined"
            startAdornment={<NavigateBeforeIcon />}
            handleClick={() => navigate(backBtnLink)}>
            {intl.get("t_general_back")}
          </Button>
        ) : (
          false
        )}
        <Button
          type="submit"
          loading={isDisabled}
          data-testid="profile_language_save_button">
          {intl.get("t_general_save")}
        </Button>
      </Stack>
    </Box>
  );
};

export default LanguageForm;
