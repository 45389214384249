import { JOB_SEARCH_SALARY_DISPLAY_DIGIT_COUNT, LOCALE } from "@utils/config";
import translate, { intl } from "@utils/translate";

// This function is used to round the salary to nearest unit and return the i18n translated string.
const roundSalary = (salary: number): string => {
  if (salary === 0) {
    return "0";
  }

  if (salary > 0) {
    let roundedSalary = "";
    const language = translate.getCurrentLocale();
    switch (language) {
      case LOCALE.EN:
        roundedSalary = roundSalaryEn(salary);
        break;
      case LOCALE.JA:
        roundedSalary = roundSalaryJa(salary);
        break;
      default:
        roundedSalary = roundSalaryJa(salary);
    }

    return roundedSalary;
  }

  return "";
};

const roundSalaryEn = (salary: number): string => {
  let unit = "";
  if (salary > 1000000000) {
    unit = "B";
    salary = salary / 1000000000;
  } else if (salary > 1000000) {
    unit = "M";
    salary = salary / 1000000;
  } else if (salary > 1000) {
    unit = "K";
    salary = salary / 1000;
  }

  salary = roundDown(salary);

  return intl.get("t_general_money_no_currency", { amount: salary }) + unit;
};

const roundSalaryJa = (salary: number): string => {
  let unit = "";

  if (salary > 100000000) {
    unit = "億";
    salary = salary / 100000000;
  } else if (salary > 10000) {
    unit = "万";
    salary = salary / 10000;
  } else if (salary > 1000) {
    unit = "千";
    salary = salary / 1000;
  }

  salary = roundDown(salary);

  return intl.get("t_general_money_no_currency", { amount: salary }) + unit;
};

const roundDown = (salary: number): number => {
  if (salary.toString().length <= JOB_SEARCH_SALARY_DISPLAY_DIGIT_COUNT) {
    return salary;
  }
  // Get the first 5 decimal digits.
  const roundedDecimal =
    Math.floor(salary * 10 ** JOB_SEARCH_SALARY_DISPLAY_DIGIT_COUNT) %
    10 ** JOB_SEARCH_SALARY_DISPLAY_DIGIT_COUNT;
  let result = Math.floor(salary);

  // If there are any decimal digits, then trim to adjust.
  if (roundedDecimal > 0) {
    // Calculate the decimal factor to check how many decimals are allowed.
    const decimalFactor = Math.pow(
      10,
      JOB_SEARCH_SALARY_DISPLAY_DIGIT_COUNT - result.toString().length
    );
    // Combine them together.
    result += Math.floor(roundedDecimal / decimalFactor) / 100;
  }

  return result;
};

export { roundSalary };
