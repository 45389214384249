import { Skeleton, Stack } from "@mui/material";

interface SkeletonStatusProps {
  multiple?: boolean;
}

const SkeletonStatus = ({ multiple }: SkeletonStatusProps) => {
  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {[...Array(multiple ? 2 : 1)].map((_, idx) => {
        return (
          <Skeleton
            key={idx}
            animation="wave"
            variant="rounded"
            height="1.5rem"
            width="10ch"
          />
        );
      })}
    </Stack>
  );
};

export default SkeletonStatus;
