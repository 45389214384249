import { ReactNode } from "react";

import useUserProfile from "@hooks/database/useUserProfile";

import { ENVIRONMENT, USER_TYPE } from "@utils/config";

interface EnvironmentSpecificProps {
  env?:
    | typeof ENVIRONMENT[keyof typeof ENVIRONMENT]
    | Array<typeof ENVIRONMENT[keyof typeof ENVIRONMENT]>;
  children: ReactNode | string | number;
  adminAllowed?: boolean;
}

const EnvironmentSpecific = ({
  env = ENVIRONMENT.STAGE,
  children,
  adminAllowed = true
}: EnvironmentSpecificProps) => {
  const userProfile = useUserProfile();

  if (typeof env === "string") {
    env = [env];
  }
  if (env.includes(ENVIRONMENT.STAGE)) {
    env.push(ENVIRONMENT.PREVIEW);
  }
  env.push(ENVIRONMENT.LOCAL);
  env.push(ENVIRONMENT.TEST);

  if (process.env.REACT_APP_ENVIRONMENT) {
    const stringTypedEnv: Array<string> = env;

    if (stringTypedEnv.includes(process.env.REACT_APP_ENVIRONMENT)) {
      return <>{children}</>;
    }
  }

  if (adminAllowed) {
    if (userProfile.value?.user_type === USER_TYPE.SUPER_USER) {
      return <>{children}</>;
    }
  }

  return <></>;
};

export default EnvironmentSpecific;
