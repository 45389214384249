import { Grid, Stack } from "@mui/material";

import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonInput from "@skeletons/SkeletonInput";

import Paper from "@components/Paper";
import Typography from "@components/Typography";

import { intl } from "@utils/translate";

const SkeletonBasicInformationForm = () => {
  return (
    <>
      {/* Basic information section start */}
      <Paper>
        <Typography variant="h3">
          {intl.get("t_profile_basic_info_heading")}
        </Typography>
        <br />
        <Typography mt={2.5} mb={3} color="text.secondary">
          {intl.get("t_profile_basic_info_sub_heading")}
        </Typography>
        <br />
        <Grid container spacing={{ xs: 0, md: 3 }}>
          <Grid item xs={12} md={6}>
            <SkeletonInput />
          </Grid>
          <Grid item xs={12} md={6}>
            <SkeletonInput />
          </Grid>
        </Grid>
        <SkeletonInput />
        <SkeletonInput />
        <SkeletonInput />
        <Grid container spacing={{ xs: 0, md: 3 }}>
          <Grid item xs={12} md={6}>
            <SkeletonInput />
          </Grid>
          <Grid item xs={12} md={6}>
            <SkeletonInput />
          </Grid>
        </Grid>
        <SkeletonInput />
        <SkeletonInput />
      </Paper>
      {/* Basic information section end */}

      <Stack justifyContent="space-between" direction="row" mt={5.5}>
        <SkeletonButton />
        <SkeletonButton />
      </Stack>
    </>
  );
};

export default SkeletonBasicInformationForm;
