import intl from "react-intl-universal";

import {
  Check as CheckIcon,
  PriorityHigh as PriorityHighIcon
} from "@mui/icons-material";
import { Box, Stack } from "@mui/material";

import Typography from "@components/Typography";

interface UserProfileStatusFlags {
  isLoggedIn?: boolean;
  isEmailVerified?: boolean;
  isProfileSummaryCompleted?: boolean;
  isResumeCompleted?: boolean;
  isCandidate?: boolean;
}

interface JobStatusFlags {
  isActive?: boolean;
}

interface UserProfileStatusProps {
  userProfileStatus: UserProfileStatusFlags;
  jobStatus?: JobStatusFlags;
}

const ApplyButtonTooltipTitle = ({
  userProfileStatus,
  jobStatus
}: UserProfileStatusProps) => {
  if (!jobStatus?.isActive) {
    return <></>;
  }

  if (!userProfileStatus.isLoggedIn) {
    return <>{intl.get("t_job_apply_login_required")}</>;
  }

  if (userProfileStatus.isLoggedIn && !userProfileStatus.isCandidate) {
    return <>{intl.get("t_job_apply_employer")}</>;
  }

  if (userProfileStatus.isLoggedIn && userProfileStatus.isCandidate) {
    return (
      <Box p={1}>
        <Typography mb={2.5} variant="body1">
          {intl.get("t_job_apply_button_tooltip_heading")}
        </Typography>
        <Stack rowGap={1.5}>
          <Stack
            data-testid="job_apply_button_tooltip_email_verify_text"
            direction="row"
            columnGap={1}>
            {userProfileStatus.isEmailVerified ? (
              <CheckIcon color="inherit" />
            ) : (
              <PriorityHighIcon color="warning" />
            )}
            <Typography variant="body1">
              {intl.get("t_job_apply_button_tooltip_email_verify")}
            </Typography>
          </Stack>
          <Stack
            data-testid="job_apply_button_tooltip_profile_summary_complete_text"
            direction="row"
            columnGap={1}>
            {userProfileStatus.isProfileSummaryCompleted ? (
              <CheckIcon color="inherit" />
            ) : (
              <PriorityHighIcon color="warning" />
            )}
            <Typography variant="body1">
              {intl.get("t_job_apply_button_tooltip_profile_summary_complete")}
            </Typography>
          </Stack>
          <Stack
            data-testid="job_apply_button_tooltip_profile_resume_complete_text"
            direction="row"
            columnGap={1}>
            {userProfileStatus.isResumeCompleted ? (
              <CheckIcon />
            ) : (
              <PriorityHighIcon color="warning" />
            )}
            <Typography variant="body1">
              {intl.get("t_job_apply_button_tooltip_profile_resume_complete")}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
  }

  return <></>;
};

export default ApplyButtonTooltipTitle;
