import { useEffect, useMemo, useState } from "react";

import { Helmet } from "react-helmet";
import {
  matchPath,
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";

import Hotjar from "@hotjar/browser";
import {
  Container,
  createTheme,
  CssBaseline,
  Stack,
  styled,
  ThemeProvider,
  useMediaQuery
} from "@mui/material";
import dayjs from "dayjs";
import { httpsCallable } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import useForceUpdate from "use-force-update";

import Debugger from "@components/Debugger";
import Footer from "@components/Footer";
import GlobalNotificationBanner from "@components/GlobalNotificationBanner";
import Header from "@components/Header";
import UserStatusChecker from "@components/UserStatusChecker";
import VerySmallScreenDialog from "@components/VerySmallScreenDialog";

import CompanyContext from "@utils/components/CompanyDetailsContext";
import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";
import ToastContext from "@utils/components/ToastContext";
import UserProfileContext from "@utils/components/UserProfileContext";
import { ENVIRONMENT, LOCALE } from "@utils/config";
import { auth, functions } from "@utils/firebase";
import theme from "@utils/theme";
import translate, {
  intl,
  LOCALE_DATA,
  LOCALE_DATA_LOWERCASE
} from "@utils/translate";

import "@assets/styles/App.css";

const siteId = Number(process.env.REACT_APP_HOTJAR_SITE_ID ?? "");
const hotjarVersion = Number(process.env.REACT_APP_HOTJAR_VERSION ?? "");

Hotjar.init(siteId, hotjarVersion);

const SECONDARY_HEADER_PATHS = [
  "/profile/*",
  "/employers/dashboard",
  "/employers/jobs/*",
  "/employers/settings/*",
  "/privacy-policy",
  "/employer-terms-and-conditions",
  "/user-terms-and-conditions"
];

const HIDE_HEADER_PATHS = [
  "/forgot-password/*",
  "/change-password/*",
  "/not-found",
  "/notifications",
  "/employers/notifications"
];

const HIDE_FOOTER_PATHS = [
  "/forgot-password/*",
  "/change-password/*",
  "/not-found",
  "/conversations/*",
  "/employers/conversations/*",
  "/notifications",
  "/employers/notifications"
];

const StyledContainer = styled(Container)({
  marginTop: "1.5rem",
  marginBottom: "1.5rem",
  zIndex: "5"
});

function App() {
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [user, loading] = useAuthState(auth);
  const forceUpdate = useForceUpdate();

  const { pathname: originalPathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const setUserMetadata = httpsCallable(functions, "setUserMetadata");

  const pathname = translate.getNonLocalizedURL(originalPathname);

  const isJobsPath = matchPath("/profile/jobs/*", pathname);
  const isHomePagePath = matchPath("/", pathname);
  const isEmployerHomePagePath = matchPath("/employers", pathname);
  const isNotFoundPagePath = matchPath("/not-found", pathname);
  const isForgotPasswordPath = matchPath("/forgot-password/*", pathname);
  const isChangePasswordPath = matchPath("/change-password/*", pathname);
  const isFaqPath = matchPath("/frequently-asked-questions", pathname);
  const isPrivacyPolicyPath = matchPath("/privacy-policy", pathname);
  const isTNCPath = matchPath("/user-terms-and-conditions", pathname);
  const isProfilePath = matchPath("/profile/*", pathname);
  const isEmployerConversationsPath = matchPath(
    "/employers/conversations",
    pathname
  );
  const isSingleEmployerConversationPath = matchPath(
    "/employers/conversations/:conversationId",
    pathname
  );
  const isCandidateConversationsPath = matchPath("/conversations", pathname);
  const isSingleCandidateConversationPath = matchPath(
    "/conversations/:conversationId",
    pathname
  );
  const isCandidateNotificationsPath = matchPath("/notifications", pathname);
  const isEmployerNotificationsPath = matchPath(
    "/employers/notifications",
    pathname
  );

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const showSecondaryHeader = SECONDARY_HEADER_PATHS.some((singlePath) =>
    matchPath(singlePath, pathname)
  );
  const isHideHeader = HIDE_HEADER_PATHS.some((singlePath) =>
    matchPath(singlePath, pathname)
  );
  const isHideFooter = HIDE_FOOTER_PATHS.some((singlePath) =>
    matchPath(singlePath, pathname)
  );

  const languageChanged = () => {
    forceUpdate();
    setUserMetadata({ currentLocale: translate.getCurrentLocale() }).catch(
      (_) => {
        /* no-op */
      }
    );
  };

  const isCurrentLocalIsJa = translate.getCurrentLocale() === LOCALE.JA;

  useEffect(() => {
    translate.initializeIntl();
    setUserMetadata({ currentLocale: translate.getCurrentLocale() }).catch(
      (_) => {
        /* no-op */
      }
    );
    setLoaded(true);
    translate.addLanguageChangedListener(languageChanged);
    return () => {
      translate.removeLanguageChangedListener(languageChanged);
    };
  }, []);

  useEffect(() => {
    if (user) {
      const returnURL = searchParams.get("return_url") ?? "";
      const isLoginRequired = searchParams.get("login_required") ?? "";

      if (returnURL) {
        searchParams.delete("return_url");
        searchParams.delete("login_required");
        searchParams.delete("mode");
        navigate(returnURL + "?" + searchParams.toString());
      } else if (isLoginRequired) {
        searchParams.delete("login_required");
        searchParams.delete("mode");
        navigate(`/${translate.getCurrentLocale()}/`);
      }
    }
  }, [user]);

  const fontFamily = [
    isCurrentLocalIsJa ? "Noto Sans JP" : "Poppins",
    "sans-serif"
  ].join(",");

  const themeWithLocale = useMemo(
    () =>
      createTheme({
        ...theme,
        components: {
          ...theme.components,
          MuiTypography: {
            ...theme.components?.MuiTypography,
            styleOverrides: {
              ...theme.components?.MuiTypography?.styleOverrides,
              root: {
                ...((theme.components?.MuiTypography?.styleOverrides?.root ??
                  {}) as object),
                fontFamily
              }
            }
          },
          MuiButtonBase: {
            ...theme.components?.MuiButtonBase,
            styleOverrides: {
              ...theme.components?.MuiButtonBase?.styleOverrides,
              root: {
                ...((theme.components?.MuiButtonBase?.styleOverrides?.root ??
                  {}) as object),
                fontFamily
              }
            }
          },
          MuiButton: {
            ...theme.components?.MuiButton,
            styleOverrides: {
              ...theme.components?.MuiButton?.styleOverrides,
              root: {
                ...((theme.components?.MuiButton?.styleOverrides?.root ??
                  {}) as object),
                fontFamily
              }
            }
          },
          MuiInputBase: {
            ...theme.components?.MuiInputBase,
            styleOverrides: {
              ...theme.components?.MuiInputBase?.styleOverrides,
              root: {
                ...((theme.components?.MuiInputBase?.styleOverrides?.root ??
                  {}) as object),
                fontFamily
              }
            }
          },
          MuiFormLabel: {
            ...theme.components?.MuiFormLabel,
            styleOverrides: {
              ...theme.components?.MuiFormLabel?.styleOverrides,
              root: {
                ...((theme.components?.MuiFormLabel?.styleOverrides?.root ??
                  {}) as object),
                fontFamily
              }
            }
          },
          MuiFormControlLabel: {
            ...theme.components?.MuiFormControlLabel,
            styleOverrides: {
              ...theme.components?.MuiFormControlLabel?.styleOverrides,
              root: {
                ...((theme.components?.MuiFormControlLabel?.styleOverrides
                  ?.root ?? {}) as object),
                fontFamily
              }
            }
          },
          MuiDivider: {
            ...theme.components?.MuiDivider,
            styleOverrides: {
              ...theme.components?.MuiDivider?.styleOverrides,
              root: {
                ...((theme.components?.MuiDivider?.styleOverrides?.root ??
                  {}) as object),
                fontFamily
              }
            }
          },
          MuiListItem: {
            ...theme.components?.MuiListItem,
            styleOverrides: {
              ...theme.components?.MuiListItem?.styleOverrides,
              root: {
                ...((theme.components?.MuiListItem?.styleOverrides?.root ??
                  {}) as object),
                fontFamily
              }
            }
          },
          MuiMenuItem: {
            ...theme.components?.MuiMenuItem,
            styleOverrides: {
              ...theme.components?.MuiMenuItem?.styleOverrides,
              root: {
                ...((theme.components?.MuiMenuItem?.styleOverrides?.root ??
                  {}) as object),
                fontFamily
              }
            }
          },
          MuiTab: {
            ...theme.components?.MuiTab,
            styleOverrides: {
              ...theme.components?.MuiTab?.styleOverrides,
              root: {
                ...((theme.components?.MuiTab?.styleOverrides?.root ??
                  {}) as object),
                fontFamily
              }
            }
          }
        }
      }),
    [translate.getCurrentLocale()]
  );

  return (
    <div
      className={
        isFaqPath || isPrivacyPolicyPath || isTNCPath || isProfilePath
          ? "App header-bg-included"
          : "App"
      }>
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="Content-Type" content="charset=UTF-8" />
        <meta lang={translate.getCurrentLocaleShort()} />
        <meta
          property="og:locale"
          content={translate.getCurrentLocaleShort()}
        />
        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow"></meta>
        {/* Leave as empty for now, to be fixed by Aayush later */}
        <meta name="google-site-verification" content="" />
        <title>{intl.get("t_seo_candidate_home_page_title")}</title>
        <link rel="canonical" href={process.env.REACT_APP_FRONTEND_URL} />
        <meta
          name="description"
          content={intl.get("t_seo_candidate_home_page_description")}
        />

        <meta property="og:url" content={process.env.REACT_APP_FRONTEND_URL} />
        <meta property="og:type" content="website" />

        <meta
          property="og:title"
          content={intl.get("t_seo_candidate_home_page_title")}
        />
        <meta
          property="og:description"
          content={intl.get("t_seo_candidate_home_page_description")}
        />
        {/* Image for facebook */}
        <meta
          property="og:image"
          content={`${
            process.env.REACT_APP_FRONTEND_URL
          }/assets/seo_resources/OGP_Image_${translate.getCurrentLocaleShort()}.png`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        {/* Time in UNIX seconds */}
        <meta
          property="og:updated_time"
          content={`${dayjs(
            process.env.REACT_APP_RELEASED_TIMESTAMP
          ).valueOf()}`}
        />

        <meta
          name="twitter:title"
          content={intl.get("t_seo_candidate_home_page_title")}
        />
        <meta
          name="twitter:description"
          content={intl.get("t_seo_candidate_home_page_description")}
        />
        {/* Image for Twitter */}
        <meta
          name="twitter:image"
          content={`${
            process.env.REACT_APP_FRONTEND_URL
          }/assets/seo_resources/OGP_Image_${translate.getCurrentLocaleShort()}.png`}
        />
        <meta name="twitter:image:alt" content={intl.get("t_seo_og_img_alt")} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@tokhimo" />

        {/* Canonical Link */}
        {Object.keys(LOCALE_DATA).map((singleLocale) => {
          const url = new URL(window.location.href);
          const pathSegments = url.pathname
            .split("/")
            .filter((segment) => segment !== "");

          if (
            pathSegments[0] &&
            Object.keys(LOCALE_DATA_LOWERCASE).includes(
              pathSegments[0].toLowerCase()
            )
          ) {
            // Locale is present, update it
            pathSegments[0] = singleLocale;
          } else {
            // Locale is not present, insert it
            pathSegments.unshift(singleLocale);
          }
          url.pathname = "/" + pathSegments.join("/");

          let frontendUrl = process.env.REACT_APP_FRONTEND_URL ?? "";
          frontendUrl = frontendUrl.replace("https://", "");
          url.hostname = frontendUrl;
          url.port = "";

          return (
            <link
              rel="alternate"
              hrefLang={singleLocale}
              key={singleLocale}
              href={url.toString()}
            />
          );
        })}
      </Helmet>
      <ThemeProvider theme={themeWithLocale}>
        <CssBaseline />
        <ScrollRestoration
          getKey={(location) => {
            const nonLocalizedURL = translate.getNonLocalizedURL(
              location.pathname
            );
            if (
              nonLocalizedURL.startsWith("/search") ||
              nonLocalizedURL.startsWith("/jobs")
            ) {
              if (isSmallScreen) {
                return location.pathname + encodeURIComponent(location.search);
              } else {
                return encodeURIComponent(location.search);
              }
            } else {
              return location.key;
            }
          }}
        />
        <UserProfileContext>
          <CompanyContext>
            <ToastContext>
              <VerySmallScreenDialog />
              {isLoaded ? (
                <>
                  <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                    <Debugger />
                  </EnvironmentSpecific>
                  <Stack
                    overflow="hidden"
                    direction="column"
                    minHeight="100vh"
                    bgcolor="background.default">
                    <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                      <GlobalNotificationBanner
                        message={intl.get("t_maintenance_banner_message")}
                        variant="error"
                      />
                    </EnvironmentSpecific>
                    <UserStatusChecker />
                    {!isHideHeader ? (
                      <>
                        {!(
                          isSmallScreen &&
                          (isSingleEmployerConversationPath ||
                            isSingleCandidateConversationPath)
                        ) ? (
                          <Header
                            isLoggedIn={!!user && !loading}
                            variant={
                              showSecondaryHeader && !isJobsPath
                                ? "secondary"
                                : "primary"
                            }
                            isSticky={
                              !!isHomePagePath || !!isEmployerHomePagePath
                            }
                          />
                        ) : (
                          false
                        )}
                      </>
                    ) : (
                      false
                    )}
                    {/* Homepage, Employer Homepage, ForgotPasswordPage & 404 page are not in Container 
                    because there are sections with full width background shapes, etc. */}
                    {isHomePagePath ||
                    isEmployerHomePagePath ||
                    ((isEmployerConversationsPath ||
                      isCandidateConversationsPath ||
                      isSingleEmployerConversationPath ||
                      isSingleCandidateConversationPath) &&
                      isSmallScreen) ||
                    isForgotPasswordPath ||
                    isChangePasswordPath ||
                    isNotFoundPagePath ||
                    isCandidateNotificationsPath ||
                    isEmployerNotificationsPath ? (
                      <Outlet />
                    ) : (
                      <StyledContainer>
                        <Outlet />
                      </StyledContainer>
                    )}
                    {!isHideFooter ? (
                      isHomePagePath || isEmployerHomePagePath ? (
                        <Footer isBgTransparent={true} />
                      ) : (
                        <Footer />
                      )
                    ) : (
                      false
                    )}
                  </Stack>
                </>
              ) : (
                false
              )}
            </ToastContext>
          </CompanyContext>
        </UserProfileContext>
      </ThemeProvider>
    </div>
  );
}

export default App;
