// Note: Mobile only component

import { ReactNode, useEffect, useState } from "react";

import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  AccountBalanceWalletRounded as AccountBalanceWalletRoundedIcon,
  Apartment as ApartmentIcon,
  ChatRounded as ChatRoundedIcon,
  CloseRounded as CloseRoundedIcon,
  ContentPasteRounded as ContentPasteRoundedIcon,
  DashboardRounded as DashboardRoundedIcon,
  ExitToAppRounded as ExitToAppRoundedIcon,
  FavoriteRounded as FavoriteRoundedIcon,
  MarkEmailReadRounded as MarkEmailReadRoundedIcon,
  Notifications as NotificationsIcon,
  PersonRounded as PersonRoundedIcon,
  SearchRounded as SearchRoundedIcon,
  SettingsRounded as SettingsRoundedIcon,
  WorkRounded as WorkRoundedIcon
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  Link as MuiLink,
  Stack,
  styled
} from "@mui/material";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";

import LanguageSwitcher from "@components/Header/LanguageSwitcher";
import SignInSignUpDialog from "@components/Header/SignInSignUpDialog";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useToast from "@hooks/useToast";

import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";
import {
  ENVIRONMENT,
  FIRESTORE_COLLECTIONS,
  LOCALE,
  NOTIFICATION_STATUS,
  UNREAD_NOTIFICATION_COUNT_BADGE_MAX_LIMIT
} from "@utils/config";
import { auth, db } from "@utils/firebase";
import theme from "@utils/theme";
import translate, { intl } from "@utils/translate";

import Logo from "@assets/images/Logo.png";

interface ProfileLinkProps {
  "to": string;
  "title": string;
  "icon": ReactNode;
  "onClick"?: () => void;
  "data-testid"?: string;
}

const StyledDrawer = styled(Drawer)({
  "& .MuiDrawer-paper": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: "100%"
  }
});

const StyledSignOut = styled(Stack)({
  "cursor": "pointer",
  "color": theme.palette.text.primary,
  ":hover": {
    color: theme.palette.primary.main
  }
});

const StyledLogo = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: 52,
    width: 52
  },
  [theme.breakpoints.up("md")]: {
    height: 72,
    width: 72
  },
  "& .MuiAvatar-img": {
    objectFit: "contain"
  }
}));

const StyledCloseIcon = styled(CloseRoundedIcon)(({ theme }) => ({
  "fontSize": 24,
  "color": theme.palette.text.secondary,
  ":hover": {
    color: theme.palette.text.primary,
    backgroundColor: "transparent"
  },
  "cursor": "pointer"
}));

const ProfileLink = ({
  to,
  icon,
  title,
  onClick,
  "data-testid": dataTestId
}: ProfileLinkProps) => {
  const location = useLocation();
  const path = translate.getNonLocalizedURL(location.pathname);
  to = translate.getNonLocalizedURL(to);

  const isActive = () => {
    // Special handling for "/employers/settings"
    if (to === "/employers/settings") {
      // Active if it's an exact match OR a sub-path but NOT the "/plan" sub-path
      return path === to || (path.startsWith(to) && !path.includes("/plan"));
    }

    // For all other links, use startsWith
    return path.startsWith(to);
  };

  return (
    <MuiLink
      underline="none"
      component={NavLink}
      to={to}
      onClick={onClick}
      data-testid={dataTestId}
      sx={{
        "color": isActive()
          ? theme.palette.primary.main
          : theme.palette.text.primary,
        ":hover": {
          color: theme.palette.primary.main
        }
      }}>
      <Stack direction="row" alignItems="center" spacing={1.8}>
        {icon}
        <Typography variant="subtitle2">{title}</Typography>
      </Stack>
    </MuiLink>
  );
};

interface HamburgerMenuProps {
  openMobileMenu: boolean;
  isLoggedIn: boolean;
  isEmployer: boolean;
  isProfileNotCompleted: boolean;
  hideLanguageSwitcher: boolean;
  showProfileMenuLinks: boolean;
  variant?: "primary" | "secondary";
  handleMenuClose: () => void;
}

const HamburgerMenu = ({
  openMobileMenu,
  handleMenuClose,
  isEmployer,
  isLoggedIn,
  hideLanguageSwitcher,
  showProfileMenuLinks,
  isProfileNotCompleted,
  variant
}: HamburgerMenuProps) => {
  const [user] = useAuthState(auth);
  const userId = user?.uid ?? "";
  const companyDetails = useCompanyDetails();
  const navigate = useNavigate();
  const [signOut] = useSignOut(auth);
  const [totalNotificationCount, setTotalNotificationCount] =
    useState<number>(0);
  const toast = useToast();

  const currentLocale = translate.getCurrentLocale();

  const creditsAvailable = companyDetails.value?.total_credits_available ?? 0;

  useEffect(() => {
    try {
      const notificationRef = collection(
        db,
        FIRESTORE_COLLECTIONS.NOTIFICATIONS
      );
      const q = query(
        notificationRef,
        where("userId", "==", userId),
        where("status", "==", NOTIFICATION_STATUS.UNREAD)
      );
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          setTotalNotificationCount(snapshot.size);
        },
        () => {
          toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
        }
      );
      return () => unsubscribe();
    } catch (err) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  }, [userId]);

  return (
    <StyledDrawer open={openMobileMenu} onClose={handleMenuClose}>
      <Box px={2.5} py={1.5}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2.5}>
          <StyledLogo
            variant="square"
            src={Logo}
            alt={intl.get("t_alt_tokhimo_job_logo")}
          />
          <StyledCloseIcon onClick={handleMenuClose} />
        </Stack>

        <Stack height="calc(100vh - 6rem)">
          <Stack spacing={3}>
            {/* Links start */}
            {isEmployer ? (
              <>
                <ProfileLink
                  title={intl.get("t_header_navlink_dashboard")}
                  to={`/${translate.getCurrentLocale()}/employers/dashboard`}
                  icon={<DashboardRoundedIcon fontSize="medium" />}
                  onClick={handleMenuClose}
                  data-testid="employer_hamburger_menu_dashboard_link"
                />
                <ProfileLink
                  title={intl.get("t_header_navlink_search_candidates")}
                  to={`/${translate.getCurrentLocale()}/employers/search`}
                  icon={<SearchRoundedIcon fontSize="medium" />}
                  onClick={handleMenuClose}
                  data-testid="employer_hamburger_menu_search_candidates_link"
                />
                <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                  {isLoggedIn ? (
                    <ProfileLink
                      to={`/${translate.getCurrentLocale()}/employers/conversations`}
                      title={intl.get("t_general_messages")}
                      icon={<ChatRoundedIcon fontSize="medium" />}
                      onClick={handleMenuClose}
                      data-testid="employer_hamburger_menu_conversations_link"
                    />
                  ) : (
                    false
                  )}
                </EnvironmentSpecific>
                <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                  {isLoggedIn ? (
                    <ProfileLink
                      to="/employers/notifications"
                      title={intl.get("t_general_notifications")}
                      icon={<NotificationsIcon fontSize="medium" />}
                      onClick={handleMenuClose}
                    />
                  ) : (
                    false
                  )}
                </EnvironmentSpecific>
              </>
            ) : (
              <>
                <ProfileLink
                  title={intl.get("t_general_find_jobs")}
                  to={`/${translate.getCurrentLocale()}/search`}
                  icon={<SearchRoundedIcon fontSize="medium" />}
                  onClick={handleMenuClose}
                  data-testid="general_hamburger_menu_find_jobs_link"
                />
                <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                  {isLoggedIn ? (
                    <ProfileLink
                      to={`/${translate.getCurrentLocale()}/conversations`}
                      title={intl.get("t_general_messages")}
                      icon={<ChatRoundedIcon fontSize="medium" />}
                      onClick={handleMenuClose}
                      data-testid="general_hamburger_menu_conversations_link"
                    />
                  ) : (
                    false
                  )}
                </EnvironmentSpecific>
                <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                  {isLoggedIn ? (
                    <ProfileLink
                      to="/notifications"
                      title={intl.get("t_general_notifications")}
                      icon={
                        <Badge
                          max={UNREAD_NOTIFICATION_COUNT_BADGE_MAX_LIMIT}
                          color="secondary"
                          badgeContent={totalNotificationCount}>
                          <NotificationsIcon fontSize="medium" />
                        </Badge>
                      }
                      onClick={handleMenuClose}
                    />
                  ) : (
                    false
                  )}
                </EnvironmentSpecific>
                {!isLoggedIn ? (
                  <ProfileLink
                    title={intl.get("t_header_navlink_employers")}
                    to={`/${translate.getCurrentLocale()}/employers`}
                    icon={<ApartmentIcon fontSize="medium" />}
                    onClick={handleMenuClose}
                    data-testid="general_hamburger_menu_employers_link"
                  />
                ) : (
                  false
                )}
              </>
            )}
            {/* Links end */}

            {/* Profile Menu start */}
            {showProfileMenuLinks ? (
              <>
                <Divider />
                {isEmployer ? (
                  <>
                    {/* TODO: Remove env specific after Saved Candidate Page implementation */}
                    <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                      <ProfileLink
                        to={`/${translate.getCurrentLocale()}/employers/settings/plan`}
                        title={intl.get("t_profile_menu_credits", {
                          credits: creditsAvailable
                        })}
                        icon={
                          <AccountBalanceWalletRoundedIcon fontSize="medium" />
                        }
                        onClick={handleMenuClose}
                        data-testid="employer_hamburger_menu_credits_link"
                      />
                      <ProfileLink
                        to={`/${translate.getCurrentLocale()}/employers/saved-candidates`}
                        title={intl.get("t_profile_menu_saved_candidate")}
                        icon={<FavoriteRoundedIcon fontSize="medium" />}
                        onClick={handleMenuClose}
                        data-testid="employer_hamburger_menu_saved_candidates_link"
                      />
                    </EnvironmentSpecific>
                    <ProfileLink
                      to={`/${translate.getCurrentLocale()}/employers/settings`}
                      title={intl.get("t_profile_menu_account_setting")}
                      icon={<SettingsRoundedIcon fontSize="medium" />}
                      onClick={handleMenuClose}
                      data-testid="employer_hamburger_menu_account_setting_link"
                    />
                  </>
                ) : (
                  <>
                    <ProfileLink
                      to={`/${translate.getCurrentLocale()}/profile/`}
                      title={intl.get("t_profile_menu_profile")}
                      icon={<PersonRoundedIcon fontSize="medium" />}
                      onClick={handleMenuClose}
                      data-testid="candidate_hamburger_menu_profile_link"
                    />
                    <ProfileLink
                      to={`/${translate.getCurrentLocale()}/profile/resume`}
                      title={intl.get("t_profile_menu_my_resume")}
                      icon={<ContentPasteRoundedIcon fontSize="medium" />}
                      onClick={handleMenuClose}
                      data-testid="candidate_hamburger_menu_my_resume_link"
                    />
                    <ProfileLink
                      to={`/${translate.getCurrentLocale()}/profile/jobs/bookmarked`}
                      title={intl.get("t_profile_menu_saved_jobs")}
                      icon={<FavoriteRoundedIcon fontSize="medium" />}
                      onClick={handleMenuClose}
                      data-testid="candidate_hamburger_menu_saved_jobs_link"
                    />
                    <ProfileLink
                      to={`/${translate.getCurrentLocale()}/profile/jobs/applied`}
                      title={intl.get("t_profile_menu_applied_jobs")}
                      icon={<WorkRoundedIcon fontSize="medium" />}
                      onClick={handleMenuClose}
                      data-testid="candidate_hamburger_menu_applied_jobs_link"
                    />
                    <ProfileLink
                      to={`/${translate.getCurrentLocale()}/profile/jobs/invited`}
                      title={intl.get("t_profile_menu_invited_jobs")}
                      icon={<MarkEmailReadRoundedIcon fontSize="medium" />}
                      onClick={handleMenuClose}
                      data-testid="candidate_hamburger_menu_invited_jobs_link"
                    />
                    <ProfileLink
                      to={`/${translate.getCurrentLocale()}/profile/settings`}
                      title={intl.get("t_profile_menu_account_setting")}
                      icon={<SettingsRoundedIcon fontSize="medium" />}
                      onClick={handleMenuClose}
                      data-testid="candidate_hamburger_menu_account_setting_link"
                    />
                  </>
                )}
                <StyledSignOut
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  onClick={() => {
                    signOut();
                    handleMenuClose();
                    navigate(
                      isEmployer
                        ? `/${translate.getCurrentLocale()}/employers`
                        : `/${translate.getCurrentLocale()}/`
                    );
                  }}>
                  <ExitToAppRoundedIcon fontSize="medium" />
                  <Typography variant="subtitle2">
                    {intl.get("t_general_signout")}
                  </Typography>
                </StyledSignOut>
              </>
            ) : (
              false
            )}
            {/* Profile Menu end */}

            {/* Language Switcher start*/}
            {!hideLanguageSwitcher ? (
              <>
                <Divider />
                <LanguageSwitcher handleMainMenuClose={handleMenuClose} />
              </>
            ) : (
              false
            )}
            {/* Language Switcher end*/}

            {/* Update Resume button start */}
            {showProfileMenuLinks && !isEmployer ? (
              <Button
                variant="contained"
                color={variant}
                size="large"
                onClick={() => {
                  navigate(
                    "/" +
                      translate.getCurrentLocale() +
                      "/" +
                      isProfileNotCompleted
                      ? "profile"
                      : currentLocale === LOCALE.EN
                      ? "profile/resume/en-cv"
                      : "profile/resume/ja-cv"
                  );
                  handleMenuClose();
                }}>
                {intl.get(
                  isProfileNotCompleted
                    ? "t_general_update_profile"
                    : "t_general_update_resume"
                )}
              </Button>
            ) : (
              false
            )}
            {/* Update Resume button end */}

            {/* Login button start */}
            {!isLoggedIn ? (
              <SignInSignUpDialog
                initiator={
                  <Button variant="contained" color={variant} size="large">
                    {intl.get("t_general_signin")}
                  </Button>
                }
              />
            ) : (
              false
            )}
            {/* Login button end */}
          </Stack>
        </Stack>
      </Box>
    </StyledDrawer>
  );
};

export default HamburgerMenu;
