import { Avatar, styled } from "@mui/material";

import { colorPalette } from "@utils/theme";

import Briefcase from "@assets/images/Briefcase.png";
import Envelope from "@assets/images/Envelope.png";
import MagnifyingGlass from "@assets/images/Magnifying_glass.png";
import Rocket from "@assets/images/Rocket.png";
import Shield from "@assets/images/Shield.png";
import ThumbsUp from "@assets/images/Thumbs_up.png";
import WritingHand from "@assets/images/Writing_hand.png";

export interface HomePageIconProps {
  image:
    | "briefcase"
    | "envelope"
    | "magnifying"
    | "rocket"
    | "shield"
    | "thumbsup"
    | "writing";
  alt: string;
}

const imageToSrcMapping = {
  briefcase: Briefcase,
  envelope: Envelope,
  magnifying: MagnifyingGlass,
  rocket: Rocket,
  shield: Shield,
  thumbsup: ThumbsUp,
  writing: WritingHand
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: colorPalette.blue[20],
  borderRadius: "0.25rem",
  [theme.breakpoints.down("md")]: {
    height: "3rem",
    width: "3rem"
  },
  [theme.breakpoints.up("md")]: {
    height: "3.5rem",
    width: "3.5rem"
  }
}));

const StyledImage = styled("img")({
  height: "2.5rem",
  width: "2.5rem"
});

const HomePageIcon = ({ image, alt }: HomePageIconProps) => {
  return (
    <StyledAvatar variant="square">
      <StyledImage src={imageToSrcMapping[image]} alt={alt} />
    </StyledAvatar>
  );
};

export default HomePageIcon;
