import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { Box, DialogActions, DialogContent, Stack } from "@mui/material";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import * as yup from "yup";

import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import Dialog from "@components/Dialog";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import {
  COMPANY_ACCOUNT_DEACTIVATION_REASONS,
  COMPANY_ACCOUNT_DEACTIVATION_REASONS_T_LABELS,
  DIALOG_ACTION,
  LOCALE
} from "@utils/config";
import { auth, functions } from "@utils/firebase";
import { prepareMultiLingual } from "@utils/multiLingual";
import { intl, intlEn, intlJa } from "@utils/translate";

interface DeactivateAccountData {
  deactivationReasons: Array<
    keyof typeof COMPANY_ACCOUNT_DEACTIVATION_REASONS_T_LABELS
  >;
}

interface DeactivateResult {
  success: boolean;
  message?: string;
}

const EmployerAccountManagement = () => {
  const userProfile = useUserProfile();
  const companyDetails = useCompanyDetails();
  const [user] = useAuthState(auth);
  const [signOut] = useSignOut(auth);
  const toast = useToast();

  const [deactivateCompany, isDeactivatingCompany] = useHttpsCallable(
    functions,
    "deactivateCompany"
  );
  const [deactivateManagerAccount, isDeactivatingManagerAccount] =
    useHttpsCallable(functions, "deactivateManagerAccount");

  const companyId = userProfile?.value?.company_id;
  const userId = user?.uid;
  const isOwner = userId === companyDetails?.value?.owner?.user_id;

  const COMPANY_ACCOUNT_DEACTIVATION_REASONS_OPTIONS = useOptions(
    COMPANY_ACCOUNT_DEACTIVATION_REASONS,
    COMPANY_ACCOUNT_DEACTIVATION_REASONS_T_LABELS
  ).map((singleOption) => {
    return { value: singleOption.key, label: singleOption.label };
  });

  const schema = yup.object({
    deactivationReasons: yup
      .array()
      .min(
        1,
        intl.get("t_error_required", { field: intl.get("t_general_reasons") })
      )
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      deactivationReasons: []
    },
    reValidateMode: "onChange"
  });
  const { handleSubmit, control, setValue, getValues } = methods;

  const handleCompanyAccountDelete = async (
    formData: DeactivateAccountData,
    handleAgree: () => void
  ) => {
    handleAgree(); // To close modal

    const { deactivationReasons } = formData;
    const allDeactivationReasons = [...deactivationReasons]?.map(
      (singleDeactivationReason) => {
        return {
          en: intlEn.get(
            COMPANY_ACCOUNT_DEACTIVATION_REASONS_T_LABELS[
              singleDeactivationReason as keyof typeof COMPANY_ACCOUNT_DEACTIVATION_REASONS_T_LABELS
            ]
          ),
          ja: intlJa.get(
            COMPANY_ACCOUNT_DEACTIVATION_REASONS_T_LABELS[
              singleDeactivationReason as keyof typeof COMPANY_ACCOUNT_DEACTIVATION_REASONS_T_LABELS
            ]
          )
        };
      }
    );

    try {
      const result = await deactivateCompany({
        companyId: companyId,
        deactivationReasons: allDeactivationReasons
      });

      const data = result?.data as DeactivateResult;

      if (data.success) {
        toast.kampai(intl.get("t_toast_success_delete_company"), "success");
        signOut();
      } else {
        toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
      }
    } catch (e) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  const handleManagerAccountDelete = async (
    reason: keyof typeof DIALOG_ACTION
  ) => {
    if (reason === DIALOG_ACTION.AGREE) {
      try {
        const result = await deactivateManagerAccount({
          userId,
          deactivationReasons: prepareMultiLingual(
            "Manager Deactivated",
            LOCALE.EN
          )
        });

        const data = result?.data as DeactivateResult;

        if (data.success) {
          toast.kampai(intl.get("t_toast_success_delete_account"), "success");
          signOut();
        } else {
          toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
        }
      } catch (e) {
        toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
      }
    }
  };

  return (
    <Stack alignItems="flex-end">
      {/* Deactivate User (Manager) Account */}
      <Dialog
        title={intl.get("t_account_deletion_modal_title")}
        maxWidth="sm"
        isStopEventPropagation
        initiator={
          <Button
            variant="text"
            color="error"
            disabled={isOwner}
            loading={isDeactivatingManagerAccount}>
            {intl.get("t_account_setting_delete_account_button")}
          </Button>
        }
        onClose={handleManagerAccountDelete}>
        {(handleAgree, handleCancel) => (
          <>
            <DialogContent>
              <Stack
                justifyContent="center"
                alignItems="center"
                gap={2}
                width="100%"
                textAlign="center">
                <CancelRoundedIcon color="error" fontSize="large" />
                <Typography variant="h5">
                  {intl.get("t_account_deletion_modal_confirmation_title")}
                </Typography>
                <Typography color="text.secondary">
                  {intl.getHTML(
                    "t_account_deletion_modal_confirmation_description"
                  )}
                </Typography>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%">
                <Button handleClick={handleCancel} variant="outlined">
                  {intl.get("t_general_cancel")}
                </Button>
                <Button handleClick={handleAgree} color="error">
                  {intl.get("t_general_confirm")}
                </Button>
              </Stack>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* Deactivate Company Account */}
      <Dialog
        title={intl.get("t_company_deletion_modal_title")}
        maxWidth="sm"
        isStopEventPropagation
        initiator={
          <Button
            variant="text"
            color="error"
            disabled={!isOwner}
            loading={isDeactivatingCompany}>
            {intl.get("t_account_setting_delete_company_button")}
          </Button>
        }>
        {(handleAgree, handleCancel) => (
          <Box
            noValidate
            component="form"
            onSubmit={handleSubmit(() =>
              handleCompanyAccountDelete(getValues(), handleAgree)
            )}>
            <DialogContent>
              <Stack
                justifyContent="center"
                alignItems="center"
                gap={2}
                width="100%"
                textAlign="center">
                <CancelRoundedIcon color="error" fontSize="large" />
                <Typography variant="h5">
                  {intl.get("t_company_deletion_modal_confirmation_title")}
                </Typography>
                <Typography color="text.secondary">
                  {intl.getHTML(
                    "t_company_deletion_modal_confirmation_description"
                  )}
                </Typography>

                <Box alignSelf="flex-start">
                  <Checkbox
                    size="medium"
                    name="deactivationReasons"
                    control={control}
                    setValue={setValue}
                    options={COMPANY_ACCOUNT_DEACTIVATION_REASONS_OPTIONS}
                  />
                </Box>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%">
                <Button handleClick={handleCancel} variant="outlined">
                  {intl.get("t_general_cancel")}
                </Button>
                <Button type="submit" color="error">
                  {intl.get("t_general_confirm")}
                </Button>
              </Stack>
            </DialogActions>
          </Box>
        )}
      </Dialog>
    </Stack>
  );
};

export default EmployerAccountManagement;
