import { useEffect } from "react";

import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  styled,
  Typography
} from "@mui/material";
import useForceUpdate from "use-force-update";

import Paper from "@components/Paper";
import SideMenu from "@components/SideMenu";

import translate, { intl } from "@utils/translate";

import Logo from "@assets/images/Logo.png";

const StyledList = styled(List)({ listStyle: "decimal", left: 15 });

const StyledListItem = styled(ListItem)({
  display: "list-item"
});

const StyledLogo = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: 62,
    width: 62
  },
  [theme.breakpoints.up("sm")]: {
    height: 55,
    width: 55
  },
  [theme.breakpoints.up("lg")]: {
    height: 62,
    width: 62
  },
  "& .MuiAvatar-img": {
    objectFit: "contain"
  }
}));

const PrivacyPolicy = () => {
  const menuLinks = [
    {
      label: intl.get("t_recruiter_term"),
      redirectLink: "/employer-terms-and-conditions"
    },
    {
      label: intl.get("t_account_setting_side_menu_user_terms"),
      redirectLink: "/user-terms-and-conditions"
    },
    {
      label: intl.get("t_account_setting_side_menu_privacy_policy"),
      redirectLink: "/privacy-policy"
    }
  ];
  const forceUpdate = useForceUpdate();

  const languageChanged = () => {
    forceUpdate();
  };

  useEffect(() => {
    translate.addLanguageChangedListener(languageChanged);
    return () => {
      translate.removeLanguageChangedListener(languageChanged);
    };
  }, []);
  return (
    <>
      <Grid container mt={4} rowGap={3}>
        <Grid item xs={12} md={3} pr={{ xs: 0, md: 2 }}>
          <SideMenu menuLinks={menuLinks} />
        </Grid>
        <Grid item xs={12} md={9} pl={{ xs: 0, md: 2 }}>
          <Paper>
            <Typography variant="h3" mb={3} id="data-for-ssg">
              {intl.get("t_account_setting_side_menu_privacy_policy")}
            </Typography>
            <Paper elevation={3}>
              <Stack alignItems="center">
                <StyledLogo
                  variant="square"
                  src={Logo}
                  alt={intl.get("t_alt_tokhimo_job_logo")}
                />
                <Typography variant="h4" mt="40px">
                  {intl.get("t_account_setting_side_menu_privacy_policy")}
                </Typography>
                <Typography variant="body1" mt="30px">
                  {intl.get("t_privacy_policy_intro_content")}
                </Typography>
                <StyledList>
                  <StyledListItem>
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {intl.get(
                            "t_privacy_policy_list_personal_information_management"
                          )}
                          <br />
                          {intl.get(
                            "t_privacy_policy_list_personal_information_management_content"
                          )}
                        </Typography>
                      }
                    />
                  </StyledListItem>
                  <StyledListItem>
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {intl.get(
                            "t_privacy_policy_list_appropriate_utilization"
                          )}
                          <br />
                          {intl.get(
                            "t_privacy_policy_list_appropriate_utilization_content"
                          )}
                        </Typography>
                      }
                    />
                  </StyledListItem>
                  <StyledListItem>
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {intl.get("t_privacy_policy_list_entrusted_handling")}
                          <br />
                          {intl.get(
                            "t_privacy_policy_list_entrusted_handling_content"
                          )}
                        </Typography>
                      }
                    />
                  </StyledListItem>
                  <StyledListItem>
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {intl.get(
                            "t_privacy_policy_list_third_party_disclosure"
                          )}
                          <br />
                          {intl.get(
                            "t_privacy_policy_list_third_party_disclosure_content"
                          )}
                        </Typography>
                      }
                    />
                  </StyledListItem>
                  <StyledListItem>
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {intl.get(
                            "t_privacy_policy_list_information_security"
                          )}
                          <br />
                          {intl.get(
                            "t_privacy_policy_list_information_security_content"
                          )}
                        </Typography>
                      }
                    />
                  </StyledListItem>
                  <StyledListItem>
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {intl.get(
                            "t_privacy_policy_list_management_register_personal_info"
                          )}
                          <br />
                          {intl.get(
                            "t_privacy_policy_list_management_register_personal_info_content"
                          )}
                        </Typography>
                      }
                    />
                  </StyledListItem>
                  <StyledListItem>
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {intl.get(
                            "t_privacy_policy_list_continuous_improvement"
                          )}
                          <br />
                          {intl.get(
                            "t_privacy_policy_list_continuous_improvement_content"
                          )}
                        </Typography>
                      }
                    />
                  </StyledListItem>
                </StyledList>
                <Typography variant="body1">
                  {intl.get("t_privacy_policy_outro_content")}
                </Typography>
                <Typography variant="body1" mt={4.5} mb={2} align="center">
                  {intl.get("t_privacy_policy_contact_title")}
                  <br />
                  {intl.get("t_privacy_policy_contact_company")}
                  <br />
                  {intl.get("t_privacy_policy_contact_department")}
                  <br />
                  {intl.get("t_privacy_policy_contact_address_detail")}
                  <br />
                  {intl.get("t_privacy_policy_contact_city_postal")}
                  <br />
                  {intl.get("t_privacy_policy_contact_phone")}
                  <br />
                  {intl.get("t_privacy_policy_contact_email")}
                  <br />
                  {intl.get("t_privacy_policy_contact_operating_hour")}
                </Typography>

                <Typography variant="body1" align="center">
                  {intl.get("t_privacy_policy_entacted_date")}
                  <br />
                  {intl.get("t_privacy_policy_contact_company")}
                </Typography>
              </Stack>
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default PrivacyPolicy;
