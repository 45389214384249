import { useEffect, useState } from "react";

import { useForm, useWatch } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Stack } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import * as yup from "yup";

import Button from "@components/Button";
import Radio from "@components/Radio";
import TextField from "@components/TextField";
import Typography from "@components/Typography";

import useToast from "@hooks/useToast";

import { LOCALE, LOCALE_SHORT } from "@utils/config";
import { auth, functions } from "@utils/firebase";
import { intl } from "@utils/translate";

const Translator = () => {
  const toast = useToast();
  const [user] = useAuthState(auth);

  // validation schema
  const schema = yup.object({
    textToTranslate: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: "Text to translate"
        })
      )
  });

  const [translateText, isTranslateTextLoading, translateTextError] =
    useHttpsCallable(functions, "languageTranslator");
  const [translatedText, setTranslatedText] = useState<string>("");

  const methods = useForm({
    defaultValues: { textToTranslate: "", selectedLang: LOCALE_SHORT.EN },
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, reset } = methods;

  const hiringLangWatcher = useWatch({
    control,
    name: "selectedLang"
  });

  const handleFormSubmit = async (data: { textToTranslate: string }) => {
    const res = await translateText({
      textToTranslate: data?.textToTranslate,
      targetLangCode:
        hiringLangWatcher === LOCALE_SHORT.EN ? LOCALE_SHORT.JA : LOCALE.EN, // here we need to provide "en-US" in else part because "en" is deprecated
      sourceLangCode: hiringLangWatcher
    });
    if (res?.data) {
      setTranslatedText(res.data as string);
    }
  };

  useEffect(() => {
    if (translateTextError) {
      toast.kampai("something went wrong", "error");
    }
  }, [translateTextError]);

  useEffect(() => {
    reset({ textToTranslate: "", selectedLang: hiringLangWatcher });
    if (translatedText) {
      setTranslatedText("");
    }
  }, [hiringLangWatcher]);

  return (
    <>
      {!user ? (
        <Typography>Please login to access this feature</Typography>
      ) : (
        <Box
          noValidate
          component="form"
          onSubmit={handleSubmit(handleFormSubmit)}>
          <Radio
            disabled={isTranslateTextLoading}
            control={control}
            name="selectedLang"
            label="Select language translation option"
            direction="row"
            required
            options={[
              {
                label: "English to Japanese",
                value: LOCALE_SHORT.EN
              },
              {
                label: "Japanese to English",
                value: LOCALE_SHORT.JA
              }
            ]}
          />

          <TextField
            disabled={isTranslateTextLoading}
            control={control}
            name="textToTranslate"
            label="Translate english to japanese"
            placeholder="Enter text"
            required
          />
          {translatedText ? (
            <Box>
              <Divider />
              <Typography mt={1} variant="subtitle2">
                Translated Text Output :-
              </Typography>
              <br />
              <Typography>{translatedText}</Typography>
            </Box>
          ) : (
            false
          )}
          <Stack justifyContent="space-between" direction="row" mt={2}>
            <Button type="submit" loading={isTranslateTextLoading}>
              Translate
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default Translator;
