import { useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { httpsCallable } from "@firebase/functions";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogActions, DialogContent, Grid, Stack } from "@mui/material";
import { FirebaseError } from "firebase/app";
import * as yup from "yup";

import Button from "@components/Button";
import Dialog from "@components/Dialog";
import Paper from "@components/Paper";
import Radio from "@components/Radio";
import TextField from "@components/TextField";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import CompanyManagerIAM from "@interfaces/database/CompanyManagerIAM";

import {
  DIALOG_ACTION,
  TEAM_MEMBER_ACCOUNT_TYPE_T_LABELS,
  TEAM_MEMBER_INVITE_ACCOUNT_TYPE
} from "@utils/config";
import { functions } from "@utils/firebase";
import { prepareMultiLingual } from "@utils/multiLingual";
import translate, { intl } from "@utils/translate";

interface InviteCompanyManagerFormData {
  firstName: string;
  lastName: string;
  email: string;
  managerInvitationType:
    | typeof TEAM_MEMBER_INVITE_ACCOUNT_TYPE[keyof typeof TEAM_MEMBER_INVITE_ACCOUNT_TYPE]
    | string;
}

const InviteCompanyManagerForm = () => {
  const navigate = useNavigate();
  const userData = useUserProfile();
  const toast = useToast();

  const inviteCompanyManager = httpsCallable(functions, "inviteCompanyManager");

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const COMPANY_TEAM_MEMBER_ACCOUNT_TYPE_OPTIONS = useOptions(
    TEAM_MEMBER_INVITE_ACCOUNT_TYPE,
    TEAM_MEMBER_ACCOUNT_TYPE_T_LABELS
  ).map((singleOption) => {
    return { label: singleOption.label, value: singleOption.key };
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    managerInvitationType: ""
  };

  const schema = yup.object({
    firstName: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_first_name")
        })
      ),
    lastName: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_last_name")
        })
      ),
    email: yup
      .string()
      .email(
        intl.get("t_error_invalid", {
          field: intl.get("t_general_email_address")
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_email_address")
        })
      ),
    managerInvitationType: yup.string().required(
      intl.get("t_error_required", {
        field: intl.get(
          "t_employer_manage_team_invite_people_form_company_manager_invitation_type"
        )
      })
    )
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
    mode: "onChange"
  });

  const { handleSubmit, control, formState, trigger } = methods;

  const handleFormSubmit = async (formData: InviteCompanyManagerFormData) => {
    const { firstName, lastName, email, managerInvitationType } = formData;

    setIsDisabled(true);

    try {
      await inviteCompanyManager({
        companyId: userData.value?.company_id,
        invitedUserFirstName: prepareMultiLingual(firstName),
        invitedUserLastName: prepareMultiLingual(lastName),
        invitedUserEmail: email,
        managerInvitationType: managerInvitationType,
        // For now, invited Manager has all the permissions, TBD by Aayush later.
        permissions: {
          canEditCompanyProfile: true,
          canUpsertJob: true,
          canViewJob: true,
          canInviteCandidate: true,
          canViewApplication: true,
          canPurchaseResume: true,
          canInviteManager: true,
          canEditIAM: true
        } as CompanyManagerIAM
      });

      toast.kampai(
        intl.get("t_employer_manage_team_invite_toast_success"),
        "success"
      );
      navigate(
        `/${translate.getCurrentLocale()}/employers/settings/manage-team`
      );
    } catch (e) {
      if (e instanceof FirebaseError) {
        if (e.code === "functions/already-exists") {
          toast.kampai(
            intl.get("t_employer_manage_team_invite_toast_error_user_exists"),
            "error"
          );
        } else if (e.code === "functions/permission-denied") {
          toast.kampai(
            intl.get(
              "t_employer_manage_team_invite_toast_error_permission_denied"
            ),
            "error"
          );
        } else {
          toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
        }
      }
    }
    setIsDisabled(false);
  };

  const handleDialogClose = (reason: keyof typeof DIALOG_ACTION) => {
    if (reason === DIALOG_ACTION.AGREE) {
      handleSubmit(handleFormSubmit)();
    }
  };

  return (
    <Box noValidate component="form">
      <Paper>
        <Typography variant="h3" mb={2.5}>
          {intl.get("t_employer_manage_team_invite_people_form_title")}
        </Typography>
        <br />
        <Typography variant="body1" color="text.secondary" mb={3}>
          {intl.get("t_employer_manage_team_invite_people_form_subtitle")}
        </Typography>
        <Grid container spacing={{ xs: 0, md: 2 }}>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={isDisabled}
              control={control}
              name="lastName"
              label={intl.get("t_general_last_name")}
              placeholder={intl.get("t_general_last_name")}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={isDisabled}
              control={control}
              name="firstName"
              label={intl.get("t_general_first_name")}
              placeholder={intl.get("t_general_first_name")}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={isDisabled}
              control={control}
              name="email"
              label={intl.get("t_general_email_address")}
              placeholder={intl.get(
                "t_employer_manage_team_invite_people_form_email_placeholder"
              )}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Radio
              disabled={isDisabled}
              control={control}
              name="managerInvitationType"
              label={intl.get(
                "t_employer_manage_team_invite_people_form_account_type"
              )}
              direction="row"
              required
              options={COMPANY_TEAM_MEMBER_ACCOUNT_TYPE_OPTIONS}
            />
          </Grid>
        </Grid>
      </Paper>

      <Stack direction="row" justifyContent="space-between" mt={3}>
        <Button
          variant="outlined"
          handleClick={() =>
            navigate(
              `/${translate.getCurrentLocale()}/employers/settings/manage-team`
            )
          }>
          {intl.get("t_general_cancel")}
        </Button>

        {formState.isValid ? (
          <Dialog
            title={intl.get("t_employer_manage_team_invite_dialog_title")}
            maxWidth="sm"
            initiator={
              <Button loading={isDisabled}>
                {intl.get(
                  "t_employer_manage_team_invite_people_form_send_invitation_btn"
                )}
              </Button>
            }
            onClose={handleDialogClose}>
            {(handleAgree, handleCancel) => (
              <>
                <DialogContent sx={{ py: 1 }}>
                  <Typography color="text.secondary" variant="body1">
                    {intl.get("t_employer_manage_team_invite_dialog_subtitle")}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button handleClick={handleCancel} variant="outlined">
                    {intl.get("t_general_cancel")}
                  </Button>
                  <Button handleClick={handleAgree} type="submit">
                    {intl.get("t_general_add")}
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        ) : (
          <Button
            loading={isDisabled}
            handleClick={() => {
              trigger();
            }}>
            {intl.get(
              "t_employer_manage_team_invite_people_form_send_invitation_btn"
            )}
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default InviteCompanyManagerForm;
