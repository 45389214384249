import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Link as MuiLink,
  Stack,
  styled,
  Typography
} from "@mui/material";
import { logEvent } from "firebase/analytics";
import useForceUpdate from "use-force-update";

import BarChart from "@components/BarChart";
import BenefitCard from "@components/BenefitCard";
import Button from "@components/Button";
import SignInSignUpDialog from "@components/Header/SignInSignUpDialog";
import PieChartCard from "@components/PieChartCard";
import PricingCard from "@components/PricingCard";
import SectionTitleLabel from "@components/SectionTitleLabel";
import SectionTitleMainText from "@components/SectionTitleMainText";
import SectionTitleSubText from "@components/SectionTitleSubText";
import TestimonialCarousel from "@components/TestimonialCarousel";
import TrustedBy from "@components/TrustedBy";
import WorldMap from "@components/WorldMap";

import useUserProfile from "@hooks/database/useUserProfile";

import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";
import {
  ANALYTICS_EVENT_TYPE,
  ENVIRONMENT,
  SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY,
  USER_TYPE
} from "@utils/config";
import { analytics } from "@utils/firebase";
import { resolveMultiLingual } from "@utils/multiLingual";
import { colorPalette } from "@utils/theme";
import translate, {
  intl,
  LOCALE_DATA,
  LOCALE_DATA_LOWERCASE
} from "@utils/translate";

import BG_Homepage_Trustedby from "@assets/images/BG_EmployerHomepage_Trustedby.svg";
import Blue_Shape from "@assets/images/shapes/Blue_Shape.png";
import Orange_Round_Cube from "@assets/images/shapes/Orange_Round_Cube.png";
import Orange_Shape from "@assets/images/shapes/Orange_Shape.png";
import Round_Pie_Chart_Shape from "@assets/images/shapes/Round_Pie_Chart_Shape.png";
import Homepage_Search_Animation from "@assets/video/Homepage_Search_Animation.mp4";

import PIE_CHART_AGE_RANGE_DATA from "@data/ageRangePieChart.json";
import EMPLOYER_TESTIMONIAL_DATA from "@data/employerTestimonials.json";
import PIE_CHART_EN_LEVEL_DATA from "@data/englishLevelPieChart.json";
import PIE_CHART_JA_LEVEL_DATA from "@data/japaneseLevelPieChart.json";
import BAR_CHART_SKILL_DATA from "@data/skillsBarChart.json";
import WORLD_MAP_DATA from "@data/worldMap.json";

interface Feature {
  type: "Feature";
  properties: {
    name: string;
  };
  geometry: {
    type: "Point";
    coordinates: [number, number];
  };
}

interface FeatureCollection {
  type: "FeatureCollection";
  features: Feature[];
}

interface GetStartedBtnProps {
  "label": string;
  "variant"?: "primary" | "secondary";
  "initiatorType": "get-started" | "post-job";
  "data-testid"?: string;
}

const locationData: FeatureCollection = {
  type: "FeatureCollection",
  features: WORLD_MAP_DATA as Feature[]
};

const StyledAccordionSummary = styled(AccordionSummary)({
  "paddingTop": 20,
  "& .MuiAccordionSummary-expandIconWrapper": {
    alignSelf: "center"
  }
});

const StyledAccordion = styled(Accordion)({
  background: colorPalette.lightBlue.background[30]
});

const StyledBackgroundImage = styled("img")(({ theme }) => {
  return {
    position: "absolute",
    bottom: 0,
    objectFit: "cover",
    width: "100%",
    height: "118%",
    zIndex: 0,
    objectPosition: "top",
    [theme.breakpoints.up("md")]: {
      height: "111%"
    },
    [theme.breakpoints.up("lg")]: {
      height: "2350px"
    },
    [theme.breakpoints.up("xl")]: {
      height: "2500px"
    }
  };
});

const StyledBlueShapeFromTrustedBy = styled("img")(({ theme }) => {
  return {
    position: "absolute",
    top: "18%",
    left: -50,
    width: 110,
    [theme.breakpoints.up("md")]: {
      top: 450,
      width: 150,
      left: -30
    },
    [theme.breakpoints.up("lg")]: {
      top: 650
    }
  };
});

const StyledBlueShapeFromDemographic = styled("img")(({ theme }) => {
  return {
    position: "absolute",
    top: 450,
    left: -50,
    width: 110,
    [theme.breakpoints.up("sm")]: {
      top: 750
    },
    [theme.breakpoints.up("md")]: {
      top: 950,
      width: 150,
      left: -30
    }
  };
});

const StyledOrangeShape = styled("img")(({ theme }) => {
  return {
    position: "absolute",
    right: 0,
    bottom: -60,
    width: 150,
    [theme.breakpoints.down("md")]: {
      bottom: -15,
      width: 100
    },
    [theme.breakpoints.up("xl")]: {
      bottom: -120
    }
  };
});

const StyledContainer = styled(Container)({
  marginTop: "1.5rem",
  marginBottom: "1.5rem"
});

const StyledVideo = styled("video")({
  objectFit: "scale-down",
  width: "100%",
  height: "100%",
  backgroundColor: "transparent",
  maskImage:
    "radial-gradient(circle, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgba(255,255,255,0) 100%)",
  zIndex: -1,
  position: "relative",
  maskSize: "110%"
});

const StyledAnimationVideoContainer = styled(Box)({
  backgroundColor: "transparent",
  overflow: "hidden",
  maskImage:
    "radial-gradient(closest-side, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 60%, rgb(255, 255, 255) 80%,rgba(255,255,255,0) 100%)",
  maskSize: "107%"
});

const EmployersHome = () => {
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const forceUpdate = useForceUpdate();
  const userProfile = useUserProfile();
  const userType = userProfile?.value?.user_type;

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (expanded === panel) {
        setExpanded(panel === "panel1" ? "panel2" : "panel1");
      } else {
        setExpanded(isExpanded ? panel : false);
      }
    };

  const languageChanged = () => {
    forceUpdate();
  };

  useEffect(() => {
    translate.addLanguageChangedListener(languageChanged);
    return () => {
      translate.removeLanguageChangedListener(languageChanged);
    };
  }, []);

  useEffect(() => {
    logEvent(analytics, ANALYTICS_EVENT_TYPE.EMPLOYER_HP_LOAD);
  }, []);

  // NOTE: Keep the following code for reference while generating dynamic value for pie chart
  // const calculatePieChartFieldPercentage = (
  //   data: Array<{
  //     value: number;
  //     category: string;
  //     color: string;
  //   }>,
  //   field: string,
  //   value: string
  // ) => {
  //   const totalValue = data.reduce((acc, item) => acc + item.value, 0);

  //   // Find the value of the specified category
  //   const categoryItem = data.find(
  //     (item) => item[field as keyof typeof item] === value
  //   );
  //   const categoryValue = categoryItem ? categoryItem.value : 0;

  //   // Calculate the percentage of the specified category
  //   const categoryPercentage = (categoryValue / totalValue) * 100;

  //   return `${categoryPercentage.toFixed(0)}%`;
  // };

  const GetStartedBtn = ({
    label,
    variant = "primary",
    initiatorType,
    "data-testid": dataTestId
  }: GetStartedBtnProps) => {
    if (userType) {
      if (userType === USER_TYPE.NO_TYPE) {
        // No action. Just show button
        return (
          <Button
            size="large"
            fullWidth={true}
            color={variant}
            data-testid={dataTestId}>
            {label}
          </Button>
        );
      } else if (userType === USER_TYPE.COMPANY) {
        return (
          <MuiLink component={Link} to="dashboard" underline="none">
            <Button color={variant} data-testid={dataTestId}>
              {label}
            </Button>
          </MuiLink>
        );
      } else {
        return (
          <MuiLink
            component={Link}
            to="https://www.tokhimo.com/jobs-contact"
            underline="none"
            target="_blank">
            <Button color={variant} data-testid={dataTestId}>
              {label}
            </Button>
          </MuiLink>
        );
      }
    } else {
      return (
        <SignInSignUpDialog
          initiator={
            <Button color={variant} data-testid={dataTestId}>
              {label}
            </Button>
          }
          initiatorType={initiatorType}
          activeContent={SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_UP}
        />
      );
    }
  };
  return (
    <>
      <Helmet>
        <title>{intl.get("t_seo_employer_home_page_title")}</title>
        <meta
          name="description"
          content={intl.get("t_seo_employer_home_page_description")}
        />
        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content={intl.get("t_seo_employer_home_page_title")}
        />
        <meta
          property="og:description"
          content={intl.get("t_seo_employer_home_page_description")}
        />
        <meta
          property="og:image"
          content={`${
            process.env.REACT_APP_FRONTEND_URL
          }/assets/seo_resources/OGP_Image_${translate.getCurrentLocaleShort()}.png`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_FRONTEND_URL}/employers`}
        />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={intl.get("t_seo_employer_home_page_title")}
        />
        <meta
          name="twitter:description"
          content={intl.get("t_seo_employer_home_page_description")}
        />
        <meta
          name="twitter:image"
          content={`${
            process.env.REACT_APP_FRONTEND_URL
          }/assets/seo_resources/OGP_Image_${translate.getCurrentLocaleShort()}.png`}
        />
        <meta name="twitter:image:alt" content={intl.get("t_seo_og_img_alt")} />

        {/* Canonical Link */}
        {Object.keys(LOCALE_DATA).map((singleLocale) => {
          const url = new URL(window.location.href);
          const pathSegments = url.pathname
            .split("/")
            .filter((segment) => segment !== "");

          if (
            pathSegments[0] &&
            Object.keys(LOCALE_DATA_LOWERCASE).includes(
              pathSegments[0].toLowerCase()
            )
          ) {
            // Locale is present, update it
            pathSegments[0] = singleLocale;
          } else {
            // Locale is not present, insert it
            pathSegments.unshift(singleLocale);
          }
          url.pathname = "/" + pathSegments.join("/");

          let frontendUrl = process.env.REACT_APP_FRONTEND_URL ?? "";
          frontendUrl = frontendUrl.replace("https://", "");
          url.hostname = frontendUrl;
          url.port = "";

          return (
            <link
              rel="alternate"
              hrefLang={singleLocale}
              key={singleLocale}
              href={url.toString()}
            />
          );
        })}
      </Helmet>
      {/* Homepage search section */}
      <section data-testid="employer_homepage_section" style={{ zIndex: 2 }}>
        <StyledContainer>
          <Box
            display={{ md: "flex" }}
            width={{ md: "95%" }}
            ml={{ md: "5%" }}
            mt={{ md: 6.25, lg: 12.5 }}>
            {/* HOMEPAGE INTRO CONTENT */}
            <Stack
              justifyContent="center"
              minWidth={{ md: 662 }}
              alignSelf="center"
              display={{ sm: "grid", md: "flex" }}>
              {/* SECTION LABEL */}
              <Box>
                <Box
                  bgcolor={colorPalette.blue[20]}
                  display="inline-flex"
                  padding={0.5}
                  borderRadius={1}
                  mb={1.5}>
                  <Typography variant="subtitle5" color="primary.main">
                    {intl.get("t_employer_homepage_tag")}
                  </Typography>
                </Box>
              </Box>
              {/* HEADING */}
              <Box minWidth={{ md: 662 }} alignSelf="center">
                <Typography variant="h1">
                  {intl.get("t_homepage_jobs_in_japan")}
                </Typography>
                <Typography
                  paragraph
                  variant="subtitle3"
                  color="text.secondary"
                  mt={2.5}
                  mb={3}>
                  {intl.get("t_employer_homepage_jobs_in_japan_sub_text")}
                </Typography>
              </Box>
              {/* ACTIONS */}
              <Stack
                flexDirection="row"
                gap={3}
                justifyContent={{ xs: "center", md: "unset" }}>
                <GetStartedBtn
                  data-testid="employer_homepage_post_a_job_button"
                  label={intl.get("t_employer_homepage_post_a_job")}
                  initiatorType="post-job"
                />
                <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                  <MuiLink
                    component={Link}
                    to="https://www.tokhimo.com/salesdeck"
                    target="_blank"
                    rel="noopener noreferrer">
                    <Button
                      variant="outlined"
                      data-testid="employer_homepage_service_deck_button">
                      {intl.get("t_employer_homepage_service_doc_btn")}
                    </Button>
                  </MuiLink>
                </EnvironmentSpecific>
              </Stack>
            </Stack>
            {/*  ANIMATION VIDEO */}
            <StyledAnimationVideoContainer
              display="flex"
              justifyContent="center"
              mb={{ xs: -12.5, md: 0 }}>
              <Box maxWidth={1200} maxHeight={900}>
                <StyledVideo
                  data-testid="employer_homepage_video"
                  autoPlay={true}
                  muted
                  loop
                  id="homepageAnimation"
                  preload="none"
                  playsInline>
                  <source src={Homepage_Search_Animation} type="video/mp4" />
                  Your browser does not support HTML5 video.
                </StyledVideo>
              </Box>
            </StyledAnimationVideoContainer>
          </Box>
        </StyledContainer>
      </section>
      {/* Trusted By, Our Benefits & Pricing Section */}
      <section style={{ position: "relative" }}>
        {/* BG Img */}
        <StyledBackgroundImage
          src={BG_Homepage_Trustedby}
          alt={intl.get("t_alt_blue_background_blob")}
          className="bg-img-employer-home-trustedby"
        />
        <StyledBlueShapeFromTrustedBy
          src={Blue_Shape}
          alt={intl.get("t_alt_blue_shape")}
        />
        <StyledOrangeShape
          src={Orange_Shape}
          alt={intl.get("t_alt_orange_shape")}
        />
        <StyledContainer>
          <Grid
            container
            spacing={3}
            px={{ md: 12.5 }}
            py={8.75}
            mt={{ xs: "calc(12%)", lg: 43.75 }}
            position="relative">
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              flexDirection="column">
              {/* Trusted By */}
              <Box mb={5}>
                <TrustedBy />
              </Box>
              {/* Our Benefit Heading  */}
              <SectionTitleLabel
                label={intl.get("t_homepage_benefits_label")}
              />
              <SectionTitleMainText
                title={intl.get("t_employer_homepage_benefits_main_text")}
              />
            </Grid>
            {/* Our Benefit Content Cards  */}
            <Grid item xs={12} md={6}>
              <BenefitCard
                title={intl.get(
                  "t_employer_homepage_benefits_free_job_posting"
                )}
                description={intl.get(
                  "t_employer_homepage_benefits_free_job_posting_desc"
                )}
                image="thumbsup"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BenefitCard
                title={intl.get(
                  "t_employer_homepage_benefits_advanced_skill_search"
                )}
                description={intl.get(
                  "t_employer_homepage_benefits_advanced_skill_search_desc"
                )}
                image="briefcase"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BenefitCard
                title={intl.get(
                  "t_employer_homepage_benefits_global_talent_acquisition"
                )}
                description={intl.get(
                  "t_employer_homepage_benefits_global_talent_acquisition_desc"
                )}
                image="magnifying"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BenefitCard
                title={intl.get(
                  "t_employer_homepage_benefits_maximize_outreach"
                )}
                description={intl.get(
                  "t_employer_homepage_benefits_maximize_outreach_desc"
                )}
                image="envelope"
              />
            </Grid>
            <Grid container item justifyContent="center" mt={5}>
              <GetStartedBtn
                data-testid="employer_homepage_get_started_button"
                label={intl.get("t_employer_homepage_get_started")}
                variant="secondary"
                initiatorType="get-started"
              />
            </Grid>
            {/* Plan & Pricing */}
            <Grid container item my={12.5} justifyContent="space-around">
              <Grid
                container
                item
                xs={12}
                sm
                pr={7.5}
                minWidth={{ sm: 350 }}
                maxWidth={{ md: 500 }}
                flexDirection="column"
                justifyContent="center">
                <Box mb={1.875} ml={{ md: -3.75 }}>
                  <img
                    src={Orange_Round_Cube}
                    width={46}
                    alt={intl.get("t_alt_orange_round_cube")}
                  />
                </Box>
                <Box>
                  <SectionTitleLabel
                    label={intl.get("t_employer_homepage_pricing_label")}
                  />
                </Box>
                <Typography variant="h3" color="primary.contrastText" mt={1.5}>
                  {intl.get("t_employer_homepage_pricing")}
                </Typography>
                <Typography
                  variant="subtitle3"
                  paragraph={true}
                  color={colorPalette.white[80]}
                  mt={2.5}
                  mb={3}>
                  {intl.get("t_employer_homepage_pricing_content")}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm
                minWidth={{ sm: 350 }}
                maxWidth={{ md: 450 }}
                mt={7.5}
                flexDirection="column">
                <PricingCard />
              </Grid>
            </Grid>
          </Grid>
        </StyledContainer>
      </section>
      {/* Demographics, Language statistics & Skill Statistics Section */}
      <section
        data-testid="employer_statistic_section"
        style={{ zIndex: 2, position: "relative" }}>
        <StyledBlueShapeFromDemographic
          src={Blue_Shape}
          alt={intl.get("t_alt_blue_shape")}
        />
        <StyledContainer>
          <Stack
            alignItems="center"
            position="relative"
            mx={{ md: 6.25 }}
            mt={{ xl: 18.75 }}>
            {/* Demographics */}
            <Box
              bgcolor={colorPalette.lighterBlue[20]}
              display="inline-flex"
              padding={0.5}
              borderRadius={1}>
              <Typography
                variant="subtitle5"
                color={colorPalette.lighterBlue.base}
                textAlign="center">
                {intl.get("t_employer_homepage_demographic")}
              </Typography>
            </Box>
            <Typography variant="h2" mt={1.5} mb={2.5} textAlign="center">
              {intl.get("t_employer_homepage_candidate_demographic")}
            </Typography>
            <Typography
              variant="subtitle3"
              color="text.secondary"
              textAlign="center">
              {intl.get("t_employer_homepage_candidate_demographic_subtitle")}
            </Typography>
            <Box
              width="100%"
              height={{ md: 675, sm: 498, xs: 197 }}
              mt={{ xs: 7.5, sm: 0 }}>
              <WorldMap
                containerId="candidate-demography-map"
                locationData={locationData}
              />
            </Box>
          </Stack>
          {/* Language Statistics */}
          <Grid
            container
            item
            mt={{ xs: 18.75, sm: 0 }}
            justifyContent="space-around"
            position="relative">
            <Grid
              container
              item
              xs={12}
              md={5}
              flexDirection="column"
              justifyContent="center">
              <Box>
                <Box
                  bgcolor={colorPalette.blue[20]}
                  display="inline-flex"
                  padding={0.5}
                  borderRadius={1}>
                  <Typography variant="subtitle5" color="primary.main">
                    {intl.get("t_employer_homepage_statistics")}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="h2" mt={1.5} mb={2.5}>
                {intl.get("t_employer_homepage_language_statistics_title")}
              </Typography>
              <Typography variant="subtitle3" color="text.secondary">
                {intl.get("t_employer_homepage_language_statistics_content")}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              lg={5}
              mt={7.5}
              flexDirection="column"
              maxWidth={{ sm: 700, md: "unset" }}
              alignItems={{ md: "flex-end" }}>
              <Box
                display="flex"
                justifyContent="flex-end"
                width="100%"
                zIndex={2}
                mr={-1.875}
                mb={-3.75}>
                <img
                  src={Round_Pie_Chart_Shape}
                  width={52}
                  alt={intl.get("t_alt_round_pie_chart_shape")}
                />
              </Box>
              <Box width={{ xs: "100%", md: "53%" }} height={435}>
                <PieChartCard
                  title={intl.get("t_general_english_level")}
                  data={PIE_CHART_EN_LEVEL_DATA.data}
                  field={intl.get("t_piechart_english_level_subtitle")}
                  fieldValue={PIE_CHART_EN_LEVEL_DATA.displayValue}
                  containerId="english-level-pie-chart"
                />
              </Box>
              <Box
                width={{ xs: "100%", md: "53%" }}
                height={435}
                zIndex={2}
                my={{ xs: 3, md: -12.5 }}
                alignSelf={{ md: "flex-start" }}>
                <PieChartCard
                  title={intl.get("t_general_japanese_level")}
                  data={PIE_CHART_JA_LEVEL_DATA.data}
                  field={intl.get("t_piechart_japanese_level_subtitle")}
                  fieldValue={PIE_CHART_JA_LEVEL_DATA.displayValue}
                  containerId="japanese-level-pie-chart"
                />
              </Box>
              <Box width={{ xs: "100%", md: "53%" }} height={435}>
                <PieChartCard
                  title={intl.get("t_piechart_age_range_label")}
                  data={PIE_CHART_AGE_RANGE_DATA.data}
                  field={intl.get("t_piechart_age_range_subtitle")}
                  fieldValue={PIE_CHART_AGE_RANGE_DATA.displayValue}
                  containerId="age-range-pie-chart"
                />
              </Box>
            </Grid>
          </Grid>
          {/* Skills Statistics */}
          <Box
            position="relative"
            mt={12.5}
            mx={{ md: 6.25 }}
            mb={{ xs: 37.5, lg: 50 }}>
            <Box
              bgcolor="secondary.light"
              display="inline-flex"
              padding={0.5}
              borderRadius={1}>
              <Typography
                variant="subtitle5"
                color="secondary.main"
                id="data-for-ssg">
                {intl.get("t_employer_homepage_skills_label")}
              </Typography>
            </Box>
            <Typography variant="h2" mt={1.5} mb={2.5}>
              {intl.get("t_employer_homepage_skills")}
            </Typography>
            <Typography variant="subtitle3" color="text.secondary">
              {intl.get("t_employer_homepage_skills_content")}
            </Typography>
            <Box width="100%" height="max-content" mt={7.5} mb={6}>
              {/* FIXME: Translation for chart data */}
              <BarChart
                containerId="skills-chart"
                data={BAR_CHART_SKILL_DATA}
              />
            </Box>

            <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
              <Stack flexDirection="row" justifyContent="center">
                <MuiLink
                  component={Link}
                  to="https://www.tokhimo.com/salesdeck"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Button
                    variant="outlined"
                    data-testid="employer_homepage_service_deck_button">
                    {intl.get("t_employer_homepage_service_doc_btn")}
                  </Button>
                </MuiLink>
              </Stack>
            </EnvironmentSpecific>
          </Box>
        </StyledContainer>
      </section>

      {/* Review, FAQ section */}
      <section style={{ position: "relative", zIndex: 2 }}>
        <StyledContainer>
          {/* Review */}
          <Box>
            <Stack alignItems="center">
              <Box>
                <SectionTitleLabel
                  label={intl.get("t_homepage_review_label")}
                />
              </Box>
              <SectionTitleMainText
                title={intl.get("t_employer_homepage_review_title")}
              />
              <SectionTitleSubText
                subText={intl.get("t_employer_homepage_review_subtitle")}
              />
            </Stack>
            <TestimonialCarousel
              testimonialContent={
                resolveMultiLingual(EMPLOYER_TESTIMONIAL_DATA) ?? []
              }
              avatarVariant="rounded"
            />
          </Box>
          {/* FAQ */}
          <Stack
            mx={{ xs: "auto", md: 0 }}
            my={{ xs: "auto", sm: 0 }}
            maxWidth={{ xs: 360, sm: 530, md: "unset" }}
            height={{ xs: 1350, sm: "unset", md: 1050 }}
            justifyContent="center"
            mb={{ sm: 12.5, md: 0 }}
            alignItems={{ xs: "center", md: "flex-start" }}>
            <Box>
              <Stack alignItems="center" mb={8} mt={{ xs: 8, sm: 0 }}>
                <Box>
                  <SectionTitleLabel
                    label={intl.get("t_employer_homepage_faq_label")}
                  />
                </Box>
                <SectionTitleMainText
                  title={intl.get("t_employer_homepage_faq_title")}
                />
              </Stack>
              <Box px={{ md: 12.5 }} data-testid="employer_faq_box">
                <StyledAccordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                  defaultExpanded>
                  <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h4" color="primary.contrastText">
                      {intl.get("t_employer_homepage_faq_post_job_title")}
                    </Typography>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" color={colorPalette.white[80]}>
                      {intl.get("t_employer_homepage_faq_post_job_content")}
                    </Typography>
                  </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}>
                  <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h4" color="primary.contrastText">
                      {intl.get(
                        "t_employer_homepage_faq_access_full_resumes_title"
                      )}
                    </Typography>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" color={colorPalette.white[80]}>
                      {intl.get(
                        "t_employer_homepage_faq_access_full_resumes_content"
                      )}
                    </Typography>
                  </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}>
                  <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h4" color="primary.contrastText">
                      {intl.get("t_employer_homepage_faq_refund_policy_title")}
                    </Typography>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" color={colorPalette.white[80]}>
                      {intl.get(
                        "t_employer_homepage_faq_refund_policy_content"
                      )}
                      <br />
                      <MuiLink
                        component={Link}
                        to="https://www.tokhimo.com/s/f/refund-policy"
                        target="_blank"
                        rel="noopener noreferrer">
                        https://www.tokhimo.com/s/f/
                        <wbr />
                        refund-policy
                      </MuiLink>
                    </Typography>
                  </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}>
                  <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h4" color="primary.contrastText">
                      {intl.get(
                        "t_employer_homepage_faq_recruiting_agencies_title"
                      )}
                    </Typography>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" color={colorPalette.white[80]}>
                      {intl.get(
                        "t_employer_homepage_faq_recruiting_agencies_content"
                      )}
                    </Typography>
                  </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5")}>
                  <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h4" color="primary.contrastText">
                      {intl.get(
                        "t_employer_homepage_faq_contact_candidate_after_purchase_title"
                      )}
                    </Typography>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" color={colorPalette.white[80]}>
                      {intl.getHTML(
                        "t_employer_homepage_faq_contact_candidate_after_purchase_content"
                      )}
                    </Typography>
                  </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion
                  expanded={expanded === "panel6"}
                  onChange={handleChange("panel6")}>
                  <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h4" color="primary.contrastText">
                      {intl.get(
                        "t_employer_homepage_faq_handle_invoices_title"
                      )}
                    </Typography>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" color={colorPalette.white[80]}>
                      {intl.getHTML(
                        "t_employer_homepage_faq_handle_invoices_content"
                      )}
                    </Typography>
                  </AccordionDetails>
                </StyledAccordion>
              </Box>
            </Box>
          </Stack>
        </StyledContainer>
      </section>
    </>
  );
};

export default EmployersHome;
