import {
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon
} from "@mui/icons-material";
import {
  Box,
  DialogActions,
  DialogContent,
  Stack,
  Tooltip
} from "@mui/material";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import { useAuthState } from "react-firebase-hooks/auth";

import ApplyButtonTooltipTitle from "@components/ApplyButtonTooltipTitle";
import Button from "@components/Button";
import Cursor from "@components/Cursor";
import CVFilledStatus from "@components/CVFilledStatus";
import Dialog from "@components/Dialog";
import CompanyInfo from "@components/JobCards/Common/CompanyInfo";
import JobCategories from "@components/JobCards/Common/JobCategories";
import JobStatus from "@components/JobCards/Common/JobStatus";
import {
  commonFavoriteIconStyle,
  StyledFavoriteIconBoxForDesktop,
  StyledFavoriteIconBoxForMobile
} from "@components/JobCards/JobCard";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";

import CvFileRequirements from "@interfaces/database/CvFileRequirements";
import Location from "@interfaces/database/Location";

import {
  JOB_APPLY_STATUS_CODE,
  JOB_STATUS_TAGS,
  USER_TYPE
} from "@utils/config";
import { auth } from "@utils/firebase";
import translate, { intl } from "@utils/translate";

dayjs.extend(relative);

interface SavedJobCardProps {
  isBookmarked?: boolean;
  isApplied?: boolean;
  timestamp?: Date;
  status: Array<typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]>;
  categories?: Array<string>;
  companyName?: string;
  companyLogo?: string;
  jobTitle?: string;
  location?: Location;
  handleJobBookmark?: () => void;
  handleJobApply?: () => void;
  handleClick?: () => void;
  isLoading?: boolean;
  isJobActive?: boolean;
  jobApplyStatusCode: typeof JOB_APPLY_STATUS_CODE[keyof typeof JOB_APPLY_STATUS_CODE];
  cvFileRequirements?: CvFileRequirements;
}

const SavedJobCard = ({
  isBookmarked = false,
  isApplied = false,
  timestamp,
  companyLogo,
  status,
  categories = [],
  companyName,
  jobTitle,
  location,
  handleJobBookmark,
  handleJobApply,
  handleClick,
  isLoading = false,
  isJobActive = true,
  jobApplyStatusCode,
  cvFileRequirements
}: SavedJobCardProps) => {
  const relativeTime = dayjs(timestamp)
    .locale(translate.getCurrentLocaleShort())
    .fromNow();

  const [user] = useAuthState(auth);
  const userProfile = useUserProfile();

  const { progress_flags } = userProfile?.value ?? {};

  const isEnResumePresent =
    userProfile?.value?.metadata?.progress_flags?.en_cv ?? false;
  const isJaResumePresent =
    userProfile?.value?.metadata?.progress_flags?.ja_cv ?? false;
  const isJaRirekishoPresent =
    userProfile?.value?.metadata?.progress_flags?.ja_rirekisho ?? false;

  const userProfileStatus = {
    isLoggedIn: user?.uid || userProfile?.value ? true : false,
    isEmailVerified: user?.emailVerified ?? false,
    isProfileSummaryCompleted:
      !progress_flags?.basic_information ||
      !progress_flags?.job_experience_overview ||
      !progress_flags?.language ||
      !progress_flags?.skills
        ? false
        : true,
    isResumeCompleted:
      isEnResumePresent || isJaResumePresent || isJaRirekishoPresent,
    isCandidate:
      userProfile?.value?.user_type === USER_TYPE.INDIVIDUAL ? true : false
  };

  const CurrentFavoriteIcon = () =>
    isBookmarked ? (
      <FavoriteIcon sx={commonFavoriteIconStyle} onClick={handleJobBookmark} />
    ) : (
      <FavoriteBorderIcon
        sx={commonFavoriteIconStyle}
        onClick={handleJobBookmark}
      />
    );

  const ApplyBtn = () => {
    return jobApplyStatusCode === JOB_APPLY_STATUS_CODE.CAN_APPLY ? (
      <Dialog
        title={intl.get("t_dialog_necessary_documents_title")}
        maxWidth="sm"
        initiator={
          <Button disabled={!isJobActive}>{intl.get("t_general_apply")}</Button>
        }>
        {(_, handleCancel) => (
          <>
            <DialogContent sx={{ py: 0.5 }}>
              <Typography variant="body1" color="text.secondary">
                {intl.get("t_dialog_necessary_documents_subtitle")}
              </Typography>
              <Stack gap={1} mt={3}>
                {cvFileRequirements?.is_en_cv_mandatory ? (
                  <CVFilledStatus
                    label={intl.get("t_general_english_cv")}
                    isAttached={isEnResumePresent}
                  />
                ) : (
                  false
                )}
                {cvFileRequirements?.is_ja_cv_mandatory ? (
                  <CVFilledStatus
                    label={intl.get("t_general_japanese_cv")}
                    isAttached={isJaResumePresent}
                  />
                ) : (
                  false
                )}
                {cvFileRequirements?.is_ja_rirekisho_mandatory ? (
                  <CVFilledStatus
                    label={intl.get("t_general_japanese_rirekisho")}
                    isAttached={isJaRirekishoPresent}
                  />
                ) : (
                  false
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button handleClick={handleCancel} variant="outlined">
                {intl.get("t_general_cancel")}
              </Button>
              <Button handleClick={handleJobApply} loading={isLoading}>
                {intl.get("t_general_apply")}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    ) : (
      <Tooltip
        PopperProps={{
          sx: {
            px: 4
          }
        }}
        enterTouchDelay={0}
        title={
          <ApplyButtonTooltipTitle
            userProfileStatus={userProfileStatus}
            jobStatus={{ isActive: isJobActive }}
          />
        }
        arrow>
        <Box sx={{ cursor: "pointer" }}>
          <Button disabled> {intl.get("t_general_apply")}</Button>
        </Box>
      </Tooltip>
    );
  };

  const DisabledAppliedBtn = () => {
    return (
      <Cursor type="default">
        <Button disabled>{intl.get("t_general_applied")}</Button>
      </Cursor>
    );
  };

  const JobDetails = () => {
    return (
      <Box
        p={2}
        borderRadius={1}
        bgcolor="common.white"
        onClick={handleClick}
        sx={{
          filter: !isJobActive ? "grayscale(1)" : "unset"
        }}>
        {/* job status and favorite icon section start */}
        {handleJobBookmark ? (
          <StyledFavoriteIconBoxForMobile
            onClick={(e) => {
              handleJobBookmark();
              e.stopPropagation();
            }}>
            <CurrentFavoriteIcon />
          </StyledFavoriteIconBoxForMobile>
        ) : (
          false
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap">
          <JobStatus status={status} />

          {handleJobBookmark ? (
            <StyledFavoriteIconBoxForDesktop
              onClick={(e) => {
                handleJobBookmark();
                e.stopPropagation();
              }}>
              <CurrentFavoriteIcon />
            </StyledFavoriteIconBoxForDesktop>
          ) : (
            false
          )}
        </Stack>
        {/* job status and favorite icon section end */}

        {/* company and role information start */}
        <CompanyInfo
          companyLogo={companyLogo}
          companyName={companyName}
          location={location}
          jobTitle={jobTitle}
        />
        {/* company and role information end */}

        {/* category tag start */}
        <Stack
          gap={1}
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          mt={1}>
          <JobCategories categories={categories} />
          <Stack
            direction="row"
            ml="auto"
            gap={1}
            alignItems="center"
            display={{ xs: "none", sm: "flex", lg: "none", xl: "flex" }}>
            <Typography variant="body2" color="text.secondary">
              {dayjs(timestamp)
                .locale(translate.getCurrentLocaleShort())
                .fromNow()}
            </Typography>
            {isApplied ? (
              <DisabledAppliedBtn />
            ) : (
              <Box onClick={(e) => e.stopPropagation()}>
                <ApplyBtn />
              </Box>
            )}
          </Stack>
        </Stack>
        {/* category tag start */}

        {/* time and revoke btn for mobile start */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          display={{ xs: "flex", sm: "none", lg: "flex", xl: "none" }}
          mt={1}>
          <Typography variant="body2" color="text.secondary">
            {relativeTime}
          </Typography>
          <Box onClick={(e) => e.stopPropagation()}>
            {isApplied ? <DisabledAppliedBtn /> : <ApplyBtn />}
          </Box>
        </Stack>
        {/* time and revoke btn for mobile end */}
      </Box>
    );
  };

  return isJobActive ? (
    <Cursor>
      <JobDetails />
    </Cursor>
  ) : (
    <Tooltip
      arrow
      placement="top"
      enterTouchDelay={0}
      title={
        <Typography variant="body2">
          {intl.get("t_job_not_active_tooltip")}
        </Typography>
      }>
      <div>
        <JobDetails />
      </div>
    </Tooltip>
  );
};

export default SavedJobCard;
