import { Skeleton, Stack } from "@mui/material";

interface SkeletonJobCategoryProps {
  multiple?: boolean;
}

const SkeletonJobCategory = ({
  multiple = false
}: SkeletonJobCategoryProps) => {
  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {[...Array(multiple ? 4 : 1)].map((_, idx) => {
        return (
          <Skeleton
            key={idx}
            animation="wave"
            variant="rounded"
            height="2.25rem"
            width="15ch"
          />
        );
      })}
    </Stack>
  );
};

export default SkeletonJobCategory;
