import { useState } from "react";

import { useForm } from "react-hook-form";

import { httpsCallable } from "@firebase/functions";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import { FirebaseError } from "firebase/app";
import * as yup from "yup";

import Button from "@components/Button";
import Radio from "@components/Radio";
import Status from "@components/Status";
import TextField from "@components/TextField";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import CompanyManagerIAM from "@interfaces/database/CompanyManagerIAM";

import {
  TEAM_MEMBER_ACCOUNT_TYPE_T_LABELS,
  TEAM_MEMBER_INVITE_ACCOUNT_TYPE
} from "@utils/config";
import { functions } from "@utils/firebase";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";
import { intl } from "@utils/translate";

dayjs.extend(relative);

interface InviteCompanyManagerFormData {
  firstName: string;
  lastName: string;
  email: string;
  managerInvitationType:
    | typeof TEAM_MEMBER_INVITE_ACCOUNT_TYPE[keyof typeof TEAM_MEMBER_INVITE_ACCOUNT_TYPE]
    | string;
}

const Invite = () => {
  const companyData = useCompanyDetails();
  const userData = useUserProfile();
  const toast = useToast();

  const inviteCompanyManager = httpsCallable(functions, "inviteCompanyManager");

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const company_manager_invitation_type_OPTIONS = useOptions(
    TEAM_MEMBER_INVITE_ACCOUNT_TYPE,
    TEAM_MEMBER_ACCOUNT_TYPE_T_LABELS
  ).map((singleOption) => {
    return { label: singleOption.label, value: singleOption.key };
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    managerInvitationType: ""
  };

  const schema = yup.object({
    firstName: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_first_name")
        })
      ),
    lastName: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_last_name")
        })
      ),
    email: yup
      .string()
      .email()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_email_address")
        })
      ),
    managerInvitationType: yup.string().required(
      intl.get("t_error_required", {
        field: intl.get(
          "t_employer_manage_team_invite_people_form_company_manager_invitation_type"
        )
      })
    )
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, reset } = methods;

  const handleFormSubmit = async (formData: InviteCompanyManagerFormData) => {
    const { firstName, lastName, email, managerInvitationType } = formData;
    setIsDisabled(true);
    try {
      await inviteCompanyManager({
        companyId: userData.value?.company_id,
        companyName: companyData.value?.name,
        invitedUserFirstName: prepareMultiLingual(firstName),
        invitedUserLastName: prepareMultiLingual(lastName),
        invitedUserEmail: email,
        managerInvitationType: managerInvitationType,
        // For now, invited Manager has all the permissions, TBD by Aayush later.
        permissions: {
          canEditCompanyProfile: true,
          canUpsertJob: true,
          canViewJob: true,
          canInviteCandidate: true,
          canViewApplication: true,
          canPurchaseResume: true,
          canInviteManager: true,
          canEditIAM: true
        } as CompanyManagerIAM
      });

      reset();

      toast.kampai("Manager Invited", "success");
    } catch (e) {
      if (e instanceof FirebaseError) {
        if (e.code === "functions/already-exists") {
          toast.kampai(
            intl.get("t_employer_manage_team_invite_toast_error_user_exists"),
            "error"
          );
        } else if (e.code === "functions/permission-denied") {
          toast.kampai(
            intl.get(
              "t_employer_manage_team_invite_toast_error_permission_denied"
            ),
            "error"
          );
        } else {
          toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
        }
      }
    }
    setIsDisabled(false);
  };

  const managerIds = Object.keys(companyData.value?.managers ?? {});

  return (
    <>
      <Typography variant="h5">Invite:</Typography>
      <br />
      <Typography variant="body1">
        Owner Details: {companyData.value?.owner.email}
        <Typography variant="helperText" style={{ fontStyle: "italic" }}>
          ({companyData.value?.owner.user_id})
        </Typography>
      </Typography>
      <br />
      <br />

      <Typography variant="body1" mb={2}>
        Invite new Manager:
      </Typography>
      {/* Invite form start */}
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container spacing={{ xs: 0, md: 2 }}>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={isDisabled}
              control={control}
              name="lastName"
              label={intl.get("t_general_last_name")}
              placeholder={intl.get("t_general_last_name")}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={isDisabled}
              control={control}
              name="firstName"
              label={intl.get("t_general_first_name")}
              placeholder={intl.get("t_general_first_name")}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={isDisabled}
              control={control}
              name="email"
              label={intl.get("t_general_email_address")}
              placeholder={intl.get(
                "t_employer_manage_team_invite_people_form_email_placeholder"
              )}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Radio
              disabled={isDisabled}
              control={control}
              name="managerInvitationType"
              label={intl.get(
                "t_employer_manage_team_invite_people_form_account_type"
              )}
              direction="row"
              required
              options={company_manager_invitation_type_OPTIONS}
            />
          </Grid>
        </Grid>
        <br />
        <Button loading={isDisabled} type="submit">
          {intl.get(
            "t_employer_manage_team_invite_people_form_send_invitation_btn"
          )}
        </Button>
      </Box>
      {/* Invite form end */}
      <br />
      <hr />
      <Typography variant="body1">Invited Managers:</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2}>
              <Typography variant="body2">Sr. No.</Typography>
            </TableCell>
            <TableCell rowSpan={2}>
              <Typography variant="body2">Email ID</Typography>
            </TableCell>
            <TableCell rowSpan={2}>
              <Typography variant="body2">User ID</Typography>
            </TableCell>
            <TableCell rowSpan={2}>
              <Typography variant="body2">Account Type</Typography>
            </TableCell>
            <TableCell rowSpan={3}>
              <Typography variant="body2">Full Name</Typography>
            </TableCell>
            <TableCell rowSpan={2}>
              <Typography variant="body2">Invited At</Typography>
            </TableCell>
            <TableCell rowSpan={2}>
              <Typography variant="body2">Joined At</Typography>
            </TableCell>

            <TableCell colSpan={8} align="center">
              <Typography variant="body2">Permissions</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2">Can Edit Company Profile</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">Can Upsert Job</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">Can View Jobs</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">Can Invite Candidate</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">Can View Applications</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">Can Purchase Resume</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">Can Invite Managers</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">Can Edit IAM</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {managerIds.map((singleManagerId, idx) => {
            const managerData = companyData.value?.managers?.[singleManagerId];

            if (managerData) {
              const firstName =
                resolveMultiLingual(managerData?.first_name) ?? "";
              const lastName =
                resolveMultiLingual(managerData?.last_name) ?? "";
              const fullName = `${lastName} ${firstName}`.trim();

              return (
                <TableRow key={idx}>
                  <TableCell>
                    <Typography variant="body2">{idx + 1}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {managerData?.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {singleManagerId === managerData?.email ? (
                      <Chip
                        color="warning"
                        size="small"
                        label="Pending Registration"
                      />
                    ) : (
                      <Typography variant="body2">{singleManagerId}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Status
                      label={managerData?.company_manager_invitation_type}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{fullName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {dayjs(managerData?.invited_at.toDate()).fromNow()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {managerData?.joined_at
                        ? dayjs(managerData?.joined_at.toDate()).fromNow()
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      disabled
                      checked={
                        managerData?.permissions.canEditCompanyProfile ?? false
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      disabled
                      checked={managerData?.permissions.canUpsertJob ?? false}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      disabled
                      checked={managerData?.permissions.canViewJob ?? false}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      disabled
                      checked={
                        managerData?.permissions.canInviteCandidate ?? false
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      disabled
                      checked={
                        managerData?.permissions.canViewApplication ?? false
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      disabled
                      checked={
                        managerData?.permissions.canPurchaseResume ?? false
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      disabled
                      checked={
                        managerData?.permissions.canInviteManager ?? false
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      disabled
                      checked={managerData?.permissions.canEditIAM ?? false}
                    />
                  </TableCell>
                </TableRow>
              );
            }
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default Invite;
