import { useState } from "react";

import { Box, DialogContent, Stack } from "@mui/material";
import Lottie from "lottie-react";

import Button from "@components/Button";
import Dialog from "@components/Dialog";
import Typography from "@components/Typography";

import CancelAnimation from "@assets/lottie/cancel.json";
import SuccessAnimation from "@assets/lottie/success.json";
import WarningAnimation from "@assets/lottie/warning.json";

const modalContents = {
  success: {
    animationData: SuccessAnimation,
    title: "Success!",
    message: "Your action was successful!"
  },
  cancel: {
    animationData: CancelAnimation,
    title: "Cancelled",
    message: "You have cancelled your action."
  },
  warning: {
    animationData: WarningAnimation,
    title: "Warning!",
    message: "There is something you need to know..."
  }
};

interface ModalButtonProps {
  type: "success" | "cancel" | "warning";
}

const ModalButton = ({ type }: ModalButtonProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { animationData, title, message } = modalContents[type];

  return (
    <>
      <Button handleClick={() => setIsOpen(true)}>{type}</Button>
      {isOpen ? (
        <Dialog
          initiator=""
          isDefaultOpen
          maxWidth="xs"
          onClose={() => setIsOpen(false)}>
          {() => (
            <DialogContent>
              <Stack justifyContent="center" alignItems="center">
                <Box mb={3}>
                  <Lottie
                    animationData={animationData}
                    loop={true}
                    style={{
                      height: 150,
                      width: 150
                    }}
                  />
                </Box>
                <Typography variant="h5" mb={1}>
                  {title}
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="text.secondary">
                  {message}
                </Typography>
              </Stack>
            </DialogContent>
          )}
        </Dialog>
      ) : (
        false
      )}
    </>
  );
};

const LottieAnimation = () => {
  return (
    <div>
      <Typography>Click to open a modal</Typography>
      <Stack direction="row" gap={2} my={1}>
        <ModalButton type="success" />
        <ModalButton type="cancel" />
        <ModalButton type="warning" />
      </Stack>
    </div>
  );
};

export default LottieAnimation;
