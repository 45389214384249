import { Box, Container, Grid, Stack, styled } from "@mui/material";

import Button from "@components/Button";
import Typography from "@components/Typography";

import { colorPalette } from "@utils/theme";
import { intl } from "@utils/translate";

import Blue_Shape from "@assets/images/shapes/Blue_Shape.png";
import Orange_Round_Cube from "@assets/images/shapes/Orange_Round_Cube.png";
import Tokhimo_Review from "@assets/images/Tokhimo_Review.png";

const StyledContainer = styled(Container)(({ theme }) => {
  return {
    backgroundColor: colorPalette.lightBlue.background[30],
    color: theme.palette.common.white,
    borderRadius: 4,
    padding: 20,
    [theme.breakpoints.up("md")]: {
      maxHeight: 416,
      width: "80vw"
    }
  };
});

const StyledImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "4px",
  maxHeight: 376
});

const StyledBlueShape = styled("img")(({ theme }) => {
  return {
    width: 85,
    zIndex: 1,
    transform: "rotate(30deg)",
    [theme.breakpoints.down("md")]: {
      width: 45
    }
  };
});

const TokhimoReviewBrandingCard = () => {
  return (
    <StyledContainer>
      <Grid container spacing={1} rowSpacing={5} height="100%">
        <Grid item xs={12} md={5.5}>
          <Stack
            height="100%"
            justifyContent="center"
            alignItems={{ xs: "center", md: "flex-start" }}>
            <Typography variant="h5" color="common.white" mb={1.5}>
              {intl.get("t_tokhimo_review_card_title")}
            </Typography>
            <Typography
              variant="subtitle5"
              color="common.white"
              mb={5}
              textAlign={{ xs: "center", md: "left" }}>
              {intl.get("t_tokhimo_review_card_subtitle")}
            </Typography>

            <a
              href="https://review.tokhimo.com/"
              target="_blank"
              rel="noopener noreferrer">
              <Button color="secondary" data-testid="tokhimo_review_button">
                {intl.get("t_tokhimo_review_card_btn")}
              </Button>
            </a>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6.5}>
          <Box width="100%" height="100%" position="relative">
            <StyledImage
              src={Tokhimo_Review}
              alt={intl.get("t_alt_tokhimo_review")}
            />
            <Box position="absolute" top={0} left={-30} zIndex={1}>
              <img
                src={Orange_Round_Cube}
                alt={intl.get("t_alt_orange_round_cube")}
                width={46}
              />
            </Box>
            <Box position="absolute" bottom={-20} right={-15}>
              <StyledBlueShape
                src={Blue_Shape}
                alt={intl.get("t_alt_blue_shape")}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default TokhimoReviewBrandingCard;
