import { useEffect, useState } from "react";

import { useForm, useWatch } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Stack } from "@mui/material";
import { ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import * as yup from "yup";

import Button from "@components/Button";
import Radio from "@components/Radio";
import TextField from "@components/TextField";
import Typography from "@components/Typography";
import WYSIWYG from "@components/WYSIWYG";

import useToast from "@hooks/useToast";

import { JOB_INFORMATION_SECTION_TYPE } from "@utils/config";
import { rawDraftContentStateToString } from "@utils/convertDraftContentState";
import { functions } from "@utils/firebase";
import translate, { intl } from "@utils/translate";

const GenerateJobInformation = () => {
  const [showPromptInput, setShowPromptInput] = useState<boolean>(false);
  const [promptId, setPromptId] = useState<string>("");
  const toast = useToast();

  // validation schema
  const schema = yup.object({
    companyName: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: "Company name"
        })
      ),
    jobTitle: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: "Job title"
        })
      )
  });

  const [
    jobInformationContent,
    isJobInformationContentLoading,
    jobInformationContentError
  ] = useHttpsCallable(functions, "getJobInformationContent");

  const initialFormValues = {
    companyName: "",
    jobTitle: "",
    jobInformationSectionType: JOB_INFORMATION_SECTION_TYPE.JOB_DESCRIPTION,
    generatedText: convertToRaw(ContentState.createFromText("")),
    promptInput: ""
  };

  const methods = useForm({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, setValue, reset } = methods;

  const jobInformationSectionTypeWatcher = useWatch({
    control,
    name: "jobInformationSectionType"
  });

  const generatedTextWatcher = useWatch({
    control,
    name: "generatedText"
  });

  const handleFormSubmit = async (data: {
    companyName: string;
    jobTitle: string;
    promptInput: string;
  }) => {
    const res = await jobInformationContent({
      jobData: {
        companyName: data.companyName,
        jobTitle: data.jobTitle,
        jobInformationSectionType: jobInformationSectionTypeWatcher
      },
      promptData: {
        promptId,
        promptInput: data.promptInput
      },
      locale: translate.getCurrentLocale()
    });
    const responseData = res?.data as {
      success: boolean;
      content: string;
      promptId: string;
    };
    if (responseData) {
      if (responseData.promptId) {
        setPromptId(responseData.promptId);
      }
      const blocksFromHtml = htmlToDraft(responseData.content);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const rawContentState = convertToRaw(contentState);
      setValue("generatedText", rawContentState);
    }
  };

  const handleResetForm = () => {
    reset({
      jobTitle: "",
      jobInformationSectionType: jobInformationSectionTypeWatcher,
      companyName: "",
      generatedText: convertToRaw(ContentState.createFromText("")),
      promptInput: ""
    });
  };

  useEffect(() => {
    if (jobInformationSectionTypeWatcher) {
      handleResetForm();
    }
  }, [jobInformationSectionTypeWatcher]);

  useEffect(() => {
    if (jobInformationContentError) {
      toast.kampai("something went wrong", "error");
    }
  }, [jobInformationContentError]);

  return (
    <Box noValidate component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack gap={1} direction="row" mb={2}>
        <Button type="submit" loading={isJobInformationContentLoading}>
          Generate Job Information
        </Button>
        <Button handleClick={() => handleResetForm()} color="secondary">
          Reset
        </Button>
      </Stack>
      <Radio
        disabled={isJobInformationContentLoading}
        control={control}
        name="jobInformationSectionType"
        label="Select job information option"
        direction="row"
        required
        options={[
          {
            label: "Job description",
            value: JOB_INFORMATION_SECTION_TYPE.JOB_DESCRIPTION
          },
          {
            label: "Job must requirements",
            value: JOB_INFORMATION_SECTION_TYPE.MUST_REQUIREMENT
          }
        ]}
      />
      <TextField
        disabled={isJobInformationContentLoading}
        control={control}
        name="companyName"
        label="Company name"
        placeholder="Enter company name"
        required
      />
      <TextField
        disabled={isJobInformationContentLoading}
        control={control}
        name="jobTitle"
        label="Job title"
        placeholder="Enter job title"
        required
      />
      {rawDraftContentStateToString(generatedTextWatcher)?.length > 0 ? (
        <Box>
          <Divider />
          <Typography mt={1} variant="subtitle2">
            Generated job information Output :-
          </Typography>
          <br />
          {showPromptInput ? (
            <>
              <TextField
                control={control}
                name="promptInput"
                label="Give instruction to customize the response"
                placeholder="Enter your instruction"
              />
              <Button type="submit" loading={isJobInformationContentLoading}>
                Generate
              </Button>
            </>
          ) : (
            <Box my={2}>
              <Button handleClick={() => setShowPromptInput(true)}>
                Click here to modify the response
              </Button>
            </Box>
          )}
          <Box my={1}>
            <WYSIWYG
              mode="edit"
              control={control}
              name="generatedText"
              label={intl.get(
                jobInformationSectionTypeWatcher ===
                  JOB_INFORMATION_SECTION_TYPE.JOB_DESCRIPTION
                  ? "t_job_post_job_information_step_job_description"
                  : "t_job_post_job_information_step_must_requirement"
              )}
              placeholder={intl.get(
                jobInformationSectionTypeWatcher ===
                  JOB_INFORMATION_SECTION_TYPE.JOB_DESCRIPTION
                  ? "t_job_post_job_information_step_job_description"
                  : "t_job_post_job_information_step_must_requirement_placeholder"
              )}
              defaultValue={initialFormValues.generatedText}
            />
          </Box>
        </Box>
      ) : (
        false
      )}
    </Box>
  );
};

export default GenerateJobInformation;
