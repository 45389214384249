import {
  Apartment as ApartmentIcon,
  PinDrop as PinDropIcon,
  Work as WorkIcon
} from "@mui/icons-material";
import { Box, Stack } from "@mui/material";

import CompanyLogo from "@components/CompanyLogo";
import EllipsisTypography from "@components/EllipsisTypography";

import Location from "@interfaces/database/Location";

import { getCountryName } from "@utils/location";
import { colorPalette } from "@utils/theme";

interface companyInfoProps {
  companyLogo?: string;
  companyName?: string;
  jobTitle?: string;
  location?: Location;
}

const CompanyInfo = ({
  companyLogo,
  companyName,
  jobTitle,
  location
}: companyInfoProps) => {
  let country = "";
  let city = "";

  if (location) {
    country = location?.country ? getCountryName(location?.country) : "-";
    city = location?.city ? `, ${location?.city}` : "";
  }

  return (
    <Box display="flex" alignItems="center" my={2} width="100%" gap={1}>
      <Box
        p={1}
        bgcolor={colorPalette.blue["04"]}
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={56}
        width={56}>
        <CompanyLogo src={companyLogo} alt={companyName} />
      </Box>
      <Stack gap={0.5}>
        <Box display="flex" gap={1} alignItems="center" color="text.secondary">
          <ApartmentIcon color="inherit" />
          <EllipsisTypography variant="body1" color="inherit" lineClamp={1}>
            {companyName ?? "-"}
          </EllipsisTypography>
        </Box>
        <Box display="flex" gap={1} alignItems="center" color="text.primary">
          <WorkIcon color="inherit" />
          <EllipsisTypography variant="subtitle2" color="inherit" lineClamp={1}>
            {jobTitle ?? "-"}
          </EllipsisTypography>
        </Box>
        <Box display="flex" gap={1} alignItems="center" color="text.secondary">
          <PinDropIcon color="inherit" />
          <EllipsisTypography variant="body1" color="inherit" lineClamp={1}>
            {country}
            {city}
          </EllipsisTypography>
        </Box>
      </Stack>
    </Box>
  );
};

export default CompanyInfo;
