import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { DialogContent, Stack } from "@mui/material";
import dayjs from "dayjs";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";

import Button from "@components/Button";
import Dialog from "@components/Dialog";
import Loader from "@components/Loader";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import { auth, functions, setAuthLocale } from "@utils/firebase";
import { resolveMultiLingual } from "@utils/multiLingual";
import { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

const VerificationEmailSentDialog = () => {
  const [isShowDialog, setShowDialog] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, isUserLoading] = useAuthState(auth);
  const { value } = useUserProfile();
  const toast = useToast();

  const currentLocale = translate.getCurrentLocale();
  const basicInformation = value?.summary?.basic_information;

  const [
    sendEmailVerification,
    isSendEmailVerificationLoading,
    sendEmailVerificationError
  ] = useHttpsCallable(functions, "verifyEmail");

  useEffect(() => {
    if (sendEmailVerificationError) {
      toast.kampai(intl.get("t_verify_email_error"), "error");
    }
  }, [sendEmailVerificationError]);

  const userFullName: string = `${
    resolveMultiLingual(basicInformation?.last_name) ?? ""
  } ${resolveMultiLingual(basicInformation?.first_name) ?? ""}`.trim();

  useEffect(() => {
    const isSendVerificationEmail =
      searchParams.get("send_verification_email") ?? "";
    if (user) {
      if (isSendVerificationEmail && !user?.emailVerified) {
        setShowDialog(true);
        setAuthLocale();
        const url =
          window.location.protocol +
          "//" +
          window.location.hostname +
          ":" +
          window.location.port;
        sendEmailVerification({
          returnURL: url,
          locale: currentLocale,
          fullName: userFullName ? userFullName : user.displayName ?? ""
        });
      } else {
        deleteSearchParam();
      }
    }
  }, [searchParams, user]);

  const deleteSearchParam = () => {
    searchParams.delete("send_verification_email");
    setSearchParams(searchParams.toString(), { replace: true });
  };

  return (
    <>
      {isUserLoading || isSendEmailVerificationLoading ? <Loader /> : false}
      {isShowDialog ? (
        <Dialog
          initiator=""
          isDefaultOpen
          maxWidth="xs"
          title={intl.get("t_signinsignup_verify_your_email")}
          onClose={deleteSearchParam}>
          {(_, closeDialog) => (
            <DialogContent>
              <Stack direction="column" gap={1}>
                <Typography variant="subtitle4">
                  {intl.get("t_verify_email_title", {
                    email: user?.email
                  })}
                </Typography>
                <Typography
                  variant="body1"
                  color={colorPalette.black.hover.base}>
                  {intl.get("t_verify_email_subtitle")}
                </Typography>
                <Button fullWidth handleClick={closeDialog}>
                  {intl.get("t_general_close")}
                </Button>
                <Typography
                  variant="body2"
                  color={colorPalette.black[80]}
                  width="100%"
                  mt={2}
                  textAlign="center">
                  {intl.get("t_general_copyright", {
                    year: dayjs().format("YYYY")
                  })}
                </Typography>
              </Stack>
            </DialogContent>
          )}
        </Dialog>
      ) : (
        false
      )}
    </>
  );
};

export default VerificationEmailSentDialog;
