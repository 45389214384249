import { ReactNode, useEffect, useState } from "react";

import { Control, Controller, UseFormSetValue } from "react-hook-form";

import {
  ArrowDropDownRounded as ArrowDropDownRoundedIcon,
  CloseRounded as CloseRoundedIcon
} from "@mui/icons-material";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  InputAdornment,
  InputLabel,
  SxProps,
  TextField,
  Theme
} from "@mui/material";

import KeyLabel from "@interfaces/components/KeyLabel";

import { intl } from "@utils/translate";

interface AutoCompleteTextFieldProps {
  "options"?: Array<KeyLabel>;
  "placeholder"?: string;
  "name": string;
  "control": Control<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  "data-testid"?: string;
  "disabled"?: boolean;
  "label"?: string;
  "required"?: boolean;
  "helperText"?: string;
  "startAdornment"?: ReactNode;
  "endAdornment"?: ReactNode;
  "multiple"?: boolean;
  "getOptions"?: (str: string) => Promise<Array<KeyLabel>>;
  "setValue": UseFormSetValue<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  "disableClearable"?: boolean;
  "sx"?: SxProps<Theme>;
}

const AutoCompleteTextField = ({
  "options": optionsProp = [],
  placeholder,
  required = false,
  label,
  name,
  control,
  disabled = false,
  helperText = "",
  startAdornment,
  endAdornment,
  getOptions,
  multiple = false,
  setValue,
  disableClearable = true,
  sx,
  "data-testid": dataTestId
}: AutoCompleteTextFieldProps) => {
  // Reconstruct to prevent from reference typing.
  const [options, setOptions] = useState<Array<KeyLabel>>([...optionsProp]);
  const [open, setOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isNoOptions, setNoOptions] = useState<boolean>(false);

  useEffect(() => {
    let active = true;

    if (!getOptions || open === false) {
      return;
    }
    if (inputValue === "") {
      setOpen(false);
    }

    setLoading(true);
    setNoOptions(false);
    setOpen(true);

    (async () => {
      const newOptions = await getOptions(inputValue);
      if (active) {
        setOptions(newOptions);
        if (newOptions.length > 0) {
          setNoOptions(false);
        } else {
          setNoOptions(true);
        }
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [inputValue]);

  const setSelectedValues = (selectedValues: Array<KeyLabel>) => {
    if (setValue) {
      if (selectedValues.length === 0) {
        setValue(name, multiple ? [] : null);
      } else {
        setValue(name, multiple ? selectedValues : selectedValues[0], {
          shouldValidate: true
        });
      }
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ref }, fieldState: { error } }) => {
        const selectedValues: Array<KeyLabel> = value;
        return (
          <>
            {label ? (
              <InputLabel
                htmlFor={name}
                required={required}
                disabled={disabled}>
                {label}
              </InputLabel>
            ) : (
              false
            )}
            <Autocomplete
              disabled={disabled}
              clearIcon={<CloseRoundedIcon />}
              popupIcon={<ArrowDropDownRoundedIcon />}
              value={selectedValues as any} // eslint-disable-line @typescript-eslint/no-explicit-any
              onChange={(_, newValues) => {
                newValues = Array.isArray(newValues)
                  ? newValues.map((singleValue) => ({
                      key: singleValue.key ?? singleValue,
                      label: singleValue.label ?? singleValue
                    }))
                  : [
                      {
                        key: newValues.key ?? newValues,
                        label: newValues.label ?? newValues
                      }
                    ];
                if (
                  newValues.at(-1)?.label?.toLowerCase() ===
                  newValues.at(-2)?.label?.toLowerCase()
                ) {
                  newValues.splice(-2, 1);
                }
                const textValueIndex = newValues.findLastIndex(
                  (singleValue: KeyLabel) => {
                    return (
                      singleValue?.label?.toLowerCase() ===
                      inputValue?.toLowerCase()
                    );
                  }
                );
                if (
                  textValueIndex !== -1 &&
                  textValueIndex !== newValues.length - 1
                ) {
                  newValues.splice(textValueIndex, 1);
                }

                setSelectedValues(newValues);
                setOptions(optionsProp);
              }}
              multiple={multiple}
              id="auto-completed-component"
              options={options}
              freeSolo
              autoComplete
              includeInputInList
              sx={sx}
              onInputChange={(event, value) => {
                if (event === null) {
                  setInputValue(value);
                  return;
                }

                let previousSelectedValues = selectedValues ?? [];
                if (multiple) {
                  const lastPreviousSelectedValue =
                    previousSelectedValues?.at(-1);
                  if (lastPreviousSelectedValue?.label === inputValue) {
                    previousSelectedValues.pop();
                  }
                } else {
                  previousSelectedValues = [];
                }

                if (value !== "") {
                  previousSelectedValues.push({
                    key: value.toLowerCase(),
                    label: value
                  });
                }

                setSelectedValues(previousSelectedValues);
                setInputValue(value);
              }}
              filterOptions={(options, state) => {
                if (loading) {
                  return [];
                }
                const inputValueLower = state?.inputValue?.toLowerCase().trim();
                const filteredOptions = options.filter(
                  (option) =>
                    option?.key?.toLowerCase().startsWith(inputValueLower) ||
                    option?.label?.toLowerCase().startsWith(inputValueLower)
                );
                if (inputValueLower !== "") {
                  // Check if the input value is present in the options list.
                  const isOptionPresentInOptionList = options?.some(
                    (singleOption) =>
                      singleOption?.label?.toLowerCase() === inputValueLower
                  );
                  if (!isOptionPresentInOptionList) {
                    filteredOptions.push({
                      key: inputValueLower,
                      label: state.inputValue.trim()
                    });
                  }
                }

                return filteredOptions;
              }}
              isOptionEqualToValue={() => {
                return false;
              }}
              disableClearable={disableClearable}
              filterSelectedOptions
              disablePortal
              getOptionLabel={(singleOption) => {
                return singleOption.label;
              }}
              renderOption={(props, singleOption: KeyLabel) => {
                let label = singleOption.label;

                if (inputValue === singleOption.key) {
                  label = multiple
                    ? intl.get("t_general_add_label", {
                        label: singleOption.label
                      })
                    : intl.get("t_general_select", {
                        label: singleOption.label
                      });
                }

                if (
                  options.length === 0 ||
                  !options.some((option) => option.key === singleOption.key)
                ) {
                  label = multiple
                    ? intl.get("t_general_add_label", {
                        label: singleOption.label
                      })
                    : intl.get("t_general_select", {
                        label: singleOption.label
                      });
                }
                return <li {...props}>{label}</li>;
              }}
              loading={loading}
              handleHomeEndKeys
              loadingText={
                isNoOptions
                  ? intl.get("t_auto_complete_no_options")
                  : intl.get("t_auto_complete_loading")
              }
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              renderTags={(_, getTagProps) =>
                selectedValues
                  .filter((singleSelectedValue, index, selectedValues) => {
                    if (index === selectedValues.length - 1) {
                      return singleSelectedValue.label !== inputValue;
                    } else {
                      return true;
                    }
                  })
                  .map((singleSelectedValue, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={index}
                      label={singleSelectedValue.label}
                    />
                  ))
              }
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    data-testid={dataTestId}
                    disabled={disabled}
                    margin="dense"
                    placeholder={placeholder}
                    error={!!error}
                    helperText={error ? error.message : helperText}
                    ref={ref}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: multiple ? (
                        params.InputProps.startAdornment
                      ) : (
                        <InputAdornment position="start">
                          {startAdornment}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <>
                          {loading && !isNoOptions ? (
                            <InputAdornment position="end">
                              <CircularProgress color="inherit" size={24} />
                            </InputAdornment>
                          ) : (
                            false
                          )}
                          {multiple ? (
                            params.InputProps.endAdornment
                          ) : (
                            <InputAdornment position="end">
                              {endAdornment}
                            </InputAdornment>
                          )}
                        </>
                      )
                    }}
                  />
                );
              }}
            />
          </>
        );
      }}
    />
  );
};

export default AutoCompleteTextField;
