import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Box, Divider, IconButton, Stack } from "@mui/material";

import Avatar from "@components/Avatar";
import CandidateResumeDrawer from "@components/Conversation/ConversationDrawer/CandidateResumeDrawer";
import EmployerJobDetailsDrawer from "@components/Conversation/ConversationDrawer/EmployerJobDetailsDrawer";
import Drawer from "@components/Drawer";
import EllipsisTypography from "@components/EllipsisTypography";

import { colorPalette } from "@utils/theme";
import { intl } from "@utils/translate";

interface ConversationContainerHeaderProps {
  avatarVariant?: "circular" | "rounded";
  imgSrc: string;
  initials: string;
  name: string;
  candidateJobTitle?: string;
  applyingForJobTitle?: string;
  backBtnLink: string;
  variant?: "employer-conversation-header" | "candidate-conversation-header";
}

const ConversationContainerHeader = ({
  variant = "employer-conversation-header",
  avatarVariant = "circular",
  imgSrc,
  initials,
  name,
  candidateJobTitle,
  applyingForJobTitle,
  backBtnLink
}: ConversationContainerHeaderProps) => {
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <Box position="sticky" top={0} mb={1} bgcolor="background.default">
      <Stack
        direction="row"
        columnGap={1}
        alignItems="center"
        p={variant === "employer-conversation-header" ? 1.5 : 1.75}>
        <Box display={{ xs: "block", md: "none" }}>
          <IconButton onClick={() => navigate(`${backBtnLink}`)}>
            <ArrowBackIcon fontSize="small" />
          </IconButton>
        </Box>
        <Drawer
          initiator={
            <Stack direction="row" gap={1} sx={{ cursor: "pointer" }}>
              <Avatar
                size="medium"
                imgSrc={imgSrc}
                initials={initials}
                variant={avatarVariant}
              />
              <Stack width={{ xs: "100%", md: "max-content" }}>
                <EllipsisTypography
                  variant="subtitle4"
                  color="text.primary"
                  lineClamp={1}>
                  {name}
                </EllipsisTypography>
                {variant === "employer-conversation-header" ? (
                  <>
                    <Box display={{ xs: "none", md: "block" }}>
                      <EllipsisTypography
                        lineClamp={1}
                        variant="body2"
                        color="text.secondary">
                        {candidateJobTitle
                          ? candidateJobTitle
                          : intl.get("t_general_fresher")}
                      </EllipsisTypography>
                    </Box>
                    <Box display={{ xs: "block", md: "none" }}>
                      <EllipsisTypography
                        variant="body2"
                        lineClamp={1}
                        color="text.secondary">
                        {candidateJobTitle
                          ? candidateJobTitle
                          : intl.get("t_general_fresher")}
                        &nbsp;|&nbsp;
                        {intl.get(
                          "t_conversations_container_header_applying_for_job_title",
                          { jobTitle: applyingForJobTitle }
                        )}
                      </EllipsisTypography>
                    </Box>
                  </>
                ) : (
                  <EllipsisTypography
                    lineClamp={1}
                    variant="body2"
                    color="text.secondary">
                    {applyingForJobTitle}
                  </EllipsisTypography>
                )}
              </Stack>
            </Stack>
          }
          drawerContent={
            variant === "employer-conversation-header" ? (
              <CandidateResumeDrawer />
            ) : (
              <EmployerJobDetailsDrawer />
            )
          }
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
        />

        {variant === "employer-conversation-header" ? (
          <>
            <Box
              borderLeft={`1px solid ${colorPalette.grey.base}`}
              height={44}
              mx={2}
              display={{ xs: "none", md: "block" }}
            />
            <Box display={{ xs: "none", md: "block" }}>
              <EllipsisTypography variant="body2">
                {intl.get(
                  "t_conversations_container_header_applying_for_job_title",
                  { jobTitle: applyingForJobTitle }
                )}
              </EllipsisTypography>
            </Box>
          </>
        ) : (
          false
        )}
      </Stack>
      <Divider />
    </Box>
  );
};

export default ConversationContainerHeader;
