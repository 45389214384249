import {
  Avatar,
  Box,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps
} from "@mui/material";

import EllipsisTypography from "@components/EllipsisTypography";
import Typography from "@components/Typography";

import { colorPalette } from "@utils/theme";

interface TestimonialCardProps {
  fullName: string;
  jobTitle: string;
  textContent: string;
  profileImg: string;
  avatarVariant?: "rounded" | "circular" | "square";
}

const StyledBox = styled(Box)(({ theme }) => {
  return {
    backgroundColor: colorPalette.lightBlue.background[30],
    color: theme.palette.common.white,
    borderRadius: 4,
    padding: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 8
    }
  };
});

const StyledAvatar = styled(Avatar)(() => {
  return {
    height: 80,
    width: 80
  };
});

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400
  }
});

const TestimonialCard = ({
  fullName,
  jobTitle,
  textContent,
  profileImg,
  avatarVariant = "circular"
}: TestimonialCardProps) => {
  return (
    <StyledBox>
      <StyledTooltip
        title={<Typography variant="body2">{textContent}</Typography>}>
        {/* image and full name start*/}
        <Box>
          <Box display="flex" alignItems="center" gap={1} mb={2}>
            <StyledAvatar src={profileImg} variant={avatarVariant} />
            <Stack>
              {/* FIXME: Update variant once responsive font guid is fixed */}
              <Typography variant="subtitle4">{fullName}</Typography>
              <Typography variant="body1">{jobTitle}</Typography>
            </Stack>
          </Box>
          {/* image and full name end*/}

          {/* review text start */}
          <EllipsisTypography
            variant="body1"
            lineClamp={8}
            color="primary.contrastText">
            {textContent}
          </EllipsisTypography>
          {/* review text end */}
        </Box>
      </StyledTooltip>
    </StyledBox>
  );
};

export default TestimonialCard;
