import { useEffect, useState } from "react";

import { Box, Stack } from "@mui/material";

import SkeletonKeyword from "@skeletons/SkeletonKeyword";
import SkeletonProfileSummaryDrawerTab from "@skeletons/SkeletonProfileSummaryDrawerTab";

import Avatar from "@components/Avatar";
import CVFilledStatus from "@components/CVFilledStatus";
import Icon from "@components/Icon";
import InformationField from "@components/InformationField";
import Tag from "@components/Tag";
import Typography from "@components/Typography";

import Skill from "@interfaces/database/Skill";
import UserProfile from "@interfaces/database/UserProfile";

import {
  INDIVIDUAL_USER_VISA_TYPE_T_LABELS,
  JA_LANGUAGE_TEST_T_LABELS,
  LANGUAGE_PROFICIENCY_T_LABELS,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import fetchInitials from "@utils/fetchInitials";
import { jaTestResultFinder } from "@utils/jaTestResultFinder";
import { getSkillLabel } from "@utils/keyLabelHandlers/skill";
import { getLanguageName } from "@utils/language";
import { getCountryName, getFullLocation } from "@utils/location";
import { resolveMultiLingual } from "@utils/multiLingual";
import { roundSalary } from "@utils/roundSalary";
import theme from "@utils/theme";
import translate, { intl } from "@utils/translate";

import VisaIcon from "@assets/images/Visa.svg";

interface ProfileSummaryTabProps {
  user: UserProfile | null;
  userProfilePicUrl: string;
}

const ProfileSummaryTab = ({
  user,
  userProfilePicUrl
}: ProfileSummaryTabProps) => {
  const [skills, setSkills] = useState<Array<Skill>>([]);
  const [isSkillsLoading, setIsSkillsLoading] = useState<boolean>(true);

  const basicInformation = user?.summary?.basic_information;
  const noJobExperience = user?.summary?.no_job_experience_overview;
  const jobExperienceOverviews = user?.summary?.job_experience_overview ?? [];
  const jobTitleId =
    user?.summary?.basic_information?.primary_job_title_index ?? 0; //FIXME: Recheck index 0 logic
  const languages = user?.summary?.languages ?? [];
  const attachedResumes = user?.metadata?.progress_flags;

  const firstName = resolveMultiLingual(basicInformation?.first_name) ?? "";
  const lastName = resolveMultiLingual(basicInformation?.last_name) ?? "";
  const initials = fetchInitials(`${lastName} ${firstName}`);

  const location = getFullLocation(
    basicInformation?.current_location?.city,
    basicInformation?.current_location?.country
  );

  //Basic Information
  const basicInformationData = {
    // Dummy email and phone number
    phoneNumber: "+91-123456789",
    email: "user@tokhimo.com",
    countryOfOrigin: basicInformation?.immigration_details?.country ?? "",
    visaType: basicInformation?.immigration_details?.visa_type,
    noOfCompanies: basicInformation?.number_of_companies,
    //FIXME: Update once NaN issue is resolved
    expectedSalary: basicInformation?.expected_salary ?? -1
  };

  //Job Overview
  const jobExperienceData = !noJobExperience
    ? jobExperienceOverviews?.map((singleJobOverview) => {
        return {
          jobTitle: resolveMultiLingual(singleJobOverview.job_title ?? ""),
          yearsOfExperience: singleJobOverview.years_of_experience
        };
      })
    : [];

  //Language
  const languageData = languages?.map((singleLanguage) => {
    return {
      name: singleLanguage?.name,
      proficiency: singleLanguage?.proficiency,
      testName: singleLanguage?.test_name,
      testResult: singleLanguage?.test_result
    };
  });

  useEffect(() => {
    (async () => {
      setIsSkillsLoading(true);
      const skills = [];
      if (user?.summary?.skills && Array.isArray(user.summary.skills)) {
        for (const singleSkill of user.summary.skills) {
          const skillLabel = await getSkillLabel(
            singleSkill.name,
            translate.getCurrentLocale()
          );
          skills.push({
            name: skillLabel,
            years_of_experience: singleSkill.years_of_experience
          });
        }
      }
      setSkills(skills);
      setIsSkillsLoading(false);
    })();
  }, [user]);

  return (
    <Box mb={2}>
      {!user ? (
        <SkeletonProfileSummaryDrawerTab />
      ) : (
        <>
          <Stack direction="row" alignItems="center" gap={1}>
            <Avatar imgSrc={userProfilePicUrl} initials={initials} />
            <Box>
              <Typography variant="subtitle2">
                {noJobExperience
                  ? intl.get("t_general_fresher")
                  : jobExperienceData?.[jobTitleId]?.jobTitle ?? "-"}
              </Typography>
              <br />
              <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                textTransform="capitalize">
                {location}
              </Typography>
              <br />
            </Box>
          </Stack>

          <Stack gap={2} my={2} width="100%">
            {/* Basic Information */}
            <Stack
              direction={{ xs: "column", md: "row" }}
              gap={{ xs: 1, md: 2 }}
              flexWrap="wrap">
              <InformationField
                icon="home"
                label={getCountryName(basicInformationData?.countryOfOrigin)}
              />
              <InformationField
                imgSrc={VisaIcon}
                imgAlt={intl.get("t_alt_visa_types")}
                label={
                  basicInformationData?.visaType
                    ? intl.get(
                        INDIVIDUAL_USER_VISA_TYPE_T_LABELS[
                          basicInformationData?.visaType
                        ]
                      )
                    : "-"
                }
              />
              {/* Not displayed when user have no job experience (fresher)*/}
              {!noJobExperience ? (
                <InformationField
                  icon="apartment"
                  label={`${
                    basicInformationData?.noOfCompanies
                      ? intl.get("t_resumes_basic_info_companies", {
                          noOfCompanies: basicInformationData?.noOfCompanies
                        })
                      : "-"
                  } `}
                />
              ) : (
                false
              )}
              <InformationField
                icon="currency_yen"
                label={
                  basicInformationData?.expectedSalary
                    ? roundSalary(basicInformationData?.expectedSalary)
                    : "-"
                }
              />
              <InformationField
                icon="mail"
                label={basicInformationData?.email}
                blurText
              />
              <InformationField
                icon="phone_enabled"
                label={basicInformationData?.phoneNumber}
                blurText
              />
            </Stack>

            {/* Experience */}
            <Typography variant="subtitle2">
              {intl.get("t_profile_summary_experience")}
            </Typography>
            {jobExperienceData?.length > 0 ? (
              <Stack gap={1} flexWrap="wrap" direction="row">
                {jobExperienceData?.map((singleExperience, index) => (
                  <Stack direction="row" alignItems="center" key={index}>
                    <Icon type="work" filled />
                    <Box ml={1.25} component="span">
                      <Typography variant="subtitle4">
                        {singleExperience.jobTitle},&nbsp;
                      </Typography>
                      <Typography variant="body1" whiteSpace="nowrap">
                        {intl.get(
                          YEARS_OF_EXPERIENCE_T_LABELS[
                            singleExperience.yearsOfExperience
                          ]
                        )}
                      </Typography>
                    </Box>
                  </Stack>
                ))}
              </Stack>
            ) : (
              <Typography variant="body1">
                {noJobExperience ? intl.get("t_general_fresher") : "-"}
              </Typography>
            )}

            {/* Language */}
            <Typography variant="subtitle2">
              {intl.get("t_profile_summary_language")}
            </Typography>
            {languageData?.length > 0 ? (
              <Stack direction="column" gap={1}>
                {languageData?.map((singleLanguage, index) => (
                  <Box component="span" key={index}>
                    <Typography variant="subtitle4">
                      {intl.get("t_language_proficiency_level", {
                        language: getLanguageName(singleLanguage?.name)
                      })}
                      &nbsp;
                    </Typography>
                    <Typography variant="body1">
                      {intl.get(
                        LANGUAGE_PROFICIENCY_T_LABELS[
                          singleLanguage.proficiency
                        ]
                      )}
                      {index === 0 && singleLanguage?.testName ? (
                        // first index (index = 0) is always japanese language
                        <>
                          {singleLanguage?.testName
                            ? `, ${intl.get(
                                JA_LANGUAGE_TEST_T_LABELS[
                                  singleLanguage.testName as keyof typeof JA_LANGUAGE_TEST_T_LABELS
                                ]
                              )}: ${jaTestResultFinder(
                                singleLanguage.testName,
                                singleLanguage.testResult
                              )}`
                            : false}
                        </>
                      ) : (
                        <>
                          {singleLanguage?.testName
                            ? `, ${singleLanguage.testName}: ${singleLanguage.testResult}`
                            : false}
                        </>
                      )}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            ) : (
              <Typography variant="body1">-</Typography>
            )}

            {/* Skills */}
            <Typography variant="subtitle2">
              {intl.get("t_profile_summary_skill")}
            </Typography>
            {isSkillsLoading ? (
              <SkeletonKeyword multiple />
            ) : skills?.length > 0 ? (
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {skills?.map((singleSkill, index) => (
                  <Tag
                    label={`${singleSkill.name} ${intl.get(
                      YEARS_OF_EXPERIENCE_T_LABELS[
                        singleSkill.years_of_experience
                      ]
                    )}`}
                    key={index}
                  />
                ))}
              </Stack>
            ) : (
              <Typography variant="body1">-</Typography>
            )}

            {/* Attached Resumes */}
            <Typography variant="subtitle2">
              {intl.get("t_profile_summary_attached_resumes")}
            </Typography>
            {/* FIXME: Update attached resume logic after meta data is implemented */}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              gap={1}
              flexWrap="wrap"
              width="100%"
              alignItems={{ xs: "start", sm: "center" }}>
              <CVFilledStatus
                label={intl.get("t_general_english_cv")}
                isAttached={attachedResumes?.en_cv ?? false}
              />
              <CVFilledStatus
                label={intl.get("t_general_japanese_cv")}
                isAttached={attachedResumes?.ja_cv ?? false}
              />
              <CVFilledStatus
                label={intl.get("t_general_japanese_rirekisho")}
                isAttached={attachedResumes?.ja_rirekisho ?? false}
              />
            </Stack>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default ProfileSummaryTab;
