import { Link, useLocation } from "react-router-dom";

import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from "@mui/material";

import Typography from "@components/Typography";

import translate, { intl } from "@utils/translate";

const Navigation = () => {
  const location = useLocation();
  const pathname = translate.getNonLocalizedURL(location.pathname);
  return (
    <>
      <Typography variant="h5">
        {intl.get("t_debugger_page_switcher")}:
      </Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">Candidate</Typography>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/`}
                selected={pathname === "/"}>
                <ListItemText>Home</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/search`}
                selected={pathname === "/search"}>
                <ListItemText>Search</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/search/abcd`}
                selected={pathname === "/search/abcd"}>
                <ListItemText>Search With Job Selected</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/profile`}
                selected={pathname === "/profile"}>
                <ListItemText>Profile</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/profile/edit`}
                selected={pathname === "/profile/edit"}>
                <ListItemText>Profile Edit</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/profile/resume`}
                selected={pathname === "/profile/resume"}>
                <ListItemText>Resume (Default English CV)</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/profile/resume/en-cv`}
                selected={pathname === "/profile/resume/en-cv"}>
                <ListItemText>English CV</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/profile/resume/ja-cv`}
                selected={pathname === "/profile/resume/ja-cv"}>
                <ListItemText>Japanese CV</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/profile/resume/ja-rirekisho`}
                selected={pathname === "/profile/resume/ja-rirekisho"}>
                <ListItemText>Japanese Rirekisho</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/profile/jobs`}
                selected={pathname === "/profile/jobs"}>
                <ListItemText>Saved/Applied/Invited Jobs</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/profile/settings`}
                selected={pathname === "/profile/settings"}>
                <ListItemText>Account Settings</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/conversations`}
                selected={pathname === "/conversations"}>
                <ListItemText>Conversations</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">Employers</Typography>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/employers`}
                selected={pathname === "/employers"}>
                <ListItemText>Home</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/employers/dashboard`}
                selected={pathname === "/employers/dashboard"}>
                <ListItemText>Dashboard</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/employers/jobs/new`}
                selected={pathname === "/employers/jobs/new"}>
                <ListItemText>New Job Posting (Starting point)</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/employers/search`}
                selected={pathname === "/employers/search"}>
                <ListItemText>Candidate Search Screen</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/employers/saved-candidates`}
                selected={pathname === "/employers/saved-candidates"}>
                <ListItemText>Saved Candidates</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/employers/settings`}
                selected={pathname === "/employers/settings"}>
                <ListItemText>Account Settings</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/employers/settings/company-settings`}
                selected={pathname === "/employers/settings/company-settings"}>
                <ListItemText>Company Settings</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/employers/settings/resume-purchases`}
                selected={pathname === "/employers/settings/resume-purchases"}>
                <ListItemText>Resume Purchases</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/employers/conversations`}
                selected={pathname === "/employers/conversations"}>
                <ListItemText>Conversations</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <List>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/not-found`}
                selected={pathname === "/not-found"}>
                <ListItemText>Not Found</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/frequently-asked-questions`}
                selected={pathname === "/frequently-asked-questions"}>
                <ListItemText>FAQ</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/privacy-policy`}
                selected={pathname === "/privacy-policy"}>
                <ListItemText>Privacy Policy</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/${translate.getCurrentLocale()}/user-terms-and-conditions`}
                selected={pathname === "/user-terms-and-conditions"}>
                <ListItemText>Terms and Conditions</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default Navigation;
