import { Box } from "@mui/material";

import Typography from "@components/Typography";

interface StatisticsCardProps {
  label: string;
  count: number;
}

const StatisticsCard = ({ label, count }: StatisticsCardProps) => {
  return (
    <Box bgcolor="background.paper" borderRadius={1} p={2} width="100%">
      <Typography variant="h5" p={1}>
        {label}
      </Typography>
      <br />
      <Typography variant="h3" p={1} color="primary.main">
        {count}
      </Typography>
    </Box>
  );
};

export default StatisticsCard;
