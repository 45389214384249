import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import pMemoize from "p-memoize";

import MetaSkills from "@interfaces/database/MetaSkills";

import { FIRESTORE_COLLECTIONS, LOCALE, SKILL_STATUS } from "@utils/config";
import { auth, db } from "@utils/firebase";
import { prepareMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import translate from "@utils/translate";

const getSkillByKeyNonMemoized = async (
  key = ""
): Promise<MetaSkills | undefined> => {
  const q = query(
    collection(db, FIRESTORE_COLLECTIONS.META_SKILLS),
    where("key", "==", key)
  );
  const docs = await getDocs(q);
  if (docs.size > 0) {
    return docs.docs[0].data() as MetaSkills;
  }
};

const getSkillByLabelNonMemoized = async (
  label = "",
  locale = translate.getCurrentLocale()
): Promise<MetaSkills | undefined> => {
  let whereKey = "label.ja";
  switch (locale) {
    case LOCALE.EN:
      whereKey = "label.en";
      break;
    case LOCALE.JA:
      whereKey = "label.ja";
      break;
    default:
      whereKey = "label.ja";
      break;
  }

  const q = query(
    collection(db, FIRESTORE_COLLECTIONS.META_SKILLS),
    where(whereKey, "==", label),
    where("status", "==", SKILL_STATUS.OK)
  );
  const docs = await getDocs(q);
  if (docs.size > 0) {
    return docs.docs[0].data() as MetaSkills;
  }
};

const isSkillExistsByKeyNonMemoized = async (key = ""): Promise<boolean> => {
  if (key !== "") {
    const skill = await getSkillByKey(key);
    if (skill !== undefined) {
      return true;
    }
  }
  return false;
};

const isSkillExistsByLabelNonMemoized = async (
  label = "",
  locale = translate.getCurrentLocale()
): Promise<boolean> => {
  if (label !== "") {
    const skill = await getSkillByLabel(label, locale);
    if (skill !== undefined) {
      return true;
    }
  }
  return false;
};

/**
 * This adds a new Skill to the Skills List.
 * Newly added Skill is in Pending state, so will NOT show up in the search.
 * However, when checking for label, it will return the correct Label.
 *
 * @param label     Label of the Skill.
 * @param locale    Locale of the Label when the Skill is added, if not passed, UI's locale is taken
 * @returns         Key of the skill, if added successfully, else empty string.
 */
const addSkill = async (
  label = "",
  locale = translate.getCurrentLocale()
): Promise<string> => {
  // Only logged in users can add a new Skill.
  const currentUserId = auth.currentUser?.uid;

  // Check for existing Skill.
  const isAlreadyExists = await isSkillExistsByLabel(label, locale);
  if (label !== "" && currentUserId !== "") {
    if (!isAlreadyExists) {
      const skillKey = prepareKeyFromLabel(label);
      const skillData: MetaSkills = {
        key: skillKey,
        label: prepareMultiLingual(label),
        alias_keys: [],
        status: SKILL_STATUS.PENDING,
        created_at: Timestamp.now(),
        updated_at: Timestamp.now(),
        metadata: {
          added_by: currentUserId
        }
      };
      const collectionRef = collection(db, FIRESTORE_COLLECTIONS.META_SKILLS);
      try {
        const newDoc = await addDoc(collectionRef, skillData);
        if (newDoc.id !== undefined) {
          return skillKey;
        } else {
          return "";
        }
      } catch (e) {
        return "";
      }
    }
  }
  return "";
};

// This is an internal function, not to be exposed.
const prepareKeyFromLabel = (label = ""): string => {
  if (label !== "") {
    //Start preparing the Key
    let key = label;

    // Convert the string to lowercase
    key = key.toLowerCase();

    // Replace space or any character which is not [a-z0-9], Hiragana, Katakana, or Kanji with underscore
    key = key.replace(
      /[^a-z0-9\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]+/g,
      "_"
    );

    // Remove duplicate underscores which are continuous
    key = key.replace(/_{2,}/g, "_");

    // Trim underscores from the start and end of the string
    key = key.replace(/^_+|_+$/g, "");

    // Append a random 6 digit alphanumeric string with _
    const randomAlphanumeric = Math.random().toString(36).substring(2, 8);
    key = `${key}_${randomAlphanumeric}`;

    return key;
  }

  return "";
};

const getSkillByKey = pMemoize(getSkillByKeyNonMemoized);
const getSkillByLabel = pMemoize(getSkillByLabelNonMemoized);
const isSkillExistsByKey = pMemoize(isSkillExistsByKeyNonMemoized);
const isSkillExistsByLabel = pMemoize(isSkillExistsByLabelNonMemoized);

export {
  addSkill,
  getSkillByKey,
  getSkillByLabel,
  isSkillExistsByKey,
  isSkillExistsByLabel,
  getSkillByKeyNonMemoized,
  getSkillByLabelNonMemoized,
  isSkillExistsByKeyNonMemoized,
  isSkillExistsByLabelNonMemoized
};
