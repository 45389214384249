import { Grid } from "@mui/material";

import SkeletonInput from "@skeletons/SkeletonInput";

const SkeletonJobOverviewForm = () => {
  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={6}>
          <SkeletonInput />
        </Grid>
        <Grid item xs={12} md={6}>
          <SkeletonInput />
        </Grid>
        <Grid item xs={6}>
          <SkeletonInput />
        </Grid>
        <Grid item xs={6} mt={2.5}>
          <SkeletonInput hasLabel={false} />
        </Grid>
        <Grid item xs={12} mb={2.5}>
          <SkeletonInput hasLabel={false} />
        </Grid>
        <Grid item xs={12}>
          <SkeletonInput />
        </Grid>
        <Grid item xs={12}>
          <SkeletonInput />
        </Grid>
        <Grid item xs={12}>
          <SkeletonInput />
        </Grid>
        <Grid item xs={12}>
          <SkeletonInput />
        </Grid>
      </Grid>
    </>
  );
};

export default SkeletonJobOverviewForm;
