import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";

import JobOverviewForm from "@components/ResumeForms/JobOverviewForm";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import {
  LOCALE,
  LOCALE_SHORT,
  MAX_WORK_EXPERIENCES,
  YEARS_OF_EXPERIENCE
} from "@utils/config";
import { auth } from "@utils/firebase";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface JobOverviewFormData {
  jobTitle?: string;
  yearOfExperience?:
    | typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE]
    | string;
  primaryJobTitle?: Array<number>;
}

const EnglishResumeEditJobExperienceOverview = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const { item_id } = useParams();
  const userData = useUserProfile();
  const toast = useToast();
  const itemId = Number(item_id);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const singleJobExperienceOverview =
    userData?.value?.summary?.job_experience_overview?.[itemId];
  const userJobExperienceOverview =
    userData.value?.summary?.job_experience_overview ?? [];
  const userPrimaryJobTitleIndex =
    userData.value?.summary?.basic_information?.primary_job_title_index ===
    undefined
      ? 0
      : userData.value?.summary?.basic_information?.primary_job_title_index;

  const initialValues =
    item_id || !userData.value?.summary?.no_job_experience_overview
      ? {
          jobTitle: resolveMultiLingual(
            singleJobExperienceOverview?.job_title,
            LOCALE.EN
          ),
          yearOfExperience: singleJobExperienceOverview?.years_of_experience,
          primaryJobTitle:
            itemId ===
            userData.value?.summary?.basic_information?.primary_job_title_index
              ? [
                  userData.value?.summary?.basic_information
                    ?.primary_job_title_index
                ]
              : userJobExperienceOverview?.length === 0 // if first job experience then by default checked checkbox
              ? [0]
              : []
        }
      : { jobTitle: "", yearsOfExperience: "", primaryJobTitle: [0] };

  useEffect(() => {
    if (
      item_id &&
      (!initialValues.jobTitle || !initialValues.yearOfExperience)
    ) {
      navigate(
        `/${translate.getCurrentLocale()}/profile/resume/en-cv/edit/job-experience-overview`,
        {
          replace: true
        }
      );
    }
  }, []);

  const handleJobExperienceOverviewSavedSuccess = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_success_resume_saved"), "success");
  };

  const handleJobExperienceOverviewSavedFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleAdd = async (formData: JobOverviewFormData) => {
    setIsDisabled(true);
    if (userJobExperienceOverview.length >= MAX_WORK_EXPERIENCES) {
      toast.kampai(
        intl.get("t_toast_error_limit_exceed", {
          fieldName: intl.get("t_resumes_job_overview_title"),
          maxLimit: MAX_WORK_EXPERIENCES
        }),
        "error"
      );
      return;
    }

    const { jobTitle, yearOfExperience, primaryJobTitle } = formData || {};
    let primaryJobTitleIndex = userPrimaryJobTitleIndex;
    if (userJobExperienceOverview?.length === 0) {
      primaryJobTitleIndex = 0;
    }
    // If length is greater then zero means user checked the checkbox
    if (primaryJobTitle && primaryJobTitle.length > 0) {
      primaryJobTitleIndex = primaryJobTitle?.[0];
    }

    userJobExperienceOverview.push({
      job_title: prepareMultiLingual(
        jobTitle ?? "",
        LOCALE.EN,
        userJobExperienceOverview?.[itemId]?.job_title
      ),
      years_of_experience:
        (yearOfExperience as typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE]) ??
        ""
    });

    try {
      if (userData.setValue && userData.value) {
        await userData.setValue(
          {
            ...userData.value,
            summary: {
              ...userData.value?.summary,
              basic_information: {
                ...userData.value?.summary?.basic_information,
                email: user?.email ?? "",
                primary_job_title_index: primaryJobTitleIndex
              },
              job_experience_overview: userJobExperienceOverview
            },
            updated_at: Timestamp.now()
          },
          handleJobExperienceOverviewSavedSuccess,
          handleJobExperienceOverviewSavedFail
        );
        navigate(`/${translate.getCurrentLocale()}/profile/resume/en-cv`);
      }
    } catch (e) {
      handleJobExperienceOverviewSavedFail();
    }
  };

  const handleEdit = async (formData: JobOverviewFormData) => {
    setIsDisabled(true);
    const { jobTitle, yearOfExperience, primaryJobTitle } = formData || {};
    let primaryJobTitleIndex = userPrimaryJobTitleIndex;

    if (userJobExperienceOverview?.length === 0) {
      primaryJobTitleIndex = 0;
    }
    // If length is greater then zero means user checked the checkbox
    if (primaryJobTitle && primaryJobTitle.length > 0) {
      primaryJobTitleIndex = primaryJobTitle?.[0];
    }

    primaryJobTitleIndex = itemId;
    userJobExperienceOverview[itemId] = {
      job_title: prepareMultiLingual(
        jobTitle ?? "",
        LOCALE.EN,
        userJobExperienceOverview[itemId].job_title
      ),
      years_of_experience:
        (yearOfExperience as typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE]) ??
        ""
    };

    try {
      if (userData.setValue && userData.value) {
        if (
          userData.value?.summary &&
          !userData.value?.summary?.job_experience_overview
        ) {
          userData.value.summary.job_experience_overview = [];
        }
        await userData.setValue(
          {
            ...userData.value,
            summary: {
              ...userData.value?.summary,
              basic_information: {
                ...userData.value?.summary?.basic_information,
                email: user?.email ?? "",
                primary_job_title_index: primaryJobTitleIndex
              },
              job_experience_overview: userJobExperienceOverview
            },
            updated_at: Timestamp.now()
          },
          handleJobExperienceOverviewSavedSuccess,
          handleJobExperienceOverviewSavedFail
        );
        navigate(`/${translate.getCurrentLocale()}/profile/resume/en-cv`);
      }
    } catch (e) {
      handleJobExperienceOverviewSavedFail();
    }
  };

  return (
    <JobOverviewForm
      initialValues={initialValues}
      handleFormSubmit={item_id ? handleEdit : handleAdd}
      backBtnLink={`/${translate.getCurrentLocale()}/profile/resume/en-cv`}
      isDisabled={isDisabled}
      formLanguage={LOCALE_SHORT.EN}
    />
  );
};

export default EnglishResumeEditJobExperienceOverview;
