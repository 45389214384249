import { collection, getDocs, query, where } from "@firebase/firestore";
import pMemoize from "p-memoize";

import KeyLabel from "@interfaces/components/KeyLabel";
import MetaSkills from "@interfaces/database/MetaSkills";

import { FIRESTORE_COLLECTIONS, LOCALE } from "@utils/config";
import encodeStringToAscii from "@utils/encodeStringToAscii";
import { db } from "@utils/firebase";
import { getSkillByKey } from "@utils/metaSkills";
import { resolveMultiLingual } from "@utils/multiLingual";

// Locale has to be passed as required value, otherwise the memoization will override language change.
// In that situation, the label will not change when the locale is changed.
const getSkillLabelNonMemoized = async (
  key = "",
  locale: typeof LOCALE[keyof typeof LOCALE]
): Promise<string> => {
  if (key != "") {
    const skillData = await getSkillByKey(key);

    if (skillData !== undefined) {
      const skillLabel = resolveMultiLingual(skillData.label, locale);
      return skillLabel ?? key;
    }
  }
  return key;
};

/**
 * NOTE:
 * If the performance is getting slow.
 * Or if there are a lot of document reads because of this.
 * Then this function can be memoized as well.
 * But, seems okay for now.
 */
const getSkillList = async (subtext: string): Promise<Array<KeyLabel>> => {
  if (subtext !== "") {
    subtext = encodeStringToAscii(subtext.toLowerCase());

    const q = query(
      collection(db, FIRESTORE_COLLECTIONS.META_SKILLS),
      where(`metadata.name_keywords.${subtext}`, "==", true)
    );
    const docs = await getDocs(q);

    if (docs.empty) {
      return [];
    }

    const skills: Record<string, string> = {};
    docs.forEach((singleDoc) => {
      if (singleDoc.data()) {
        const data = singleDoc.data() as MetaSkills;
        skills[data.key] = resolveMultiLingual(data.label) ?? "";
      }
    });

    return Object.keys(skills).map((singleSkillKey) => ({
      key: singleSkillKey,
      label: skills[singleSkillKey]
    }));
  }

  return [];
};

const getSkillLabel = pMemoize(getSkillLabelNonMemoized);

export { getSkillList, getSkillLabel, getSkillLabelNonMemoized };
