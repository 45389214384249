import { useState } from "react";

import { Step } from "react-joyride";

import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField
} from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";

import Button from "@components/Button";
import FTUIContainer from "@components/FTUIContainer";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import { RESUME_PURCHASE_STATUS_CODE } from "@utils/config";
import { auth } from "@utils/firebase";
import {
  canPurchaseResume,
  isCandidateProfilePurchased,
  isResumePurchased,
  purchaseResume
} from "@utils/purchaseResume";
import { intl } from "@utils/translate";

const PurchaseResume = () => {
  const tourSteps: Array<Step> = [
    {
      target: "#purchase-resume-step",
      title: intl.get("t_ftui_debugger_purchase_resume_title"),
      content: intl.get("t_ftui_debugger_purchase_resume_description"),
      disableBeacon: true
    },
    {
      target: "#can-purchase-resume-step",
      title: intl.get("t_ftui_debugger_purchase_resume_can_purchase_title"),
      content: intl.get(
        "t_ftui_debugger_purchase_resume_can_purchase_description"
      )
    },
    {
      target: "#is-resume-purchased-one-step",
      title: intl.get("t_ftui_debugger_purchase_resume_is_purchased_one_title"),
      content: intl.get(
        "t_ftui_debugger_purchase_resume_is_purchased_one_description"
      )
    },
    {
      target: "#is-resume-purchased-two-step",
      title: intl.get("t_ftui_debugger_purchase_resume_is_purchased_two_title"),
      content: intl.get(
        "t_ftui_debugger_purchase_resume_is_purchased_two_description"
      )
    },
    {
      target: "#purchase-new-resume-step",
      title: intl.get("t_ftui_debugger_purchase_resume_new_title"),
      content: intl.get("t_ftui_debugger_purchase_resume_new_description")
    }
  ];

  const toast = useToast();
  const [user] = useAuthState(auth);
  const userProfile = useUserProfile();
  const companyDetails = useCompanyDetails();
  const [candidateId, setCandidateId] = useState<string>("");
  const [applicationId, setApplicationId] = useState<string>("");
  const [jobId, setJobId] = useState<string>("");

  const userId = user?.uid;
  const creditsAvailable = companyDetails.value?.total_credits_available ?? 0;

  const handlePurchaseResume = async () => {
    if (applicationId && jobId && userId) {
      const isPurchased = await purchaseResume(
        jobId,
        applicationId,
        userProfile,
        userId,
        creditsAvailable
      );
      if (isPurchased) {
        toast.kampai("Resume Purchase Success", "success");
      } else {
        toast.kampai("Resume Purchase Failed", "error");
      }
    } else {
      toast.kampai("Cannot Check, Missing Required fields", "error");
    }
  };

  const handleIsCandidateProfilePurchased = async () => {
    if (candidateId && userId) {
      const isPurchased = await isCandidateProfilePurchased(
        candidateId,
        userProfile,
        userId
      );
      toast.kampai(
        `Resume Purchase status: ${
          isPurchased ? "Purchased" : "Not Purchased"
        }`,
        "info",
        true
      );
    } else {
      toast.kampai("Cannot Check, Missing Required fields", "error");
    }
  };

  const handleIsResumePurchased = async () => {
    if (applicationId && userId) {
      const isPurchased = await isResumePurchased(
        applicationId,
        userProfile,
        userId
      );
      toast.kampai(
        `Resume Purchase status: ${
          isPurchased ? "Purchased" : "Not Purchased"
        }`,
        "info",
        true
      );
    } else {
      toast.kampai("Cannot Check, Missing Required fields", "error");
    }
  };

  const handleCanPurchaseResume = async () => {
    if (jobId && applicationId && userId) {
      const canPurchase = await canPurchaseResume(
        jobId,
        applicationId,
        userProfile,
        userId,
        creditsAvailable
      );
      toast.kampai(
        `Can Purchase status: ${
          canPurchase === RESUME_PURCHASE_STATUS_CODE.CAN_PURCHASE
            ? "Can Purchase"
            : "Cannot Purchase"
        }`,
        "info",
        true
      );
    } else {
      toast.kampai("Cannot Check, Missing Required fields", "error");
    }
  };

  return (
    <>
      <Typography variant="h5" id="purchase-resume-step">
        Purchase Resume:
      </Typography>
      <br />
      <Stack gap={2}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Candidate ID</TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  label="Candidate ID"
                  variant="outlined"
                  value={candidateId}
                  onChange={(e) => setCandidateId(e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Application ID</TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  label="Application ID"
                  variant="outlined"
                  value={applicationId}
                  onChange={(e) => setApplicationId(e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Job ID</TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  label="Job ID"
                  variant="outlined"
                  value={jobId}
                  onChange={(e) => setJobId(e.target.value)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Stack direction="row" gap={4}>
          <Box id="can-purchase-resume-step">
            <Button
              handleClick={() => handleCanPurchaseResume()}
              variant="outlined"
              color="primary">
              Can Purchase the Resume?
            </Button>
            <br />
            <Typography variant="body2">
              Requires Job ID + Application ID
            </Typography>
          </Box>
          <Box id="is-resume-purchased-one-step">
            <Button
              handleClick={() => handleIsCandidateProfilePurchased()}
              variant="outlined"
              color="primary">
              Is Resume Purchased?
            </Button>
            <br />
            <Typography variant="body2">Requires Candidate ID</Typography>
          </Box>
          <Box id="is-resume-purchased-two-step">
            <Button
              handleClick={() => handleIsResumePurchased()}
              variant="outlined"
              color="secondary">
              Is Resume Purchased?
            </Button>
            <br />
            <Typography variant="body2">requires Application ID</Typography>
          </Box>
          <Box id="purchase-new-resume-step">
            <Button
              handleClick={() => handlePurchaseResume()}
              variant="outlined">
              Purchase New Resume
            </Button>
            <br />
            <Typography variant="body2">
              Requires Job ID + Application ID
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <FTUIContainer tourSteps={tourSteps} />
    </>
  );
};

export default PurchaseResume;
