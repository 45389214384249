import { Box } from "@mui/system";

import SkeletonJobCategory from "@skeletons/SkeletonJobCategory";

import Well from "@components/Well";

interface SkeletonSkillsCardProps {
  mode?: "edit" | "view";
}

const SkeletonSkillsCard = ({ mode = "view" }: SkeletonSkillsCardProps) => {
  return (
    <Well showBorder={mode === "edit" ? true : false}>
      <Box display="flex" flexWrap="wrap" gap={1}>
        <SkeletonJobCategory multiple />
        <SkeletonJobCategory multiple />
      </Box>
    </Well>
  );
};

export default SkeletonSkillsCard;
