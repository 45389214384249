import { ReactNode } from "react";

import { Control, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";

import {
  InputAdornment,
  InputLabel,
  TextField as MuiTextField
} from "@mui/material";

interface NumberInputProps {
  "name": string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  "control": Control<any>;
  "label"?: string;
  "disabled"?: boolean;
  "required"?: boolean;
  "placeholder"?: string;
  "autoComplete"?: boolean;
  "helperText"?: string;
  "startAdornment"?: ReactNode;
  "endAdornment"?: ReactNode;
  "readOnly"?: boolean;
  "allowNegative"?: boolean;
  "thousandSeparator"?: boolean;
  "data-testid"?: string;
}

const NumberInput = ({
  name,
  control,
  label,
  thousandSeparator = false,
  disabled = false,
  required = false,
  placeholder = "",
  autoComplete = false,
  startAdornment = null,
  endAdornment = null,
  readOnly = false,
  helperText = "",
  allowNegative = false,
  "data-testid": dataTestId,
  ...rest
}: NumberInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            {label ? (
              <InputLabel
                htmlFor={name}
                required={required}
                disabled={disabled}>
                {label}
              </InputLabel>
            ) : (
              false
            )}
            <NumericFormat
              {...rest}
              data-testid={dataTestId}
              allowNegative={allowNegative}
              helperText={error ? error?.message : helperText}
              customInput={MuiTextField}
              thousandSeparator={thousandSeparator}
              onValueChange={(values) => {
                onChange(values?.value);
              }}
              name={name}
              error={!!error}
              value={value}
              fullWidth
              margin="dense"
              placeholder={!label && required ? `${placeholder}*` : placeholder}
              disabled={disabled}
              required={required}
              InputProps={{
                readOnly,
                autoComplete: autoComplete ? "on" : "off",
                startAdornment: (
                  <>
                    {startAdornment ? (
                      <InputAdornment position="start">
                        {startAdornment}
                      </InputAdornment>
                    ) : (
                      false
                    )}
                  </>
                ),
                endAdornment: (
                  <>
                    {endAdornment ? (
                      <InputAdornment position="end">
                        {endAdornment}
                      </InputAdornment>
                    ) : (
                      false
                    )}
                  </>
                )
              }}
            />
          </>
        );
      }}
    />
  );
};

export default NumberInput;
