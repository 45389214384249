import { Stack } from "@mui/material";

import SkeletonAvatar from "@skeletons/SkeletonAvatar";
import SkeletonTypography from "@skeletons/SkeletonTypography";

interface SkeletonConversationBlockProps {
  avatarVariant?: "circular" | "rounded";
}

const SkeletonConversationBlock = ({
  avatarVariant = "circular"
}: SkeletonConversationBlockProps) => {
  return (
    <Stack p={0.75} direction="row" gap={1}>
      <SkeletonAvatar variant={avatarVariant} />
      <Stack alignSelf="center" width="80%">
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <SkeletonTypography
            variant="subtitle5"
            color="text.primary"
            width="short"
          />
          <SkeletonTypography variant="inputTable" width="short" ml={4} />
        </Stack>
        <Stack
          mt={0.5}
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <SkeletonTypography variant="body2" width="short" />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SkeletonConversationBlock;
