import { Box, styled } from "@mui/material";

import Typography from "@components/Typography";

import { colorPalette } from "@utils/theme";

interface sectionTitleLabelProps {
  label: string;
}

const StyledSectionTitleLabel = styled(Box)({
  backgroundColor: colorPalette.lightBlue.background[30],
  borderRadius: 4,
  display: "inline-flex",
  padding: 4,
  wordBreak: "break-word"
});

const SectionTitleLabel = ({ label }: sectionTitleLabelProps) => {
  return (
    <StyledSectionTitleLabel>
      <Typography variant="subtitle5" color="primary.contrastText">
        {label}
      </Typography>
    </StyledSectionTitleLabel>
  );
};

export default SectionTitleLabel;
