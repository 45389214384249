import { useEffect, useState } from "react";

import {
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";

import { CircularProgress, DialogContent, Stack } from "@mui/material";
import { applyActionCode } from "firebase/auth";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import useForceUpdate from "use-force-update";

import Dialog from "@components/Dialog";
import EmailNotVerifiedBanner from "@components/EmailNotVerifiedBanner";
import Loader from "@components/Loader";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import UserTypeDetails from "@interfaces/functions/UserTypeDetails";

import { ENVIRONMENT, LOCALE, LOCALE_SHORT, USER_TYPE } from "@utils/config";
import { auth, functions } from "@utils/firebase";
import translate, { intl } from "@utils/translate";

const HIDE_BANNER_PATHS = [
  "/forgot-password/*",
  "/change-password/*",
  "/not-found"
];

const UserStatusChecker = () => {
  const [user, isUserLoading] = useAuthState(auth);
  const [signOut] = useSignOut(auth);
  const [isShowEmailNotVerifiedBanner, setShowEmailNotVerifiedBanner] =
    useState<boolean>(false);
  const userProfile = useUserProfile();
  const toast = useToast();
  const { pathname: originalPathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [
    setNewUserTypeDetails,
    isSetNewUserTypeDetailsLoading,
    setNetUserTypeDetailsError
  ] = useHttpsCallable(functions, "setNewUserTypeDetails");
  const forceUpdate = useForceUpdate();

  const pathname = translate.getNonLocalizedURL(originalPathname);
  const isEmployersPath = matchPath("/employers/*", pathname);
  const hideBannerPath = HIDE_BANNER_PATHS.some((singlePath) =>
    matchPath(singlePath, pathname)
  );

  const currentLocale = translate.getCurrentLocale();

  const [isUserTypeNotSet, setIsUserTypeNotSet] = useState<boolean>(false);

  const resendVerificationEmail = () => {
    searchParams.set("send_verification_email", "1");
    setSearchParams(searchParams.toString(), { replace: true });
  };

  /**
   * This function will redirect user to the next page.
   * If the next page is in the same domain, it will use react-router-dom to navigate to the next page.
   * In that case, forceUpdate() is called to force the component to re-render.
   */
  const navigateToNextPage = () => {
    const continueURL = searchParams.get("continueUrl") ?? "";
    if (continueURL) {
      searchParams.delete("continueURL");
      const parsedContinueURL = new URL(continueURL);
      if (parsedContinueURL.hostname === window.location.hostname) {
        navigate(parsedContinueURL.pathname + parsedContinueURL.search);
        forceUpdate();
      } else {
        window.location.href = continueURL;
      }
    } else {
      setSearchParams(searchParams.toString(), { replace: true });
      forceUpdate();
    }
  };

  useEffect(() => {
    const lang = searchParams.get("lang") ?? "";
    if (lang) {
      const lang = searchParams.get("lang") ?? "";
      let newLocale: typeof LOCALE[keyof typeof LOCALE] = LOCALE.JA;
      switch (lang) {
        case LOCALE_SHORT.EN:
          newLocale = LOCALE.EN;
          break;
        case LOCALE_SHORT.JA:
          newLocale = LOCALE.JA;
          break;
        default:
          newLocale = LOCALE.JA;
          break;
      }
      const currentLocale = translate.getCurrentLocale();
      if (currentLocale !== newLocale) {
        translate.setCurrentLocale(newLocale);
      }
      searchParams.delete("lang");
      setSearchParams(searchParams.toString(), { replace: true });
    }
    const mode = searchParams.get("mode") ?? "";
    const verificationCode = searchParams.get("oobCode") ?? "";
    const apiKey = searchParams.get("apiKey") ?? "";
    if (mode === "verifyEmail") {
      if (verificationCode) {
        if (
          process.env.REACT_APP_ENVIRONMENT !== ENVIRONMENT.LOCAL &&
          apiKey !== process.env.REACT_APP_FIREABSE_API_KEY
        ) {
          toast.kampai(intl.get("t_forgot_password_expired_link"), "error");
          navigate(`/${translate.getCurrentLocale()}/`);
        }
        (async () => {
          try {
            await applyActionCode(auth, verificationCode);
            toast.kampai(intl.get("t_verify_email_success"), "success");
            setShowEmailNotVerifiedBanner(false);
            searchParams.delete("mode");
            searchParams.delete("oobCode");
            searchParams.delete("apiKey");
            navigateToNextPage();
          } catch (e) {
            toast.kampai(intl.get("t_forgot_password_expired_link"), "error");
            navigate(`/${translate.getCurrentLocale()}/`);
          }
        })();
      }
    }
  }, []);

  useEffect(() => {
    if (!hideBannerPath) {
      if (user && !user.emailVerified) {
        setShowEmailNotVerifiedBanner(true);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      if (userProfile.value?.user_type === USER_TYPE.NO_TYPE) {
        const userTypeDetails: UserTypeDetails = {
          user_type: USER_TYPE.INDIVIDUAL
        };
        if (isEmployersPath) {
          userTypeDetails.user_type = USER_TYPE.COMPANY;
        }
        if (searchParams.get("is_dummy") === "1") {
          userTypeDetails.is_dummy = true;
          searchParams.delete("is_dummy");
          setSearchParams(searchParams.toString(), { replace: true });
        }
        (async () => {
          try {
            await setNewUserTypeDetails({
              accountInformation: userTypeDetails,
              locale: currentLocale,
              fullName: user.displayName ?? ""
            });
            searchParams.delete("set_user_type");
            searchParams.set("send_verification_email", "1");
            setSearchParams(searchParams.toString(), { replace: true });
          } catch (e) {
            toast.kampai(
              intl.get("t_general_internal_error_login_again"),
              "error"
            );
            signOut();
          }
        })();
      }

      if (
        userProfile.value?.user_type &&
        userProfile.value?.user_type !== USER_TYPE.NO_TYPE
      ) {
        if (isUserTypeNotSet) {
          searchParams.delete("set_user_type");
          setSearchParams(searchParams.toString(), { replace: true });
        }
      }
    } else {
      setShowEmailNotVerifiedBanner(false);
      if (isUserTypeNotSet) {
        searchParams.delete("set_user_type");
        setSearchParams(searchParams.toString(), { replace: true });
      }
    }
  }, [isUserTypeNotSet, user, userProfile]);

  useEffect(() => {
    if (setNetUserTypeDetailsError) {
      toast.kampai(intl.get("t_general_internal_error_login_again"), "error");
      signOut();
    }
  }, [setNetUserTypeDetailsError]);

  useEffect(() => {
    const isUserTypeNotSet = searchParams.get("set_user_type") ?? "";

    if (isUserTypeNotSet || isSetNewUserTypeDetailsLoading) {
      setIsUserTypeNotSet(true);
    } else {
      setIsUserTypeNotSet(false);
    }
  }, [searchParams, isSetNewUserTypeDetailsLoading]);

  return (
    <>
      {isUserLoading || isSetNewUserTypeDetailsLoading ? <Loader /> : false}
      {isShowEmailNotVerifiedBanner ? (
        <EmailNotVerifiedBanner
          email={user?.email ?? ""}
          handleResend={resendVerificationEmail}
        />
      ) : (
        false
      )}
      {isUserTypeNotSet ? (
        <Dialog
          initiator=""
          isDefaultOpen
          maxWidth="xs"
          showCloseBtn={false}
          isPersistent>
          {() => (
            <DialogContent>
              <Stack
                direction="column"
                gap={3}
                alignItems="center"
                justifyContent="center"
                mt={2}>
                <CircularProgress color="primary" size={60} />
                <Typography variant="body1">
                  {intl.get(
                    "t_signinsignup_user_type_not_set_dialog_description"
                  )}
                </Typography>
              </Stack>
            </DialogContent>
          )}
        </Dialog>
      ) : (
        false
      )}
    </>
  );
};

export default UserStatusChecker;
