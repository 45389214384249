import { addDoc, collection, deleteDoc, doc } from "firebase/firestore";

import BookmarkedCandidate from "@interfaces/database/BookmarkedCandidate";
import CompanyID from "@interfaces/database/CompanyID";
import UserID from "@interfaces/database/UserID";

import { CompanyDetailsContextStructure } from "@utils/components/CompanyDetailsContext";
import { UserProfileContextStructure } from "@utils/components/UserProfileContext";
import { FIRESTORE_COLLECTIONS, USER_STATUS, USER_TYPE } from "@utils/config";
import { db } from "@utils/firebase";
import Timestamp from "@utils/Timestamp";

// To check if the current logged-in Manager passes criteria to bookmark a candidate
export const canManagerBookmark = (
  userProfile: UserProfileContextStructure,
  userId: UserID
) => {
  if (
    userProfile.value &&
    userProfile.value.status === USER_STATUS.OK &&
    (userProfile.value.user_type === USER_TYPE.COMPANY ||
      userProfile.value.user_type === USER_TYPE.COMPANY_MANAGER) &&
    userId
  ) {
    return true;
  }
  return false;
};

export const handleCandidateBookmarkChange = async (
  companyId: CompanyID,
  candidateId: UserID,
  managerId: UserID,
  managerProfile: UserProfileContextStructure,
  companyProfile: CompanyDetailsContextStructure,
  isBookmarked: boolean,
  bookmarkedCandidateDocId?: string
) => {
  if (!canManagerBookmark(managerProfile, managerId)) {
    return;
  }

  const { bookmarked_candidates: bookmarkedCandidates } =
    companyProfile.value || {};

  const subCollectionRef = collection(
    db,
    `${FIRESTORE_COLLECTIONS.COMPANIES}/${companyId}/${FIRESTORE_COLLECTIONS.SAVED_CANDIDATES}`
  );

  try {
    if (isBookmarked && bookmarkedCandidateDocId) {
      // Delete the document based on bookmarkedCandidateDocId from DB
      const bookmarkedCandidateDocRef = doc(
        subCollectionRef,
        bookmarkedCandidateDocId
      );
      await deleteDoc(bookmarkedCandidateDocRef);

      // Remove the bookmark from the context
      if (
        bookmarkedCandidates &&
        companyProfile.setValue &&
        companyProfile.value
      ) {
        delete bookmarkedCandidates[bookmarkedCandidateDocId];
        await companyProfile.setValue({
          ...companyProfile.value,
          bookmarked_candidates: bookmarkedCandidates
        });
      }

      return "";
    } else {
      const bookmarkedCandidate: BookmarkedCandidate = {
        candidate_id: candidateId,
        manager_id: managerId,
        created_at: Timestamp.now()
      };

      // Add new saved candidate document to the DB
      const newBookmarkedCandidateDocId = await addDoc(
        subCollectionRef,
        bookmarkedCandidate
      );

      // Add the bookmark to the context
      if (
        bookmarkedCandidates &&
        companyProfile.setValue &&
        companyProfile.value
      ) {
        bookmarkedCandidates[newBookmarkedCandidateDocId.id] =
          bookmarkedCandidate;
        await companyProfile.setValue({
          ...companyProfile.value,
          bookmarked_candidates: bookmarkedCandidates
        });
      }

      return newBookmarkedCandidateDocId.id;
    }
  } catch (error) {
    // TODO: Might handle the error with Crash Analytics later
    /* no op */
  }
};
