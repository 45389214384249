import { Box, Stack } from "@mui/material";

import SkeletonAvatar from "@skeletons/SkeletonAvatar";
import SkeletonJobCategory from "@skeletons/SkeletonJobCategory";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Well from "@components/Well";

const SkeletonProfileSummaryCard = () => {
  return (
    <Well>
      <Box display="flex" alignItems="center" gap={1} width="100%" mb={1}>
        <SkeletonAvatar size="large" />
        <Box width="80%">
          <SkeletonTypography variant="subtitle4" width="medium" />
          <SkeletonTypography variant="subtitle2" width="short" />
          <SkeletonTypography variant="body1" width="short" />
        </Box>
      </Box>

      {/* Basic Information */}
      <Stack direction="row" spacing={1} width="100%" mb={1}>
        <SkeletonTypography variant="body1" width="short" />
        <SkeletonTypography variant="body1" width="short" />
        <SkeletonTypography variant="body1" width="short" />
        <SkeletonTypography variant="body1" width="short" />
        <SkeletonTypography variant="body1" width="short" />
      </Stack>

      {/* Experiences */}
      <SkeletonTypography variant="subtitle2" width="short" mb={1} />
      <Stack direction="row" spacing={1} width="100%" mb={1}>
        <SkeletonTypography variant="body1" width="short" />
        <SkeletonTypography variant="body1" width="short" />
        <SkeletonTypography variant="body1" width="short" />
      </Stack>

      {/* Languages */}
      <SkeletonTypography variant="subtitle2" width="short" mb={1} />
      <Stack direction="row" spacing={1} width="100%" mb={1}>
        <SkeletonTypography variant="body1" width="short" />
        <SkeletonTypography variant="body1" width="short" />
      </Stack>

      {/* Skills */}
      <SkeletonTypography variant="subtitle2" width="short" mb={1} />
      <Box display="flex" flexWrap="wrap" gap={1} mb={1}>
        <SkeletonJobCategory multiple />
        <SkeletonJobCategory multiple />
      </Box>

      {/* Attached Resumes */}
      <SkeletonTypography variant="subtitle2" width="short" mb={1} />
      <Stack direction="row" spacing={1} width="100%">
        <SkeletonTypography variant="body1" width="short" />
        <SkeletonTypography variant="body1" width="short" />
        <SkeletonTypography variant="body1" width="short" />
      </Stack>
    </Well>
  );
};

export default SkeletonProfileSummaryCard;
