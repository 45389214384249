import { Grid } from "@mui/material";

import SkeletonInput from "@skeletons/SkeletonInput";
import SkeletonTypography from "@skeletons/SkeletonTypography";

const SkeletonLanguageRequirementForm = () => {
  return (
    <>
      <Grid item xs={12} md={5.5}>
        <SkeletonInput />
      </Grid>
      <Grid item xs={12} md={5.5}>
        <SkeletonInput />
      </Grid>
      <Grid item xs={12} md={5.5}>
        <SkeletonInput />
      </Grid>
      <Grid item xs={12} md={5.5}>
        <SkeletonInput />
      </Grid>
      <SkeletonTypography variant="h6" />
    </>
  );
};

export default SkeletonLanguageRequirementForm;
