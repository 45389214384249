import { useState } from "react";

import { Step } from "react-joyride";
import { useNavigate } from "react-router-dom";

import {
  CloudSync as CloudSyncIcon,
  ContentCopy as ContentCopyIcon,
  Edit as EditIcon,
  PersonAdd as PersonAddIcon
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";

import Button from "@components/Button";
import FTUIContainer from "@components/FTUIContainer";
import Loader from "@components/Loader";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import { auth, functions } from "@utils/firebase";
import { handleInviteCandidate } from "@utils/inviteCandidate";
import translate, { intl } from "@utils/translate";

const Jobs = () => {
  const tourSteps: Array<Step> = [
    {
      target: "#job-step",
      title: intl.get("t_ftui_debugger_job_title"),
      content: intl.get("t_ftui_debugger_job_description"),
      disableBeacon: true
    },
    {
      target: "#post-job-step",
      title: intl.get("t_ftui_debugger_job_post_title"),
      content: intl.get("t_ftui_debugger_job_post_description")
    },
    {
      target: "#job-id-step",
      title: intl.get("t_ftui_debugger_job_id_title"),
      content: intl.get("t_ftui_debugger_job_id_description")
    },
    {
      target: "#job-edit-step",
      title: intl.get("t_ftui_debugger_job_edit_title"),
      content: intl.get("t_ftui_debugger_job_edit_description")
    },
    {
      target: "#job-copy-step",
      title: intl.get("t_ftui_debugger_job_copy_title"),
      content: intl.get("t_ftui_debugger_job_copy_description")
    },
    {
      target: "#job-invite-step",
      title: intl.get("t_ftui_debugger_job_invite_title"),
      content: intl.get("t_ftui_debugger_job_invite_description")
    },
    {
      target: "#job-sync-step",
      title: intl.get("t_ftui_debugger_job_sync_title"),
      content: intl.get("t_ftui_debugger_job_sync_description")
    }
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const companyDetails = useCompanyDetails();
  const userProfile = useUserProfile();
  const jobIds = companyDetails.value?.jobs;
  const [user] = useAuthState(auth);
  const toast = useToast();

  const syncJobWithTypesense = async (jobId: string) => {
    try {
      setIsLoading(true);
      const syncFunction = httpsCallable(functions, "syncJobWithTypesense");
      const { data } = await syncFunction(jobId);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const typedData = data as any;
      if (typedData.success) {
        toast.kampai("Sync Successful", "success");
      } else {
        toast.kampai("Sync Failed, Maybe Job is not Approved", "error");
      }
    } catch (error) {
      toast.kampai("Sync Failed", "error");
    }
    setIsLoading(false);
  };

  return (
    <>
      <Typography variant="h5" id="job-step">
        Jobs:
      </Typography>
      <br />
      <br />
      {isLoading ? <Loader /> : false}
      <Box>
        {user ? (
          <>
            <div id="post-job-step">
              <Button
                color="secondary"
                handleClick={() =>
                  navigate(
                    `/${translate.getCurrentLocale()}/employers/jobs/new/company-information`
                  )
                }>
                Post Job
              </Button>
            </div>
            <br />
            <br />
            <TableContainer>
              <Table sx={{ width: "initial" }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr. No.</TableCell>
                    <TableCell id="job-id-step">Job ID</TableCell>
                    <TableCell id="job-edit-step">Edit</TableCell>
                    <TableCell id="job-copy-step">Copy</TableCell>
                    <TableCell id="job-invite-step">Invite</TableCell>
                    <TableCell id="job-sync-step">Sync</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobIds?.map((singleJobId, idx) => {
                    return (
                      <TableRow
                        key={singleJobId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 }
                        }}>
                        <TableCell>{idx + 1}</TableCell>
                        <TableCell>{singleJobId}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              navigate(
                                `/${translate.getCurrentLocale()}/employers/jobs/${singleJobId}/company-information`
                              )
                            }>
                            <EditIcon color="info" />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              navigate(
                                `/${translate.getCurrentLocale()}/employers/jobs/new/company-information?copy_from_job_id=${singleJobId}`
                              )
                            }>
                            <ContentCopyIcon color="secondary" />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              (async () => {
                                const candidateId = prompt(
                                  "Enter Candidate ID to Invite"
                                );
                                if (
                                  candidateId &&
                                  userProfile?.value?.company_id
                                ) {
                                  const isInvited = await handleInviteCandidate(
                                    singleJobId,
                                    candidateId,
                                    userProfile.value.company_id,
                                    userProfile,
                                    user.uid
                                  );
                                  if (isInvited) {
                                    toast.kampai(
                                      "Invitation Successful",
                                      "success"
                                    );
                                  } else {
                                    toast.kampai("Invitation Failed", "error");
                                  }
                                }
                              })();
                            }}>
                            <PersonAddIcon color="info" />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => syncJobWithTypesense(singleJobId)}>
                            <CloudSyncIcon color="secondary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Typography>Not Logged In</Typography>
        )}
      </Box>
      <FTUIContainer tourSteps={tourSteps} />
    </>
  );
};

export default Jobs;
