import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import SkillsForm from "@components/ResumeForms/SkillsForm";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import SkillData from "@interfaces/components/SkillData";
import Skill from "@interfaces/database/Skill";

import { LOCALE_SHORT, MAX_SKILLS } from "@utils/config";
import { getSkillLabel } from "@utils/keyLabelHandlers/skill";
import { addSkill, isSkillExistsByKey } from "@utils/metaSkills";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

const EnglishResumeEditSkills = () => {
  const userData = useUserProfile();
  const navigate = useNavigate();
  const toast = useToast();
  const [initialValues, setInitialValues] = useState<{
    skills: Array<SkillData>;
  }>({
    skills: []
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const userSkills = userData.value?.summary?.skills;

  useEffect(() => {
    const getInitialSkillValues = async () => {
      setIsLoading(true);
      const initialSkills: Array<SkillData> = [];
      if (userSkills) {
        for (let i = 0; i < userSkills?.length; i++) {
          const singleSkill = userSkills[i];
          initialSkills.push({
            name: {
              key: singleSkill.name,
              label: await getSkillLabel(
                singleSkill?.name,
                translate.getCurrentLocale()
              )
            },
            yearOfExperience: singleSkill?.years_of_experience
          });
        }
      }
      setInitialValues({ skills: initialSkills });
      setIsLoading(false);
    };
    getInitialSkillValues();
  }, []);

  const handleSkillsSavedSuccess = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_success_resume_saved"), "success");
  };

  const handleSkillsSavedFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleFormSubmit = async (formData: { skills: Array<SkillData> }) => {
    setIsDisabled(true);
    for (let index = 0; index < formData.skills.length; index++) {
      const singleSkill = formData.skills[index];
      const isSkillAlreadyExistsByKey = await isSkillExistsByKey(
        singleSkill?.name?.key
      );
      if (!isSkillAlreadyExistsByKey) {
        const skillKey = await addSkill(
          singleSkill?.name?.label,
          translate.getCurrentLocale()
        );
        if (skillKey && singleSkill?.name?.key) {
          singleSkill.name.key = skillKey;
        }
      }
    }

    const selectedSkills = formData?.skills
      ?.map((singleSkillData: SkillData) => {
        return {
          name: singleSkillData?.name?.key,
          years_of_experience: singleSkillData.yearOfExperience
        };
      })
      ?.filter(
        // ignore the empty skills object and return only valid skills
        (singleSkillData: { name: string | undefined }) => singleSkillData?.name
      );

    if (selectedSkills?.length > MAX_SKILLS) {
      toast.kampai(
        intl.get("t_toast_error_limit_exceed", {
          fieldName: intl.get("t_profile_skill_skill"),
          maxLimit: MAX_SKILLS
        }),
        "error"
      );
      return;
    }
    try {
      if (userData.setValue && userData.value) {
        await userData.setValue(
          {
            ...userData.value,
            summary: {
              ...userData.value.summary,
              skills: selectedSkills as Array<Skill>
            },
            updated_at: Timestamp.now()
          },
          handleSkillsSavedSuccess,
          handleSkillsSavedFail
        );
        navigate(`/${translate.getCurrentLocale()}/profile/resume/en-cv`);
      }
    } catch (e) {
      handleSkillsSavedFail();
    }
  };

  return (
    <SkillsForm
      isLoading={isLoading}
      initialValues={initialValues}
      handleFormSubmit={handleFormSubmit}
      backBtnLink={`/${translate.getCurrentLocale()}/profile/resume/en-cv`}
      isDisabled={isDisabled}
      formLanguage={LOCALE_SHORT.EN}
    />
  );
};

export default EnglishResumeEditSkills;
