import { ChangeEvent, useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Box } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { SearchParams } from "typesense/lib/Typesense/Documents";

import {
  CandidateListSingleCandidate,
  JobExperience,
  Language,
  Skill
} from "@pages/EmployersSearch";

import SkeletonCandidateProfileCard from "@skeletons/SkeletonCandidateProfileCard";

import CandidateProfileCard from "@components/CandidateProfileCard";
import NoCandidatesCard from "@components/NoCandidatesCard";
import Pagination from "@components/Pagination";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import BookmarkedCandidate from "@interfaces/database/BookmarkedCandidate";
import MultiLingual from "@interfaces/database/MultiLingual";

import { handleCandidateBookmarkChange } from "@utils/bookmarkCandidate";
import {
  INDIVIDUAL_USER_NUMBER_OF_COMPANIES,
  INDIVIDUAL_USER_VISA_TYPE,
  LANGUAGE_PROFICIENCY,
  PAGINATION,
  TYPESENSE_COLLECTIONS,
  YEARS_OF_EXPERIENCE
} from "@utils/config";
import { auth } from "@utils/firebase";
import { resolveMultiLingual } from "@utils/multiLingual";
import { intl } from "@utils/translate";
import getClient from "@utils/typesense";

const EmployersSavedCandidates = () => {
  const [user] = useAuthState(auth);
  const companyData = useCompanyDetails();
  const userProfile = useUserProfile();
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();

  const companyId = userProfile.value?.company_id;
  const bookmarkedCandidates = companyData?.value?.bookmarked_candidates ?? {};
  const jobTitleOptions =
    resolveMultiLingual(companyData.value?.job_titles) ?? {};

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [bookmarkedCandidatesPerPage, setBookmarkedCandidatesPerPage] =
    useState<Array<{ key: string; value: BookmarkedCandidate }>>([]);
  const [candidateDataPerPage, setCandidateDataPerPage] = useState<
    Array<CandidateListSingleCandidate>
  >([]);
  const [bookmarkedCandidatesRecord, setBookmarkedCandidatesRecord] = useState<
    Record<string, string>
  >({});
  const [isCandidatesLoading, setIsCandidatesLoading] = useState<boolean>(true);
  const [isBookmarkedCandidatesLoading, setIsBookmarkedCandidatesLoading] =
    useState<boolean>(true);

  const numOfBookmarkedCandidates: number = Object.keys(
    bookmarkedCandidates ?? {}
  ).length;
  const numOfPages = Math.ceil(
    numOfBookmarkedCandidates / PAGINATION.ITEMS_PER_PAGE
  );
  const bookmarkedCandidatesArray: Array<{
    key: string;
    value: BookmarkedCandidate;
  }> = Object.entries(bookmarkedCandidates ?? {}).map(([key, value]) => ({
    key,
    value
  }));

  // To check if page number is valid
  useEffect(() => {
    if (searchParams.has("page")) {
      const page = Number(searchParams.get("page"));

      if (Number.isInteger(page) && page > 0 && page <= numOfPages) {
        setCurrentPage(page);
      } else {
        setCurrentPage(1);
        searchParams.delete("page");
        setSearchParams(searchParams.toString(), { replace: true });
      }
    }
  }, []);

  useEffect(() => {
    if (!companyData.loading) {
      setBookmarkedCandidatesPerPage(
        bookmarkedCandidatesArray.slice(
          (currentPage - 1) * PAGINATION.ITEMS_PER_PAGE,
          currentPage * PAGINATION.ITEMS_PER_PAGE
        )
      );
    }
  }, [currentPage, companyData.loading]);

  useEffect(() => {
    setIsBookmarkedCandidatesLoading(true);

    if (bookmarkedCandidates) {
      const newBookmarkedCandidates: Record<string, string> = {};
      for (const key in bookmarkedCandidates) {
        const candidateId = bookmarkedCandidates[key].candidate_id;
        newBookmarkedCandidates[candidateId] = key;
      }
      setBookmarkedCandidatesRecord(newBookmarkedCandidates);
    }

    setIsBookmarkedCandidatesLoading(false);
  }, [bookmarkedCandidates]);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    searchParams.set("page", `${value}`);
    setSearchParams(searchParams.toString(), { replace: true });
  };

  const fetchCandidateData = async (
    bookmarkedCandidates: Array<{ key: string; value: BookmarkedCandidate }>
  ) => {
    setIsCandidatesLoading(true);

    if (bookmarkedCandidates.length > 0) {
      const candidateIdsFilter = `id:=[${bookmarkedCandidates
        .map((singleCandidate) => `${singleCandidate.value.candidate_id}`)
        .join(", ")}]`;

      const typesenseClient = getClient();
      const typesenseSearchParams: SearchParams = {
        q: "*",
        query_by: "",
        filter_by: candidateIdsFilter
      };

      try {
        const candidateList = await typesenseClient
          .collections(TYPESENSE_COLLECTIONS.CANDIDATES)
          .documents()
          .search(typesenseSearchParams);

        if (candidateList.hits && Array.isArray(candidateList.hits)) {
          const candidates = candidateList.hits.map(
            (singleCandidate) =>
              singleCandidate.document as CandidateListSingleCandidate
          );

          setCandidateDataPerPage(candidates);
        }
      } catch (error) {
        toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
      }
    }

    setIsCandidatesLoading(false);
  };

  useEffect(() => {
    if (bookmarkedCandidatesPerPage.length > 0) {
      fetchCandidateData(bookmarkedCandidatesPerPage);
    }
  }, [bookmarkedCandidatesPerPage]);

  return (
    <>
      <Typography variant="h3" mt={6} mb={2.5}>
        {intl.get("t_employer_saved_candidates_page_title")}
      </Typography>
      {numOfBookmarkedCandidates > 0 || candidateDataPerPage.length > 0 ? (
        <>
          <Box my={2}>
            {isCandidatesLoading ||
            isBookmarkedCandidatesLoading ||
            companyData.loading ||
            userProfile.loading
              ? [...Array(PAGINATION.ITEMS_PER_PAGE_LARGE)].map((_, index) => (
                  <Box my={3} key={index}>
                    <SkeletonCandidateProfileCard />
                  </Box>
                ))
              : candidateDataPerPage.map(
                  (singleCandidate: CandidateListSingleCandidate) => {
                    const {
                      candidate_id = "",
                      country_of_origin = "",
                      expected_salary,
                      initials_en = "",
                      is_en_cv_attached = false,
                      is_ja_cv_attached = false,
                      is_ja_rirekisho_attached = false,
                      job_titles_en = [],
                      job_titles_ja = [],
                      job_years_of_experience = [],
                      languages_name = [],
                      languages_proficiency = [],
                      languages_test_name = [],
                      languages_test_result = [],
                      location_city = "",
                      location_country = "",
                      no_job_experience = false,
                      no_of_companies = "",
                      primary_job_title_index = 0,
                      skills_name = [],
                      skills_years_of_experience = [],
                      visa_type,
                      unix_last_login_at,
                      profile_pic_url = ""
                    } = singleCandidate ?? {};

                    const experiences: Array<JobExperience> = [];
                    // job_titles_ja and job_titles_en array length is always same so iterate using default local(ja) array(job_titles_ja)
                    job_titles_ja.forEach(
                      (singleJobTitle: string, index: number) => {
                        const jobTitle: MultiLingual<string> = {
                          en: job_titles_en[index],
                          ja: singleJobTitle
                        };
                        experiences.push({
                          jobTitle: resolveMultiLingual(jobTitle) ?? "",
                          yearsOfExperience: job_years_of_experience?.[
                            index
                          ]?.toString() as typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE]
                        });
                      }
                    );

                    const languages: Array<Language> = [];
                    languages_name.forEach(
                      (singleLanguageName: string, index: number) => {
                        languages.push({
                          name: singleLanguageName ?? "",
                          proficiency:
                            (languages_proficiency?.[
                              index
                            ]?.toString() as typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY]) ??
                            LANGUAGE_PROFICIENCY.NONE,
                          testName: languages_test_name[index] ?? "",
                          testResult: languages_test_result[index] ?? ""
                        });
                      }
                    );

                    const skills: Array<Skill> = [];
                    skills_name.forEach(
                      (singleSkillName: string, index: number) => {
                        skills.push({
                          name: singleSkillName ?? "",
                          yearsOfExperience: skills_years_of_experience?.[
                            index
                          ]?.toString() as typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE]
                        });
                      }
                    );

                    const bookmarkedCandidateDocId =
                      bookmarkedCandidatesRecord[candidate_id];
                    const isCandidateBookmarked = bookmarkedCandidateDocId
                      ? true
                      : false;

                    const candidateProfileCardProps = {
                      candidateId: candidate_id,
                      initials: initials_en ?? "",
                      jobTitleId: primary_job_title_index,
                      profilePicUrl: profile_pic_url,
                      location: {
                        city: location_city,
                        country: location_country
                      },
                      countryOfOrigin: country_of_origin,
                      visaType: visa_type
                        ? (visa_type?.toString() as typeof INDIVIDUAL_USER_VISA_TYPE[keyof typeof INDIVIDUAL_USER_VISA_TYPE])
                        : undefined, // if value exist then pass values in string format
                      noOfCompanies: no_of_companies
                        ? (no_of_companies?.toString() as typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES[keyof typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES])
                        : undefined,
                      expectedSalary: expected_salary,
                      experiences,
                      noJobExperience: no_job_experience,
                      languages,
                      skills,
                      attachedResumes: {
                        englishCV: is_en_cv_attached,
                        japaneseCV: is_ja_cv_attached,
                        japaneseRirekisho: is_ja_rirekisho_attached
                      },
                      isBookmarked: isCandidateBookmarked,
                      lastLoggedIn: unix_last_login_at,
                      jobTitleOptions: Object.values(jobTitleOptions),
                      isJobTitleOptionsLoading: companyData?.loading ?? false
                    };
                    return (
                      <Box my={3} key={candidate_id}>
                        <CandidateProfileCard
                          handleCandidateBookmark={() => {
                            candidate_id && user?.uid && companyId
                              ? handleCandidateBookmarkChange(
                                  companyId,
                                  candidate_id,
                                  user?.uid,
                                  userProfile,
                                  companyData,
                                  isCandidateBookmarked,
                                  bookmarkedCandidateDocId
                                )
                              : false;
                          }}
                          {...candidateProfileCardProps}
                        />
                      </Box>
                    );
                  }
                )}
          </Box>

          <Pagination
            count={numOfPages === 0 ? 1 : numOfPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        </>
      ) : (
        <NoCandidatesCard />
      )}
    </>
  );
};

export default EmployersSavedCandidates;
