import { Box, Stack } from "@mui/material";

import SkeletonTypography from "@skeletons/SkeletonTypography";

import theme, { colorPalette } from "@utils/theme";

interface SkeletonMessageBlockProps {
  variant?: "sender" | "receiver";
}

const SkeletonMessageBlock = ({
  variant = "sender"
}: SkeletonMessageBlockProps) => {
  return (
    <Box maxWidth={640} width={{ xs: "90%", md: "100%" }}>
      <Stack
        justifyContent="center"
        p={1.5}
        bgcolor={
          variant === "sender"
            ? theme.palette.primary.main
            : colorPalette.blue["04"]
        }
        borderRadius={5}>
        <SkeletonTypography variant="body2" width="full" />
      </Stack>
      <Box width="100%" textAlign={variant === "sender" ? "end" : "start"}>
        <SkeletonTypography
          variant="inputTable"
          color="text.secondary"
          width="x-short"
        />
      </Box>
    </Box>
  );
};

export default SkeletonMessageBlock;
