import intl, { ReactIntlUniversal } from "react-intl-universal";

import { LOCALE, LOCALE_SHORT } from "@utils/config";
import enUS from "@utils/lang/en-us.json";
import jaJP from "@utils/lang/ja-jp.json";

// Load dayjs locales
import "dayjs/locale/ja";
import "dayjs/locale/en";

export const LOCALES_LIST = [
  {
    label: LOCALE_SHORT.EN,
    value: LOCALE.EN
  },
  {
    label: LOCALE_SHORT.JA,
    value: LOCALE.JA
  }
];

export const LOCALE_DATA = {
  "en-US": enUS,
  "ja-JP": jaJP
};

export const LOCALE_DATA_LOWERCASE = {
  "en-us": enUS,
  "ja-jp": jaJP
};

const getLocaleShort = (
  locale: string | undefined = ""
): typeof LOCALE_SHORT[keyof typeof LOCALE_SHORT] => {
  let localeShort: typeof LOCALE_SHORT[keyof typeof LOCALE_SHORT] =
    LOCALE_SHORT.JA;

  switch (locale) {
    case LOCALE.EN:
      localeShort = LOCALE_SHORT.EN;
      break;
    case LOCALE.JA:
      localeShort = LOCALE_SHORT.JA;
      break;
    default:
      localeShort = LOCALE_SHORT.JA;
      break;
  }

  return localeShort;
};

const getLocaleFromShort = (
  localeShort: string | undefined = ""
): typeof LOCALE[keyof typeof LOCALE] => {
  let locale: typeof LOCALE[keyof typeof LOCALE] = LOCALE.JA;

  switch (localeShort) {
    case LOCALE_SHORT.EN:
      locale = LOCALE.EN;
      break;
    case LOCALE_SHORT.JA:
      locale = LOCALE.JA;
      break;
    default:
      locale = LOCALE.JA;
      break;
  }

  return locale;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const intlEn = new ReactIntlUniversal();
intlEn.init({
  currentLocale: LOCALE.EN,
  locales: LOCALE_DATA,
  fallbackLocale: LOCALE.JA
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const intlJa = new ReactIntlUniversal();
intlJa.init({
  currentLocale: LOCALE.JA,
  locales: LOCALE_DATA,
  fallbackLocale: LOCALE.JA
});

export default (() => {
  const updateListeners: Array<() => void> = [];
  let appLocale: typeof LOCALE[keyof typeof LOCALE] = LOCALE.JA;
  const setLocale = (currentLocale: string, callListeners = false) => {
    intl.init({
      currentLocale,
      locales: LOCALE_DATA,
      fallbackLocale: LOCALE.JA
    });
    localStorage.setItem("lang", currentLocale);
    appLocale = currentLocale as typeof LOCALE[keyof typeof LOCALE];
    if (callListeners) {
      updateListeners.forEach((singleUpdateListener) => singleUpdateListener());
    }
  };

  return {
    initializeIntl: () => {
      // 1. Get the currentLocale from url, cookie, or browser setting
      let currentLocale = intl.determineLocale({
        urlLocaleKey: "lang", // Example: https://jobs.tokhimo.com?lang=en-US
        localStorageLocaleKey: "lang"
      });

      // 2. Fallback to "ja-JP" if the currentLocale isn't supported in LOCALES_LIST
      if (!LOCALES_LIST.some((item) => item.value === currentLocale)) {
        currentLocale = LOCALE.JA;
      }

      // 3. Set currentLocale and load locale data
      setLocale(currentLocale);
    },

    setCurrentLocale: (currentLocale: string, callListeners = false) => {
      setLocale(currentLocale, callListeners);
    },

    getNonLocalizedURL: (url = "") => {
      const regex = new RegExp(`(/${LOCALE.EN}|/${LOCALE.JA})`, "gim");
      return url.replace(regex, "");
    },

    getCurrentLocale: () => {
      return appLocale;
    },

    getLocaleShort: getLocaleShort,

    getLocaleFromShort: getLocaleFromShort,

    getCurrentLocaleShort: () => {
      return getLocaleShort(appLocale);
    },

    getIntlByLocale: (locale: string | undefined = "") => {
      switch (locale) {
        case LOCALE.EN:
          return intlEn;
        case LOCALE.JA:
          return intlJa;
        default:
          return intl;
      }
    },

    addLanguageChangedListener: (listener: () => void) => {
      updateListeners.push(listener);
    },
    removeLanguageChangedListener: (listener: () => void) => {
      const key = updateListeners.indexOf(listener);
      if (key !== -1) {
        updateListeners.splice(key, 1);
      }
    }
  };
})();

export { intlEn, intlJa, intl };
