const typesenseConfig = {
  nodes: [
    {
      host: process.env.REACT_APP_TYPESENSE_HOST ?? "",
      port: parseInt(process.env.REACT_APP_TYPESENSE_PORT ?? "0"),
      protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL ?? ""
    }
  ],
  apiKey: process.env.REACT_APP_TYPESENSE_API_KEY ?? "",
  connectionTimeoutSeconds: parseInt(
    process.env.REACT_APP_TYPESENSE_CONNECTION_TIMEOUT_SECONDS ?? "0"
  )
};

export default typesenseConfig;
