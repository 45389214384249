import { Link } from "react-router-dom";

import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link as MuiLink,
  Stack,
  Typography
} from "@mui/material";

import Icon from "@components/Icon";

import { intl } from "@utils/translate";

const HowTokhimoJobsWorksCard = () => {
  // FIXME: will recheck "h5" and "subtitle3" when poppins.css is updated
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack spacing={2}>
          <Typography variant="h5">
            {intl.get("t_general_how_tokhimo_jobs_works_title")}
          </Typography>
          <Typography variant="subtitle3" color="text.secondary">
            {intl.get("t_general_how_tokhimo_jobs_works_content")}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <Stack spacing={1.5}>
            <Typography variant="h5">
              <Box display="flex" alignItems="center" gap={1}>
                <Icon type="drive_file_rename_outline" filled />
                {intl.get("t_general_job_posting_title")}
              </Box>
            </Typography>
            <ul>
              <li>
                <Typography variant="subtitle3" color="text.secondary">
                  {intl.get("t_general_job_posting_content_one")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle3" color="text.secondary">
                  {intl.getHTML("t_general_job_posting_content_two")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle3" color="text.secondary">
                  {intl.get("t_general_job_posting_content_three")}
                </Typography>
              </li>
            </ul>
          </Stack>
          <Stack spacing={1.5}>
            <Typography variant="h5">
              <Box display="flex" alignItems="center" gap={1}>
                <Icon type="person_search" filled />
                {intl.get("t_general_candidate_search_and_invitation_title")}
              </Box>
            </Typography>
            <ul>
              <li>
                <Typography variant="subtitle3" color="text.secondary">
                  {intl.get(
                    "t_general_candidate_search_and_invitation_content_one"
                  )}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle3" color="text.secondary">
                  {intl.get(
                    "t_general_candidate_search_and_invitation_content_two"
                  )}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle3" color="text.secondary">
                  {intl.get(
                    "t_general_candidate_search_and_invitation_content_three"
                  )}
                </Typography>
              </li>
            </ul>
          </Stack>
          <Stack spacing={1.5}>
            <Typography variant="h5">
              <Box display="flex" alignItems="center" gap={1}>
                <Icon type="task" filled />
                {intl.get("t_general_resume_acquisition_title")}
              </Box>
            </Typography>
            <ul>
              <li>
                <Typography variant="subtitle3" color="text.secondary">
                  {intl.get("t_general_resume_acquisition_content_one")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle3" color="text.secondary">
                  {intl.get("t_general_resume_acquisition_content_two")}
                </Typography>
              </li>
              <li>
                <Typography
                  variant="subtitle3"
                  color="text.secondary"
                  component={Stack}>
                  {intl.get("t_general_resume_acquisition_content_three")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle3" color="text.secondary">
                  {intl.get("t_general_resume_acquisition_content_four")}
                  <br />
                  <MuiLink
                    component={Link}
                    to="https://www.tokhimo.com/s/f/refund-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ wordWrap: "break-word" }}>
                    https://www.tokhimo.com/s/f/refund-policy
                  </MuiLink>
                </Typography>
              </li>
            </ul>
          </Stack>
          <Stack spacing={1.5}>
            <Typography variant="h5">
              <Box display="flex" alignItems="center" gap={1}>
                <Icon type="connect_without_contact" filled />
                {intl.get("t_general_application_management_title")}
              </Box>
            </Typography>
            <ul>
              <li>
                <Typography variant="subtitle3" color="text.secondary">
                  {intl.get("t_general_application_management_content_one")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle3" color="text.secondary">
                  {intl.get("t_general_application_management_content_two")}
                </Typography>
              </li>
            </ul>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default HowTokhimoJobsWorksCard;
