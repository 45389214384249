import { useEffect, useState } from "react";

import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  CurrencyYen as CurrencyYenIcon,
  NavigateBefore as NavigateBeforeIcon
} from "@mui/icons-material";
import { Box, Grid, Stack, Typography } from "@mui/material";
import parsePhoneNumberFromString from "libphonenumber-js";
import * as yup from "yup";

import SkeletonBasicInformationForm from "@skeletons/SkeletonBasicInformationForm";

import AutoCompleteTextField from "@components/AutoCompleteTextField";
import Button from "@components/Button";
import NonTypeableSelect from "@components/NonTypeableSelect";
import NumberInput from "@components/NumberInput";
import Paper from "@components/Paper";
import TelephoneInput from "@components/TelephoneInput";
import TextField from "@components/TextField";
import TypeableSelect from "@components/TypeableSelect";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";

import KeyLabel from "@interfaces/components/KeyLabel";

import {
  FREE_TEXT_FIELD_MAX_LENGTH,
  INDIVIDUAL_USER_NUMBER_OF_COMPANIES,
  INDIVIDUAL_USER_NUMBER_OF_COMPANIES_T_LABELS,
  INDIVIDUAL_USER_VISA_TYPE,
  INDIVIDUAL_USER_VISA_TYPE_T_LABELS,
  JP_ALPHA3_CODE,
  LOCALE_SHORT,
  MAX_EXPECTED_SALARY
} from "@utils/config";
import { getCityList } from "@utils/keyLabelHandlers/location";
import { getDropdownCountryList } from "@utils/location";
import { colorPalette } from "@utils/theme";
import { intl } from "@utils/translate";

interface BasicInformationFormData {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  countryOfOrigin?: KeyLabel;
  visaType?: KeyLabel;
  country?: KeyLabel;
  city?: KeyLabel;
  numberOfCompanies?: typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES[keyof typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES];
  expectedSalary?: number;
}
interface BasicInformationFormProps {
  initialValues: BasicInformationFormData;
  handleFormSubmit: (formData: BasicInformationFormData) => void;
  backBtnLink?: string;
  isDisabled?: boolean;
  formLanguage?: typeof LOCALE_SHORT[keyof typeof LOCALE_SHORT];
}

const BasicInformationForm = ({
  initialValues,
  handleFormSubmit,
  backBtnLink,
  isDisabled = false,
  formLanguage = LOCALE_SHORT.JA
}: BasicInformationFormProps) => {
  const navigate = useNavigate();
  const userData = useUserProfile();
  const [loading, setLoading] = useState<boolean>(true);

  // validation schema
  const schema = yup.object({
    firstName: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_first_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_first_name")
        })
      ),
    lastName: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_last_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_last_name")
        })
      ),
    phoneNumber: yup
      .string()
      .test(
        "phone",
        intl.get("t_error_invalid", {
          field: intl.get("t_general_phone_number")
        }),
        (value) => {
          // Empty error will be handled separately.
          if (!value) {
            return true;
          }
          const phoneNumber = parsePhoneNumberFromString(value);
          if (!phoneNumber) {
            return false;
          }
          return phoneNumber.isValid();
        }
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_phone_number")
        })
      ),
    countryOfOrigin: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .test(
        "countryOfOrigin",
        intl.get("t_error_required", {
          field: intl.get("t_profile_basic_info_nationality")
        }),
        (value) => (value?.key && value?.label ? true : false)
      )
      .nullable(),
    visaType: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .nullable(),
    country: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .test(
        "country",
        intl.get("t_error_required", {
          field: intl.get("t_general_country")
        }),
        (value) => (value?.key && value?.label ? true : false)
      )
      .nullable(),
    city: yup
      .object()
      .shape({
        key: yup.string().trim(),
        label: yup.string().trim()
      })
      .test(
        "label",
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_city"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        }),
        (value) => {
          return value && value.label
            ? value.label.length <= FREE_TEXT_FIELD_MAX_LENGTH
            : true;
        }
      )
      .nullable(),
    numberOfCompanies: yup.string().required(
      intl.get("t_error_required", {
        field: intl.get("t_profile_basic_info_number_of_companies")
      })
    ),
    expectedSalary: yup
      .number()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_profile_basic_info_expected_salary")
        })
      )
      .min(0)
      .max(
        MAX_EXPECTED_SALARY,
        intl.get("t_error_expected_salary_limit", {
          salary: MAX_EXPECTED_SALARY
        })
      )
      .nullable()
      .transform((expectedSalary) => {
        return isNaN(expectedSalary) ||
          expectedSalary === null ||
          expectedSalary === undefined
          ? null
          : expectedSalary;
      })
  });

  const userBasicInformation = userData.value?.summary?.basic_information;

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, setValue, formState } = methods;

  const selectedCountryIsoCode = useWatch({
    control,
    name: "country.key"
  });
  const selectedCountryOfOriginIsoCode = useWatch({
    control,
    name: "countryOfOrigin.key"
  });

  useEffect(() => {
    // check selected country is modified or not
    if (formState.dirtyFields.country) {
      setValue("city", { key: "", label: "" });
    }

    // clear visa type value if country of origin is Japan
    if (selectedCountryOfOriginIsoCode === JP_ALPHA3_CODE) {
      setValue("visaType", { key: "", label: "" });
    }
  }, [selectedCountryIsoCode, selectedCountryOfOriginIsoCode, setValue]);

  useEffect(() => {
    setLoading(false);
  }, [userBasicInformation]);

  const VISA_TYPE_OPTIONS = useOptions(
    INDIVIDUAL_USER_VISA_TYPE,
    INDIVIDUAL_USER_VISA_TYPE_T_LABELS
  );

  const NUMBER_OF_COMPANIES_OPTIONS = useOptions(
    INDIVIDUAL_USER_NUMBER_OF_COMPANIES,
    INDIVIDUAL_USER_NUMBER_OF_COMPANIES_T_LABELS
  );

  const COUNTRY_OPTIONS = getDropdownCountryList();

  if (loading || userData.loading) {
    return <SkeletonBasicInformationForm />;
  }

  return (
    <Box noValidate component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Paper>
        <Typography variant="h3">
          {intl.get("t_profile_basic_info_heading")}
        </Typography>
        <Typography mt={2.5} color="text.secondary">
          {intl.get("t_profile_basic_info_sub_heading")}
        </Typography>
        <Typography mb={3} color="text.secondary">
          {formLanguage === LOCALE_SHORT.JA
            ? intl.get("t_resumes_form_subtitle_lang_japanese")
            : intl.get("t_resumes_form_subtitle_lang_english")}
        </Typography>
        <Grid container spacing={{ xs: 0, md: 3 }}>
          <Grid item xs={12} md={6}>
            <TextField
              data-testid="basic_information_last_name_input"
              disabled={isDisabled}
              control={control}
              name="lastName"
              label={intl.get("t_general_last_name")}
              placeholder={intl.get("t_general_last_name")}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              data-testid="basic_information_first_name_input"
              disabled={isDisabled}
              control={control}
              name="firstName"
              label={intl.get("t_general_first_name")}
              placeholder={intl.get("t_general_first_name")}
              required
            />
          </Grid>
        </Grid>
        <TelephoneInput
          data-testid="basic_information_phone_number_input"
          control={control}
          disabled={isDisabled}
          name="phoneNumber"
          label={intl.get("t_general_phone_number")}
          placeholder={intl.get("t_general_phone_number")}
          required
        />
        <TypeableSelect
          data-testid="basic_information_country_of_origin_select"
          control={control}
          disabled={isDisabled}
          name="countryOfOrigin"
          label={intl.get("t_profile_basic_info_nationality")}
          placeholder={intl.get("t_profile_basic_info_nationality")}
          required
          options={COUNTRY_OPTIONS}
        />
        {selectedCountryOfOriginIsoCode !== JP_ALPHA3_CODE ? (
          <TypeableSelect
            data-testid="basic_information_visa_type_select"
            disabled={isDisabled}
            control={control}
            name="visaType"
            label={intl.get("t_profile_basic_info_visa_type")}
            placeholder={intl.get("t_profile_basic_info_visa_type")}
            options={VISA_TYPE_OPTIONS}
          />
        ) : (
          false
        )}
        <Grid container spacing={{ xs: 0, md: 3 }}>
          <Grid item xs={12} md={6}>
            <TypeableSelect
              data-testid="basic_information_country_select"
              disabled={isDisabled}
              control={control}
              name="country"
              label={intl.get("t_general_country")}
              placeholder={intl.get(
                "t_profile_basic_info_location_country_placeholder"
              )}
              required
              options={COUNTRY_OPTIONS}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AutoCompleteTextField
              data-testid="basic_information_city_input"
              control={control}
              disabled={!selectedCountryIsoCode || isDisabled}
              name="city"
              label={intl.get("t_general_city")}
              placeholder={intl.get(
                "t_profile_basic_info_location_city_placeholder"
              )}
              setValue={setValue}
              getOptions={(subText) => {
                return getCityList(subText, selectedCountryIsoCode);
              }}
            />
          </Grid>
        </Grid>
        <NonTypeableSelect
          data-testid="basic_information_number_of_companies_select"
          disabled={isDisabled}
          setValue={setValue}
          control={control}
          name="numberOfCompanies"
          label={intl.get("t_profile_basic_info_number_of_companies")}
          placeholder={intl.get(
            "t_profile_basic_info_number_of_companies_placeholder"
          )}
          required
          options={NUMBER_OF_COMPANIES_OPTIONS}
        />
        <NumberInput
          data-testid="basic_information_expected_salary_input"
          disabled={isDisabled}
          control={control}
          thousandSeparator
          startAdornment={
            <CurrencyYenIcon
              htmlColor={colorPalette.black.base}
              fontSize="small"
            />
          }
          name="expectedSalary"
          label={intl.get("t_profile_basic_info_expected_salary")}
          placeholder={intl.get("t_profile_basic_info_expected_salary")}
          required
        />
      </Paper>
      <Stack justifyContent="space-between" direction="row" mt={5.5}>
        {backBtnLink ? (
          <Button
            data-testid="basic_information_back_button"
            variant="outlined"
            startAdornment={<NavigateBeforeIcon />}
            handleClick={() => navigate(backBtnLink)}>
            {intl.get("t_general_back")}
          </Button>
        ) : (
          false
        )}
        <Button
          type="submit"
          loading={isDisabled}
          data-testid="basic_information_save_button">
          {intl.get("t_general_save")}
        </Button>
      </Stack>
    </Box>
  );
};

export default BasicInformationForm;
