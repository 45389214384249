import { useState } from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";

import Button from "@components/Button";

import { colorPalette } from "@utils/theme";
import { intl } from "@utils/translate";

const VerySmallScreenDialog = () => {
  const [isDialogClosed, setIsDialogClosed] = useState<boolean>(false);
  const isVerySmallScreen = useMediaQuery("(max-width:319px)");

  const handleCloseDialog = () => {
    setIsDialogClosed(true);
  };

  const navigateContact = () => {
    window.location.href = "https://www.tokhimo.com/jobs-contact";
  };

  if (isVerySmallScreen && !isDialogClosed) {
    return (
      <Box
        width="100vw"
        height="150vh"
        position="fixed"
        zIndex={9999}
        top={0}
        left={0}
        bgcolor={colorPalette.white[80]}
        sx={{
          backdropFilter: "blur(5px)"
        }}>
        <Stack m="auto" maxWidth="90vw" p={2} borderRadius={1}>
          <Box textAlign="right" onClick={handleCloseDialog}>
            <CloseIcon />
          </Box>
          <Typography variant="h2" textAlign="center">
            {intl.get("t_general_warning")}
          </Typography>
          <Typography variant="h5" textAlign="center">
            {intl.get("t_very_small_screen_dialog_heading")}
          </Typography>
          <Typography variant="body1" mt={4} mb={2} textAlign="center">
            {intl.get("t_very_small_screen_dialog_contact_message")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            handleClick={navigateContact}>
            {intl.get("t_very_small_screen_dialog_report_button_caption")}
          </Button>
        </Stack>
      </Box>
    );
  } else {
    return <></>;
  }
};

export default VerySmallScreenDialog;
