import { Outlet, useLocation } from "react-router-dom";

import { Grid, Stack, styled, Typography } from "@mui/material";

import SkeletonProfileInformationCard from "@skeletons/SkeletonProfileInformationCard";

import ProfileInformationCard from "@components/ProfileInformationCard";
import ScrollTopButton from "@components/ScrollTopButton";
import SideMenu from "@components/SideMenu";

import useUserProfile from "@hooks/database/useUserProfile";

import translate, { intl } from "@utils/translate";

const StyledProfileInformationCardContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    paddingRight: 0
  },
  [theme.breakpoints.up("sm")]: {
    paddingRight: "2rem"
  }
}));

const StyledResumeCardContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 0
  },
  [theme.breakpoints.up("sm")]: {
    paddingLeft: "2rem"
  }
}));

const Resume = () => {
  const { pathname: originalPathname } = useLocation();
  const pathname = translate.getNonLocalizedURL(originalPathname);
  const isEditPage = pathname.includes("edit");

  const { loading } = useUserProfile();

  const navLinks = [
    {
      label: intl.get("t_profile_summary"),
      redirectLink: `/${translate.getCurrentLocale()}/profile`,
      aliasLinks: ["/profile"]
    },
    {
      label: intl.get("t_profile_menu_my_resume"),
      redirectLink: `/${translate.getCurrentLocale()}/profile/resume/`,
      aliasLinks: [
        "/profile/resume/en-cv",
        "/profile/resume/ja-cv",
        "/profile/resume/ja-rirekisho",
        `/${translate.getCurrentLocale()}/profile/resume/en-cv`,
        `/${translate.getCurrentLocale()}/profile/resume/ja-cv`,
        `/${translate.getCurrentLocale()}/profile/resume/ja-rirekisho`
      ]
    }
  ];

  return (
    <>
      <Typography variant="h3" color="common.white">
        {intl.get("t_profile_your_profile")}
      </Typography>
      <Grid container mt={4} rowGap={3}>
        {/* FIXME: Responsive Issue for tablet screen */}
        {!isEditPage ? (
          <StyledProfileInformationCardContainer item xs={12} sm={4}>
            <Stack gap={3}>
              {loading ? (
                <SkeletonProfileInformationCard />
              ) : (
                <ProfileInformationCard />
              )}

              <SideMenu menuLinks={navLinks} />
            </Stack>
          </StyledProfileInformationCardContainer>
        ) : (
          false
        )}

        <StyledResumeCardContainer item xs={12} sm={isEditPage ? 12 : 8}>
          <Outlet />
          <Stack direction="row" justifyContent="end" mt={3}>
            <ScrollTopButton />
          </Stack>
        </StyledResumeCardContainer>
      </Grid>
    </>
  );
};

export default Resume;
