import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  DialogActions,
  DialogContent,
  Stack,
  Tooltip
} from "@mui/material";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import { useAuthState } from "react-firebase-hooks/auth";
import * as yup from "yup";

import ApplyButtonTooltipTitle from "@components/ApplyButtonTooltipTitle";
import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import Cursor from "@components/Cursor";
import CVFilledStatus from "@components/CVFilledStatus";
import Dialog from "@components/Dialog";
import CompanyInfo from "@components/JobCards/Common/CompanyInfo";
import JobCategories from "@components/JobCards/Common/JobCategories";
import JobStatus from "@components/JobCards/Common/JobStatus";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";

import CvFileRequirements from "@interfaces/database/CvFileRequirements";
import Location from "@interfaces/database/Location";
import MultiLingual from "@interfaces/database/MultiLingual";

import {
  CANDIDATE_JOB_DECLINED_REASONS,
  CANDIDATE_JOB_DECLINED_REASONS_T_LABELS,
  DIALOG_ACTION,
  FREE_TEXT_FIELD_MAX_LENGTH,
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  JOB_APPLY_STATUS_CODE,
  JOB_STATUS_TAGS,
  USER_TYPE
} from "@utils/config";
import { auth } from "@utils/firebase";
import theme from "@utils/theme";
import translate, { intl, intlEn, intlJa } from "@utils/translate";

dayjs.extend(relative);

interface InvitedJobCardProps {
  timestamp?: Date;
  status: Array<
    | typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]
    | typeof JOB_APPLICATION_INVITATION_ACTION_TYPE[keyof typeof JOB_APPLICATION_INVITATION_ACTION_TYPE]
  >;
  categories?: Array<string>;
  companyName?: string;
  companyLogo?: string;
  jobTitle?: string;
  location?: Location;
  handleJobApply?: () => void;
  handleJobDecline?: (jobDeclinedReasons?: Array<MultiLingual<string>>) => void;
  handleClick?: () => void;
  isApplyLoading?: boolean;
  isDeclineLoading?: boolean;
  isJobActive?: boolean;
  isHighlighted?: boolean;
  jobApplyStatusCode: typeof JOB_APPLY_STATUS_CODE[keyof typeof JOB_APPLY_STATUS_CODE];
  cvFileRequirements?: CvFileRequirements;
}

interface DeclinedReasonsFormData {
  jobDeclinedReasons: Array<string>;
  otherJobDeclinedReason: string;
}

const InvitedJobCard = ({
  timestamp,
  companyLogo,
  status,
  categories = [],
  companyName,
  jobTitle,
  location,
  handleJobApply,
  handleJobDecline,
  handleClick,
  isApplyLoading = false,
  isDeclineLoading = false,
  isJobActive = true,
  isHighlighted = false,
  jobApplyStatusCode,
  cvFileRequirements
}: InvitedJobCardProps) => {
  const relativeTime = dayjs(timestamp)
    .locale(translate.getCurrentLocaleShort())
    .fromNow();
  const [user] = useAuthState(auth);
  const userProfile = useUserProfile();

  const CANDIDATE_JOB_DECLINED_REASONS_OPTIONS = useOptions(
    CANDIDATE_JOB_DECLINED_REASONS,
    CANDIDATE_JOB_DECLINED_REASONS_T_LABELS
  ).map((singleOption) => {
    return { value: singleOption.key, label: singleOption.label };
  });

  // validation schema
  const schema = yup.object({
    jobDeclinedReasons: yup.array(),
    otherJobDeclinedReason: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_first_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      jobDeclinedReasons: [],
      otherJobDeclinedReason: ""
    }
  });

  const { progress_flags } = userProfile?.value ?? {};

  const isEnResumePresent =
    userProfile?.value?.metadata?.progress_flags?.en_cv ?? false;
  const isJaResumePresent =
    userProfile?.value?.metadata?.progress_flags?.ja_cv ?? false;
  const isJaRirekishoPresent =
    userProfile?.value?.metadata?.progress_flags?.ja_rirekisho ?? false;

  const userProfileStatus = {
    isLoggedIn: user?.uid || userProfile?.value ? true : false,
    isEmailVerified: user?.emailVerified ?? false,
    isProfileSummaryCompleted:
      !progress_flags?.basic_information ||
      !progress_flags?.job_experience_overview ||
      !progress_flags?.language ||
      !progress_flags?.skills
        ? false
        : true,
    isResumeCompleted:
      isEnResumePresent || isJaResumePresent || isJaRirekishoPresent,
    isCandidate:
      userProfile?.value?.user_type === USER_TYPE.INDIVIDUAL ? true : false
  };

  const isDisabled =
    status.includes(JOB_APPLICATION_INVITATION_ACTION_TYPE.APPLIED) ||
    status.includes(JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED) ||
    status.includes(JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED_DECLINED) ||
    !isJobActive;

  const handleClose = (reason: keyof typeof DIALOG_ACTION) => {
    if (reason === DIALOG_ACTION.AGREE && handleJobDecline) {
      handleJobDecline();
    }
  };

  const { handleSubmit, control, setValue } = methods;

  const handleFormSubmit = (formData: DeclinedReasonsFormData) => {
    const allJobDeclineReasons = [
      ...formData.jobDeclinedReasons
      // formData.otherJobDeclinedReason // FIXME: add this in phase-2
    ]?.map((singleJobDeclinedReason) => {
      return {
        en: intlEn.get(
          CANDIDATE_JOB_DECLINED_REASONS_T_LABELS[
            singleJobDeclinedReason as keyof typeof CANDIDATE_JOB_DECLINED_REASONS_T_LABELS
          ]
        ),
        ja: intlJa.get(
          CANDIDATE_JOB_DECLINED_REASONS_T_LABELS[
            singleJobDeclinedReason as keyof typeof CANDIDATE_JOB_DECLINED_REASONS_T_LABELS
          ]
        )
      };
    });
    handleJobDecline?.(allJobDeclineReasons);
  };

  const ApplyBtn = () => {
    return jobApplyStatusCode === JOB_APPLY_STATUS_CODE.CAN_APPLY ? (
      <Dialog
        title={intl.get("t_dialog_necessary_documents_title")}
        maxWidth="sm"
        initiator={
          <Button disabled={!isJobActive}>{intl.get("t_general_apply")}</Button>
        }
        onClose={handleClose}>
        {(_, handleCancel) => (
          <>
            <DialogContent sx={{ py: 0.5 }}>
              <Typography variant="body1" color="text.secondary">
                {intl.get("t_dialog_necessary_documents_subtitle")}
              </Typography>
              <Stack gap={1} mt={3}>
                {cvFileRequirements?.is_en_cv_mandatory ? (
                  <CVFilledStatus
                    label={intl.get("t_general_english_cv")}
                    isAttached={isEnResumePresent}
                  />
                ) : (
                  false
                )}
                {cvFileRequirements?.is_ja_cv_mandatory ? (
                  <CVFilledStatus
                    label={intl.get("t_general_japanese_cv")}
                    isAttached={isJaResumePresent}
                  />
                ) : (
                  false
                )}
                {cvFileRequirements?.is_ja_rirekisho_mandatory ? (
                  <CVFilledStatus
                    label={intl.get("t_general_japanese_rirekisho")}
                    isAttached={isJaRirekishoPresent}
                  />
                ) : (
                  false
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button handleClick={handleCancel} variant="outlined">
                {intl.get("t_general_cancel")}
              </Button>
              <Button handleClick={handleJobApply} loading={isApplyLoading}>
                {intl.get("t_general_apply")}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    ) : (
      <Tooltip
        PopperProps={{
          sx: {
            px: 4
          }
        }}
        enterTouchDelay={0}
        title={
          <ApplyButtonTooltipTitle
            userProfileStatus={userProfileStatus}
            jobStatus={{ isActive: isJobActive }}
          />
        }
        arrow>
        <Box sx={{ cursor: "pointer" }}>
          <Button disabled> {intl.get("t_general_apply")}</Button>
        </Box>
      </Tooltip>
    );
  };

  const DisabledAppliedBtn = () => {
    return (
      <Cursor type="default">
        <Button disabled>
          {status.includes(JOB_APPLICATION_INVITATION_ACTION_TYPE.APPLIED)
            ? intl.get("t_general_applied")
            : intl.get("t_general_apply")}
        </Button>
      </Cursor>
    );
  };

  const DeclineBtn = () => {
    return isJobActive ? (
      <Dialog
        title={intl.get("t_dialog_decline_invite_confirmation_title")}
        maxWidth="sm"
        initiator={
          <Button loading={isDeclineLoading}>
            {intl.get("t_general_candidate_invited_decline")}
          </Button>
        }
        onClose={handleClose}>
        {(handleAgree, handleCancel) => (
          <>
            <Box
              noValidate
              component="form"
              onSubmit={handleSubmit(handleFormSubmit)}>
              <DialogContent sx={{ py: 1 }}>
                <Typography color="text.secondary" variant="body1">
                  {intl.get("t_dialog_please_answer_reason")}
                </Typography>
                <Checkbox
                  size="medium"
                  name="jobDeclinedReasons"
                  control={control}
                  setValue={setValue}
                  options={CANDIDATE_JOB_DECLINED_REASONS_OPTIONS}
                />
                {/* FIXME: this is phase2 - need to handle email and phone number check */}
                {/* <TextField
                  control={control}
                  name="otherDeclinedReason"
                  placeholder={intl.get("t_general_other_decline_reason")}
                /> */}
              </DialogContent>
              <DialogActions>
                <Button handleClick={handleCancel} variant="outlined">
                  {intl.get("t_dialog_decline_cancel_button")}
                </Button>
                <Button type="submit">
                  {intl.get("t_dialog_decline_agree_button")}
                </Button>
              </DialogActions>
            </Box>
          </>
        )}
      </Dialog>
    ) : (
      <Button disabled>
        {intl.get("t_general_candidate_invited_decline")}
      </Button>
    );
  };

  const DisabledDeclinedBtn = () => {
    return (
      <Cursor type="default">
        <Button disabled>
          {status.includes(JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED)
            ? intl.get("t_general_employer_declined_candidate_view")
            : status.includes(
                JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED_DECLINED
              )
            ? intl.get("t_general_candidate_invited_declined")
            : intl.get("t_general_candidate_invited_decline")}
        </Button>
      </Cursor>
    );
  };

  const JobDetails = () => {
    return (
      <Box
        p={2}
        borderRadius={1}
        bgcolor="common.white"
        onClick={handleClick}
        sx={{
          filter: !isJobActive ? "grayscale(1)" : "unset",
          border:
            isJobActive && isHighlighted
              ? `1px solid ${theme.palette.primary.main}`
              : ""
        }}>
        {/* job status and time text start */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <JobStatus status={status} />
          <Typography variant="body2" color="text.secondary" ml={1}>
            {intl.get("t_general_invited")}&nbsp;{relativeTime}
          </Typography>
        </Stack>
        {/* job status and time text end */}

        {/* company and role information start */}
        <CompanyInfo
          companyLogo={companyLogo}
          companyName={companyName}
          location={location}
          jobTitle={jobTitle}
        />
        {/* company and role information end */}

        {/* category and buttons for desktop start */}
        <Stack gap={1} direction="row" alignItems="center" flexWrap="wrap">
          <JobCategories categories={categories} />
          <Stack direction="row" gap={1} ml="auto">
            <Box onClick={(e) => e.stopPropagation()}>
              {isDisabled ? <DisabledDeclinedBtn /> : <DeclineBtn />}
            </Box>
            <Box onClick={(e) => e.stopPropagation()}>
              <Cursor type="default">
                {isDisabled ? <DisabledAppliedBtn /> : <ApplyBtn />}
              </Cursor>
            </Box>
          </Stack>
        </Stack>
        {/* category and buttons for desktop end */}
      </Box>
    );
  };

  return isJobActive ? (
    <Cursor>
      <JobDetails />
    </Cursor>
  ) : (
    <Tooltip
      arrow
      placement="top"
      enterTouchDelay={0}
      title={
        <Typography variant="body2">
          {intl.get("t_job_not_active_tooltip")}
        </Typography>
      }>
      <div>
        <JobDetails />
      </div>
    </Tooltip>
  );
};

export default InvitedJobCard;
