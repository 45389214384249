import { Link, Stack } from "@mui/material";

import Typography from "@components/Typography";

import theme from "@utils/theme";
import { intl } from "@utils/translate";

const MessageInputDisabled = () => {
  return (
    <Stack
      direction="row"
      gap={1}
      justifyContent="center"
      alignItems="center"
      minHeight={60}
      sx={{
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white
      }}>
      <Typography variant="body2">
        {intl.get("t_conversations_input_disabled")}
      </Typography>
      {/* FIXME: Fix Link */}
      <Link
        href="https://www.tokhimo.com/tokhimo-jobs-faq"
        target="_blank"
        sx={{ color: theme.palette.common.white, textDecoration: "underline" }}>
        <Typography variant="body2">
          {intl.get("t_conversations_input_disabled_learn_more_link")}
        </Typography>
      </Link>
    </Stack>
  );
};

export default MessageInputDisabled;
