import { useState } from "react";

import { Editor, RawDraftContentState } from "react-draft-wysiwyg";
import { Control, Controller } from "react-hook-form";

import { FormHelperText, InputLabel, Stack } from "@mui/material";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";

import { LOCALE } from "@utils/config";
import { colorPalette } from "@utils/theme";
import translate from "@utils/translate";

import FormatAlignCenterIcon from "@assets/icons/format_align_center.svg";
import FormatAlignJustifyIcon from "@assets/icons/format_align_justify.svg";
import FormatAlignLeftIcon from "@assets/icons/format_align_left.svg";
import FormatAlignRightIcon from "@assets/icons/format_align_right.svg";
import FormatBoldIcon from "@assets/icons/format_bold.svg";
import FormatItalicIcon from "@assets/icons/format_italic.svg";
import FormatListBulletedIcon from "@assets/icons/format_list_bulleted.svg";
import FormatListNumberedIcon from "@assets/icons/format_list_numbered.svg";
import FormatUnderlineIcon from "@assets/icons/format_underline.svg";

import "@assets/styles/wysiwyg.css";
import "@assets/styles/wysiwyg_theme.css";

interface WYSIWYGProps {
  mode?: "edit" | "view";
  name?: string;
  control?: Control<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  label?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  helperText?: string;
  maxLength?: number;
  readOnly?: boolean;
  defaultValue: RawDraftContentState;
}

const WYSIWYG = ({
  mode = "view",
  name = "wysiwyg",
  control,
  label,
  disabled = false,
  required = false,
  placeholder = "",
  helperText = "",
  maxLength = 0,
  readOnly = false,
  defaultValue
}: WYSIWYGProps) => {
  const isCurrentLocalIsJa = translate.getCurrentLocale() === LOCALE.JA;
  const fontFamily = [
    isCurrentLocalIsJa ? "Noto Sans JP" : "Poppins",
    "sans-serif"
  ].join(",");
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(convertFromRaw(defaultValue))
  );

  const toolbarConfig = {
    options: ["inline", "blockType", "list", "textAlign"],
    inline: {
      options: ["bold", "italic", "underline"],
      bold: {
        icon: FormatBoldIcon
      },
      italic: {
        icon: FormatItalicIcon
      },
      underline: {
        icon: FormatUnderlineIcon
      }
    },
    blockType: {
      options: ["Normal", "H2", "H4"]
    },
    list: {
      options: ["unordered", "ordered"],
      unordered: {
        icon: FormatListBulletedIcon
      },
      ordered: {
        icon: FormatListNumberedIcon
      }
    },
    textAlign: {
      left: {
        icon: FormatAlignLeftIcon
      },
      center: {
        icon: FormatAlignCenterIcon
      },
      right: {
        icon: FormatAlignRightIcon
      },
      justify: {
        icon: FormatAlignJustifyIcon
      }
    }
  };

  return (
    <>
      {label ? (
        <InputLabel htmlFor={name} required={required} disabled={disabled}>
          {label}
        </InputLabel>
      ) : (
        false
      )}
      {mode === "edit" ? (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({
            field: { onChange, value },
            fieldState: { error, isTouched }
          }) => {
            if (!isTouched) {
              const editorValuePlainText = editorState
                .getCurrentContent()
                .getPlainText();
              const valuePlainText = EditorState.createWithContent(
                convertFromRaw(value)
              )
                .getCurrentContent()
                .getPlainText();
              if (editorValuePlainText !== valuePlainText) {
                setEditorState(
                  EditorState.createWithContent(convertFromRaw(value))
                );
              }
            }
            const editorContent = convertFromRaw(value);
            const editotState = EditorState.createWithContent(editorContent);
            const characters = editorContent.getPlainText().length;
            const leftCharacters = maxLength - characters;
            return (
              <>
                <Editor
                  defaultEditorState={editotState}
                  editorState={editorState}
                  toolbar={toolbarConfig}
                  toolbarClassName={
                    disabled ? "wysiwyg-toolbar disabled-ui" : "wysiwyg-toolbar"
                  }
                  wrapperClassName="wysiwyg-wrapper"
                  editorClassName={
                    disabled ? "wysiwyg-editor disabled-ui" : "wysiwyg-editor"
                  }
                  onEditorStateChange={(state) => {
                    setEditorState(state);
                    const content = convertToRaw(state.getCurrentContent());
                    onChange(content);
                  }}
                  readOnly={readOnly || disabled}
                  placeholder={placeholder}
                  editorStyle={
                    error
                      ? {
                          borderColor: colorPalette.red.base,
                          borderWidth: "1px",
                          fontFamily
                        }
                      : { fontFamily }
                  }
                />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ px: "0.875rem" }}>
                  <FormHelperText error={!!error}>
                    <span>{error?.message ?? helperText}</span>
                  </FormHelperText>
                  <FormHelperText error={!!error}>
                    {maxLength > 0 ? (
                      <span>
                        {leftCharacters >= 0 ? leftCharacters : 0}
                        &nbsp;characters left
                      </span>
                    ) : (
                      false
                    )}
                  </FormHelperText>
                </Stack>
              </>
            );
          }}
        />
      ) : (
        <>
          <Editor
            defaultEditorState={EditorState.createWithContent(
              convertFromRaw(defaultValue)
            )}
            readOnly
            toolbarHidden
            toolbar={toolbarConfig}
            wrapperClassName="wysiwyg-wrapper"
            editorClassName="wysiwyg-viewer"
            editorStyle={{ fontFamily }}
          />
          <FormHelperText sx={{ px: "0.875rem" }}>
            <span>{helperText}</span>
          </FormHelperText>
        </>
      )}
    </>
  );
};

export default WYSIWYG;
