import { Skeleton, styled } from "@mui/material";

const StyledSkeleton = styled(Skeleton)(() => ({
  height: "1rem",
  width: "2.5rem",
  borderRadius: "0.625rem"
}));

const SkeletonSwitch = () => {
  return <StyledSkeleton animation="wave" variant="rounded" />;
};

export default SkeletonSwitch;
