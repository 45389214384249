import { useNavigate } from "react-router-dom";

import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";

import Button from "@components/Button";
import Well from "@components/Well";

import theme from "@utils/theme";
import translate, { intl } from "@utils/translate";

const CompleteProfileCard = () => {
  const navigate = useNavigate();
  return (
    <Well>
      <Typography variant="h4" mb={2.5}>
        {intl.get("t_profile_summary_complete_title")}
      </Typography>
      <Typography variant="body1" color={theme.palette.text.secondary} mb={3}>
        {intl.get("t_profile_summary_complete_subtitle")}
      </Typography>

      <Button
        variant="contained"
        endAdornment={<ChevronRightIcon />}
        handleClick={() =>
          navigate(`/${translate.getCurrentLocale()}/profile/edit`)
        }
        data-testid="profile_summary_complete_button">
        {intl.get("t_profile_summary_complete_button_label")}
      </Button>
    </Well>
  );
};

export default CompleteProfileCard;
