import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { LOCALE } from "@utils/config";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";
dayjs.extend(relativeTime);

/**
 * Get message time banner based on the provided timestamp
 *
 * @param {Timestamp} currentMessageDate The timestamp of the message
 * @return {string} The message time banner
 */

export const getMessageTimeBanner = (currentMessageDate: Timestamp) => {
  const messageDate = dayjs(currentMessageDate.toDate());
  const today = dayjs();
  const yesterday = dayjs().subtract(1, "day");
  const currentLocale = translate.getCurrentLocale();

  if (messageDate.isSame(today, "day")) {
    return intl.get("t_general_today");
  } else if (messageDate.isSame(yesterday, "day")) {
    return intl.get("t_general_yesterday");
  } else {
    return messageDate.format(
      currentLocale === LOCALE.EN ? "DD/MM/YYYY" : "MM/DD/YYYY"
    );
  }
};
