import { ReactNode } from "react";

import { Box, styled } from "@mui/material";

interface CursorProps {
  children: ReactNode;
  type?: "auto" | "default" | "none" | "pointer" | "text" | "not-allowed"; // Add more here if required
}

const StyledCursor = styled(Box)<CursorProps>(({ type }) => {
  return {
    cursor: type
  };
});

const Cursor = ({ children, type = "pointer" }: CursorProps) => {
  return (
    <StyledCursor component="span" type={type}>
      {children}
    </StyledCursor>
  );
};

export default Cursor;
