import { useEffect, useState } from "react";

import { Alert, Box, styled } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";

import ProfileSummaryTab from "@components/CandidateResumeDrawer/Tabs/ProfileSummaryTab";
import Typography from "@components/Typography";

import UserID from "@interfaces/database/UserID";
import UserProfile from "@interfaces/database/UserProfile";

import { FIRESTORE_COLLECTIONS, USER_STATUS } from "@utils/config";
import { db } from "@utils/firebase";
import { intl } from "@utils/translate";

interface InvitedResumeDrawerProps {
  candidateId: UserID;
  candidateProfilePicUrl: string;
  handleError: () => void;
}

const StyledAlert = styled(Alert)({
  boxShadow: "none !important",
  marginBottom: 16
});

const InvitedResumeDrawer = ({
  candidateId,
  candidateProfilePicUrl,
  handleError
}: InvitedResumeDrawerProps) => {
  const [candidate, setCandidate] = useState<UserProfile | null>(null);

  useEffect(() => {
    fetchUserDetails(candidateId);
  }, []);

  const fetchUserDetails = async (candidateId: string) => {
    try {
      const userRef = doc(db, FIRESTORE_COLLECTIONS.USERS, candidateId);
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.data()) {
        setCandidate(userSnapshot.data() as UserProfile);
      } else {
        handleError();
      }
    } catch (error) {
      handleError();
    }
  };

  return (
    <>
      <Box px={{ xs: 2.5, md: 3, lg: 4 }}>
        {candidate?.status === USER_STATUS.LOCKED ? (
          <StyledAlert severity="error" variant="filled">
            <Typography variant="body1">
              {intl.get("t_candidate_unavailable_banner")}
            </Typography>
          </StyledAlert>
        ) : (
          false
        )}

        <ProfileSummaryTab
          user={candidate}
          userProfilePicUrl={candidateProfilePicUrl}
        />
      </Box>
    </>
  );
};

export default InvitedResumeDrawer;
