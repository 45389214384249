import { useEffect, useState } from "react";

import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step
} from "react-joyride";
import { useNavigate } from "react-router-dom";

import { doc, updateDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import TourToolTip from "@components/TourToolTip";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import FTUIStepStatus from "@interfaces/database/FTUIStepStatus";

import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";
import {
  ENVIRONMENT,
  FIRESTORE_COLLECTIONS,
  FTUI_TOUR_NAME,
  FTUI_TOUR_STATUS
} from "@utils/config";
import { auth, db } from "@utils/firebase";
import { colorPalette } from "@utils/theme";
import { intl } from "@utils/translate";

export interface CustomStep extends Step {
  nextRoute?: string;
  prevRoute?: string;
}

interface FTUIContainerProps {
  tourSteps: Array<CustomStep>;
  tourName?: typeof FTUI_TOUR_NAME[keyof typeof FTUI_TOUR_NAME];
  isContinuous?: boolean;
  handleSkip?: () => void;
  handleExit?: () => void;
}

const FTUIContainer = ({
  tourSteps,
  tourName,
  isContinuous = true,
  handleSkip,
  handleExit
}: FTUIContainerProps) => {
  const [user] = useAuthState(auth);
  const { value: userProfile, loading: userProfileLoading } = useUserProfile();
  const navigate = useNavigate();
  const toast = useToast();

  const userId = user?.uid;
  const userFtuiMetadata = userProfile?.metadata?.ftui_information;
  const tourStatus = tourName
    ? (userFtuiMetadata?.candidate?.[tourName] as FTUIStepStatus)
    : undefined;

  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<number>(0);

  useEffect(() => {
    // Mutation Observer to watch for DOM changes
    const observer = new MutationObserver(() => {
      const targetElement = document.querySelector(
        tourSteps[0].target as string
      );
      if (targetElement) {
        if (userProfileLoading || !user) {
          return;
        }
        if (tourStatus && tourStatus.status === FTUI_TOUR_STATUS.COMPLETED) {
          setIsRunning(false); // Don't run the tour if already completed
        } else {
          if (
            tourStatus?.status === FTUI_TOUR_STATUS.IN_PROGRESS &&
            tourStatus.step_idx !== undefined
          ) {
            setStepIndex(tourStatus.step_idx); // Set the current step to step_idx
          }
          setIsRunning(true);
        }
        observer.disconnect(); // Stop observing once the element is found
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
  }, [user, userProfileLoading]);

  const handleTourCallback = (data: CallBackProps) => {
    const { action, status, index, type } = data;

    if (action === ACTIONS.NEXT && type === "tour:end") {
      const nextRoute = tourSteps[index]?.nextRoute;
      if (nextRoute) {
        navigate(nextRoute);
      }
    }

    if (action === ACTIONS.PREV) {
      const prevRoute = tourSteps[index]?.prevRoute;
      if (prevRoute) {
        navigate(prevRoute);
      }
    }

    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    }

    // Note: 'Skip' means "Mark as Complete" is opted and 'Finish' means tour is completed by the user. In both cases, FTUI screen won't be shown again.
    if (
      (status === STATUS.FINISHED || status === STATUS.SKIPPED) &&
      type === "tour:end"
    ) {
      setIsRunning(false);
      updateUserMetaData({ status: FTUI_TOUR_STATUS.COMPLETED });

      if (action === ACTIONS.SKIP && handleSkip) {
        handleSkip();
      }

      return;
    }

    // Note: This 'Close' action means user force closed the modal using close button and FTUI screen will be shown again
    if (status === STATUS.RUNNING && action === ACTIONS.CLOSE) {
      setIsRunning(false);
      updateUserMetaData({
        status: FTUI_TOUR_STATUS.IN_PROGRESS,
        step_idx: index
      });

      if (handleExit) {
        handleExit();
      }

      return;
    }
  };

  const updateUserMetaData = async ({ status, step_idx }: FTUIStepStatus) => {
    const tourData: FTUIStepStatus = {
      status,
      ...(step_idx !== undefined ? { step_idx } : {})
    };

    try {
      if (tourName && userId) {
        const userRef = doc(db, FIRESTORE_COLLECTIONS.USERS, userId);
        const updatePath = `metadata.ftui_information.candidate.${tourName}`;

        await updateDoc(userRef, {
          [updatePath]: tourData
        });
      }
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  return (
    <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
      <Joyride
        steps={tourSteps}
        stepIndex={stepIndex}
        run={isRunning}
        tooltipComponent={TourToolTip}
        floaterProps={{ disableAnimation: true }}
        continuous={isContinuous}
        showSkipButton={true}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
        disableScrolling={false}
        disableScrollParentFix
        callback={handleTourCallback}
        styles={{
          options: {
            zIndex: 20000
          },
          spotlight: {
            border: `2px solid ${colorPalette.orange.spotlight}`,
            borderRadius: "4px"
          }
        }}
        debug={true}
      />
    </EnvironmentSpecific>
  );
};

export default FTUIContainer;
