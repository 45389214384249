import { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import JobDetailForm from "@components/ResumeForms/JobDetailForm";

import useUserProfile from "@hooks/database/useUserProfile";

import { LOCALE, LOCALE_SHORT } from "@utils/config";
import { getCountryName } from "@utils/location";
import { resolveMultiLingual } from "@utils/multiLingual";
import translate from "@utils/translate";

const EnglishResumeEditJobExperienceDetails = () => {
  const userData = useUserProfile();
  const navigate = useNavigate();
  const { item_id } = useParams();
  const itemId = Number(item_id);

  //Fetch the job detail data based on valid itemId
  const userJobDetails = userData.value?.cv?.job_experience ?? [];
  const jobDetail = itemId >= 0 ? userJobDetails?.[itemId] : undefined;

  //If the itemId is not valid, re-route to Add Form Page
  useEffect(() => {
    if (item_id && !jobDetail) {
      navigate(
        `/${translate.getCurrentLocale()}/profile/resume/en-cv/edit/job-experience-details`,
        {
          replace: true
        }
      );
    }
  }, []);

  //Setting initial form values
  const initialValues = {
    companyName: resolveMultiLingual(jobDetail?.company_name, LOCALE.EN) ?? "",
    jobTitle: resolveMultiLingual(jobDetail?.job_title, LOCALE.EN) ?? "",
    contractType: jobDetail?.contract_type ?? "",
    country: {
      key: jobDetail?.location?.country ?? "",
      label: getCountryName(jobDetail?.location?.country ?? "") ?? ""
    },
    city: {
      key: jobDetail?.location?.city ?? "",
      label: jobDetail?.location?.city ?? ""
    },
    responsibilities:
      resolveMultiLingual(jobDetail?.responsibilities, LOCALE.EN) ?? "",
    isCurrentJob: !jobDetail || jobDetail?.timeline?.end ? [] : [1],
    startDate: jobDetail?.timeline?.start?.toDate() ?? "",
    endDate: jobDetail?.timeline?.end?.toDate() ?? ""
  };

  return (
    <JobDetailForm
      initialValues={initialValues}
      formLanguage={LOCALE_SHORT.EN}
    />
  );
};

export default EnglishResumeEditJobExperienceDetails;
