import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Box, Stack } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  SearchParams,
  SearchResponseHit
} from "typesense/lib/Typesense/Documents";

import SkeletonCandidateProfileCard from "@skeletons/SkeletonCandidateProfileCard";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import CandidateProfileCard from "@components/CandidateProfileCard";
import EmployersSearchHeader from "@components/EmployersSearchHeader";
import Pagination from "@components/Pagination";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import MultiLingual from "@interfaces/database/MultiLingual";

import { handleCandidateBookmarkChange } from "@utils/bookmarkCandidate";
import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";
import {
  CANDIDATE_SEARCH_SALARY_SLIDER_MAX,
  CANDIDATE_SEARCH_SALARY_SLIDER_MIN,
  CANDIDATE_SEARCH_SALARY_SLIDER_TOTAL_DISTANCE,
  ENVIRONMENT,
  INDIVIDUAL_USER_NUMBER_OF_COMPANIES,
  INDIVIDUAL_USER_VISA_TYPE,
  LANGUAGE_PROFICIENCY,
  PAGINATION,
  TYPESENSE_COLLECTIONS,
  YEARS_OF_EXPERIENCE
} from "@utils/config";
import { auth } from "@utils/firebase";
import { getCountryCode, isValidCountryCode } from "@utils/location";
import { resolveMultiLingual } from "@utils/multiLingual";
import { intl } from "@utils/translate";
import getClient from "@utils/typesense";

export interface CandidateListSingleCandidate {
  candidate_id: string;
  country_of_origin: string;
  expected_salary: number;
  id: string;
  initials_en: string;
  is_en_cv_attached: boolean;
  is_ja_cv_attached: boolean;
  is_ja_rirekisho_attached: boolean;
  job_titles_en: Array<string>;
  job_titles_ja: Array<string>;
  job_years_of_experience: Array<number>;
  languages_name: Array<string>;
  languages_proficiency: Array<number>;
  languages_test_name: Array<string>;
  languages_test_result: Array<string>;
  location_city: string;
  location_country: string;
  maximum_experience: number;
  no_job_experience: boolean;
  no_of_companies: number;
  primary_job_title_index: number;
  proficiency_en: number;
  proficiency_ja: number;
  profile_pic_url: string;
  skills_name: Array<string>;
  skills_years_of_experience: Array<number>;
  visa_type: string;
  unix_last_login_at: number;
}

interface CandidateListMeta {
  total_hits: number;
  search_time_ms: number;
}

export interface JobExperience {
  jobTitle: string;
  yearsOfExperience: typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE];
}

export interface Language {
  name: string;
  proficiency: typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY];
  testName?: string;
  testResult?: string;
}

export interface Skill {
  name: string;
  yearsOfExperience: typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE];
}

const EmployersSearch = () => {
  const [user] = useAuthState(auth);
  const userProfile = useUserProfile();
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const [isCandidatesLoading, setIsCandidatesLoading] = useState<boolean>(true);
  const [candidates, setCandidates] = useState<
    Array<CandidateListSingleCandidate>
  >([]);
  const [candidatesCount, setCandidatesCount] = useState<number>(0);
  const companyDetails = useCompanyDetails();
  const jobTitleOptions =
    resolveMultiLingual(companyDetails.value?.job_titles) ?? {};
  const bookmarkedCandidatesData = companyDetails.value?.bookmarked_candidates;
  const [bookmarkedCandidates, setBookmarkedCandidates] = useState<
    Record<string, string>
  >({});
  const [isBookmarkedCandidatesLoading, setIsBookmarkedCandidatesLoading] =
    useState<boolean>(true);

  const getCandidateList = async (): Promise<
    [Array<CandidateListSingleCandidate>, CandidateListMeta]
  > => {
    const typesenseClient = getClient();
    const typesenseSearchParams: SearchParams = {
      q: "",
      query_by: "job_titles_en,job_titles_ja", //FIXME: Confirm after i18n implementation. (Phase 2)
      filter_by: ""
    };

    const filters = [];

    const keyword = searchParams.get("q");
    if (keyword) {
      typesenseSearchParams.q = keyword;
    }

    const location = searchParams.get("location");
    if (location) {
      if (isValidCountryCode(location)) {
        filters.push(`location_country:=${location}`);
      } else if (getCountryCode(location)) {
        filters.push(`location_country:=${getCountryCode(location)}`);
      } else {
        filters.push(`location_city:=${location}`);
      }
    }

    const minExperience = searchParams.get("min_experience");
    if (
      minExperience &&
      !isNaN(parseInt(minExperience)) &&
      parseInt(minExperience) >= 0
    ) {
      filters.push(
        `maximum_experience:>=${parseInt(
          searchParams.get("min_experience") ?? ""
        )}`
      );
    }

    const jaLevel = searchParams.getAll("language_ja_level");
    if (jaLevel.length > 0) {
      const jaLevelValues = [];
      for (const singleJaLevel of jaLevel) {
        if (
          (Object.values(LANGUAGE_PROFICIENCY) as Array<string>).includes(
            singleJaLevel
          )
        ) {
          jaLevelValues.push(singleJaLevel);
        }
      }
      if (jaLevelValues.length > 0) {
        filters.push(`proficiency_ja:=[${jaLevelValues.join(",")}]`);
      }
    }

    const enLevel = searchParams.getAll("language_en_level");
    if (enLevel.length > 0) {
      const enLevelValues = [];
      for (const singleEnLevel of enLevel) {
        if (
          (Object.values(LANGUAGE_PROFICIENCY) as Array<string>).includes(
            singleEnLevel
          )
        ) {
          enLevelValues.push(singleEnLevel);
        }
      }
      if (enLevelValues.length > 0) {
        filters.push(`proficiency_en:=[${enLevelValues.join(",")}]`);
      }
    }

    const skills = searchParams.getAll("skills");
    if (skills.length > 0) {
      for (const singleSkill of skills) {
        filters.push(`skills_name:=${singleSkill}`);
      }
    }

    const minSalary =
      searchParams.get("min_salary") ??
      CANDIDATE_SEARCH_SALARY_SLIDER_MIN.toString();
    const maxSalary =
      searchParams.get("max_salary") ??
      CANDIDATE_SEARCH_SALARY_SLIDER_TOTAL_DISTANCE.toString();
    if (
      (minSalary && !isNaN(parseInt(minSalary)) && parseInt(minSalary) >= 0) ||
      (maxSalary && !isNaN(parseInt(maxSalary)) && parseInt(maxSalary) >= 0)
    ) {
      if (
        maxSalary &&
        parseInt(maxSalary) > CANDIDATE_SEARCH_SALARY_SLIDER_MAX
      ) {
        filters.push(`expected_salary:>=${minSalary}`);
      } else {
        filters.push(
          `expected_salary:[${searchParams.get(
            "min_salary"
          )}..${searchParams.get("max_salary")}]`
        );
      }
    }

    if (filters.length > 0) {
      typesenseSearchParams.filter_by = filters.join(" && ");
    }

    const pageNo = searchParams.get("page");
    if (pageNo && !isNaN(parseInt(pageNo)) && parseInt(pageNo) >= 0) {
      typesenseSearchParams.page = parseInt(pageNo);
    }

    try {
      const candidateList = await typesenseClient
        .collections(TYPESENSE_COLLECTIONS.CANDIDATES)
        .documents()
        .search({
          ...typesenseSearchParams,
          per_page: PAGINATION.ITEMS_PER_PAGE_LARGE
        });

      if (candidateList.hits && Array.isArray(candidateList.hits)) {
        const candidates = candidateList.hits.map(
          // FIXME: A typesense Interface needs to be updated.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (candidate: SearchResponseHit<any>) => candidate.document
        );
        const metadata = {
          total_hits: candidateList.found,
          search_time_ms: candidateList.search_time_ms
        };
        return [candidates, metadata];
      }
    } catch (e) {
      return [
        [],
        {
          total_hits: 0,
          search_time_ms: 0
        }
      ];
    }
    return [
      [],
      {
        total_hits: 0,
        search_time_ms: 0
      }
    ];
  };

  let currentPage = Number(searchParams.get("page"));
  const totalPages = Math.ceil(
    candidatesCount / PAGINATION.ITEMS_PER_PAGE_LARGE
  );
  if (
    !currentPage ||
    isNaN(currentPage) ||
    currentPage < 1 ||
    currentPage > totalPages
  ) {
    currentPage = 1;
  }

  useEffect(() => {
    if (!searchParams.has("page")) {
      searchParams.set("page", "1");
      setSearchParams(searchParams);
      return;
    }
    setIsCandidatesLoading(true);
    const getCandidates = async () => {
      try {
        const [candidateList, metadata] = await getCandidateList();
        setCandidatesCount(metadata.total_hits);
        setCandidates(candidateList);
        setIsCandidatesLoading(false);
      } catch (e) {
        toast.kampai(intl.get("t_job_search_failed_error"), "error");
      }
    };
    getCandidates();
  }, [searchParams]);

  useEffect(() => {
    if (bookmarkedCandidatesData) {
      setIsBookmarkedCandidatesLoading(true);
      const newBookmarkedCandidates: Record<string, string> = {};
      for (const key in bookmarkedCandidatesData) {
        const candidateId = bookmarkedCandidatesData[key].candidate_id;
        newBookmarkedCandidates[candidateId] = key;
      }
      setBookmarkedCandidates(newBookmarkedCandidates);
      setIsBookmarkedCandidatesLoading(false);
    }
  }, [bookmarkedCandidatesData]);

  return (
    <>
      <Typography variant="h3" mb={4} mt={6}>
        {intl.get("t_employer_search_page_title")}
      </Typography>
      <EmployersSearchHeader />
      <Box my={2}>
        <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
          {isCandidatesLoading ? (
            <SkeletonTypography width="medium" mt={3} />
          ) : (
            <Typography mt={3}>
              {intl.get("t_employer_search_result_count", {
                count: candidatesCount
              })}
            </Typography>
          )}
        </EnvironmentSpecific>
        <Box>
          {isCandidatesLoading ||
          isBookmarkedCandidatesLoading ||
          companyDetails.loading
            ? [...Array(PAGINATION.ITEMS_PER_PAGE_LARGE)].map((_, index) => (
                <Box my={3} key={index}>
                  <SkeletonCandidateProfileCard />
                </Box>
              ))
            : candidates.map(
                (singleCandidate: CandidateListSingleCandidate) => {
                  const {
                    candidate_id = "",
                    country_of_origin = "",
                    expected_salary,
                    initials_en = "",
                    is_en_cv_attached = false,
                    is_ja_cv_attached = false,
                    is_ja_rirekisho_attached = false,
                    job_titles_en = [],
                    job_titles_ja = [],
                    job_years_of_experience = [],
                    languages_name = [],
                    languages_proficiency = [],
                    languages_test_name = [],
                    languages_test_result = [],
                    location_city = "",
                    location_country = "",
                    no_job_experience = false,
                    no_of_companies = "",
                    primary_job_title_index = 0,
                    skills_name = [],
                    skills_years_of_experience = [],
                    visa_type,
                    unix_last_login_at,
                    profile_pic_url = ""
                  } = singleCandidate ?? {};

                  const experiences: Array<JobExperience> = [];
                  // job_titles_ja and job_titles_en array length is always same so iterate using default local(ja) array(job_titles_ja)
                  job_titles_ja.forEach(
                    (singleJobTitle: string, index: number) => {
                      const jobTitle: MultiLingual<string> = {
                        en: job_titles_en[index],
                        ja: singleJobTitle
                      };
                      experiences.push({
                        jobTitle: resolveMultiLingual(jobTitle) ?? "",
                        yearsOfExperience: job_years_of_experience?.[
                          index
                        ]?.toString() as typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE]
                      });
                    }
                  );

                  const languages: Array<Language> = [];
                  languages_name.forEach(
                    (singleLanguageName: string, index: number) => {
                      languages.push({
                        name: singleLanguageName ?? "",
                        proficiency:
                          (languages_proficiency?.[
                            index
                          ]?.toString() as typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY]) ??
                          LANGUAGE_PROFICIENCY.NONE,
                        testName: languages_test_name[index] ?? "",
                        testResult: languages_test_result[index] ?? ""
                      });
                    }
                  );

                  const skills: Array<Skill> = [];
                  skills_name.forEach(
                    (singleSkillName: string, index: number) => {
                      skills.push({
                        name: singleSkillName ?? "",
                        yearsOfExperience: skills_years_of_experience?.[
                          index
                        ]?.toString() as typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE]
                      });
                    }
                  );

                  const bookmarkedCandidateDocId =
                    bookmarkedCandidates[candidate_id];
                  const isCandidateBookmarked = bookmarkedCandidateDocId
                    ? true
                    : false;

                  const candidateProfileCardProps = {
                    candidateId: candidate_id,
                    initials: initials_en ?? "",
                    jobTitleId: primary_job_title_index,
                    profilePicUrl: profile_pic_url,
                    location: {
                      city: location_city,
                      country: location_country
                    },
                    countryOfOrigin: country_of_origin,
                    visaType: visa_type
                      ? (visa_type?.toString() as typeof INDIVIDUAL_USER_VISA_TYPE[keyof typeof INDIVIDUAL_USER_VISA_TYPE])
                      : undefined, // if value exist then pass values in string format
                    noOfCompanies: no_of_companies
                      ? (no_of_companies?.toString() as typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES[keyof typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES])
                      : undefined,
                    expectedSalary: expected_salary,
                    experiences,
                    noJobExperience: no_job_experience,
                    languages,
                    skills,
                    attachedResumes: {
                      englishCV: is_en_cv_attached,
                      japaneseCV: is_ja_cv_attached,
                      japaneseRirekisho: is_ja_rirekisho_attached
                    },
                    isBookmarked: isCandidateBookmarked,
                    lastLoggedIn: unix_last_login_at,
                    handleCandidateBookmark: () => {
                      if (user && userProfile.value?.company_id) {
                        const updatedBookmarkedCandidates = {
                          ...bookmarkedCandidates
                        };

                        if (isCandidateBookmarked) {
                          // Remove candidateId from bookmarkedCandidates
                          delete updatedBookmarkedCandidates[candidate_id];
                          setBookmarkedCandidates(updatedBookmarkedCandidates);
                        } else {
                          // Add candidateId to bookmarkedCandidates
                          updatedBookmarkedCandidates[candidate_id] = "";
                          setBookmarkedCandidates(updatedBookmarkedCandidates);
                        }

                        (async () => {
                          if (user && userProfile.value?.company_id) {
                            // Fetch the document id for the new bookmark and update the state
                            const newBookmarkedCandidateDocId =
                              await handleCandidateBookmarkChange(
                                userProfile?.value.company_id,
                                candidate_id,
                                user?.uid,
                                userProfile,
                                companyDetails,
                                isCandidateBookmarked,
                                bookmarkedCandidateDocId
                              );

                            if (newBookmarkedCandidateDocId) {
                              const updatedBookmarkedCandidates = {
                                ...bookmarkedCandidates
                              };
                              updatedBookmarkedCandidates[candidate_id] =
                                newBookmarkedCandidateDocId;
                              setBookmarkedCandidates(
                                updatedBookmarkedCandidates
                              );
                            }
                          }
                        })();
                      } else {
                        return false;
                      }
                    },
                    jobTitleOptions: Object.values(jobTitleOptions),
                    isJobTitleOptionsLoading: companyDetails?.loading ?? false
                  };
                  return (
                    <Box my={3} key={candidate_id}>
                      <CandidateProfileCard {...candidateProfileCardProps} />
                    </Box>
                  );
                }
              )}

          {!isCandidatesLoading && candidates.length === 0 ? (
            <Stack my={5}>
              <Typography variant="h2" textAlign="center">
                {intl.get("t_employer_search_page_no_results_found")}
              </Typography>
              <Typography variant="subtitle3" textAlign="center">
                {intl.get("t_general_try_different_search_terms")}
              </Typography>
            </Stack>
          ) : (
            false
          )}
          {candidates?.length ? (
            <Stack direction="row" mt={3} mb={{ xs: 10, lg: 22 }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(_, value) => {
                  searchParams.set("page", value.toString());
                  setSearchParams(searchParams);
                }}
              />
            </Stack>
          ) : (
            false
          )}
        </Box>
      </Box>
    </>
  );
};

export default EmployersSearch;
