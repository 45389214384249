import { ENVIRONMENT, USER_TYPE } from "@utils/config";

interface EnvironmentSpecificProps {
  env?:
    | typeof ENVIRONMENT[keyof typeof ENVIRONMENT]
    | Array<typeof ENVIRONMENT[keyof typeof ENVIRONMENT]>;
  adminAllowed?: boolean;
  userType: typeof USER_TYPE[keyof typeof USER_TYPE];
}

const shouldExecuteInEnvironment = ({
  env = [ENVIRONMENT.STAGE],
  adminAllowed = true,
  userType
}: EnvironmentSpecificProps): boolean => {
  const currentEnv = process.env
    .REACT_APP_ENVIRONMENT as keyof typeof ENVIRONMENT;

  // Combine environments
  const allowedEnvironments = new Set([
    ...(typeof env === "string" ? [env] : env),
    ENVIRONMENT.PREVIEW,
    ENVIRONMENT.LOCAL,
    ENVIRONMENT.TEST
  ]);

  if (allowedEnvironments.has(currentEnv)) {
    return true;
  }

  if (adminAllowed && userType === USER_TYPE.SUPER_USER) {
    return true;
  }

  return false;
};

export default shouldExecuteInEnvironment;
