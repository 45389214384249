import { ReactNode } from "react";

import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom";

import { ReactNodeArray } from "prop-types";
import { useAuthState } from "react-firebase-hooks/auth";

import Loader from "@components/Loader";

import useUserProfile from "@hooks/database/useUserProfile";

import { USER_TYPE } from "@utils/config";
import { auth } from "@utils/firebase";
import translate from "@utils/translate";

interface RequireAuthProps {
  children?: ReactNode | ReactNodeArray;
  accessRestriction:
    | typeof USER_TYPE[keyof typeof USER_TYPE]
    | Array<typeof USER_TYPE[keyof typeof USER_TYPE]>;
}

const RequireAuth = ({
  children,
  accessRestriction = USER_TYPE.INDIVIDUAL
}: RequireAuthProps) => {
  if (!Array.isArray(accessRestriction)) {
    accessRestriction = [accessRestriction];
  }
  const [user, loading] = useAuthState(auth);
  const userProfile = useUserProfile();
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = translate.getNonLocalizedURL(location.pathname);
  const isEmployersPath = matchPath("/employers/*", pathname);

  if (loading) {
    return <Loader />;
  } else if (user) {
    if (userProfile.loading || !userProfile.value?.user_type) {
      return <Loader />;
    } else if (userProfile.value?.user_type === USER_TYPE.NO_TYPE) {
      return <Loader />;
    } else if (
      accessRestriction.includes(userProfile.value?.user_type) ||
      userProfile.value?.user_type === USER_TYPE.SUPER_USER
    ) {
      return (
        <>
          <Outlet />
          {children}
        </>
      );
    } else {
      if (isEmployersPath) {
        navigate(`/${translate.getCurrentLocale()}/employers/`);
      } else {
        navigate(`/${translate.getCurrentLocale()}/`);
      }
      return <Loader />;
    }
  } else {
    if (isEmployersPath) {
      navigate(
        `/${translate.getCurrentLocale()}/employers?login_required=1&return_url=${
          location.pathname
        }`
      );
    } else {
      navigate(
        `/${translate.getCurrentLocale()}/?login_required=1&return_url=${
          location.pathname
        }`
      );
    }
    return <Loader />;
  }
};

export default RequireAuth;
