import { IconButton } from "@mui/material";

import Icon from "@components/Icon";

import { colorPalette } from "@utils/theme";

interface ActionButtonProps {
  "icon": string;
  "color"?: "blue" | "black" | "grey";
  "handleAction"?: () => void;
  "disabled"?: boolean;
  "data-testid"?: string;
}

const ActionButton = ({
  icon,
  color = "grey",
  handleAction,
  disabled = false,
  "data-testid": dataTestId
}: ActionButtonProps) => {
  let iconColor = "";

  if (color === "blue") {
    iconColor = "primary.main";
  } else if (color === "black") {
    iconColor = "text.primary";
  } else {
    iconColor = "text.secondary";
  }

  return (
    <IconButton
      disabled={disabled}
      disableRipple
      onClick={handleAction}
      data-testid={dataTestId}
      sx={{
        "color": iconColor,
        "&:hover": {
          backgroundColor: colorPalette.blue["04"]
        },
        "&:active": {
          backgroundColor: colorPalette.blue["20"]
        }
      }}>
      <Icon type={icon} filled />
    </IconButton>
  );
};

export default ActionButton;
