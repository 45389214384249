import { ReactNode } from "react";

import { Tabs as MuiTabs, TabsOwnProps } from "@mui/material";

interface TabsProps {
  children: ReactNode;
  value: string | number;
  indicatorColor?: "secondary";
  textColor?: "inherit";
  variant?: TabsOwnProps["variant"];
}

const Tabs = ({
  children,
  value,
  indicatorColor = "secondary",
  textColor = "inherit",
  variant = "scrollable"
}: TabsProps) => {
  return (
    <MuiTabs
      value={value}
      aria-label="tabs"
      indicatorColor={indicatorColor}
      textColor={textColor}
      variant={variant}
      allowScrollButtonsMobile>
      {children}
    </MuiTabs>
  );
};

export default Tabs;
