import { ReactNode, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import {
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogContent, Divider, Link, Stack } from "@mui/material";
import dayjs from "dayjs";
import { logEvent } from "firebase/analytics";
import { FirebaseError } from "firebase/app";
import { OAuthProvider, signInWithPopup } from "firebase/auth";
import { renderToString } from "react-dom/server";
import {
  useAuthState,
  useCreateUserWithEmailAndPassword,
  useSignInWithEmailAndPassword,
  useSignInWithGoogle
} from "react-firebase-hooks/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import * as yup from "yup";

import Button from "@components/Button";
import Dialog from "@components/Dialog";
import SocialButton from "@components/Header/SocialButton";
import Loader from "@components/Loader";
import TextField from "@components/TextField";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import Email from "@interfaces/database/Email";

import DangerousComponentFromHTML from "@utils/components/DangerousComponentFromHTML";
import {
  ANALYTICS_EVENT_TYPE,
  SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY,
  SOCIAL_BUTTON_VARIANT,
  USER_TYPE
} from "@utils/config";
import { analytics, auth, functions, setAuthLocale } from "@utils/firebase";
import { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

interface SignInSignUpDialogProps {
  initiator: ReactNode;
  initiatorType?: "default" | "get-started" | "start-for-free" | "post-job";
  activeContent?: typeof SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY[keyof typeof SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY];
}

interface SignInSignUpForm {
  mode: string;
  email: Email;
  password: string;
}

const SignInSignUpDialog = ({
  initiator,
  initiatorType = "default",
  activeContent = SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_IN
}: SignInSignUpDialogProps) => {
  const [user] = useAuthState(auth);
  const userProfile = useUserProfile();

  const [activeContentKey, setActiveContentKey] =
    useState<
      typeof SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY[keyof typeof SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY]
    >(activeContent);
  const [isDialogOpenDefault, setDialogOpenDefault] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { pathname: originalPathname } = useLocation();
  const pathname = translate.getNonLocalizedURL(originalPathname);
  const isEmployerHomePagePath = matchPath("/employers", pathname);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();

  const currentLocale = translate.getCurrentLocale();

  const [
    signInWithEmailAndPassword,
    ,
    isSignInWithEmailAndPasswordLoading,
    signInWithEmailAndPasswordError
  ] = useSignInWithEmailAndPassword(auth);

  const [signInWithGoogle, , isSignInWithGoogleLoading, signInWithGoogleError] =
    useSignInWithGoogle(auth);

  const [
    createUserWithEmailAndPassword,
    ,
    isCreateUserWithEmailAndPasswordLoading,
    createUserWithEmailAndPasswordError
  ] = useCreateUserWithEmailAndPassword(auth);

  const [forgotPassword, isForgotPasswordLoading, forgotPasswordError] =
    useHttpsCallable(functions, "forgotPassword");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLinkedInAuth = async () => {
    const provider = new OAuthProvider("linkedin.com");

    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      if (error instanceof FirebaseError) {
        const code = error?.code;
        if (code === "auth/popup-closed-by-user") {
          setErrorMessage("");
        } else if (code === "auth/account-exists-with-different-credential") {
          setErrorMessage(
            intl.get("t_auth_error_account_exists_diff_credentials")
          );
        } else if (code === "auth/email-already-in-use") {
          setErrorMessage(intl.get("t_auth_error_email_already_in_use"));
        } else if (code === "auth/user-disabled") {
          setErrorMessage(intl.get("t_auth_error_account_deactivated"));
        } else if (
          code === "auth/internal-error" ||
          code === "auth/too-many-requests"
        ) {
          setErrorMessage(intl.get("t_general_internal_error"));
        } else {
          setErrorMessage(intl.get("t_auth_error_default"));
        }
      }
    }
  };

  useEffect(() => {
    if (
      isForgotPasswordLoading ||
      isSignInWithGoogleLoading ||
      isSignInWithEmailAndPasswordLoading ||
      isCreateUserWithEmailAndPasswordLoading
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    isForgotPasswordLoading,
    isSignInWithGoogleLoading,
    isSignInWithEmailAndPasswordLoading,
    isCreateUserWithEmailAndPasswordLoading
  ]);

  // FIXME: Add Translation labels for all the errors
  useEffect(() => {
    const code = signInWithEmailAndPasswordError?.code;
    if (code === "auth/invalid-email") {
      setErrorMessage(intl.get("t_auth_error_wrong_email"));
    } else if (
      code === "auth/user-not-found" ||
      code === "auth/wrong-password" ||
      code === "auth/invalid-login-credentials"
    ) {
      setErrorMessage(intl.get("t_auth_error_wrong_email_or_password"));
    } else if (code === "auth/too-many-requests") {
      setErrorMessage(intl.get("t_auth_error_password_many_attempt"));
    } else if (code === "auth/internal-error") {
      setErrorMessage(intl.get("t_general_internal_error"));
    } else if (code === "auth/user-disabled") {
      setErrorMessage(intl.get("t_auth_error_account_deactivated"));
    } else {
      setErrorMessage(intl.get("t_general_internal_error"));
    }
  }, [signInWithEmailAndPasswordError]);

  useEffect(() => {
    const code = createUserWithEmailAndPasswordError?.code;
    if (code === "auth/invalid-email") {
      setErrorMessage(intl.get("t_auth_error_wrong_email"));
    } else if (code === "auth/email-already-in-use") {
      setErrorMessage(intl.get("t_auth_error_email_already_in_use"));
    } else if (code === "auth/weak-password") {
      setErrorMessage(intl.get("t_auth_error_weak_password"));
    } else if (
      code === "auth/internal-error" ||
      code === "auth/too-many-requests"
    ) {
      setErrorMessage(intl.get("t_general_internal_error"));
    } else {
      setErrorMessage(intl.get("t_general_internal_error"));
    }
  }, [createUserWithEmailAndPasswordError]);

  useEffect(() => {
    const code = (forgotPasswordError as FirebaseError | undefined)?.code;
    if (code === "auth/invalid-email") {
      setErrorMessage(intl.get("t_auth_error_wrong_email"));
    } else if (code === "auth/user-not-found") {
      setErrorMessage(intl.get("t_auth_error_user_not_found"));
    } else if (code === "auth/too-many-requests") {
      setErrorMessage(intl.get("t_auth_error_password_many_attempt"));
    } else if (code === "auth/internal-error") {
      setErrorMessage(intl.get("t_general_internal_error"));
    } else {
      setErrorMessage(intl.get("t_general_internal_error"));
    }
  }, [forgotPasswordError]);

  useEffect(() => {
    const code = signInWithGoogleError?.code;
    if (code === "auth/popup-closed-by-user") {
      setErrorMessage("");
    } else if (
      code === "auth/internal-error" ||
      code === "auth/too-many-requests"
    ) {
      setErrorMessage(intl.get("t_general_internal_error"));
    } else if (code === "auth/user-disabled") {
      setErrorMessage(intl.get("t_auth_error_account_deactivated"));
    } else {
      setErrorMessage(intl.get("t_general_internal_error"));
    }
  }, [signInWithGoogleError]);

  const schema = yup.object().shape({
    mode: yup.string().required(intl.get("t_general_internal_error")),
    email: yup
      .string()
      .email()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_email_address")
        })
      ),
    password: yup.string().when("mode", {
      is: (
        mode: typeof SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY[keyof typeof SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY]
      ) =>
        (
          [
            SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_IN,
            SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_UP
          ] as Array<
            typeof SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY[keyof typeof SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY]
          >
        ).includes(mode),
      then: yup.string().required(
        intl.get("t_error_required", {
          field: intl.get("t_general_password")
        })
      )
    })
  });

  const defaultFormValues: SignInSignUpForm = {
    mode: SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_IN,
    email: searchParams.get("email") ?? "",
    password: ""
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, reset } = methods;

  useEffect(() => {
    const formValues = {
      ...defaultFormValues,
      mode: activeContentKey
    };
    setErrorMessage("");
    reset(formValues);
  }, [activeContentKey]);

  useEffect(() => {
    const isLoginRequired = searchParams.get("login_required") ?? "";
    const mode = searchParams.get("mode") ?? "";

    if (isLoginRequired) {
      if (mode) {
        if (mode === "sign-up") {
          setActiveContentKey(
            SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_UP
          );
          toast.kampai(
            intl.get("t_general_internal_error_signup_required"),
            "warning"
          );
        }
        if (mode === "reset-password") {
          setActiveContentKey(
            SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.RESET_PASSWORD_EMAIL_INPUT
          );
        }
        // ...Add more modes as required
      } else {
        toast.kampai(
          intl.get("t_general_internal_error_login_required"),
          "warning"
        );
      }
      if (initiatorType === "default") {
        setDialogOpenDefault(true);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      activeContentKey === SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_UP &&
      !isLoading &&
      user &&
      !userProfile.loading &&
      !userProfile.value?.user_type
    ) {
      searchParams.set("set_user_type", "1");
      setSearchParams(searchParams.toString(), { replace: true });
    }
  }, [user, userProfile, isLoading, activeContentKey]);

  const getDialogTitle = (
    activeContentKey: typeof SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY[keyof typeof SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY]
  ) => {
    switch (activeContentKey) {
      case SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.RESET_PASSWORD_EMAIL_SENT:
      case SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.RESET_PASSWORD_EMAIL_INPUT:
        return intl.get("t_signin_reset_password");
      case SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_UP:
        return intl.get(
          isEmployerHomePagePath
            ? "t_general_employer_signup"
            : "t_general_candidate_signup"
        );
      case SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_IN:
      default:
        return intl.get("t_signinsignup_welcome_back");
    }
  };

  const onSubmit = (formData: SignInSignUpForm) => {
    setAuthLocale();
    switch (formData.mode) {
      case SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_IN:
        signInWithEmailAndPassword(formData.email, formData.password);
        searchParams.delete("send_verification_email");
        searchParams.delete("set_user_type");
        setSearchParams(searchParams.toString(), { replace: true });
        break;
      case SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_UP:
        createUserWithEmailAndPassword(formData.email, formData.password);
        logEvent(analytics, ANALYTICS_EVENT_TYPE.EMAIL_SIGN_UP, {
          user_type: isEmployerHomePagePath
            ? USER_TYPE.COMPANY
            : USER_TYPE.INDIVIDUAL
        });
        break;
      case SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.RESET_PASSWORD_EMAIL_INPUT:
        {
          const returnURL = searchParams.get("return_url") ?? "";
          const url =
            window.location.protocol +
            "//" +
            window.location.hostname +
            ":" +
            window.location.port +
            returnURL;
          forgotPassword({
            email: formData.email,
            returnURL: url,
            locale: currentLocale
          });
          setActiveContentKey(
            SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.RESET_PASSWORD_EMAIL_SENT
          );
        }
        break;
      default:
        break;
    }
  };

  if (auth.currentUser) {
    return <></>;
  } else {
    return (
      <Dialog
        data-testid="sign_in_sign_up_dialog"
        initiator={initiator}
        isDefaultOpen={isDialogOpenDefault}
        maxWidth="xs"
        title={getDialogTitle(activeContentKey)}
        onClose={() => {
          // Timeout is to wait for the animation to complete and then change the content.
          setTimeout(() => {
            setActiveContentKey(
              SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_IN
            );
          }, 250);
        }}>
        {(_, closeDialog) => (
          <DialogContent>
            <>
              {isLoading ? <Loader /> : false}
              <Stack direction="column" gap={1}>
                {(() => {
                  switch (activeContentKey) {
                    case SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.RESET_PASSWORD_EMAIL_SENT:
                      return (
                        <>
                          <Typography variant="subtitle4">
                            {intl.get("t_reset_password_email_sent_heading")}
                          </Typography>
                          <Typography
                            variant="body1"
                            color={colorPalette.black.hover.base}>
                            {intl.get("t_reset_password_email_sent_subtitle")}
                          </Typography>
                          <Button fullWidth handleClick={closeDialog}>
                            {intl.get("t_general_close")}
                          </Button>
                        </>
                      );
                    case SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.RESET_PASSWORD_EMAIL_INPUT:
                      return (
                        <>
                          <Typography variant="subtitle4">
                            {intl.get("t_reset_password_email_input_heading")}
                          </Typography>
                          <Typography
                            mt={1.5}
                            variant="subtitle4"
                            color={colorPalette.red.base}>
                            {errorMessage}
                          </Typography>
                          <Box
                            data-testid="reset_password_dialog_box"
                            component="form"
                            noValidate
                            onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                              data-testid="reset_password_email_input"
                              type="email"
                              name="email"
                              control={control}
                              label={intl.get("t_general_email_address")}
                              placeholder={intl.get("t_general_email_address")}
                              required
                            />
                            <Box display="none">
                              <TextField
                                type="hidden"
                                name="mode"
                                defaultValue={
                                  SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.RESET_PASSWORD_EMAIL_INPUT
                                }
                                readOnly
                                control={control}
                              />
                            </Box>
                            <Button
                              data-testid="reset_password_email_input_button"
                              fullWidth
                              type="submit">
                              {intl.get(
                                "t_reset_password_email_input_send_button"
                              )}
                            </Button>
                          </Box>
                        </>
                      );
                    case SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_UP:
                      return (
                        <>
                          <Typography
                            variant="subtitle4"
                            color={colorPalette.red.base}>
                            {errorMessage}
                          </Typography>
                          <Box
                            component="form"
                            noValidate
                            onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                              data-testid="sign_up_email_input"
                              type="email"
                              name="email"
                              control={control}
                              label={intl.get("t_general_email_address")}
                              placeholder={intl.get("t_general_email_address")}
                              required
                              disabled={searchParams.has("email")}
                            />
                            <TextField
                              data-testid="sign_up_password_input"
                              type="password"
                              name="password"
                              control={control}
                              label={intl.get("t_general_password")}
                              placeholder={intl.get("t_general_password")}
                              required
                            />
                            <Box display="none">
                              <TextField
                                type="hidden"
                                name="mode"
                                defaultValue={
                                  SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_UP
                                }
                                readOnly
                                control={control}
                              />
                            </Box>
                            <Button
                              fullWidth
                              type="submit"
                              data-testid="sign_up_form_submit_button">
                              {intl.get(
                                isEmployerHomePagePath
                                  ? "t_general_employer_signup"
                                  : "t_general_candidate_signup"
                              )}
                            </Button>
                          </Box>

                          <Box my={2}>
                            <Divider>{intl.get("t_general_or")}</Divider>
                          </Box>

                          <SocialButton
                            variant={SOCIAL_BUTTON_VARIANT.GOOGLE}
                            label={intl.get(
                              "t_signinsignup_continue_with_google"
                            )}
                            onClick={() => {
                              signInWithGoogle();
                              logEvent(
                                analytics,
                                ANALYTICS_EVENT_TYPE.GOOGLE_SIGNIN_SIGNUP,
                                {
                                  user_type: isEmployerHomePagePath
                                    ? USER_TYPE.COMPANY
                                    : USER_TYPE.INDIVIDUAL
                                }
                              );
                            }}
                          />
                          <SocialButton
                            variant={SOCIAL_BUTTON_VARIANT.LINKEDIN}
                            label={intl.get(
                              "t_signinsignup_continue_with_linkedin"
                            )}
                            onClick={() => {
                              handleLinkedInAuth();
                              logEvent(
                                analytics,
                                ANALYTICS_EVENT_TYPE.LINKEDIN_SIGNIN_SIGNUP,
                                {
                                  user_type: isEmployerHomePagePath
                                    ? USER_TYPE.COMPANY
                                    : USER_TYPE.INDIVIDUAL
                                }
                              );
                            }}
                          />
                          <Stack justifyContent="start">
                            <Typography variant="body1" alignItems="center">
                              {intl.get("t_signinsignup_are_you_a_member")}
                              &nbsp;
                              <Link
                                href="#"
                                underline="hover"
                                onClick={(event) => {
                                  event.preventDefault();
                                  setActiveContentKey(
                                    SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_IN
                                  );
                                }}>
                                {intl.get("t_general_signin")}
                              </Link>
                            </Typography>
                            <Typography variant="body1" mb={1.5}>
                              {isEmployerHomePagePath
                                ? intl.get("t_signinsignup_are_you_a_candidate")
                                : intl.get(
                                    "t_signinsignup_are_you_an_employer"
                                  )}
                              &nbsp;
                              <Link
                                data-testid="sign_up_sign_in_dialog_user_type_toggle_link"
                                href="#"
                                underline="hover"
                                onClick={(event) => {
                                  event.preventDefault();
                                  if (isEmployerHomePagePath) {
                                    navigate(
                                      `/${translate.getCurrentLocale()}/`
                                    );
                                  } else {
                                    navigate(
                                      `/${translate.getCurrentLocale()}/employers`
                                    );
                                  }
                                  setActiveContentKey(
                                    SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_UP
                                  );
                                }}>
                                {intl.get("t_general_signup")}
                              </Link>
                            </Typography>
                          </Stack>

                          <Typography
                            width="100%"
                            textAlign="center"
                            variant="helperText"
                            lineHeight={1.3} // here height is modified to improve the text readability
                            color={colorPalette.black.hover.base}>
                            <DangerousComponentFromHTML
                              html={intl.get(
                                isEmployerHomePagePath
                                  ? "t_signup_employer_tnc"
                                  : "t_signup_candidate_tnc",
                                {
                                  userTerms: renderToString(
                                    <Link
                                      data-testid="signin_signup_user_terms_link"
                                      href={`/${translate.getCurrentLocale()}/user-terms-and-conditions`}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                      {intl.get("t_general_user_terms")}
                                    </Link>
                                  ),
                                  privacyPolicy: renderToString(
                                    <Link
                                      data-testid="signin_signup_privacy_policy_link"
                                      href={`/${translate.getCurrentLocale()}/privacy-policy`}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                      {intl.get("t_general_privacy_policy")}
                                    </Link>
                                  ),
                                  recruiterTeam: isEmployerHomePagePath
                                    ? renderToString(
                                        <Link
                                          data-testid="signin_signup_recruiter_terms_link"
                                          href={`/${translate.getCurrentLocale()}/employer-terms-and-conditions`}
                                          target="_blank"
                                          rel="noopener noreferrer">
                                          {intl.get(
                                            "t_general_recruiter_terms"
                                          )}
                                        </Link>
                                      )
                                    : ""
                                }
                              )}
                            />
                          </Typography>
                        </>
                      );
                    case SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_IN:
                    default:
                      return (
                        <>
                          <Typography
                            variant="subtitle4"
                            color={colorPalette.red.base}>
                            {errorMessage}
                          </Typography>
                          <Box
                            component="form"
                            noValidate
                            onSubmit={handleSubmit(onSubmit)}
                            width="100%">
                            <TextField
                              data-testid="sign_in_email_input"
                              type="email"
                              name="email"
                              control={control}
                              label={intl.get("t_general_email_address")}
                              placeholder={intl.get("t_general_email_address")}
                              required
                            />
                            <TextField
                              data-testid="sign_in_password_input"
                              type="password"
                              name="password"
                              control={control}
                              label={intl.get("t_general_password")}
                              placeholder={intl.get("t_general_password")}
                              required
                            />
                            <Box display="none">
                              <TextField
                                type="hidden"
                                name="mode"
                                defaultValue={
                                  SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_IN
                                }
                                readOnly
                                control={control}
                              />
                            </Box>
                            <Stack direction="row-reverse" mb={2}>
                              <Typography>
                                <Link
                                  data-testid="sign_in_forgot_password_link"
                                  href="#"
                                  underline="hover"
                                  onClick={() =>
                                    setActiveContentKey(
                                      SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.RESET_PASSWORD_EMAIL_INPUT
                                    )
                                  }>
                                  {intl.get("t_signin_forgot_password")}
                                </Link>
                              </Typography>
                            </Stack>
                            <Button
                              fullWidth
                              type="submit"
                              data-testid="sign_in_form_submit_button">
                              {intl.get("t_general_signin")}
                            </Button>
                          </Box>

                          <Box my={2}>
                            <Divider>{intl.get("t_general_or")}</Divider>
                          </Box>

                          <SocialButton
                            variant={SOCIAL_BUTTON_VARIANT.GOOGLE}
                            label={intl.get(
                              "t_signinsignup_continue_with_google"
                            )}
                            onClick={() => signInWithGoogle()}
                          />
                          <SocialButton
                            variant={SOCIAL_BUTTON_VARIANT.LINKEDIN}
                            label={intl.get(
                              "t_signinsignup_continue_with_linkedin"
                            )}
                            onClick={handleLinkedInAuth}
                          />

                          <Typography variant="body1" alignItems="center">
                            {intl.get("t_signinsignup_not_a_member_yet")}
                            &nbsp;
                            <Link
                              data-testid="sign_in_sign_up_dialog_sign_up_mode_link"
                              href="#"
                              underline="hover"
                              onClick={(event) => {
                                event.preventDefault();
                                setActiveContentKey(
                                  SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_UP
                                );
                              }}>
                              {intl.get("t_general_signup")}
                            </Link>
                          </Typography>
                        </>
                      );
                  }
                })()}
                <Typography
                  variant="body2"
                  color={colorPalette.black[80]}
                  width="100%"
                  mt={2}
                  textAlign="center">
                  {intl.get("t_general_copyright", {
                    year: dayjs().format("YYYY")
                  })}
                </Typography>
              </Stack>
            </>
          </DialogContent>
        )}
      </Dialog>
    );
  }
};

export default SignInSignUpDialog;
