import { useEffect } from "react";

import intl from "react-intl-universal";

import { Box } from "@mui/material";
import useForceUpdate from "use-force-update";

import TabNavigation from "@components/TabNavigation";

import useUserProfile from "@hooks/database/useUserProfile";

import { LOCALE } from "@utils/config";
import translate from "@utils/translate";

const ResumeTab = () => {
  const { value } = useUserProfile();
  const { progress_steps } = value ?? {};
  const enShowBadge =
    progress_steps?.en_cv?.current &&
    progress_steps?.en_cv?.total &&
    progress_steps?.en_cv?.current > progress_steps?.en_cv?.total
      ? false
      : true;
  const jaShowBadge =
    progress_steps?.ja_cv?.current &&
    progress_steps?.ja_cv?.total &&
    progress_steps?.ja_cv?.current > progress_steps?.ja_cv?.total
      ? false
      : true;
  const enMenuLinks = [
    {
      label: intl.get("t_general_english_cv"),
      redirectLink: "/profile/resume/en-cv",
      showBadge: enShowBadge
    },
    {
      label: intl.get("t_general_japanese_cv"),
      redirectLink: "/profile/resume/ja-cv",
      showBadge: jaShowBadge
    },
    {
      label: intl.get("t_general_japanese_rirekisho"),
      redirectLink: "/profile/resume/ja-rirekisho",
      showBadge: jaShowBadge
    }
  ];
  const jaMenuLinks = [
    {
      label: intl.get("t_general_japanese_cv"),
      redirectLink: "/profile/resume/ja-cv",
      showBadge: enShowBadge
    },
    {
      label: intl.get("t_general_japanese_rirekisho"),
      redirectLink: "/profile/resume/ja-rirekisho",
      showBadge: jaShowBadge
    },
    {
      label: intl.get("t_general_english_cv"),
      redirectLink: "/profile/resume/en-cv",
      showBadge: jaShowBadge
    }
  ];
  const forceUpdate = useForceUpdate();
  const languageChanged = () => {
    forceUpdate();
  };

  useEffect(() => {
    translate.addLanguageChangedListener(languageChanged);
    return () => {
      translate.removeLanguageChangedListener(languageChanged);
    };
  }, []);
  const currentLocale = translate.getCurrentLocale();
  return (
    <Box id="candidate-resume-tab">
      <TabNavigation
        navLinks={currentLocale === LOCALE.EN ? enMenuLinks : jaMenuLinks}
        color="dark"
      />
    </Box>
  );
};

export default ResumeTab;
