import { Grid, Stack } from "@mui/material";

import SkeletonAvatar from "@skeletons/SkeletonAvatar";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Table, { ColumnProps } from "@components/Table";

import { intl } from "@utils/translate";

const SkeletonRirekishoTable = () => {
  const nameTableColumns: Array<ColumnProps> = [
    {
      accessorKey: "fullName",
      header: `${intl.get("t_general_last_name")}, ${intl.get(
        "t_general_first_name"
      )}`
    }
  ];

  const locationTableColumns: Array<ColumnProps> = [
    {
      accessorKey: "location",
      header: `${intl.get("t_general_country")}, ${intl.get("t_general_city")}`
    }
  ];

  const phoneNumberTableColumns: Array<ColumnProps> = [
    {
      accessorKey: "phoneNumber",
      header: intl.get("t_general_phone_number")
    }
  ];

  const emailTableColumns: Array<ColumnProps> = [
    {
      accessorKey: "email",
      header: intl.get("t_general_email_address")
    }
  ];

  const educationTableColumns: Array<ColumnProps> = [
    {
      accessorKey: "year",
      header: intl.get("t_general_year"),
      cellWidth: "10%"
    },
    {
      accessorKey: "month",
      header: intl.get("t_general_month"),
      cellWidth: "10%"
    },
    {
      accessorKey: "education",
      header: intl.get("t_resumes_rirekisho_education_history")
    }
  ];

  const jobExperienceTableColumns: Array<ColumnProps> = [
    {
      accessorKey: "year",
      header: intl.get("t_general_year"),
      cellWidth: "10%"
    },
    {
      accessorKey: "month",
      header: intl.get("t_general_month"),
      cellWidth: "10%"
    },
    {
      accessorKey: "workHistory",
      header: intl.get("t_resumes_rirekisho_work_history")
    }
  ];

  return (
    <Grid container>
      <Grid item xs={8}>
        <Table
          columns={nameTableColumns}
          data={[
            {
              fullName: (
                <SkeletonTypography variant="subtitle4" width="medium" />
              )
            }
          ]}
          cellHeight="3.125rem"
          showTableBottomBorder={false}
        />
      </Grid>
      <Grid item xs={4}>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <SkeletonAvatar size="large" />
        </Stack>
      </Grid>

      <Grid item xs={8}>
        <Table
          columns={locationTableColumns}
          data={[
            {
              location: (
                <SkeletonTypography variant="subtitle4" width="medium" />
              )
            }
          ]}
          cellHeight="5.625rem"
        />
      </Grid>

      <Grid item container xs={4}>
        <Grid item xs={12}>
          <Table
            columns={phoneNumberTableColumns}
            data={[
              {
                phoneNumber: (
                  <SkeletonTypography variant="subtitle4" width="medium" />
                )
              }
            ]}
            cellHeight="1.75rem"
            showTableBottomBorder={false}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={emailTableColumns}
            data={[
              {
                email: <SkeletonTypography variant="subtitle4" width="medium" />
              }
            ]}
            cellHeight="1.75rem"
          />
        </Grid>
      </Grid>

      <Grid item xs={12} my={1}>
        <Table
          columns={educationTableColumns}
          data={new Array(3).fill({
            year: <SkeletonTypography variant="subtitle4" width="short" />,
            month: <SkeletonTypography variant="subtitle4" width="short" />,
            education: <SkeletonTypography variant="subtitle4" width="medium" />
          })}
          showRowBorder
        />
      </Grid>

      <Grid item xs={12}>
        <Table
          columns={jobExperienceTableColumns}
          data={new Array(3).fill({
            year: <SkeletonTypography variant="subtitle4" width="short" />,
            month: <SkeletonTypography variant="subtitle4" width="short" />,
            workHistory: (
              <SkeletonTypography variant="subtitle4" width="medium" />
            )
          })}
          showRowBorder
        />
      </Grid>
    </Grid>
  );
};

export default SkeletonRirekishoTable;
