import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import * as yup from "yup";

import Button from "@components/Button";
import TextField from "@components/TextField";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import { auth } from "@utils/firebase";
import { isResumePurchased } from "@utils/purchaseResume";
import translate, { intl } from "@utils/translate";

const Chat = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const userProfile = useUserProfile();
  const companyId = userProfile?.value?.company_id ?? "";
  const toast = useToast();

  // validation schema
  const schema = yup.object({
    candidateId: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: "Candidate Id"
        })
      ),
    applicationId: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: "Application Id"
        })
      ),
    jobId: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: "Job Id"
        })
      )
  });

  const methods = useForm({
    defaultValues: {
      candidateId: "",
      applicationId: "",
      jobId: ""
    },
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control } = methods;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFormSubmit = async (formData: any) => {
    const { applicationId, candidateId, jobId } = formData;

    navigate(
      `/${translate.getCurrentLocale()}/employers/conversations/new?application_id=${applicationId}&candidate_id=${candidateId}&job_id=${jobId}&company_id=${companyId}`
    );

    // check resume is purchased or not for given data
    if (user?.uid) {
      const isPurchased = await isResumePurchased(
        applicationId,
        userProfile,
        candidateId
      );
      if (!isPurchased) {
        toast.kampai(
          `Candidate(${candidateId}) resume is not purchased, Please purchase to chat with candidate`,
          "error"
        );
        return;
      }
    }
  };

  return (
    <Box
      mb={2}
      noValidate
      component="form"
      onSubmit={handleSubmit(handleFormSubmit)}>
      <Typography variant="h3">Send message to candidate</Typography>
      <Typography my={2.5} color="text.secondary">
        Make sure you buy resume to start conversation
      </Typography>
      <Grid container spacing={{ xs: 0, md: 3 }}>
        <Grid item xs={12} md={6}>
          <TextField
            control={control}
            name="candidateId"
            label="Candidate Id"
            placeholder="Enter candidate id"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            control={control}
            name="applicationId"
            label="Application Id"
            placeholder="Enter application id"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            control={control}
            name="jobId"
            label="Job Id"
            placeholder="Enter job id"
            required
          />
        </Grid>
      </Grid>
      <Button type="submit">Chat with candidate</Button>
    </Box>
  );
};

export default Chat;
