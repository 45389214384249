import { ChangeEvent, ReactNode, useState } from "react";

import { Control, Controller, FieldError } from "react-hook-form";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  InputLabel,
  TextField as MuiTextField,
  Stack,
  SxProps,
  Theme
} from "@mui/material";

import { ALLOW_ONLY_NON_NEGATIVE_INTEGERS_REGEX } from "@utils/config";
import { intl } from "@utils/translate";

interface TextFieldProps {
  "name": string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  "control": Control<any>;
  "label"?: string;
  "disabled"?: boolean;
  "required"?: boolean;
  "type"?: "text" | "password" | "number" | "email" | "hidden";
  "placeholder"?: string;
  "multiline"?: boolean;
  "rows"?: number;
  "autoComplete"?: boolean;
  "helperText"?: string;
  "startAdornment"?: ReactNode;
  "endAdornment"?: ReactNode;
  "maxLength"?: number;
  "readOnly"?: boolean;
  "defaultValue"?: string;
  "allowOnlyNonNegativeIntegers"?: boolean;
  "sx"?: SxProps<Theme>; // This is style of the child input field
  "baseSx"?: SxProps<Theme>; // This is style of the MuiTextField component.
  "data-testid"?: string;
}

const HelperText = ({
  value,
  error,
  maxLength = 0,
  helperText
}: {
  value: string;
  error: FieldError | undefined;
  maxLength: number;
  helperText: string;
}) => {
  const leftCharacters = maxLength - value?.length;
  if (error || helperText || maxLength) {
    return (
      <Stack direction="row" justifyContent="space-between">
        {error ? <span>{error?.message}</span> : <span>{helperText}</span>}
        {maxLength > 0 ? (
          <span>
            {intl.get("t_textfield_multi_line_characters_left", {
              character: leftCharacters >= 0 ? leftCharacters : 0
            })}
          </span>
        ) : (
          false
        )}
      </Stack>
    );
  } else {
    return null;
  }
};

const TextField = ({
  name,
  control,
  label,
  disabled = false,
  required = false,
  type = "text",
  placeholder = "",
  multiline = false,
  rows = 1,
  autoComplete = false,
  helperText = "",
  startAdornment = null,
  endAdornment = null,
  maxLength = 0,
  readOnly = false,
  defaultValue,
  allowOnlyNonNegativeIntegers = false,
  baseSx,
  sx,
  "data-testid": dataTestId
}: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [inputType, setInputType] = useState<
    "text" | "password" | "number" | "email" | "hidden"
  >(type);

  const handleClickShowPassword = () => {
    if (inputType === "password") {
      setInputType("text");
      setShowPassword(!showPassword);
    } else {
      setInputType("password");
      setShowPassword(!showPassword);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            {label ? (
              <InputLabel
                htmlFor={name}
                required={required}
                disabled={disabled}>
                {label}
              </InputLabel>
            ) : (
              false
            )}
            <MuiTextField
              id={name}
              helperText={
                <HelperText
                  value={value}
                  error={error}
                  maxLength={maxLength}
                  helperText={helperText}
                />
              }
              FormHelperTextProps={{ component: "span" }}
              // Reference:- https://stackoverflow.com/a/75454523/13285590
              onInput={(e: ChangeEvent<HTMLInputElement>) => {
                if (allowOnlyNonNegativeIntegers) {
                  e.target.value = e.target.value.replace(
                    ALLOW_ONLY_NON_NEGATIVE_INTEGERS_REGEX,
                    ""
                  );
                }
              }}
              error={!!error}
              onChange={onChange}
              value={value}
              fullWidth
              multiline={multiline}
              rows={rows}
              margin="dense"
              variant="outlined"
              placeholder={!label && required ? `${placeholder}*` : placeholder}
              disabled={disabled}
              required={required}
              sx={baseSx}
              type={type === "password" ? inputType : type}
              SelectProps={{
                inputProps: {
                  sx
                }
              }}
              InputProps={{
                inputProps: {
                  sx,
                  "data-testid": dataTestId
                },
                readOnly,
                autoComplete: autoComplete ? "on" : "off",
                startAdornment: (
                  <>
                    {startAdornment ? (
                      <InputAdornment position="start">
                        {startAdornment}
                      </InputAdornment>
                    ) : (
                      false
                    )}
                  </>
                ),
                endAdornment: (
                  <>
                    {type === "password" && !endAdornment ? (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      false
                    )}
                    {endAdornment ? (
                      <InputAdornment position="end">
                        {endAdornment}
                      </InputAdornment>
                    ) : (
                      false
                    )}
                  </>
                )
              }}
            />
          </>
        );
      }}
    />
  );
};

export default TextField;
