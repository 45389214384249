import { useNavigate } from "react-router-dom";

import { Stack } from "@mui/material";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";

import Button from "@components/Button";
import ProgressBar from "@components/ProgressBar";
import Typography from "@components/Typography";
import Well from "@components/Well";

import { INDIVIDUAL_USER_PROFILE_COMPLETION_TOTAL_PROGRESS_STEPS_EN_CV } from "@utils/config";
import translate, { intl } from "@utils/translate";

dayjs.extend(relative);

interface ResumeProgressCardProps {
  totalProgressStep?: number;
  currentProgressStep?: number;
  nextProgressStep?: {
    label: string;
    to: string;
  };
  updatedDate?: Date | null;
  isResumeUploaded?: boolean;
}
const ResumeProgressCard = ({
  totalProgressStep = INDIVIDUAL_USER_PROFILE_COMPLETION_TOTAL_PROGRESS_STEPS_EN_CV,
  currentProgressStep = 1,
  nextProgressStep,
  updatedDate = null,
  isResumeUploaded = false
}: ResumeProgressCardProps) => {
  const navigate = useNavigate();

  return (
    <>
      {currentProgressStep <= totalProgressStep && nextProgressStep ? (
        <Well>
          {!!updatedDate && (
            <Stack
              component="span"
              direction="row"
              alignItems="center"
              ml="auto"
              mb={3}>
              <Typography variant="subtitle5" color="text.primary">
                {intl.get("t_resumes_progress_updatedAt")}
                :&nbsp;
              </Typography>
              <Typography variant="body2" color="text.primary" lineHeight={0}>
                {dayjs(updatedDate)
                  .locale(translate.getCurrentLocaleShort())
                  .fromNow()}
              </Typography>
            </Stack>
          )}

          <Typography variant="h4" color="text.primary" mb={2.5}>
            {isResumeUploaded
              ? intl.get("t_resumes_resume_uploaded_progress_title")
              : intl.get("t_resumes_progress_title")}
          </Typography>
          <Typography variant="body1" color="text.secondary" mb={2}>
            {intl.get("t_resumes_progress_subtitle")}
          </Typography>
          <Button
            data-testid="upload_basic_information_button"
            variant="contained"
            handleClick={() => navigate(nextProgressStep.to)}>
            {intl.get(nextProgressStep.label)}
          </Button>

          <Stack
            data-testid="resume_progress_bar"
            direction={{ xs: "column", lg: "row" }}
            alignItems={{ xs: "end", lg: "center" }}
            justifyContent={{ xs: "end", lg: "center" }}
            gap={1}
            ml="auto"
            mt={2}>
            <ProgressBar
              currentProgressStep={currentProgressStep}
              totalProgressSteps={totalProgressStep}
            />
            <Typography variant="body1" color="text.secondary" ml={1.75}>
              {intl.get("t_resumes_progress_status", {
                step: totalProgressStep - (currentProgressStep - 1)
              })}
            </Typography>
          </Stack>
        </Well>
      ) : (
        false
      )}
    </>
  );
};

export default ResumeProgressCard;
