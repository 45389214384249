import { useState } from "react";

import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Info as InfoIcon
} from "@mui/icons-material";
import { Alert, Box, Collapse, Container, styled } from "@mui/material";

import Typography from "@components/Typography";

const StyledAlert = styled(Alert)({
  boxShadow: "none !important",
  wordBreak: "break-word"
});

interface GlobalNotificationBannerProps {
  variant?: "success" | "error" | "info" | "warning";
  message: string;
}

const GlobalNotificationBanner = ({
  variant = "warning",
  message = ""
}: GlobalNotificationBannerProps) => {
  const [isOpen, setOpen] = useState<boolean>(true);

  return (
    <Box zIndex={1} width="100%">
      <Collapse in={isOpen} sx={{ bgcolor: "error.light" }}>
        <Container>
          <StyledAlert
            severity={variant}
            variant="filled"
            iconMapping={{
              success: <CheckCircleIcon />,
              error: <ErrorIcon />,
              info: <InfoIcon color="info" />,
              warning: <InfoIcon />
            }}
            onClose={() => setOpen(false)}>
            <Typography variant="body1">{message}</Typography>
          </StyledAlert>
        </Container>
      </Collapse>
    </Box>
  );
};

export default GlobalNotificationBanner;
