import { ReactNode } from "react";

import { styled, Table, TableContainer } from "@mui/material";

import { colorPalette } from "@utils/theme";
interface DataTableWrapperProps {
  children?: ReactNode;
}

const StyledTable = styled(Table)({
  "maxWidth": "100%",
  "tableLayout": "auto",
  "borderRadius": "0.25rem",
  "border": `1px solid ${colorPalette.grey.base}`,
  "borderCollapse": "separate",
  "& .MuiTableRow-root": {
    "&: hover": {
      backgroundColor: colorPalette.blue["04"]
    }
  },
  "& .MuiTableRow-head, & .MuiTableRow-footer": {
    "&: hover": {
      backgroundColor: "inherit"
    }
  },
  "& .MuiTableCell-root": {
    "& .MuiTablePagination-root": {
      borderBottom: 0
    }
  }
});

const DataTableWrapper = ({ children }: DataTableWrapperProps) => {
  return (
    <TableContainer>
      <StyledTable>{children}</StyledTable>
    </TableContainer>
  );
};

export default DataTableWrapper;
