import { convertFromRaw, EditorState, RawDraftContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

/**
 * Converts a RawDraftContentState to plain text.
 *
 * @param contentState - The RawDraftContentState to convert to plain text.
 * @returns The plain text representation of the RawDraftContentState.
 */

export const rawDraftContentStateToString = (
  contentState: RawDraftContentState | undefined
): string => {
  if (!contentState) {
    return ""; // Handle the case when contentState is undefined
  }

  return convertFromRaw(contentState).getPlainText();
};

/**
 * Converts a RawDraftContentState to HTML.
 *
 * @param contentState - The RawDraftContentState to convert to HTML.
 * @returns The HTML representation of the RawDraftContentState.
 */
export const rawDraftContentStateToHTML = (
  contentState: RawDraftContentState | undefined
): string => {
  if (!contentState) {
    return ""; // Handle the case when contentState is undefined
  }

  const editorState = EditorState.createWithContent(
    convertFromRaw(contentState)
  );
  const contentHTML = stateToHTML(editorState.getCurrentContent());

  return contentHTML;
};
