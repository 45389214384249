import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { LOCALE } from "@utils/config";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";
dayjs.extend(relativeTime);

/**
 * Format conversation timestamps based on different conditions.
 *
 * @param {Timestamp} timestamp The timestamp of the conversation message.
 * @returns {string} The formatted timestamp string.
 */

export const formatConversationTimestamp = (timestamp: Timestamp): string => {
  const currentLocal = translate.getCurrentLocale();

  const messageDate = timestamp.toDate();
  const now = dayjs(); // Current date and time

  // Check if the message is today
  if (dayjs(messageDate).isSame(now, "day")) {
    // Format as HH:mm (24-hour format) for today's messages
    return dayjs(messageDate).format("HH:mm");
  } else if (dayjs(messageDate).isSame(now.subtract(1, "day"), "day")) {
    // Format as 'Yesterday' for messages sent yesterday
    return intl.get("t_general_yesterday");
  } else {
    // Format as DD/MM/YYYY for older messages
    return dayjs(messageDate).format(
      currentLocal === LOCALE.EN ? "DD/MM" : "MM/DD"
    );
  }
};
