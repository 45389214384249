import {
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon
} from "@mui/icons-material";
import { Box, Stack, styled } from "@mui/material";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";

import Cursor from "@components/Cursor";
import EllipsisTypography from "@components/EllipsisTypography";
import CompanyInfo from "@components/JobCards/Common/CompanyInfo";
import JobCategories from "@components/JobCards/Common/JobCategories";
import JobStatus from "@components/JobCards/Common/JobStatus";
import Typography from "@components/Typography";

import Location from "@interfaces/database/Location";

import { JOB_STATUS_TAGS } from "@utils/config";
import { colorPalette } from "@utils/theme";
import translate from "@utils/translate";

dayjs.extend(relative);

interface JobCardProps {
  "isBookmarked"?: boolean;
  "createdDate"?: Date;
  "description"?: string;
  "status": Array<typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]>;
  "categories"?: Array<string>;
  "companyName"?: string;
  "companyLogo"?: string;
  "jobTitle"?: string;
  "location"?: Location;
  "handleBookmarkChange"?: (isBookmarked: boolean) => void;
  "handleClick"?: () => void;
  "isSelected"?: boolean;
  "data-testid"?: string;
}

export const commonFavoriteIconStyle = {
  height: 24,
  width: 24,
  display: "flex",
  justifyContent: "flex-end",
  cursor: "pointer"
};

export const StyledFavoriteIconBoxForMobile = styled(Box)(({ theme }) => {
  return {
    display: "none",
    color: theme.palette.error.main,
    [theme.breakpoints.down("md")]: {
      display: "block",
      margin: "0 0 8px auto",
      width: "max-content"
    }
  };
});

export const StyledFavoriteIconBoxForDesktop = styled(Box)(({ theme }) => {
  return {
    display: "block",
    color: theme.palette.error.main,
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  };
});

const JobCard = ({
  isBookmarked = false,
  createdDate,
  companyLogo,
  description,
  status,
  categories = [],
  companyName,
  jobTitle,
  location,
  handleBookmarkChange,
  handleClick,
  isSelected = false,
  "data-testid": dataTestId
}: JobCardProps) => {
  const CurrentFavoriteIcon = () =>
    isBookmarked ? (
      <FavoriteIcon
        data-testid="bookmark_icon"
        sx={commonFavoriteIconStyle}
        onClick={(e) => {
          handleBookmarkChange ? handleBookmarkChange(isBookmarked) : false;
          e.stopPropagation();
        }}
      />
    ) : (
      <FavoriteBorderIcon
        data-testid="bookmark_icon"
        sx={commonFavoriteIconStyle}
        onClick={(e) => {
          handleBookmarkChange ? handleBookmarkChange(isBookmarked) : false;
          e.stopPropagation();
        }}
      />
    );

  return (
    <Cursor>
      <Box
        data-testid={dataTestId}
        position="relative"
        height="100%"
        p={2}
        borderRadius={1}
        bgcolor="common.white"
        onClick={handleClick}
        boxShadow={
          isSelected ? `0px 3px 8px ${colorPalette.black.hover["20"]}` : "none"
        }
        sx={{
          "&:hover": {
            boxShadow: `0px 3px 8px ${colorPalette.black.hover["30"]}`,
            transform: "scale(1.03)"
          },
          "transition": "transform 0.3s ease"
        }}>
        {/* job status and favorite icon section start */}
        {handleBookmarkChange ? (
          <StyledFavoriteIconBoxForMobile>
            <CurrentFavoriteIcon />
          </StyledFavoriteIconBoxForMobile>
        ) : (
          false
        )}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap">
          <JobStatus status={status} />
          {handleBookmarkChange ? (
            <StyledFavoriteIconBoxForDesktop
              onClick={(e) => {
                handleBookmarkChange(!isBookmarked);
                e.stopPropagation();
              }}>
              <CurrentFavoriteIcon />
            </StyledFavoriteIconBoxForDesktop>
          ) : (
            false
          )}
        </Box>
        {/* job status and favorite icon section end */}

        {/* company and role information start */}
        <CompanyInfo
          companyLogo={companyLogo}
          companyName={companyName}
          location={location}
          jobTitle={jobTitle}
        />
        {/* company and role information end */}

        {/* description start*/}
        <EllipsisTypography lineClamp={3}>{description}</EllipsisTypography>
        <br />
        {/* description end */}

        <Stack
          gap={1}
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          mt={1}>
          <JobCategories categories={categories} />
          {/* date start */}
          <Stack direction="row" ml="auto" gap={1} alignItems="center">
            <Typography variant="body2" color="text.secondary">
              {dayjs(createdDate)
                .locale(translate.getCurrentLocaleShort())
                .fromNow()}
            </Typography>
          </Stack>
          {/* date end */}
        </Stack>
      </Box>
    </Cursor>
  );
};

export default JobCard;
