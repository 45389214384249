import { Avatar, Box, styled } from "@mui/material";

import Icon from "@components/Icon";

interface CompanyLogoProps {
  src?: string;
  alt?: string;
}

const StyledAvatar = styled(Avatar)({
  "height": "3.5rem",
  "width": "3.5rem",
  "borderRadius": 4,
  "& .MuiAvatar-img": {
    objectFit: "contain"
  }
});

const CompanyLogo = ({ src = "", alt = "-" }: CompanyLogoProps) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={56}
      width={56}>
      <StyledAvatar src={src}>
        {alt ? alt[0] : <Icon type="domain" filled />}
      </StyledAvatar>
    </Box>
  );
};

export default CompanyLogo;
