import { Box, Grid, Stack } from "@mui/material";

import SkeletonAvatar from "@skeletons/SkeletonAvatar";
import SkeletonSkillsCard from "@skeletons/SkeletonSkillsCard";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Typography from "@components/Typography";

import { intl } from "@utils/translate";

const SkeletonProfileSummaryDrawerTab = () => {
  return (
    <Stack>
      <Box display="flex" alignItems="center" gap={1} width="100%" mb={1}>
        <SkeletonAvatar size="large" />
        <Stack width="80%">
          <SkeletonTypography variant="subtitle2" width="short" />
          <SkeletonTypography variant="body1" width="short" />
        </Stack>
      </Box>

      <Stack gap={2} mb={2}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 1, md: 2 }}
          width="100%"
          mb={1}>
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
        </Stack>

        <Typography variant="h4">
          {intl.get("t_profile_summary_experience")}
        </Typography>
        <Grid container gap={1}>
          <Grid item xs={12} md={3}>
            <Stack direction="column" width="100%">
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="column" width="100%">
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="column" width="100%">
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="column" width="100%">
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="h4">
          {intl.get("t_resumes_language_title")}
        </Typography>
        <Grid container gap={1}>
          <Grid item xs={12} md={3}>
            <Stack direction="column" width="100%">
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="column" width="100%">
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="column" width="100%">
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="h4">
          {intl.get("t_resumes_skills_title")}
        </Typography>
        <SkeletonSkillsCard mode="view" />

        <Typography variant="subtitle2">
          {intl.get("t_profile_summary_attached_resumes")}
        </Typography>
        <Stack direction="row" spacing={1} width="100%">
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SkeletonProfileSummaryDrawerTab;
