import {
  addDoc,
  collection,
  doc,
  FieldValue,
  getDoc,
  getDocs,
  increment,
  query,
  Timestamp,
  updateDoc,
  where
} from "firebase/firestore";

import ApplicationID from "@interfaces/database/ApplicationID";
import CompanyID from "@interfaces/database/CompanyID";
import Conversation from "@interfaces/database/Conversation";
import JobID from "@interfaces/database/JobID";
import Message from "@interfaces/database/Message";
import UserID from "@interfaces/database/UserID";

import { FIRESTORE_COLLECTIONS, MESSAGE_TYPE, USER_TYPE } from "@utils/config";
import { db } from "@utils/firebase";
import { replaceNewlinesWithBreak } from "@utils/replaceNewlinesWithBreak";

interface IsConversationAlreadyExistParams {
  applicationId: ApplicationID;
  companyId: CompanyID;
  jobId: JobID;
  candidateId: UserID;
}

const isConversationExist = async ({
  applicationId,
  companyId,
  jobId,
  candidateId
}: IsConversationAlreadyExistParams): Promise<boolean> => {
  if (!applicationId || !companyId || !jobId || !candidateId) {
    return false;
  }
  const conversationCollectionReference = collection(
    db,
    FIRESTORE_COLLECTIONS.CONVERSATIONS
  );
  const conversation = await getDocs(
    query(
      conversationCollectionReference,
      where("application_id", "==", applicationId),
      where("company_id", "==", companyId),
      where("candidate_id", "==", candidateId),
      where("job_id", "==", jobId)
    )
  );
  if (conversation.empty) {
    return false;
  }
  return true;
};

const fetchConversationDoc = async (conversationId: string) => {
  const conversationDocRef = doc(
    db,
    FIRESTORE_COLLECTIONS.CONVERSATIONS,
    conversationId
  );
  const conversationDoc = await getDoc(conversationDocRef);

  if (conversationDoc.exists()) {
    const conversationData = conversationDoc.data() as Conversation;
    return conversationData;
  }
  return "";
};

const sendNewMessage = async (
  conversationId: string,
  message: Message,
  userId: string,
  userType: typeof USER_TYPE[keyof typeof USER_TYPE]
) => {
  const conversationCollectionRef = doc(
    db,
    `${FIRESTORE_COLLECTIONS.CONVERSATIONS}/${conversationId}`
  );
  const conversationSubCollectionRef = collection(
    db,
    `${FIRESTORE_COLLECTIONS.CONVERSATIONS}/${conversationId}/${FIRESTORE_COLLECTIONS.MESSAGES}`
  );
  const messageData: Message = {
    body: replaceNewlinesWithBreak(message.body),
    sender_id: message.sender_id,
    type: message.type,
    created_at: message.created_at,
    updated_at: message.updated_at
  };
  await addDoc(conversationSubCollectionRef, messageData);
  const conversationDoc = await getDoc(conversationCollectionRef);
  const conversationData = conversationDoc.data() as Conversation;
  const companyUnreadMsgCount = conversationData.company_unread_msg_count;
  const candidateUnreadMsgCount = conversationData.candidate_unread_msg_count;

  const updatedMessageCount: Record<string, FieldValue | number> = {};
  if (userType === USER_TYPE.INDIVIDUAL) {
    // if candidate send message than increment message count to all company user
    Object.entries(companyUnreadMsgCount).forEach(([userId]) => {
      const key = "company_unread_msg_count." + userId;
      updatedMessageCount[key] = increment(1);
    });
  } else if (
    userType === USER_TYPE.COMPANY ||
    userType === USER_TYPE.COMPANY_MANAGER
  ) {
    // if company invited user send message then create by default company_unread_msg_count to 0
    if (!companyUnreadMsgCount?.[userId]?.toString()) {
      const key = "company_unread_msg_count." + userId;
      updatedMessageCount[key] = 0;
    }
    // if company user send message than increment message count to all candidates
    Object.entries(candidateUnreadMsgCount).forEach(([userId]) => {
      const key = "candidate_unread_msg_count." + userId;
      updatedMessageCount[key] = increment(1);
    });
  }
  await updateDoc(conversationCollectionRef, {
    "metadata.last_message_body": message.body,
    "metadata.last_message_by_user_id": userId,
    "metadata.last_message_type":
      userType === USER_TYPE.INDIVIDUAL
        ? MESSAGE_TYPE.FROM_CANDIDATE
        : MESSAGE_TYPE.FROM_COMPANY,
    "metadata.last_message_time": Timestamp.now(),
    ...updatedMessageCount
  });
};

const resetUnreadMessageCount = async (
  conversationId: string,
  userType: typeof USER_TYPE[keyof typeof USER_TYPE],
  userId: string
) => {
  const conversationCollectionRef = doc(
    db,
    `${FIRESTORE_COLLECTIONS.CONVERSATIONS}/${conversationId}`
  );
  const conversationDoc = await getDoc(conversationCollectionRef);
  const conversationData = conversationDoc.data() as Conversation;

  const candidateUnreadMsgCount =
    conversationData?.candidate_unread_msg_count?.[userId] ?? 0;
  const employerUnreadMsgCount =
    conversationData?.company_unread_msg_count?.[userId] ?? 0;

  let updatedMessageCount = {};

  if (userType === USER_TYPE.INDIVIDUAL && candidateUnreadMsgCount !== 0) {
    updatedMessageCount = {
      ...updatedMessageCount,
      ["candidate_unread_msg_count." + userId]: 0
    };
  } else if (
    (userType === USER_TYPE.COMPANY ||
      userType === USER_TYPE.COMPANY_MANAGER) &&
    employerUnreadMsgCount !== 0
  ) {
    updatedMessageCount = {
      ...updatedMessageCount,
      ["company_unread_msg_count." + userId]: 0
    };
  }
  if (Object.keys(updatedMessageCount).length > 0) {
    await updateDoc(conversationCollectionRef, updatedMessageCount);
  }
};

export {
  isConversationExist,
  sendNewMessage,
  fetchConversationDoc,
  resetUnreadMessageCount
};
