import { Control, Controller } from "react-hook-form";

import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio as MuiRadio,
  RadioGroup
} from "@mui/material";

export interface FormInputProps {
  "name": string;
  "control": Control<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  "label"?: string;
  "disabled"?: boolean;
  "required"?: boolean;
  "options": Array<{
    label: string;
    value: string | number;
    disabled?: boolean;
  }>;
  "direction"?: "row" | "col";
  "size"?: "small" | "medium";
  "data-testid"?: string;
}

const Radio = ({
  name,
  control,
  label,
  disabled = false,
  required = false,
  options,
  direction = "col",
  size = "small",
  "data-testid": dataTestId
}: FormInputProps) => {
  const generateRadioOptions = () => {
    return options.map((singleOption, index) => {
      const optionName = String(singleOption.label)
        .toLowerCase()
        .replace(/ /g, "_");
      const testId = `${optionName}_option`;
      return (
        <FormControlLabel
          data-testid={testId}
          key={index}
          value={singleOption.value}
          label={singleOption.label}
          control={
            <MuiRadio
              disabled={disabled || singleOption.disabled}
              size={size}
            />
          }
        />
      );
    });
  };

  return (
    <>
      {label ? (
        <FormLabel component="legend" required={required}>
          {label}
        </FormLabel>
      ) : (
        false
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <RadioGroup
                data-testid={dataTestId}
                value={value}
                onChange={onChange}
                row={direction === "col" ? false : true}>
                {generateRadioOptions()}
              </RadioGroup>
              {error ? (
                <FormHelperText error={!!error}>
                  {error?.message}
                </FormHelperText>
              ) : (
                false
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default Radio;
