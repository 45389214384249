import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Well from "@components/Well";

const SkeletonUploadResumeCard = () => {
  return (
    <Well>
      <SkeletonTypography variant="h4" width="medium" mb={2.5} />
      <SkeletonTypography variant="body1" width="medium" mb={1} />
      <SkeletonTypography variant="body2" width="medium" mb={4} />
      <SkeletonButton />
    </Well>
  );
};

export default SkeletonUploadResumeCard;
