import { useState } from "react";

import { InfoRounded as InfoRoundedIcon } from "@mui/icons-material";
import { Box, Divider, Stack } from "@mui/material";
import dayjs from "dayjs";
import { deleteObject, ref } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDownloadURL, useUploadFile } from "react-firebase-hooks/storage";

import SkeletonResumeDownloadCard from "@skeletons/SkeletonResumeDownloadCard";
import SkeletonRirekishoTable from "@skeletons/SkeletonRirekishoTable";
import SkeletonUploadResumeCard from "@skeletons/SkeletonUploadResumeCard";

import Paper from "@components/Paper";
import ResumeDownloadCard from "@components/ResumeDownloadCard";
import ResumeTab from "@components/ResumeTab";
import RirekishoTable from "@components/RirekishoTable";
import Typography from "@components/Typography";
import UploadResumeCard from "@components/UploadResumeCard";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import {
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_RIREKISHO,
  FILES_LOCATION_INDIVIDUAL_JA_RIREKISHO,
  LOCALE
} from "@utils/config";
import { auth, storage } from "@utils/firebase";
import getFileMetaInformation from "@utils/getFileMetaInformation";
import { resolveMultiLingual } from "@utils/multiLingual";
import { colorPalette } from "@utils/theme";
import Timestamp from "@utils/Timestamp";
import translate, { intl, intlJa } from "@utils/translate";

const JapaneseRirekisho = () => {
  const { loading, value, setValue } = useUserProfile();
  const [user] = useAuthState(auth);
  const [uploadFile] = useUploadFile();
  const toast = useToast();
  const [downloadResumeUrl] = useDownloadURL(
    ref(storage, `${FILES_LOCATION_INDIVIDUAL_JA_RIREKISHO}/${user?.uid}.pdf`)
  );
  const [downloadAutoGeneratedCvUrl] = useDownloadURL(
    ref(
      storage,
      `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_RIREKISHO}/${user?.uid}.pdf`
    )
  );
  const [isResumeUploading, setIsResumeUploading] = useState<boolean>(false);

  const progressSteps = value?.progress_steps;
  const rirekishoFileInfo = value?.ja_rirekisho?.file_information;
  const cvData = value?.cv;
  const userData = value?.summary?.basic_information;

  const updateDeleteSuccess = () => {
    toast.kampai(intl.get("t_resumes_toast_success_delete"), "success");
  };

  const updateUploadSuccess = () => {
    toast.kampai(intl.get("t_resumes_toast_success_upload"), "success");
  };

  const updateFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleResumeFileUpload = async (resumeFile: File) => {
    setIsResumeUploading(true);
    try {
      const { fileSize, fileName, fileNameWithoutExt, fileExt, fileType } =
        getFileMetaInformation(resumeFile);

      if (user?.uid) {
        const storageRef = ref(
          storage,
          `${FILES_LOCATION_INDIVIDUAL_JA_RIREKISHO}/${user?.uid}.${fileExt}`
        );

        await uploadFile(storageRef, resumeFile, {
          contentType: fileType,
          contentDisposition: `attachment; filename=${fileName}`
        });

        if (setValue && value) {
          if (!value?.ja_rirekisho) {
            value.ja_rirekisho = {};
          }

          await setValue(
            {
              ...value,
              ja_rirekisho: {
                ...value?.ja_rirekisho,
                file_information: {
                  name: fileNameWithoutExt,
                  extension: fileExt,
                  size: fileSize,
                  uploaded_at: Timestamp.now()
                }
              },
              updated_at: Timestamp.now()
            },
            updateUploadSuccess,
            updateFail
          );
        }
      }
    } catch (e) {
      updateFail();
    }
    setIsResumeUploading(false);
  };

  const handleResumeDelete = async () => {
    try {
      if (user?.uid) {
        const storageRef = ref(
          storage,
          `${FILES_LOCATION_INDIVIDUAL_JA_RIREKISHO}/${user?.uid}.pdf`
        );

        // TODO: Might need to consider soft delete in future
        await deleteObject(storageRef);

        if (setValue && value && value?.ja_rirekisho?.file_information) {
          delete value?.ja_rirekisho?.file_information;

          await setValue(
            {
              ...value,
              ja_rirekisho: {
                ...value?.ja_rirekisho
              },
              updated_at: Timestamp.now()
            },
            updateDeleteSuccess,
            updateFail
          );
        }
      }
    } catch (e) {
      updateFail();
    }
  };

  const educations = cvData?.education?.flatMap((singleEducation) => {
    const baseEducationName = `${
      resolveMultiLingual(singleEducation.institute_name, LOCALE.JA) ?? ""
    }, ${
      resolveMultiLingual(singleEducation.field_of_study, LOCALE.JA) ?? ""
    }`.trim();
    const education = [
      {
        year: `${dayjs(singleEducation.timeline?.start?.toDate())
          .locale(
            translate.getLocaleShort(intlJa.getInitOptions().currentLocale)
          )
          .format("YYYY")}`,
        month: `${dayjs(singleEducation.timeline?.start?.toDate())
          .locale(
            translate.getLocaleShort(intlJa.getInitOptions().currentLocale)
          )
          .format("MM")}`,
        education: `${baseEducationName} ${intlJa.get(
          "t_resumes_rirekisho_education_admission"
        )}`
      },
      {
        year: `${dayjs(singleEducation.timeline?.end?.toDate())
          .locale(
            translate.getLocaleShort(intlJa.getInitOptions().currentLocale)
          )
          .format("YYYY")}`,
        month: `${dayjs(singleEducation.timeline?.end?.toDate())
          .locale(
            translate.getLocaleShort(intlJa.getInitOptions().currentLocale)
          )
          .format("MM")}`,
        education: `${baseEducationName} ${
          singleEducation.timeline?.end?.toDate() > new Date()
            ? intlJa.get("t_resumes_rirekisho_education_expected_graduation")
            : intlJa.get("t_resumes_rirekisho_education_graduation")
        }`
      }
    ];

    return education;
  });

  const jobExperiences = cvData?.job_experience?.flatMap((singleJob) => {
    const baseJobExperienceName = `${
      resolveMultiLingual(singleJob.company_name, LOCALE.JA) ?? ""
    }, ${resolveMultiLingual(singleJob.job_title, LOCALE.JA) ?? ""}`.trim();
    const jobExperience = [
      {
        year: `${dayjs(singleJob.timeline?.start?.toDate())
          .locale(
            translate.getLocaleShort(intlJa.getInitOptions().currentLocale)
          )
          .format("YYYY")}`,
        month: `${dayjs(singleJob.timeline?.start?.toDate())
          .locale(
            translate.getLocaleShort(intlJa.getInitOptions().currentLocale)
          )
          .format("MM")}`,
        workHistory: `${baseJobExperienceName} ${intlJa.get(
          "t_resumes_rirekisho_work_entry"
        )}`
      }
    ];

    if (singleJob.timeline?.end) {
      jobExperience.push({
        year: `${dayjs(singleJob.timeline.end?.toDate())
          .locale(
            translate.getLocaleShort(intlJa.getInitOptions().currentLocale)
          )
          .format("YYYY")}`,
        month: `${dayjs(singleJob.timeline.end?.toDate())
          .locale(
            translate.getLocaleShort(intlJa.getInitOptions().currentLocale)
          )
          .format("MM")}`,
        workHistory: `${baseJobExperienceName} ${intlJa.get(
          "t_resumes_rirekisho_work_resign"
        )}`
      });
    }

    return jobExperience;
  });

  const rirekishoTableProps = {
    firstName: resolveMultiLingual(userData?.first_name, LOCALE.JA),
    lastName: resolveMultiLingual(userData?.last_name, LOCALE.JA),
    profilePicUrl: user?.photoURL ?? "",
    phoneNumber: userData?.phone_number,
    email: userData?.email,
    location: {
      country: userData?.current_location?.country ?? "",
      city: userData?.current_location?.city ?? ""
    },
    educations,
    jobExperiences
  };

  return (
    <>
      <Paper sx={{ mb: 3 }}>
        <ResumeTab />
        <br />
        <br />
        <Typography variant="h3" mb={3}>
          {intl.get("t_rirekisho_upload_title")}
        </Typography>

        {/* TODO:: Handle File upload  */}
        {loading ? (
          <SkeletonUploadResumeCard />
        ) : (
          <UploadResumeCard
            resumeDetails={rirekishoFileInfo}
            handleFileUpload={handleResumeFileUpload}
            handleDelete={handleResumeDelete}
            downloadResumeUrl={downloadResumeUrl}
            isLoading={isResumeUploading}
            downloadBtnText={intl.get("t_resumes_download_rirekisho")}
          />
        )}
      </Paper>

      <Box my={3.5} color={colorPalette.grey.base}>
        <Divider>{intl.get("t_general_or")}</Divider>
      </Box>

      <Paper>
        <Stack direction="row" justifyContent="flex-end" mb={3}>
          <InfoRoundedIcon color="info" />
          <Typography variant="subtitle4" ml={1}>
            {intl.get("t_rirekisho_info")}
          </Typography>
        </Stack>

        {loading ? (
          <SkeletonResumeDownloadCard />
        ) : (
          <>
            {progressSteps?.ja_cv?.current &&
            progressSteps?.ja_cv?.total &&
            progressSteps.ja_cv.current > progressSteps.ja_cv.total &&
            downloadAutoGeneratedCvUrl ? (
              <Box mb={3}>
                <Typography variant="h3" mb={3}>
                  {intl.get("t_general_japanese_rirekisho")}
                </Typography>
                <ResumeDownloadCard
                  title={intl.get("t_resumes_download_rirekisho_card_title")}
                  downloadBtnText={intl.get("t_resumes_download_rirekisho")}
                  downloadCvUrl={downloadAutoGeneratedCvUrl}
                />
              </Box>
            ) : (
              false
            )}
          </>
        )}

        {loading ? (
          <SkeletonRirekishoTable />
        ) : (
          <RirekishoTable {...rirekishoTableProps} />
        )}
      </Paper>
    </>
  );
};

export default JapaneseRirekisho;
