import { ReactNode } from "react";

import { Control, Controller, UseFormSetValue } from "react-hook-form";

import {
  ArrowDropDownRounded as ArrowDropDownRoundedIcon,
  CloseRounded as CloseRoundedIcon,
  Done as DoneIcon
} from "@mui/icons-material";
import {
  Box,
  Chip,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from "@mui/material";

import Cursor from "@components/Cursor";
import Typography from "@components/Typography";

import KeyLabel from "@interfaces/components/KeyLabel";

import { colorPalette } from "@utils/theme";

interface NonTypeableSelectProps {
  "options": Array<KeyLabel>;
  "placeholder"?: string;
  "name": string;
  "multiSelect"?: boolean;
  "startAdornment"?: string | ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  "control": Control<any>;
  "disabled"?: boolean;
  "label"?: string;
  "required"?: boolean;
  "readOnly"?: boolean;
  "helperText"?: string;
  "clearable"?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  "setValue": UseFormSetValue<any>;
  "data-testid"?: string;
}

const NonTypeableSelect = ({
  options,
  placeholder,
  startAdornment = "",
  required = false,
  label,
  multiSelect = false,
  name,
  control,
  disabled = false,
  helperText = "",
  readOnly = false,
  setValue,
  clearable = true,
  "data-testid": dataTestId
}: NonTypeableSelectProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value = multiSelect ? [] : "", ref },
        fieldState: { error }
      }) => (
        <>
          {label ? (
            <InputLabel htmlFor={name} required={required} disabled={disabled}>
              {label}
            </InputLabel>
          ) : (
            false
          )}
          <Box mb={2}>
            <Select
              data-testid={dataTestId}
              readOnly={readOnly}
              // Reference:- https://github.com/mui/material-ui/issues/26473#issuecomment-1365598285
              IconComponent={ArrowDropDownRoundedIcon}
              onChange={onChange}
              disabled={disabled}
              error={!!error}
              displayEmpty
              value={value}
              sx={{
                mt: "0.75rem",
                pr: "2rem",
                pl: "0.313rem"
              }}
              SelectDisplayProps={{ style: { height: 0 } }}
              inputRef={ref}
              endAdornment={
                clearable &&
                !disabled &&
                ((multiSelect && value.length > 0) ||
                  (!multiSelect && value)) ? (
                  <Cursor>
                    <IconButton
                      size="small"
                      onClick={() => onChange(multiSelect ? [] : "")}>
                      <CloseRoundedIcon fontSize="small" />
                    </IconButton>
                  </Cursor>
                ) : (
                  false
                )
              }
              renderValue={(selected) => (
                <Stack direction="row" alignItems="center" gap={1}>
                  {startAdornment ? (
                    <Typography
                      variant="subtitle4"
                      alignSelf="stretch"
                      height={0}
                      color="text.secondary">
                      {startAdornment}
                    </Typography>
                  ) : (
                    false
                  )}
                  {selected.length === 0 ? (
                    <Typography
                      variant="body1"
                      color={colorPalette.grey.placeholder}>
                      {placeholder}
                    </Typography>
                  ) : multiSelect ? (
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      gap={1}
                      mt="calc(-0.25rem - 1px)"
                      mb={-0.5}>
                      {selected.map((singleKey: string) => {
                        const singleOption = options.find(
                          (singleOption) => singleOption.key === singleKey
                        );
                        return (
                          <Chip
                            key={singleOption?.key}
                            label={singleOption?.label}
                            disabled={disabled}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            onDelete={() => {
                              const newOptions = selected.filter(
                                (e: string) => e !== singleOption?.key
                              );
                              setValue(name, newOptions ?? []);
                            }}
                          />
                        );
                      })}
                    </Stack>
                  ) : (
                    <Typography variant="body1">
                      {
                        options.find(
                          (singleOption) => singleOption.key === selected
                        )?.label
                      }
                    </Typography>
                  )}
                </Stack>
              )}
              multiple={multiSelect}>
              {options.map(({ key, label }) => {
                const testLabel = String(label)
                  .toLocaleLowerCase()
                  .replace(/ /g, "_");

                const testId = `${testLabel}_option`;
                return (
                  <MenuItem key={key} value={key}>
                    <Stack
                      data-testid={testId}
                      direction="row"
                      justifyContent="space-between"
                      width="100%">
                      {label}
                      {value?.includes(key) && multiSelect ? (
                        <DoneIcon fontSize="small" color="primary" />
                      ) : (
                        ""
                      )}
                    </Stack>
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText error={!!error} sx={{ px: "0.875rem" }}>
              {error ? error.message : helperText}
            </FormHelperText>
          </Box>
        </>
      )}
    />
  );
};

export default NonTypeableSelect;
