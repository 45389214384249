// Ref: https://docs.react-joyride.com/custom-components

import { TooltipRenderProps } from "react-joyride";

import { Close as CloseIcon } from "@mui/icons-material";
import { Box, IconButton, Stack, styled } from "@mui/material";

import Button from "@components/Button";
import { CustomStep } from "@components/FTUIContainer";
import Typography from "@components/Typography";

import theme from "@utils/theme";
import { intl } from "@utils/translate";

const StyledIconButton = styled(IconButton)({
  marginLeft: "auto"
});

const StyledBox = styled(Box)(({ theme }) => {
  return {
    [theme.breakpoints.down("md")]: {
      maxWidth: "80vw"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "60vw"
    },
    backgroundColor: theme.palette.common.white,
    padding: "1rem",
    borderRadius: "4px",
    animation: "fade-in 0.5s ease-in-out"
  };
});

const TourToolTip = ({
  backProps,
  continuous,
  index,
  primaryProps,
  step,
  tooltipProps,
  closeProps,
  isLastStep,
  skipProps
}: TooltipRenderProps) => {
  const customStep = step as CustomStep;

  const LastButton = () => {
    if (isLastStep && customStep?.nextRoute) {
      return <Button {...primaryProps}>{intl.get("t_general_next")}</Button>;
    } else if (isLastStep) {
      return <Button {...closeProps}>{intl.get("t_general_close")}</Button>;
    } else if (continuous) {
      return <Button {...primaryProps}>{intl.get("t_general_next")}</Button>;
    } else {
      return <Button {...closeProps}>{intl.get("t_general_close")}</Button>;
    }
  };

  return (
    <StyledBox {...tooltipProps}>
      {step.title ? (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={4}>
          <Typography variant="h4">{step.title}</Typography>
          <StyledIconButton {...closeProps} size="large">
            <CloseIcon />
          </StyledIconButton>
        </Stack>
      ) : (
        false
      )}

      {step.content ? (
        <Typography variant="body1" color={theme.palette.text.secondary}>
          {step.content}
        </Typography>
      ) : (
        false
      )}

      <Stack direction="row" justifyContent="space-between" mt={3} gap={3}>
        <Button variant="text" {...skipProps}>
          {intl.get("t_ftui_mark_as_complete_button_label")}
        </Button>

        <Stack direction="row" gap={2}>
          {index > 0 ? (
            <Button {...backProps} variant="outlined">
              {intl.get("t_general_back")}
            </Button>
          ) : (
            false
          )}
          <LastButton />
        </Stack>
      </Stack>
    </StyledBox>
  );
};

export default TourToolTip;
