import { MouseEvent, useState } from "react";

import { Document, Page, pdfjs } from "react-pdf";

import {
  NavigateBeforeRounded as NavigateBeforeRoundedIcon,
  NavigateNextRounded as NavigateNextRoundedIcon
} from "@mui/icons-material";
import { Box, styled } from "@mui/material";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "@assets/styles/pdf_viewer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

import Typography from "@components/Typography";

import { colorPalette } from "@utils/theme";

interface PdfViewerThumbnailProps {
  pdfURL: string;
}

const StyledBox = styled(Box)({
  height: 300,
  width: 220,
  border: `1px solid ${colorPalette.grey.base}`,
  borderRadius: "0.25rem",
  cursor: "pointer"
});

const StyledIconBox = styled(Box)({
  "height": 18,
  "width": 18,
  "& .MuiSvgIcon-root": {
    height: "100%",
    width: "100%"
  }
});

const PdfViewerThumbnail = ({ pdfURL = "" }: PdfViewerThumbnailProps) => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [animate, setAnimate] = useState<boolean>(false);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setTotalPages(numPages);
    setCurrentPage(1);
  };

  const triggerAnimation = () => {
    setAnimate(true);

    // Optionally reset the animation after it completes
    setTimeout(() => {
      setAnimate(false);
    }, 250); // Duration of your animation
  };

  const removeFocus = (e: MouseEvent<HTMLButtonElement>) => {
    if (e.target) {
      const target = e.target as HTMLButtonElement;
      target.blur();
    }
  };

  const goBack = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    removeFocus(e);
    triggerAnimation();
    setCurrentPage((currentPage) => {
      if (currentPage > 1) {
        return currentPage - 1;
      } else {
        return 1;
      }
    });
  };

  const goForward = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    removeFocus(e);
    triggerAnimation();
    setCurrentPage((currentPage) => {
      if (currentPage < totalPages) {
        return currentPage + 1;
      } else {
        return totalPages;
      }
    });
  };

  return (
    <StyledBox>
      <div className="pdf-thumbnail-container">
        <Document file={pdfURL} onLoadSuccess={onDocumentLoadSuccess}>
          <div className={animate ? "react-pdf__Document--loading" : ""}>
            <div
              style={{
                opacity: animate ? 0 : 1
              }}>
              <Page pageNumber={currentPage} scale={0.4} />
            </div>
          </div>
          <div className="page-controls">
            <button disabled={currentPage <= 1} type="button" onClick={goBack}>
              <StyledIconBox>
                <NavigateBeforeRoundedIcon />
              </StyledIconBox>
            </button>
            <Typography>
              {currentPage}/{totalPages}
            </Typography>
            <button
              disabled={currentPage >= totalPages}
              type="button"
              onClick={goForward}>
              <StyledIconBox>
                <NavigateNextRoundedIcon />
              </StyledIconBox>
            </button>
          </div>
        </Document>
      </div>
    </StyledBox>
  );
};

export default PdfViewerThumbnail;
