import { Grid, Stack } from "@mui/material";

import Avatar from "@components/Avatar";
import Table, { ColumnProps } from "@components/Table";

import Email from "@interfaces/database/Email";
import Location from "@interfaces/database/Location";

import { LOCALE_SHORT } from "@utils/config";
import { getFullLocation } from "@utils/location";
import { intlJa } from "@utils/translate";

interface RirekishoTableProps {
  firstName?: string;
  lastName?: string;
  profilePicUrl?: string;
  phoneNumber?: string;
  email?: Email;
  location?: Location;
  educations?: Array<{
    year: string;
    month: string;
    education: string;
  }>;
  jobExperiences?: Array<{
    year: string;
    month: string;
    workHistory: string;
  }>;
}

const RirekishoTable = ({
  firstName = "",
  lastName = "",
  profilePicUrl = "",
  phoneNumber = "",
  email = "",
  location: userLocation,
  educations = [
    {
      year: "-",
      month: "-",
      education: "-"
    }
  ],
  jobExperiences = [
    {
      year: "-",
      month: "-",
      workHistory: "-"
    }
  ]
}: RirekishoTableProps) => {
  const nameTableColumns: Array<ColumnProps> = [
    {
      accessorKey: "fullName",
      header: intlJa.get("t_general_full_name")
    }
  ];

  const locationTableColumns: Array<ColumnProps> = [
    {
      accessorKey: "location",
      header: intlJa.get("t_general_address")
    }
  ];

  const phoneNumberTableColumns: Array<ColumnProps> = [
    {
      accessorKey: "phoneNumber",
      header: intlJa.get("t_general_phone_number")
    }
  ];

  const emailTableColumns: Array<ColumnProps> = [
    {
      accessorKey: "email",
      header: intlJa.get("t_general_email_address")
    }
  ];

  const educationTableColumns: Array<ColumnProps> = [
    {
      accessorKey: "year",
      header: intlJa.get("t_general_year"),
      cellWidth: "10%"
    },
    {
      accessorKey: "month",
      header: intlJa.get("t_general_month"),
      cellWidth: "10%"
    },
    {
      accessorKey: "education",
      header: intlJa.get("t_resumes_rirekisho_education_history")
    }
  ];

  const jobExperienceTableColumns: Array<ColumnProps> = [
    {
      accessorKey: "year",
      header: intlJa.get("t_general_year"),
      cellWidth: "10%"
    },
    {
      accessorKey: "month",
      header: intlJa.get("t_general_month"),
      cellWidth: "10%"
    },
    {
      accessorKey: "workHistory",
      header: intlJa.get("t_resumes_rirekisho_work_history")
    }
  ];

  const fullName = `${lastName} ${firstName}`;
  const location = getFullLocation(
    userLocation?.city,
    userLocation?.country,
    true,
    LOCALE_SHORT.JA
  );

  return (
    <Grid container spacing={0.1}>
      <Grid item xs={8} maxWidth="calc(66.666667% - 0.4px) !important">
        <Table
          columns={nameTableColumns}
          data={[{ fullName }]}
          cellHeight="3.125rem"
          showTableBottomBorder={false}
        />
      </Grid>
      <Grid item xs={4}>
        <Stack
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%">
          <Avatar fullName={fullName} imgSrc={profilePicUrl} />
        </Stack>
      </Grid>

      <Grid item xs={8} textTransform="capitalize">
        <Table
          columns={locationTableColumns}
          data={[{ location }]}
          cellHeight="5.625rem"
          showTableBottomBorder
        />
      </Grid>

      <Grid item container xs={4}>
        <Grid item xs={12}>
          <Table
            columns={phoneNumberTableColumns}
            data={[{ phoneNumber }]}
            cellHeight="1.75rem"
            showTableBottomBorder={false}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={emailTableColumns}
            data={[{ email }]}
            cellHeight="1.75rem"
          />
        </Grid>
      </Grid>

      <Grid item xs={12} my={1}>
        <Table
          columns={educationTableColumns}
          data={
            educations.length !== 0
              ? educations
              : (educations = [
                  {
                    year: "-",
                    month: "-",
                    education: "-"
                  }
                ])
          }
          showRowBorder
        />
      </Grid>

      <Grid item xs={12}>
        <Table
          columns={jobExperienceTableColumns}
          data={
            jobExperiences.length !== 0
              ? jobExperiences
              : (jobExperiences = [
                  {
                    year: "-",
                    month: "-",
                    workHistory: "-"
                  }
                ])
          }
          showRowBorder
        />
      </Grid>
    </Grid>
  );
};

export default RirekishoTable;
