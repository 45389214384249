import { Stack } from "@mui/material";

import Status from "@components/Status";

import {
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  JOB_STATUS_TAGS
} from "@utils/config";

interface JobStatusProps {
  status: Array<
    | typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]
    | typeof JOB_APPLICATION_INVITATION_ACTION_TYPE[keyof typeof JOB_APPLICATION_INVITATION_ACTION_TYPE]
  >;
  labelText?: string;
  labelTextColor?: string;
  bgColor?: string;
}

const JobStatus = ({
  status,
  labelText = "",
  labelTextColor,
  bgColor
}: JobStatusProps) => {
  return (
    <Stack gap={1} direction="row" flexWrap="wrap">
      {status?.map((singleStatus) => (
        <Status
          label={singleStatus}
          key={singleStatus}
          labelText={labelText}
          labelTextColor={labelTextColor}
          bgColor={bgColor}
        />
      ))}
    </Stack>
  );
};

export default JobStatus;
