import { Box, Stack } from "@mui/material";

import SkeletonAvatar from "@skeletons/SkeletonAvatar";
import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonJobCategory from "@skeletons/SkeletonJobCategory";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import { colorPalette } from "@utils/theme";

const SkeletonCandidateProfileCard = () => {
  return (
    <Box
      p={2}
      borderRadius={1}
      bgcolor="common.white"
      sx={{
        "transition": "all 0.125s linear",
        "&:hover": {
          boxShadow: `0px 3px 8px ${colorPalette.black.hover["30"]}`
        }
      }}>
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        alignItems="flex-start"
        justifyContent="space-between"
        width="100%"
        mb={1}>
        {/* User Details */}
        <Stack direction="row" alignItems="center" gap={1} width="100%" mb={1}>
          <SkeletonAvatar />
          <Stack width="100%">
            <SkeletonTypography variant="subtitle2" />
            <SkeletonTypography variant="body1" />
          </Stack>
        </Stack>
        {/* Action button */}
        <Box ml="auto">
          <SkeletonButton />
        </Box>
      </Stack>

      <Stack spacing={1} width="100%">
        {/* Basic Information */}
        <Stack gap={1} flexWrap="wrap" direction="row">
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
        </Stack>

        {/* Experiences */}
        <SkeletonTypography variant="subtitle2" width="short" mb={1} />
        <Stack direction="row" spacing={1} width="100%" mb={1}>
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
        </Stack>

        {/* Languages */}
        <SkeletonTypography variant="subtitle2" width="short" mb={1} />
        <Stack direction="row" spacing={1} width="100%" mb={1}>
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
        </Stack>

        {/* Skills */}
        <SkeletonTypography variant="subtitle2" width="short" mb={1} />
        <Box display="flex" flexWrap="wrap" gap={1} mb={1}>
          <SkeletonJobCategory multiple />
        </Box>

        {/* Attached Resumes */}
        <SkeletonTypography variant="subtitle2" width="short" mb={1} />
        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={1}
          flexWrap="wrap"
          width="100%"
          alignItems={{ xs: "start", sm: "center" }}>
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
          <SkeletonTypography variant="body1" width="short" />
          {/* lastLoggedIn text */}
          <SkeletonTypography variant="body1" width="short" ml="auto" />
        </Stack>
      </Stack>
    </Box>
  );
};

export default SkeletonCandidateProfileCard;
