import { useState } from "react";

import { Control, Controller } from "react-hook-form";

import { CalendarMonth as CalendarMonthIcon } from "@mui/icons-material";
import { Box, InputLabel, styled, SxProps, TextField } from "@mui/material";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { colorPalette } from "@utils/theme";

interface DatePickerProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  label?: string;
  disabled?: boolean;
  disableFuture?: boolean;
  required?: boolean;
  placeholder?: string;
  helperText?: string;
  dateFormat?: string;
}

const PopperProps: SxProps = {
  "& .MuiCalendarPicker-root": {
    overflowY: "hidden"
  },
  "& .MuiPickersCalendarHeader-root": {
    borderBottom: `1px solid ${colorPalette.grey.base}`
  },
  "& .PrivatePickersYear-yearButton": {
    padding: "0.5rem",
    borderRadius: "0.125rem",
    fontSize: "0.875rem",
    lineHeight: "1.25rem"
  },
  "& .PrivatePickersMonth-root": {
    padding: "0.5rem",
    borderRadius: "0.125rem",
    fontSize: "0.875rem",
    lineHeight: "1.25rem"
  }
};

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    flexDirection: "row-reverse"
  }
});

const DatePicker = ({
  name,
  control,
  label,
  disabled = false,
  required = false,
  disableFuture = true,
  placeholder = "MM/YYYY",
  helperText,
  dateFormat = "MM/YYYY"
}: DatePickerProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Box mb={2}>
            {label ? (
              <InputLabel
                htmlFor={name}
                required={required}
                disabled={disabled}
                sx={{ mb: 1.5 }}>
                {label}
              </InputLabel>
            ) : (
              false
            )}
            <MuiDatePicker
              open={open}
              value={value}
              inputFormat={dateFormat}
              onChange={(e) => {
                onChange(e);
              }}
              onClose={() => setOpen(false)}
              renderInput={(params) => {
                return (
                  <StyledTextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      value: value ? params?.inputProps?.value : "",
                      placeholder: placeholder
                    }}
                    error={!!error}
                    helperText={error ? error.message : helperText}
                    fullWidth
                    onClick={() => setOpen(!disabled)}
                  />
                );
              }}
              views={["year", "month"]}
              openTo="year"
              disableFuture={disableFuture}
              disabled={disabled}
              components={{
                OpenPickerIcon: CalendarMonthIcon
              }}
              PopperProps={{
                sx: PopperProps,
                placement: "bottom-start"
              }}
            />
          </Box>
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
