import { ReactIntlUniversal } from "react-intl-universal";

import { Box, DialogActions, DialogContent, Stack } from "@mui/material";

import ActionButton from "@components/ActionButton";
import Button from "@components/Button";
import Dialog from "@components/Dialog";
import Typography from "@components/Typography";
import Well from "@components/Well";

import {
  DIALOG_ACTION,
  JA_ALPHA3_CODE,
  JA_LANGUAGE_TEST,
  JA_LANGUAGE_TEST_T_LABELS,
  JLPT_SCORE,
  LANGUAGE_PROFICIENCY,
  LANGUAGE_PROFICIENCY_T_LABELS,
  LOCALE
} from "@utils/config";
import { jaTestResultFinder } from "@utils/jaTestResultFinder";
import { getLanguageName } from "@utils/language";
import { intl as intlGlobal } from "@utils/translate";

interface LanguageCardProps {
  name: string;
  proficiency: typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY];
  testName?: typeof JA_LANGUAGE_TEST[keyof typeof JA_LANGUAGE_TEST] | string;
  testResult?: typeof JLPT_SCORE[keyof typeof JLPT_SCORE] | string;
  handleEdit?: () => void;
  handleDelete?: () => void;
  mode?: "edit" | "view";
  intl?: typeof intlGlobal | ReactIntlUniversal;
}

const LanguageCard = ({
  name,
  proficiency,
  testName,
  testResult,
  handleEdit,
  handleDelete,
  mode = "view",
  intl = intlGlobal
}: LanguageCardProps) => {
  let japaneseTestName = "";
  let japaneseTestResult = "";

  const languageName =
    name?.length > 0 &&
    getLanguageName(
      name,
      intl.getInitOptions().currentLocale as typeof LOCALE[keyof typeof LOCALE]
    )
      ? getLanguageName(
          name,
          intl.getInitOptions()
            .currentLocale as typeof LOCALE[keyof typeof LOCALE]
        )
      : name;

  if (name === JA_ALPHA3_CODE && testName) {
    japaneseTestName = intl.get(
      JA_LANGUAGE_TEST_T_LABELS[
        testName as keyof typeof JA_LANGUAGE_TEST_T_LABELS
      ]
    );
    japaneseTestResult = jaTestResultFinder(
      testName,
      testResult,
      intl.getInitOptions().currentLocale as typeof LOCALE[keyof typeof LOCALE]
    );
  }

  const handleClose = (reason: keyof typeof DIALOG_ACTION) => {
    if (reason === DIALOG_ACTION.AGREE && handleDelete) {
      handleDelete();
    }
  };

  return (
    <Well showBorder={mode === "edit" ? true : false}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        minHeight={40}
        width="100%">
        {/* Language, Proficiency */}
        <Box component="span">
          <Typography variant="subtitle4">
            {intl.get("t_language_proficiency_level", {
              language: languageName
            })}
            &nbsp;
          </Typography>
          <Typography variant="body1">
            {intl.get(LANGUAGE_PROFICIENCY_T_LABELS[proficiency])}
          </Typography>
        </Box>

        {/* Actions */}
        {mode === "edit" ? (
          <Stack direction="row">
            <ActionButton icon="edit" color="blue" handleAction={handleEdit} />
            {name !== JA_ALPHA3_CODE ? (
              <Dialog
                title={intlGlobal.get("t_resumes_remove_language")}
                maxWidth="sm"
                initiator={<ActionButton icon="delete" color="grey" />}
                onClose={handleClose}>
                {(handleAgree, handleCancel) => (
                  <>
                    <DialogContent sx={{ py: 1 }}>
                      <Typography color="text.secondary">
                        {intlGlobal.get("t_resumes_dialog_delete_title", {
                          entry: intlGlobal.get("t_resumes_language_title")
                        })}
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button handleClick={handleCancel} variant="outlined">
                        {intlGlobal.get(
                          "t_resumes_dialog_delete_cancel_button"
                        )}
                      </Button>
                      <Button handleClick={handleAgree}>
                        {intlGlobal.get("t_resumes_dialog_delete_agree_button")}
                      </Button>
                    </DialogActions>
                  </>
                )}
              </Dialog>
            ) : (
              false
            )}
          </Stack>
        ) : (
          false
        )}
      </Stack>

      {/* Test Name, Test Result */}
      <Stack spacing={1} width="100%" alignSelf="center">
        {japaneseTestName ? (
          <Typography variant="body1" mt={1}>
            {japaneseTestName}:&nbsp;{japaneseTestResult}
          </Typography>
        ) : testName ? (
          <Typography variant="body1" mt={1}>
            {testName}:&nbsp;{testResult}
          </Typography>
        ) : (
          false
        )}
      </Stack>
    </Well>
  );
};

export default LanguageCard;
