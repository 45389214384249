import { useEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box } from "@mui/material";

import { intl } from "@utils/translate";

interface BarChartProps {
  containerId?: string;
  data: Array<{
    skill: string;
    lessThanOne: number;
    betweenOneandThree: number;
    betweenThreeAndFive: number;
    moreThanFive: number;
  }>;
}

const PieChart = ({ containerId = "bar-chart", data }: BarChartProps) => {
  useEffect(() => {
    // Create root element
    const root = am5.Root.new(containerId);

    if (root._logo) {
      root._logo.dispose(); // Remove AMCharts watermark
    }

    // Set themes
    const myTheme = am5.Theme.new(root);

    myTheme.rule("Grid", ["base"]).setAll({
      strokeOpacity: 0.1
    });

    myTheme.rule("Label").setAll({
      fill: am5.color(0x7b828b),
      fontSize: "0.625rem",
      fontFamily: "Poppins"
    });

    root.setThemes([am5themes_Animated.new(root), myTheme]);

    // Create chart
    // Ref: https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout
      })
    );

    chart
      ?.get("colors")
      ?.set("colors", [
        am5.color(0x1c18fb),
        am5.color(0x1859ff),
        am5.color(0x4397f8),
        am5.color(0x16b4f2)
      ]);

    // Create axes
    // Ref: https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const yRenderer = am5xy.AxisRendererY.new(root, { minGridDistance: 18 });
    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "skill",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    yRenderer.grid.template.setAll({
      strokeOpacity: 0
    });

    yAxis.data.setAll(data);

    const xRenderer = am5xy.AxisRendererX.new(root, {});
    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: xRenderer
      })
    );

    xRenderer.grid.template.setAll({
      location: 1,
      strokeOpacity: 0
    });

    // Add legend
    // Ref: https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        marginTop: 32
      })
    );

    // Add series
    // Ref: https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    const makeSeries = (name: string, fieldName: string) => {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          baseAxis: yAxis,
          valueXField: fieldName,
          categoryYField: "skill"
        })
      );
      series.columns.template.setAll({});
      series.data.setAll(data);

      const cellSize = 42;
      series.events.on("datavalidated", (event) => {
        const series = event.target;
        const chart = series.chart;
        const xAxis = chart?.xAxes.getIndex(0);

        // Calculate how we need to adjust chart height
        const chartHeight =
          series.data.length * cellSize +
          (xAxis?.height() ?? 0) +
          (chart?.get("paddingTop", 0) ?? 0) +
          (chart?.get("paddingBottom", 0) ?? 0);

        // Set it on the chart's container
        if (chart) {
          chart.root.dom.style.height = chartHeight + "px";
        }
      });

      // Make stuff animate on load
      // Ref: https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      legend.data.push(series);
    };

    //FIXME: Handle Locale change detection
    makeSeries(intl.get("t_barchart_less_than_one_label"), "lessThanOne");
    makeSeries(
      intl.get("t_barchart_between_one_and_three_label"),
      "betweenOneandThree"
    );
    makeSeries(
      intl.get("t_barchart_between_three_and_five_label"),
      "betweenThreeAndFive"
    );
    makeSeries(intl.get("t_barchart_more_than_five_label"), "moreThanFive");

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, []);

  return <Box id={containerId} width="100%" height="100%" overflow="hidden" />;
};

export default PieChart;
