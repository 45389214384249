import { Link } from "react-router-dom";

import { NavigateNext as NavigateNextIcon } from "@mui/icons-material";
import { Box, Link as MuiLink, Stack, styled } from "@mui/material";

import Button from "@components/Button";
import Typography from "@components/Typography";

import { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

interface NoJobsCardProps {
  type: "invited" | "applied" | "saved";
}

const StyledBox = styled(Box)({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 4,
  borderRadius: 4,
  marginTop: 10,
  marginBottom: 12,
  transform: "rotate(4.34deg)",
  boxShadow: `0px 3px 5px -1px ${colorPalette.grey[20]}, 
              0px 6px 10px ${colorPalette.grey[14]}, 
              0px 1px 18px ${colorPalette.grey[12]}`
});

const NoJobsCard = ({ type }: NoJobsCardProps) => {
  let boxTextColor = "";
  let boxBgColor = "";
  let title = "";
  let subtitle = "";
  switch (type) {
    case "invited":
      boxBgColor = "secondary.light";
      boxTextColor = "secondary.main";
      title = "t_no_jobs_invited_title";
      subtitle = "t_no_jobs_invited_subtitle";
      break;
    case "applied":
      boxBgColor = colorPalette.lighterBlue[20];
      boxTextColor = colorPalette.lighterBlue.base;
      title = "t_no_jobs_applied_title";
      subtitle = "t_no_jobs_applied_subtitle";
      break;
    case "saved":
      boxBgColor = colorPalette.blue[20];
      boxTextColor = "primary.main";
      title = "t_no_jobs_saved_title";
      subtitle = "t_no_jobs_saved_subtitle";
      break;
  }
  return (
    <>
      {/* FIXME: will recheck when h2 & subtitle3 typography is updated */}
      <Stack direction="row" justifyContent="center">
        <Stack>
          <Box alignSelf="flex-end">
            <StyledBox bgcolor={boxBgColor}>
              <Typography variant="subtitle5" color={boxTextColor}>
                {intl.get("t_no_jobs_label")}
              </Typography>
            </StyledBox>
          </Box>
          <Typography variant="h2" align="center" mb={2.5} mx={5}>
            {intl.get(title)}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="center">
        <Stack alignItems="center">
          <Typography
            variant="subtitle3"
            color="text.secondary"
            align="center"
            mb={3}>
            {intl.get(subtitle)}
          </Typography>
          <MuiLink
            component={Link}
            to={`/${translate.getCurrentLocale()}/search`}
            underline="none">
            <Button size="large" endAdornment={<NavigateNextIcon />}>
              {intl.get("t_no_jobs_find_jobs_button")}
            </Button>
          </MuiLink>
        </Stack>
      </Stack>
    </>
  );
};

export default NoJobsCard;
