import SkeletonAvatar from "@skeletons/SkeletonAvatar";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Paper from "@components/Paper";
import { PaperSxProps } from "@components/ProfileInformationCard";

const SkeletonProfileInformationCard = () => {
  return (
    <Paper sx={PaperSxProps}>
      <SkeletonAvatar size="large" />
      <SkeletonTypography variant="h5" width="short" />
      <SkeletonTypography variant="subtitle2" width="short" />
    </Paper>
  );
};

export default SkeletonProfileInformationCard;
