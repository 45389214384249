import { addDoc, collection, doc, getDoc } from "firebase/firestore";

import CompanyID from "@interfaces/database/CompanyID";
import JobApplicationInvitation from "@interfaces/database/JobApplicationInvitation";
import JobApplicationInvitationAction from "@interfaces/database/JobApplicationInvitationAction";
import JobID from "@interfaces/database/JobID";
import UserID from "@interfaces/database/UserID";

import { UserProfileContextStructure } from "@utils/components/UserProfileContext";
import {
  FIRESTORE_COLLECTIONS,
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  USER_STATUS,
  USER_TYPE
} from "@utils/config";
import { db } from "@utils/firebase";
import Timestamp from "@utils/Timestamp";

const canInviteCandidate = (
  userProfile: UserProfileContextStructure,
  userId: UserID
) => {
  if (
    userProfile.value &&
    userProfile.value.status === USER_STATUS.OK &&
    userProfile.value.user_type === USER_TYPE.COMPANY &&
    userId
  ) {
    return true;
  }
  return false;
};

// FIXME: Add logic to compute eligible jobs to invite any particular candidate
const eligibleJobsForInvite = () => {
  /* no-op */
};

const handleInviteCandidate = async (
  jobId: JobID,
  candidateId: UserID,
  companyId: CompanyID,
  userProfile: UserProfileContextStructure,
  userId: string
) => {
  if (
    jobId &&
    userId &&
    companyId &&
    (await isCandidateExists(candidateId)) &&
    canInviteCandidate(userProfile, userId)
  ) {
    const currentTimeStamp = Timestamp.now();

    const applicationAction: JobApplicationInvitationAction = {
      action_type: JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED,
      initiator_user_id: userId,
      updated_at: currentTimeStamp
    };

    const jobApplication: JobApplicationInvitation = {
      job_id: jobId,
      candidate_id: candidateId,
      company_id: companyId,
      actions: [applicationAction]
    };

    try {
      const collectionReference = collection(
        db,
        `${FIRESTORE_COLLECTIONS.JOBS}/${jobId}/${FIRESTORE_COLLECTIONS.APPLICATIONS}`
      );
      const applicationDocument = await addDoc(
        collectionReference,
        jobApplication
      );

      if (applicationDocument.id) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
  return false;
};

const isCandidateExists = async (candidateId: string): Promise<boolean> => {
  const docRef = doc(db, FIRESTORE_COLLECTIONS.USERS, candidateId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return true;
  }

  return false;
};

export {
  canInviteCandidate,
  eligibleJobsForInvite,
  handleInviteCandidate,
  isCandidateExists
};
