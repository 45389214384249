import { useEffect, useState } from "react";

import { useForm, UseFormSetValue } from "react-hook-form";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack
} from "@mui/material";
import { ContentState, convertToRaw, RawDraftContentState } from "draft-js";
import { doc, getDoc } from "firebase/firestore";
import htmlToDraft from "html-to-draftjs";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import * as yup from "yup";

import { AutoSuggestionDialogState } from "@pages/EmployersPostJob/JobInformation";
import JobInformationPromptInputForm from "@pages/EmployersPostJob/JobInformation/JobInformationPromptInputForm";

import SkeletonJobDetailForm from "@skeletons/SkeletonJobDetailForm";

import Button from "@components/Button";
import Dialog from "@components/Dialog";
import Typography from "@components/Typography";
import WYSIWYG from "@components/WYSIWYG";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import JobProfile from "@interfaces/database/JobProfile";

import {
  FIRESTORE_COLLECTIONS,
  FREE_TEXT_FIELD_MAX_LENGTH,
  JOB_DESCRIPTION_FIELD_MAX_LENGTH,
  JOB_INFORMATION_SECTION_TYPE,
  WYSIWYG_URL_REGEX
} from "@utils/config";
import { rawDraftContentStateToString } from "@utils/convertDraftContentState";
import { db, functions } from "@utils/firebase";
import { resolveMultiLingual } from "@utils/multiLingual";
import translate, { intl } from "@utils/translate";

interface JobInformationFormDialogProps {
  generatedContent: RawDraftContentState;
  label: string;
  placeholder: string;
  autoSuggestionDialog: AutoSuggestionDialogState;
  setAutoSuggestionDialog?: (data: AutoSuggestionDialogState) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setJobFieldValue: UseFormSetValue<any>;
  jobTitle: string;
  isEdit?: boolean;
}

export interface JobInformationContentResponse {
  success: boolean;
  content: string;
  promptId: string;
}

const JobInformationFormDialog = ({
  generatedContent,
  label,
  placeholder,
  autoSuggestionDialog,
  setAutoSuggestionDialog,
  setJobFieldValue,
  jobTitle,
  isEdit = false
}: JobInformationFormDialogProps) => {
  const userData = useUserProfile();
  const toast = useToast();
  const { job_id: jobId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPromptInput, setShowPromptInput] = useState<boolean>(isEdit);
  const [promptId, setPromptId] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [
    jobInformationContent,
    isJobInformationContentLoading,
    jobInformationContentError
  ] = useHttpsCallable(functions, "getJobInformationContent");

  const schema = yup.object({
    promptInput: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get(
            "t_job_post_job_information_auto_generation_prompt_input_label"
          ),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get(
            "t_job_post_job_information_auto_generation_prompt_input_label"
          )
        })
      ),
    generatedContent: yup
      .object()
      .shape({
        blocks: yup.array().of(yup.object()).min(1),
        entityMap: yup.object()
      })
      .test(
        "generatedContent",
        intl.get("t_error_required", {
          field: intl.get("t_job_post_job_information_step_job_description")
        }),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) =>
          rawDraftContentStateToString(rawDraftState).length > 0
      )
      .test(
        "generatedContent",
        intl.get("t_error_max_limit", {
          field: intl.get("t_job_post_job_information_step_job_description"),
          maxLimit: JOB_DESCRIPTION_FIELD_MAX_LENGTH
        }),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) =>
          rawDraftContentStateToString(rawDraftState).length <=
          JOB_DESCRIPTION_FIELD_MAX_LENGTH
      )
      .test(
        "generatedContent",
        intl.get("t_error_urls_are_prohibited"),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) => {
          const isLinkNotFound = Object.values(rawDraftState.entityMap).every(
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            (singleNode: any) => {
              singleNode.type !== "LINK";
            }
          );
          return isLinkNotFound;
        }
      )
      .test(
        "generatedContent",
        intl.get("t_error_urls_are_prohibited"),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) => {
          const text = rawDraftContentStateToString(rawDraftState);
          return !WYSIWYG_URL_REGEX.test(text);
        }
      )
  });

  const formInitValues = {
    generatedContent: generatedContent,
    promptInput: ""
  };

  const methods = useForm({
    defaultValues: formInitValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, setValue, reset, watch } = methods;

  useEffect(() => {
    reset(formInitValues);
    (async () => {
      if (autoSuggestionDialog.isOpen && jobId) {
        try {
          setIsLoading(true);
          const jobDocRef = doc(db, FIRESTORE_COLLECTIONS.JOBS, jobId);
          const jobDocSnap = await getDoc(jobDocRef);
          if (jobDocSnap.exists()) {
            const jobData = jobDocSnap.data() as JobProfile;
            const companyId = jobData.client_company_id
              ? jobData.client_company_id
              : jobData.company_id;

            const companyDocRef = doc(
              db,
              FIRESTORE_COLLECTIONS.COMPANIES,
              companyId
            );
            const companyDocSnap = await getDoc(companyDocRef);
            if (companyDocSnap.exists()) {
              const companyData = companyDocSnap.data();
              setCompanyName(resolveMultiLingual(companyData.name) ?? "");
              if (isEdit) {
                setShowPromptInput(true);
                setJobFieldValue(
                  autoSuggestionDialog.openFor === "job-description"
                    ? "jobDescription"
                    : "mustRequirements",
                  generatedContent
                );
              } else {
                const res = await jobInformationContent({
                  jobData: {
                    companyName: resolveMultiLingual(companyData.name),
                    jobTitle: jobTitle,
                    jobInformationSectionType:
                      autoSuggestionDialog.openFor === "job-description"
                        ? JOB_INFORMATION_SECTION_TYPE.JOB_DESCRIPTION
                        : JOB_INFORMATION_SECTION_TYPE.MUST_REQUIREMENT
                  },
                  locale: translate.getCurrentLocale()
                });
                const responseData = res?.data as JobInformationContentResponse;
                if (responseData.promptId) {
                  setPromptId(responseData.promptId);
                }
                const blocksFromHtml = htmlToDraft(responseData.content);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(
                  contentBlocks,
                  entityMap
                );
                const rawContentState = convertToRaw(contentState);
                setValue("generatedContent", rawContentState);
              }
            }
          }
          setIsLoading(false);
        } catch (error) {
          toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
          setIsLoading(false);
        }
      } else {
        reset();
        setShowPromptInput(false);
      }
    })();
  }, [autoSuggestionDialog.isOpen, isEdit, jobId]);

  useEffect(() => {
    if (jobInformationContentError) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  }, [jobInformationContentError]);

  const handleUseThisButton = async (formData: { promptInput: string }) => {
    setIsLoading(true);
    try {
      const res = await jobInformationContent({
        jobData: {
          companyName: companyName,
          jobTitle: jobTitle,
          jobInformationSectionType:
            autoSuggestionDialog.openFor === "job-description"
              ? JOB_INFORMATION_SECTION_TYPE.JOB_DESCRIPTION
              : JOB_INFORMATION_SECTION_TYPE.MUST_REQUIREMENT
        },
        promptData: {
          promptId,
          promptInput: formData.promptInput
        },
        locale: translate.getCurrentLocale()
      });
      const responseData = res?.data as JobInformationContentResponse;
      if (responseData.promptId) {
        setPromptId(responseData.promptId);
      }
      const blocksFromHtml = htmlToDraft(responseData.content);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const rawContentState = convertToRaw(contentState);
      setValue("generatedContent", rawContentState);
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
    setIsLoading(false);
  };

  if (userData?.loading) {
    return <SkeletonJobDetailForm />;
  }

  return (
    <Dialog
      height="80vh"
      title={label}
      isStopEventPropagation
      isDefaultOpen={autoSuggestionDialog.isOpen}
      initiator=""
      onClose={() =>
        setAutoSuggestionDialog?.({
          ...autoSuggestionDialog,
          isOpen: false
        })
      }>
      {(handleAgree, handleCancel) => (
        <>
          {isJobInformationContentLoading || isLoading ? (
            <Stack
              height="100%"
              gap={2}
              width="100%"
              justifyContent="center"
              alignItems="center">
              <Typography variant="h4">
                {intl.get("t_general_your_response_generate")}
              </Typography>
              <CircularProgress />
            </Stack>
          ) : (
            <>
              <DialogContent>
                <Grid container columnSpacing={3}>
                  <Grid item xs={12}>
                    {showPromptInput ? (
                      <JobInformationPromptInputForm
                        companyName={companyName}
                        jobTitle={jobTitle}
                        autoSuggestionDialog={autoSuggestionDialog}
                        setJobInfoFieldValue={setValue}
                        promptId={promptId}
                        isEdit={isEdit}
                        oldContent={generatedContent}
                      />
                    ) : (
                      <Box my={2}>
                        <Button
                          handleClick={() => setShowPromptInput(true)}
                          variant="outlined">
                          {intl.get(
                            "t_job_post_job_information_auto_generation_prompt_button_label"
                          )}
                        </Button>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      noValidate
                      component="form"
                      id="job-information-dialog-form"
                      onSubmit={handleSubmit(handleUseThisButton)}>
                      <Typography variant="body1" color="text.secondary" mb={2}>
                        {intl.get(
                          "t_job_post_job_information_auto_generation_modal_subtitle",
                          {
                            sectionType:
                              autoSuggestionDialog.openFor === "job-description"
                                ? intl.get(
                                    "t_job_post_job_information_step_job_description"
                                  )
                                : intl.get(
                                    "t_job_post_job_information_step_must_requirement"
                                  )
                          }
                        )}
                      </Typography>
                      <WYSIWYG
                        defaultValue={convertToRaw(
                          ContentState.createFromText("")
                        )}
                        mode="edit"
                        control={control}
                        name="generatedContent"
                        label={label}
                        placeholder={placeholder}
                        required
                        disabled={isLoading}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ pb: 2 }}>
                <Button
                  handleClick={handleCancel}
                  variant="outlined"
                  disabled={isLoading}>
                  {intl.get("t_general_cancel")}
                </Button>
                <Button
                  handleClick={() => {
                    setJobFieldValue(
                      autoSuggestionDialog.openFor === "job-description"
                        ? "jobDescription"
                        : "mustRequirements",
                      watch("generatedContent")
                    );
                    handleCancel();
                  }}
                  disabled={isLoading}
                  loading={isLoading}
                  formId="job-information-dialog-form">
                  {intl.get("t_general_use_this")}
                </Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

export default JobInformationFormDialog;
