import { Box, styled } from "@mui/material";

import { INDIVIDUAL_USER_PROFILE_COMPLETION_TOTAL_PROGRESS_STEPS_EN_CV } from "@utils/config";
import { colorPalette } from "@utils/theme";

interface ProgressBarProps {
  currentProgressStep: number;
  totalProgressSteps?: number;
}

const StyledProgressBar = styled(Box)({
  display: "flex",
  gap: 8
});

const ProgressBar = ({
  currentProgressStep,
  totalProgressSteps = INDIVIDUAL_USER_PROFILE_COMPLETION_TOTAL_PROGRESS_STEPS_EN_CV
}: ProgressBarProps) => {
  return (
    <StyledProgressBar>
      {[...Array(totalProgressSteps)].map((_, index) => {
        const indexProgressStep = index + 1;
        const testId =
          indexProgressStep < currentProgressStep
            ? "progress_completed"
            : "progress_incomplete";
        return (
          <Box
            data-testid={testId}
            component="div"
            key={index}
            width={{
              xs: "clamp(30px, 5vw, 40px)",
              sm: "clamp(20px, 4vw, 40px)",
              md: 40
            }}
            height={24}
            display="block"
            borderRadius={1}
            bgcolor={
              currentProgressStep - 1 > 0 && index < currentProgressStep - 1
                ? colorPalette.orange.base
                : colorPalette.grey.base
            }
          />
        );
      })}
    </StyledProgressBar>
  );
};

export default ProgressBar;
