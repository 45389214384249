import { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Container,
  Grid,
  Stack,
  styled,
  Typography,
  useMediaQuery
} from "@mui/material";
import { logEvent } from "firebase/analytics";
import { useAuthState } from "react-firebase-hooks/auth";
import { SearchResponseHit } from "typesense/lib/Typesense/Documents";
import useForceUpdate from "use-force-update";

import SkeletonJobCard from "@skeletons/SkeletonJobCard";

import BenefitCard from "@components/BenefitCard";
import Button from "@components/Button";
import FTUIContainer, { CustomStep } from "@components/FTUIContainer";
import HomepageSearch from "@components/HomepageSearch";
import JobCard from "@components/JobCards/JobCard";
import SectionTitleLabel from "@components/SectionTitleLabel";
import SectionTitleMainText from "@components/SectionTitleMainText";
import SectionTitleSubText from "@components/SectionTitleSubText";
import TestimonialCarousel from "@components/TestimonialCarousel";
import TokhimoReviewBrandingCard from "@components/TokhimoReviewBrandingCard";
import TrustedBy from "@components/TrustedBy";

import useUserProfile from "@hooks/database/useUserProfile";

import MultiLingual from "@interfaces/database/MultiLingual";

import { canUserBookmark, handleJobBookmarkChange } from "@utils/bookmarkJob";
import {
  ANALYTICS_EVENT_TYPE,
  FILES_LOCATION_COMPANY_LOGO,
  FTUI_TOUR_NAME,
  JOB_ACTIVE_TAG_COMPANY_LAST_LOGIN_DAY,
  JOB_NEW_TAG_LAST_UPDATED_DAY,
  JOB_REMOTE_WORK_TYPE,
  JOB_REMOTE_WORK_TYPE_T_LABELS,
  JOB_STATUS_TAGS,
  JOB_VISA_SPONSORSHIP_AVAILABLE,
  LANGUAGE_PROFICIENCY,
  MIN_YEARS_OF_EXPERIENCE_T_LABELS,
  TYPESENSE_COLLECTIONS
} from "@utils/config";
import { getDaysAgo } from "@utils/dateTime";
import { analytics, auth } from "@utils/firebase";
import { getFileURL } from "@utils/getFileURL";
import { resolveMultiLingual } from "@utils/multiLingual";
import theme, { colorPalette } from "@utils/theme";
import translate, {
  intl,
  LOCALE_DATA,
  LOCALE_DATA_LOWERCASE
} from "@utils/translate";
import getClient from "@utils/typesense";

import BG_Homepage_Trustedby from "@assets/images/BG_Homepage_Trustedby.svg";
import Blue_Shape from "@assets/images/shapes/Blue_Shape.png";
import Orange_Shape from "@assets/images/shapes/Orange_Shape.png";
import Homepage_Search_Animation from "@assets/video/Homepage_Search_Animation.mp4";

import CANDIDATE_TESTIMONIAL_DATA from "@data/candidateTestimonials.json";
import HOME_PAGE_JOB_CARDS_DATA from "@data/homePageJobCards.json";

interface JobListSingleJob {
  company_id: string;
  company_logo_extension: string;
  company_name_en: string;
  company_name_ja: string;
  contract_type: string;
  id: string;
  job_description_en: string;
  job_description_ja: string;
  job_id: string;
  language_requirements_en: number;
  language_requirements_ja: number;
  location_city: string;
  location_country: string;
  minimum_experience: number;
  remote_work: string;
  salary_max: number;
  salary_min: number;
  skills: Array<string>;
  title_en: string;
  title_ja: string;
  unix_last_login_at: number;
  unix_updated_at: number;
  unix_company_logo_updated_at: number;
  visa_sponsorship: string;
}

const StyledContainer = styled(Container)({
  marginTop: "1.5rem",
  marginBottom: "1.5rem"
});

const getJobList = async (): Promise<Array<JobListSingleJob>> => {
  const typesenseClient = getClient();
  try {
    const jobIds = (HOME_PAGE_JOB_CARDS_DATA as Array<{ jobId: string }>).map(
      (singleJobCardData) => singleJobCardData.jobId
    );
    const jobIdFilter = `id:=[${jobIds
      .map((singleJobId) => `${singleJobId}`)
      .join(", ")}]`;

    const jobList = await typesenseClient
      .collections(TYPESENSE_COLLECTIONS.JOBS)
      .documents()
      .search({
        q: "",
        query_by: "title_en,company_name_en,title_ja,company_name_ja",
        filter_by: jobIdFilter
      });

    if (jobList.hits && Array.isArray(jobList.hits)) {
      const jobs = jobList.hits.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (singleJob: SearchResponseHit<any>) => singleJob.document
      );
      return jobs;
    }
  } catch (e) {
    return [];
  }
  return [];
};

const StyledVideo = styled("video")({
  objectFit: "scale-down",
  width: "100%",
  height: "100%",
  backgroundColor: "transparent",
  maskImage:
    "radial-gradient(circle, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgba(255,255,255,0) 100%)",
  zIndex: -1,
  position: "relative",
  maskSize: "110%"
});

const StyledAnimationVideoContainer = styled(Box)({
  backgroundColor: "transparent",
  overflow: "hidden",
  maskImage:
    "radial-gradient(closest-side, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 60%, rgb(255, 255, 255) 80%,rgba(255,255,255,0) 100%)",
  maskSize: "107%"
});

const StyledBackgroundImage = styled("img")(({ theme }) => {
  return {
    position: "absolute",
    bottom: 0,
    objectFit: "cover",
    width: "100%",
    height: "130%",
    zIndex: 0,
    objectPosition: "top",
    [theme.breakpoints.up("xl")]: {
      height: 1700
    }
  };
});

const StyledBlueShape = styled("img")(({ theme }) => {
  return {
    position: "absolute",
    top: "40%",
    left: -50,
    width: 160,
    [theme.breakpoints.down("md")]: {
      top: "33%",
      width: 110,
      left: -30
    }
  };
});

const StyledOrangeShape = styled("img")(({ theme }) => {
  return {
    position: "absolute",
    right: 0,
    bottom: -120,
    width: 160,
    [theme.breakpoints.down("md")]: {
      bottom: -30,
      width: 100
    }
  };
});

const Home = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const tourSteps: Array<CustomStep> = [
    {
      target: isSmallScreen
        ? "body"
        : "#candidate_update_profile_resume_button",
      title: intl.get("t_ftui_candidate_home_page_update_profile_title"),
      content: intl.get(
        "t_ftui_candidate_home_page_update_profile_description"
      ),
      disableBeacon: true,
      nextRoute: `/${translate.getCurrentLocale()}/profile`,
      ...(isSmallScreen ? { placement: "center" } : {})
    }
  ];

  const forceUpdate = useForceUpdate();
  const [jobs, setJobs] = useState<Array<JobListSingleJob>>([]);
  const [isJobsLoading, setIsJobsLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const userProfile = useUserProfile();
  const [user] = useAuthState(auth);
  const userId = user?.uid ?? "";

  const languageChanged = () => {
    forceUpdate();
  };

  useEffect(() => {
    translate.addLanguageChangedListener(languageChanged);
    return () => {
      translate.removeLanguageChangedListener(languageChanged);
    };
  }, []);

  useEffect(() => {
    logEvent(analytics, ANALYTICS_EVENT_TYPE.CANDIDATE_HP_LOAD);
    (async () => {
      setIsJobsLoading(true);
      const jobsList = await getJobList();
      setJobs(jobsList);
      setIsJobsLoading(false);
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>{intl.get("t_seo_candidate_home_page_title")}</title>
        <meta
          name="description"
          content={intl.get("t_seo_candidate_home_page_description")}
        />
        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content={intl.get("t_seo_candidate_home_page_title")}
        />
        <meta
          property="og:description"
          content={intl.get("t_seo_candidate_home_page_description")}
        />
        <meta
          property="og:image"
          content={`${
            process.env.REACT_APP_FRONTEND_URL
          }/assets/seo_resources/OGP_Image_${translate.getCurrentLocaleShort()}.png`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={process.env.REACT_APP_FRONTEND_URL} />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={intl.get("t_seo_candidate_home_page_title")}
        />
        <meta
          name="twitter:description"
          content={intl.get("t_seo_candidate_home_page_description")}
        />
        <meta
          name="twitter:image"
          content={`${
            process.env.REACT_APP_FRONTEND_URL
          }/assets/seo_resources/OGP_Image_${translate.getCurrentLocaleShort()}.png`}
        />
        <meta name="twitter:image:alt" content={intl.get("t_seo_og_img_alt")} />

        {/* Canonical Link */}
        {Object.keys(LOCALE_DATA).map((singleLocale) => {
          const url = new URL(window.location.href);
          const pathSegments = url.pathname
            .split("/")
            .filter((segment) => segment !== "");

          if (
            pathSegments[0] &&
            Object.keys(LOCALE_DATA_LOWERCASE).includes(
              pathSegments[0].toLowerCase()
            )
          ) {
            // Locale is present, update it
            pathSegments[0] = singleLocale;
          } else {
            // Locale is not present, insert it
            pathSegments.unshift(singleLocale);
          }
          url.pathname = "/" + pathSegments.join("/");

          let frontendUrl = process.env.REACT_APP_FRONTEND_URL ?? "";
          frontendUrl = frontendUrl.replace("https://", "");
          url.hostname = frontendUrl;
          url.port = "";

          return (
            <link
              rel="alternate"
              hrefLang={singleLocale}
              key={singleLocale}
              href={url.toString()}
            />
          );
        })}
      </Helmet>
      {/* Homepage search section */}
      <section style={{ zIndex: 2 }}>
        <StyledContainer>
          <Box
            display={{ md: "flex" }}
            width={{ md: "95%" }}
            ml={{ md: "5%" }}
            mt={{ md: 6.25, lg: 12.5 }}>
            {/* HOMEPAGE SEARCH CONTENT */}
            <Box minWidth={{ md: 662 }} alignSelf="center">
              {/* HEADING */}
              <Box>
                <Typography variant="h1">
                  {intl.get("t_homepage_jobs_in_japan")}
                </Typography>
                <Typography
                  paragraph
                  variant="subtitle3"
                  color="text.secondary"
                  mt={2.5}
                  mb={3}>
                  {intl.get("t_homepage_jobs_in_japan_sub_text")}
                </Typography>
              </Box>
              {/* ACTIONS */}
              <HomepageSearch />
            </Box>
            {/*  ANIMATION VIDEO */}
            <StyledAnimationVideoContainer
              display="flex"
              justifyContent="center"
              mb={{ xs: -12.5, md: 0 }}>
              <Box maxWidth={1200} maxHeight={900}>
                <StyledVideo
                  data-testid="candidate_homepage_video"
                  autoPlay={true}
                  muted
                  loop
                  id="homepageAnimation"
                  preload="none"
                  playsInline>
                  <source src={Homepage_Search_Animation} type="video/mp4" />
                  Your browser does not support HTML5 video.
                </StyledVideo>
              </Box>
            </StyledAnimationVideoContainer>
          </Box>
        </StyledContainer>
      </section>
      {/* Trusted By & Benefit Card */}
      <section style={{ position: "relative" }}>
        <StyledBackgroundImage
          src={BG_Homepage_Trustedby}
          alt={intl.get("t_alt_blue_background_blob")}
          className="bg-img-home-trustedby"
        />
        <StyledBlueShape src={Blue_Shape} alt={intl.get("t_alt_blue_shape")} />
        <StyledOrangeShape
          src={Orange_Shape}
          alt={intl.get("t_alt_orange_shape")}
        />
        <StyledContainer>
          <Grid
            container
            spacing={3}
            px={{ md: 12.5 }}
            py={8.75}
            mt={{ xs: "calc(12%)", lg: 43.75 }}
            position="relative">
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              flexDirection="column">
              <Box mb={5}>
                <TrustedBy />
              </Box>
              <SectionTitleLabel
                label={intl.get("t_homepage_benefits_label")}
              />
              <SectionTitleMainText
                title={intl.get("t_homepage_benefits_main_text")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BenefitCard
                title={intl.get(
                  "t_homepage_benefits_effortless_profile_creation"
                )}
                description={intl.get(
                  "t_homepage_benefits_effortless_profile_creation_desc"
                )}
                image="rocket"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BenefitCard
                title={intl.get("t_homepage_benefits_language_compatibility")}
                description={intl.get(
                  "t_homepage_benefits_language_compatibility_desc"
                )}
                image="thumbsup"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BenefitCard
                title={intl.get("t_homepage_benefits_skill_based_matching")}
                description={intl.get(
                  "t_homepage_benefits_skill_based_matching_desc"
                )}
                image="writing"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BenefitCard
                title={intl.get(
                  "t_homepage_benefits_secure_application_process"
                )}
                description={intl.get(
                  "t_homepage_benefits_secure_application_process_desc"
                )}
                image="shield"
              />
            </Grid>
          </Grid>
        </StyledContainer>
      </section>
      {/* Job Card */}
      <section style={{ zIndex: 2 }}>
        <StyledContainer>
          <Grid
            container
            spacing={3}
            px={{ md: 12.5 }}
            mt={10}
            mb={{ xs: 31.25, md: 43.75, xl: 62.5 }}>
            <Grid item xs={12} mb={5}>
              <Stack alignItems="center">
                <Box
                  bgcolor={colorPalette.blue[20]}
                  display="inline-flex"
                  padding={0.5}
                  borderRadius={1}>
                  <Typography variant="subtitle5" color="primary.main">
                    {intl.get("t_homepage_explore_jobs")}
                  </Typography>
                </Box>
                <Typography variant="h2" mt={1.5} mb={2.5}>
                  {intl.get("t_homepage_actively_hiring")}
                </Typography>
                <Typography
                  variant="subtitle3"
                  color="text.secondary"
                  align="center">
                  {intl.get("t_homepage_actively_hiring_sub_text")}
                </Typography>
              </Stack>
            </Grid>
            {isJobsLoading ? (
              [...Array(HOME_PAGE_JOB_CARDS_DATA.length)].map((_, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <SkeletonJobCard />
                </Grid>
              ))
            ) : (
              <div id="data-for-ssg">
                <Grid container alignItems="stretch" spacing={3} ml="unset">
                  {/* FIXME: Same, a typesense interface needs to be prepared */}
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {jobs.map((singleJob: any, index: number) => {
                    const statusTags: Array<
                      typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]
                    > = [];
                    if (singleJob.unix_updated_at) {
                      const updatedAt = new Date(
                        singleJob.unix_updated_at * 1000
                      );

                      if (
                        getDaysAgo(updatedAt) <= JOB_NEW_TAG_LAST_UPDATED_DAY
                      ) {
                        statusTags.push(JOB_STATUS_TAGS.NEW);
                      }
                    }
                    if (
                      singleJob.client_company_name_en ||
                      singleJob.client_company_name_ja
                    ) {
                      statusTags.push(JOB_STATUS_TAGS.AGENCY);
                    }
                    if (singleJob.unix_last_login_at) {
                      const lastLoginAt = new Date(
                        singleJob.unix_last_login_at * 1000
                      );
                      if (
                        getDaysAgo(lastLoginAt) <=
                        JOB_ACTIVE_TAG_COMPANY_LAST_LOGIN_DAY
                      ) {
                        statusTags.push(JOB_STATUS_TAGS.ACTIVE);
                      }
                    }
                    const companyName: MultiLingual<string> = {
                      en: singleJob.company_name_en,
                      ja: singleJob.company_name_ja
                    };

                    const jobTitle: MultiLingual<string> = {
                      en: singleJob.title_en,
                      ja: singleJob.title_ja
                    };

                    const jobDescription: MultiLingual<string> = {
                      en: singleJob.job_description_en,
                      ja: singleJob.job_description_ja
                    };

                    const categories = [];
                    if (singleJob?.job?.job_overview?.minimum_experience) {
                      categories.push(
                        intl.get(
                          MIN_YEARS_OF_EXPERIENCE_T_LABELS[
                            singleJob?.job_overview
                              ?.minimum_experience as keyof typeof MIN_YEARS_OF_EXPERIENCE_T_LABELS
                          ]
                        )
                      );
                    }
                    if (
                      singleJob?.job?.job_overview?.remote_possible ==
                        JOB_REMOTE_WORK_TYPE.FULLY_REMOTE ||
                      singleJob?.job?.job_overview?.remote_possible ==
                        JOB_REMOTE_WORK_TYPE.PARTIALLY_REMOTE
                    ) {
                      categories.push(
                        intl.get(
                          JOB_REMOTE_WORK_TYPE_T_LABELS[
                            singleJob?.job?.job_overview
                              ?.remote_possible as keyof typeof JOB_REMOTE_WORK_TYPE_T_LABELS
                          ]
                        )
                      );
                    }
                    if (
                      parseInt(LANGUAGE_PROFICIENCY.BUSINESS) >=
                      parseInt(
                        singleJob?.job?.language_requirement?.at(1)
                          ?.proficiency ?? "0"
                      )
                    ) {
                      categories.push(intl.get("t_job_card_en_tag"));
                    }

                    if (
                      singleJob?.job?.visa_sponsorship ==
                      JOB_VISA_SPONSORSHIP_AVAILABLE.YES
                    ) {
                      categories.push(intl.get("t_job_visa_sponsorship"));
                    }

                    return (
                      <Grid item xs={12} md={6} key={index} mb={3}>
                        <JobCard
                          categories={categories}
                          isBookmarked={
                            canUserBookmark(userProfile, userId)
                              ? userProfile.value?.bookmarks?.includes(
                                  singleJob.job_id
                                )
                              : false
                          }
                          handleBookmarkChange={
                            canUserBookmark(userProfile, userId)
                              ? (isBookmarked) =>
                                  handleJobBookmarkChange(
                                    singleJob.job_id,
                                    isBookmarked,
                                    userProfile,
                                    userId
                                  )
                              : undefined
                          }
                          companyName={resolveMultiLingual(companyName)}
                          status={statusTags}
                          jobTitle={resolveMultiLingual(jobTitle)}
                          location={{
                            country: singleJob.location_country,
                            city: singleJob.location_city
                          }}
                          description={resolveMultiLingual(jobDescription)}
                          companyLogo={getFileURL(
                            FILES_LOCATION_COMPANY_LOGO,
                            singleJob.company_id,
                            singleJob.company_logo_extension,
                            new Date(singleJob.company_logo_updated_at * 1000)
                          )}
                          handleClick={() =>
                            navigate(
                              `/${translate.getCurrentLocale()}/jobs/${
                                singleJob.job_id
                              }`
                            )
                          }
                          createdDate={
                            new Date(singleJob.unix_updated_at * 1000)
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            )}

            <Grid item xs={12} display="flex" justifyContent="center" mt={5}>
              <Button
                handleClick={() =>
                  navigate(`/${translate.getCurrentLocale()}/search`)
                }
                data-testid="homepage_search_more_jobs_button">
                {intl.get("t_homepage_search_more_jobs")}
              </Button>
            </Grid>
          </Grid>
        </StyledContainer>
      </section>
      {/* Review */}
      <section style={{ position: "relative", zIndex: 2 }}>
        <StyledContainer>
          {/* Tokhimo Review Card */}
          <Stack
            alignItems="center"
            mt={{ xs: "9.4rem", md: "6.25rem" }}
            mb="11.6rem">
            <TokhimoReviewBrandingCard />
          </Stack>

          <Stack alignItems="center">
            <Box>
              <SectionTitleLabel label={intl.get("t_homepage_review_label")} />
            </Box>
            <SectionTitleMainText title={intl.get("t_homepage_review_title")} />
            <SectionTitleSubText
              subText={intl.get("t_homepage_review_sub_text")}
            />
          </Stack>
          <TestimonialCarousel
            testimonialContent={
              resolveMultiLingual(CANDIDATE_TESTIMONIAL_DATA) ?? []
            }
          />
        </StyledContainer>
      </section>

      {/* FTUI */}
      <FTUIContainer
        tourSteps={tourSteps}
        tourName={FTUI_TOUR_NAME.CANDIDATE_HOME_PAGE}
      />
    </>
  );
};

export default Home;
