import { Context, createContext, ReactNode, useEffect, useState } from "react";

import {
  addDoc,
  collection,
  doc,
  FirestoreError,
  orderBy,
  query,
  setDoc,
  where
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";

import useUserProfile from "@hooks/database/useUserProfile";

import ContextCompanyProfile from "@interfaces/components/ContextCompanyProfile";
import KeyLabel from "@interfaces/components/KeyLabel";
import BookmarkedCandidate from "@interfaces/database/BookmarkedCandidate";
import CompanyProfile from "@interfaces/database/CompanyProfile";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import MultiLingual from "@interfaces/database/MultiLingual";

import { FIRESTORE_COLLECTIONS, LOCALE } from "@utils/config";
import { auth, db } from "@utils/firebase";
import { resolveMultiLingual } from "@utils/multiLingual";

interface CompanyDetailsContextProps {
  children?: ReactNode;
}

export interface CompanyDetailsContextStructure {
  value?: ContextCompanyProfile;
  setValue?: (
    companyValue: ContextCompanyProfile,
    handleSuccess?: () => void,
    handleFail?: () => void
  ) => Promise<void>;
  setJobValue?: (
    jobId: JobID,
    jobValue: JobProfile,
    handleSuccess: (jobId: string) => void,
    handleFail: () => void,
    options?: { merge: boolean }
  ) => Promise<void>;
  loading?: boolean;
  error?: FirestoreError;
}

export const CompanyDetailsDBContext: Context<CompanyDetailsContextStructure> =
  createContext({});

const CompanyDetailsContext = ({ children }: CompanyDetailsContextProps) => {
  const [companyDetails, setCompanyDetails] = useState<object>({});
  const userProfile = useUserProfile();
  const [user] = useAuthState(auth);

  // Fetch Company Data
  const documentReference = doc(
    db,
    `${FIRESTORE_COLLECTIONS.COMPANIES}/${userProfile.value?.company_id}`
  );
  const [companyData, companyDataLoading, companyDataError] =
    useDocumentData(documentReference);

  // Fetch Job Data
  const jobsCollection = collection(db, FIRESTORE_COLLECTIONS.JOBS);
  const [jobsData, jobsDataLoading, jobsDataError] = useCollection(
    query(
      jobsCollection,
      where("company_id", "==", userProfile.value?.company_id + "")
    )
  );

  // Fetch Saved Candidate Data
  const bookmarkedCandidatesCollection = collection(
    db,
    `${FIRESTORE_COLLECTIONS.COMPANIES}/${userProfile.value?.company_id + ""}/${
      FIRESTORE_COLLECTIONS.SAVED_CANDIDATES
    }`
  );
  const queryForBookmarkedCandidates = user?.uid
    ? query(
        bookmarkedCandidatesCollection,
        where("manager_id", "==", user.uid),
        orderBy("created_at", "desc")
      )
    : query(bookmarkedCandidatesCollection);

  const [
    bookmarkedCandidatesData,
    bookmarkedCandidatesDataLoading,
    bookmarkedCandidatesDataError
  ] = useCollection(queryForBookmarkedCandidates);

  const setCompanyValue = async (
    companyValue: ContextCompanyProfile,
    handleSuccess: () => void = () => undefined,
    handleFail: () => void = () => undefined
  ) => {
    const data = companyValue as CompanyProfile;
    setDoc(documentReference, data)
      .then(() => handleSuccess())
      .catch(() => handleFail());
  };

  const setJobValue = async (
    jobId: JobID,
    jobValue: JobProfile,
    handleSuccess: (jobId: JobID) => void = () => undefined,
    handleFail: () => void = () => undefined,
    options: { merge: boolean } = { merge: false }
  ) => {
    jobValue.company_id = userProfile.value?.company_id + "";
    const skillNames = jobValue.skills?.map((singleSkill) => singleSkill.name);
    if (skillNames !== undefined) {
      if (!jobValue.metadata) {
        jobValue.metadata = {};
      }
      jobValue.metadata.skill_names = skillNames;
    }
    if (jobId === "new") {
      addDoc(collection(db, FIRESTORE_COLLECTIONS.JOBS), jobValue)
        .then((docRef) => handleSuccess(docRef.id))
        .catch(() => handleFail());
    } else {
      const jobDocumentReference = doc(
        db,
        `${FIRESTORE_COLLECTIONS.JOBS}/${jobId}`
      );
      setDoc(jobDocumentReference, jobValue, { merge: options?.merge })
        .then(() => handleSuccess(jobId))
        .catch(() => handleFail());
    }
  };

  useEffect(() => {
    // Populate job titles.
    const jobTitleOptions: MultiLingual<Record<string, KeyLabel>> = {};

    jobsData?.docs.forEach((singleJob) => {
      const jobTitle = singleJob.data()?.job_title;
      if (jobTitle) {
        jobTitleOptions.en = jobTitleOptions.en || {};
        jobTitleOptions.ja = jobTitleOptions.ja || {};

        jobTitleOptions.en[singleJob.id] = {
          key: singleJob.id,
          label: resolveMultiLingual(jobTitle, LOCALE.EN) ?? singleJob.id
        };

        jobTitleOptions.ja[singleJob.id] = {
          key: singleJob.id,
          label: resolveMultiLingual(jobTitle, LOCALE.JA) ?? singleJob.id
        };
      }
    });

    // Populate saved candidate data.
    const bookmarkedCandidates: Record<string, BookmarkedCandidate> = {};

    if (bookmarkedCandidatesData) {
      //Note: Using forEach loop throws type error. So using traditional for loop.
      for (const singleBookmark of bookmarkedCandidatesData.docs) {
        const data = singleBookmark.data() as BookmarkedCandidate;
        bookmarkedCandidates[singleBookmark.id] = data;
      }
    }

    setCompanyDetails({
      value: {
        ...companyData,
        jobs: jobsData?.docs.map((singleJob) => singleJob.id),
        job_titles: jobTitleOptions,
        ...(bookmarkedCandidates
          ? {
              bookmarked_candidates: bookmarkedCandidates
            }
          : {})
      },
      loading:
        companyDataLoading ||
        jobsDataLoading ||
        bookmarkedCandidatesDataLoading,
      error: companyDataError || jobsDataError || bookmarkedCandidatesDataError,
      setValue: setCompanyValue,
      setJobValue: setJobValue
    });
  }, [
    companyData,
    companyDataLoading,
    companyDataError,
    jobsData,
    jobsDataLoading,
    jobsDataError,
    bookmarkedCandidatesData,
    bookmarkedCandidatesDataLoading,
    bookmarkedCandidatesDataError
  ]);

  return (
    <CompanyDetailsDBContext.Provider value={companyDetails}>
      {children}
    </CompanyDetailsDBContext.Provider>
  );
};

export default CompanyDetailsContext;
