/**
 * Returns the meta information of a file
 *
 * @param {File} file
 * @return {object} file meta information
 */

const getFileMetaInformation = (file: File) => {
  const fileName = file.name; //With extension
  const fileNameWithoutExt = fileName.substring(0, fileName.lastIndexOf(".")); //Without extension

  const fileExt = fileName.substring(
    fileName.lastIndexOf(".") + 1,
    fileName.length
  );
  const fileType = file.type; //MIME type

  const fileSize = file.size; //In bytes
  const fileSizeInMb = file.size / 1024 ** 2;
  return {
    fileName,
    fileNameWithoutExt,
    fileExt,
    fileType,
    fileSize,
    fileSizeInMb
  };
};

export default getFileMetaInformation;
