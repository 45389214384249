import { Fragment } from "react";

import { Skeleton, TypographyProps } from "@mui/material";

import Typography from "@components/Typography";

interface SkeletonTypographyProps extends TypographyProps {
  width?: "x-short" | "short" | "medium" | "long" | "full";
  multiLine?: boolean;
}

const SkeletonTypography = ({
  variant,
  width = "short",
  multiLine = false,
  ...rest
}: SkeletonTypographyProps) => {
  return (
    <>
      {[...Array(multiLine ? 4 : 1)].map((_, idx) => {
        return (
          <Fragment key={idx}>
            <Typography
              display="block"
              variant={variant}
              width={
                width === "x-short"
                  ? "min(5ch, 100%)"
                  : width === "short"
                  ? "min(15ch, 100%)"
                  : width === "medium"
                  ? "min(25ch, 100%)"
                  : width === "long"
                  ? "min(40ch, 100%)"
                  : "100%"
              }
              key={idx}
              {...rest}>
              <Skeleton animation="wave" width="100%" />
            </Typography>
          </Fragment>
        );
      })}
    </>
  );
};

export default SkeletonTypography;
