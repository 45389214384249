import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonInput from "@skeletons/SkeletonInput";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Well from "@components/Well";

const SkeletonAdditionalInformation = () => {
  return (
    <>
      <SkeletonTypography my={1.5} variant="body2" />
      <SkeletonInput multiLine />
      <Well>
        <SkeletonTypography variant="body1" width="medium" mb={1} />
        <SkeletonTypography variant="body2" width="medium" mb={3} />
        <SkeletonButton />
      </Well>
    </>
  );
};

export default SkeletonAdditionalInformation;
