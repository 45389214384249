import { styled } from "@mui/material";

import Typography from "@components/Typography";

interface sectionTitleMainTextProps {
  title: string;
}

const StyledSectionTitleMainText = styled(Typography)({
  wordBreak: "break-word"
});

const SectionTitleMainText = ({ title }: sectionTitleMainTextProps) => {
  return (
    <StyledSectionTitleMainText
      variant="h3"
      color="primary.contrastText"
      textAlign="center"
      mt={1.5}>
      {title}
    </StyledSectionTitleMainText>
  );
};

export default SectionTitleMainText;
