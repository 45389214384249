import { Context, createContext, ReactNode, useEffect, useState } from "react";

import { User } from "firebase/auth";
import { doc, FirestoreError, setDoc } from "firebase/firestore";
import { useAuthState, useUpdateProfile } from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";

import ContextUserProfile from "@interfaces/components/ContextUserProfile";
import UserProfile from "@interfaces/database/UserProfile";

import {
  FIRESTORE_COLLECTIONS,
  INDIVIDUAL_USER_PROFILE_COMPLETION_TOTAL_PROGRESS_STEPS_EN_CV,
  INDIVIDUAL_USER_PROFILE_COMPLETION_TOTAL_PROGRESS_STEPS_JA_CV,
  INDIVIDUAL_USER_PROFILE_COMPLETION_TOTAL_PROGRESS_STEPS_JA_RIREKISHO,
  JA_ALPHA3_CODE,
  LANGUAGE_PROFICIENCY,
  LOCALE,
  USER_TYPE
} from "@utils/config";
import { auth, db } from "@utils/firebase";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";

interface UserProfileContextProps {
  children?: ReactNode;
}

export interface UserProfileContextStructure {
  value?: ContextUserProfile;
  setValue?: (
    details: ContextUserProfile,
    handleSuccess?: () => void,
    handleFail?: () => void,
    options?: {
      forceReloadUser?: boolean;
    }
  ) => Promise<void>;
  loading?: boolean;
  error?: FirestoreError;
}

// FIXME: Fix phone number updating.

export const userProfileDBContext: Context<UserProfileContextStructure> =
  createContext({});

const UserProfileContext = ({ children }: UserProfileContextProps) => {
  const [profileData, setProfileData] = useState<object>({});
  const [user] = useAuthState(auth);
  const documentReference = doc(
    db,
    `${FIRESTORE_COLLECTIONS.USERS}/${user?.uid}`
  );
  const [value, loading, error] = useDocumentData(documentReference);
  const [updateProfile] = useUpdateProfile(auth);

  const setDetails = async (
    details: ContextUserProfile,
    handleSuccess: () => void = () => undefined,
    handleFail: () => void = () => undefined,
    options: { forceReloadUser?: boolean } = { forceReloadUser: false }
  ) => {
    if (options.forceReloadUser) {
      await auth.currentUser?.reload();
    }

    if (!user || !auth.currentUser) {
      // Should not be able to update profile if not logged in.
      return;
    }

    const data = details as UserProfile;
    const firstName = resolveMultiLingual(
      details.summary?.basic_information?.first_name
    );
    const lastName = resolveMultiLingual(
      details.summary?.basic_information?.last_name
    );
    const name = firstName + (lastName ? ` ${lastName}` : "");

    if (!data.metadata) {
      data.metadata = {};
    }

    const {
      enCVCurrentProgressStep,
      jaCVCurrentProgressStep,
      jaRirekishoCurrentProgressStep,
      enCVTotalProgressSteps,
      jaCVTotalProgressSteps,
      jaRirekishoTotalProgressSteps
    } = getProgressFlagsAndSteps(details as UserProfile, auth.currentUser);

    const isEnCVCompleted =
      enCVCurrentProgressStep > enCVTotalProgressSteps ? true : false;
    const isJaCVCompleted =
      jaCVCurrentProgressStep > jaCVTotalProgressSteps ? true : false;

    const isJaRirekishoCompleted =
      jaRirekishoCurrentProgressStep > jaRirekishoTotalProgressSteps
        ? true
        : false;

    data.metadata.progress_flags = {
      en_cv: isEnCVCompleted,
      ja_cv: isJaCVCompleted,
      ja_rirekisho: isJaRirekishoCompleted
    };

    if (name !== user?.displayName) {
      updateProfile({ displayName: name });
    }

    const skillNames = data.summary?.skills?.map(
      (singleSkill) => singleSkill.name
    );
    if (skillNames !== undefined) {
      data.metadata.skill_names = skillNames;
    }

    if (data.summary) {
      if (
        data.summary.job_experience_overview &&
        data.summary.job_experience_overview.length > 0
      ) {
        data.summary.no_job_experience_overview = false;
      } else {
        data.summary.no_job_experience_overview = true;
      }
    }

    setDoc(documentReference, data)
      .then(() => handleSuccess())
      .catch(() => handleFail());
  };

  useEffect(() => {
    if (user && value?.user_type === USER_TYPE.INDIVIDUAL) {
      const {
        enCVCurrentProgressStep,
        jaCVCurrentProgressStep,
        jaRirekishoCurrentProgressStep,
        isJaBasicInformationCompleted,
        isEnBasicInformationCompleted,
        isJobExperienceOverviewCompleted,
        isLanguagesCompleted,
        isSkillsCompleted,
        isEnJobExperienceDetailsCompleted,
        isEnEducationCompleted,
        isJaJobExperienceDetailsCompleted,
        isJaEducationCompleted,
        enCVTotalProgressSteps,
        jaCVTotalProgressSteps,
        jaRirekishoTotalProgressSteps
      } = getProgressFlagsAndSteps(value as UserProfile, user);

      value.progress_flags = {
        basic_information: {
          en: isEnBasicInformationCompleted,
          ja: isJaBasicInformationCompleted
        },
        job_experience_overview: isJobExperienceOverviewCompleted,
        language: isLanguagesCompleted,
        skills: isSkillsCompleted,
        en_cv: {
          job_experience_details: isEnJobExperienceDetailsCompleted,
          education: isEnEducationCompleted
        },
        ja_cv: {
          job_experience_details: isJaJobExperienceDetailsCompleted,
          education: isJaEducationCompleted
        }
      };

      value.progress_steps = {
        en_cv: {
          current: enCVCurrentProgressStep,
          total: enCVTotalProgressSteps
        },
        ja_cv: {
          current: jaCVCurrentProgressStep,
          total: jaCVTotalProgressSteps
        },
        ja_rirekisho: {
          current: jaRirekishoCurrentProgressStep,
          total: jaRirekishoTotalProgressSteps
        }
      };
      if (!value.summary) {
        value.summary = {};
      }
      if (!value.summary.basic_information) {
        value.summary.basic_information = {};
      }
      if (!value.summary.languages) {
        value.summary.languages = [
          {
            name: JA_ALPHA3_CODE,
            proficiency: LANGUAGE_PROFICIENCY.NONE
          }
        ];
      }
      if (
        !resolveMultiLingual(value.summary.basic_information.first_name) &&
        !resolveMultiLingual(value.summary.basic_information.last_name)
      ) {
        const [firstName, ...lastName] = user?.displayName?.split(" ") ?? [
          "",
          ""
        ];
        value.summary.basic_information.first_name = prepareMultiLingual(
          firstName ?? ""
        );
        value.summary.basic_information.last_name = prepareMultiLingual(
          lastName.join(" ") ?? ""
        );
      }

      value.summary.basic_information.email =
        value.summary.basic_information.email ?? user?.email ?? "";
      value.summary.basic_information.phone_number =
        value.summary.basic_information.phone_number ?? user?.phoneNumber ?? "";
    }
    setProfileData({
      value: value as ContextUserProfile,
      loading: loading,
      error: error,
      setValue: setDetails
    });
  }, [value, loading, error]);

  return (
    <userProfileDBContext.Provider value={profileData}>
      {children}
    </userProfileDBContext.Provider>
  );
};

// Note: If we update the flag logic code in this file then make sure to update the same logic in the admin tools script
const getProgressFlagsAndSteps = (value: UserProfile, user: User) => {
  let enCVCurrentProgressStep = 1;
  let jaCVCurrentProgressStep = 1;
  let jaRirekishoCurrentProgressStep = 1;
  let isJaBasicInformationCompleted = false;
  let isEnBasicInformationCompleted = false;
  let isJobExperienceOverviewCompleted = false;
  let isLanguagesCompleted = false;
  let isSkillsCompleted = false;
  let isEnJobExperienceDetailsCompleted = false;
  let isEnEducationCompleted = false;
  let isJaJobExperienceDetailsCompleted = false;
  let isJaEducationCompleted = false;

  const enCVTotalProgressSteps =
    INDIVIDUAL_USER_PROFILE_COMPLETION_TOTAL_PROGRESS_STEPS_EN_CV;
  const jaCVTotalProgressSteps =
    INDIVIDUAL_USER_PROFILE_COMPLETION_TOTAL_PROGRESS_STEPS_JA_CV;
  const jaRirekishoTotalProgressSteps =
    INDIVIDUAL_USER_PROFILE_COMPLETION_TOTAL_PROGRESS_STEPS_JA_RIREKISHO;

  const isBasicInfoFilled =
    user.phoneNumber &&
    value.summary?.basic_information?.current_location?.country &&
    value.summary?.basic_information?.immigration_details?.country &&
    value.summary?.basic_information?.number_of_companies &&
    value.summary?.basic_information?.expected_salary !== null &&
    value.summary?.basic_information?.expected_salary !== undefined;

  const enCVFileInformation = resolveMultiLingual(
    value.cv?.file_information,
    LOCALE.EN,
    true
  );
  const jaCVFileInformation = resolveMultiLingual(
    value.cv?.file_information,
    LOCALE.JA,
    true
  );

  const isEnCVFileUploaded =
    enCVFileInformation &&
    enCVFileInformation.size &&
    enCVFileInformation.size > 0;
  const isJaCVFileUploaded =
    jaCVFileInformation &&
    jaCVFileInformation.size &&
    jaCVFileInformation.size > 0;

  if (
    isBasicInfoFilled &&
    resolveMultiLingual(
      value.summary?.basic_information?.first_name,
      LOCALE.EN
    ) &&
    resolveMultiLingual(value.summary?.basic_information?.last_name, LOCALE.EN)
  ) {
    isEnBasicInformationCompleted = true;
    enCVCurrentProgressStep += 1;
  }

  if (
    isBasicInfoFilled &&
    resolveMultiLingual(
      value.summary?.basic_information?.first_name,
      LOCALE.JA
    ) &&
    resolveMultiLingual(value.summary?.basic_information?.last_name, LOCALE.JA)
  ) {
    isJaBasicInformationCompleted = true;
    jaCVCurrentProgressStep += 1;
  }

  if (
    value.summary?.no_job_experience_overview ||
    (value.summary?.job_experience_overview?.length &&
      value.summary.job_experience_overview.length > 0)
  ) {
    isJobExperienceOverviewCompleted = true;
    enCVCurrentProgressStep += 1;
    jaCVCurrentProgressStep += 1;
  }

  if (value.summary?.languages?.length && value.summary.languages.length > 0) {
    isLanguagesCompleted = true;
    enCVCurrentProgressStep += 1;
    jaCVCurrentProgressStep += 1;
  }

  if (value.summary?.skills?.length && value.summary.skills.length > 0) {
    isSkillsCompleted = true;
    enCVCurrentProgressStep += 1;
    jaCVCurrentProgressStep += 1;
  }

  if (
    value.cv?.no_job_experience ||
    (value.cv?.job_experience?.length && value.cv.job_experience.length > 0)
  ) {
    isEnJobExperienceDetailsCompleted = true;
    isJaJobExperienceDetailsCompleted = true;
    enCVCurrentProgressStep += 1;
    jaCVCurrentProgressStep += 1;
  } else {
    if (isEnCVFileUploaded) {
      enCVCurrentProgressStep += 1;
    }
    if (isJaCVFileUploaded) {
      jaCVCurrentProgressStep += 1;
    }
  }

  if (value.cv?.education?.length && value.cv.education.length > 0) {
    isEnEducationCompleted = true;
    isJaEducationCompleted = true;
    enCVCurrentProgressStep += 1;
    jaCVCurrentProgressStep += 1;
  } else {
    if (isEnCVFileUploaded) {
      enCVCurrentProgressStep += 1;
    }
    if (isJaCVFileUploaded) {
      jaCVCurrentProgressStep += 1;
    }
  }

  if (
    isJaBasicInformationCompleted &&
    isJobExperienceOverviewCompleted &&
    isLanguagesCompleted &&
    isSkillsCompleted
  ) {
    if (isJaJobExperienceDetailsCompleted && isJaEducationCompleted) {
      jaRirekishoCurrentProgressStep += 1;
    } else if (
      value.ja_rirekisho?.file_information?.size &&
      value.ja_rirekisho.file_information.size > 0
    ) {
      jaRirekishoCurrentProgressStep += 1;
    }
  }

  return {
    enCVCurrentProgressStep,
    jaCVCurrentProgressStep,
    jaRirekishoCurrentProgressStep,
    isJaBasicInformationCompleted,
    isEnBasicInformationCompleted,
    isJobExperienceOverviewCompleted,
    isLanguagesCompleted,
    isSkillsCompleted,
    isEnJobExperienceDetailsCompleted,
    isEnEducationCompleted,
    isJaJobExperienceDetailsCompleted,
    isJaEducationCompleted,
    enCVTotalProgressSteps,
    jaCVTotalProgressSteps,
    jaRirekishoTotalProgressSteps
  };
};

export default UserProfileContext;
