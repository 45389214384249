import { useState } from "react";

import { useLocation, useSearchParams } from "react-router-dom";

import { CloudSync as CloudSyncIcon } from "@mui/icons-material";
import {
  Input,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import {
  useAuthState,
  useCreateUserWithEmailAndPassword,
  useSendEmailVerification,
  useSignInWithEmailAndPassword,
  useSignOut
  // useUpdateProfile
} from "react-firebase-hooks/auth";

import Button from "@components/Button";
import Loader from "@components/Loader";
import Tag from "@components/Tag";
import Typography from "@components/Typography";
import Well from "@components/Well";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import { USER_TYPE } from "@utils/config";
import { auth, functions } from "@utils/firebase";
import translate, { intl } from "@utils/translate";

const Login = () => {
  const [user, loading] = useAuthState(auth);
  const [signOut] = useSignOut(auth);
  const [signInWithEmailAndPassword, , , isSignInError] =
    useSignInWithEmailAndPassword(auth);
  const [createUserWithEmailAndPassword, , , isRegisterError] =
    useCreateUserWithEmailAndPassword(auth);
  const [sendEmailVerification, , sendEmailVerificationError] =
    useSendEmailVerification(auth);

  // FIXME: Add Update profile along with it's listeners.
  // const [updateProfile, updating, error] = useUpdateProfile(auth);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userProfile = useUserProfile();
  const toast = useToast();
  const location = useLocation();
  const pathname = translate.getNonLocalizedURL(location.pathname);

  const isEnResumePresent =
    userProfile?.value?.metadata?.progress_flags?.en_cv ?? false;
  const isJaResumePresent =
    userProfile?.value?.metadata?.progress_flags?.ja_cv ?? false;
  const isJaRirekishoPresent =
    userProfile?.value?.metadata?.progress_flags?.ja_rirekisho ?? false;
  const isProfileComplete =
    (isEnResumePresent || isJaResumePresent || isJaRirekishoPresent) &&
    user?.emailVerified;

  const registerUser = (is_dummy = false) => {
    const email = prompt(intl.get("t_debugger_email_prompt"));
    const password = prompt(intl.get("t_debugger_password_prompt"));
    if (email && password) {
      if (is_dummy) {
        searchParams.set("is_dummy", "1");
        setSearchParams(searchParams.toString(), { replace: true });
      }
      createUserWithEmailAndPassword(email, password);
    } else {
      alert(intl.get("t_debugger_missing_email_password_alert"));
    }
  };
  const signin = () => {
    const email = prompt(intl.get("t_debugger_email_prompt"));
    const password = prompt(intl.get("t_debugger_password_prompt"));
    if (email && password) {
      signInWithEmailAndPassword(email, password);
    } else {
      alert(intl.get("t_debugger_missing_email_password_alert"));
    }
  };

  const getUserTypeLabel = (
    userType: typeof USER_TYPE[keyof typeof USER_TYPE] = USER_TYPE.NO_TYPE
  ) => {
    switch (userType) {
      case USER_TYPE.INDIVIDUAL:
        return "Candidate";
      case USER_TYPE.COMPANY:
        return "Company Owner";
      case USER_TYPE.COMPANY_MANAGER:
        return "Company Manager";
      case USER_TYPE.OEM:
        return "OEM";
      case USER_TYPE.SUPER_USER:
        return "Tokhimo Internal User";
      case USER_TYPE.NO_TYPE:
      default:
        return "No Type";
    }
  };

  const syncCandidateWithTypesense = async (candidateId: string) => {
    try {
      setIsLoading(true);
      const syncFunction = httpsCallable(
        functions,
        "syncCandidateWithTypesense"
      );
      const { data } = await syncFunction(candidateId);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const typedData = data as any;
      if (typedData.success) {
        toast.kampai("Sync Successful", "success");
      } else {
        toast.kampai(
          "Sync Failed, Maybe the Candidate has not completed the profile ",
          "error"
        );
      }
    } catch (error) {
      toast.kampai("Sync Failed", "error");
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? <Loader /> : false}
      <Typography variant="h5">
        {intl.get("t_debugger_login_information")}:
      </Typography>
      <br />
      {loading ? (
        <Loader />
      ) : (
        <>
          {isSignInError ? (
            <Typography color="error.main">
              {intl.get("t_debugger_login_error_message")}
            </Typography>
          ) : (
            false
          )}
          {isRegisterError ? (
            <Typography color="error.main">
              {intl.get("t_debugger_registration_error_message")}
            </Typography>
          ) : (
            false
          )}
          {sendEmailVerificationError ? (
            <Typography color="error.main">
              Cannot send verification email.
            </Typography>
          ) : (
            false
          )}
          <Typography> {intl.get("t_general_status")}: </Typography>
          {user ? (
            <Tag label={intl.get("t_debugger_loggedin_status_logged_in")} />
          ) : (
            <Tag label={intl.get("t_debugger_loggedin_status_not_logged_in")} />
          )}
          <br />
          <br />
          {user ? (
            <TableContainer component={Well}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Property</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Update?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>User Type</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {getUserTypeLabel(userProfile.value?.user_type)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button disabled>Save</Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Display Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Input defaultValue={user.displayName} />
                    </TableCell>
                    <TableCell>
                      <Button>Save</Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Email</Typography>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <Button disabled>Save</Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Email Verified?</Typography>
                    </TableCell>
                    <TableCell>{user.emailVerified ? "✔️" : "❌"}</TableCell>
                    <TableCell>
                      {user.emailVerified ? (
                        <Button disabled>
                          {intl.get("t_debugger_email_verified")}
                        </Button>
                      ) : (
                        <Button handleClick={() => sendEmailVerification()}>
                          Send Verification Email
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Profile Picture</Typography>
                    </TableCell>
                    <TableCell>
                      <img
                        src={user.photoURL ?? ""}
                        alt={intl.get("t_alt_user_profile_photo")}
                        height={100}
                      />
                    </TableCell>
                    <TableCell>
                      <Button>Upload Photo</Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Password</Typography>
                    </TableCell>
                    <TableCell>
                      <Input />
                    </TableCell>
                    <TableCell>
                      <Button>Save</Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Created At</Typography>
                    </TableCell>
                    <TableCell>{user.metadata?.creationTime}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Last Login</Typography>
                    </TableCell>
                    <TableCell>{user.metadata?.lastSignInTime}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>User ID</Typography>
                    </TableCell>
                    <TableCell>{user.uid}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {userProfile.value?.user_type === USER_TYPE.COMPANY ? (
                    <TableRow>
                      <TableCell>
                        <Typography>Company ID</Typography>
                      </TableCell>
                      <TableCell>{userProfile.value?.company_id}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ) : (
                    false
                  )}
                  {userProfile.value?.user_type === USER_TYPE.INDIVIDUAL ? (
                    <TableRow>
                      <TableCell>
                        <Typography>Sync User</Typography>
                      </TableCell>
                      <TableCell>
                        {isProfileComplete ? (
                          <Button
                            size="small"
                            color="secondary"
                            handleClick={() =>
                              syncCandidateWithTypesense(user.uid)
                            }
                            endAdornment={<CloudSyncIcon />}>
                            Sync
                          </Button>
                        ) : (
                          <Tooltip title="Please complete you profile to enable sync option">
                            <span>
                              <Button
                                size="small"
                                disabled
                                color="secondary"
                                endAdornment={
                                  <CloudSyncIcon color="disabled" />
                                }>
                                Sync
                              </Button>
                            </span>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ) : (
                    false
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            false
          )}
          <br />
          <Typography>Action:</Typography>
          <Stack direction="row" gap={2}>
            {user ? (
              <>
                <Button color="secondary" handleClick={() => signOut()}>
                  {intl.get("t_general_logout")}
                </Button>
              </>
            ) : (
              <>
                <Button handleClick={() => signin()}>
                  {intl.get("t_general_login")}
                </Button>
              </>
            )}
            <Button data-testid="sign_in_forgot_password_button">
              {intl.get("t_signin_forgot_password")}
            </Button>
          </Stack>
          <br />
          <Stack direction="row" gap={2} flexWrap="wrap">
            {pathname.startsWith("/employer") ? (
              <>
                <Button handleClick={() => registerUser(false)}>
                  {intl.get("t_login_create_new_account")} (Company)
                </Button>
                <Button handleClick={() => registerUser(true)}>
                  {intl.get("t_login_create_new_account")} (Company with Dummy
                  Data)
                </Button>
              </>
            ) : (
              <>
                <Button handleClick={() => registerUser(false)}>
                  {intl.get("t_login_create_new_account")} (Candidate)
                </Button>
                <Button handleClick={() => registerUser(true)}>
                  {intl.get("t_login_create_new_account")} (Candidate with Dummy
                  Data)
                </Button>
              </>
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export default Login;
