import { ReactIntlUniversal } from "react-intl-universal";

import { DialogActions, DialogContent, Stack } from "@mui/material";

import ActionButton from "@components/ActionButton";
import Button from "@components/Button";
import Dialog from "@components/Dialog";
import InformationField from "@components/InformationField";
import Typography from "@components/Typography";
import Well from "@components/Well";

import {
  DIALOG_ACTION,
  YEARS_OF_EXPERIENCE,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import { intl as intlGlobal } from "@utils/translate";

interface JobExperienceOverviewCardProps {
  jobTitle: string;
  yearsOfExperience: typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE];
  handleEdit?: () => void;
  handleDelete?: () => void;
  mode?: "edit" | "view";
  intl?: typeof intlGlobal | ReactIntlUniversal;
}

const JobExperienceOverviewCard = ({
  jobTitle,
  yearsOfExperience,
  handleEdit,
  handleDelete,
  mode = "view",
  intl = intlGlobal
}: JobExperienceOverviewCardProps) => {
  const handleClose = (reason: keyof typeof DIALOG_ACTION) => {
    if (reason === DIALOG_ACTION.AGREE && handleDelete) {
      handleDelete();
    }
  };

  return (
    <Well showBorder={mode === "edit" ? true : false}>
      {/* Job Title */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height={40}
        width="100%">
        {/* FIXME: Add logic to handle en or ja */}
        <InformationField icon="work" label={jobTitle} variant="subtitle4" />

        {/* Actions */}
        {mode === "edit" ? (
          <Stack direction="row" alignItems="flex-start">
            <ActionButton icon="edit" color="blue" handleAction={handleEdit} />
            <Dialog
              title={intlGlobal.get("t_resumes_remove_job_overview")}
              maxWidth="sm"
              initiator={<ActionButton icon="delete" color="grey" />}
              onClose={handleClose}>
              {(handleAgree, handleCancel) => (
                <>
                  <DialogContent sx={{ py: 1 }}>
                    <Typography color="text.secondary">
                      {intlGlobal.get("t_resumes_dialog_delete_title", {
                        entry: intlGlobal.get("t_resumes_job_overview_title")
                      })}
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button handleClick={handleCancel} variant="outlined">
                      {intlGlobal.get("t_resumes_dialog_delete_cancel_button")}
                    </Button>
                    <Button handleClick={handleAgree}>
                      {intlGlobal.get("t_resumes_dialog_delete_agree_button")}
                    </Button>
                  </DialogActions>
                </>
              )}
            </Dialog>
          </Stack>
        ) : (
          false
        )}
      </Stack>

      {/* Years of Experience */}
      <InformationField
        icon="work_history"
        label={intl.get(YEARS_OF_EXPERIENCE_T_LABELS[yearsOfExperience])}
      />
    </Well>
  );
};

export default JobExperienceOverviewCard;
