import { useEffect, useState } from "react";

import { useForm, useWatch } from "react-hook-form";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams
} from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Help as HelpIcon,
  NavigateNext as NavigateNextIcon,
  Search as SearchIcon
} from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import deepEqual from "deep-equal";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where
} from "firebase/firestore";
import { ref } from "firebase/storage";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useUploadFile } from "react-firebase-hooks/storage";
import * as yup from "yup";

import CompanyInfoForm from "@pages/EmployersPostJob/CompanyInfoForm";

import SkeletonCompanyInformationForm from "@skeletons/EmployersPostJob/SkeletonCompanyInformationForm";
import SkeletonButton from "@skeletons/SkeletonButton";

import Avatar from "@components/Avatar";
import Button from "@components/Button";
import Paper from "@components/Paper";
import Radio from "@components/Radio";
import TypeableSelect from "@components/TypeableSelect";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import KeyLabel from "@interfaces/components/KeyLabel";
import CompanyProfile from "@interfaces/database/CompanyProfile";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import MultiLingual from "@interfaces/database/MultiLingual";

import {
  COMPANY_LOGO_UPLOAD_CONFIG,
  COMPANY_STATUS,
  FILES_LOCATION_COMPANY_LOGO,
  FILES_LOCATION_JOB_ADDITIONAL_FILE,
  FIRESTORE_COLLECTIONS,
  JOB_POSTING_STATUS,
  JOB_POSTING_TYPE,
  LOCALE,
  TOTAL_JOB_POSTING_STEPS
} from "@utils/config";
import { db, functions, storage } from "@utils/firebase";
import getFileMetaInformation from "@utils/getFileMetaInformation";
import { getFileURL } from "@utils/getFileURL";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface CompanyInformationFormData {
  companyName: string;
  companyWebsiteURL: string;
}

const CompanyInformation = () => {
  const [searchParams] = useSearchParams();
  const copyFromJobId = searchParams.get("copy_from_job_id") ?? "";
  const { job_id: jobId } = useParams();
  const navigate = useNavigate();
  const {
    jobData,
    handleSetJobData
  }: {
    jobData: JobProfile;
    handleSetJobData: (
      jobId: JobID,
      jobData: JobProfile,
      handleJobUpdateSuccess: (jobId: string) => void,
      handleJobUpdateFail: () => void
    ) => void;
  } = useOutletContext();
  const [uploadFile] = useUploadFile();
  const [copyFileData] = useHttpsCallable(functions, "copyFile");
  const companyDetails = useCompanyDetails();
  const userData = useUserProfile();
  const toast = useToast();

  const companyId = userData?.value?.company_id;
  const isCompanyDetailsExist =
    companyDetails?.value?.website_url &&
    typeof resolveMultiLingual(companyDetails?.value?.name) !== undefined
      ? true
      : false;
  const imageURL = companyDetails?.value?.logo
    ? getFileURL(
        FILES_LOCATION_COMPANY_LOGO,
        companyId,
        companyDetails?.value?.logo?.extension,
        companyDetails?.value?.logo?.uploaded_at
      )
    : "";

  const [companyLogoUrl, setCompanyLogoUrl] = useState<string>(imageURL);
  const [companyLogoFile, setCompanyLogoFile] = useState<File>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [hiringForClientCompanyData, setHiringForClientCompanyData] =
    useState<CompanyInformationFormData>({
      companyName: "",
      companyWebsiteURL: ""
    });
  const [formInitValues, setFormInitValues] =
    useState<CompanyInformationFormData>();
  const [isCompanyDetailsLoading, setIsCompanyDetailsLoading] =
    useState<boolean>(true);
  const [jobDataCopy, setJobDataCopy] = useState<JobProfile>();
  const [clientCompanyData, setClientCompanyData] = useState<CompanyProfile>();
  const [isClientCompanyDataLoading, setIsClientCompanyDataLoading] =
    useState<boolean>(true);
  const [isClientCompanyListLoading, setIsClientCompanyListLoading] =
    useState<boolean>(true);
  const [clientCompanyId, setClientCompanyId] = useState<string>("");

  const [clientCompanyList, setClientCompanyList] = useState<
    Record<string, CompanyProfile>
  >({});
  const [clientCompanySearchOptions, setClientCompanySearchOptions] = useState<
    Array<KeyLabel>
  >([]);
  const clientCompanySelectNewCompanyOption: KeyLabel = {
    key: "new",
    label: intl.get(
      "t_job_post_company_information_step_client_company_selector_new_company"
    )
  };

  const clientCompanyListSearchSchema = yup.object({
    clientCompany: yup.object().shape({
      key: yup.string(),
      label: yup.string()
    })
  });

  //Client Company Search form
  const clientCompanyListSearchMethods = useForm({
    defaultValues: {
      clientCompany: clientCompanySelectNewCompanyOption
    },
    reValidateMode: "onChange",
    resolver: yupResolver(clientCompanyListSearchSchema)
  });
  const {
    control: clientCompanyListSearchControl,
    watch: clientCompanyListSearchWatcher,
    reset: clientCompanyListSearchReset
  } = clientCompanyListSearchMethods;

  useEffect(() => {
    const companyNameList: Array<KeyLabel> = [];
    companyNameList.push(clientCompanySelectNewCompanyOption);
    Object.keys(clientCompanyList).forEach((key) => {
      companyNameList.push({
        key: key,
        label: resolveMultiLingual(clientCompanyList[key]?.name) ?? ""
      });
    });
    setClientCompanySearchOptions(companyNameList);
  }, [clientCompanyList]);

  // for copy job - en job title and ja job title
  const enJobTitle = resolveMultiLingual(jobDataCopy?.job_title, LOCALE.EN);
  const jaJobTitle = resolveMultiLingual(jobDataCopy?.job_title, LOCALE.JA);

  const clientCompanyListSearchValue: KeyLabel | null =
    clientCompanyListSearchWatcher("clientCompany");

  useEffect(() => {
    setIsCompanyDetailsLoading(
      companyDetails?.loading ||
        isClientCompanyDataLoading ||
        isClientCompanyListLoading
    );
  }, [companyDetails, isClientCompanyDataLoading, isClientCompanyListLoading]);

  // validation schema
  const schema = yup.object({
    hiringFor: yup.string().required() //FIXME: Add message for required.
  });

  const hiringForRadioInitValues: {
    hiringFor: typeof JOB_POSTING_TYPE.SELF | typeof JOB_POSTING_TYPE.CLIENT;
  } = {
    hiringFor:
      jobData?.company_id && jobData?.client_company_id
        ? JOB_POSTING_TYPE.CLIENT
        : JOB_POSTING_TYPE.SELF
  };
  const methods = useForm({
    defaultValues: hiringForRadioInitValues,
    resolver: yupResolver(schema)
  });

  const { control, reset } = methods;
  const hiringForWatcher = useWatch({
    control,
    name: "hiringFor"
  });
  const [hiringFor, setHiringFor] = useState<
    typeof JOB_POSTING_TYPE[keyof typeof JOB_POSTING_TYPE]
  >(JOB_POSTING_TYPE.SELF);

  useEffect(() => {
    setHiringFor(hiringForWatcher);
  }, [hiringForWatcher]);

  useEffect(() => {
    if (isCompanyDetailsLoading) {
      return;
    }
    if (hiringFor === JOB_POSTING_TYPE.CLIENT) {
      if (
        clientCompanyListSearchValue &&
        clientCompanyListSearchValue.key &&
        clientCompanyListSearchValue.key !== "" &&
        clientCompanyListSearchValue.key !== "new"
      ) {
        const selectedClientCompanyData =
          clientCompanyList[clientCompanyListSearchValue.key];
        if (selectedClientCompanyData) {
          const imageURL = getFileURL(
            FILES_LOCATION_COMPANY_LOGO,
            clientCompanyListSearchValue.key,
            selectedClientCompanyData?.logo?.extension,
            selectedClientCompanyData?.logo?.uploaded_at
          );
          if (imageURL) {
            setCompanyLogoUrl(imageURL);
          }
          setHiringForClientCompanyData({
            companyName:
              resolveMultiLingual(selectedClientCompanyData?.name) ?? "",
            companyWebsiteURL: selectedClientCompanyData?.website_url ?? ""
          });
          setClientCompanyData(selectedClientCompanyData);
          setFormInitValues({
            companyName:
              resolveMultiLingual(selectedClientCompanyData?.name) ?? "",
            companyWebsiteURL: selectedClientCompanyData?.website_url ?? ""
          });
          setClientCompanyId(clientCompanyListSearchValue.key);
        } else {
          setCompanyLogoUrl("");
          setHiringForClientCompanyData({
            companyName: "",
            companyWebsiteURL: ""
          });
          setClientCompanyData(undefined);
          setFormInitValues({
            companyName: "",
            companyWebsiteURL: ""
          });
          setClientCompanyId("");
        }
      } else {
        setCompanyLogoUrl("");
        setHiringForClientCompanyData({
          companyName: "",
          companyWebsiteURL: ""
        });
        setClientCompanyData(undefined);
        setFormInitValues({
          companyName: "",
          companyWebsiteURL: ""
        });
        setClientCompanyId("");
      }
      setCompanyLogoFile(undefined);
    }
  }, [clientCompanyListSearchValue, hiringFor]);

  useEffect(() => {
    // copy job data from another job and set form data
    if (copyFromJobId && jobId === "new") {
      const getJobData = async () => {
        const jobDocRef = doc(db, FIRESTORE_COLLECTIONS.JOBS, copyFromJobId);
        const jobDoc = await getDoc(jobDocRef);
        if (jobDoc.exists()) {
          const jobData = jobDoc.data() as JobProfile;
          setJobDataCopy(jobData);
          if (jobData) {
            if (jobData?.company_id && jobData?.client_company_id) {
              const companiesDocRef = doc(
                db,
                FIRESTORE_COLLECTIONS.COMPANIES,
                jobData?.client_company_id
              );
              const companiesDoc = await getDoc(companiesDocRef);
              if (companiesDoc.exists()) {
                const imageURL = getFileURL(
                  FILES_LOCATION_COMPANY_LOGO,
                  jobData?.client_company_id,
                  companiesDoc.data()?.logo?.extension,
                  companiesDoc.data()?.logo?.uploaded_at
                );
                if (imageURL) {
                  setCompanyLogoUrl(imageURL);
                }
                setHiringForClientCompanyData({
                  companyName:
                    resolveMultiLingual(
                      companiesDoc.data()?.name as MultiLingual<string>
                    ) ?? "",
                  companyWebsiteURL: companiesDoc.data()?.website_url ?? ""
                });
                setClientCompanyData(companiesDoc.data() as CompanyProfile);
                reset({
                  hiringFor: JOB_POSTING_TYPE.CLIENT
                });
                clientCompanyListSearchReset({
                  clientCompany: {
                    key: companiesDoc.id,
                    label:
                      resolveMultiLingual(
                        companiesDoc.data()?.name as MultiLingual<string>
                      ) ?? ""
                  }
                });
                setClientCompanyId(jobData?.client_company_id);
                setHiringFor(JOB_POSTING_TYPE.CLIENT);
              }
            }
          }
        } else {
          navigate(
            `/${translate.getCurrentLocale()}/employers/jobs/new/company-information`
          );
        }
        setIsClientCompanyDataLoading(false);
      };
      getJobData();
    }
  }, []);

  useEffect(() => {
    // if job found then reset the form and fetch company details
    reset(hiringForRadioInitValues);
    if (jobId && jobId !== "new") {
      const getHiringForClientInitialData = async () => {
        if (jobData) {
          if (jobData?.company_id && jobData?.client_company_id) {
            const companiesDocRef = doc(
              db,
              FIRESTORE_COLLECTIONS.COMPANIES,
              jobData?.client_company_id
            );
            const companiesDoc = await getDoc(companiesDocRef);
            if (companiesDoc.exists()) {
              const companyData = companiesDoc.data() as CompanyProfile;
              const imageURL = getFileURL(
                FILES_LOCATION_COMPANY_LOGO,
                jobData?.client_company_id,
                companyData?.logo?.extension,
                companyData?.logo?.uploaded_at
              );

              if (imageURL) {
                setCompanyLogoUrl(imageURL);
              }
              setHiringForClientCompanyData({
                companyName:
                  resolveMultiLingual(
                    companyData.name as MultiLingual<string>
                  ) ?? "",
                companyWebsiteURL: companyData.website_url ?? ""
              });
              setClientCompanyData(companyData);
              clientCompanyListSearchReset({
                clientCompany: {
                  key: companiesDoc.id,
                  label:
                    resolveMultiLingual(
                      companyData.name as MultiLingual<string>
                    ) ?? ""
                }
              });
              setClientCompanyId(companiesDoc.id);
            }
          }
          setIsClientCompanyDataLoading(false);
        }
      };
      getHiringForClientInitialData();
    } else if (jobId === "new") {
      setIsClientCompanyDataLoading(false);
    }
  }, [jobData?.company_id, jobData?.client_company_id]);

  useEffect(() => {
    if (!isCompanyDetailsLoading) {
      if (
        hiringFor === (JOB_POSTING_TYPE.SELF as typeof hiringFor) &&
        imageURL
      ) {
        setCompanyLogoUrl(imageURL);
      } else if (hiringFor === (JOB_POSTING_TYPE.CLIENT as typeof hiringFor)) {
        const baseJobData = jobData ?? jobDataCopy;
        if (baseJobData && baseJobData.client_company_id) {
          const imageURL = companyDetails?.value?.logo
            ? getFileURL(
                FILES_LOCATION_COMPANY_LOGO,
                baseJobData.client_company_id,
                clientCompanyData?.logo?.extension,
                clientCompanyData?.logo?.uploaded_at
              )
            : "";
          if (imageURL) {
            setCompanyLogoUrl(imageURL);
          } else {
            setCompanyLogoUrl("");
          }
        } else {
          setCompanyLogoUrl("");
        }
      }

      setCompanyLogoFile(undefined);

      setFormInitValues(
        hiringFor === JOB_POSTING_TYPE.SELF
          ? {
              companyName:
                resolveMultiLingual(companyDetails.value?.name) ?? "",
              companyWebsiteURL: companyDetails.value?.website_url ?? ""
            }
          : hiringForClientCompanyData
      );
    }
  }, [isCompanyDetailsLoading, hiringFor]);

  const handleJobUpdateSuccess = (updatedJobId: string) => {
    setIsDisabled(false);
    navigate(
      `/${translate.getCurrentLocale()}/employers/jobs/${updatedJobId}/job-information`
    );
  };

  const handleJobUpdateFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleCompanyUpdateSuccess = async () => {
    const currentTime = Timestamp.now();
    try {
      if (companyId && jobId && companyDetails?.value) {
        if (jobData?.client_company_id) {
          delete jobData.client_company_id;
        }

        const initialJobData = {
          company_id: companyId,
          client_company_id: "",
          status: JOB_POSTING_STATUS.DRAFT,
          posting_type: JOB_POSTING_TYPE.SELF,
          created_at: currentTime,
          updated_at: currentTime
        };

        // update the time data while copying job data from other job data
        if (jobId === "new" && copyFromJobId && jobDataCopy) {
          jobDataCopy.created_at = currentTime;
          jobDataCopy.updated_at = currentTime;
          jobDataCopy.job_title = {
            en: intl.get("t_general_copy_of", { title: enJobTitle }),
            ja: intl.get("t_general_copy_of", { title: jaJobTitle })
          };
          if (jobDataCopy?.additional_file) {
            jobDataCopy.additional_file.uploaded_at = currentTime;
            if (jobDataCopy?.additional_file) {
              jobDataCopy.additional_file.uploaded_at = currentTime;
            }
            if (jobDataCopy?.additional_file) {
              jobDataCopy.additional_file.uploaded_at = currentTime;
            }
          }
        }

        const updatedJobData =
          jobId === "new" && copyFromJobId && jobDataCopy // copy job data condition
            ? jobDataCopy
            : jobId !== "new" && !copyFromJobId // edit job data condition
            ? jobData
            : initialJobData; // create new job data

        if (
          hiringFor === JOB_POSTING_TYPE.SELF &&
          updatedJobData.client_company_id !== undefined
        ) {
          delete updatedJobData.client_company_id;
        }

        handleSetJobData(
          jobId,
          updatedJobData,
          (updatedJobId) => {
            if (
              updatedJobId &&
              jobId === "new" &&
              copyFromJobId &&
              jobDataCopy
            ) {
              const sourceFilePath = `${FILES_LOCATION_JOB_ADDITIONAL_FILE}/${copyFromJobId}.${jobDataCopy.additional_file?.extension}`;
              const destinationFilePath = `${FILES_LOCATION_JOB_ADDITIONAL_FILE}/${updatedJobId}.${jobDataCopy.additional_file?.extension}`;
              copyFileData({
                sourceFilePath,
                destinationFilePath
              });
            }
            handleJobUpdateSuccess(updatedJobId);
          },
          handleJobUpdateFail
        );
      }
    } catch (e) {
      setIsDisabled(false);
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  const handleCompanyUpdateFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleHiringForMySelfFormSubmit = async (
    formData: CompanyInformationFormData
  ) => {
    setIsDisabled(true);
    const currentTime = Timestamp.now();
    const { companyName, companyWebsiteURL } = formData || {};
    let companyLogoMetadata;
    try {
      if (companyLogoFile) {
        const { fileExt, fileName, fileSize, fileType } =
          getFileMetaInformation(companyLogoFile);
        companyLogoMetadata = {
          name: fileName,
          extension: fileExt,
          size: fileSize,
          uploaded_at: currentTime
        };

        if (companyId) {
          const path = `${FILES_LOCATION_COMPANY_LOGO}/${companyId}.${fileExt}`;
          const storageRef = ref(storage, path);

          await uploadFile(storageRef, companyLogoFile, {
            contentType: fileType,
            customMetadata: {
              v: currentTime.toDate().getTime().toString()
            }
          });
        }
      }

      if (companyDetails.setValue && companyDetails.value) {
        if (companyDetails?.value?.jobs) {
          // delete jobs array and save company information in db
          delete companyDetails.value.jobs;
        }

        if (
          hiringFor === JOB_POSTING_TYPE.SELF &&
          jobData &&
          jobData.client_company_id
        ) {
          delete jobData.client_company_id;
        }
        if (
          hiringFor === JOB_POSTING_TYPE.SELF &&
          jobDataCopy &&
          jobDataCopy.client_company_id
        ) {
          delete jobDataCopy.client_company_id;
        }

        const isFormValueChanged = !deepEqual(formData, formInitValues);

        if (
          isFormValueChanged ||
          companyLogoFile ||
          jobId === "new" ||
          copyFromJobId // for copy job
        ) {
          await companyDetails.setValue(
            {
              ...companyDetails.value,
              name: prepareMultiLingual(
                companyName,
                undefined,
                companyDetails.value.name
              ),
              website_url: companyWebsiteURL,
              ...(companyLogoFile ? { logo: companyLogoMetadata } : {})
            },
            handleCompanyUpdateSuccess,
            handleCompanyUpdateFail
          );
        } else if (jobId) {
          await handleCompanyUpdateSuccess();
        }
      }
    } catch (e) {
      handleCompanyUpdateFail();
    }
  };

  const handleHiringForClientFormSubmit = async (
    formData: CompanyInformationFormData
  ) => {
    setIsDisabled(true);
    const currentTime = Timestamp.now();
    const { companyName, companyWebsiteURL } = formData || {};
    try {
      // Ideally should never happen. But just in case for Typesafety.
      if (!companyId) {
        setIsDisabled(false);
        toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
        return;
      }

      let clientCompanyProfile = clientCompanyData;
      let companyLogoMetadata;

      if (companyLogoFile) {
        const { fileExt, fileName, fileSize } =
          getFileMetaInformation(companyLogoFile);
        companyLogoMetadata = {
          name: fileName,
          extension: fileExt,
          size: fileSize,
          uploaded_at: currentTime
        };
      }

      if (clientCompanyProfile) {
        clientCompanyProfile.name = prepareMultiLingual(
          companyName,
          translate.getCurrentLocale(),
          clientCompanyData?.name
        );
        clientCompanyProfile.website_url = companyWebsiteURL;
        if (companyLogoFile) {
          clientCompanyProfile.logo = companyLogoMetadata;
        }
      }

      let newClientCompanyId = clientCompanyId;

      if (
        !clientCompanyId ||
        clientCompanyId == "" ||
        clientCompanyId == "undefined" ||
        typeof clientCompanyId == "undefined"
      ) {
        newClientCompanyId = doc(
          collection(db, `${FIRESTORE_COLLECTIONS.COMPANIES}`)
        ).id;
        setClientCompanyId(newClientCompanyId);

        clientCompanyProfile = {
          status: COMPANY_STATUS.OK,
          name: prepareMultiLingual(companyName),
          website_url: companyWebsiteURL,
          ...(companyLogoFile ? { logo: companyLogoMetadata } : {}),
          managers: {},
          // TBD - Spec pending by Aayush.
          owner: {
            user_id: "",
            email: ""
          },
          is_client_company: true,
          parent_company_id: companyId,
          created_at: currentTime,
          updated_at: currentTime,
          last_login_at: currentTime
        };
      }

      // update the time data while copying job data from other job data
      if (jobId === "new" && copyFromJobId && jobDataCopy) {
        jobDataCopy.job_title = {
          en: intl.get("t_general_copy_of", { title: enJobTitle }),
          ja: intl.get("t_general_copy_of", { title: jaJobTitle })
        };
        jobDataCopy.created_at = currentTime;
        jobDataCopy.updated_at = currentTime;
        if (jobDataCopy?.additional_file) {
          jobDataCopy.additional_file.uploaded_at = currentTime;
        }
      }

      // update the existing company logo data
      const isFormValueChanged = !deepEqual(formData, formInitValues);
      if (
        jobId === "new" ||
        isFormValueChanged ||
        companyLogoFile ||
        jobData.client_company_id !== newClientCompanyId
      ) {
        const companyDocumentReference = doc(
          db,
          `${FIRESTORE_COLLECTIONS.COMPANIES}/${newClientCompanyId}`
        );
        await setDoc(companyDocumentReference, clientCompanyProfile);
      }

      if (companyLogoFile && companyLogoMetadata) {
        const { fileType } = getFileMetaInformation(companyLogoFile);
        const path = `${FILES_LOCATION_COMPANY_LOGO}/${newClientCompanyId}.${companyLogoMetadata.extension}`;
        const storageRef = ref(storage, path);

        await uploadFile(storageRef, companyLogoFile, {
          contentType: fileType,
          customMetadata: {
            v: currentTime.toDate().getTime().toString()
          }
        });
        // set the logo once it is uploaded
        const imageURL = getFileURL(
          FILES_LOCATION_COMPANY_LOGO,
          newClientCompanyId,
          companyLogoMetadata.extension,
          companyLogoMetadata.uploaded_at
        );

        if (imageURL) {
          setCompanyLogoUrl(imageURL);
        }
      }
      const jobsData: JobProfile = {
        // if copyFromJobId is Present, then append that job data
        ...(copyFromJobId ? jobDataCopy : jobData ?? {}),
        status: JOB_POSTING_STATUS.DRAFT,
        posting_type: JOB_POSTING_TYPE.CLIENT,
        company_id: companyId,
        client_company_id: newClientCompanyId,
        created_at: currentTime,
        updated_at: currentTime
      };

      if (jobId === "new") {
        handleSetJobData(
          jobId,
          jobsData,
          (newJobId) => {
            if (newJobId && jobId === "new" && copyFromJobId && jobDataCopy) {
              const sourceFilePath = `${FILES_LOCATION_JOB_ADDITIONAL_FILE}/${copyFromJobId}.${jobDataCopy.additional_file?.extension}`;
              const destinationFilePath = `${FILES_LOCATION_JOB_ADDITIONAL_FILE}/${newJobId}.${jobDataCopy.additional_file?.extension}`;
              copyFileData({
                sourceFilePath,
                destinationFilePath
              });
            }
            handleJobUpdateSuccess(newJobId);
          },
          handleJobUpdateFail
        );
      } else if (jobId && hiringFor === JOB_POSTING_TYPE.CLIENT) {
        if (
          isFormValueChanged ||
          companyLogoFile ||
          jobData.client_company_id !== newClientCompanyId
        ) {
          handleSetJobData(
            jobId,
            jobsData,
            handleJobUpdateSuccess,
            handleJobUpdateFail
          );
        } else if (jobId) {
          handleJobUpdateSuccess(jobId);
        }
      } else if (jobId) {
        handleJobUpdateSuccess(jobId);
      }
    } catch (e) {
      handleCompanyUpdateFail();
    }
  };

  const handleCompanyLogoUpload = (imageFile: File) => {
    const companyLogo = URL.createObjectURL(imageFile);
    setCompanyLogoFile(imageFile);
    if (companyLogo) {
      setCompanyLogoUrl(companyLogo);
    }
  };

  const getClientCompanyList = async () => {
    if (companyId) {
      const companiesCollectionRef = collection(
        db,
        FIRESTORE_COLLECTIONS.COMPANIES
      );
      const childCompanyQuery = query(
        companiesCollectionRef,
        where("parent_company_id", "==", companyId),
        where("is_client_company", "==", true)
      );
      const childCompanyQuerySnapshot = await getDocs(childCompanyQuery);
      const clientCompanyDetails: Record<string, CompanyProfile> = {};
      childCompanyQuerySnapshot.forEach((singleClientCompanyDocument) => {
        clientCompanyDetails[singleClientCompanyDocument.id as string] =
          singleClientCompanyDocument.data() as CompanyProfile;
      });
      setClientCompanyList(clientCompanyDetails);
    }
    setIsClientCompanyListLoading(false);
  };

  useEffect(() => {
    getClientCompanyList();
  }, [companyId]);

  return (
    <>
      <Paper>
        <Stack direction={{ xs: "column", md: "row" }} gap={{ md: 10 }}>
          <Stack width={{ xs: "100%", md: "40%" }}>
            <Typography
              variant="subtitle2"
              mb={{ xs: 3, md: 8 }}
              color="secondary.main">
              {intl.get("t_general_step", { stepNumber: 1 })}/
              {TOTAL_JOB_POSTING_STEPS}
            </Typography>
            <Typography variant="h4" mb={2.5}>
              {intl.get("t_job_post_company_information_step_label")}
            </Typography>
          </Stack>
          <Stack width={{ xs: "100%", md: "60%" }}>
            {isCompanyDetailsLoading || formInitValues == undefined ? (
              <SkeletonCompanyInformationForm />
            ) : (
              <>
                <Box position="relative">
                  <Radio
                    disabled={isDisabled}
                    control={control}
                    name="hiringFor"
                    label={intl.get(
                      "t_job_post_company_information_step_radio_field_label"
                    )}
                    direction="row"
                    required
                    options={[
                      {
                        label: intl.get(
                          "t_job_post_company_information_step_radio_option_hiring_for_myself"
                        ),
                        value: JOB_POSTING_TYPE.SELF
                      },
                      {
                        label: intl.get(
                          "t_job_post_company_information_step_radio_option_hiring_for_client"
                        ),
                        value: JOB_POSTING_TYPE.CLIENT,
                        disabled: !isCompanyDetailsExist
                      }
                    ]}
                  />
                  <Tooltip
                    hidden={isCompanyDetailsExist}
                    title={
                      <Typography
                        variant="body2"
                        maxWidth={{ xs: 114, sm: "100%" }}>
                        {intl.get(
                          "t_job_post_company_information_step_tooltip_hiring_for_client"
                        )}
                      </Typography>
                    }
                    arrow
                    enterTouchDelay={0}
                    placement="right">
                    <Box
                      position="absolute"
                      left={{ xs: 166, sm: 335 }}
                      bottom={8}>
                      <IconButton>
                        <HelpIcon color="primary" />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </Box>
                {hiringFor === JOB_POSTING_TYPE.CLIENT ? (
                  <Box>
                    <TypeableSelect
                      data-testid="company_information_client_company_select"
                      disablePortal={false}
                      name="clientCompany"
                      startAdornment={<SearchIcon />}
                      control={clientCompanyListSearchControl}
                      placeholder={intl.get(
                        "t_job_post_company_information_step_client_company_selector_placeholder"
                      )}
                      options={clientCompanySearchOptions}
                    />
                  </Box>
                ) : (
                  false
                )}
                <Typography
                  variant="subtitle4"
                  mb={{ xs: 0.5, lg: 1.5 }}
                  mt={{ xs: 2, lg: 1 }}>
                  {intl.get(
                    "t_job_post_company_information_step_company_logo_label"
                  )}
                </Typography>
                <br />
                <Typography variant="body2" color="text.secondary" mb={1.5}>
                  {intl.get(
                    "t_job_post_company_information_step_company_logo_format_label"
                  )}
                </Typography>
                <Box mb={2}>
                  <Avatar
                    data-testid="company_information_upload_logo_input"
                    variant="rounded"
                    imgSrc={companyLogoUrl}
                    allowAddPhoto={!isDisabled} //FIXME: update this once's hide camera icon logic is implemented
                    iconType="domain"
                    handleImageChange={handleCompanyLogoUpload}
                    config={COMPANY_LOGO_UPLOAD_CONFIG}
                  />
                </Box>
                <CompanyInfoForm
                  isDisabled={isDisabled}
                  initialValues={formInitValues}
                  handleFormSubmit={(formData) => {
                    hiringFor === JOB_POSTING_TYPE.SELF
                      ? handleHiringForMySelfFormSubmit(formData)
                      : handleHiringForClientFormSubmit(formData);
                  }}
                />
              </>
            )}
          </Stack>
        </Stack>
      </Paper>
      <br />
      <Stack direction="row-reverse" columnGap={3}>
        {isCompanyDetailsLoading ? (
          <SkeletonButton />
        ) : (
          <Button
            data-testid="company_information_next_button"
            loading={isDisabled}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            formId="company-information-form" // ref: https://github.com/react-hook-form/react-hook-form/issues/566#issuecomment-675515940
            endAdornment={<NavigateNextIcon />}>
            {intl.get("t_general_save_and_next")}
          </Button>
        )}
      </Stack>
    </>
  );
};

export default CompanyInformation;
