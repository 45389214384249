import { Box } from "@mui/material";

import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonCompanyInfo from "@skeletons/SkeletonCompanyInfo";
import SkeletonJobCategory from "@skeletons/SkeletonJobCategory";
import SkeletonStatus from "@skeletons/SkeletonStatus";
import SkeletonTypography from "@skeletons/SkeletonTypography";

const SkeletonSavedJobCard = () => {
  return (
    <Box p={2} borderRadius={1} bgcolor="common.white">
      {/* job status start */}
      <SkeletonStatus multiple />
      {/* job status  end */}

      {/* company and role information start */}
      <SkeletonCompanyInfo />
      {/* company and role information end */}

      {/* category tag start */}
      <Box gap={1} display="flex" alignItems="center" flexWrap="wrap" mt={1}>
        <SkeletonJobCategory multiple />
        <SkeletonTypography
          variant="body2"
          color="text.secondary"
          ml="auto"
          display={{ xs: "none", sm: "block", lg: "none", xl: "block" }}
        />
        <Box display={{ xs: "none", sm: "block", lg: "none", xl: "block" }}>
          <SkeletonButton />
        </Box>
      </Box>
      {/* category tag start */}

      {/* time and apply btn for mobile start */}
      <Box
        justifyContent="space-between"
        alignItems="center"
        display={{ xs: "flex", sm: "none", lg: "flex", xl: "none" }}
        mt={1}>
        <SkeletonTypography variant="body2" color="text.secondary" />
        <SkeletonButton />
      </Box>
      {/* time and apply btn for mobile end */}
    </Box>
  );
};

export default SkeletonSavedJobCard;
