import JobID from "@interfaces/database/JobID";
import UserID from "@interfaces/database/UserID";

import { UserProfileContextStructure } from "@utils/components/UserProfileContext";
import { USER_STATUS, USER_TYPE } from "@utils/config";

export const canUserBookmark = (
  userProfile: UserProfileContextStructure,
  userId: UserID
) => {
  if (
    userProfile.value &&
    userProfile.value.status === USER_STATUS.OK &&
    userProfile.value.user_type === USER_TYPE.INDIVIDUAL &&
    userId
  ) {
    return true;
  }
  return false;
};

export const handleJobBookmarkChange = async (
  jobId: JobID,
  isBookmarked: boolean,
  userProfile: UserProfileContextStructure,
  userId: UserID
) => {
  if (userProfile.value && canUserBookmark(userProfile, userId)) {
    let userBookmarks = userProfile.value.bookmarks;
    if (!userBookmarks) {
      userBookmarks = [];
    }
    const index = userBookmarks.indexOf(jobId);

    // FIXME: Remove splice logic
    if (isBookmarked) {
      // Un-bookmark
      if (index !== -1) {
        userBookmarks?.splice(index, 1);
      }
    } else {
      // Bookmark
      if (index === -1) {
        //Checking if value is duplicate entry
        {
          userBookmarks.push(jobId);
        }
      }
    }
    if (userProfile.setValue) {
      const newProfile = {
        ...userProfile.value,
        bookmarks: userBookmarks
      };
      await userProfile.setValue(newProfile);
    }
  }
};
