import { useNavigate } from "react-router-dom";

import { Box, keyframes, Stack, styled } from "@mui/material";

import Button from "@components/Button";
import Typography from "@components/Typography";

import { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

import BG_NotFound_Desktop from "@assets/images/BG_NotFound_Desktop.png";
import BG_NotFound_Mobile from "@assets/images/BG_NotFound_Mobile.png";
import BG_NotFound_Tablet from "@assets/images/BG_NotFound_Tablet.png";

const scrollAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
`;

const BackgroundBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "200%",
  position: "absolute",
  top: 0,
  zIndex: 10,
  backgroundRepeat: "repeat-y",
  backgroundSize: "contain",
  [theme.breakpoints.down("sm")]: {
    backgroundImage: `url(${BG_NotFound_Mobile})`
  },
  [theme.breakpoints.up("sm")]: {
    backgroundImage: `url(${BG_NotFound_Tablet})`
  },
  [theme.breakpoints.up("md")]: {
    backgroundImage: `url(${BG_NotFound_Desktop})`
  },
  animation: `${scrollAnimation} 20s linear infinite`
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.primary.main,
  backgroundColor: colorPalette.blue[20],
  padding: 4,
  borderRadius: 4,
  marginBottom: 12,
  boxShadow: `0px 3px 5px -1px ${colorPalette.grey[20]}, 
              0px 6px 10px ${colorPalette.grey[14]}, 
              0px 1px 18px ${colorPalette.grey[12]}`
}));

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Stack
      width="100vw"
      height="100vh"
      position="relative"
      alignItems="center"
      justifyContent="center"
      overflow="hidden">
      <BackgroundBox />
      <Stack
        spacing={3}
        alignItems="center"
        justifyContent="center"
        p={1}
        zIndex={50}
        position="relative"
        sx={{ backdropFilter: "blur(5px)" }}>
        <StyledBox>
          <Typography variant="subtitle5">
            {intl.get("t_not_found_page_404_tag")}
          </Typography>
        </StyledBox>

        <Stack spacing={1.25} alignItems="center" justifyContent="center">
          <Typography variant="h2">
            {intl.get("t_not_found_page_title")}
          </Typography>
          <Typography
            variant="subtitle3"
            textAlign="center"
            color="text.secondary">
            {intl.get("t_not_found_page_subtitle")}
          </Typography>
        </Stack>

        <Button
          handleClick={() => navigate(`/${translate.getCurrentLocale()}/`)}>
          {intl.get("t_not_found_page_go_home_btn")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default NotFound;
