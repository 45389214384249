import { Link, useLocation } from "react-router-dom";

import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Link as MuiLink,
  Stack,
  styled
} from "@mui/material";

import Typography from "@components/Typography";

import { colorPalette } from "@utils/theme";
import translate from "@utils/translate";

interface TabNavigationProps {
  color?: "dark" | "light";
  navLinks: Array<{
    label: string;
    redirectLink: string;
    showBadge: boolean;
    aliasLinks?: Array<string>;
  }>;
}

const StyledStack = styled(Stack)({
  "overflow": "auto",
  "::-webkit-scrollbar": {
    display: "none"
  }
});

const StyledListItemButton = styled(ListItemButton)<{ color: string }>(
  ({ color, theme }) => ({
    "paddingLeft": 0,
    "paddingRight": 0,
    ".MuiListItemText-primary": {
      "color":
        color === "light"
          ? colorPalette.white[80]
          : theme.palette.text.secondary,
      "&: hover": {
        color:
          color === "light"
            ? theme.palette.primary.contrastText
            : theme.palette.text.primary
      }
    },
    "&: hover": {
      background: "transparent"
    },
    "&.Mui-selected": {
      "borderBottom": `4px solid ${theme.palette.secondary.main}`,
      "background": "transparent",
      ".MuiListItemText-primary": {
        color:
          color === "light"
            ? theme.palette.primary.contrastText
            : theme.palette.text.primary
      },
      "&: hover": {
        background: "transparent"
      }
    }
  })
);

const TabNavigation = ({ navLinks, color = "light" }: TabNavigationProps) => {
  const currentPath = useLocation();
  const pathname = translate.getNonLocalizedURL(currentPath.pathname);
  return (
    <>
      <List disablePadding>
        <StyledStack direction="row" whiteSpace="nowrap" spacing={3}>
          {navLinks.map((singleNavLink) => {
            const label = singleNavLink.label.toLowerCase().replace(" ", "_");
            const dataTestId = `tab_navigation_${label}_link`;
            return (
              <MuiLink
                key={singleNavLink.label}
                component={Link}
                to={singleNavLink.redirectLink}
                underline="none">
                <ListItem disablePadding>
                  <StyledListItemButton
                    color={color}
                    disableRipple
                    selected={
                      pathname === singleNavLink.redirectLink ||
                      singleNavLink.aliasLinks?.includes(pathname)
                    }>
                    <ListItemText
                      data-testid={dataTestId}
                      primary={
                        <Typography
                          showBadge={singleNavLink.showBadge}
                          variant="subtitle2">
                          {singleNavLink.label}
                        </Typography>
                      }
                    />
                  </StyledListItemButton>
                </ListItem>
              </MuiLink>
            );
          })}
        </StyledStack>
      </List>
      <Divider color={colorPalette.grey.base} />
    </>
  );
};

export default TabNavigation;
