import { Control, Controller } from "react-hook-form";

import {
  FormHelperText,
  InputLabel,
  Slider,
  SliderProps,
  Tooltip
} from "@mui/material";
import { SliderValueLabelProps } from "@mui/material/Slider/SliderValueLabel.types";

import {
  RANGE_SLIDER_MIN,
  RANGE_SLIDER_STEP,
  RANGE_SLIDER_TOTAL_DISTANCE
} from "@utils/config";

interface RangeSliderProps extends SliderProps {
  name: string;
  label?: string;
  helperText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  required?: boolean;
  minDistance?: number;
}

// NOTE: For now, We will keep this here, In future if we want to make tooltip position dynamic positions, we will move this to the parent component
const ValueLabelComponent = (props: SliderValueLabelProps) => {
  const { children, open, value } = props;
  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="bottom"
      title={value}
      arrow>
      {children ? children : <></>}
    </Tooltip>
  );
};

const RangeSlider = ({
  name,
  control,
  label,
  helperText,
  required = false,
  disabled = false,
  min = RANGE_SLIDER_MIN,
  max = RANGE_SLIDER_TOTAL_DISTANCE,
  step = RANGE_SLIDER_STEP,
  valueLabelFormat,
  valueLabelDisplay = "auto",
  minDistance = RANGE_SLIDER_STEP
}: RangeSliderProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const handleChange = (
          event: Event,
          newValue: number | number[],
          activeThumb: number
        ) => {
          if (!Array.isArray(newValue)) {
            return;
          }

          if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
              const clamped = Math.min(newValue[0], max - minDistance);
              onChange([clamped, clamped + minDistance]);
            } else {
              const clamped = Math.max(newValue[1], minDistance);
              onChange([clamped - minDistance, clamped]);
            }
          } else {
            onChange(newValue as number[]);
          }
        };

        return (
          <>
            {label ? (
              <InputLabel
                htmlFor={name}
                required={required}
                disabled={disabled}>
                {label}
              </InputLabel>
            ) : (
              false
            )}
            <Slider
              disableSwap
              getAriaLabel={() => "Slider"}
              value={value}
              onChange={handleChange}
              valueLabelDisplay={valueLabelDisplay}
              valueLabelFormat={valueLabelFormat}
              min={min}
              max={max}
              step={step}
              disabled={disabled}
              components={{
                ValueLabel: ValueLabelComponent
              }}
            />
            <FormHelperText error={!!error}>
              {error ? error.message : helperText}
            </FormHelperText>
          </>
        );
      }}
    />
  );
};

export default RangeSlider;
