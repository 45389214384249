import { Link, useLocation } from "react-router-dom";

import { Box, Container, Grid, Link as MuiLink, styled } from "@mui/material";
import dayjs from "dayjs";

import Typography from "@components/Typography";

import { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

import BG_EmployerHomepage_Footer from "@assets/images/BG_EmployerHomepage_Footer.svg";
import BG_Homepage_Footer from "@assets/images/BG_Homepage_Footer.svg";
import Facebook_Icon from "@assets/images/Facebook_Icon.svg";
import Linkedin_Icon from "@assets/images/Linkedin_Icon.svg";
import Logo_White from "@assets/images/Logo_White.png";
import Orange_Shape from "@assets/images/shapes/Orange_Shape.png";
import Twitter_Icon from "@assets/images/Twitter_Icon.svg";
import Youtube_Icon from "@assets/images/Youtube_Icon.svg";

interface FooterProps {
  isBgTransparent?: boolean;
}

const StyledBox = styled(Box)(() => ({
  "width": 36,
  "height": 36,
  "borderRadius": 4,
  "backgroundColor": colorPalette.lightBlue.background[30],
  "&: hover": {
    backgroundColor: colorPalette.lightBlue.background.base
  }
}));

const StyledList = styled("li")(() => ({
  marginRight: 24
}));

const StyledGrid = styled(Grid)<{ itemType: string }>(({ theme, itemType }) => {
  switch (itemType) {
    case "gridContainer":
      return {
        position: "relative",
        zIndex: 2,
        [theme.breakpoints.down("md")]: {
          justifyContent: "space-between",
          paddingLeft: 19,
          paddingTop: 40
        },
        [theme.breakpoints.up("md")]: {
          paddingTop: 40
        }
      };
    case "infoLinkGridItem":
      return {
        minWidth: 200,

        [theme.breakpoints.down("md")]: {
          marginTop: 24
        }
      };
    case "logoGridItem":
      return {
        display: "flex",
        position: "relative",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "flex-end",
        [theme.breakpoints.down("md")]: {
          alignItems: "flex-start"
        }
      };
    case "socialLinkGridItem":
      return {
        minWidth: 240,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
          alignItems: "flex-start",
          marginTop: 15
        },
        [theme.breakpoints.between("sm", "md")]: {
          display: "flex",
          alignItems: "flex-end",
          marginTop: 24
        },
        [theme.breakpoints.up("md")]: {
          display: "flex",
          alignItems: "flex-end"
        }
      };
  }
});

const StyledSocialIconImage = styled("img")({
  "padding": 6,
  "filter": "brightness(0) invert(1)",
  "&:hover": {
    filter: "none"
  }
});

const StyledEmployerHomepageBackgroundImage = styled("img")(({ theme }) => {
  return {
    position: "absolute",
    bottom: 0,
    objectFit: "cover",
    width: "100%",
    height: 3580,
    zIndex: 0,
    objectPosition: "top",
    [theme.breakpoints.up("sm")]: {
      height: "660%",
      maxHeight: 3400
    },
    [theme.breakpoints.up("md")]: {
      height: 3000,
      maxHeight: "unset"
    }
  };
});

const StyledEmployerHomepageOrangeShape = styled("img")(({ theme }) => {
  return {
    position: "absolute",
    transform: "scaleX(-1)",
    left: 5,
    bottom: 2710,
    width: 100,
    [theme.breakpoints.up("sm")]: {
      left: 30,
      top: -2000
    },
    [theme.breakpoints.up("md")]: {
      width: 150,
      left: 70,
      top: "auto",
      bottom: 2250
    },
    [theme.breakpoints.up("xl")]: {
      width: 150,
      left: "15%",
      top: "auto",
      bottom: 2250
    }
  };
});

const StyledHomepageBackgroundImage = styled("img")(({ theme }) => {
  return {
    position: "absolute",
    bottom: 0,
    objectFit: "cover",
    width: "100%",
    height: 2750,
    zIndex: 0,
    objectPosition: "top",
    [theme.breakpoints.up("sm")]: {
      height: "460%",
      maxHeight: 3250
    },
    [theme.breakpoints.up("md")]: {
      height: 2400,
      maxHeight: "unset"
    },
    [theme.breakpoints.up("lg")]: {
      height: 2450
    },
    [theme.breakpoints.up("xl")]: {
      height: 2600
    }
  };
});

const StyledHomepageOrangeShape = styled("img")(({ theme }) => {
  return {
    position: "absolute",
    transform: "scaleX(-1)",
    left: 10,
    bottom: 1350,
    width: 100,
    [theme.breakpoints.up("sm")]: {
      left: 30,
      top: -950
    },
    [theme.breakpoints.up("md")]: {
      width: 150,
      left: 100,
      top: "auto",
      bottom: 1100
    }
  };
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  "color": colorPalette.white[80],
  "&: hover": {
    color: theme.palette.primary.contrastText
  }
}));

const StyledUnorderedList = styled("ul")<{ listName: string }>(
  ({ listName }) => {
    switch (listName) {
      case "infoLinkList":
        return {
          listStyle: "none",
          paddingLeft: 0
        };
      case "socialLinkList":
        return {
          listStyleType: "none",
          listStyle: "none",
          paddingLeft: 0,
          display: "flex",
          position: "relative"
        };
    }
  }
);

const Footer = ({ isBgTransparent = false }: FooterProps) => {
  const currentPath = useLocation();
  const pathname = translate.getNonLocalizedURL(currentPath.pathname);
  return (
    <Box
      data-testid="footer_box"
      width="100%"
      mt="auto"
      sx={{
        background: isBgTransparent
          ? "transparent"
          : colorPalette.blue["footer-gradient"],
        position: "relative"
      }}>
      {isBgTransparent && pathname === "/employers" ? (
        <>
          <StyledEmployerHomepageBackgroundImage
            src={BG_EmployerHomepage_Footer}
            alt={intl.get("t_alt_blue_background_blob")}
            className="bg-img-employer-home-footer"
          />
          <StyledEmployerHomepageOrangeShape
            src={Orange_Shape}
            alt={intl.get("t_alt_orange_shape")}
          />
        </>
      ) : isBgTransparent && pathname === "/" ? (
        <>
          <StyledHomepageBackgroundImage
            src={BG_Homepage_Footer}
            alt={intl.get("t_alt_blue_background_blob")}
            className="bg-img-home-footer"
          />
          <StyledHomepageOrangeShape
            src={Orange_Shape}
            alt={intl.get("t_alt_orange_shape")}
          />
        </>
      ) : (
        false
      )}

      <Container>
        <StyledGrid
          container
          spacing={1}
          itemType="gridContainer"
          zIndex={2}
          height={{ sm: 600, md: "unset" }}>
          <StyledGrid item xs={12} md={3} itemType="logoGridItem">
            <MuiLink component={Link} to="/">
              <img
                src={Logo_White}
                data-testid="footer_tokhimo_image"
                alt={intl.get("t_alt_footer_logo")}
              />
            </MuiLink>
          </StyledGrid>
          <StyledGrid item xs={12} sm={3} md={2} itemType="infoLinkGridItem">
            <Typography variant="h4" color="primary.contrastText">
              {intl.get("t_general_tokhimo_jobs")}
            </Typography>
            <StyledUnorderedList listName="infoLinkList">
              <li>
                <MuiLink
                  component={Link}
                  data-testid="footer_home_link"
                  to="/"
                  underline="none">
                  <StyledTypography variant="subtitle2">
                    {intl.get("t_general_home")}
                  </StyledTypography>
                </MuiLink>
              </li>
              <li>
                <MuiLink
                  component={Link}
                  data-testid="footer_find_jobs_link"
                  to="/search"
                  underline="none">
                  <StyledTypography variant="subtitle2">
                    {intl.get("t_general_find_jobs")}
                  </StyledTypography>
                </MuiLink>
              </li>
              <li>
                <MuiLink
                  component={Link}
                  data-testid="footer_blog_link"
                  to="https://www.tokhimo.com/blog"
                  underline="none"
                  target="_blank"
                  rel="noopener noreferrer">
                  <StyledTypography variant="subtitle2">
                    {intl.get("t_general_blog")}
                  </StyledTypography>
                </MuiLink>
              </li>
              <li>
                <MuiLink
                  component={Link}
                  data-testid="footer_contact_us_link"
                  to="https://www.tokhimo.com/jobs-contact"
                  underline="none"
                  target="_blank"
                  rel="noopener noreferrer">
                  <StyledTypography variant="subtitle2">
                    {intl.get("t_general_contact_us")}
                  </StyledTypography>
                </MuiLink>
              </li>
              <li>
                <MuiLink
                  component={Link}
                  data-testid="footer_terms_and_condition_link"
                  to="/user-terms-and-conditions"
                  underline="none">
                  <StyledTypography variant="subtitle2">
                    {intl.get("t_general_user_terms")}
                  </StyledTypography>
                </MuiLink>
              </li>
              <li>
                <MuiLink
                  component={Link}
                  data-testid="footer_privacy_policy_link"
                  to="/privacy-policy"
                  underline="none">
                  <StyledTypography variant="subtitle2">
                    {intl.get("t_general_privacy_policy")}
                  </StyledTypography>
                </MuiLink>
              </li>
              <li>
                <MuiLink
                  component={Link}
                  data-testid="footer_faq_link"
                  to="https://www.tokhimo.com/jobs-faq"
                  underline="none"
                  target="_blank"
                  rel="noopener noreferrer">
                  <StyledTypography variant="subtitle2">
                    {intl.get("t_general_faq")}
                  </StyledTypography>
                </MuiLink>
              </li>
            </StyledUnorderedList>
          </StyledGrid>
          <StyledGrid
            item
            xs={12}
            sm={4}
            md={3}
            itemType="infoLinkGridItem"
            minWidth={240}>
            <Typography variant="h4" color="primary.contrastText">
              {intl.get("t_general_for_employers")}
            </Typography>
            <StyledUnorderedList listName="infoLinkList">
              <li>
                <MuiLink
                  component={Link}
                  to="/employers"
                  underline="none"
                  data-testid="footer_hire_via_tokhimo_link">
                  <StyledTypography variant="subtitle2">
                    {intl.get("t_general_hire_via_tokhimo_jobs")}
                  </StyledTypography>
                </MuiLink>
              </li>
              <li>
                <MuiLink
                  data-testid="footer_employer_contact_us_link"
                  component={Link}
                  to="https://www.tokhimo.com/jobs-contact"
                  underline="none"
                  target="_blank"
                  rel="noopener noreferrer">
                  <StyledTypography variant="subtitle2">
                    {intl.get("t_general_contact_us")}
                  </StyledTypography>
                </MuiLink>
              </li>
              <li>
                <MuiLink
                  data-testid="footer_employer_corporate_site_link"
                  component={Link}
                  to="https://corporate.tokhimo.com/"
                  underline="none"
                  target="_blank"
                  rel="noopener noreferrer">
                  <StyledTypography variant="subtitle2">
                    {intl.get("t_general_corporate_site")}
                  </StyledTypography>
                </MuiLink>
              </li>
            </StyledUnorderedList>
          </StyledGrid>
          <StyledGrid item xs={12} sm={4} md={3} itemType="socialLinkGridItem">
            <Box>
              <Typography variant="h4" color="primary.contrastText">
                {intl.get("t_general_follow_us")}
              </Typography>
              <StyledUnorderedList listName="socialLinkList">
                <StyledList>
                  <MuiLink
                    data-testid="footer_linkedin_link"
                    href="https://www.linkedin.com/company/tokhimo"
                    target="_blank"
                    rel="noopener noreferrer">
                    <StyledBox>
                      <StyledSocialIconImage
                        src={Linkedin_Icon}
                        alt={intl.get("t_alt_footer_linkedin")}
                      />
                    </StyledBox>
                  </MuiLink>
                </StyledList>
                <StyledList>
                  <MuiLink
                    data-testid="footer_facebook_link"
                    href="https://www.facebook.com/Tokhimo-117563023428228"
                    target="_blank"
                    rel="noopener noreferrer">
                    <StyledBox>
                      <StyledSocialIconImage
                        src={Facebook_Icon}
                        alt={intl.get("t_alt_footer_facebook")}
                      />
                    </StyledBox>
                  </MuiLink>
                </StyledList>
                <StyledList>
                  <MuiLink
                    data-testid="footer_twitter_link"
                    href="https://twitter.com/tokhimo"
                    target="_blank"
                    rel="noopener noreferrer">
                    <StyledBox>
                      <StyledSocialIconImage
                        src={Twitter_Icon}
                        alt={intl.get("t_alt_footer_twitter")}
                      />
                    </StyledBox>
                  </MuiLink>
                </StyledList>
                <StyledList>
                  <MuiLink
                    data-testid="footer_youtube_link"
                    href="https://www.youtube.com/channel/UCChyNQwrJXxVhYaH-aWB2xg"
                    target="_blank"
                    rel="noopener noreferrer">
                    <StyledBox>
                      <StyledSocialIconImage
                        src={Youtube_Icon}
                        alt={intl.get("t_alt_footer_youtube")}
                      />
                    </StyledBox>
                  </MuiLink>
                </StyledList>
              </StyledUnorderedList>
            </Box>
          </StyledGrid>
        </StyledGrid>
        <Box
          data-testid="footer_copyright_box"
          justifyContent="center"
          display="flex"
          position="relative"
          zIndex={2}
          pt={2.5}
          pb={5}>
          <Typography variant="body2" color={colorPalette.white[80]}>
            {intl.get("t_general_copyright", {
              year: dayjs().format("YYYY")
            })}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
