import { useEffect } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  DialogActions,
  DialogContent,
  Stack,
  Tooltip
} from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import useForceUpdate from "use-force-update";
import * as yup from "yup";

import Button from "@components/Button";
import ManageTeamTable from "@components/DataTable/ManageTeamTable";
import Dialog from "@components/Dialog";
import Paper from "@components/Paper";
import Radio from "@components/Radio";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import { auth, functions } from "@utils/firebase";
import translate, { intl } from "@utils/translate";

interface NewOwnerData {
  newOwner: string;
}

const TeamList = () => {
  const [user] = useAuthState(auth);
  const userProfile = useUserProfile();
  const companyDetails = useCompanyDetails();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  const toast = useToast();

  const [changeCompanyOwner, isChangeCompanyOwnerLoading] = useHttpsCallable(
    functions,
    "changeCompanyOwner"
  );

  const languageChanged = () => {
    forceUpdate();
  };

  useEffect(() => {
    translate.addLanguageChangedListener(languageChanged);
    return () => {
      translate.removeLanguageChangedListener(languageChanged);
    };
  }, []);

  const currentUserId = user?.uid;
  const ownerUserId = companyDetails?.value?.owner?.user_id;
  const managers = companyDetails?.value?.managers;

  const activeManagers = Object.fromEntries(
    Object.entries(managers || {}).filter(
      ([_, manager]) => manager.joined_at !== undefined
    )
  );
  const managerOptionsList = activeManagers
    ? Object.entries(activeManagers).map(([userId, managerInfo]) => ({
        value: userId,
        label: managerInfo.email
      }))
    : [];

  const schema = yup.object({
    newOwner: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_owner")
        })
      )
  });

  const methods = useForm({
    defaultValues: {
      newOwner: ""
    },
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control } = methods;

  const handleChangeOwner = async (formData: NewOwnerData) => {
    const { newOwner } = formData;

    try {
      const result = await changeCompanyOwner({
        companyId: userProfile.value?.company_id,
        newOwnerUserId: newOwner
      });

      const data = result?.data as {
        success: boolean;
        message?: string;
      };

      if (data.success) {
        toast.kampai(intl.get("t_toast_success_change_owner"), "success");
      } else {
        toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
      }
    } catch (e) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  return (
    <>
      <Paper>
        <ManageTeamTable />
      </Paper>

      <Stack mt={6} direction="row" justifyContent="space-between">
        {managerOptionsList.length > 0 ? (
          // TODO: Use IAM Permissions in the future
          currentUserId === ownerUserId ? (
            <Dialog
              title={intl.get(
                "t_employer_manage_team_change_owner_modal_title"
              )}
              maxWidth="sm"
              isStopEventPropagation
              initiator={
                <Button
                  variant="outlined"
                  loading={isChangeCompanyOwnerLoading}>
                  {intl.get("t_employer_manage_team_change_owner_btn")}
                </Button>
              }>
              {(handleAgree, handleCancel) => (
                <Box
                  noValidate
                  component="form"
                  onSubmit={handleSubmit(handleChangeOwner)}>
                  <DialogContent>
                    <Typography color="text.secondary" mb={2}>
                      {intl.get(
                        "t_employer_manage_team_change_owner_modal_description"
                      )}
                    </Typography>
                    <Radio
                      size="medium"
                      name="newOwner"
                      control={control}
                      options={managerOptionsList}
                      required
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button handleClick={handleCancel} variant="outlined">
                      {intl.get("t_general_cancel")}
                    </Button>
                    <Button handleClick={handleAgree} type="submit">
                      {intl.get("t_general_confirm")}
                    </Button>
                  </DialogActions>
                </Box>
              )}
            </Dialog>
          ) : (
            <Tooltip
              enterTouchDelay={0}
              arrow
              placement="top"
              title={
                <Typography variant="body2">
                  {intl.get(
                    "t_employer_manage_team_change_owner_no_permission_tooltip"
                  )}
                </Typography>
              }>
              <span>
                <Button variant="outlined" disabled>
                  {intl.get("t_employer_manage_team_change_owner_btn")}
                </Button>
              </span>
            </Tooltip>
          )
        ) : (
          false
        )}

        <Stack direction="row" flex={1} justifyContent="flex-end">
          <Button handleClick={() => navigate("invite-people")}>
            {intl.get("t_employer_manage_team_invite_people_btn")}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default TeamList;
