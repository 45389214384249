import { ChangeEvent, useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Box, Grid } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import SkeletonInvitedJobCard from "@skeletons/SkeletonInvitedJobCard";

import InvitedJobCard from "@components/JobCards/InvitedJobCard";
import NoJobsCard from "@components/NoJobsCard";
import Pagination from "@components/Pagination";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import ApplicationID from "@interfaces/database/ApplicationID";
import AppliedInvitedJob from "@interfaces/database/AppliedInvitedJob";
import CompanyProfile from "@interfaces/database/CompanyProfile";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import MultiLingual from "@interfaces/database/MultiLingual";

import { canUserApply, handleInvitedJobApply } from "@utils/applyJob";
import {
  FILES_LOCATION_COMPANY_LOGO,
  FIRESTORE_COLLECTIONS,
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  JOB_POSTING_STATUS,
  JOB_REMOTE_WORK_TYPE,
  JOB_REMOTE_WORK_TYPE_T_LABELS,
  JOB_VISA_SPONSORSHIP_AVAILABLE,
  LANGUAGE_PROFICIENCY,
  MIN_YEARS_OF_EXPERIENCE_T_LABELS,
  PAGINATION
} from "@utils/config";
import { handleInvitedJobDecline } from "@utils/declineJob";
import { auth, db } from "@utils/firebase";
import { getFileURL } from "@utils/getFileURL";
import { resolveMultiLingual } from "@utils/multiLingual";
import { intl } from "@utils/translate";

interface InvitedJob extends AppliedInvitedJob {
  job: JobProfile;
  company: CompanyProfile;
  isApplyLoading?: boolean;
  isDeclineLoading?: boolean;
}

const InvitedJobs = () => {
  const [user] = useAuthState(auth);
  const userProfile = useUserProfile();
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const invitedJobs = userProfile.value?.jobs?.invited.reverse() ?? [];
  const appliedJobs = userProfile.value?.jobs?.applied ?? [];

  const [loading, setLoading] = useState<boolean>(false);
  const [invitedJobsData, setInvitedJobsData] = useState<Array<InvitedJob>>([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = PAGINATION.ITEMS_PER_PAGE;
  const noOfPages =
    currentPage > Math.ceil(invitedJobs.length / itemsPerPage)
      ? currentPage
      : Math.ceil(invitedJobs.length / itemsPerPage);
  const [invitedJobsPerPage, setInvitedJobsPerPage] = useState<
    Array<AppliedInvitedJob>
  >([]);

  // To check if page number is valid
  useEffect(() => {
    if (searchParams.has("page")) {
      const page = Number(searchParams.get("page"));

      if (Number.isInteger(page) && page > 0 && page <= noOfPages) {
        setCurrentPage(page);
      } else {
        setCurrentPage(1);
        searchParams.delete("page");
        setSearchParams(searchParams.toString(), { replace: true });
      }
    }
  }, []);

  useEffect(() => {
    setInvitedJobsPerPage(
      invitedJobs.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
    );
  }, [currentPage]);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    searchParams.set("page", `${value}`);
    setSearchParams(searchParams.toString(), { replace: true });
  };

  // Fetch Jobs
  const fetchInvitedJobs = async (jobs: Array<AppliedInvitedJob>) => {
    setLoading(true);
    try {
      if (jobs.length > 0) {
        const jobsData: Array<InvitedJob> = [];

        for (const singleJob of jobs) {
          const jobDocRef = doc(db, FIRESTORE_COLLECTIONS.JOBS, singleJob.id);
          const jobDocSnap = await getDoc(jobDocRef);

          if (jobDocSnap.exists()) {
            const jobData = jobDocSnap.data();
            const companyId = jobData.client_company_id
              ? jobData.client_company_id
              : jobData.company_id;

            const companyDocRef = doc(
              db,
              FIRESTORE_COLLECTIONS.COMPANIES,
              companyId
            );
            const companyDocSnap = await getDoc(companyDocRef);

            if (companyDocSnap.exists()) {
              const companyData = companyDocSnap.data();
              // Add company data to the job document
              const jobWithCompanyData = {
                ...singleJob,
                job: jobData as JobProfile,
                company: companyData as CompanyProfile
              };
              jobsData.push(jobWithCompanyData);
            }
          }
        }
        // Process the final array of job documents with company data
        setInvitedJobsData(jobsData);
      }
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInvitedJobs(invitedJobsPerPage);
  }, [invitedJobsPerPage]);

  // Apply Job
  const handleApplySuccess = () => {
    toast.kampai(intl.get("t_toast_apply_job_success"), "success");
  };

  const handleApplyFail = () => {
    toast.kampai(intl.get("t_toast_apply_job_error"), "error");
  };

  const handleApplyJob = async (jobId: JobID, applicationId: ApplicationID) => {
    try {
      if (user && userProfile && jobId && applicationId) {
        // Check if application is duplicate
        const duplicateApplicationIndex = appliedJobs.findIndex(
          (singleJob) => singleJob.id === jobId
        );

        if (
          duplicateApplicationIndex !== -1 &&
          userProfile.value &&
          userProfile.value.jobs &&
          userProfile.setValue
        ) {
          toast.kampai(intl.get("t_toast_apply_duplicate_job_error"), "error");
          const updatedInvitedJobsData = [...invitedJobs];
          updatedInvitedJobsData.splice(duplicateApplicationIndex, 1);
          userProfile.value.jobs.invited = updatedInvitedJobsData;

          await userProfile.setValue(userProfile.value);

          return;
        }

        const index = invitedJobsData.findIndex(
          (singleJob) => singleJob.id === jobId
        );

        if (index !== -1) {
          const updatedInvitedJobsData = [...invitedJobsData];

          // Loading state updated
          updatedInvitedJobsData[index].isApplyLoading = true;
          setInvitedJobsData(updatedInvitedJobsData);

          const success = await handleInvitedJobApply(
            jobId,
            applicationId,
            userProfile,
            user.uid
          );
          if (success) {
            handleApplySuccess();
            // Temporary status update
            updatedInvitedJobsData[index] = {
              ...invitedJobsData[index],
              status: JOB_APPLICATION_INVITATION_ACTION_TYPE.APPLIED
            };
          } else {
            handleApplyFail();
          }
          // Loading state updated
          updatedInvitedJobsData[index].isApplyLoading = false;
          setInvitedJobsData(updatedInvitedJobsData);
        }
      }
    } catch (error) {
      handleApplyFail();
    }
  };

  // Decline Job
  const handleDeclineSuccess = () => {
    toast.kampai(intl.get("t_toast_decline_success"), "success");
  };

  const handleDeclineFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleDeclineJob = async (
    applicationId: ApplicationID,
    jobId: JobID,
    jobDeclineReasons?: Array<MultiLingual<string>>
  ) => {
    try {
      const index = invitedJobsData.findIndex(
        (singleJob) => singleJob.id === jobId
      );

      if (index !== -1) {
        const updatedInvitedJobsData = [...invitedJobsData];

        if (applicationId && userProfile.value?.jobs?.invited && user) {
          // Loading state updated
          updatedInvitedJobsData[index].isDeclineLoading = true;
          setInvitedJobsData(updatedInvitedJobsData);

          const success = await handleInvitedJobDecline(
            applicationId,
            jobId,
            user?.uid,
            userProfile,
            jobDeclineReasons
          );
          if (success) {
            handleDeclineSuccess();

            // Temporary status update
            updatedInvitedJobsData[index] = {
              ...invitedJobsData[index],
              status: JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED_DECLINED
            };
          } else {
            handleDeclineFail();
          }

          // Loading state updated
          updatedInvitedJobsData[index].isDeclineLoading = false;
          setInvitedJobsData(updatedInvitedJobsData);
        }
      }
    } catch (error) {
      handleDeclineFail();
    }
  };

  return (
    <>
      {userProfile.loading || loading ? (
        <Grid container spacing={3} mt={5.5}>
          <Grid item xs={12} sm={6}>
            <SkeletonInvitedJobCard />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SkeletonInvitedJobCard />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SkeletonInvitedJobCard />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SkeletonInvitedJobCard />
          </Grid>
        </Grid>
      ) : invitedJobsPerPage?.length === 0 ? (
        <Box my="20vh">
          <NoJobsCard type="invited" />
        </Box>
      ) : (
        <>
          <Grid container spacing={3} mt={5.5} mb={3}>
            {invitedJobsData?.map((singleJob, index) => {
              const isJobActive =
                singleJob.job.status === JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                singleJob.job.status === JOB_POSTING_STATUS.OK_MANUAL_REVIEWED
                  ? true
                  : false;

              const categories = [];
              if (singleJob?.job?.job_overview?.minimum_experience) {
                categories.push(
                  intl.get(
                    MIN_YEARS_OF_EXPERIENCE_T_LABELS[
                      singleJob?.job?.job_overview
                        ?.minimum_experience as keyof typeof MIN_YEARS_OF_EXPERIENCE_T_LABELS
                    ]
                  )
                );
              }
              if (
                singleJob?.job?.job_overview?.remote_possible ==
                  JOB_REMOTE_WORK_TYPE.FULLY_REMOTE ||
                singleJob?.job?.job_overview?.remote_possible ==
                  JOB_REMOTE_WORK_TYPE.PARTIALLY_REMOTE
              ) {
                categories.push(
                  intl.get(
                    JOB_REMOTE_WORK_TYPE_T_LABELS[
                      singleJob?.job?.job_overview
                        ?.remote_possible as keyof typeof JOB_REMOTE_WORK_TYPE_T_LABELS
                    ]
                  )
                );
              }
              if (
                parseInt(LANGUAGE_PROFICIENCY.BUSINESS) >=
                parseInt(
                  singleJob?.job?.language_requirement?.at(1)?.proficiency ??
                    "0"
                )
              ) {
                categories.push(intl.get("t_job_card_en_tag"));
              }
              if (
                singleJob?.job?.job_overview?.visa_sponsorship ==
                JOB_VISA_SPONSORSHIP_AVAILABLE.YES
              ) {
                categories.push(intl.get("t_job_visa_sponsorship"));
              }

              return (
                // FIXME: Replace index with singleJob.jobId once duplicate invitation is handled
                <Grid item xs={12} sm={6} key={index}>
                  <InvitedJobCard
                    categories={categories}
                    status={[singleJob.status]}
                    timestamp={singleJob?.updated_at.toDate()}
                    jobTitle={resolveMultiLingual(singleJob?.job?.job_title)}
                    companyLogo={getFileURL(
                      FILES_LOCATION_COMPANY_LOGO,
                      singleJob?.job?.client_company_id
                        ? singleJob.job.client_company_id
                        : singleJob?.job?.company_id,
                      singleJob?.company?.logo?.extension,
                      singleJob?.company?.logo?.uploaded_at
                    )}
                    companyName={resolveMultiLingual(singleJob?.company?.name)}
                    location={{
                      city: singleJob?.job?.job_overview?.location.city ?? "",
                      country:
                        singleJob?.job?.job_overview?.location?.country ?? ""
                    }}
                    jobApplyStatusCode={canUserApply(userProfile, user)}
                    handleJobApply={() =>
                      handleApplyJob(singleJob.id, singleJob?.application_id)
                    }
                    handleJobDecline={(
                      jobDeclinedReasons?: Array<MultiLingual<string>>
                    ) => {
                      handleDeclineJob(
                        singleJob?.application_id,
                        singleJob.id,
                        jobDeclinedReasons
                      );
                    }}
                    handleClick={() => {
                      if (isJobActive) {
                        window.open(
                          `/jobs/${singleJob.id}?hide_search=1`,
                          "_blank",
                          "noreferrer"
                        );
                      }
                    }}
                    isApplyLoading={singleJob?.isApplyLoading}
                    isDeclineLoading={singleJob?.isDeclineLoading}
                    isJobActive={isJobActive}
                    isHighlighted={singleJob.id === searchParams.get("job_id")}
                    cvFileRequirements={singleJob?.job?.cv_file_requirements}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Pagination
            count={noOfPages === 0 ? 1 : noOfPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        </>
      )}
    </>
  );
};

export default InvitedJobs;
