import { ChangeEvent, useEffect, useState } from "react";

import { PersonRounded as PersonRoundedIcon } from "@mui/icons-material";
import {
  alpha,
  Badge,
  Box,
  CircularProgress,
  LinearProgress,
  Avatar as MuiAvatar,
  styled
} from "@mui/material";

import Icon from "@components/Icon";

import useToast from "@hooks/useToast";

import { AVATAR_IMAGE_UPLOAD_CONFIG } from "@utils/config";
import fetchInitials from "@utils/fetchInitials";
import getFileMetaInformation from "@utils/getFileMetaInformation";
import { colorPalette } from "@utils/theme";
import { intl } from "@utils/translate";

interface AvatarProps {
  "allowAddPhoto"?: boolean;
  "fullName"?: string;
  "initials"?: string;
  "imgSrc"?: string;
  "size"?: "small" | "medium" | "large";
  "variant"?: "circular" | "rounded";
  "handleImageChange"?: (image: File) => void;
  "config"?: {
    fileSize: number;
    fileFormat: Array<string>;
  };
  "iconType"?: string;
  "loading"?: boolean;
  "data-testid"?: string;
}

const StyledAvatar = styled(MuiAvatar)<{ size: string }>(({ size }) => {
  return {
    width: size === "large" ? 64 : size === "medium" ? 40 : 32,
    height: size === "large" ? 64 : size === "medium" ? 40 : 32,
    backgroundColor: colorPalette.grey.base
  };
});

const StyledPersonRoundedIcon = styled(PersonRoundedIcon)<{ size: string }>(
  ({ size }) => {
    return {
      fontSize: size === "large" ? "32px" : "16px"
    };
  }
);

const StyledCircularProgress = styled(CircularProgress)({
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 1
});

const StyledLinearProgress = styled(LinearProgress)({
  position: "relative",
  overflow: "hidden",
  display: "block",
  zIndex: 0,
  backgroundColor: "transparent",
  borderRadius: "8px",
  height: "0.5rem",
  top: "-8px"
});

const StyledAddPhotoAvatar = styled(MuiAvatar)(({ theme }) => ({
  "width": 20,
  "height": 20,
  "fontSize": 16,
  "padding": 4,
  "backgroundColor": theme.palette.common.white,
  "color": theme.palette.text.primary,
  "boxShadow": `0px 3px 5px -1px ${colorPalette.grey[20]}, 0px 6px 10px ${alpha(
    colorPalette.grey.base,
    0.14
  )}, 0px 1px 18px ${alpha(colorPalette.grey.base, 0.12)}`,
  "&: hover": {
    color: theme.palette.primary.main,
    cursor: "pointer"
  }
}));

const Avatar = ({
  fullName = "",
  imgSrc = "",
  allowAddPhoto = false,
  size = "large",
  variant = "circular",
  handleImageChange = () => {
    /* no-op */
  },
  config = {
    fileSize: AVATAR_IMAGE_UPLOAD_CONFIG.fileSize,
    fileFormat: AVATAR_IMAGE_UPLOAD_CONFIG.fileFormat
  },
  iconType = "person",
  initials,
  loading = false,
  "data-testid": dataTestId
}: AvatarProps) => {
  const toast = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;

    if (!input.files?.length) {
      return;
    }

    const imageFile = input.files[0];
    const { fileSizeInMb, fileExt } = getFileMetaInformation(imageFile);

    if (!config.fileFormat.includes(fileExt)) {
      toast.kampai(
        intl.get("t_toast_error_avatar_image_file_formate", {
          fileFormat: fileExt,
          allowedFormats: config.fileFormat.join()
        }),
        "error"
      );
      return;
    }

    if (fileSizeInMb > config.fileSize) {
      toast.kampai(
        intl.get("t_toast_error_avatar_image_file_size", {
          fileSize: fileSizeInMb.toFixed(2),
          maxSize: config.fileSize
        }),
        "error"
      );
      return;
    }

    setLoading(true);
    handleImageChange && handleImageChange(imageFile);
  };

  useEffect(() => {
    setLoading(false);
  }, [imgSrc]);

  return allowAddPhoto ? (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      invisible={isLoading}
      badgeContent={
        <label htmlFor="upload-image">
          <Box
            data-testid={dataTestId}
            component="input"
            display="none"
            id="upload-image"
            name="upload-image"
            type="file"
            accept={config.fileFormat.map((ext) => `image/${ext}`).join(", ")}
            onChange={handleChange}
          />
          <StyledAddPhotoAvatar
            sx={{ borderRadius: variant === "rounded" ? "4px" : "50%" }}>
            <Icon type="add_a_photo" filled />
          </StyledAddPhotoAvatar>
        </label>
      }>
      <Box position="relative">
        <StyledAvatar size={size} src={imgSrc} variant={variant}>
          {initials ? (
            initials
          ) : fullName ? (
            fetchInitials(fullName)
          ) : (
            <>
              {iconType ? (
                <Box fontSize={size === "large" ? 32 : 16}>
                  <Icon type={iconType} filled />
                </Box>
              ) : (
                <StyledPersonRoundedIcon size={size} />
              )}
            </>
          )}
        </StyledAvatar>
        {isLoading ? (
          variant === "circular" ? (
            <StyledCircularProgress
              size={size === "large" ? 64 : size === "medium" ? 40 : 32}
            />
          ) : (
            <StyledLinearProgress />
          )
        ) : (
          false
        )}
      </Box>
    </Badge>
  ) : (
    <StyledAvatar size={size} src={imgSrc} variant={variant}>
      {initials ? (
        initials
      ) : fullName ? (
        fetchInitials(fullName)
      ) : (
        <>
          {iconType ? (
            <Box fontSize={size === "large" ? 32 : 16}>
              <Icon type={iconType} filled />
            </Box>
          ) : (
            <StyledPersonRoundedIcon size={size} />
          )}
        </>
      )}
    </StyledAvatar>
  );
};

export default Avatar;
