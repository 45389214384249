import { useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon,
  KeyboardArrowUpRounded as KeyboardArrowUpRoundedIcon,
  LanguageRounded as LanguageRoundedIcon
} from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Stack, styled } from "@mui/material";

import Typography from "@components/Typography";

import { LOCALE } from "@utils/config";
import theme, { colorPalette } from "@utils/theme";
import translate, {
  LOCALE_DATA_LOWERCASE,
  LOCALES_LIST
} from "@utils/translate";

interface LanguageSwitcherProps {
  variant?: "primary" | "secondary";
  handleMainMenuClose?: () => void;
}

const StyledTypography = styled(Typography)({
  "cursor": "pointer",
  "textTransform": "uppercase",
  "color": theme.palette.text.primary,
  ":hover, &.active": {
    color: theme.palette.primary.main
  }
});

const LanguageSwitcher = ({
  variant = "primary",
  handleMainMenuClose
}: LanguageSwitcherProps) => {
  const languageSwitcherElement = useRef<HTMLDivElement>(null);
  const [openLanguageMenu, setOpenLanguageMenu] = useState<boolean>(false);
  const navigate = useNavigate();

  const currentLocale = translate.getCurrentLocale();

  const handleClose = () => {
    setOpenLanguageMenu(false);
  };

  const handleLocaleChange = (locale: typeof LOCALE[keyof typeof LOCALE]) => {
    handleClose();
    // For mobile hamburger menu close
    if (handleMainMenuClose) {
      handleMainMenuClose();
    }

    const lowercaseLocale = locale.toLowerCase();

    // Navigate to the locale Page.
    const pathSegments = window.location.pathname.split("/");
    const firstPathSegmentLower = pathSegments[1].toLowerCase();

    if (Object.keys(LOCALE_DATA_LOWERCASE).includes(firstPathSegmentLower)) {
      pathSegments[1] = lowercaseLocale;
    } else {
      pathSegments.splice(1, 0, lowercaseLocale);
    }

    const newPath = pathSegments.join("/");
    navigate(newPath);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={0.5}
        ref={languageSwitcherElement}
        onClick={() => setOpenLanguageMenu(!openLanguageMenu)}
        sx={{
          "cursor": "pointer",
          "color":
            variant === "primary"
              ? theme.palette.text.primary
              : colorPalette.white[80],
          ":hover, &.active": {
            color:
              variant === "primary"
                ? theme.palette.primary.main
                : theme.palette.common.white
          }
        }}>
        <IconButton size="small" disableRipple color="inherit">
          <LanguageRoundedIcon />
        </IconButton>
        <Typography variant="h5" textTransform="uppercase">
          {
            LOCALES_LIST.find(
              (singleLocale) => singleLocale.value === currentLocale
            )?.label
          }
        </Typography>
        <IconButton size="small" disableRipple color="inherit">
          {openLanguageMenu ? (
            <KeyboardArrowUpRoundedIcon />
          ) : (
            <KeyboardArrowDownRoundedIcon />
          )}
        </IconButton>
      </Stack>

      {/* Desktop */}
      <Menu
        anchorEl={languageSwitcherElement.current}
        id="language-switcher"
        open={openLanguageMenu}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          display: { xs: "none", md: "block" },
          textTransform: "uppercase"
        }}>
        {LOCALES_LIST.map((singleLocale) => (
          <MenuItem
            data-testid={`header_language_switcher_${singleLocale.label}`}
            key={singleLocale.value}
            onClick={() => handleLocaleChange(singleLocale.value)}>
            {singleLocale.label}
          </MenuItem>
        ))}
      </Menu>

      {/* Mobile */}
      {openLanguageMenu ? (
        <Stack spacing={3} display={{ xs: "flex", md: "none" }}>
          {LOCALES_LIST.map((singleLocale) => (
            <StyledTypography
              key={singleLocale.value}
              onClick={() => handleLocaleChange(singleLocale.value)}
              variant="h5">
              {singleLocale.label}
            </StyledTypography>
          ))}
        </Stack>
      ) : (
        false
      )}
    </>
  );
};

export default LanguageSwitcher;
