import { Stack } from "@mui/material";

import SkeletonCompanyLogo from "@skeletons/SkeletonCompanyLogo";
import SkeletonTypography from "@skeletons/SkeletonTypography";

const SkeletonCompanyInfo = () => {
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        marginY={1}
        flexWrap="wrap"
        justifyItems="stretch">
        <SkeletonCompanyLogo />
        <Stack direction="column" gap={0.5} flexGrow={1}>
          <SkeletonTypography width="medium" variant="body1" />
          <SkeletonTypography width="medium" variant="subtitle2" />
          <SkeletonTypography width="medium" variant="body1" />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SkeletonCompanyInfo;
