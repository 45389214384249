import { useEffect, useState } from "react";

import { Control, Controller, UseFormSetValue } from "react-hook-form";

import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Checkbox as MuiCheckbox,
  Stack
} from "@mui/material";

export interface CheckboxProps {
  "name": string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  "control": Control<any>;
  "label"?: string;
  "disabled"?: boolean;
  "required"?: boolean;
  "options": Array<{
    label: string;
    value: string | number;
    disabled?: boolean;
  }>;
  "direction"?: "row" | "col";
  "size"?: "small" | "medium";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  "setValue": UseFormSetValue<any>;
  "data-testid"?: string;
}

const Checkbox = ({
  name,
  control,
  setValue,
  label,
  options,
  direction = "col",
  required = false,
  disabled = false,
  size = "small",
  "data-testid": dataTestId
}: CheckboxProps) => {
  const [selectedItems, setSelectedItems] = useState<Array<string | number>>([
    ...control._defaultValues[name]
  ]);

  // handling the selection manually
  const handleSelect = (value: string | number) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter(
        (item: string | number) => item !== value
      );
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems: Array<string | number>) => [
        ...prevItems,
        value
      ]);
    }
  };

  // setting form value manually here
  useEffect(() => {
    setValue(name, selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (
      control._formValues[name]?.length &&
      // check value is equal or not
      JSON.stringify(selectedItems) !==
        JSON.stringify(control._formValues[name])
    ) {
      setSelectedItems(control._formValues[name]);
    }
  }, [control._formValues[name]]);

  return (
    <>
      {label ? (
        <FormLabel component="legend" required={required}>
          {label}
        </FormLabel>
      ) : (
        false
      )}
      <Controller
        name={name}
        control={control}
        render={({ fieldState: { error } }) => {
          return (
            <>
              <Stack
                data-testid={dataTestId}
                direction={direction === "col" ? "column" : "row"}
                width="max-content">
                {options.map(
                  (singleOption: {
                    label: string;
                    value: string | number;
                    disabled?: boolean;
                  }) => {
                    return (
                      <FormControlLabel
                        sx={{ width: "max-content" }}
                        control={
                          <Controller
                            name={name}
                            render={({ field: { value } }) => {
                              const testLabel = String(singleOption.label)
                                .toLowerCase()
                                .replace(/ /g, "_");
                              const testId = `${testLabel}_option`;
                              return (
                                <MuiCheckbox
                                  data-testid={testId}
                                  value={value}
                                  size={size}
                                  checked={
                                    selectedItems.includes(
                                      singleOption.value
                                    ) || singleOption.value === value?.[0]
                                  }
                                  onChange={() =>
                                    handleSelect(singleOption.value)
                                  }
                                  disabled={disabled || singleOption.disabled}
                                />
                              );
                            }}
                            control={control}
                          />
                        }
                        disabled={disabled}
                        label={singleOption.label}
                        key={singleOption.value}
                      />
                    );
                  }
                )}
              </Stack>
              {error ? (
                <FormHelperText error={!!error}>
                  {error?.message}
                </FormHelperText>
              ) : (
                false
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default Checkbox;
