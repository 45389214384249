import { useEffect } from "react";

import {
  Avatar,
  Grid,
  List,
  ListItem,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import useForceUpdate from "use-force-update";

import Paper from "@components/Paper";
import SideMenu from "@components/SideMenu";

import { LOCALE } from "@utils/config";
import { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

import Logo from "@assets/images/Logo.png";

const StyledLogo = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: 62,
    width: 62
  },
  [theme.breakpoints.up("sm")]: {
    height: 55,
    width: 55
  },
  [theme.breakpoints.up("lg")]: {
    height: 62,
    width: 62
  },
  "& .MuiAvatar-img": {
    objectFit: "contain"
  }
}));

const StyledUserTermsListItem = styled(ListItem)({
  "display": "inline-block",
  "&:before": {
    counterIncrement: "section",
    content: "counters(section, '.') '. '"
  }
});

const StyledUserTermsList = styled(List)({
  counterReset: "section",
  fontFamily:
    translate.getCurrentLocale() === LOCALE.JA
      ? "Noto Sans JP"
      : "Poppins, sans-serif"
});

const StyledUnorderedList = styled(List)({
  listStyleType: "disc",
  paddingLeft: 2,
  fontFamily:
    translate.getCurrentLocale() === LOCALE.JA
      ? "Noto Sans JP"
      : "Poppins, sans-serif"
});

const StyledUnorderedListItem = styled(ListItem)({ display: "list-item" });

const StyledTableContainer = styled(TableContainer)({
  width: "95%",
  marginX: "auto"
});

const StyledTableHeaderRow = styled(TableRow)({
  backgroundColor: colorPalette.black.hover[20]
});
const StyledTableBodyRow = styled(TableRow)({
  height: 40
});

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => {
  return {
    border: "1px solid " + theme.palette.text.secondary,
    textAlign: "center",
    width: 100
  };
});
const StyledTableBodyCell = styled(TableCell)(({ theme }) => {
  return {
    border: "1px solid " + theme.palette.text.secondary,
    textAlign: "center",
    padding: "0px 16px"
  };
});

const EmployerTNC = () => {
  const menuLinks = [
    {
      label: intl.get("t_recruiter_term"),
      redirectLink: "/employer-terms-and-conditions"
    },
    {
      label: intl.get("t_account_setting_side_menu_user_terms"),
      redirectLink: "/user-terms-and-conditions"
    },
    {
      label: intl.get("t_account_setting_side_menu_privacy_policy"),
      redirectLink: "/privacy-policy"
    }
  ];
  const forceUpdate = useForceUpdate();

  const languageChanged = () => {
    forceUpdate();
  };

  useEffect(() => {
    translate.addLanguageChangedListener(languageChanged);
    return () => {
      translate.removeLanguageChangedListener(languageChanged);
    };
  }, []);
  return (
    <>
      <Grid container mt={4} rowGap={3}>
        <Grid item xs={12} md={3} pr={{ xs: 0, md: 2 }}>
          <SideMenu menuLinks={menuLinks} />
        </Grid>
        <Grid item xs={12} md={9} pl={{ xs: 0, md: 2 }}>
          <Paper>
            <Typography variant="h3" mb={3} id="data-for-ssg">
              {intl.get("t_recruiter_term")}
            </Typography>
            <Paper elevation={3}>
              <Stack alignItems="center">
                <StyledLogo
                  variant="square"
                  src={Logo}
                  alt={intl.get("t_alt_tokhimo_job_logo")}
                />
                <Typography variant="h4" mt="40px">
                  {intl.get("t_recruiter_term_page_title")}
                </Typography>
                <StyledUserTermsList>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_one_title")}
                    <br />
                    {intl.get("t_recruiter_term_list_one_details_one")}
                    <br />
                    {intl.get("t_recruiter_term_list_one_details_two")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_two_title")}
                    <br />
                    {intl.get("t_recruiter_term_list_two_details")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_two_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_two_two")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_two_three")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_two_four")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_two_five")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_two_six")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_two_six_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_two_six_two")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_two_six_three")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_two_six_four")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_two_six_five")}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_two_seven")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_two_eight")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_two_eight_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_two_eight_two")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_two_eight_three")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_two_eight_four")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_two_eight_five")}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_two_nine")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_two_ten")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_three_title")}
                    <br />
                    {intl.get("t_recruiter_term_list_three_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_four_title")}
                    <br />
                    {intl.get("t_recruiter_term_list_four_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_five_title")}
                    <br />
                    {intl.get("t_recruiter_term_list_five_details_one")}
                    <br />
                    {intl.get("t_recruiter_term_list_five_details_two")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_six")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_six_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_six_two")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_six_three")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_six_four")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_six_five")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_seven")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_seven_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_seven_two")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_seven_three")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_seven_four")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_eight")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_eight_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_eight_two")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_eight_three")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_eight_four")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_eight_five")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_nine_title")}
                    <br />
                    {intl.get("t_recruiter_term_list_nine_details_one")}
                    <br />
                    {intl.get("t_recruiter_term_list_nine_details_two")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_ten_title")}
                    <br />
                    {intl.get("t_recruiter_term_list_ten_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_eleven")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_eleven_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_eleven_two")}
                      </StyledUserTermsListItem>
                      {/* FIXME: */}
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_eleven_three")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_eleven_four")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_eleven_five")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_twelve")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twelve_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twelve_two")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twelve_three")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twelve_four")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twelve_five")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_thirteen_title")}
                    <br />
                    {intl.get("t_recruiter_term_list_thirteen_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_fourteen")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_fourteen_one")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_fourteen_one_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_fourteen_one_two")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_fourteen_one_three"
                            )}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_fourteen_two_title")}
                        <br />
                        {intl.get("t_recruiter_term_list_fourteen_two_details")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_fourteen_three_title")}
                        <br />
                        {intl.get(
                          "t_recruiter_term_list_fourteen_three_details"
                        )}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_fourteen_three_one"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_fourteen_three_two"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_fourteen_three_three"
                            )}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_fifteen")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_fifteen_one")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_fifteen_one_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_fifteen_one_two")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_fifteen_one_three"
                            )}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_fifteen_two_title")}
                        <br />
                        {intl.get("t_recruiter_term_list_fifteen_two_details")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_sixteen_title")}
                    <br />
                    {intl.get("t_recruiter_term_list_sixteen_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_seventeen")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_seventeen_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_seventeen_two")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_eighteen")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_eighteen_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_eighteen_two")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_nineteen")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_nineteen_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_nineteen_two")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_nineteen_three")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_twenty")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twenty_one")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_twenty_one_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_twenty_one_two")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_twenty_one_three")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_recruiter_term_list_twenty_one_four")}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twenty_two")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twenty_three")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twenty_four")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_twentyone")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentyone_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentyone_two")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentyone_three")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentyone_four")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_twentytwo")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentytwo_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentytwo_two")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_twentythree")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentythree_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentythree_two")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentythree_two_one"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentythree_two_two"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentythree_two_three"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentythree_two_four"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentythree_two_five"
                            )}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentythree_three")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentythree_four")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentythree_five")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentythree_six")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_twentyfour_title")}
                    <br />
                    {intl.get("t_recruiter_term_list_twentyfour_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_twentyfive_title")}
                    <br />{" "}
                    {intl.get("t_recruiter_term_list_twentyfive_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_twentysix")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentysix_one")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentysix_one_one"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentysix_one_two"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentysix_one_three"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentysix_one_four"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentysix_one_five"
                            )}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentysix_two")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentysix_two_one"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentysix_two_two"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentysix_two_three"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentysix_two_four"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentysix_two_five"
                            )}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_twentyseven")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentyseven_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentyseven_two")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentyseven_two_one"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentyseven_two_two"
                            )}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentyseven_three")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentyseven_four")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentyseven_four_one"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentyseven_four_two"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentyseven_four_three"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentyseven_four_four"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentyseven_four_five"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentyseven_four_six"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentyseven_four_seven"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentyseven_four_eight"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentyseven_four_nine"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentyseven_four_ten"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_recruiter_term_list_twentyseven_four_eleven"
                            )}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentyseven_five")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentyseven_six")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_recruiter_term_list_twentyseven_seven")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_twentyeight_title")}
                    <br />
                    {intl.get("t_recruiter_term_list_twentyeight_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_twentynine_title")}
                    <br />
                    {intl.get("t_recruiter_term_list_twentynine_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_recruiter_term_list_thirty_title")}
                    <br />
                    {intl.get("t_recruiter_term_list_thirty_details")}
                  </StyledUserTermsListItem>
                </StyledUserTermsList>
              </Stack>
              <Typography variant="body1" pl={2} mt={3.75}>
                {intl.get("t_recruiter_term_supplementary_provisions")}
              </Typography>
              <Typography variant="body1" align="right" mt={12.5}>
                {intl.get("t_recruiter_term_attachment")}
              </Typography>
              <Stack>
                <Typography
                  variant="subtitle4"
                  align="center"
                  mt={6.25}
                  mb={3.75}>
                  {intl.get("t_recruiter_term_fee_structure")}
                </Typography>
                <StyledTableContainer>
                  <Table aria-label="fee structure table">
                    <TableBody>
                      <StyledTableHeaderRow>
                        <StyledTableHeaderCell>
                          {intl.get("t_recruiter_term_plan")}
                        </StyledTableHeaderCell>
                        <StyledTableHeaderCell>
                          {intl.get("t_recruiter_term_billing_model")}
                        </StyledTableHeaderCell>
                        <StyledTableHeaderCell>
                          {intl.get("t_recruiter_term_credit_usage")}
                        </StyledTableHeaderCell>
                        <StyledTableHeaderCell sx={{ width: "auto" }}>
                          {intl.get("t_recruiter_term_unit_price")}
                          <br />
                          {intl.get("t_recruiter_term_tax_excluded")}
                        </StyledTableHeaderCell>
                      </StyledTableHeaderRow>
                      <StyledTableBodyRow>
                        <StyledTableBodyCell>
                          {intl.get("t_recruiter_term_basic")}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {intl.get("t_recruiter_term_pay_per_use")}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {intl.get("t_recruiter_term_one")}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {intl.get("t_recruiter_term_thirty_thousand_yen")}
                        </StyledTableBodyCell>
                      </StyledTableBodyRow>
                    </TableBody>
                  </Table>
                </StyledTableContainer>
                <StyledUnorderedList>
                  <StyledUnorderedListItem>
                    {intl.get("t_recruiter_term_fee_content")}
                  </StyledUnorderedListItem>
                  <StyledUnorderedListItem>
                    {intl.get("t_recruiter_term_billing_method")}
                  </StyledUnorderedListItem>
                </StyledUnorderedList>
                <Typography variant="body1">
                  {intl.get("t_recruiter_term_pay_formula")}
                </Typography>
              </Stack>
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default EmployerTNC;
