import SkeletonTypography from "@skeletons/SkeletonTypography";

import Well from "@components/Well";

interface SkeletonEducationCardProps {
  mode?: "edit" | "view";
}

const SkeletonEducationCard = ({
  mode = "view"
}: SkeletonEducationCardProps) => {
  return (
    <Well showBorder={mode === "edit" ? true : false}>
      <SkeletonTypography variant="subtitle4" width="medium" />
      <SkeletonTypography variant="body1" width="short" />
      <SkeletonTypography variant="body1" width="short" />
      <SkeletonTypography variant="body1" width="medium" />
    </Well>
  );
};

export default SkeletonEducationCard;
