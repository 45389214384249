import { ReactIntlUniversal } from "react-intl-universal";

import { Stack } from "@mui/material";

import ActionButton from "@components/ActionButton";
import InformationField from "@components/InformationField";
import Well from "@components/Well";

import { intl as intlGlobal } from "@utils/translate";

interface NoJobExperienceOverviewCardProps {
  handleEdit?: () => void;
  mode?: "edit" | "view";
  intl?: typeof intlGlobal | ReactIntlUniversal;
}

const NoJobExperienceOverviewCard = ({
  handleEdit,
  mode = "view",
  intl = intlGlobal
}: NoJobExperienceOverviewCardProps) => {
  return (
    <Well showBorder={true}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height={40}
        width="100%">
        <InformationField
          icon="work"
          label={intl.get("t_general_fresher")}
          variant="subtitle4"
        />
        {mode === "edit" ? (
          <ActionButton icon="edit" color="blue" handleAction={handleEdit} />
        ) : (
          false
        )}
      </Stack>
    </Well>
  );
};

export default NoJobExperienceOverviewCard;
