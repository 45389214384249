import React, { useEffect, useState } from "react";

import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import Loader from "@components/Loader";
import Typography from "@components/Typography";

import useToast from "@hooks/useToast";

import {
  FIRESTORE_COLLECTIONS,
  JOB_CONTRACT_TYPE_T_LABELS,
  JOB_REMOTE_WORK_TYPE_T_LABELS,
  JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS,
  LANGUAGE_PROFICIENCY_T_LABELS,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import { auth, db } from "@utils/firebase";
import { intl } from "@utils/translate";

const SavedJobFilters = () => {
  const [user] = useAuthState(auth);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [rowData, setRowData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();

  const fetchSavedJobFilters = async () => {
    setLoading(true);
    const savedFiltersSubCollectionRef = collection(
      db,
      `${FIRESTORE_COLLECTIONS.USERS}/${user?.uid}/${FIRESTORE_COLLECTIONS.SAVED_JOB_FILTERS}`
    );
    const savedFiltersDocs = await getDocs(savedFiltersSubCollectionRef);
    if (!savedFiltersDocs.empty) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const tableRowData: Array<any> = [];
      for (let index = 0; index < savedFiltersDocs.docs.length; index++) {
        const savedFiltersSingleDocs = savedFiltersDocs.docs[index];
        if (savedFiltersSingleDocs?.exists()) {
          const savedFiltersSingleDocsData = savedFiltersSingleDocs.data();
          tableRowData.push({
            id: savedFiltersSingleDocs.id,
            filters: {
              ...(savedFiltersSingleDocsData.keyword
                ? { keyword: savedFiltersSingleDocsData.keyword }
                : {}),
              ...(savedFiltersSingleDocsData.ja_level
                ? { japaneseLevel: savedFiltersSingleDocsData.ja_level }
                : {}),
              ...(savedFiltersSingleDocsData.en_level
                ? { englishLevel: savedFiltersSingleDocsData.en_level }
                : {}),
              ...(savedFiltersSingleDocsData.min_salary
                ? { minSalary: savedFiltersSingleDocsData.min_salary }
                : {}),
              ...(savedFiltersSingleDocsData.location
                ? { location: savedFiltersSingleDocsData.location }
                : {}),
              ...(savedFiltersSingleDocsData.min_experience
                ? { minExperience: savedFiltersSingleDocsData.min_experience }
                : {}),
              ...(savedFiltersSingleDocsData.contract_type
                ? { contractType: savedFiltersSingleDocsData.contract_type }
                : {}),
              ...(savedFiltersSingleDocsData.remote_allowed
                ? { remoteAllowed: savedFiltersSingleDocsData.remote_allowed }
                : {}),
              ...(savedFiltersSingleDocsData.visa_sponsorship
                ? {
                    visaSponsorship: savedFiltersSingleDocsData.visa_sponsorship
                  }
                : {}),
              ...(savedFiltersSingleDocsData.skills
                ? {
                    skills: savedFiltersSingleDocsData.skills
                  }
                : {})
            }
          });
        }
      }
      setRowData(tableRowData);
      setLoading(false);
    } else {
      setLoading(false);
      setRowData([]);
    }
  };

  useEffect(() => {
    fetchSavedJobFilters();
  }, []);

  const handleDelete = async (filterId: string) => {
    try {
      setLoading(true);
      const filterDocRef = doc(
        db,
        `${FIRESTORE_COLLECTIONS.USERS}/${user?.uid}/${FIRESTORE_COLLECTIONS.SAVED_JOB_FILTERS}`,
        filterId
      );
      await deleteDoc(filterDocRef);
      // Update the state to remove the deleted filter from UI
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setRowData((prev: any) => prev.filter((row: any) => row.id !== filterId));
      setLoading(false);
      toast.kampai("Filter deleted successfully", "success");
    } catch (error) {
      setLoading(false);
      toast.kampai("Failed to delete", "error");
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {user ? (
        <>
          {rowData?.length === 0 ? (
            <Typography>Filters not found, Please save filter</Typography>
          ) : (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr. No.</TableCell>
                    <TableCell>Filter ID</TableCell>
                    <TableCell>Filters</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {rowData?.map((singleRowData: any, idx: number) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell>{idx + 1}</TableCell>
                        <TableCell>{singleRowData?.id}</TableCell>
                        <TableCell>
                          <Table>
                            <TableBody>
                              {singleRowData?.filters?.keyword ? (
                                <TableRow>
                                  <TableCell>Keyword</TableCell>
                                  <TableCell>
                                    {singleRowData.filters.keyword}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                false
                              )}
                              {singleRowData?.filters?.japaneseLevel ? (
                                <TableRow>
                                  <TableCell>Japanese Level</TableCell>
                                  <TableCell>
                                    {singleRowData?.filters?.japaneseLevel.map(
                                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                      (singleJaLevel: any, index: number) => {
                                        return (
                                          <>
                                            {intl.get(
                                              LANGUAGE_PROFICIENCY_T_LABELS[
                                                singleJaLevel as keyof typeof LANGUAGE_PROFICIENCY_T_LABELS
                                              ]
                                            )}
                                            {index <
                                            singleRowData?.filters
                                              ?.japaneseLevel?.length -
                                              1
                                              ? ", "
                                              : false}
                                          </>
                                        );
                                      }
                                    )}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                false
                              )}
                              {singleRowData?.filters?.englishLevel ? (
                                <TableRow>
                                  <TableCell>English Level</TableCell>
                                  <TableCell>
                                    {singleRowData?.filters?.englishLevel.map(
                                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                      (singleEnLevel: any, index: number) => {
                                        return (
                                          <>
                                            {intl.get(
                                              LANGUAGE_PROFICIENCY_T_LABELS[
                                                singleEnLevel as keyof typeof LANGUAGE_PROFICIENCY_T_LABELS
                                              ]
                                            )}
                                            {index <
                                            singleRowData?.filters?.englishLevel
                                              ?.length -
                                              1
                                              ? ", "
                                              : false}
                                          </>
                                        );
                                      }
                                    )}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                false
                              )}
                              {singleRowData?.filters?.minExperience ? (
                                <TableRow>
                                  <TableCell>Minimum Experience</TableCell>
                                  <TableCell>
                                    {intl.get(
                                      YEARS_OF_EXPERIENCE_T_LABELS[
                                        singleRowData?.filters
                                          ?.minExperience as keyof typeof YEARS_OF_EXPERIENCE_T_LABELS
                                      ]
                                    )}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                false
                              )}
                              {singleRowData?.filters?.skills ? (
                                <TableRow>
                                  <TableCell>Skills</TableCell>
                                  <TableCell>
                                    {singleRowData?.filters?.skills.map(
                                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                      (singleSkill: any, index: number) => {
                                        return (
                                          <>
                                            {singleSkill?.label}
                                            {index <
                                            singleRowData?.filters?.skills
                                              ?.length -
                                              1
                                              ? ", "
                                              : false}
                                          </>
                                        );
                                      }
                                    )}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                false
                              )}
                              {singleRowData?.filters?.minSalary ? (
                                <TableRow>
                                  <TableCell>Minimum Salary</TableCell>
                                  <TableCell>
                                    {`${singleRowData?.filters?.minSalary}`}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                false
                              )}
                              {singleRowData?.filters?.remoteAllowed ? (
                                <TableRow>
                                  <TableCell>Remote Allowed</TableCell>
                                  <TableCell>
                                    {singleRowData?.filters?.remoteAllowed.map(
                                      (
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        singleRemoteAllowed: any,
                                        index: number
                                      ) => {
                                        return (
                                          <>
                                            {intl.get(
                                              JOB_REMOTE_WORK_TYPE_T_LABELS[
                                                singleRemoteAllowed as keyof typeof JOB_REMOTE_WORK_TYPE_T_LABELS
                                              ]
                                            )}
                                            {index <
                                            singleRowData?.filters
                                              ?.remoteAllowed?.length -
                                              1
                                              ? ", "
                                              : false}
                                          </>
                                        );
                                      }
                                    )}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                false
                              )}

                              {singleRowData?.filters?.contractType ? (
                                <TableRow>
                                  <TableCell>Contract type</TableCell>
                                  <TableCell>
                                    {singleRowData?.filters?.contractType.map(
                                      (
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        singleContractType: any,
                                        index: number
                                      ) => {
                                        return (
                                          <>
                                            {intl.get(
                                              JOB_CONTRACT_TYPE_T_LABELS[
                                                singleContractType as keyof typeof JOB_CONTRACT_TYPE_T_LABELS
                                              ]
                                            )}
                                            {index <
                                            singleRowData?.filters?.contractType
                                              ?.length -
                                              1
                                              ? ", "
                                              : false}
                                          </>
                                        );
                                      }
                                    )}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                false
                              )}

                              {singleRowData?.filters?.visaSponsorship ? (
                                <TableRow>
                                  <TableCell>Visa Sponsorship</TableCell>
                                  <TableCell>
                                    {intl.get(
                                      JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS[
                                        singleRowData?.filters
                                          ?.visaSponsorship as keyof typeof JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS
                                      ]
                                    )}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                false
                              )}
                              {singleRowData?.filters?.location ? (
                                <TableRow>
                                  <TableCell>Location</TableCell>
                                  <TableCell>
                                    {singleRowData?.filters?.location?.label}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                false
                              )}
                            </TableBody>
                          </Table>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            disabled={loading}
                            onClick={() => handleDelete(singleRowData.id)}
                            color="error"
                            size="small">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      ) : (
        <Typography>Not Logged In</Typography>
      )}
    </>
  );
};

export default SavedJobFilters;
