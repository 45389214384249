import { ReactNode } from "react";

import { TypographyProps } from "@mui/material";

import Typography from "@components/Typography";

import { ELLIPSIS_TYPOGRAPHY_DEFAULT_VALUES } from "@utils/config";

interface EllipsisTypographyProps {
  children: ReactNode | string;
  lineClamp?: number;
  color?: TypographyProps["color"];
  variant?: TypographyProps["variant"];
}

const EllipsisTypography = ({
  children,
  lineClamp = ELLIPSIS_TYPOGRAPHY_DEFAULT_VALUES.lineClamp,
  color = ELLIPSIS_TYPOGRAPHY_DEFAULT_VALUES.color,
  variant
}: EllipsisTypographyProps) => {
  return (
    <Typography
      variant={variant}
      color={color}
      sx={{
        wordBreak: "break-word",
        display: "-webkit-box",
        WebkitLineClamp: lineClamp,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}>
      {children}
    </Typography>
  );
};

export default EllipsisTypography;
