import { MouseEvent, useState } from "react";

import { Info as InfoIcon } from "@mui/icons-material";
import { Alert, Box, Collapse, Container, Link, styled } from "@mui/material";

import Typography from "@components/Typography";

import Email from "@interfaces/database/Email";

import { colorPalette } from "@utils/theme";
import { intl } from "@utils/translate";

interface EmailNotVerifiedBannerProps {
  email?: Email;
  handleResend?: () => void;
}

const StyledAlert = styled(Alert)({
  boxShadow: "none !important",
  wordBreak: "break-word"
});

const EmailNotVerifiedBanner = ({
  email = "",
  handleResend = () => {
    /* no-op */
  }
}: EmailNotVerifiedBannerProps) => {
  const [isOpen, setOpen] = useState<boolean>(true);

  const resend = (e: MouseEvent) => {
    e.preventDefault();
    handleResend();
  };

  return (
    <Box
      data-testid="email_not_verified_banner_box"
      width="100%"
      bgcolor={colorPalette.yellow.background.base}
      boxShadow={colorPalette.yellow.boxShadow}
      zIndex={1}>
      <Collapse in={isOpen}>
        <Container>
          <StyledAlert
            severity="warning"
            variant="filled"
            icon={<InfoIcon />}
            onClose={() => setOpen(false)}>
            <Typography variant="body1">
              {intl.get("t_verify_not_verified_banner", { email })}
            </Typography>
            &nbsp;
            <Typography variant="subtitle4">
              <Link href="#" onClick={resend} color="inherit">
                {intl.get("t_verify_email_resend")}
              </Link>
            </Typography>
          </StyledAlert>
        </Container>
      </Collapse>
    </Box>
  );
};

export default EmailNotVerifiedBanner;
