import {
  Skeleton,
  Stack,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from "@mui/material";

import SkeletonTypography from "@skeletons/SkeletonTypography";

import DataTableWrapper from "@components/DataTable/DataTableWrapper";
import Typography from "@components/Typography";

import { PAGINATION } from "@utils/config";
import { intl } from "@utils/translate";

interface SkeletonResumePurchaseDataTableProps {
  rows?: number;
}

interface Column {
  id: "dateOfPurchase" | "candidateName";
  label: string;
  align?: "left" | "right" | "center";
}

const COLUMNS: ReadonlyArray<Column> = [
  {
    id: "dateOfPurchase",
    label: "t_employer_resume_purchase_table_date_of_purchase",
    align: "left"
  },
  {
    id: "candidateName",
    label: "t_employer_resume_purchase_table_candidate_name"
  }
];

const SkeletonResumePurchaseDataTable = ({
  rows = PAGINATION.TABLE_ROWS_PER_PAGE[0]
}: SkeletonResumePurchaseDataTableProps) => {
  const ROWS = new Array(rows).fill({
    dateOfPurchase: <SkeletonTypography variant="body2" width="short" />,
    candidateName: <SkeletonTypography variant="body2" width="short" />
  });

  return (
    <DataTableWrapper>
      <TableHead>
        <TableRow>
          {COLUMNS?.map((singleColumn) => (
            <TableCell
              key={singleColumn.id}
              width="20%"
              align={singleColumn?.align ?? "center"}>
              <Typography variant="subtitle5">
                {singleColumn.label ? intl.get(singleColumn.label) : ""}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {ROWS?.map((singleRow, index) => (
          <TableRow key={index}>
            {COLUMNS?.map((singleColumn) => (
              <TableCell
                key={singleColumn.id}
                align={singleColumn.align ?? "center"}>
                {singleRow[singleColumn.id]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
      <TableFooter sx={{ mr: 0 }}>
        <TableRow>
          <TableCell colSpan={5}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={3.25}
              height={48}>
              <Skeleton variant="rounded" height="1rem" width="6rem" />
              <Skeleton variant="rounded" height="1rem" width="4rem" />
              <Skeleton variant="rounded" height="1rem" width="2rem" />
              <Skeleton variant="rounded" height="1rem" width="2rem" />
            </Stack>
          </TableCell>
        </TableRow>
      </TableFooter>
    </DataTableWrapper>
  );
};

export default SkeletonResumePurchaseDataTable;
