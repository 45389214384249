import { Fragment, useEffect, useState } from "react";

import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddCircle as AddCircleIcon,
  Add as AddIcon,
  Close as CloseIcon,
  CurrencyYen as CurrencyYenIcon,
  NavigateBefore as NavigateBeforeIcon
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography
} from "@mui/material";
import { FirebaseError } from "firebase/app";
import { httpsCallable, HttpsCallableResult } from "firebase/functions";
import parsePhoneNumberFromString from "libphonenumber-js";
import { useAuthState } from "react-firebase-hooks/auth";
import { MultiSearchResponse } from "typesense/lib/Typesense/MultiSearch";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import SkeletonDesktopProfileSummaryForm from "@skeletons/SkeletonDesktopProfileSummaryForm";
import SkeletonKeyword from "@skeletons/SkeletonKeyword";

import AutoCompleteTextField from "@components/AutoCompleteTextField";
import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import Cursor from "@components/Cursor";
import Icon from "@components/Icon";
import Keyword from "@components/Keyword";
import NonTypeableSelect from "@components/NonTypeableSelect";
import NumberInput from "@components/NumberInput";
import Paper from "@components/Paper";
import Radio from "@components/Radio";
import Tag from "@components/Tag";
import TelephoneInput from "@components/TelephoneInput";
import TextField from "@components/TextField";
import TypeableSelect from "@components/TypeableSelect";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import ContextUserSummary from "@interfaces/components/ContextUserSummary";
import KeyLabel from "@interfaces/components/KeyLabel";
import SkillData from "@interfaces/components/SkillData";
import Skill from "@interfaces/database/Skill";
import TypesenseSkill from "@interfaces/database/TypesenseSkill";

import {
  FREE_TEXT_FIELD_MAX_LENGTH,
  INDIVIDUAL_USER_NUMBER_OF_COMPANIES,
  INDIVIDUAL_USER_NUMBER_OF_COMPANIES_T_LABELS,
  INDIVIDUAL_USER_VISA_TYPE,
  INDIVIDUAL_USER_VISA_TYPE_T_LABELS,
  JA_ALPHA3_CODE,
  JA_LANGUAGE_TEST,
  JA_LANGUAGE_TEST_T_LABELS,
  JP_ALPHA3_CODE,
  LANGUAGE_PROFICIENCY,
  LANGUAGE_PROFICIENCY_T_LABELS,
  MAX_EXPECTED_SALARY,
  MAX_LANGUAGES,
  MAX_SKILLS,
  MAX_WORK_EXPERIENCES,
  OTHER_LANGUAGE_PROFICIENCY,
  OTHER_LANGUAGE_PROFICIENCY_T_LABELS,
  PROFILE_SUMMARY_NO_EXPERIENCE_CHECKBOX_VALUES,
  RELATED_SKILL_LIST_MAX_SIZE,
  SKILL_FIELD_MAX_LENGTH,
  TYPESENSE_COLLECTIONS,
  YEARS_OF_EXPERIENCE,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import { auth, functions } from "@utils/firebase";
import { jaTestLevelOptionsFinder } from "@utils/jaTestLevelOptionsFinder";
import { getCityList } from "@utils/keyLabelHandlers/location";
import { getSkillLabel, getSkillList } from "@utils/keyLabelHandlers/skill";
import { getDropdownLanguageList, getLanguageName } from "@utils/language";
import { getCountryName, getDropdownCountryList } from "@utils/location";
import { throttle } from "@utils/lodash";
import { addSkill, isSkillExistsByKey } from "@utils/metaSkills";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";
import { colorPalette } from "@utils/theme";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";
import getClient from "@utils/typesense";

interface ProfileSummaryFormData {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  countryOfOrigin?: KeyLabel;
  visaType?: KeyLabel;
  country?: KeyLabel;
  city?: KeyLabel;
  numberOfCompanies?:
    | typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES[keyof typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES]
    | string;
  expectedSalary?: number;
  noExperience: number[];
  primaryJobTitle: number;
  jobOverview: Array<{
    jobTitle: string;
    yearOfExperience:
      | typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE]
      | string;
  }>;
  jaLanguage: {
    name: KeyLabel;
    proficiency: typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY];
    testName: string;
    testResult: string;
  };
  language: Array<{
    name: KeyLabel;
    proficiency: typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY];
    testName: string;
    testResult: string;
  }>;
  skills: Array<SkillData>;
}

const StyledIconButton = styled(IconButton)(() => ({
  marginTop: "2.5rem"
}));

export const StyledTransitionGroup = styled(TransitionGroup)(() => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: 8
}));

const emptySkill = {
  name: { key: "", label: "" },
  yearOfExperience: ""
};

const DesktopProfileSummaryForm = () => {
  const userData = useUserProfile();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isRecommendedSkillsLoading, setIsRecommendedSkillsLoading] =
    useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [languageFieldIdsArr, setLanguageFieldIdsArr] = useState<Array<string>>(
    []
  );
  const [jobOverviewFieldIdsArr, setJobOverviewFieldIdsArr] = useState<
    Array<string>
  >([uuidv4()]);
  const currentLocale = translate.getCurrentLocale();

  const userBasicInformation = userData.value?.summary?.basic_information;
  const userJobOverview =
    userData.value?.summary?.job_experience_overview ?? [];
  const userNoExperienceFlag =
    userData.value?.summary?.no_job_experience_overview === undefined
      ? false
      : userData.value?.summary?.no_job_experience_overview;
  const userPrimaryJobTitleIndex =
    userData.value?.summary?.basic_information?.primary_job_title_index ===
    undefined
      ? 0
      : userData.value?.summary?.basic_information?.primary_job_title_index;
  const userLanguages = userData.value?.summary?.languages ?? [];
  const userSkills = userData.value?.summary?.skills;

  const [recommendedSkillListArr, setRecommendedSkillListArr] = useState<
    Array<KeyLabel>
  >([]);
  const [selectedSkillKey, setSelectedSkillKey] = useState<string>("");

  useEffect(() => {
    if (userLanguages && userLanguages?.length > 0) {
      const additionalLanguages = userLanguages.length - 1;
      setLanguageFieldIdsArr(
        Array.from({ length: additionalLanguages }, () => uuidv4())
      );
    }
  }, [userLanguages]);

  useEffect(() => {
    if (userJobOverview && userJobOverview?.length > 0) {
      const additionalJobOverviews = userJobOverview.length;
      setJobOverviewFieldIdsArr(
        Array.from({ length: additionalJobOverviews }, () => uuidv4())
      );
    }
  }, [userJobOverview]);

  // validation schema
  const schema = yup.object({
    firstName: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_first_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_first_name")
        })
      ),
    lastName: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_last_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_last_name")
        })
      ),
    phoneNumber: yup
      .string()
      .test(
        "phone",
        intl.get("t_error_invalid", {
          field: intl.get("t_general_phone_number")
        }),
        (value) => {
          // Empty error will be handled separately.
          if (!value) {
            return true;
          }
          const phoneNumber = parsePhoneNumberFromString(value);
          if (!phoneNumber) {
            return false;
          }
          return phoneNumber.isValid();
        }
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_phone_number")
        })
      ),
    countryOfOrigin: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .test(
        "countryOfOrigin",
        intl.get("t_error_required", {
          field: intl.get("t_profile_basic_info_nationality")
        }),
        (value) => (value?.key && value?.label ? true : false)
      )
      .nullable(),
    visaType: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .nullable(),
    country: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .test(
        "country",
        intl.get("t_error_required", {
          field: intl.get("t_general_country")
        }),
        (value) => (value?.key && value?.label ? true : false)
      )
      .nullable(),
    city: yup
      .object()
      .shape({
        key: yup.string().trim(),
        label: yup.string().trim()
      })
      .test(
        "label",
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_city"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        }),
        (value) => {
          return value && value.label
            ? value.label.length <= FREE_TEXT_FIELD_MAX_LENGTH
            : true;
        }
      )
      .nullable(),
    numberOfCompanies: yup.string().required(
      intl.get("t_error_required", {
        field: intl.get("t_profile_basic_info_number_of_companies")
      })
    ),
    expectedSalary: yup
      .number()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_profile_basic_info_expected_salary")
        })
      )
      .min(0)
      .max(
        MAX_EXPECTED_SALARY,
        intl.get("t_error_expected_salary_limit", {
          salary: MAX_EXPECTED_SALARY
        })
      )
      .nullable()
      .transform((expectedSalary) => {
        return isNaN(expectedSalary) ||
          expectedSalary === null ||
          expectedSalary === undefined
          ? null
          : expectedSalary;
      }),
    noExperience: yup.array().test(
      "noExperience",
      intl.get("t_error_invalid", {
        field: intl.get("t_profile_job_overview_no_job_experience_check")
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (value: any) => {
        if (
          value?.length === 0 ||
          value?.includes(
            PROFILE_SUMMARY_NO_EXPERIENCE_CHECKBOX_VALUES.NO_EXPERIENCE_CHECKED
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    ),
    jobOverview: yup.array().when("noExperience", {
      is: (noExperience: Array<number>) => noExperience?.includes(1),
      then: (schema) => {
        return schema.of(
          yup.object({
            jobTitle: yup.string().trim(),
            yearOfExperience: yup.string()
          })
        );
      },
      otherwise: (schema) => {
        return schema.of(
          yup.object({
            jobTitle: yup
              .string()
              .trim()
              .max(
                FREE_TEXT_FIELD_MAX_LENGTH,
                intl.get("t_error_max_limit", {
                  field: intl.get("t_profile_job_overview_job_title"),
                  maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
                })
              )
              .required(
                intl.get("t_error_required", {
                  field: intl.get("t_profile_job_overview_job_title")
                })
              ),
            yearOfExperience: yup.string().required(
              intl.get("t_error_required", {
                field: intl.get("t_profile_job_overview_year_of_experience")
              })
            )
          })
        );
      }
    }),
    jaLanguage: yup.object({
      name: yup
        .object()
        .shape({
          key: yup.string(),
          label: yup.string()
        })
        .test(
          "name",
          intl.get("t_error_required", {
            field: intl.get("t_profile_language_heading")
          }),
          (value) => (value?.key && value?.label ? true : false)
        )
        .nullable(),
      proficiency: yup
        .string()
        .required(
          intl.get("t_error_required", {
            field: intl.get("t_profile_language_proficiency")
          })
        )
        .nullable(),
      testName: yup.string(),
      testResult: yup.string().when("testName", {
        is: (testName: string) => testName?.trim() !== "",
        then: (schema) =>
          schema.required(
            intl.get("t_error_required", {
              field: intl.get("t_profile_language_test_result")
            })
          )
      })
    }),
    language: yup
      .array()
      .of(
        yup.object().shape(
          {
            name: yup
              .object()
              .shape({
                key: yup.string(),
                label: yup.string()
              })
              .test(
                "name",
                intl.get("t_error_required", {
                  field: intl.get("t_profile_language_heading")
                }),
                (value) => (value?.key && value?.label ? true : false)
              )
              .nullable(),
            proficiency: yup
              .string()
              .required(
                intl.get("t_error_required", {
                  field: intl.get("t_profile_language_proficiency")
                })
              )
              .nullable(),
            testName: yup
              .string()
              .trim()
              .max(
                FREE_TEXT_FIELD_MAX_LENGTH,
                intl.get("t_error_max_limit", {
                  field: intl.get("t_profile_language_test"),
                  maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
                })
              )
              .when("testResult", {
                is: (testResult: string) => testResult?.trim() !== "",
                then: (schema) =>
                  schema.required(
                    intl.get("t_error_required", {
                      field: intl.get("t_profile_language_test")
                    })
                  )
              }),
            testResult: yup
              .string()
              .trim()
              .max(
                FREE_TEXT_FIELD_MAX_LENGTH,
                intl.get("t_error_max_limit", {
                  field: intl.get("t_profile_language_test_result"),
                  maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
                })
              )
              .when("testName", {
                is: (testName: string) => testName?.trim() !== "",
                then: (schema) =>
                  schema.required(
                    intl.get("t_error_required", {
                      field: intl.get("t_profile_language_test_result")
                    })
                  )
              })
          },
          [["testName", "testResult"]]
        )
      )
      .required(),
    skills: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .object()
            .shape({
              key: yup.string().trim(),
              label: yup.string().trim()
            })
            .test(
              "label",
              intl.get("t_error_max_limit", {
                field: intl.get("t_general_skill"),
                maxLimit: SKILL_FIELD_MAX_LENGTH
              }),
              (value) => {
                return value && value.label
                  ? value.label.length <= SKILL_FIELD_MAX_LENGTH
                  : true;
              }
            )
            .test(
              "is-required",
              intl.get("t_error_required", {
                field: intl.get("t_general_skill")
              }),

              (
                skillName,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                context: any
              ) => {
                // check minimum 1 value is entered or not
                if (
                  context?.path === "skills[0].name" &&
                  context?.originalValue
                ) {
                  if (
                    !context?.originalValue?.key ||
                    !context?.originalValue?.label
                  ) {
                    return false;
                  }
                }

                // check yearOfExperience is entered then skill name is required
                const { yearOfExperience } = context.parent as SkillData;
                if (yearOfExperience) {
                  return !!skillName?.key;
                }
                return true;
              }
            )
            .test(
              "label",
              intl.get("t_error_max_limit", {
                field: intl.get("t_general_skill"),
                maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
              }),
              (value) => {
                return value && value.label
                  ? value.label.length <= FREE_TEXT_FIELD_MAX_LENGTH
                  : true;
              }
            )
            .nullable(),
          yearOfExperience: yup
            .string()
            .nullable()
            .test(
              "is-required",
              intl.get("t_error_required", {
                field: intl.get("t_profile_job_overview_year_of_experience")
              }),
              (
                yearOfExperience,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                context: any
              ) => {
                // check minimum 1 value is entered or not
                if (
                  context?.path === "skills[0].yearOfExperience" &&
                  !context?.originalValue
                ) {
                  return false;
                }

                // check skill name is entered then yearOfExperience is required
                const { name } = context.parent as SkillData;
                if (name?.key) {
                  return !!yearOfExperience && yearOfExperience.length > 0;
                }
                return true;
              }
            )
        })
      )
      .test("is-duplicate", "", (skills, { createError }) => {
        if (skills) {
          const skillLabels = new Set<string>();

          for (const singleSkill of skills) {
            // skip if the skill is not entered
            if (!singleSkill.name || !singleSkill.name.label) {
              continue;
            }

            if (skillLabels.has(singleSkill.name.label.toLowerCase())) {
              // check if duplicate skill is entered and return error
              return createError({
                path: `skills[${skills.indexOf(singleSkill)}].name`,
                message: intl.get("t_error_duplicate_value", {
                  value: singleSkill.name.label
                })
              });
            }

            skillLabels.add(singleSkill.name.label.toLowerCase());
          }
          return true;
        } else {
          return true;
        }
      })
  });

  const formInitValues = {
    firstName: resolveMultiLingual(userBasicInformation?.first_name),
    lastName: resolveMultiLingual(userBasicInformation?.last_name),
    phoneNumber: userBasicInformation?.phone_number ?? "",
    countryOfOrigin: {
      key: userBasicInformation?.immigration_details?.country ?? "",
      label:
        getCountryName(
          userBasicInformation?.immigration_details?.country ?? ""
        ) ?? ""
    },
    visaType: {
      key: userBasicInformation?.immigration_details?.visa_type ?? "",
      label: userBasicInformation?.immigration_details?.visa_type
        ? intl.get(
            INDIVIDUAL_USER_VISA_TYPE_T_LABELS[
              userBasicInformation?.immigration_details
                ?.visa_type as keyof typeof INDIVIDUAL_USER_VISA_TYPE_T_LABELS
            ]
          )
        : ""
    },
    country: {
      key: userBasicInformation?.current_location?.country ?? "",
      label:
        getCountryName(userBasicInformation?.current_location?.country ?? "") ??
        ""
    },
    city: {
      key: userBasicInformation?.current_location?.city ?? "",
      label: userBasicInformation?.current_location?.city ?? ""
    },
    numberOfCompanies: userBasicInformation?.number_of_companies ?? "",
    expectedSalary: Number(userBasicInformation?.expected_salary) ?? "",
    noExperience: userNoExperienceFlag ? [1] : [], // by default [] and if user checked the checkbox it will become [1]
    primaryJobTitle: userPrimaryJobTitleIndex, // by default 0 and if user checked the radio it will refer index of jobOverview array
    jobOverview:
      userJobOverview && userJobOverview?.length > 0 && !userNoExperienceFlag
        ? userJobOverview.map((singleJobOverview) => {
            return {
              jobTitle: resolveMultiLingual(singleJobOverview?.job_title) ?? "",
              yearOfExperience: singleJobOverview.years_of_experience
            };
          })
        : [
            {
              jobTitle: "",
              yearOfExperience: ""
            }
          ],
    jaLanguage: {
      name: {
        key: JA_ALPHA3_CODE,
        label: getLanguageName(JA_ALPHA3_CODE) ?? ""
      },
      proficiency: userLanguages?.[0]?.proficiency ?? "",
      testName: userLanguages?.[0]?.test_name ?? "",
      testResult: userLanguages?.[0]?.test_result ?? ""
    },
    language:
      // ignore first language(Japanese) from array
      userLanguages && userLanguages?.length >= 1
        ? userLanguages
            ?.slice(1)
            .filter(
              (singleLanguageInfo) =>
                singleLanguageInfo?.name && singleLanguageInfo?.proficiency
            )
            .map((singleLanguageInfo) => {
              return {
                name: {
                  key: singleLanguageInfo?.name ?? JA_ALPHA3_CODE,
                  label:
                    getLanguageName(
                      singleLanguageInfo?.name ?? JA_ALPHA3_CODE
                    ) ?? ""
                },
                proficiency: singleLanguageInfo?.proficiency ?? "",
                testName: singleLanguageInfo?.test_name ?? "",
                testResult: singleLanguageInfo?.test_result ?? ""
              };
            })
        : [],
    skills: [emptySkill]
  };

  const methods = useForm({
    defaultValues: formInitValues,
    resolver: yupResolver(schema)
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    reset,
    getValues,
    formState,
    setFocus
  } = methods;

  const jobOverviewFieldArr = useFieldArray({
    control,
    name: "jobOverview"
  });
  const languageFieldArr = useFieldArray({
    control,
    name: "language"
  });
  const skillsFieldArr = useFieldArray({
    control,
    name: "skills"
  });

  const selectedCountryIsoCode = useWatch({
    control,
    name: "country.key"
  });
  const selectedCountryOfOriginIsoCode = useWatch({
    control,
    name: "countryOfOrigin.key"
  });
  const selectedJaTestName = watch("jaLanguage.testName");

  const jobOverview = useWatch({
    control,
    name: "jobOverview"
  });

  const skills = useWatch({
    control,
    name: "skills"
  });

  useEffect(() => {
    // check selected country is modified or not
    if (formState.dirtyFields.country) {
      setValue("city", { key: "", label: "" });
    }

    if (formState.dirtyFields?.jaLanguage?.testName) {
      setValue("jaLanguage.testResult", "");
    }

    // clear visa type value if country of origin is Japan
    if (selectedCountryOfOriginIsoCode === JP_ALPHA3_CODE) {
      setValue("visaType", { key: "", label: "" });
    }
  }, [
    selectedCountryIsoCode,
    selectedJaTestName,
    selectedCountryOfOriginIsoCode,
    setValue
  ]);

  useEffect(() => {
    const getInitialSkillValues = async () => {
      setIsLoading(true);
      const initialSkills: Array<SkillData> = [];
      if (userSkills) {
        const userSkillKeys: Array<string> = [];
        for (let i = 0; i < userSkills?.length; i++) {
          const singleSkill = userSkills[i];
          initialSkills.push({
            name: {
              key: singleSkill.name,
              label: await getSkillLabel(
                singleSkill?.name,
                translate.getCurrentLocale()
              )
            },
            yearOfExperience: singleSkill?.years_of_experience
          });
          userSkillKeys.push(singleSkill.name);
        }
        updateRecommendedSkills(userSkillKeys);
      }
      reset({ ...formInitValues, skills: initialSkills });
      // append empty object to make it initially empty
      skillsFieldArr.append(emptySkill);
      setIsLoading(false);
    };
    getInitialSkillValues();
  }, [userData]);

  const isNoExperience = watch("noExperience")?.includes(1); // array contain 1 => no experience checkbox checked else unchecked
  const primaryJobTitleIndex = Number(watch("primaryJobTitle"));

  useEffect(() => {
    // if no field then check the checkbox and set primaryJobTitle = -1
    if (jobOverviewFieldArr.fields.length === 0) {
      setValue("noExperience", [1]);
      setValue("primaryJobTitle", -1);
    }
  }, [jobOverviewFieldArr.fields.length]);

  useEffect(() => {
    // if the field length is zero and the user click to uncheck the checkbox then append a new field and reset the noExperience and primaryJobTitle
    if (!isNoExperience && jobOverviewFieldArr.fields.length === 0) {
      jobOverviewFieldArr.append({ jobTitle: "", yearOfExperience: "" });
      setJobOverviewFieldIdsArr((oldJobOverviewFieldIdsArr) => {
        return [...oldJobOverviewFieldIdsArr, uuidv4()];
      });
      setValue("noExperience", []);
      setValue("primaryJobTitle", 0);
    }
  }, [isNoExperience]);

  const VISA_TYPE_OPTIONS = useOptions(
    INDIVIDUAL_USER_VISA_TYPE,
    INDIVIDUAL_USER_VISA_TYPE_T_LABELS
  );

  const NUMBER_OF_COMPANIES_OPTIONS = useOptions(
    INDIVIDUAL_USER_NUMBER_OF_COMPANIES,
    INDIVIDUAL_USER_NUMBER_OF_COMPANIES_T_LABELS
  );

  const YEARS_OF_EXPERIENCE_OPTIONS = useOptions(
    YEARS_OF_EXPERIENCE,
    YEARS_OF_EXPERIENCE_T_LABELS
  );

  const JA_LANGUAGE_TEST_OPTIONS = useOptions(
    JA_LANGUAGE_TEST,
    JA_LANGUAGE_TEST_T_LABELS
  );

  const LANGUAGE_PROFICIENCY_OPTIONS = useOptions(
    LANGUAGE_PROFICIENCY,
    LANGUAGE_PROFICIENCY_T_LABELS
  );

  const OTHER_LANGUAGE_PROFICIENCY_OPTIONS = useOptions(
    OTHER_LANGUAGE_PROFICIENCY,
    OTHER_LANGUAGE_PROFICIENCY_T_LABELS
  );

  const COUNTRY_OPTIONS = getDropdownCountryList();

  const ALL_LANGUAGES = getDropdownLanguageList();

  const updateSuccess = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_success_profile_summary_saved"), "success");
  };

  const updateFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const onSubmit = async (formData: ProfileSummaryFormData) => {
    const {
      city,
      country,
      countryOfOrigin,
      expectedSalary,
      firstName,
      jaLanguage,
      jobOverview,
      language,
      lastName,
      numberOfCompanies,
      phoneNumber,
      primaryJobTitle,
      noExperience,
      skills,
      visaType
    } = formData ?? {};

    setIsDisabled(true);
    for (let index = 0; index < skills.length; index++) {
      const singleSkill = skills[index];
      const isSkillAlreadyExistsByKey = await isSkillExistsByKey(
        singleSkill?.name?.key
      );
      if (!isSkillAlreadyExistsByKey) {
        const skillKey = await addSkill(
          singleSkill?.name?.label,
          translate.getCurrentLocale()
        );
        if (skillKey && singleSkill?.name?.key) {
          singleSkill.name.key = skillKey;
        }
      }
    }

    const selectedSkills = skills
      ?.map((singleSkillData: SkillData) => {
        return {
          name: singleSkillData?.name?.key,
          years_of_experience: singleSkillData.yearOfExperience
        };
      })
      ?.filter(
        // ignore the empty skills object and return only valid skills
        (singleSkillData: { name: string | undefined }) => singleSkillData?.name
      );

    if (selectedSkills?.length > MAX_SKILLS) {
      toast.kampai(
        intl.get("t_toast_error_limit_exceed", {
          fieldName: intl.get("t_profile_skill_skill"),
          maxLimit: MAX_SKILLS
        }),
        "error"
      );
      return;
    }

    const basicInformation = {
      email: user?.email ?? "",
      primary_job_title_index: parseInt(primaryJobTitle.toString()),
      first_name: prepareMultiLingual(
        firstName ?? "",
        currentLocale,
        userBasicInformation?.first_name
      ),
      last_name: prepareMultiLingual(
        lastName ?? "",
        currentLocale,
        userBasicInformation?.last_name
      ),
      phone_number: phoneNumber ?? "",
      current_location: {
        country: country?.key ?? "",
        city: city?.key ?? ""
      },
      immigration_details: {
        country: countryOfOrigin?.key ?? "",
        ...(countryOfOrigin?.key === JP_ALPHA3_CODE || !visaType?.key
          ? {}
          : {
              visa_type:
                (visaType?.key as typeof INDIVIDUAL_USER_VISA_TYPE[keyof typeof INDIVIDUAL_USER_VISA_TYPE]) ??
                ""
            })
      },
      number_of_companies:
        (numberOfCompanies as typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES[keyof typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES]) ||
        "",
      expected_salary: Number(expectedSalary)
    };

    // set and unset primary_job_title_index
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { primary_job_title_index, ...noJobTitleBasicInformation } =
      basicInformation;

    const summary: ContextUserSummary = {
      basic_information:
        isNoExperience || primaryJobTitleIndex === -1
          ? noJobTitleBasicInformation
          : basicInformation,
      no_job_experience_overview: Boolean(noExperience?.includes(1)),
      job_experience_overview: !isNoExperience
        ? jobOverview?.map(
            (
              singleExperienceData: {
                jobTitle: string;
                yearOfExperience: string;
              },
              index
            ) => {
              return {
                job_title:
                  userJobOverview.length > 0
                    ? prepareMultiLingual(
                        singleExperienceData?.jobTitle ?? "",
                        currentLocale,
                        userJobOverview[index]?.job_title
                      )
                    : prepareMultiLingual(singleExperienceData?.jobTitle ?? ""),
                years_of_experience:
                  (singleExperienceData?.yearOfExperience as typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE]) ??
                  ""
              };
            }
          )
        : [],
      languages: [
        {
          name: JA_ALPHA3_CODE,
          proficiency: jaLanguage.proficiency ?? LANGUAGE_PROFICIENCY.NONE,
          test_name: jaLanguage.testName ?? "",
          test_result: jaLanguage.testResult ?? ""
        },
        ...language.map(
          (singleLanguageData: {
            name: KeyLabel;
            proficiency: string;
            testName: string;
            testResult: string;
          }) => {
            return {
              name: singleLanguageData?.name?.key?.toUpperCase() ?? "",
              proficiency:
                (singleLanguageData.proficiency as typeof OTHER_LANGUAGE_PROFICIENCY[keyof typeof OTHER_LANGUAGE_PROFICIENCY]) ??
                "",
              test_name: singleLanguageData.testName ?? "",
              test_result: singleLanguageData.testResult ?? ""
            };
          }
        )
      ],
      skills: selectedSkills as Array<Skill>
    };
    try {
      if (userData.setValue && userData.value) {
        try {
          if (
            summary?.basic_information?.phone_number !==
            userBasicInformation?.phone_number
          ) {
            const setUserPhoneNumber = httpsCallable(
              functions,
              "setUserPhoneNumber"
            );
            await setUserPhoneNumber(phoneNumber);
          }
        } catch (e) {
          if (e instanceof FirebaseError) {
            if (e.code === "functions/invalid-argument") {
              toast.kampai(
                intl.get("t_toast_error_phone_number_format_is_incorrect"),
                "error"
              );
            } else if (e.code === "functions/already-exists") {
              toast.kampai(
                intl.get("t_toast_error_phone_number_already_in_use"),
                "error"
              );
            } else {
              toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
            }
          }
          setIsDisabled(false);
          return;
        }
        await userData.setValue(
          {
            ...userData.value,
            summary,
            updated_at: Timestamp.now()
          },
          updateSuccess,
          updateFail,
          {
            forceReloadUser: true
          }
        );
        navigate(`/${translate.getCurrentLocale()}/profile`);
      }
    } catch (e) {
      updateFail();
    }
  };

  const handleRemoveJobOverviewField = (idx: number) => {
    setJobOverviewFieldIdsArr((oldJobOverviewFieldIdsArr) => {
      oldJobOverviewFieldIdsArr.splice(idx, 1);
      return [...oldJobOverviewFieldIdsArr];
    });
    const fieldValues = getValues("jobOverview");
    fieldValues.splice(idx, 1);
    jobOverviewFieldArr.replace(fieldValues);
    // if the user deleted the primary job title field then default select the first item as the default
    if (primaryJobTitleIndex === idx || isNoExperience) {
      setValue("primaryJobTitle", 0);
    }
  };

  const handleRemoveLanguageField = (idx: number) => {
    setLanguageFieldIdsArr((oldLanguageFieldIdsArr) => {
      oldLanguageFieldIdsArr.splice(idx, 1);
      return [...oldLanguageFieldIdsArr];
    });
    const fieldValues = getValues("language");
    fieldValues.splice(idx, 1);
    languageFieldArr.replace(fieldValues);
  };

  const updateRecommendedSkills = async (skillKeys: string | Array<string>) => {
    if (!skillKeys || isRecommendedSkillsLoading) {
      return;
    }
    if (!Array.isArray(skillKeys)) {
      skillKeys = [skillKeys];
    }

    setIsRecommendedSkillsLoading(true);

    const recommendedSkills: Array<KeyLabel> = recommendedSkillListArr;

    for await (const singleSkillKey of skillKeys) {
      const currentLocale = translate.getCurrentLocale();
      const typesenseDocumentId = singleSkillKey
        ? `${singleSkillKey}_${currentLocale}`
        : "";
      const typesenseClient = getClient();
      const selectedSkillKeys = getValues("skills")
        .map((singleSkill: SkillData) =>
          singleSkill.name?.key
            ? `${singleSkill.name?.key}_${currentLocale}`
            : ""
        )
        .filter((singleKey) => singleKey !== "");
      const recommendedSkillsKeys = recommendedSkills
        .map((singleRecommendedSkill) =>
          singleRecommendedSkill.key
            ? `${singleRecommendedSkill.key}_${currentLocale}`
            : ""
        )
        .filter((singleKey) => singleKey !== "");

      const ignoreSkillKeys = [...selectedSkillKeys, ...recommendedSkillsKeys];

      const perPageCount = Math.max(
        RELATED_SKILL_LIST_MAX_SIZE - recommendedSkills.length,
        3
      );

      const relatedTypesenseSkillDocuments =
        (await typesenseClient.multiSearch.perform({
          searches: [
            {
              collection: TYPESENSE_COLLECTIONS.SKILLS,
              q: "*",
              query_by: "",
              vector_query: `embedding:([], id: ${typesenseDocumentId})`,
              per_page: perPageCount,
              hidden_hits: ignoreSkillKeys.join(","),
              filter_by: `locale:=${translate.getCurrentLocale()}`
            }
          ]
        })) as MultiSearchResponse<Array<TypesenseSkill>>;

      const relatedSkillsResults = relatedTypesenseSkillDocuments.results;
      if (
        !relatedSkillsResults ||
        !relatedSkillsResults[0] ||
        !relatedSkillsResults[0].hits
      ) {
        setIsRecommendedSkillsLoading(false);
        return;
      }
      const relatedSkills = relatedSkillsResults[0].hits;

      recommendedSkills.unshift(
        ...relatedSkills.map((singleRelatedSkill) => ({
          key: singleRelatedSkill.document.key,
          label: singleRelatedSkill.document.label
        }))
      );
    }

    const filteredRecommendedSkills = recommendedSkills
      .filter((singleRecommendedSkill) => {
        const isSkillAlreadyExistsByKey = skillKeys.includes(
          singleRecommendedSkill.key
        );
        return !isSkillAlreadyExistsByKey;
      })
      .slice(0, RELATED_SKILL_LIST_MAX_SIZE);

    setRecommendedSkillListArr([...filteredRecommendedSkills]);
    setIsRecommendedSkillsLoading(false);
  };

  const deboundedGetRecommendedSkillsByContent = throttle(
    async (content: string) => {
      if (content) {
        setIsRecommendedSkillsLoading(false);
        const getRecommendedSkillsByContent = httpsCallable(
          functions,
          "getRecommendedSkillsByContent"
        );
        try {
          const recommendedSkillInfo = (await getRecommendedSkillsByContent({
            content,
            options: {
              locale: translate.getCurrentLocale()
            }
          })) as HttpsCallableResult<{ related_skills?: Array<KeyLabel> }>;
          const relatedSkills: Array<KeyLabel> =
            recommendedSkillInfo.data?.related_skills ?? [];
          setRecommendedSkillListArr([...relatedSkills]);
          setIsRecommendedSkillsLoading(false);
        } catch (e) {
          setIsRecommendedSkillsLoading(false);
        }
      }
    },
    2000
  );

  useEffect(() => {
    if (!skills || !Array.isArray(skills) || skills.length <= 1) {
      const jobTitles = jobOverview.map(
        (singleJobOverview) => singleJobOverview.jobTitle
      );
      const content = jobTitles.join(", ") ?? "";
      if (content !== "") {
        deboundedGetRecommendedSkillsByContent(content);
      }
    }
  }, [jobOverview]);

  const JA_TEST_SCORE_OPTIONS = jaTestLevelOptionsFinder(selectedJaTestName);

  if (isLoading || userData.loading) {
    return <SkeletonDesktopProfileSummaryForm />;
  }

  return (
    <Box noValidate component="form" onSubmit={handleSubmit(onSubmit)}>
      {/* Basic information section start */}
      <Paper sx={{ mb: 3 }}>
        <Typography variant="h3">
          {intl.get("t_profile_basic_info_heading")}
        </Typography>
        <Typography mt={2.5} mb={3} color="text.secondary">
          {intl.get("t_profile_basic_info_sub_heading")}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              disabled={isDisabled}
              control={control}
              name="lastName"
              label={intl.get("t_general_last_name")}
              placeholder={intl.get("t_general_last_name")}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={isDisabled}
              control={control}
              name="firstName"
              label={intl.get("t_general_first_name")}
              placeholder={intl.get("t_general_first_name")}
              required
            />
          </Grid>
        </Grid>
        <TelephoneInput
          disabled={isDisabled}
          control={control}
          name="phoneNumber"
          label={intl.get("t_general_phone_number")}
          placeholder={intl.get("t_general_phone_number")}
          required
        />
        <TypeableSelect
          disabled={isDisabled}
          control={control}
          name="countryOfOrigin"
          label={intl.get("t_profile_basic_info_nationality")}
          placeholder={intl.get("t_profile_basic_info_nationality")}
          required
          options={COUNTRY_OPTIONS}
        />
        {selectedCountryOfOriginIsoCode !== JP_ALPHA3_CODE ? (
          <TypeableSelect
            disabled={isDisabled}
            control={control}
            name="visaType"
            label={intl.get("t_profile_basic_info_visa_type")}
            placeholder={intl.get("t_profile_basic_info_visa_type")}
            options={VISA_TYPE_OPTIONS}
          />
        ) : (
          false
        )}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TypeableSelect
              disabled={isDisabled}
              control={control}
              name="country"
              label={intl.get("t_general_country")}
              placeholder={intl.get(
                "t_profile_basic_info_location_country_placeholder"
              )}
              required
              options={COUNTRY_OPTIONS}
            />
          </Grid>
          <Grid item xs={6}>
            <AutoCompleteTextField
              control={control}
              disabled={!selectedCountryIsoCode || isDisabled}
              name="city"
              label={intl.get("t_general_city")}
              placeholder={intl.get(
                "t_profile_basic_info_location_city_placeholder"
              )}
              setValue={setValue}
              getOptions={(subText) => {
                return getCityList(subText, selectedCountryIsoCode);
              }}
            />
          </Grid>
        </Grid>
        <NonTypeableSelect
          disabled={isDisabled}
          setValue={setValue}
          control={control}
          name="numberOfCompanies"
          label={intl.get("t_profile_basic_info_number_of_companies")}
          placeholder={intl.get(
            "t_profile_basic_info_number_of_companies_placeholder"
          )}
          required
          options={NUMBER_OF_COMPANIES_OPTIONS}
        />
        <NumberInput
          disabled={isDisabled}
          control={control}
          thousandSeparator
          startAdornment={
            <CurrencyYenIcon
              htmlColor={colorPalette.black.base}
              fontSize="small"
            />
          }
          name="expectedSalary"
          label={intl.get("t_profile_basic_info_expected_salary")}
          placeholder={intl.get("t_profile_basic_info_expected_salary")}
          required
        />
      </Paper>
      {/* Basic information section end */}

      {/* Job overview section start */}
      <Paper sx={{ mb: 3 }}>
        <Typography variant="h3">
          {intl.get("t_profile_job_overview_heading")}
        </Typography>
        <Typography mt={2.5} mb={2} color="text.secondary">
          {intl.get("t_profile_job_overview_sub_heading")}
        </Typography>

        <Checkbox
          disabled={isDisabled}
          size="medium"
          name="noExperience"
          control={control}
          setValue={setValue}
          options={[
            {
              label: intl.get("t_profile_job_overview_no_job_experience"),
              value:
                PROFILE_SUMMARY_NO_EXPERIENCE_CHECKBOX_VALUES.NO_EXPERIENCE_CHECKED
            }
          ]}
        />
        {!isNoExperience && (
          <>
            <Grid container columnSpacing={3}>
              <Grid item xs={12}>
                <TransitionGroup>
                  {jobOverviewFieldIdsArr.map((id, index) => {
                    return (
                      <Collapse key={id}>
                        <Grid container columnSpacing={3}>
                          <Grid item xs={5.5} mt={3}>
                            <TextField
                              disabled={isDisabled}
                              control={control}
                              name={`jobOverview.${index}.jobTitle`}
                              label={intl.get(
                                "t_profile_job_overview_job_title"
                              )}
                              placeholder={intl.get(
                                "t_profile_job_overview_job_title"
                              )}
                              required
                            />
                          </Grid>
                          <Grid item xs={5.5} mt={3}>
                            <NonTypeableSelect
                              disabled={isDisabled}
                              setValue={setValue}
                              control={control}
                              name={`jobOverview.${index}.yearOfExperience`}
                              label={intl.get(
                                "t_profile_job_overview_year_of_experience"
                              )}
                              placeholder={intl.get(
                                "t_profile_job_overview_year_of_experience"
                              )}
                              required
                              options={YEARS_OF_EXPERIENCE_OPTIONS}
                            />
                          </Grid>
                          <Grid item xs={1} mt={3}>
                            <StyledIconButton
                              disabled={isDisabled}
                              onClick={() =>
                                handleRemoveJobOverviewField(index)
                              }>
                              <Icon type="delete" filled />
                            </StyledIconButton>
                          </Grid>
                          <Grid item xs={12}>
                            <Radio
                              disabled={isDisabled}
                              size="medium"
                              name="primaryJobTitle"
                              control={control}
                              options={[
                                {
                                  label: intl.get(
                                    "t_profile_job_overview_primary_job_title"
                                  ),
                                  value: index
                                }
                              ]}
                            />
                          </Grid>
                        </Grid>
                      </Collapse>
                    );
                  })}
                </TransitionGroup>
              </Grid>
            </Grid>
            <Button
              color="primary"
              disabled={isDisabled}
              handleClick={() => {
                // if the max limit is exceeded, set a toast notification
                if (jobOverviewFieldArr.fields.length >= MAX_WORK_EXPERIENCES) {
                  toast.kampai(
                    intl.get("t_toast_error_limit_exceed", {
                      fieldName: intl.get("t_profile_summary_experience"),
                      maxLimit: MAX_WORK_EXPERIENCES
                    }),
                    "error"
                  );
                } else {
                  jobOverviewFieldArr.append({
                    jobTitle: "",
                    yearOfExperience: ""
                  });
                  setJobOverviewFieldIdsArr((oldJobOverviewFieldIdsArr) => {
                    return [...oldJobOverviewFieldIdsArr, uuidv4()];
                  });
                }
              }}
              size="small"
              startAdornment={<AddCircleIcon />}
              variant="text">
              {intl.get("t_general_add_another")}
            </Button>
          </>
        )}
      </Paper>
      {/* Job overview section end */}

      {/* Language section start */}
      <Paper sx={{ mb: 3 }}>
        <Typography variant="h3">
          {intl.get("t_profile_language_heading")}
        </Typography>
        <Typography mt={2.5} mb={2} color="text.secondary">
          {intl.get("t_profile_language_sub_heading")}
        </Typography>

        <Grid container columnSpacing={3}>
          {/* form for japanese language start */}
          <Grid item xs={5.5}>
            <TypeableSelect
              options={[]}
              disabled={isDisabled}
              control={control}
              name="jaLanguage.name"
              label={intl.get("t_profile_language_heading")}
              placeholder={intl.get("t_profile_language_heading")}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={5.5}>
            <NonTypeableSelect
              disabled={isDisabled}
              setValue={setValue}
              control={control}
              name="jaLanguage.proficiency"
              label={intl.get("t_profile_language_proficiency")}
              placeholder={intl.get(
                "t_profile_language_proficiency_placeholder"
              )}
              required
              options={LANGUAGE_PROFICIENCY_OPTIONS}
            />
          </Grid>
          <Grid item xs={5.5}>
            <NonTypeableSelect
              disabled={isDisabled}
              setValue={setValue}
              control={control}
              name="jaLanguage.testName"
              label={intl.get("t_profile_language_test")}
              placeholder={intl.get("t_profile_language_test")}
              options={JA_LANGUAGE_TEST_OPTIONS}
              required={Boolean(selectedJaTestName)}
            />
          </Grid>
          <Grid item xs={5.5}>
            <NonTypeableSelect
              disabled={isDisabled || !selectedJaTestName}
              setValue={setValue}
              control={control}
              name="jaLanguage.testResult"
              label={intl.get("t_profile_language_test_result")}
              placeholder={intl.get("t_profile_language_test_result")}
              options={JA_TEST_SCORE_OPTIONS}
              required={Boolean(selectedJaTestName)}
            />
          </Grid>
          {/* form for japanese language end */}
          <Grid item xs={12}>
            <TransitionGroup>
              {languageFieldIdsArr.map((id, index) => (
                <Collapse key={id}>
                  <Grid container columnSpacing={3}>
                    <Grid item xs={5.5} mt={3}>
                      <TypeableSelect
                        disabled={isDisabled}
                        control={control}
                        name={`language.${index}.name`}
                        label={intl.get("t_profile_language_heading")}
                        placeholder={intl.get("t_profile_language_heading")}
                        required
                        options={ALL_LANGUAGES}
                      />
                    </Grid>
                    <Grid item xs={5.5} mt={3}>
                      <NonTypeableSelect
                        disabled={isDisabled}
                        setValue={setValue}
                        control={control}
                        name={`language.${index}.proficiency`}
                        label={intl.get("t_profile_language_proficiency")}
                        placeholder={intl.get(
                          "t_profile_language_proficiency_placeholder"
                        )}
                        required
                        options={OTHER_LANGUAGE_PROFICIENCY_OPTIONS}
                      />
                    </Grid>
                    <Grid item xs={5.5}>
                      <TextField
                        disabled={isDisabled}
                        control={control}
                        name={`language.${index}.testName`}
                        label={intl.get("t_profile_language_test")}
                        placeholder={intl.get("t_profile_language_test")}
                        required={
                          Boolean(watch(`language.${index}.testName`)) ||
                          Boolean(watch(`language.${index}.testResult`))
                        }
                      />
                    </Grid>
                    <Grid item xs={5.5}>
                      <TextField
                        disabled={isDisabled}
                        control={control}
                        name={`language.${index}.testResult`}
                        label={intl.get("t_profile_language_test_result")}
                        placeholder={intl.get("t_profile_language_test_result")}
                        required={
                          Boolean(watch(`language.${index}.testName`)) ||
                          Boolean(watch(`language.${index}.testResult`))
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <StyledIconButton
                        disabled={isDisabled}
                        onClick={() => handleRemoveLanguageField(index)}>
                        <Icon type="delete" filled />
                      </StyledIconButton>
                    </Grid>
                  </Grid>
                </Collapse>
              ))}
            </TransitionGroup>
          </Grid>
        </Grid>
        <Button
          disabled={isDisabled}
          color="primary"
          handleClick={() => {
            // if the max limit is exceeded, set a toast notification
            if (languageFieldArr.fields.length > MAX_LANGUAGES - 1) {
              toast.kampai(
                intl.get("t_toast_error_limit_exceed", {
                  fieldName: intl.get("t_profile_summary_language"),
                  maxLimit: MAX_LANGUAGES
                }),
                "error"
              );
            } else {
              languageFieldArr.append({
                name: { key: "", label: "" },
                proficiency: OTHER_LANGUAGE_PROFICIENCY.NATIVE,
                testName: "",
                testResult: ""
              });
              setLanguageFieldIdsArr((oldLanguageFieldIdsArr) => {
                return [...oldLanguageFieldIdsArr, uuidv4()];
              });
            }
          }}
          size="small"
          startAdornment={<AddCircleIcon />}
          variant="text">
          {intl.get("t_general_add_another")}
        </Button>
      </Paper>
      {/* Language section end */}

      {/* Skills section start */}
      <Paper sx={{ mb: 3 }}>
        <Typography variant="h3">
          {intl.get("t_profile_skill_heading")}
        </Typography>
        <Typography mt={2.5} mb={2} color="text.secondary">
          {intl.get("t_profile_skill_sub_heading")}
        </Typography>
        <Grid container columnSpacing={3}>
          {skillsFieldArr.fields.map(({ id }, index) => {
            return (
              <Fragment key={id}>
                {skillsFieldArr.fields.length === index + 1 && (
                  <Fragment key={id}>
                    <Grid item xs={5.5}>
                      <AutoCompleteTextField
                        disabled={isDisabled}
                        getOptions={getSkillList}
                        setValue={(_, value, options) => {
                          setSelectedSkillKey(value?.key ?? "");
                          setValue(`skills.${index}.name`, value, options);
                        }}
                        control={control}
                        name={`skills.${index}.name`}
                        label={intl.get("t_profile_skill_skill")}
                        placeholder={intl.get("t_profile_skill_skill")}
                        required
                      />
                    </Grid>
                    <Grid item xs={5.5}>
                      <NonTypeableSelect
                        disabled={isDisabled}
                        setValue={setValue}
                        control={control}
                        name={`skills.${index}.yearOfExperience`}
                        label={intl.get("t_profile_skill_experience")}
                        placeholder={intl.get("t_profile_skill_experience")}
                        required
                        options={YEARS_OF_EXPERIENCE_OPTIONS}
                      />
                    </Grid>
                  </Fragment>
                )}
              </Fragment>
            );
          })}
        </Grid>
        <Button
          disabled={isDisabled}
          color="primary"
          handleClick={async () => {
            // if the max limit is exceeded, set a toast notification
            if (skillsFieldArr.fields.length > MAX_SKILLS) {
              toast.kampai(
                intl.get("t_toast_error_limit_exceed", {
                  fieldName: intl.get("t_profile_skill_skill"),
                  maxLimit: MAX_SKILLS
                }),
                "error"
              );
              return;
            }
            const isValid = await trigger("skills");
            // if skill is not selected then return
            if (!getValues(`skills.${skillsFieldArr.fields.length - 1}.name`)) {
              return;
            }
            if (isValid) {
              const skillKey = getValues(
                `skills.${skillsFieldArr.fields.length - 1}.name.key`
              );

              updateRecommendedSkills(skillKey);
              return skillsFieldArr.append(emptySkill);
            }
          }}
          size="small"
          startAdornment={<AddCircleIcon />}
          variant="text">
          {intl.get("t_profile_skill_add_skill")}
        </Button>

        <Box>
          <Typography variant="h4" my={2}>
            {intl.get("t_general_recommended_skills")}
          </Typography>
          {isRecommendedSkillsLoading || isLoading ? (
            <Stack
              direction="row"
              gap={1}
              mt={2}
              alignItems="center"
              flexWrap="wrap">
              {[...Array(10)].map((_, idx) => (
                <SkeletonKeyword key={idx} />
              ))}
            </Stack>
          ) : recommendedSkillListArr.length == 0 ? (
            <Typography variant="subtitle4" color="text.secondary">
              Please fill job overview or select some skills to get
              recommendations
            </Typography>
          ) : (
            <Stack
              direction="row"
              gap={1}
              mt={2}
              alignItems="center"
              flexWrap="wrap">
              {recommendedSkillListArr.map((singleRecommendedSkill) => (
                <Box
                  key={singleRecommendedSkill.key}
                  onClick={() => {
                    setSelectedSkillKey(singleRecommendedSkill.key);
                    setValue(
                      `skills.${skillsFieldArr.fields.length - 1}.name`,
                      singleRecommendedSkill
                    );
                    setFocus(
                      `skills.${
                        skillsFieldArr.fields.length - 1
                      }.yearOfExperience`
                    );
                  }}>
                  <Keyword
                    isClickable
                    isSelected={singleRecommendedSkill.key === selectedSkillKey}
                    label={singleRecommendedSkill.label}
                    startAdornment={<AddIcon height={24} width={24} />}
                  />
                </Box>
              ))}
            </Stack>
          )}
        </Box>

        {/* if 1 skill added then show selected skills section */}
        {skillsFieldArr.fields.length > 1 && (
          <>
            <Typography
              variant="subtitle4"
              color="text.secondary"
              mt={3}
              mb={1.5}
              display="flex">
              {intl.get("t_profile_skill_selected_skills")}
            </Typography>
            {/* ignore last index object from rendering */}
            <StyledTransitionGroup>
              {getValues("skills")
                ?.slice(0, skillsFieldArr.fields.length - 1)
                .map((singleField: SkillData, index: number) => {
                  return (
                    <Collapse key={index}>
                      {singleField?.name?.label &&
                      singleField?.yearOfExperience ? (
                        <Tag
                          label={`${singleField?.name?.label} ${intl.get(
                            YEARS_OF_EXPERIENCE_T_LABELS[
                              singleField?.yearOfExperience as keyof typeof YEARS_OF_EXPERIENCE_T_LABELS
                            ]
                          )}`}
                          endAdornment={
                            <Box
                              component="span"
                              onClick={() => {
                                if (!isDisabled) {
                                  skillsFieldArr.remove(index);
                                }
                              }}>
                              <Cursor
                                type={isDisabled ? "not-allowed" : "pointer"}>
                                <CloseIcon fontSize="small" />
                              </Cursor>
                            </Box>
                          }
                        />
                      ) : (
                        false
                      )}
                    </Collapse>
                  );
                })}
            </StyledTransitionGroup>
          </>
        )}
      </Paper>
      {/* Skills section end */}

      <Stack justifyContent="space-between" direction="row" mt={7.5}>
        <Button
          data-testid="profile_summary_back_button"
          startAdornment={<NavigateBeforeIcon />}
          handleClick={() =>
            navigate(`/${translate.getCurrentLocale()}/profile`)
          }
          variant="outlined">
          {intl.get("t_general_back")}
        </Button>
        <Button
          data-testid="profile_summary_save_button"
          type="submit"
          loading={isDisabled}>
          {intl.get("t_general_save")}
        </Button>
      </Stack>
    </Box>
  );
};

export default DesktopProfileSummaryForm;
