import { Link } from "react-router-dom";

import { NavigateNext as NavigateNextIcon } from "@mui/icons-material";
import { Box, Link as MuiLink, Stack, styled } from "@mui/material";

import Button from "@components/Button";
import Typography from "@components/Typography";

import { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

const StyledBox = styled(Box)({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 4,
  borderRadius: 4,
  marginTop: 10,
  marginBottom: 12,
  transform: "rotate(4.34deg)",
  boxShadow: `0px 3px 5px -1px ${colorPalette.grey[20]}, 
              0px 6px 10px ${colorPalette.grey[14]}, 
              0px 1px 18px ${colorPalette.grey[12]}`
});

const NoCandidatesCard = () => {
  return (
    <Box my="20vh">
      <Stack direction="row" justifyContent="center">
        <Stack>
          <Box alignSelf="flex-end">
            <StyledBox bgcolor={colorPalette.blue[20]}>
              <Typography variant="subtitle5" color="primary.main">
                {intl.get("t_employer_no_saved_candidate_label")}
              </Typography>
            </StyledBox>
          </Box>
          <Typography variant="h2" align="center" mb={2.5} mx={5}>
            {intl.get("t_employer_no_saved_candidate_title")}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="center">
        <Stack alignItems="center">
          <Typography
            variant="subtitle3"
            color="text.secondary"
            align="center"
            mb={3}>
            {intl.get("t_employer_no_saved_candidate_subtitle")}
          </Typography>
          <MuiLink
            component={Link}
            to={`/${translate.getCurrentLocale()}/employers/search`}
            underline="none">
            <Button size="large" endAdornment={<NavigateNextIcon />}>
              {intl.get("t_employer_no_saved_candidate_find_candidate_btn")}
            </Button>
          </MuiLink>
        </Stack>
      </Stack>
    </Box>
  );
};

export default NoCandidatesCard;
