import { Stack } from "@mui/material";

import Tag from "@components/Tag";

const JobCategories = ({ categories }: { categories: Array<string> }) => {
  return (
    <Stack display="contents" direction="row" flexWrap="wrap">
      {categories?.map((singleCategory) => (
        <Tag label={singleCategory} key={singleCategory} />
      ))}
    </Stack>
  );
};

export default JobCategories;
