import { useEffect } from "react";

import {
  Avatar,
  Grid,
  List,
  ListItem,
  Stack,
  styled,
  Typography
} from "@mui/material";
import useForceUpdate from "use-force-update";

import Paper from "@components/Paper";
import SideMenu from "@components/SideMenu";

import { LOCALE } from "@utils/config";
import translate, { intl } from "@utils/translate";

import Logo from "@assets/images/Logo.png";

const StyledLogo = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: 62,
    width: 62
  },
  [theme.breakpoints.up("sm")]: {
    height: 55,
    width: 55
  },
  [theme.breakpoints.up("lg")]: {
    height: 62,
    width: 62
  },
  "& .MuiAvatar-img": {
    objectFit: "contain"
  }
}));

const StyledUserTermsListItem = styled(ListItem)({
  "display": "inline-block",
  "&:before": {
    counterIncrement: "section",
    content: "counters(section, '.') '. '"
  }
});

const StyledUserTermsList = styled(List)({
  counterReset: "section",
  fontFamily:
    translate.getCurrentLocale() === LOCALE.JA
      ? "Noto Sans JP"
      : "Poppins, sans-serif"
});

const UserTNC = () => {
  const menuLinks = [
    {
      label: intl.get("t_recruiter_term"),
      redirectLink: "/employer-terms-and-conditions"
    },
    {
      label: intl.get("t_account_setting_side_menu_user_terms"),
      redirectLink: "/user-terms-and-conditions"
    },
    {
      label: intl.get("t_account_setting_side_menu_privacy_policy"),
      redirectLink: "/privacy-policy"
    }
  ];
  const forceUpdate = useForceUpdate();

  const languageChanged = () => {
    forceUpdate();
  };

  useEffect(() => {
    translate.addLanguageChangedListener(languageChanged);
    return () => {
      translate.removeLanguageChangedListener(languageChanged);
    };
  }, []);
  return (
    <>
      <Grid container mt={4} rowGap={3}>
        <Grid item xs={12} md={3} pr={{ xs: 0, md: 2 }}>
          <SideMenu menuLinks={menuLinks} />
        </Grid>
        <Grid item xs={12} md={9} pl={{ xs: 0, md: 2 }}>
          <Paper>
            <Typography variant="h3" mb={3} id="data-for-ssg">
              {intl.get("t_account_setting_side_menu_user_terms")}
            </Typography>
            <Paper elevation={3}>
              <Stack alignItems="center">
                <StyledLogo
                  variant="square"
                  src={Logo}
                  alt={intl.get("t_alt_tokhimo_job_logo")}
                />
                <Typography variant="h4" mt="40px">
                  {intl.get("t_user_terms_title")}
                </Typography>
                <StyledUserTermsList>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_one")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_one_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_one_two")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_one_three")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_one_four")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_one_five")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_one_six")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_two")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_two_one_title")}
                        <br />
                        {intl.get("t_user_terms_list_two_one_details")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_two_two_title")}
                        <br />
                        {intl.get("t_user_terms_list_two_two_details")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_two_three_title")}
                        <br />
                        {intl.get("t_user_terms_list_two_three_details")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_two_four_title")}
                        <br />
                        {intl.get("t_user_terms_list_two_four_details")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_two_five_title")}
                        <br />
                        {intl.get("t_user_terms_list_two_five_details")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_two_six_title")}
                        <br />
                        {intl.get("t_user_terms_list_two_six_details")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_two_seven_title")}
                        <br />
                        {intl.get("t_user_terms_list_two_seven_details")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_three_title")}
                    <br />
                    {intl.get("t_user_terms_list_three_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_four_title")}
                    <br />
                    {intl.get("t_user_terms_list_four_details")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_four_one_title")}
                        <br />
                        {intl.get("t_user_terms_list_four_one_details")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_four_two_title")}
                        <br />
                        {intl.get("t_user_terms_list_four_two_details")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_two_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_two_two")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_two_three")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_two_four")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_two_five")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_two_six")}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_four_three_title")}
                        <br />
                        {intl.get("t_user_terms_list_four_three_details")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_four_four_title")}
                        <br />
                        {intl.get("t_user_terms_list_four_four_details")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_four_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_four_two")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_four_three")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_four_four")}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_four_five")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_five_one")}
                            <StyledUserTermsList>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_four_five_one_one"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_four_five_one_two"
                                )}
                              </StyledUserTermsListItem>
                            </StyledUserTermsList>
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_four_six_title")}
                        <br />
                        {intl.get("t_user_terms_list_four_six_details")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_four_seven_title")}
                        <br />
                        {intl.get("t_user_terms_list_four_seven_details")}
                        <br />
                        {intl.get(
                          "t_user_terms_list_four_seven_details_contact"
                        )}
                        <br />
                        {intl.get(
                          "t_user_terms_list_four_seven_details_address"
                        )}
                        <br />
                        {intl.get(
                          "t_user_terms_list_four_seven_details_city_postal"
                        )}
                        <br />
                        {intl.get(
                          "t_user_terms_list_four_seven_details_company"
                        )}
                        <br />
                        {intl.get(
                          "t_user_terms_list_four_seven_details_department"
                        )}
                        <br />
                        {intl.get("t_user_terms_list_four_seven_details_phone")}
                        <br />
                        {intl.get("t_user_terms_list_four_seven_details_email")}
                        <br />
                        {intl.get(
                          "t_user_terms_list_four_seven_details_operating_hours"
                        )}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_four_eight_title")}
                        <br />
                        {intl.get("t_user_terms_list_four_eight_details")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_four_nine")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_nine_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_nine_two")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_nine_three")}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_four_ten")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_ten_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_four_ten_two")}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_five_title")}
                    <br />
                    {intl.get("t_user_terms_list_five_details")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_five_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_five_two")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_five_three")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_five_four")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_five_five")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_six_title")}
                    <br />
                    {intl.get("t_user_terms_list_six_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_seven_title")}
                    <br />
                    {intl.get("t_user_terms_list_seven_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_eight_title")}
                    <br />
                    {intl.get("t_user_terms_list_eight_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_nine_title")}
                    <br />
                    {intl.get("t_user_terms_list_nine_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_ten")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_ten_one")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_ten_one_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_ten_one_two")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_ten_one_three")}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_ten_two")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_eleven_title")}
                    <br />
                    {intl.get("t_user_terms_list_eleven_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_twelve")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_twelve_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_twelve_two")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_twelve_two_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_twelve_two_two")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_twelve_two_three")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_twelve_two_four")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_twelve_two_five")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_twelve_two_six")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_twelve_two_seven")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_twelve_two_eight")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_twelve_two_nine")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_twelve_two_ten")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_twelve_two_eleven")}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_thirteen")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_thirteen_one")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_one_one")}
                            <StyledUserTermsList>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_one_one_one"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_one_one_two"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_one_one_three"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_one_one_four"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_one_one_five"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_one_one_six"
                                )}
                              </StyledUserTermsListItem>
                            </StyledUserTermsList>
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_one_two")}
                            <StyledUserTermsList>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_one_two_one"
                                )}
                                <StyledUserTermsList>
                                  <StyledUserTermsListItem>
                                    {intl.get(
                                      "t_user_terms_list_thirteen_one_two_one_one"
                                    )}
                                  </StyledUserTermsListItem>
                                  <StyledUserTermsListItem>
                                    {intl.get(
                                      "t_user_terms_list_thirteen_one_two_one_two"
                                    )}
                                  </StyledUserTermsListItem>
                                  <StyledUserTermsListItem>
                                    {intl.get(
                                      "t_user_terms_list_thirteen_one_two_one_three"
                                    )}
                                  </StyledUserTermsListItem>
                                </StyledUserTermsList>
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_one_two_two"
                                )}
                              </StyledUserTermsListItem>
                            </StyledUserTermsList>
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_thirteen_two")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_two_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_two_two")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_two_three")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_two_four")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_two_five")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_two_six")}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_thirteen_three")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_user_terms_list_thirteen_three_one_title"
                            )}
                            <br />
                            {intl.get(
                              "t_user_terms_list_thirteen_three_one_details"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_user_terms_list_thirteen_three_two_title"
                            )}
                            <br />
                            {intl.get(
                              "t_user_terms_list_thirteen_three_two_details"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_user_terms_list_thirteen_three_three_title"
                            )}
                            <br />
                            {intl.get(
                              "t_user_terms_list_thirteen_three_three_details"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_user_terms_list_thirteen_three_four_title"
                            )}
                            <br />
                            {intl.get(
                              "t_user_terms_list_thirteen_three_four_details"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_three_five")}
                            <StyledUserTermsList>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_three_five_one"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_three_five_two"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_three_five_three"
                                )}
                              </StyledUserTermsListItem>
                            </StyledUserTermsList>
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_three_six")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_three_seven")}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_thirteen_four")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_thirteen_five")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_five_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_five_two")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_five_three")}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_thirteen_six")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_six_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_six_two")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_six_three")}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_thirteen_seven")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_seven_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_seven_two")}
                            <StyledUserTermsList>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_seven_two_one"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_seven_two_two"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_seven_two_three"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_seven_two_four"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_seven_two_five"
                                )}
                              </StyledUserTermsListItem>
                            </StyledUserTermsList>
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_thirteen_eight")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_eight_one")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_eight_two")}
                            <StyledUserTermsList>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_eight_two_one"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_eight_two_two"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_eight_two_three"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_eight_two_four"
                                )}
                              </StyledUserTermsListItem>
                            </StyledUserTermsList>
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_eight_three")}
                            <StyledUserTermsList>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_eight_three_one"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_eight_three_two"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_eight_three_three"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_eight_three_four"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_eight_three_five"
                                )}
                              </StyledUserTermsListItem>
                            </StyledUserTermsList>
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_thirteen_nine_title")}
                        <br />
                        {intl.get("t_user_terms_list_thirteen_nine_content")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_thirteen_ten")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_user_terms_list_thirteen_ten_one_title"
                            )}
                            <br />
                            {intl.get(
                              "t_user_terms_list_thirteen_ten_one_content"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_user_terms_list_thirteen_ten_two_title"
                            )}
                            <br />
                            {intl.get(
                              "t_user_terms_list_thirteen_ten_two_content"
                            )}
                            <StyledUserTermsList>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_ten_two_one"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_ten_two_two"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_ten_two_three"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_ten_two_four"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_ten_two_five"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_ten_two_six"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_ten_two_seven"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_ten_two_eight"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_ten_two_nine"
                                )}
                              </StyledUserTermsListItem>
                            </StyledUserTermsList>
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_user_terms_list_thirteen_ten_three_title"
                            )}
                            <br />
                            {intl.get(
                              "t_user_terms_list_thirteen_ten_three_content"
                            )}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_thirteen_eleven")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_eleven_one")}
                            <StyledUserTermsList>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_eleven_one_one"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_eleven_one_two"
                                )}
                              </StyledUserTermsListItem>
                            </StyledUserTermsList>
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_eleven_two")}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_thirteen_twelve")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_twelve_one")}
                            <StyledUserTermsList>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_twelve_one_one"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_twelve_one_two"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_twelve_one_three"
                                )}
                              </StyledUserTermsListItem>
                            </StyledUserTermsList>
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_twelve_two")}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_user_terms_list_thirteen_twelve_three"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get("t_user_terms_list_thirteen_twelve_four")}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_thirteen_thirteen")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_user_terms_list_thirteen_thirteen_one"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_user_terms_list_thirteen_thirteen_two"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_user_terms_list_thirteen_thirteen_three"
                            )}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_thirteen_fourteen")}
                        <StyledUserTermsList>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_user_terms_list_thirteen_fourteen_one"
                            )}
                            <StyledUserTermsList>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_fourteen_one_one"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_fourteen_one_two"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_fourteen_one_three"
                                )}
                              </StyledUserTermsListItem>
                              <StyledUserTermsListItem>
                                {intl.get(
                                  "t_user_terms_list_thirteen_fourteen_one_four"
                                )}
                              </StyledUserTermsListItem>
                            </StyledUserTermsList>
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_user_terms_list_thirteen_fourteen_two"
                            )}
                          </StyledUserTermsListItem>
                          <StyledUserTermsListItem>
                            {intl.get(
                              "t_user_terms_list_thirteen_fourteen_three"
                            )}
                          </StyledUserTermsListItem>
                        </StyledUserTermsList>
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_fourteen")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_fourteen_one")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_fourteen_two")}
                      </StyledUserTermsListItem>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_fourteen_three")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_fifteen_title")}
                    <br />
                    {intl.get("t_user_terms_list_fifteen_details")}
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_sixteen")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_sixteen_one")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_seventeen")}
                    <StyledUserTermsList>
                      <StyledUserTermsListItem>
                        {intl.get("t_user_terms_list_seventeen_one")}
                      </StyledUserTermsListItem>
                    </StyledUserTermsList>
                  </StyledUserTermsListItem>
                  <StyledUserTermsListItem>
                    {intl.get("t_user_terms_list_eighteen_title")}
                    <br />
                    {intl.get("t_user_terms_list_eighteen_effective_date")}
                    <br />
                    {intl.get("t_user_terms_list_eighteen_last_updated_date")}
                  </StyledUserTermsListItem>
                </StyledUserTermsList>
              </Stack>
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default UserTNC;
