import {
  COMPANY_LOGO_UPLOAD_CONFIG,
  FILES_LOCATION_COMPANY_LOGO,
  FILES_LOCATION_PROFILE_PICTURE,
  PROFILE_IMAGE_UPLOAD_CONFIG
} from "@utils/config";
import { envOptions } from "@utils/firebase";
import Timestamp from "@utils/Timestamp";

/**
 * Returns the URL of a file stored in Firebase Storage.
 *
 * @param {string}            folderName      The name of the folder where the file is stored. This should come from the config file.
 * @param {string}            fileName        The name of the file without the extension. Usually it's the document ID.
 * @param {string}            fileExtension   The extension of the file.
 * @param {Timestamp | Date}  uploadedAt      The timestamp or date when the file was uploaded.
 *
 * @returns {string} The URL of the file.
 */

export const getFileURL = (
  folderName: string,
  fileName?: string,
  fileExtension?: string,
  uploadedAt?: Timestamp | Date
) => {
  if (fileName && fileExtension && envOptions.storageHost) {
    let extensionConfig;

    switch (folderName) {
      case FILES_LOCATION_PROFILE_PICTURE:
        extensionConfig = PROFILE_IMAGE_UPLOAD_CONFIG.fileFormat;
        break;
      case FILES_LOCATION_COMPANY_LOGO:
        extensionConfig = COMPANY_LOGO_UPLOAD_CONFIG.fileFormat;
        break;
      default:
        extensionConfig = ["png", "jpeg", "jpg", "gif", "webp"];
    }

    const isMedia = extensionConfig.includes(fileExtension);

    const fileURL = new URL(
      `${envOptions.storageHost}/v0/b/${
        process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
      }/o/${encodeURIComponent(`${folderName}/${fileName}.${fileExtension}`)}`
    );

    if (isMedia) {
      fileURL.searchParams.append("alt", "media");
    }

    if (uploadedAt) {
      if (uploadedAt instanceof Date) {
        fileURL.searchParams.append("v", `${uploadedAt.getTime().toString()}`);
      } else {
        fileURL.searchParams.append(
          "v",
          `${uploadedAt.toDate().getTime().toString()}`
        );
      }
    }

    return fileURL.toString();
  } else {
    return "";
  }
};
