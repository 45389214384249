import { Skeleton } from "@mui/material";

import Avatar from "@components/Avatar";

interface SkeletonAvatarProps {
  size?: "small" | "medium" | "large";
  variant?: "circular" | "rounded";
}

const SkeletonAvatar = ({
  size,
  variant = "circular"
}: SkeletonAvatarProps) => {
  return (
    <Skeleton variant={variant} animation="wave">
      <Avatar fullName="" imgSrc="" size={size} />
    </Skeleton>
  );
};

export default SkeletonAvatar;
