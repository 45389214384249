import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import {
  Box,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Stack,
  styled,
  Tooltip
} from "@mui/material";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import * as yup from "yup";

import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import Cursor from "@components/Cursor";
import Dialog from "@components/Dialog";
import CompanyInfo from "@components/JobCards/Common/CompanyInfo";
import JobCategories from "@components/JobCards/Common/JobCategories";
import JobStatus from "@components/JobCards/Common/JobStatus";
import Typography from "@components/Typography";

import { useOptions } from "@hooks/useOptions";

import HiringFlow from "@interfaces/database/HiringFlow";
import Location from "@interfaces/database/Location";
import MultiLingual from "@interfaces/database/MultiLingual";

import {
  CANDIDATE_JOB_REVOKED_REASONS,
  CANDIDATE_JOB_REVOKED_REASONS_T_LABELS,
  DIALOG_ACTION,
  FREE_TEXT_FIELD_MAX_LENGTH,
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  JOB_APPLICATION_INVITATION_ACTION_TYPE_T_LABELS,
  JOB_STATUS_TAGS
} from "@utils/config";
import generateHiringProcessSteps from "@utils/generateHiringProcessSteps";
import { resolveMultiLingual } from "@utils/multiLingual";
import theme from "@utils/theme";
import translate, { intl, intlEn, intlJa } from "@utils/translate";

dayjs.extend(relative);

interface AppliedJobCardProps {
  timestamp?: Date;
  status: Array<
    | typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]
    | typeof JOB_APPLICATION_INVITATION_ACTION_TYPE[keyof typeof JOB_APPLICATION_INVITATION_ACTION_TYPE]
  >;
  categories?: Array<string>;
  companyName?: string;
  companyLogo?: string;
  jobTitle?: string;
  location?: Location;
  handleJobRevoke?: (jobRevokedReasons?: Array<MultiLingual<string>>) => void;
  handleClick?: () => void;
  isRevoked?: boolean;
  isLoading?: boolean;
  isJobActive?: boolean;
  isHighlighted?: boolean;
  applicationDeclinedOrRevokedReasons?: Array<MultiLingual<string>>;
  hiringFlow?: HiringFlow;
  hiringFlowActiveStepKey?: string;
}

interface JobRevokedReasonsFormData {
  jobRevokedReasons: Array<keyof typeof CANDIDATE_JOB_REVOKED_REASONS>;
  otherJobRevokeReason: string;
}

const StyledList = styled(List)({
  "listStyleType": "disc",
  "padding": "0.25rem 1.25rem",
  "& .MuiListItem-root": {
    padding: 0,
    display: "list-item"
  }
});

const AppliedJobCard = ({
  timestamp,
  companyLogo,
  status,
  categories = [],
  companyName,
  jobTitle,
  location,
  handleJobRevoke,
  handleClick,
  isRevoked = false,
  isLoading = false,
  isJobActive = true,
  isHighlighted = false,
  applicationDeclinedOrRevokedReasons = [],
  hiringFlow,
  hiringFlowActiveStepKey
}: AppliedJobCardProps) => {
  const relativeTime = dayjs(timestamp)
    .locale(translate.getCurrentLocaleShort())
    .fromNow();

  const CANDIDATE_JOB_REVOKED_REASON_OPTIONS = useOptions(
    CANDIDATE_JOB_REVOKED_REASONS,
    CANDIDATE_JOB_REVOKED_REASONS_T_LABELS
  ).map((singleOption) => {
    return { value: singleOption.key, label: singleOption.label };
  });

  // validation schema
  const schema = yup.object({
    jobRevokedReasons: yup.array(),
    otherJobRevokeReason: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_first_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      jobRevokedReasons: [],
      otherJobRevokeReason: ""
    }
  });

  const { handleSubmit, control, setValue } = methods;

  const handleFormSubmit = (formData: JobRevokedReasonsFormData) => {
    const allJobRevokedReasons = [
      ...formData.jobRevokedReasons
      // formData.otherJobRevokeReason // FIXME: add this in phase-2(need to handle email and phone number check)
    ]?.map((singleJobRevokedReason) => {
      return {
        en: intlEn.get(
          CANDIDATE_JOB_REVOKED_REASONS_T_LABELS[
            singleJobRevokedReason as keyof typeof CANDIDATE_JOB_REVOKED_REASONS_T_LABELS
          ]
        ),
        ja: intlJa.get(
          CANDIDATE_JOB_REVOKED_REASONS_T_LABELS[
            singleJobRevokedReason as keyof typeof CANDIDATE_JOB_REVOKED_REASONS_T_LABELS
          ]
        )
      };
    });
    handleJobRevoke?.(allJobRevokedReasons);
  };

  const handleClose = (reason: keyof typeof DIALOG_ACTION) => {
    if (reason === DIALOG_ACTION.AGREE && handleJobRevoke) {
      handleJobRevoke();
    }
  };

  const isDisabled =
    isRevoked ||
    status.includes(JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED);
  const RevokeBtn = () => {
    return isJobActive &&
      !status.includes(JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED) ? (
      <Dialog
        title={intl.get("t_dialog_revoke_confirmation_title")}
        maxWidth="sm"
        initiator={
          <Button loading={isLoading}>{intl.get("t_general_revoke")}</Button>
        }
        onClose={handleClose}>
        {(handleAgree, handleCancel) => (
          <>
            <Box
              noValidate
              component="form"
              onSubmit={handleSubmit(handleFormSubmit)}>
              <DialogContent sx={{ py: 1 }}>
                <Typography color="text.secondary" variant="body1">
                  {intl.get("t_dialog_please_answer_reason")}
                </Typography>
                <Checkbox
                  size="medium"
                  name="jobRevokedReasons"
                  control={control}
                  setValue={setValue}
                  options={CANDIDATE_JOB_REVOKED_REASON_OPTIONS}
                />
                {/* FIXME: this is phase2 - need to handle email and phone number check */}
                {/* <TextField
                  control={control}
                  name="otherDeclinedReason"
                  placeholder={intl.get("t_general_other_decline_reason")}
                /> */}
              </DialogContent>
              <DialogActions>
                <Button handleClick={handleCancel} variant="outlined">
                  {intl.get("t_dialog_revoke_cancel_button")}
                </Button>
                <Button type="submit">
                  {intl.get("t_dialog_revoke_agree_button")}
                </Button>
              </DialogActions>
            </Box>
          </>
        )}
      </Dialog>
    ) : (
      <Button disabled>{intl.get("t_general_revoke")}</Button>
    );
  };

  const DisabledAppliedBtn = () => {
    return (
      <Cursor type="default">
        <Button disabled>{intl.get("t_general_applied")}</Button>
      </Cursor>
    );
  };

  const JobDetails = () => {
    return (
      <Box
        p={2}
        borderRadius={1}
        bgcolor="common.white"
        onClick={handleClick}
        sx={{
          filter: !isJobActive ? "grayscale(1)" : "unset",
          border:
            isJobActive && isHighlighted
              ? `1px solid ${theme.palette.primary.main}`
              : ""
        }}>
        {/* job status and time text start */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Stack direction="row" columnGap={1} alignItems="center">
            {status.includes(JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED) ? (
              <JobStatus
                status={[JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED]}
              />
            ) : status.includes(
                JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRING_FLOW_STEP_CHANGED
              ) &&
              hiringFlow &&
              hiringFlowActiveStepKey ? (
              <JobStatus
                status={status}
                labelText={
                  generateHiringProcessSteps(hiringFlow).find(
                    (singleHiringProcessStep) =>
                      singleHiringProcessStep?.key === hiringFlowActiveStepKey
                  )?.label
                }
              />
            ) : (
              <JobStatus status={status} />
            )}
            {(status.includes(
              JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED
            ) ||
              status.includes(
                JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED
              )) &&
            applicationDeclinedOrRevokedReasons.length > 0 ? (
              <Tooltip
                enterTouchDelay={0}
                arrow
                title={
                  <Box p={1}>
                    <Typography mb={0.5} variant="body1">
                      {status.includes(
                        JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED
                      )
                        ? `${intl.get(
                            JOB_APPLICATION_INVITATION_ACTION_TYPE_T_LABELS[
                              JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED
                            ]
                          )} ${intl.get("t_general_reasons")}:`
                        : status.includes(
                            JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED
                          )
                        ? `${intl.get(
                            JOB_APPLICATION_INVITATION_ACTION_TYPE_T_LABELS[
                              JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED
                            ]
                          )} ${intl.get("t_general_reasons")}:`
                        : false}
                    </Typography>
                    <StyledList>
                      <Stack rowGap={0.5}>
                        {applicationDeclinedOrRevokedReasons.map(
                          (singleApplicationDeclinedReason, index) => {
                            const declinedReason: string =
                              resolveMultiLingual(
                                singleApplicationDeclinedReason
                              ) ?? "";
                            return (
                              <>
                                {declinedReason ? (
                                  <ListItem key={index}>
                                    {declinedReason}
                                  </ListItem>
                                ) : (
                                  false
                                )}
                              </>
                            );
                          }
                        )}
                      </Stack>
                    </StyledList>
                  </Box>
                }>
                <InfoOutlinedIcon />
              </Tooltip>
            ) : (
              false
            )}
          </Stack>
          <Typography
            component="p"
            display={{ xs: "none", sm: "block" }}
            variant="body2"
            color="text.secondary"
            ml={1}>
            {intl.get("t_general_updated")}&nbsp;{relativeTime}
          </Typography>
        </Stack>
        {/* job status and time text end */}
        {/* company and role information start */}
        <CompanyInfo
          companyLogo={companyLogo}
          companyName={companyName}
          location={location}
          jobTitle={jobTitle}
        />
        {/* company and role information end */}
        {/* category and revoke button for desktop start */}
        <Stack gap={1} direction="row" alignItems="center" flexWrap="wrap">
          <JobCategories categories={categories} />
          <Box ml="auto" display={{ xs: "none", sm: "block" }}>
            {/* FIXME: Check by Aayush after merge */}
            <Box onClick={(e) => e.stopPropagation()}>
              {isDisabled ? <DisabledAppliedBtn /> : <RevokeBtn />}
            </Box>
          </Box>
        </Stack>
        {/* category and revoke button for desktop end */}
        {/* time and revoke btn for mobile start */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          display={{ xs: "flex", sm: "none" }}
          mt={1}>
          <Typography variant="body2" color="text.secondary">
            {intl.get("t_general_updated")}&nbsp;{relativeTime}
          </Typography>
          <Box onClick={(e) => e.stopPropagation()}>
            {isRevoked ? <DisabledAppliedBtn /> : <RevokeBtn />}
          </Box>
        </Stack>
        {/* time and revoke btn for mobile end */}
      </Box>
    );
  };

  return isJobActive ? (
    <Cursor>
      {status.includes(JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED) ? (
        <Tooltip
          arrow
          placement="top"
          enterTouchDelay={0}
          title={
            <Typography variant="body2">
              {intl.get("t_general_congratulations")}
            </Typography>
          }>
          <div>
            <JobDetails />
          </div>
        </Tooltip>
      ) : (
        <JobDetails />
      )}
    </Cursor>
  ) : (
    <Tooltip
      arrow
      placement="top"
      enterTouchDelay={0}
      title={
        <Typography variant="body2">
          {intl.get("t_job_not_active_tooltip")}
        </Typography>
      }>
      <div>
        <JobDetails />
      </div>
    </Tooltip>
  );
};

export default AppliedJobCard;
