import { Box } from "@mui/material";

import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonCompanyInfo from "@skeletons/SkeletonCompanyInfo";
import SkeletonJobCategory from "@skeletons/SkeletonJobCategory";
import SkeletonStatus from "@skeletons/SkeletonStatus";
import SkeletonTypography from "@skeletons/SkeletonTypography";

const SkeletonAppliedJobCard = () => {
  return (
    <Box p={2} borderRadius={1} bgcolor="common.white">
      {/* job status and time text start */}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <SkeletonStatus />
        <SkeletonTypography
          display={{ xs: "none", sm: "block" }}
          variant="body2"
          ml="auto"
        />
      </Box>
      {/* job status and time text end */}

      {/* company and role information start */}
      <SkeletonCompanyInfo />
      {/* company and role information end */}

      {/* category and revoke button for desktop start */}
      <Box gap={1} display="flex" alignItems="center" flexWrap="wrap">
        <SkeletonJobCategory multiple />
        <Box ml="auto" display={{ xs: "none", sm: "block" }}>
          <SkeletonButton />
        </Box>
      </Box>
      {/* category and revoke button for desktop end */}
      {/* time and revoke btn for mobile start */}
      <Box
        justifyContent="space-between"
        alignItems="center"
        display={{ xs: "flex", sm: "none" }}
        mt={1}>
        <SkeletonTypography variant="body2" color="text.secondary" />
        <SkeletonButton />
      </Box>
      {/* time and revoke btn for mobile end */}
    </Box>
  );
};

export default SkeletonAppliedJobCard;
