import { Divider, Stack } from "@mui/material";

import EmployerAccountInformation from "@components/EmployerAccountInformation";
import EmployerAccountManagement from "@components/EmployerAccountManagement";
import Paper from "@components/Paper";
import Typography from "@components/Typography";

import { intl } from "@utils/translate";

const AccountSettings = () => {
  return (
    <Paper>
      <Typography variant="h3">
        {intl.get("t_account_setting_title")}
      </Typography>

      <Stack gap={3}>
        <EmployerAccountInformation />
        <Divider />
        <EmployerAccountManagement />
      </Stack>
    </Paper>
  );
};

export default AccountSettings;
