// Note: Desktop only component

import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  AccountBalanceWalletRounded as AccountBalanceWalletRoundedIcon,
  ContentPasteRounded as ContentPasteRoundedIcon,
  ExitToAppRounded as ExitToAppRoundedIcon,
  FavoriteRounded as FavoriteRoundedIcon,
  MarkEmailReadRounded as MarkEmailReadRoundedIcon,
  PersonRounded as PersonRoundedIcon,
  SettingsRounded as SettingsRoundedIcon,
  WorkRounded as WorkRoundedIcon
} from "@mui/icons-material";
import {
  Box,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Link as MuiLink
} from "@mui/material";
import { useSignOut } from "react-firebase-hooks/auth";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";

import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";
import { ENVIRONMENT, USER_TYPE } from "@utils/config";
import { auth } from "@utils/firebase";
import translate, { intl } from "@utils/translate";

interface ProfileMenuProps {
  anchorEl: HTMLElement | null;
  openProfileMenu: boolean;
  handleClose: () => void;
}

const ProfileMenu = ({
  anchorEl,
  openProfileMenu,
  handleClose
}: ProfileMenuProps) => {
  const [signOut] = useSignOut(auth);
  const userProfile = useUserProfile();
  const companyDetails = useCompanyDetails();

  const location = useLocation();
  const navigate = useNavigate();

  const currentPath = translate.getNonLocalizedURL(location.pathname);
  const isEmployer =
    userProfile?.value?.user_type === USER_TYPE.COMPANY ||
    userProfile?.value?.user_type === USER_TYPE.COMPANY_MANAGER;

  const creditsAvailable = companyDetails.value?.total_credits_available ?? 0;

  return (
    <Menu
      anchorEl={anchorEl}
      id="profile-menu"
      open={openProfileMenu}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      sx={{
        display: { xs: "none", md: "block" }
      }}
      data-testid="profile_menu">
      {isEmployer ? (
        <Box component="div">
          {/* TODO: Remove env specific after Saved Candidate Page implementation */}
          <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
            <MuiLink
              component={Link}
              to={`/${translate.getCurrentLocale()}/employers/settings/plan`}
              underline="none"
              color="text.primary"
              onClick={handleClose}
              data-testid="employer_menu_credits_link">
              <MenuItem
                selected={currentPath.startsWith("/employers/settings/plan")}>
                <ListItemIcon>
                  <AccountBalanceWalletRoundedIcon fontSize="medium" />
                </ListItemIcon>
                {intl.get("t_profile_menu_credits", {
                  credits: creditsAvailable
                })}
              </MenuItem>
            </MuiLink>
          </EnvironmentSpecific>
          <MuiLink
            component={Link}
            to={`/${translate.getCurrentLocale()}/employers/saved-candidates`}
            underline="none"
            color="text.primary"
            onClick={handleClose}
            data-testid="employer_menu_saved_candidates_link">
            <MenuItem
              selected={currentPath.startsWith("/employers/saved-candidates")}>
              <ListItemIcon>
                <FavoriteRoundedIcon fontSize="medium" />
              </ListItemIcon>
              {intl.get("t_profile_menu_saved_candidate")}
            </MenuItem>
          </MuiLink>
          <Divider />
          <MuiLink
            component={Link}
            to={`/${translate.getCurrentLocale()}/employers/settings`}
            underline="none"
            color="text.primary"
            onClick={handleClose}
            data-testid="employer_menu_account_setting_link">
            <MenuItem
              selected={
                currentPath.startsWith("/employers/settings") &&
                !currentPath.endsWith("/plan")
              }>
              <ListItemIcon>
                <SettingsRoundedIcon fontSize="medium" />
              </ListItemIcon>
              {intl.get("t_profile_menu_account_setting")}
            </MenuItem>
          </MuiLink>
        </Box>
      ) : (
        <Box component="div">
          <MuiLink
            component={Link}
            to={`/${translate.getCurrentLocale()}/profile`}
            underline="none"
            color="text.primary"
            onClick={handleClose}
            data-testid="candidate_menu_profile_link">
            <MenuItem selected={currentPath.endsWith("/profile")}>
              <ListItemIcon>
                <PersonRoundedIcon fontSize="medium" />
              </ListItemIcon>
              {intl.get("t_profile_menu_profile")}
            </MenuItem>
          </MuiLink>
          <MuiLink
            component={Link}
            to={`/${translate.getCurrentLocale()}/profile/resume`}
            underline="none"
            color="text.primary"
            onClick={handleClose}
            data-testid="candidate_menu_resume_link">
            <MenuItem
              selected={
                currentPath.startsWith("/profile/resume/en-cv") ||
                currentPath.startsWith("/profile/resume/ja-cv") ||
                currentPath.startsWith("/profile/resume/ja-rirekisho")
              }>
              <ListItemIcon>
                <ContentPasteRoundedIcon fontSize="medium" />
              </ListItemIcon>
              {intl.get("t_profile_menu_my_resume")}
            </MenuItem>
          </MuiLink>
          <MuiLink
            component={Link}
            to={`/${translate.getCurrentLocale()}/profile/jobs/bookmarked`}
            underline="none"
            color="text.primary"
            onClick={handleClose}
            data-testid="candidate_menu_saved_jobs_link">
            <MenuItem
              selected={currentPath.startsWith("/profile/jobs/bookmarked")}>
              <ListItemIcon>
                <FavoriteRoundedIcon fontSize="medium" />
              </ListItemIcon>
              {intl.get("t_profile_menu_saved_jobs")}
            </MenuItem>
          </MuiLink>
          <MuiLink
            component={Link}
            to={`/${translate.getCurrentLocale()}/profile/jobs/applied`}
            underline="none"
            color="text.primary"
            onClick={handleClose}
            data-testid="candidate_menu_applied_jobs_link">
            <MenuItem
              selected={currentPath.startsWith("/profile/jobs/applied")}>
              <ListItemIcon>
                <WorkRoundedIcon fontSize="medium" />
              </ListItemIcon>
              {intl.get("t_profile_menu_applied_jobs")}
            </MenuItem>
          </MuiLink>
          <MuiLink
            component={Link}
            to={`/${translate.getCurrentLocale()}/profile/jobs/invited`}
            underline="none"
            color="text.primary"
            onClick={handleClose}
            data-testid="candidate_menu_invited_jobs_link">
            <MenuItem
              selected={currentPath.startsWith("/profile/jobs/invited")}>
              <ListItemIcon>
                <MarkEmailReadRoundedIcon fontSize="medium" />
              </ListItemIcon>
              {intl.get("t_profile_menu_invited_jobs")}
            </MenuItem>
          </MuiLink>
          <Divider />
          <MuiLink
            component={Link}
            to={`/${translate.getCurrentLocale()}/profile/settings`}
            underline="none"
            color="text.primary"
            onClick={handleClose}
            data-testid="candidate_menu_account_setting_link">
            <MenuItem
              selected={
                currentPath.startsWith("/profile/settings") ||
                currentPath.startsWith("/user-terms-and-conditions") ||
                currentPath.startsWith("/privacy-policy") ||
                currentPath.startsWith("/employer-terms-and-conditions")
              }>
              <ListItemIcon>
                <SettingsRoundedIcon fontSize="medium" />
              </ListItemIcon>
              {intl.get("t_profile_menu_account_setting")}
            </MenuItem>
          </MuiLink>
        </Box>
      )}
      <MenuItem
        color="text.primary"
        onClick={() => {
          signOut();
          handleClose();
          navigate(
            isEmployer
              ? `/${translate.getCurrentLocale()}/employers`
              : `/${translate.getCurrentLocale()}/`
          );
        }}
        data-testid="general_profile_menu_signout">
        <ListItemIcon>
          <ExitToAppRoundedIcon fontSize="medium" />
        </ListItemIcon>
        {intl.get("t_general_signout")}
      </MenuItem>
    </Menu>
  );
};

export default ProfileMenu;
