import { useState } from "react";

import { useForm } from "react-hook-form";
import { Step } from "react-joyride";

import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, TextareaAutosize, TextField } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import * as yup from "yup";

import Button from "@components/Button";
import FTUIContainer from "@components/FTUIContainer";
import Keyword from "@components/Keyword";
import TypeableSelect from "@components/TypeableSelect";
import Typography from "@components/Typography";

import useToast from "@hooks/useToast";

import KeyLabel from "@interfaces/components/KeyLabel";

import { functions } from "@utils/firebase";
import { getSkillLabel, getSkillList } from "@utils/keyLabelHandlers/skill";
import { addSkill } from "@utils/metaSkills";
import translate, { intl } from "@utils/translate";

interface FormData {
  skill: string;
}

const Skills = () => {
  const tourSteps: Array<Step> = [
    {
      target: "#skill-step",
      title: intl.get("t_ftui_debugger_skill_title"),
      content: intl.get("t_ftui_debugger_skill_description"),
      disableBeacon: true
    },
    {
      target: "#select-skill-step",
      title: intl.get("t_ftui_debugger_skill_select_title"),
      content: intl.get("t_ftui_debugger_skill_select_description")
    },
    {
      target: "#add-skill-step",
      title: intl.get("t_ftui_debugger_skill_add_title"),
      content: intl.get("t_ftui_debugger_skill_add_description")
    },
    {
      target: "#check-skill-label-step",
      title: intl.get("t_ftui_debugger_skill_check_label_title"),
      content: intl.get("t_ftui_debugger_skill_check_label_description")
    }
  ];

  const toast = useToast();
  const [newSkill, setNewSkill] = useState<string>("");
  const [existingSkill, setExistingSkill] = useState<string>("");
  const [skillRecommendationContent, setSkillRecommendationContent] =
    useState<string>("");
  const [recommendedSkills, setRecommendedSkills] = useState<Array<KeyLabel>>(
    []
  );
  const getRecommendedSkillsByContent = httpsCallable(
    functions,
    "getRecommendedSkillsByContent"
  );

  const addNewSkill = async () => {
    try {
      const skillKey = await addSkill(newSkill);
      if (skillKey !== "") {
        toast.kampai(`Skill Added, key - ${skillKey}`, "success");
      } else {
        toast.kampai("Cannot Add Skill", "error");
      }
    } catch (e) {
      toast.kampai("Cannot Add Skill", "error");
    }
  };

  const getExistingSkillLabel = async () => {
    try {
      const skillLabel = await getSkillLabel(
        existingSkill,
        translate.getCurrentLocale()
      );
      if (skillLabel !== undefined) {
        toast.kampai(`Skill - ${skillLabel}`, "success");
      } else {
        toast.kampai("Skill Not Found", "error");
      }
    } catch (e) {
      toast.kampai("Cannot get Skill Label", "error");
    }
  };

  const methods = useForm<FormData>({
    resolver: yupResolver(
      yup.object({
        skill: yup
          .object()
          .shape({
            key: yup.string(),
            label: yup.string()
          })
          .nullable()
      })
    )
  });

  const { control } = methods;

  const getRecommendedSkills = async () => {
    const recommendedSkillsResponse = await getRecommendedSkillsByContent({
      content: skillRecommendationContent
    });
    if (recommendedSkillsResponse && recommendedSkillsResponse.data) {
      const recommendedSkills = recommendedSkillsResponse.data as {
        related_skills: Array<KeyLabel>;
        status: boolean;
      };
      setRecommendedSkills(recommendedSkills.related_skills ?? []);
    }
  };

  return (
    <>
      <Typography variant="h5" id="skill-step">
        Skills:
      </Typography>
      <hr />
      <br />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" id="select-skill-step">
            Select a skill
          </Typography>
          <br />
          <TypeableSelect
            control={control}
            getOptions={getSkillList}
            name="skills"
            placeholder="Select a skill"
            options={[]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" id="add-skill-step">
            Add skill
          </Typography>
          <br />
          <TextField
            sx={{ marginTop: "12px" }}
            fullWidth
            label="Skill Name"
            variant="outlined"
            value={newSkill}
            onChange={(e) => setNewSkill(e.target.value)}
          />
          <br />
          <br />
          <Button variant="contained" handleClick={addNewSkill}>
            Add Skill
          </Button>
        </Grid>
      </Grid>
      <br />
      <hr />
      <Typography variant="body1" id="check-skill-label-step">
        Check Skill Label:
      </Typography>
      <br />
      <Stack direction="row" spacing={2}>
        <TextField
          fullWidth
          label="Skill Key"
          variant="outlined"
          value={existingSkill}
          onChange={(e) => setExistingSkill(e.target.value)}
        />
        <Button color="secondary" handleClick={getExistingSkillLabel}>
          Check
        </Button>
      </Stack>
      <FTUIContainer tourSteps={tourSteps} />

      <br />
      <br />
      <TextareaAutosize
        minRows={5}
        style={{ width: "100%" }}
        placeholder="Enter the content to get recommended skills"
        value={skillRecommendationContent}
        onChange={(e) => setSkillRecommendationContent(e.target.value)}
      />
      <br />
      <Button variant="contained" handleClick={getRecommendedSkills}>
        Get Recommended Skills
      </Button>
      <br />
      <Stack direction="row" gap={1.5} mt={2}>
        {recommendedSkills.map((singleRecommendedSkill) => (
          <Keyword
            key={singleRecommendedSkill.key}
            label={singleRecommendedSkill.label}
          />
        ))}
      </Stack>
    </>
  );
};

export default Skills;
