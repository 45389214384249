/**
 * Fetches the Test Result based on the Japanese Test Name and config values.
 *
 * @param {string} testName
 * @param {string} testResult
 * @return {string} test result
 */

import {
  BJT_SCORE_T_LABELS,
  J_TEST_SCORE_T_LABELS,
  JA_LANGUAGE_TEST,
  JLPT_SCORE_T_LABELS,
  LOCALE,
  NAT_SCORE_T_LABELS
} from "@utils/config";
import translate from "@utils/translate";

export const jaTestResultFinder = (
  testName?: string,
  testResult?: string,
  locale?: typeof LOCALE[keyof typeof LOCALE]
) => {
  const { JLPT, NAT, BJT, J_TEST } = JA_LANGUAGE_TEST;
  const intl = translate.getIntlByLocale(locale);

  //FIXME: Recheck if the test name is coming in number format & remove string conversion accordingly
  switch (testName?.toString()) {
    case JLPT: {
      return intl.get(
        JLPT_SCORE_T_LABELS[testResult as keyof typeof JLPT_SCORE_T_LABELS]
      );
    }
    case NAT: {
      return intl.get(
        NAT_SCORE_T_LABELS[testResult as keyof typeof NAT_SCORE_T_LABELS]
      );
    }
    case BJT: {
      return intl.get(
        BJT_SCORE_T_LABELS[testResult as keyof typeof BJT_SCORE_T_LABELS]
      );
    }
    case J_TEST: {
      return intl.get(
        J_TEST_SCORE_T_LABELS[testResult as keyof typeof J_TEST_SCORE_T_LABELS]
      );
    }
    default: {
      return intl.get("t_ja_language_test_none");
    }
  }
};
