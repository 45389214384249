import { Fragment } from "react";

import { Grid, Stack } from "@mui/material";

import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonInput from "@skeletons/SkeletonInput";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Paper from "@components/Paper";
import Typography from "@components/Typography";

import { intl } from "@utils/translate";

interface SkeletonJobOverviewFormProps {
  hasSingleField?: boolean;
}

const SkeletonJobOverviewForm = ({
  hasSingleField = false
}: SkeletonJobOverviewFormProps) => {
  return (
    <>
      {/* Job overview section start */}
      <Paper>
        <Typography variant="h3">
          {intl.get("t_profile_job_overview_heading")}
        </Typography>
        <br />
        <Typography mt={2.5} mb={2} color="text.secondary">
          {intl.get("t_profile_job_overview_sub_heading")}
        </Typography>
        <br />
        <Grid container columnSpacing={3}>
          {[...Array(hasSingleField ? 1 : 3)].map((_, index) => {
            return (
              <Fragment key={index}>
                <Grid item xs={12} md={5.5}>
                  <SkeletonInput />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <SkeletonInput />
                </Grid>
                <Grid item xs={12}>
                  <SkeletonTypography width="medium" variant="h4" />
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
        {/* add another text */}
        {!hasSingleField ? <SkeletonTypography variant="h6" /> : false}
      </Paper>
      {/* Job overview section end */}

      <Stack justifyContent="space-between" direction="row" mt={5.5}>
        <SkeletonButton />
        <SkeletonButton />
      </Stack>
    </>
  );
};

export default SkeletonJobOverviewForm;
