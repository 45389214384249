import { Avatar, styled } from "@mui/material";

import Icon from "@components/Icon";

interface JobDescriptionIconProps {
  icon:
    | "currency_yen"
    | "edit_document"
    | "forum"
    | "psychology"
    | "laptop_mac"
    | "business_center"
    | "schedule"
    | "record_voice_over";
  filled?: boolean;
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
  color: theme.palette.info.main,
  borderRadius: 2,
  width: 40,
  height: 40,
  fontSize: 24
}));

const JobDescriptionIcon = ({ icon, filled }: JobDescriptionIconProps) => {
  return (
    <StyledAvatar variant="square">
      <Icon type={icon} filled={filled} />
    </StyledAvatar>
  );
};

export default JobDescriptionIcon;
