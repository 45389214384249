interface DangerousComponentFromHTMLProps {
  html?: string;
}

const DangerousComponentFromHTML = ({
  html = ""
}: DangerousComponentFromHTMLProps) => {
  // This will sanitize and keep only valid html tags and attributes.
  const createTempHTMLElement = (html: string) => {
    const e = document.createElement("span");
    e.innerHTML = html;
    return e.childNodes[0]?.nodeValue ?? e.innerHTML ?? "";
  };

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: createTempHTMLElement(html)
      }}
    />
  );
};

export default DangerousComponentFromHTML;
