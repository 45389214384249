import { Box, Skeleton } from "@mui/material";

import SkeletonTypography from "@skeletons/SkeletonTypography";

interface SkeletonInputProps {
  multiLine?: boolean;
  hasLabel?: boolean;
}

const SkeletonInput = ({
  multiLine = false,
  hasLabel = true
}: SkeletonInputProps) => {
  return (
    <Box width="100%">
      {hasLabel ? <SkeletonTypography /> : false}
      <Box mb={hasLabel ? 2 : 0}>
        <Skeleton
          animation="wave"
          variant="rounded"
          width="100%"
          height={multiLine ? "14rem" : "3.5rem"}
        />
      </Box>
    </Box>
  );
};

export default SkeletonInput;
