import { Fragment } from "react";

import { Grid, Stack } from "@mui/material";

import SkeletonInput from "@skeletons/SkeletonInput";
import SkeletonKeyword from "@skeletons/SkeletonKeyword";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Paper from "@components/Paper";
import Typography from "@components/Typography";

import { intl } from "@utils/translate";

const SkeletonSkillsForm = () => {
  return (
    <>
      {/* Skills section start */}
      <Paper>
        <Typography variant="h3">
          {intl.get("t_profile_skill_heading")}
        </Typography>
        <br />
        <Typography mt={2.5} mb={2} color="text.secondary">
          {intl.get("t_profile_skill_sub_heading")}
        </Typography>
        <br />
        <Grid container columnSpacing={3}>
          {[...Array(1)].map((_, index) => {
            return (
              <Fragment key={index}>
                <Grid item xs={12} md={6}>
                  <SkeletonInput />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SkeletonInput />
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
        {/* add another text */}
        <SkeletonTypography variant="h6" />
        <br />
        <Typography
          variant="subtitle4"
          color="text.secondary"
          mt={2}
          mb={1.5}
          display="flex">
          {intl.get("t_profile_skill_selected_skills")}
        </Typography>
        <br />
        <Stack direction="row" gap={1} flexWrap="wrap">
          {[...Array(5)].map((_, index) => {
            return (
              <Fragment key={index}>
                <SkeletonKeyword />
              </Fragment>
            );
          })}
        </Stack>
      </Paper>
      {/* Skills section end */}
    </>
  );
};

export default SkeletonSkillsForm;
