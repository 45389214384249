import { useNavigate } from "react-router-dom";

import { NavigateNext as NavigateNextIcon } from "@mui/icons-material";
import { Box, styled } from "@mui/material";

import Button from "@components/Button";
import Paper from "@components/Paper";
import Typography from "@components/Typography";

import { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

const StyledBox = styled(Box)({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 4,
  borderRadius: 4,
  marginTop: 10,
  marginBottom: 12,
  position: "absolute",
  right: 0,
  top: -48,
  transform: "rotate(4.34deg)",
  boxShadow: `0px 3px 5px -1px ${colorPalette.grey[20]},
              0px 6px 10px ${colorPalette.grey[14]},
              0px 1px 18px ${colorPalette.grey[12]}`,
  backgroundColor: colorPalette.lighterBlue[20],
  color: colorPalette.lighterBlue.base
});

const Success = () => {
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
      }}>
      <Box position="relative" mt={6} mb={4}>
        <StyledBox>
          <Typography variant="subtitle5">
            {intl.get("t_job_post_success")}
          </Typography>
        </StyledBox>
        <Typography variant="h3" textAlign="center">
          {intl.get("t_job_post_success_message")}
        </Typography>
      </Box>
      <Button
        data-testid="search_candidates_button"
        color="primary"
        type="submit"
        size="large"
        endAdornment={<NavigateNextIcon />}
        handleClick={() =>
          navigate(`/${translate.getCurrentLocale()}/employers/search`)
        }>
        {intl.get("t_job_post_success_btn_search_candidates")}
      </Button>
    </Paper>
  );
};

export default Success;
