import { ReactNode, useEffect } from "react";

import { NavigateBefore as NavigateBeforeIcon } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Drawer as MuiDrawer,
  styled,
  SxProps,
  Theme
} from "@mui/material";

interface DrawerProps {
  initiator: ReactNode;
  drawerContent: ReactNode;
  isDrawerOpen: boolean;
  setIsDrawerOpen: (isDrawerOpen: boolean) => void;
  initiatorStyle?: SxProps<Theme>;
  initiatorComponent?: React.ElementType;
}

const StyledDrawer = styled(MuiDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    width: "100%",
    zIndex: 1000, //To show below  modal
    [theme.breakpoints.down("md")]: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    },
    [theme.breakpoints.up("md")]: { width: "90%" },
    [theme.breakpoints.up("lg")]: { width: "80%" }
  }
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary
}));

const Drawer = ({
  initiator,
  drawerContent,
  isDrawerOpen,
  setIsDrawerOpen,
  initiatorStyle,
  initiatorComponent
}: DrawerProps) => {
  //To disable background scroll when drawer is open
  useEffect(() => {
    if (isDrawerOpen && typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isDrawerOpen]);

  return (
    <>
      <Box
        sx={initiatorStyle}
        onClick={() => setIsDrawerOpen(true)}
        component={initiatorComponent}>
        {initiator}
      </Box>
      <StyledDrawer
        anchor="right"
        open={isDrawerOpen}
        disableScrollLock={true}
        onClose={() => setIsDrawerOpen(false)}>
        <Box mt={1}>
          <StyledIconButton onClick={() => setIsDrawerOpen(false)} size="large">
            <NavigateBeforeIcon />
          </StyledIconButton>
          {drawerContent}
        </Box>
      </StyledDrawer>
    </>
  );
};

export default Drawer;
