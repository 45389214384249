import { Fragment, useEffect, useState } from "react";

import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddCircle as AddCircleIcon,
  Add as AddIcon,
  Close as CloseIcon,
  NavigateBefore as NavigateBeforeIcon
} from "@mui/icons-material";
import { Box, Collapse, Grid, Stack, styled, Typography } from "@mui/material";
import { httpsCallable, HttpsCallableResult } from "firebase/functions";
import { MultiSearchResponse } from "typesense/lib/Typesense/MultiSearch";
import * as yup from "yup";

import SkeletonKeyword from "@skeletons/SkeletonKeyword";
import SkeletonSkillsForm from "@skeletons/SkeletonSkillsForm";

import AutoCompleteTextField from "@components/AutoCompleteTextField";
import Button from "@components/Button";
import Cursor from "@components/Cursor";
import Keyword from "@components/Keyword";
import NonTypeableSelect from "@components/NonTypeableSelect";
import Paper from "@components/Paper";
import Tag from "@components/Tag";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import KeyLabel from "@interfaces/components/KeyLabel";
import SkillData from "@interfaces/components/SkillData";
import TypesenseSkill from "@interfaces/database/TypesenseSkill";

import {
  FREE_TEXT_FIELD_MAX_LENGTH,
  LOCALE_SHORT,
  MAX_SKILLS,
  RELATED_SKILL_LIST_MAX_SIZE,
  SKILL_FIELD_MAX_LENGTH,
  TYPESENSE_COLLECTIONS,
  YEARS_OF_EXPERIENCE,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import { functions } from "@utils/firebase";
import { getSkillList } from "@utils/keyLabelHandlers/skill";
import { resolveMultiLingual } from "@utils/multiLingual";
import translate, { intl } from "@utils/translate";
import getClient from "@utils/typesense";

interface SkillRequirementForm {
  skills: Array<SkillData>;
}

const StyledTransitionGroup = styled(TransitionGroup)(() => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: 8
}));
interface SkillFormProps {
  initialValues: SkillRequirementForm;
  handleFormSubmit: (formData: SkillRequirementForm) => void;
  backBtnLink?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  formLanguage?: typeof LOCALE_SHORT[keyof typeof LOCALE_SHORT];
}

const emptySkill = {
  name: { key: "", label: "" },
  yearOfExperience: ""
};

const SkillsForm = ({
  handleFormSubmit,
  initialValues,
  backBtnLink,
  isLoading = false,
  isDisabled = false,
  formLanguage = LOCALE_SHORT.JA
}: SkillFormProps) => {
  const navigate = useNavigate();
  const userData = useUserProfile();
  const toast = useToast();
  const [isRecommendedSkillsLoading, setIsRecommendedSkillsLoading] =
    useState<boolean>(false);
  const [recommendedSkillListArr, setRecommendedSkillListArr] = useState<
    Array<KeyLabel>
  >([]);
  const [selectedSkillKey, setSelectedSkillKey] = useState<string>("");

  const userSkills = userData.value?.summary?.skills;
  const userJobOverview =
    userData.value?.summary?.job_experience_overview ?? [];
  const userJobDetails = userData.value?.cv?.job_experience ?? [];

  // validation schema
  const schema = yup.object({
    skills: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .object()
            .shape({
              key: yup.string().trim(),
              label: yup.string().trim()
            })
            .test(
              "label",
              intl.get("t_error_max_limit", {
                field: intl.get("t_general_skill"),
                maxLimit: SKILL_FIELD_MAX_LENGTH
              }),
              (value) => {
                return value && value.label
                  ? value.label.length <= SKILL_FIELD_MAX_LENGTH
                  : true;
              }
            )
            .test(
              "is-required",
              intl.get("t_error_required", {
                field: intl.get("t_general_skill")
              }),
              (
                skillName,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                context: any
              ) => {
                // check minimum 1 value is entered or not
                if (
                  context?.path === "skills[0].name" &&
                  context?.originalValue
                ) {
                  if (
                    !context?.originalValue?.key ||
                    !context?.originalValue?.label
                  ) {
                    return false;
                  }
                }

                // check yearOfExperience is entered then skill name is required
                const { yearOfExperience } = context.parent as SkillData;
                if (yearOfExperience) {
                  return !!skillName?.key;
                }
                return true;
              }
            )
            .test(
              "label",
              intl.get("t_error_max_limit", {
                field: intl.get("t_general_skill"),
                maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
              }),
              (value) => {
                return value && value.label
                  ? value.label.length <= FREE_TEXT_FIELD_MAX_LENGTH
                  : true;
              }
            )
            .nullable(),
          yearOfExperience: yup
            .string()
            .nullable()
            .test(
              "is-required",
              intl.get("t_error_required", {
                field: intl.get("t_profile_job_overview_year_of_experience")
              }),
              (
                yearOfExperience,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                context: any
              ) => {
                // check minimum 1 value is entered or not
                if (
                  context?.path === "skills[0].yearOfExperience" &&
                  !context.originalValue
                ) {
                  return false;
                }

                // check skill name is entered then yearOfExperience is required
                const { name } = context.parent as SkillData;
                if (name?.key) {
                  return !!yearOfExperience && yearOfExperience.length > 0;
                }
                return true;
              }
            )
        })
      )
      .test("is-duplicate", "", (skills, { createError }) => {
        if (skills) {
          const skillLabels = new Set<string>();

          for (const singleSkill of skills) {
            // skip if the skill is not entered
            if (!singleSkill.name || !singleSkill.name.label) {
              continue;
            }

            if (skillLabels.has(singleSkill.name.label.toLowerCase())) {
              // check if duplicate skill is entered and return error
              return createError({
                path: `skills[${skills.indexOf(singleSkill)}].name`,
                message: intl.get("t_error_duplicate_value", {
                  value: singleSkill.name.label
                })
              });
            }

            skillLabels.add(singleSkill.name.label.toLowerCase());
          }
          return true;
        } else {
          return true;
        }
      })
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    getValues,
    reset,
    setFocus
  } = methods;

  const skillsFieldArr = useFieldArray({
    control,
    name: "skills"
  });

  useEffect(() => {
    if (!isLoading && !userData.loading) {
      if (initialValues.skills && initialValues.skills.length > 0) {
        const skillKeys = initialValues.skills
          .map((singleSkill) => singleSkill?.name?.key ?? "")
          .filter((singleSkillKey) => singleSkillKey !== "");
        updateRecommendedSkills(skillKeys);
      } else {
        setIsRecommendedSkillsLoading(false);
      }
    }
    reset(initialValues);
    skillsFieldArr.append(emptySkill);
  }, [initialValues]);

  const YEARS_OF_EXPERIENCE_OPTIONS = useOptions(
    YEARS_OF_EXPERIENCE,
    YEARS_OF_EXPERIENCE_T_LABELS
  );

  const updateRecommendedSkills = async (skillKeys: string | Array<string>) => {
    if (!skillKeys || isRecommendedSkillsLoading) {
      return;
    }
    if (!Array.isArray(skillKeys)) {
      skillKeys = [skillKeys];
    }

    setIsRecommendedSkillsLoading(true);

    const recommendedSkills: Array<KeyLabel> = recommendedSkillListArr;

    for await (const singleSkillKey of skillKeys) {
      const currentLocale = translate.getCurrentLocale();
      const typesenseDocumentId = singleSkillKey
        ? `${singleSkillKey}_${currentLocale}`
        : "";
      const typesenseClient = getClient();
      const selectedSkillKeys = getValues("skills")
        .map((singleSkill: SkillData) =>
          singleSkill.name?.key
            ? `${singleSkill.name?.key}_${currentLocale}`
            : ""
        )
        .filter((singleKey) => singleKey !== "");
      const recommendedSkillsKeys = recommendedSkills
        .map((singleRecommendedSkill) =>
          singleRecommendedSkill.key
            ? `${singleRecommendedSkill.key}_${currentLocale}`
            : ""
        )
        .filter((singleKey) => singleKey !== "");

      const ignoreSkillKeys = [...selectedSkillKeys, ...recommendedSkillsKeys];

      const perPageCount = Math.max(
        RELATED_SKILL_LIST_MAX_SIZE - recommendedSkills.length,
        3
      );

      const relatedTypesenseSkillDocuments =
        (await typesenseClient.multiSearch.perform({
          searches: [
            {
              collection: TYPESENSE_COLLECTIONS.SKILLS,
              q: "*",
              query_by: "",
              vector_query: `embedding:([], id: ${typesenseDocumentId})`,
              per_page: perPageCount,
              hidden_hits: ignoreSkillKeys.join(","),
              filter_by: `locale:=${translate.getCurrentLocale()}`
            }
          ]
        })) as MultiSearchResponse<Array<TypesenseSkill>>;

      const relatedSkillsResults = relatedTypesenseSkillDocuments.results;
      if (
        !relatedSkillsResults ||
        !relatedSkillsResults[0] ||
        !relatedSkillsResults[0].hits
      ) {
        setIsRecommendedSkillsLoading(false);
        return;
      }
      const relatedSkills = relatedSkillsResults[0].hits;

      recommendedSkills.unshift(
        ...relatedSkills.map((singleRelatedSkill) => ({
          key: singleRelatedSkill.document.key,
          label: singleRelatedSkill.document.label
        }))
      );
    }

    const filteredRecommendedSkills = recommendedSkills
      .filter((singleRecommendedSkill) => {
        const isSkillAlreadyExistsByKey = skillKeys.includes(
          singleRecommendedSkill.key
        );
        return !isSkillAlreadyExistsByKey;
      })
      .slice(0, RELATED_SKILL_LIST_MAX_SIZE);

    setRecommendedSkillListArr([...filteredRecommendedSkills]);
    setIsRecommendedSkillsLoading(false);
  };

  useEffect(() => {
    if (userData.value) {
      const formLocale = translate.getLocaleFromShort(formLanguage);
      if (!userSkills || !Array.isArray(userSkills) || userSkills.length <= 1) {
        const jobTitles = userJobOverview.map((singleJobOverview) =>
          resolveMultiLingual(singleJobOverview.job_title, formLocale)
        );
        const jobResponsibilities = userJobDetails.map((singleJobDetail) =>
          resolveMultiLingual(singleJobDetail.responsibilities, formLocale)
        );
        const content = [...jobTitles, ...jobResponsibilities].join(", ") ?? "";
        if (content !== "") {
          (async () => {
            setIsRecommendedSkillsLoading(false);
            const getRecommendedSkillsByContent = httpsCallable(
              functions,
              "getRecommendedSkillsByContent"
            );
            try {
              const recommendedSkillInfo = (await getRecommendedSkillsByContent(
                {
                  content,
                  options: {
                    locale: formLocale
                  }
                }
              )) as HttpsCallableResult<{ related_skills?: Array<KeyLabel> }>;
              const relatedSkills: Array<KeyLabel> =
                recommendedSkillInfo.data?.related_skills ?? [];
              setRecommendedSkillListArr([...relatedSkills]);
              setIsRecommendedSkillsLoading(false);
            } catch (e) {
              setIsRecommendedSkillsLoading(false);
            }
          })();
        }
      }
    }
  }, [userData.value]);

  return (
    <Box noValidate component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      {/* Skills section start */}
      {isLoading || userData.loading ? (
        <SkeletonSkillsForm />
      ) : (
        <Paper>
          <Typography variant="h3">
            {intl.get("t_profile_skill_heading")}
          </Typography>
          <Typography mt={2.5} color="text.secondary">
            {intl.get("t_profile_skill_sub_heading")}
          </Typography>
          <Typography mb={3} color="text.secondary">
            {formLanguage === LOCALE_SHORT.JA
              ? intl.get("t_resumes_form_subtitle_lang_japanese")
              : intl.get("t_resumes_form_subtitle_lang_english")}
          </Typography>
          <Grid container columnSpacing={3}>
            {skillsFieldArr.fields.map(({ id }, index) => {
              return (
                <Fragment key={id}>
                  {skillsFieldArr.fields.length === index + 1 ? (
                    <Fragment key={id}>
                      <Grid item xs={12} md={6}>
                        <AutoCompleteTextField
                          data-testid="profile_skill_name_input"
                          disabled={isDisabled}
                          control={control}
                          name={`skills.${index}.name`}
                          label={intl.get("t_profile_skill_skill")}
                          placeholder={intl.get("t_profile_skill_skill")}
                          required
                          getOptions={getSkillList}
                          setValue={(_, value, options) => {
                            setSelectedSkillKey(value?.key ?? "");
                            setValue(`skills.${index}.name`, value, options);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <NonTypeableSelect
                          data-testid="profile_skill_year_of_experience_select"
                          disabled={isDisabled}
                          setValue={setValue}
                          control={control}
                          name={`skills.${index}.yearOfExperience`}
                          label={intl.get("t_profile_skill_experience")}
                          placeholder={intl.get("t_profile_skill_experience")}
                          required
                          options={YEARS_OF_EXPERIENCE_OPTIONS}
                        />
                      </Grid>
                    </Fragment>
                  ) : (
                    false
                  )}
                </Fragment>
              );
            })}
          </Grid>
          <Button
            data-testid="profile_skill_add_button"
            color="primary"
            disabled={isDisabled}
            handleClick={async () => {
              // if the max limit is exceeded, set a toast notification
              if (skillsFieldArr.fields.length > MAX_SKILLS) {
                toast.kampai(
                  intl.get("t_toast_error_limit_exceed", {
                    fieldName: intl.get("t_profile_skill_skill"),
                    maxLimit: MAX_SKILLS
                  }),
                  "error"
                );
                return;
              }
              const isValid = await trigger("skills");
              // if skill is not selected then return
              if (!watch(`skills.${skillsFieldArr.fields.length - 1}.name`)) {
                return;
              }
              if (isValid) {
                const skillKey = getValues(
                  `skills.${skillsFieldArr.fields.length - 1}.name.key`
                );

                updateRecommendedSkills(skillKey);
                return skillsFieldArr.append(emptySkill);
              }
            }}
            size="small"
            startAdornment={<AddCircleIcon />}
            variant="text">
            {intl.get("t_profile_skill_add_skill")}
          </Button>

          <Box>
            <Typography variant="h4" my={2}>
              {intl.get("t_general_recommended_skills")}
            </Typography>
            {isRecommendedSkillsLoading || isLoading ? (
              <Stack
                direction="row"
                gap={1}
                mt={2}
                alignItems="center"
                flexWrap="wrap">
                {[...Array(10)].map((_, idx) => (
                  <SkeletonKeyword key={idx} />
                ))}
              </Stack>
            ) : recommendedSkillListArr.length == 0 ? (
              <Typography variant="subtitle4" color="text.secondary">
                Please fill job overview or select some skills to get
                recommendations
              </Typography>
            ) : (
              <Stack
                direction="row"
                gap={1}
                mt={2}
                alignItems="center"
                flexWrap="wrap">
                {recommendedSkillListArr.map((singleRecommendedSkill) => (
                  <Box
                    key={singleRecommendedSkill.key}
                    onClick={() => {
                      setSelectedSkillKey(singleRecommendedSkill.key);
                      setValue(
                        `skills.${skillsFieldArr.fields.length - 1}.name`,
                        singleRecommendedSkill
                      );
                      setFocus(
                        `skills.${
                          skillsFieldArr.fields.length - 1
                        }.yearOfExperience`
                      );
                    }}>
                    <Keyword
                      isClickable
                      isSelected={
                        singleRecommendedSkill.key === selectedSkillKey
                      }
                      label={singleRecommendedSkill.label}
                      startAdornment={<AddIcon height={24} width={24} />}
                    />
                  </Box>
                ))}
              </Stack>
            )}
          </Box>

          {/* if 1 skill added then show selected skills section */}
          {skillsFieldArr.fields.length > 1 ? (
            <>
              <Typography
                variant="subtitle4"
                color="text.secondary"
                mt={2}
                mb={1.5}
                display="flex">
                {intl.get("t_profile_skill_selected_skills")}
              </Typography>
              {/* ignore last index object from rendering */}
              <StyledTransitionGroup>
                {getValues("skills")
                  .slice(0, skillsFieldArr.fields.length - 1)
                  .map((singleField: SkillData, index: number) => {
                    return (
                      <Collapse key={index}>
                        {singleField?.name?.label &&
                        singleField?.yearOfExperience ? (
                          <Tag
                            label={`${singleField?.name?.label} ${intl.get(
                              YEARS_OF_EXPERIENCE_T_LABELS[
                                singleField?.yearOfExperience as keyof typeof YEARS_OF_EXPERIENCE_T_LABELS
                              ]
                            )}`}
                            endAdornment={
                              <Box
                                component="span"
                                onClick={() => {
                                  if (!isDisabled) {
                                    skillsFieldArr.remove(index);
                                  }
                                }}>
                                <Cursor
                                  type={isDisabled ? "not-allowed" : "pointer"}>
                                  <CloseIcon fontSize="small" />
                                </Cursor>
                              </Box>
                            }
                          />
                        ) : (
                          false
                        )}
                      </Collapse>
                    );
                  })}
              </StyledTransitionGroup>
            </>
          ) : (
            false
          )}
        </Paper>
      )}
      {/* Skills section end */}

      <Stack justifyContent="space-between" direction="row" mt={5.5}>
        {backBtnLink ? (
          <Button
            data-testid="profile_skill_back_button"
            variant="outlined"
            startAdornment={<NavigateBeforeIcon />}
            handleClick={() => navigate(backBtnLink)}>
            {intl.get("t_general_back")}
          </Button>
        ) : (
          false
        )}
        {isLoading || userData.loading ? (
          <Button>{intl.get("t_general_save")}</Button>
        ) : (
          <Button
            type="submit"
            loading={isDisabled}
            data-testid="profile_skill_save_button">
            {intl.get("t_general_save")}
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default SkillsForm;
