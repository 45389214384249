import { useEffect, useRef } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_continentsLow from "@amcharts/amcharts5-geodata/continentsLow";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import Marker from "@assets/images/WorldMap_Marker.svg";

interface WorldMapProps {
  containerId?: string;
  locationData?: {
    type: "FeatureCollection";
    features: Array<{
      type: "Feature";
      properties: {
        name: string;
      };
      geometry: {
        type: "Point";
        coordinates: [number, number];
      };
    }>;
  };
}

const WorldMap = ({
  containerId = "world-map",
  locationData
}: WorldMapProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  let markerDimension: number;

  if (isSmallScreen) {
    markerDimension = 16;
  } else if (isMediumScreen) {
    markerDimension = 32;
  } else if (isLargeScreen) {
    markerDimension = 40;
  }

  const rootRef = useRef<am5.Root | null>(null);
  const chartRef = useRef<am5map.MapChart | null>(null);

  useEffect(() => {
    // Chart Setup
    if (!rootRef.current) {
      rootRef.current = am5.Root.new(containerId);

      const root = rootRef.current;
      let chart = chartRef.current;

      // Set themes
      const myTheme = am5.Theme.new(root);

      myTheme.rule("Label").setAll({
        fontFamily: "Poppins"
      });
      root.setThemes([am5themes_Animated.new(root), myTheme]);

      if (root._logo) {
        root._logo.dispose(); // Remove AMCharts watermark
      }

      chart = root.container.children.push(
        am5map.MapChart.new(root, {
          maxZoomLevel: 1,
          panX: "none",
          panY: "none",
          wheelY: "none",
          pinchZoom: false,
          projection: am5map.geoNaturalEarth1()
        })
      );

      // Polygon Series
      const continents = [
        "europe",
        "asia",
        "africa",
        "northAmerica",
        "southAmerica",
        "oceania"
      ];
      const data = continents.map((continent) => ({
        id: continent,
        settings: {
          fill: am5.color(0xffffff),
          fillPattern: am5.CirclePattern.new(root, {
            color: am5.color(0xced3e0),
            checkered: false,
            fillOpacity: 0,
            gap: 3,
            radius: 1.5,
            centered: false
          })
        }
      }));

      const polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_continentsLow,
          exclude: ["antarctica"]
        })
      );

      polygonSeries.mapPolygons.template.setAll({
        interactive: true,
        templateField: "settings"
      });

      polygonSeries.data.setAll(data);

      // Tooltip
      const tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        autoTextColor: false
      });

      tooltip.label.setAll({
        fill: am5.color(0x1859ff),
        fontSize: 12,
        fontWeight: "400"
      });

      tooltip.get("background")?.setAll({
        fill: am5.color(0xffffff),
        fillOpacity: 1
      });

      // Marker
      const pointSeries = chart?.series.push(
        am5map.MapPointSeries.new(root, {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          geoJSON: locationData as any
        })
      );

      pointSeries?.bullets.push(() => {
        const bullet = am5.Picture.new(root, {
          tooltipText: "{name}",
          src: Marker,
          height: markerDimension,
          width: markerDimension,
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          cursorOverStyle: "pointer",
          tooltip: tooltip
        });

        bullet.appear(5000); // Animate the bullet's scale and opacity

        return am5.Bullet.new(root, {
          sprite: bullet
        });
      });
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
        chartRef.current = null;
      }

      if (rootRef.current) {
        rootRef.current.dispose();
        rootRef.current = null;
      }
    };
  }, [isSmallScreen, isMediumScreen, isLargeScreen, containerId]);

  return (
    <Box
      id={containerId}
      width="100%"
      height="100%"
      overflow="hidden"
      sx={{ pointerEvents: { xs: "none", md: "unset" } }}
    />
  );
};

export default WorldMap;
