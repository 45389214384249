import { ChangeEvent } from "react";

import { useParams } from "react-router-dom";

import { CheckCircleRounded as CheckCircleRoundedIcon } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  Link,
  Stack
} from "@mui/material";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import { ref } from "firebase/storage";
import { useDownloadURL } from "react-firebase-hooks/storage";

import ActionButton from "@components/ActionButton";
import Button from "@components/Button";
import Dialog from "@components/Dialog";
import Icon from "@components/Icon";
import Typography from "@components/Typography";
import Well from "@components/Well";

import useToast from "@hooks/useToast";

import UploadedFileInformation from "@interfaces/database/UploadedFileInformation";

import {
  DIALOG_ACTION,
  FILES_LOCATION_JOB_ADDITIONAL_FILE,
  JOB_DESCRIPTION_FILE_UPLOAD_CONFIG as UPLOAD_CONFIG
} from "@utils/config";
import { storage } from "@utils/firebase";
import getFileMetaInformation from "@utils/getFileMetaInformation";
import theme from "@utils/theme";
import translate, { intl } from "@utils/translate";

dayjs.extend(relative);

interface UploadJobDetailCardProps {
  "fileDetails"?: UploadedFileInformation;
  "handleFileUpload"?: (file: File) => void;
  "handleDelete"?: () => void;
  "disabledActions"?: boolean;
  "data-testid"?: string;
}

const UploadJobDetailCard = ({
  fileDetails,
  handleFileUpload,
  handleDelete,
  disabledActions = false,
  "data-testid": dataTestId
}: UploadJobDetailCardProps) => {
  const toast = useToast();
  const { job_id: jobId } = useParams();
  const [downloadFileUrl] = fileDetails?.extension
    ? useDownloadURL(
        ref(
          storage,
          `${FILES_LOCATION_JOB_ADDITIONAL_FILE}/${jobId}.${fileDetails.extension}`
        )
      )
    : [""];

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;

    if (!input?.files?.length) {
      return;
    }

    const file = input.files[0];
    const { fileSizeInMb, fileExt } = getFileMetaInformation(file);

    if (!UPLOAD_CONFIG.fileFormat.includes(fileExt)) {
      toast.kampai(
        intl.get("t_toast_error_job_description_file_formate", {
          fileExt,
          allowedFormats: UPLOAD_CONFIG.fileFormat.join()
        }),
        "error"
      );
      return;
    }

    if (fileSizeInMb > UPLOAD_CONFIG.fileSize) {
      toast.kampai(
        intl.get("t_toast_error_job_description_file_size", {
          fileSize: fileSizeInMb.toFixed(2),
          maxSize: UPLOAD_CONFIG.fileSize
        }),
        "error"
      );
      return;
    }

    if (handleFileUpload) {
      handleFileUpload(file);
    }
  };

  const handleClose = (reason: keyof typeof DIALOG_ACTION) => {
    if (reason === DIALOG_ACTION.AGREE && handleDelete) {
      handleDelete();
    }
  };

  return (
    <Box data-testid={dataTestId}>
      <Well>
        {!fileDetails ? (
          <>
            <Typography variant="body1">
              {intl.get(
                "t_job_post_job_additional_information_step_attach_job_description_label"
              )}
            </Typography>
            <Typography
              variant="subtitle5"
              color="text.secondary"
              mb={3}
              mt={1}>
              {intl.get(
                "t_job_post_job_additional_information_step_job_description_support_file"
              )}
            </Typography>
            {/* FIXME: Ticket #292 - Implement progress indicator. */}
            <Box
              sx={{
                cursor: "not-allowed"
              }}>
              <Button
                data-testid="attached_job_description_button"
                variant="contained"
                startAdornment={<Icon type="backup" filled />}
                component="label">
                {intl.get(
                  "t_job_post_job_additional_information_step_job_description_upload_button"
                )}
                <input
                  type="file"
                  hidden
                  accept="application/pdf"
                  onChange={handleChange}
                />
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%">
              <Typography variant="body1" ml={-0.25}>
                <Stack
                  component="span"
                  direction="row"
                  alignItems="center"
                  gap={1}>
                  {disabledActions ? (
                    <CircularProgress color="secondary" size={25} />
                  ) : (
                    <CheckCircleRoundedIcon color="success" />
                  )}
                  {fileDetails?.name}
                </Stack>
              </Typography>
              {disabledActions ? (
                <ActionButton icon="delete" color="grey" disabled />
              ) : (
                <Dialog
                  title={intl.get(
                    "t_job_post_job_additional_information_step_dialog_title"
                  )}
                  maxWidth="sm"
                  initiator={<ActionButton icon="delete" color="grey" />}
                  onClose={handleClose}>
                  {(handleAgree, handleCancel) => (
                    <>
                      <DialogContent sx={{ py: 1 }}>
                        <Typography color="text.secondary">
                          {intl.get(
                            "t_job_post_job_additional_information_step_dialog_content"
                          )}
                        </Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          data-testid="job_additional_dialog_box_delete_cancel_button"
                          handleClick={handleCancel}
                          variant="outlined">
                          {intl.get(
                            "t_job_post_job_additional_information_step_dialog_delete_cancel_button"
                          )}
                        </Button>
                        <Button
                          data-testid="job_additional_dialog_box_delete_agree_button"
                          handleClick={handleAgree}>
                          {intl.get(
                            "t_job_post_job_additional_information_step_dialog_delete_agree_button"
                          )}
                        </Button>
                      </DialogActions>
                    </>
                  )}
                </Dialog>
              )}
            </Stack>

            <Stack
              component="span"
              direction="row"
              alignItems="center"
              mb={downloadFileUrl ? 1 : 0}>
              <Typography
                variant="subtitle5"
                color={theme.palette.text.primary}>
                {intl.get(
                  "t_job_post_job_additional_information_step_job_description_updated_at"
                )}
                :&nbsp;
              </Typography>
              <Typography variant="body2" color={theme.palette.text.primary}>
                {dayjs(fileDetails?.uploaded_at.toDate())
                  .locale(translate.getCurrentLocaleShort())
                  .fromNow()}
              </Typography>
            </Stack>

            {downloadFileUrl && !disabledActions ? (
              <Link underline="none" href={downloadFileUrl}>
                <Button
                  data-testid="job_additional_download_button"
                  variant="text"
                  startAdornment={<Icon type="download" />}>
                  {intl.get(
                    "t_job_post_job_additional_information_step_job_description_download"
                  )}
                </Button>
              </Link>
            ) : (
              false
            )}
          </>
        )}
      </Well>
    </Box>
  );
};

export default UploadJobDetailCard;
