import { Stack, styled } from "@mui/material";

import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import { colorPalette } from "@utils/theme";

const StyledStack = styled(Stack)(({ theme }) => ({
  alignItems: "flex-start",
  background: theme.palette.common.white,
  borderRadius: 4,
  border: `1px solid ${colorPalette.grey.base}`,
  padding: 24,
  maxWidth: "100%"
}));

const SkeletonPlanCard = () => {
  return (
    <StyledStack gap={1}>
      <SkeletonTypography variant="subtitle2" />
      <SkeletonTypography variant="body1" />

      <SkeletonTypography variant="h3" />
      <SkeletonTypography variant="body1" multiLine />

      <SkeletonButton width="full" />
    </StyledStack>
  );
};

export default SkeletonPlanCard;
