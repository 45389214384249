import { Outlet } from "react-router-dom";

import { LOCALE } from "@utils/config";
import translate from "@utils/translate";

interface TranslateProps {
  locale: typeof LOCALE[keyof typeof LOCALE];
}

const Translate = ({ locale = LOCALE.JA }: TranslateProps) => {
  translate.setCurrentLocale(locale);

  return <Outlet />;
};

export default Translate;
