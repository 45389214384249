import { ChangeEvent, useEffect, useState } from "react";

import { CheckCircleRounded as CheckCircleRoundedIcon } from "@mui/icons-material";
import { Box, DialogActions, DialogContent, Link, Stack } from "@mui/material";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";

import ActionButton from "@components/ActionButton";
import Button from "@components/Button";
import Dialog from "@components/Dialog";
import Icon from "@components/Icon";
import Typography from "@components/Typography";
import Well from "@components/Well";

import useToast from "@hooks/useToast";

import UploadedFileInformation from "@interfaces/database/UploadedFileInformation";

import {
  DIALOG_ACTION,
  CV_FILE_UPLOAD_CONFIG as UPLOAD_CONFIG
} from "@utils/config";
import getFileMetaInformation from "@utils/getFileMetaInformation";
import theme from "@utils/theme";
import translate, { intl } from "@utils/translate";

dayjs.extend(relative);

interface UploadResumeCardProps {
  resumeDetails?: UploadedFileInformation;
  downloadResumeUrl?: string;
  handleFileUpload?: (resumeFile: File) => void;
  handleDelete?: () => void;
  isLoading?: boolean;
  downloadBtnText?: string;
}

const UploadResumeCard = ({
  resumeDetails,
  downloadResumeUrl,
  handleFileUpload,
  handleDelete,
  isLoading = false,
  downloadBtnText = intl.get("t_resumes_download")
}: UploadResumeCardProps) => {
  const toast = useToast();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    setIsUploading(isLoading);
  }, [isLoading]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;

    if (!input.files?.length) {
      return;
    }

    const file = input.files[0];
    const { fileSizeInMb, fileExt } = getFileMetaInformation(file);

    if (!UPLOAD_CONFIG.fileFormat.includes(fileExt)) {
      toast.kampai(
        intl.get("t_resumes_upload_file_alert_file_format", {
          fileExt,
          allowedFormats: UPLOAD_CONFIG.fileFormat.join()
        }),
        "error"
      );
      return;
    }

    if (fileSizeInMb >= UPLOAD_CONFIG.fileSize) {
      toast.kampai(
        intl.get("t_resumes_upload_file_alert_file_size", {
          fileSize: fileSizeInMb.toFixed(2),
          maxSize: UPLOAD_CONFIG.fileSize
        }),
        "error"
      );
      return;
    }

    handleFileUpload && handleFileUpload(file);
  };

  const handleClose = (reason: keyof typeof DIALOG_ACTION) => {
    if (reason === DIALOG_ACTION.AGREE && handleDelete) {
      handleDelete();
    }
  };

  return (
    <Well>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        mb={2.5}>
        <Typography variant="h4" width="100%">
          {intl.get("t_resumes_upload_title")}
        </Typography>
        {resumeDetails ? (
          <Dialog
            title={intl.get("t_resumes_remove_resume")}
            maxWidth="sm"
            initiator={<ActionButton icon="delete" color="grey" />}
            onClose={handleClose}>
            {(handleAgree, handleCancel) => (
              <>
                <DialogContent sx={{ py: 1 }}>
                  <Typography color="text.secondary">
                    {intl.get("t_resumes_dialog_delete_title", {
                      entry: intl.get("t_general_resume")
                    })}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button handleClick={handleCancel} variant="outlined">
                    {intl.get("t_resumes_dialog_delete_cancel_button")}
                  </Button>
                  <Button handleClick={handleAgree}>
                    {intl.get("t_resumes_dialog_delete_agree_button")}
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        ) : (
          false
        )}
      </Stack>

      {!resumeDetails ? (
        <>
          <Typography variant="body1" mb={1}>
            {intl.get("t_resumes_upload_instruction")}
          </Typography>
          <Typography
            variant="subtitle5"
            color={theme.palette.text.secondary}
            mb={3}>
            {intl.get("t_resumes_upload_rule")}
          </Typography>
          <Box
            sx={{
              cursor: "not-allowed"
            }}>
            <Button
              loading={isUploading}
              variant="contained"
              startAdornment={<Icon type="backup" filled />}
              component="label"
              data-testid="resumes_upload_button">
              {intl.get("t_resumes_upload_button")}
              <input
                type="file"
                hidden
                accept="application/pdf"
                onChange={handleChange}
              />
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="body1" ml={-0.25} mb={1}>
            <Stack component="span" direction="row" alignItems="center" gap={1}>
              <CheckCircleRoundedIcon color="success" />
              {resumeDetails?.name}.{resumeDetails?.extension}
            </Stack>
          </Typography>
          <Stack component="span" direction="row" alignItems="center" mb={1}>
            <Typography variant="subtitle5" color={theme.palette.text.primary}>
              {intl.get("t_resumes_upload_updatedAt")}
              :&nbsp;
            </Typography>
            <Typography variant="body2" color={theme.palette.text.primary}>
              {dayjs(resumeDetails?.uploaded_at?.toDate())
                .locale(translate.getCurrentLocaleShort())
                .fromNow()}
            </Typography>
          </Stack>

          {downloadResumeUrl ? (
            <Link underline="none" href={downloadResumeUrl}>
              <Button variant="text" startAdornment={<Icon type="download" />}>
                {downloadBtnText}
              </Button>
            </Link>
          ) : (
            false
          )}
        </>
      )}
    </Well>
  );
};

export default UploadResumeCard;
