/**
 * Generate translated options using keys and labels
 *
 * @param {object} keys
 * @param {object} labels
 * @return {array} list of translated options in key, label format
 */

import KeyLabel from "@interfaces/components/KeyLabel";

import { intl } from "@utils/translate";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useOptions = (keys: any, labels: any): Array<KeyLabel> => {
  return Object.keys(keys).map((singleKey) => {
    let key = keys[singleKey];
    if (typeof keys[singleKey] !== "string") {
      key = keys[singleKey].toString();
    }
    return {
      key: key ?? "",
      label: intl.get(labels[keys[singleKey]]) ?? ""
    };
  });
};
