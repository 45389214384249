import { Stack, Tooltip } from "@mui/material";

import Button from "@components/Button";
import Typography from "@components/Typography";

import useToast from "@hooks/useToast";

import { intl } from "@utils/translate";

const Other = () => {
  const toast = useToast();
  return (
    <>
      <Typography variant="h5">Other:</Typography>
      <br />
      <Typography variant="body1">Toasts:</Typography>
      <br />
      <Stack gap={1} direction="row">
        <Button
          handleClick={() => toast.kampai("Success Message", "success")}
          variant="outlined">
          ✔️ Success Toast
        </Button>
        <Button
          handleClick={() => toast.kampai("Error Message", "error")}
          variant="outlined">
          ❌ Error Toast
        </Button>
        <Button
          handleClick={() => toast.kampai("Info Message", "info")}
          variant="outlined">
          ℹ️ Info Toast
        </Button>
        <Button
          handleClick={() => toast.kampai("Warning Message", "warning")}
          variant="outlined">
          ⚠️ Warning Toast
        </Button>
        <Button
          handleClick={() => toast.kampai("Simple Message", "none")}
          variant="outlined">
          Simple Toast
        </Button>
      </Stack>
      <br />
      <Typography variant="body1">Disabled Button Tooltip:</Typography>
      <br />
      <Tooltip
        arrow
        placement="bottom"
        enterTouchDelay={0}
        title={<Typography variant="body2">Some tooltip caption</Typography>}>
        <span>
          <Button disabled>{intl.get("t_general_buy_resume")}</Button>
        </span>
      </Tooltip>
    </>
  );
};

export default Other;
