import Button from "@components/Button";

import { SOCIAL_BUTTON_VARIANT } from "@utils/config";

import GoogleLogo from "@assets/images/Google.svg";
import LinkedInLogo from "@assets/images/LinkedIn.svg";

interface SocialButtonProps {
  variant: typeof SOCIAL_BUTTON_VARIANT[keyof typeof SOCIAL_BUTTON_VARIANT];
  label?: string;
  onClick: () => void;
}

const SocialButton = ({ variant, label, onClick }: SocialButtonProps) => {
  let logo = "";
  switch (variant) {
    case SOCIAL_BUTTON_VARIANT.GOOGLE:
      logo = GoogleLogo;
      label = label ? label : "Google";
      break;
    case SOCIAL_BUTTON_VARIANT.LINKEDIN:
      logo = LinkedInLogo;
      label = label ? label : "LinkedIn";
      break;
  }

  return (
    <Button
      variant="outlined"
      fullWidth
      startAdornment={<img src={logo} alt={label} />}
      handleClick={onClick}>
      {label}
    </Button>
  );
};

export default SocialButton;
