import { useEffect, useState } from "react";

import { Box, Stack } from "@mui/material";

import SkeletonKeyword from "@skeletons/SkeletonKeyword";

import ActionButton from "@components/ActionButton";
import Avatar from "@components/Avatar";
import CVFilledStatus from "@components/CVFilledStatus";
import Icon from "@components/Icon";
import InformationField from "@components/InformationField";
import Tag from "@components/Tag";
import Typography from "@components/Typography";
import Well from "@components/Well";

import Location from "@interfaces/database/Location";
import Skill from "@interfaces/database/Skill";

import {
  INDIVIDUAL_USER_NUMBER_OF_COMPANIES,
  INDIVIDUAL_USER_VISA_TYPE,
  INDIVIDUAL_USER_VISA_TYPE_T_LABELS,
  JA_LANGUAGE_TEST_T_LABELS,
  LANGUAGE_PROFICIENCY,
  LANGUAGE_PROFICIENCY_T_LABELS,
  YEARS_OF_EXPERIENCE,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import { jaTestResultFinder } from "@utils/jaTestResultFinder";
import { getSkillLabel } from "@utils/keyLabelHandlers/skill";
import { getLanguageName } from "@utils/language";
import { getCountryName, getFullLocation } from "@utils/location";
import { roundSalary } from "@utils/roundSalary";
import theme from "@utils/theme";
import translate, { intl } from "@utils/translate";

import VisaIcon from "@assets/images/Visa.svg";

interface ProfileSummaryCardProps {
  jobTitleId?: number;
  profilePicUrl?: string;
  location?: Location;
  phoneNumber?: string;
  countryOfOrigin?: string;
  visaType?: typeof INDIVIDUAL_USER_VISA_TYPE[keyof typeof INDIVIDUAL_USER_VISA_TYPE];
  noOfCompanies?: typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES[keyof typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES];
  expectedSalary?: number;
  experiences?: Array<{
    jobTitle: string;
    yearsOfExperience: typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE];
  }>;
  noJobExperience?: boolean;
  languages?: Array<{
    name: string;
    proficiency: typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY];
    testName?: string;
    testResult?: string;
  }>;
  // FIXME: Use Skill interface
  skills?: Array<{
    name: string;
    yearsOfExperience: typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE];
  }>;
  attachedResumes?: {
    englishCV: boolean;
    japaneseCV: boolean;
    japaneseResume: boolean;
  };
  handleEdit?: () => void;
  mode?: "edit" | "view";
  initials: string;
}

const ProfileSummaryCard = ({
  jobTitleId = 0,
  profilePicUrl,
  location,
  visaType,
  phoneNumber,
  countryOfOrigin = "",
  noOfCompanies,
  expectedSalary = -1,
  experiences = [],
  noJobExperience = false,
  languages = [],
  skills: candidateSkills = [],
  attachedResumes,
  handleEdit,
  mode = "edit",
  initials
}: ProfileSummaryCardProps) => {
  const [skills, setSkills] = useState<Array<Skill>>([]);
  const [isSkillsLoading, setIsSkillsLoading] = useState<boolean>(true);

  const userLocation = getFullLocation(location?.city, location?.country);

  useEffect(() => {
    (async () => {
      setIsSkillsLoading(true);
      const skills = [];

      for (const singleSkill of candidateSkills) {
        const skillLabel = await getSkillLabel(
          singleSkill.name,
          translate.getCurrentLocale()
        );
        skills.push({
          name: skillLabel,
          years_of_experience: singleSkill.yearsOfExperience
        });
      }

      setSkills(skills);
      setIsSkillsLoading(false);
    })();
  }, [candidateSkills]);

  return (
    <Well showBorder={mode === "edit" ? true : false}>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        width="100%"
        mb={1}>
        {/* User Details */}
        <Stack direction="row" alignItems="center" gap={1}>
          <Avatar imgSrc={profilePicUrl} initials={initials} />
          <Box>
            <Typography variant="subtitle2">
              {noJobExperience
                ? intl.get("t_general_fresher")
                : experiences?.[jobTitleId]?.jobTitle ?? "-"}
            </Typography>
            <br />
            <Typography
              variant="body1"
              color={theme.palette.text.secondary}
              textTransform="capitalize">
              {userLocation ?? "-"}
            </Typography>
            <br />
          </Box>
        </Stack>

        {/* Action - Edit */}
        {mode === "edit" ? (
          <ActionButton icon="edit" color="blue" handleAction={handleEdit} />
        ) : (
          false
        )}
      </Stack>

      <Stack spacing={1}>
        {/* Basic Information */}
        <Stack direction={{ xs: "column", md: "row" }} gap={1} flexWrap="wrap">
          <InformationField icon="phone_enabled" label={phoneNumber} />
          <InformationField
            icon="home"
            label={getCountryName(countryOfOrigin)}
          />
          <InformationField
            imgSrc={VisaIcon}
            imgAlt={intl.get("t_alt_visa_types")}
            label={
              visaType
                ? intl.get(INDIVIDUAL_USER_VISA_TYPE_T_LABELS[visaType])
                : "-"
            }
          />
          {/* Not displayed when user have no job experience (fresher)*/}
          {!noJobExperience ? (
            <InformationField
              icon="apartment"
              label={`${
                noOfCompanies
                  ? intl.get("t_resumes_basic_info_companies", {
                      noOfCompanies
                    })
                  : "-"
              } `}
            />
          ) : (
            false
          )}

          <InformationField
            icon="currency_yen"
            label={`${
              expectedSalary >= 0
                ? intl.get("t_resumes_basic_info_rounded_salary", {
                    expectedSalary: roundSalary(expectedSalary)
                  })
                : "-"
            } `}
          />
        </Stack>

        {/* Experience */}
        <Typography variant="subtitle2">
          {intl.get("t_profile_summary_experience")}
        </Typography>
        {experiences?.length > 0 ? (
          <Stack direction="column" gap={1} flexWrap="wrap">
            {experiences?.map((singleExperience, index) => (
              <Stack direction="row" alignItems="center" key={index}>
                <Icon type="work" filled />
                <Box ml={1.25} component="span">
                  <Typography variant="subtitle4">
                    {singleExperience.jobTitle},&nbsp;
                  </Typography>
                  <Typography variant="body1" whiteSpace="nowrap">
                    {intl.get(
                      YEARS_OF_EXPERIENCE_T_LABELS[
                        singleExperience.yearsOfExperience
                      ]
                    )}
                  </Typography>
                </Box>
              </Stack>
            ))}
          </Stack>
        ) : (
          <Typography variant="body1">
            {noJobExperience ? intl.get("t_general_fresher") : "-"}
          </Typography>
        )}

        {/* Language */}
        <Typography variant="subtitle2">
          {intl.get("t_profile_summary_language")}
        </Typography>
        {languages?.length > 0 ? (
          <Stack direction="column" flexWrap="wrap" gap={1}>
            {languages?.map((singleLanguage, index) => (
              <Box component="span" key={index}>
                <Typography variant="subtitle4">
                  {intl.get("t_language_proficiency_level", {
                    language: getLanguageName(singleLanguage?.name)
                  })}
                  &nbsp;
                </Typography>
                <Typography variant="body1">
                  {intl.get(
                    LANGUAGE_PROFICIENCY_T_LABELS[singleLanguage.proficiency]
                  )}
                  {index === 0 && singleLanguage?.testName ? (
                    // first index (index = 0) is always japanese language
                    <>
                      {singleLanguage?.testName
                        ? `, ${intl.get(
                            JA_LANGUAGE_TEST_T_LABELS[
                              singleLanguage.testName as keyof typeof JA_LANGUAGE_TEST_T_LABELS
                            ]
                          )}: ${jaTestResultFinder(
                            singleLanguage.testName,
                            singleLanguage.testResult
                          )}`
                        : false}
                    </>
                  ) : (
                    <>
                      {singleLanguage?.testName
                        ? `, ${singleLanguage.testName}: ${singleLanguage.testResult}`
                        : false}
                    </>
                  )}
                </Typography>
              </Box>
            ))}
          </Stack>
        ) : (
          <Typography variant="body1">-</Typography>
        )}

        {/* Skills */}
        <Typography variant="subtitle2">
          {intl.get("t_profile_summary_skill")}
        </Typography>
        {isSkillsLoading ? (
          <SkeletonKeyword multiple />
        ) : (
          <>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {skills?.length > 0 ? (
                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {skills?.map((singleSkill, index) => (
                    <Tag
                      label={`${singleSkill.name} ${intl.get(
                        YEARS_OF_EXPERIENCE_T_LABELS[
                          singleSkill.years_of_experience
                        ]
                      )}`}
                      key={index}
                    />
                  ))}
                </Stack>
              ) : (
                <Typography variant="body1">-</Typography>
              )}
            </Stack>
          </>
        )}

        {/* FIXME: Update "Attached Resumes" title */}
        {/* Attached Resumes */}
        <Typography variant="subtitle2">
          {intl.get("t_profile_summary_attached_resumes")}
        </Typography>
        <Stack direction={{ xs: "column", md: "row" }} gap={1} flexWrap="wrap">
          <CVFilledStatus
            label={intl.get("t_general_english_cv")}
            isAttached={attachedResumes?.englishCV || false}
          />
          <CVFilledStatus
            label={intl.get("t_general_japanese_cv")}
            isAttached={attachedResumes?.japaneseCV || false}
          />
          <CVFilledStatus
            label={intl.get("t_general_japanese_rirekisho")}
            isAttached={attachedResumes?.japaneseResume || false}
          />
        </Stack>
      </Stack>
    </Well>
  );
};

export default ProfileSummaryCard;
