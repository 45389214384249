import { Box, Stack, styled } from "@mui/material";

import PieChart from "@components/PieChart";
import PieChartLegend from "@components/PieChartLegend";
import Typography from "@components/Typography";

import theme from "@utils/theme";

interface PieChartCardProps {
  containerId?: string;
  title: string;
  field: string;
  fieldValue: string;
  data: Array<{
    value: number;
    category: string;
    color: string;
  }>;
}

const StyledStack = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100%",
  maxHeight: "max-content",
  alignItems: "center",
  borderRadius: "4px",
  backgroundColor: theme.palette.common.white,
  boxShadow:
    "0px 3px 14px 2px rgba(206, 211, 224, 0.12), 0px 8px 10px 1px rgba(206, 211, 224, 0.14), 0px 5px 5px -3px rgba(206, 211, 224, 0.15)"
}));

const PieChartCard = ({
  containerId,
  data,
  title,
  field,
  fieldValue
}: PieChartCardProps) => {
  const legendData = data?.map((singleData) => ({
    category: singleData.category,
    color: singleData.color
  }));

  return (
    <StyledStack>
      <Stack alignItems="center" pt={4} mb={4}>
        <Typography variant="h4" color={theme.palette.text.secondary} mb={3}>
          {title}
        </Typography>
        <Box component="span">
          <Typography variant="body1">{field}:&nbsp;</Typography>
          <Typography variant="subtitle4">{fieldValue}</Typography>
        </Box>
        <Box width={200} height={200}>
          <PieChart data={data} containerId={containerId} />
        </Box>
      </Stack>

      <Box width={{ xs: "90%", sm: "80%", lg: "90%" }} mb={4}>
        <PieChartLegend data={legendData} />
      </Box>
    </StyledStack>
  );
};

export default PieChartCard;
