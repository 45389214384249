import { useEffect } from "react";

import { Outlet, useLocation } from "react-router-dom";

import useForceUpdate from "use-force-update";

import TabNavigation from "@components/TabNavigation";
import Typography from "@components/Typography";

import translate, { intl } from "@utils/translate";

const Jobs = () => {
  // FIXME: Update Badge Logic
  const navLinks = [
    {
      label: intl.get("t_jobs_page_saved"),
      redirectLink: "/profile/jobs/bookmarked",
      showBadge: false
    },
    {
      label: intl.get("t_jobs_page_applied"),
      redirectLink: "/profile/jobs/applied",
      showBadge: false
    },
    {
      label: intl.get("t_jobs_page_invited"),
      redirectLink: "/profile/jobs/invited",
      showBadge: false
    }
  ];

  const { pathname: originalPathname } = useLocation();
  let pageTitle = "";
  const pathname = translate.getNonLocalizedURL(originalPathname);
  if (pathname === "/profile/jobs/bookmarked") {
    pageTitle = intl.get("t_saved_jobs_page_title");
  } else if (pathname === "/profile/jobs/applied") {
    pageTitle = intl.get("t_applied_jobs_page_title");
  } else if (pathname === "/profile/jobs/invited") {
    pageTitle = intl.get("t_invited_jobs_page_title");
  }

  const forceUpdate = useForceUpdate();
  const languageChanged = () => {
    forceUpdate();
  };

  useEffect(() => {
    translate.addLanguageChangedListener(languageChanged);
    return () => {
      translate.removeLanguageChangedListener(languageChanged);
    };
  }, []);

  return (
    <>
      <Typography variant="h3" mt={8} mb={5.5}>
        {pageTitle}
      </Typography>
      <TabNavigation navLinks={navLinks} color="dark" />
      <Outlet />
    </>
  );
};

export default Jobs;
