import { Box, Stack, styled } from "@mui/material";

import Typography from "@components/Typography";

import { intl } from "@utils/translate";

import Current_Logo from "@assets/images/Current.png";
import lti_Logo from "@assets/images/Ltl.png";
import Magichat_Logo from "@assets/images/Magichat.png";
import QibiTech_Logo from "@assets/images/QibiTech.png";
import Recruit_Logo from "@assets/images/Recruit.png";

// NOTE: Translation is not required in imageLabel because we need to show english in both(en,ja) ui
const LOGO_IMAGES = [
  {
    imgLabel: "Magichat",
    imgSrc: Magichat_Logo
  },
  {
    imgLabel: "Let's solve",
    imgSrc: lti_Logo
  },
  {
    imgLabel: "QibiTech",
    imgSrc: QibiTech_Logo
  },
  {
    imgLabel: "Recruit",
    imgSrc: Recruit_Logo
  },
  {
    imgLabel: "Current",
    imgSrc: Current_Logo
  }
];

const StyledBox = styled(Box)({
  "display": "flex",
  "filter": "brightness(0) contrast(0.8) invert(1)",
  "&:hover": { filter: "none" }
});

const StyledImage = styled("img")({
  maxHeight: 40,
  maxWidth: "100%",
  marginBottom: 50,
  objectFit: "contain"
});

const TrustedBy = () => {
  return (
    <Stack alignItems="center">
      <Typography
        variant="h4"
        color="primary.contrastText"
        align="center"
        mb={6.5}>
        {intl.get("t_homepage_trusted_by")}
      </Typography>
      <Stack
        direction="row"
        spacing={{ xs: 0, sm: 2, md: 4, lg: 5, xl: 6 }}
        alignItems="center"
        justifyContent={{ xs: "space-between", sm: "center" }}
        mx={{ xs: 1.25, sm: 3.75 }}
        flexWrap="wrap">
        {LOGO_IMAGES.map((singleLogoImage) => (
          <StyledBox key={singleLogoImage.imgLabel}>
            <StyledImage
              src={singleLogoImage.imgSrc}
              alt={singleLogoImage.imgLabel}
            />
          </StyledBox>
        ))}
      </Stack>
    </Stack>
  );
};

export default TrustedBy;
