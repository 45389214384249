import { useEffect, useState } from "react";

import {
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";

import { doc, getDoc } from "firebase/firestore";

import useCompanyDetails from "@hooks/database/useCompanyDetails";

import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";

import { FIRESTORE_COLLECTIONS, JOB_POSTING_STATUS } from "@utils/config";
import { db } from "@utils/firebase";
import translate from "@utils/translate";

const EmployersPostJob = () => {
  const { job_id: jobId } = useParams();
  const [searchParams] = useSearchParams();
  const copyFromJobId = searchParams.get("copy_from_job_id") ?? "";
  const navigate = useNavigate();
  const { pathname: originalPathname } = useLocation();
  const companyDetails = useCompanyDetails();

  const [jobData, setJobData] = useState<JobProfile>();

  const pathname = translate.getNonLocalizedURL(originalPathname);

  const isMatch = matchPath("/employers/jobs/:job_id", pathname);
  const isConfirmationStep = matchPath(
    "/employers/jobs/:job_id/confirmation",
    pathname
  ); // this is last step for entering job data

  useEffect(() => {
    if (isMatch !== null) {
      navigate(
        `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/company-information`
      );
    }
    if (jobId === "new" && !pathname.includes("/company-information")) {
      navigate(
        `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/company-information`
      );
    }

    if (jobId && jobId !== "new") {
      // fetch job data for edit operation
      const getJobData = async () => {
        const jobDocRef = doc(db, FIRESTORE_COLLECTIONS.JOBS, jobId);
        const jobDoc = await getDoc(jobDocRef);
        if (jobDoc.exists()) {
          setJobData(jobDoc.data() as JobProfile);
        } else {
          navigate(
            `/${translate.getCurrentLocale()}/employers/jobs/new/company-information`
          );
        }
      };
      getJobData();
    }
  }, [isMatch, jobId, copyFromJobId]);

  const handleSetJobData = async (
    jobId: JobID,
    jobData: JobProfile,
    handleJobUpdateSuccess: (jobId: string) => void,
    handleJobUpdateFail: () => void
  ) => {
    try {
      const {
        skills,
        company_id,
        job_title,
        job_description,
        must_requirements,
        job_overview,
        language_requirement
      } = jobData || {};
      // handle job status
      if (
        isConfirmationStep &&
        skills?.length &&
        company_id &&
        job_title &&
        job_description &&
        must_requirements &&
        job_overview &&
        language_requirement?.length
      ) {
        // If this is the last step in the job entry process then set it to submission for auto-review. Otherwise, draft it because the employer may change the data at any time if it is an active job.
        jobData.status = JOB_POSTING_STATUS.SUBMITTED_FOR_AUTO_REVIEW;
      } else {
        jobData.status = JOB_POSTING_STATUS.DRAFT;
      }

      if (companyDetails?.setJobValue) {
        if (jobId === "new") {
          let newJobId = "";
          // create new job and push job reference
          companyDetails?.setJobValue(
            jobId,
            jobData,
            async (updatedJobId) => {
              companyDetails?.value?.jobs?.push(updatedJobId);
              newJobId = updatedJobId;
              handleJobUpdateSuccess(newJobId);
              setJobData(jobData);
            },
            handleJobUpdateFail
          );
        } else {
          companyDetails?.setJobValue(
            jobId,
            jobData,
            () => {
              setJobData((prevJobData) => {
                return { ...prevJobData, ...jobData };
              });
              handleJobUpdateSuccess(jobId);
            },
            handleJobUpdateFail
          );
        }
      }
    } catch (e) {
      handleJobUpdateFail();
    }
  };

  return <Outlet context={{ jobData, handleSetJobData }} />;
};

export default EmployersPostJob;
