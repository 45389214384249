import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  Timestamp,
  updateDoc,
  where
} from "firebase/firestore";

import ApplicationID from "@interfaces/database/ApplicationID";
import CompanyProfile from "@interfaces/database/CompanyProfile";
import CreditInformation from "@interfaces/database/CreditInformation";
import JobApplicationInvitation from "@interfaces/database/JobApplicationInvitation";
import JobApplicationInvitationAction from "@interfaces/database/JobApplicationInvitationAction";
import JobID from "@interfaces/database/JobID";
import PurchasedResume from "@interfaces/database/PurchasedResume";
import Transaction from "@interfaces/database/Transaction";
import UserID from "@interfaces/database/UserID";

import { UserProfileContextStructure } from "@utils/components/UserProfileContext";
import {
  CREDIT_CHANGE_TYPE,
  CREDIT_TRANSACTION_TYPE,
  ENVIRONMENT,
  FIRESTORE_COLLECTIONS,
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  LOCALE,
  RESUME_PURCHASE_STATUS,
  RESUME_PURCHASE_STATUS_CODE,
  USER_STATUS,
  USER_TYPE
} from "@utils/config";
import shouldExecuteInEnvironment from "@utils/envSpecific";
import { db } from "@utils/firebase";
import { prepareMultiLingual } from "@utils/multiLingual";

const isCandidateProfilePurchased = async (
  candidateId: UserID,
  userProfile: UserProfileContextStructure,
  userId: UserID
): Promise<boolean> => {
  if (userProfile.value?.company_id && userId) {
    const purchasedResumeRef = collection(
      db,
      `${FIRESTORE_COLLECTIONS.COMPANIES}/${userProfile.value.company_id}/${FIRESTORE_COLLECTIONS.PURCHASED_RESUMES}`
    );
    const firestoreQuery = query(
      purchasedResumeRef,
      where("candidate_id", "==", candidateId),
      where("status", "==", RESUME_PURCHASE_STATUS.OK)
    );
    try {
      const snapshot = await getDocs(firestoreQuery);
      if (snapshot.docs.length > 0) {
        return true;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
};

const isResumePurchased = async (
  applicationId: ApplicationID,
  userProfile: UserProfileContextStructure,
  userId: UserID
): Promise<boolean> => {
  if (userProfile.value?.company_id && userId) {
    const purchasedResumeRef = collection(
      db,
      `${FIRESTORE_COLLECTIONS.COMPANIES}/${userProfile.value.company_id}/${FIRESTORE_COLLECTIONS.PURCHASED_RESUMES}`
    );
    const firestoreQuery = query(
      purchasedResumeRef,
      where("application_id", "==", applicationId),
      where("status", "==", RESUME_PURCHASE_STATUS.OK)
    );
    try {
      const snapshot = await getDocs(firestoreQuery);
      if (snapshot.docs.length > 0) {
        return true;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
};

const canPurchaseResume = async (
  jobId: JobID,
  applicationId: ApplicationID,
  userProfile: UserProfileContextStructure,
  userId: UserID,
  credits: number
): Promise<
  typeof RESUME_PURCHASE_STATUS_CODE[keyof typeof RESUME_PURCHASE_STATUS_CODE]
> => {
  if (
    userProfile.value &&
    userProfile.value.status === USER_STATUS.OK &&
    (userProfile.value.user_type === USER_TYPE.COMPANY ||
      userProfile.value.user_type === USER_TYPE.COMPANY_MANAGER) &&
    userId
  ) {
    if (await isResumePurchased(applicationId, userProfile, userId)) {
      // Already Purchased, cannot re-purchase again.
      return RESUME_PURCHASE_STATUS_CODE.ALREADY_PURCHASED;
    }

    const docRef = doc(
      db,
      `${FIRESTORE_COLLECTIONS.JOBS}/${jobId}/${FIRESTORE_COLLECTIONS.APPLICATIONS}`,
      applicationId
    );
    const docSnap = await getDoc(docRef);

    const applicationData = docSnap.data() as JobApplicationInvitation;
    if (applicationData) {
      //Checking if candidate actually applied for that job or not.
      const isApplied = applicationData.actions.some(
        (singleAction: JobApplicationInvitationAction) => {
          if (
            singleAction.action_type ===
            JOB_APPLICATION_INVITATION_ACTION_TYPE.APPLIED
          ) {
            return true;
          }
          return false;
        }
      );
      if (!isApplied) {
        return RESUME_PURCHASE_STATUS_CODE.CANDIDATE_NOT_APPLIED;
      }

      //Check if it is revoked or not. (The application should not be revoked for a purchase to happen).
      const isRevoked = applicationData.actions.some(
        (singleAction: JobApplicationInvitationAction) => {
          if (
            singleAction.action_type ===
            JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED
          ) {
            return true;
          }
          return false;
        }
      );
      if (isRevoked) {
        return RESUME_PURCHASE_STATUS_CODE.CANDIDATE_REVOKED;
      }

      if (
        shouldExecuteInEnvironment({
          env: ENVIRONMENT.STAGE,
          userType: userProfile.value.user_type
        })
      ) {
        if (credits <= 0) {
          return RESUME_PURCHASE_STATUS_CODE.NO_CREDITS;
        }

        if (isApplied && !isRevoked && credits > 0) {
          return RESUME_PURCHASE_STATUS_CODE.CAN_PURCHASE;
        }
      } else {
        if (isApplied && !isRevoked) {
          return RESUME_PURCHASE_STATUS_CODE.CAN_PURCHASE;
        }
      }
    }
  }
  return RESUME_PURCHASE_STATUS_CODE.UNAUTHENTICATED;
};

const purchaseResume = async (
  jobId: JobID,
  applicationId: ApplicationID,
  userProfile: UserProfileContextStructure,
  userId: UserID,
  credits: number
): Promise<boolean> => {
  /**
   * Note:
   * We use Application as an Entry point for purchasing a resume.
   * Because resume cannot be purchased without the Candidate Applying for a job.
   * Therefore, we also need the Job ID (This is for efficiency).
   * We can get the document ID from Application directly.
   * But it can introduce edge cases (Two separate jobs have same application ID)
   * Therefore, application ID and Job ID is paired most of the time in resume purchase.
   */

  const companyId = userProfile.value?.company_id;
  const userType = userProfile.value?.user_type;

  if (
    applicationId &&
    jobId &&
    userId &&
    userType &&
    (await canPurchaseResume(
      jobId,
      applicationId,
      userProfile,
      userId,
      credits
    )) === RESUME_PURCHASE_STATUS_CODE.CAN_PURCHASE &&
    companyId
  ) {
    const candidateId = await getCandidateId(jobId, applicationId);
    if (candidateId) {
      const newPurchase: PurchasedResume = {
        job_id: jobId,
        application_id: applicationId,
        candidate_id: candidateId,
        status: RESUME_PURCHASE_STATUS.OK,
        purchased_at: Timestamp.now()
      };

      try {
        const collectionReference = collection(
          db,
          `${FIRESTORE_COLLECTIONS.COMPANIES}/${companyId}/${FIRESTORE_COLLECTIONS.PURCHASED_RESUMES}`
        );
        const applicationDocument = await addDoc(
          collectionReference,
          newPurchase
        );

        //FIXME: TBD by Aayush, Update the Application on Resume Purchase. (This needs to be done in Functions)

        const applicationAction: JobApplicationInvitationAction = {
          action_type: JOB_APPLICATION_INVITATION_ACTION_TYPE.RESUME_PURCHASED,
          initiator_user_id: userId,
          updated_at: Timestamp.now()
        };

        const applicationRef = doc(
          db,
          FIRESTORE_COLLECTIONS.JOBS,
          jobId,
          FIRESTORE_COLLECTIONS.APPLICATIONS,
          applicationId
        );

        await updateDoc(applicationRef, {
          actions: arrayUnion(applicationAction)
        });

        if (
          shouldExecuteInEnvironment({
            env: ENVIRONMENT.STAGE,
            userType: userType
          })
        ) {
          // Use Credit and update DB
          const newTransaction: Transaction = {
            type: CREDIT_TRANSACTION_TYPE.USE,
            initiated_by: userId,
            created_at: Timestamp.now(),
            credit_change_count: 1,
            credit_change_type: CREDIT_CHANGE_TYPE.DEBIT,
            credit_balance_before: credits,
            credit_balance_after: credits - 1,
            comment: prepareMultiLingual("Resume purchased", LOCALE.EN),
            metadata: {
              application_id: applicationId
            }
          };

          const transactionRef = collection(
            db,
            `${FIRESTORE_COLLECTIONS.COMPANIES}/${companyId}/${FIRESTORE_COLLECTIONS.TRANSACTIONS}`
          );
          const transactionDoc = await addDoc(transactionRef, newTransaction);

          //Update total credit left for the Company
          const companyRef = doc(
            db,
            FIRESTORE_COLLECTIONS.COMPANIES,
            companyId
          );
          const companySnap = await getDoc(companyRef);
          const companyData = companySnap.data() as CompanyProfile;

          if (!companyData || !companyData?.credit_information) {
            return false;
          }

          const [deductionSuccess, updatedCreditInfo] = deductCredit(
            companyData?.credit_information,
            1
          );

          if (deductionSuccess) {
            await updateDoc(companyRef, {
              total_credits_available: increment(-1),
              credit_information: updatedCreditInfo
            });
          }

          if (applicationDocument.id && transactionDoc.id && deductionSuccess) {
            return true;
          } else {
            return false;
          }
        } else {
          if (applicationDocument.id) {
            return true;
          }
        }
      } catch (error) {
        return false;
      }
    }
  }
  return false;
};

const getCandidateId = async (
  jobId: JobID,
  applicationId: ApplicationID
): Promise<string> => {
  const docRef = doc(
    db,
    `${FIRESTORE_COLLECTIONS.JOBS}/${jobId}/${FIRESTORE_COLLECTIONS.APPLICATIONS}`,
    applicationId
  );
  const docSnap = await getDoc(docRef);

  const applicationData = docSnap.data() as JobApplicationInvitation;
  if (applicationData) {
    return applicationData.candidate_id;
  }

  return "";
};

const deductCredit = (
  creditInformation: CreditInformation,
  amountToDeduct: number
): [boolean, CreditInformation] => {
  const updatedCredits: CreditInformation = { ...creditInformation };
  let deductionSuccessful = false;

  for (const [, rangeCredits] of Object.entries(updatedCredits)) {
    if (!rangeCredits.credits) {
      continue;
    }

    if (rangeCredits.credits >= amountToDeduct) {
      rangeCredits.credits -= amountToDeduct;
      deductionSuccessful = true;
      break;
    } else {
      amountToDeduct -= rangeCredits.credits;
      rangeCredits.credits = 0;
    }
  }

  return [deductionSuccessful, updatedCredits];
};

export {
  isResumePurchased,
  isCandidateProfilePurchased,
  canPurchaseResume,
  purchaseResume,
  deductCredit
};
