import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Box,
  Container,
  Grid,
  Link as MuiLink,
  Stack,
  styled
} from "@mui/material";
import dayjs from "dayjs";
import { FirebaseError } from "firebase/app";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import * as yup from "yup";

import Button from "@components/Button";
import Loader from "@components/Loader";
import TextField from "@components/TextField";
import Typography from "@components/Typography";

import useToast from "@hooks/useToast";

import { ENVIRONMENT, LOCALE, LOCALE_SHORT } from "@utils/config";
import { auth } from "@utils/firebase";
import { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

import Left_Gradient_Blob from "@assets/images/Left_Gradient_Blob.svg";
import Logo_White from "@assets/images/Logo_White.png";
import Blue_Blur_Cone from "@assets/images/shapes/Blue_Blur_Cone.png";
import Blue_Blur_Round_Cube from "@assets/images/shapes/Blue_Blur_Round_Cube.png";
import Blue_Dome from "@assets/images/shapes/Blue_Dome.png";
import Blue_Round_Pie_Chart_Shape from "@assets/images/shapes/Blue_Round_Pie_Chart_Shape.png";
import Blue_Shape from "@assets/images/shapes/Blue_Shape.png";
import Blue_Torus from "@assets/images/shapes/Blue_Torus.png";
import Orange_Cone from "@assets/images/shapes/Orange_Cone.png";
import Orange_Shape from "@assets/images/shapes/Orange_Shape.png";
import Orange_Slice from "@assets/images/shapes/Orange_Slice.png";
import Orange_Torus from "@assets/images/shapes/Orange_Torus.png";
import Top_Gradient_Blob from "@assets/images/Top_Gradient_Blob.svg";

interface PasswordResetForm {
  password: string;
}

const StyledLogo = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: 62,
    width: 62
  },
  [theme.breakpoints.up("sm")]: {
    height: 55,
    width: 55
  },
  [theme.breakpoints.up("lg")]: {
    height: 62,
    width: 62
  },
  "& .MuiAvatar-img": {
    objectFit: "contain"
  }
}));

const StyledLeftGradientImage = styled("img")({
  transform: "translateX(-30%) translateY(-35%) rotate(7deg)",
  height: "200vh"
});

const StyledTopGradientImage = styled("img")({
  objectFit: "cover",
  height: "60vh"
});

const StyledRightContentGridContainer = styled(Grid)({
  position: "relative",
  top: "calc(50vh - 1.5rem)",
  transform: "translateY(-50%)"
});

const StyledOrangeShape = styled("img")(({ theme }) => {
  return {
    width: 65,
    position: "absolute",
    zIndex: 1,
    transform: "rotate(-90deg)",
    right: 20,
    [theme.breakpoints.up("md")]: {
      right: "unset",
      transform: "rotate(-80deg)",
      left: "28%",
      top: 25
    },
    [theme.breakpoints.up("lg")]: {
      width: 80
    }
  };
});

const StyledBlueRoundPieChartShape = styled("img")(({ theme }) => {
  return {
    width: 120,
    position: "absolute",
    zIndex: 1,
    top: "5%",
    left: "17%",
    [theme.breakpoints.up("md")]: {
      width: 80,
      top: "15%",
      left: "12%"
    },
    [theme.breakpoints.up("lg")]: {
      width: 120
    }
  };
});

const StyledOrangeSlice = styled("img")(({ theme }) => {
  return {
    width: 65,
    position: "absolute",
    zIndex: 1,
    left: 20,
    top: "13%",
    [theme.breakpoints.up("md")]: {
      left: "2%",
      top: "18%"
    },
    [theme.breakpoints.up("lg")]: {
      width: 80
    }
  };
});

const StyledBlueDome = styled("img")(({ theme }) => {
  return {
    width: 100,
    position: "absolute",
    zIndex: 1,
    left: "50%",
    top: -55,
    transform: "rotate(15deg)",
    [theme.breakpoints.up("md")]: {
      width: 80,
      left: 0,
      top: "40%",
      transform: "unset"
    },
    [theme.breakpoints.up("lg")]: {
      width: 120
    }
  };
});

const StyledBlueBlurCone = styled("img")(({ theme }) => {
  return {
    width: 50,
    position: "absolute",
    zIndex: 1,
    top: "2%",
    left: "35%",
    [theme.breakpoints.up("md")]: {
      top: "38%",
      left: "10%"
    },
    [theme.breakpoints.up("lg")]: {
      width: 80
    }
  };
});

const StyledBlueRoundCube = styled("img")(({ theme }) => {
  return {
    width: 50,
    position: "absolute",
    zIndex: 1,
    top: "4%",
    right: "27%",
    [theme.breakpoints.up("md")]: {
      top: "unset",
      bottom: "31%",
      left: "15%"
    },
    [theme.breakpoints.up("lg")]: {
      width: 80
    }
  };
});

const StyledOrangeTorus = styled("img")(({ theme }) => {
  return {
    width: 100,
    position: "absolute",
    zIndex: 1,
    top: "3%",
    right: "15%",
    [theme.breakpoints.up("md")]: {
      width: 110,
      top: "unset",
      bottom: "25%",
      left: "21%"
    },
    [theme.breakpoints.up("lg")]: {
      width: 180
    }
  };
});

const StyledBlueShape = styled("img")(({ theme }) => {
  return {
    width: 100,
    position: "absolute",
    zIndex: 1,
    transform: "rotate(-143.36deg)",
    left: "23%",
    top: "25%",
    [theme.breakpoints.up("lg")]: {
      width: 180
    }
  };
});

const StyledOrangeCone = styled("img")(({ theme }) => {
  return {
    width: 65,
    position: "absolute",
    zIndex: 1,
    bottom: "30%",
    left: "5%",
    [theme.breakpoints.up("lg")]: {
      width: 120
    }
  };
});

const StyledBlueTorus = styled("img")(({ theme }) => {
  return {
    width: 120,
    position: "absolute",
    zIndex: 1,
    bottom: 0,
    left: "2%",
    [theme.breakpoints.up("lg")]: {
      width: 180
    }
  };
});

const StyledOrangePieUpper = styled("img")(({ theme }) => {
  return {
    width: 55,
    position: "absolute",
    zIndex: 1,
    transform: "scaleX(-1) rotate(30deg)",
    bottom: "12%",
    left: "25%",
    [theme.breakpoints.up("lg")]: {
      width: 100
    }
  };
});

const StyledOrangePieLower = styled("img")(({ theme }) => {
  return {
    width: 55,
    position: "absolute",
    zIndex: 1,
    transform: "scaleX(-1) rotate(-70deg)",
    bottom: "3%",
    left: "22%",
    [theme.breakpoints.up("lg")]: {
      width: 100
    }
  };
});

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const { is_updated: isUpdated } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [returnURL, setReturnURL] = useState<string>("/");

  const navigateHome = () => {
    navigate(`/${translate.getCurrentLocale()}/`);
    toast.kampai(intl.get("t_forgot_password_expired_link"), "error");
  };

  useEffect(() => {
    const returnURL = searchParams.get("continueUrl") ?? "";
    if (returnURL) {
      setReturnURL(returnURL);
    } else {
      setReturnURL("/");
    }
    if (isUpdated) {
      setShowLoader(false);
      return;
    }
    const mode = searchParams.get("mode") ?? "";
    const passwordResetCode = searchParams.get("oobCode") ?? "";
    const apiKey = searchParams.get("apiKey") ?? "";
    const lang = searchParams.get("lang") ?? "";
    let newLocale: typeof LOCALE[keyof typeof LOCALE] = LOCALE.EN;
    switch (lang) {
      case LOCALE_SHORT.EN:
        newLocale = LOCALE.EN;
        break;
      case LOCALE_SHORT.JA:
        newLocale = LOCALE.JA;
        break;
      default:
        newLocale = LOCALE.EN;
        break;
    }
    const currentLocale = translate.getCurrentLocale();
    if (currentLocale !== newLocale) {
      translate.setCurrentLocale(newLocale);
    }

    if (!mode || !passwordResetCode || !apiKey || mode !== "resetPassword") {
      navigateHome();
    }

    if (
      process.env.REACT_APP_ENVIRONMENT !== ENVIRONMENT.LOCAL &&
      apiKey !== process.env.REACT_APP_FIREABSE_API_KEY
    ) {
      navigateHome();
    }

    (async () => {
      try {
        const email = await verifyPasswordResetCode(auth, passwordResetCode);
        if (email) {
          setShowLoader(false);
        } else {
          navigateHome();
        }
      } catch (e) {
        navigateHome();
      }
    })();
  }, [searchParams]);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required(
        intl.get("t_error_required", { field: intl.get("t_general_password") })
      )
  });

  const defaultFormValues: PasswordResetForm = {
    password: ""
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control } = methods;

  const setPassword = (formData: PasswordResetForm) => {
    const passwordResetCode = searchParams.get("oobCode") ?? "";
    setShowLoader(true);
    (async () => {
      try {
        await confirmPasswordReset(auth, passwordResetCode, formData.password);
        setShowLoader(false);
        navigate("password-updated?continueUrl=" + returnURL);
      } catch (e) {
        setErrorMessage(intl.get("t_general_internal_error"));

        if (e instanceof FirebaseError) {
          const code = e.code;
          if (code === "auth/invalid-action-code") {
            setErrorMessage(intl.get("t_forgot_password_expired_link"));
          } else if (code === "auth/weak-password") {
            setErrorMessage(intl.get("t_forgot_password_weak_password"));
          }
        }
      }
    })();
  };

  const navigteToContinueURL = () => {
    const continueURL = new URL(returnURL);
    if (continueURL.hostname === window.location.hostname) {
      let path = continueURL.pathname;
      if (continueURL.search) {
        path = path + "?" + continueURL.search;
      }
      navigate(path);
    } else {
      window.location.href = returnURL;
    }
  };

  return (
    <>
      {isShowLoader ? <Loader /> : false}
      <Grid container mb={6}>
        <Grid item xs={12} md={6}>
          <Box
            zIndex={1}
            position="absolute"
            mx={1.25}
            my={2.5}
            left={{ md: "5%", lg: "10%" }}>
            <MuiLink component={Link} to="/">
              <StyledLogo
                variant="square"
                src={Logo_White}
                alt={intl.get("t_alt_tokhimo_job_logo")}
              />
            </MuiLink>
          </Box>
          {/* Images that display on tablet and above screens */}
          <Box display={{ xs: "none", sm: "block" }}>
            <StyledOrangeShape
              src={Orange_Shape}
              alt={intl.get("t_alt_orange_shape")}
            />
            <StyledBlueRoundPieChartShape
              src={Blue_Round_Pie_Chart_Shape}
              alt={intl.get("t_alt_forgot_password_blue_round_pie_shape")}
            />
            <StyledOrangeSlice
              src={Orange_Slice}
              alt={intl.get("t_alt_forgot_password_orange_slice")}
            />
            <StyledBlueDome
              src={Blue_Dome}
              alt={intl.get("t_alt_forgot_password_blue_dome")}
            />
            <StyledBlueBlurCone
              src={Blue_Blur_Cone}
              alt={intl.get("t_alt_forgot_password_blue_blur_cone")}
            />
            <StyledBlueRoundCube
              src={Blue_Blur_Round_Cube}
              alt={intl.get("t_alt_forgot_password_blue_blur_round_cube")}
            />
            <StyledOrangeTorus
              src={Orange_Torus}
              alt={intl.get("t_alt_forgot_password_orange_torus")}
            />
          </Box>

          {/* Images that only show on desktop screens */}
          <Box display={{ xs: "none", md: "block" }}>
            <StyledBlueShape
              src={Blue_Shape}
              alt={intl.get("t_alt_blue_shape")}
            />
            <StyledOrangeCone
              src={Orange_Cone}
              alt={intl.get("t_alt_forgot_password_orange_cone")}
            />
            <StyledBlueTorus
              src={Blue_Torus}
              alt={intl.get("t_alt_forgot_password_blue_torus")}
            />
            <StyledOrangePieUpper
              src={Orange_Slice}
              alt={intl.get("t_alt_forgot_password_orange_slice")}
            />
            <StyledOrangePieLower
              src={Orange_Slice}
              alt={intl.get("t_alt_forgot_password_orange_slice")}
            />
          </Box>

          <Box
            position="absolute"
            overflow="hidden"
            top="0"
            left="0"
            maxHeight="100vh"
            maxWidth="50vw"
            display={{ xs: "none", md: "block" }}>
            <StyledLeftGradientImage
              src={Left_Gradient_Blob}
              alt={intl.get("t_alt_forgot_password_pc_background_blob")}
            />
          </Box>
          <Box
            width="100%"
            maxHeight="35vh"
            overflow="hidden"
            display={{ xs: "block", md: "none" }}
            position="absolute">
            <StyledTopGradientImage
              src={Top_Gradient_Blob}
              alt={intl.get("t_alt_forgot_password_mobile_background_blob")}
              width="100%"
            />
          </Box>
        </Grid>
        <StyledRightContentGridContainer item xs={12} md={4} zIndex={2}>
          <Container>
            {isUpdated ? (
              <>
                <Typography variant="h3" mb={1}>
                  {intl.get("t_forgot_password_updated_title")}
                </Typography>
                <br />
                <br />
                <Button handleClick={navigteToContinueURL}>
                  {intl.get("t_general_continue")}
                </Button>
              </>
            ) : (
              <>
                <Typography variant="h3" mb={1}>
                  {intl.get("t_forgot_password_title")}
                </Typography>
                <Typography variant="subtitle4" color={colorPalette.red.base}>
                  {errorMessage}
                </Typography>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit(setPassword)}
                  mt={1}>
                  <TextField
                    type="password"
                    name="password"
                    required
                    label={intl.get("t_forgot_password_label")}
                    placeholder={intl.get("t_forgot_password_placeholder")}
                    control={control}
                  />
                  <Button fullWidth type="submit">
                    {intl.get("t_signin_reset_password")}
                  </Button>
                </Box>
              </>
            )}
          </Container>
        </StyledRightContentGridContainer>
      </Grid>

      <Box position="absolute" bottom="2rem" left="0" width="100%">
        <Container>
          <Grid container>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={4}>
              <Stack direction="row" justifyContent="center">
                <Typography
                  variant="body2"
                  color={colorPalette.black[80]}
                  mt={2}>
                  {intl.get("t_general_copyright", {
                    year: dayjs().format("YYYY")
                  })}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPassword;
