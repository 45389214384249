import SkeletonInput from "@skeletons/SkeletonInput";

const SkeletonJobInformationForm = () => {
  return (
    <>
      <SkeletonInput />
      <SkeletonInput multiLine />
      <SkeletonInput multiLine />
    </>
  );
};

export default SkeletonJobInformationForm;
