import { Fragment } from "react";

import { Box, Grid, Stack } from "@mui/material";

import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonInput from "@skeletons/SkeletonInput";
import SkeletonKeyword from "@skeletons/SkeletonKeyword";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Paper from "@components/Paper";
import Typography from "@components/Typography";

import { intl } from "@utils/translate";

const SkeletonDesktopProfileSummaryForm = () => {
  return (
    <Box>
      {/* Basic information section start */}
      <Paper sx={{ mb: 3 }}>
        <Typography variant="h3">
          {intl.get("t_profile_basic_info_heading")}
        </Typography>
        <br />
        <Typography mt={2.5} mb={3} color="text.secondary">
          {intl.get("t_profile_basic_info_sub_heading")}
        </Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <SkeletonInput />
          </Grid>
          <Grid item xs={6}>
            <SkeletonInput />
          </Grid>
        </Grid>
        <SkeletonInput />
        <SkeletonInput />
        <SkeletonInput />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <SkeletonInput />
          </Grid>
          <Grid item xs={6}>
            <SkeletonInput />
          </Grid>
        </Grid>
        <SkeletonInput />
        <SkeletonInput />
      </Paper>
      {/* Basic information section end */}

      {/* Job overview section start */}
      <Paper sx={{ mb: 3 }}>
        <Typography variant="h3">
          {intl.get("t_profile_job_overview_heading")}
        </Typography>
        <br />
        <Typography mt={2.5} mb={2} color="text.secondary">
          {intl.get("t_profile_job_overview_sub_heading")}
        </Typography>
        <br />
        <SkeletonTypography width="medium" variant="h3" />
        <Grid container columnSpacing={3}>
          {[...Array(3)].map((_, index) => {
            return (
              <Fragment key={index}>
                <Grid item xs={5.5}>
                  <SkeletonInput />
                </Grid>
                <Grid item xs={5.5}>
                  <SkeletonInput />
                </Grid>
                <Grid item xs={12}>
                  <SkeletonTypography width="medium" variant="h4" />
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
        {/* add another text */}
        <SkeletonTypography variant="h6" />
      </Paper>
      {/* Job overview section end */}

      {/* Language section start */}
      <Paper sx={{ mb: 3 }}>
        <Typography variant="h3">
          {intl.get("t_profile_language_heading")}
        </Typography>
        <br />
        <Typography mt={2.5} mb={2} color="text.secondary">
          {intl.get("t_profile_language_sub_heading")}
        </Typography>
        <br />

        <Grid container columnSpacing={3}>
          {[...Array(3)].map((_, index) => {
            return (
              <Fragment key={index}>
                <Grid item xs={5.5} mt={3}>
                  <SkeletonInput />
                </Grid>
                <Grid item xs={5.5} mt={3}>
                  <SkeletonInput />
                </Grid>
                <Grid item xs={5.5}>
                  <SkeletonInput />
                </Grid>
                <Grid item xs={5.5}>
                  <SkeletonInput />
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
        {/* add another text */}
        <SkeletonTypography variant="h6" />
      </Paper>
      {/* Language section end */}

      {/* Skills section start */}
      <Paper sx={{ mb: 3 }}>
        <Typography variant="h3">
          {intl.get("t_profile_skill_heading")}
        </Typography>
        <br />
        <Typography mt={2.5} mb={2} color="text.secondary">
          {intl.get("t_profile_skill_sub_heading")}
        </Typography>
        <br />
        <Grid container columnSpacing={3}>
          {[...Array(1)].map((_, index) => {
            return (
              <Fragment key={index}>
                <Grid item xs={5.5}>
                  <SkeletonInput />
                </Grid>
                <Grid item xs={5.5}>
                  <SkeletonInput />
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
        {/* add another text */}
        <SkeletonTypography variant="h6" />
        <br />
        <>
          <Typography
            variant="subtitle4"
            color="text.secondary"
            mt={2}
            mb={1.5}
            display="flex">
            {intl.get("t_profile_skill_selected_skills")}
          </Typography>
          <br />
          <Stack direction="row" gap={1} flexWrap="wrap">
            {[...Array(5)].map((_, index) => {
              return (
                <Fragment key={index}>
                  <SkeletonKeyword />
                </Fragment>
              );
            })}
          </Stack>
        </>
      </Paper>
      {/* Skills section end */}

      <Stack justifyContent="space-between" direction="row" mt={7.5}>
        <SkeletonButton />
        <SkeletonButton />
      </Stack>
    </Box>
  );
};

export default SkeletonDesktopProfileSummaryForm;
