import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Box, Grid, Stack, styled } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";

import SkeletonProfileInformationCard from "@skeletons/SkeletonProfileInformationCard";
import SkeletonProfileSummaryCard from "@skeletons/SkeletonProfileSummaryCard";

import CompleteProfileCard from "@components/CompleteProfileCard";
import FTUIContainer, { CustomStep } from "@components/FTUIContainer";
import Paper from "@components/Paper";
import ProfileInformationCard from "@components/ProfileInformationCard";
import ProfileSummaryCard from "@components/ProfileSummaryCard";
import ScrollTopButton from "@components/ScrollTopButton";
import SideMenu from "@components/SideMenu";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";

import Skill from "@interfaces/database/Skill";
import UserJobExperienceOverview from "@interfaces/database/UserJobExperienceOverview";
import UserLanguage from "@interfaces/database/UserLanguage";

import { FTUI_TOUR_NAME, LOCALE } from "@utils/config";
import fetchInitials from "@utils/fetchInitials";
import { auth } from "@utils/firebase";
import { resolveMultiLingual } from "@utils/multiLingual";
import translate, { intl } from "@utils/translate";

const StyledProfileInformationCardContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    paddingRight: 0
  },
  [theme.breakpoints.up("sm")]: {
    paddingRight: "2rem"
  }
}));

const StyledProfileSummaryCardContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 0
  },
  [theme.breakpoints.up("sm")]: {
    paddingLeft: "2rem"
  }
}));

const ProfileSummary = () => {
  const tourSteps: Array<CustomStep> = [
    {
      target: "#candidate-profile-info-card",
      title: intl.get("t_ftui_candidate_profile_page_update_avatar_title"),
      content: intl.get(
        "t_ftui_candidate_profile_page_update_avatar_description"
      ),
      disableBeacon: true
    },
    {
      target: "#candidate-complete-profile-card",
      title: intl.get("t_ftui_candidate_profile_page_complete_profile_title"),
      content: intl.get(
        "t_ftui_candidate_profile_page_complete_profile_description"
      ),
      disableBeacon: true
    },
    {
      target: "#candidate-sidemenu-resume-link",
      title: intl.get("t_ftui_candidate_profile_page_check_resume_title"),
      content: intl.get(
        "t_ftui_candidate_profile_page_check_resume_description"
      ),
      disableBeacon: true,
      nextRoute: `/${translate.getCurrentLocale()}/profile/resume/en-cv`
    }
  ];

  const [user] = useAuthState(auth);
  const { value, loading } = useUserProfile();
  const navigate = useNavigate();
  const { summary, progress_flags } = value ?? {};
  const { basic_information, job_experience_overview, languages, skills } =
    summary ?? {};
  const isEnResumePresent = value?.metadata?.progress_flags?.en_cv ?? false;
  const isJaResumePresent = value?.metadata?.progress_flags?.ja_cv ?? false;
  const isJaRirekishoPresent =
    value?.metadata?.progress_flags?.ja_rirekisho ?? false;

  const enInitials =
    fetchInitials(
      `${resolveMultiLingual(
        basic_information?.first_name,
        LOCALE.EN,
        true
      )} ${resolveMultiLingual(basic_information?.last_name, LOCALE.EN, true)}`
    ) ?? "";

  const [photoUrl, setPhotoUrl] = useState<string | null>(user?.photoURL ?? "");

  const navLinks = [
    {
      label: intl.get("t_profile_summary"),
      redirectLink: `/${translate.getCurrentLocale()}/profile`,
      aliasLinks: ["/profile"]
    },
    {
      label: intl.get("t_profile_menu_my_resume"),
      redirectLink: `/${translate.getCurrentLocale()}/profile/resume/`,
      aliasLinks: [
        "/profile/resume/en-cv",
        "/profile/resume/ja-cv",
        "/profile/resume/ja-rirekisho",
        `/${translate.getCurrentLocale()}/profile/resume/en-cv`,
        `/${translate.getCurrentLocale()}/profile/resume/ja-cv`,
        `/${translate.getCurrentLocale()}/profile/resume/ja-rirekisho`
      ],
      id: "candidate-sidemenu-resume-link"
    }
  ];

  return (
    <>
      <Typography variant="h3" color="common.white">
        {intl.get("t_profile_your_profile")}
      </Typography>
      <Grid container mt={4}>
        <StyledProfileInformationCardContainer item xs={12} sm={4}>
          {loading ? (
            <SkeletonProfileInformationCard />
          ) : (
            <>
              <ProfileInformationCard handleProfilePicChange={setPhotoUrl} />
              <Box mt={3}>
                <SideMenu menuLinks={navLinks} />
              </Box>
            </>
          )}
        </StyledProfileInformationCardContainer>
        <StyledProfileSummaryCardContainer item xs={12} sm={8}>
          <Paper sx={{ mt: { xs: 3, sm: 0 } }}>
            <Typography variant="h3" mt={5.5} mb={2.5} color="text.primary">
              {intl.get("t_profile_summary")}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              mb={{ xs: 3, md: 7 }}>
              {intl.get("t_profile_summary_description")}
            </Typography>
            {loading ? (
              <SkeletonProfileSummaryCard />
            ) : (
              <Box
                id="candidate-complete-profile-card"
                data-testid="candidate_complete_profile_card">
                {!progress_flags?.basic_information ||
                !progress_flags?.job_experience_overview ||
                !progress_flags?.language ||
                !progress_flags?.skills ? (
                  <CompleteProfileCard />
                ) : (
                  <ProfileSummaryCard
                    attachedResumes={{
                      englishCV: isEnResumePresent,
                      japaneseCV: isJaResumePresent,
                      japaneseResume: isJaRirekishoPresent
                    }}
                    initials={enInitials}
                    noJobExperience={summary?.no_job_experience_overview}
                    handleEdit={() =>
                      navigate(`/${translate.getCurrentLocale()}/profile/edit`)
                    }
                    profilePicUrl={photoUrl ?? ""}
                    jobTitleId={basic_information?.primary_job_title_index}
                    expectedSalary={basic_information?.expected_salary}
                    visaType={basic_information?.immigration_details?.visa_type}
                    location={{
                      city: basic_information?.current_location?.city ?? "",
                      country:
                        basic_information?.current_location?.country ?? ""
                    }}
                    countryOfOrigin={
                      basic_information?.immigration_details?.country
                    }
                    noOfCompanies={basic_information?.number_of_companies}
                    phoneNumber={basic_information?.phone_number}
                    experiences={job_experience_overview?.map(
                      (singleJobExperience: UserJobExperienceOverview) => {
                        return {
                          jobTitle:
                            resolveMultiLingual(
                              singleJobExperience.job_title
                            ) ?? "",
                          yearsOfExperience:
                            singleJobExperience.years_of_experience
                        };
                      }
                    )}
                    languages={languages?.map(
                      (singleLanguage: UserLanguage) => {
                        return {
                          name: singleLanguage.name,
                          proficiency: singleLanguage.proficiency,
                          testName: singleLanguage.test_name,
                          testResult: singleLanguage.test_result
                        };
                      }
                    )}
                    skills={skills?.map((skill: Skill) => {
                      return {
                        name: skill.name,
                        yearsOfExperience: skill.years_of_experience
                      };
                    })}
                  />
                )}
              </Box>
            )}
          </Paper>
          <Stack direction="row" justifyContent="end" mt={3}>
            <ScrollTopButton />
          </Stack>
        </StyledProfileSummaryCardContainer>
      </Grid>

      {/* FTUI */}
      <FTUIContainer
        tourSteps={tourSteps}
        tourName={FTUI_TOUR_NAME.CANDIDATE_PROFILE_PAGE}
      />
    </>
  );
};

export default ProfileSummary;
