import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult
} from "react-beautiful-dnd";
import { Control, FieldValues, UseFormSetValue } from "react-hook-form";
import { TransitionGroup } from "react-transition-group";

import { DragIndicator as DragIndicatorIcon } from "@mui/icons-material";
import { Box, Collapse, IconButton, Stack, Tooltip } from "@mui/material";

import Icon from "@components/Icon";
import NonTypeableSelect from "@components/NonTypeableSelect";

import KeyLabel from "@interfaces/components/KeyLabel";

import { colorPalette } from "@utils/theme";
import { intl } from "@utils/translate";

interface DragDropSelectProps {
  fields: Array<FieldValues>;
  fieldIdsArr?: Array<string>;
  handleDragEnd: (result: DropResult) => void;
  handleDeleteField: (index: number, name: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  options: Array<KeyLabel>;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  disabled?: boolean;
}

const DragDropSelect = ({
  fields,
  fieldIdsArr,
  handleDragEnd,
  handleDeleteField,
  control,
  setValue,
  options,
  name,
  disabled = false
}: DragDropSelectProps) => {
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={name} isDropDisabled={disabled}>
        {(provided: DroppableProvided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <TransitionGroup>
              {fieldIdsArr?.map((_, index) => {
                const item = fields[index];
                return (
                  <Collapse key={item.id}>
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={disabled}>
                      {(provided: DraggableProvided) => (
                        <>
                          <Stack
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            direction="row"
                            alignItems="center"
                            width="100%"
                            columnGap={1}>
                            <Box width="100%" minWidth="50%">
                              <Tooltip
                                arrow
                                enterTouchDelay={0}
                                title={
                                  disabled || item?.key?.length !== 0
                                    ? intl.get(
                                        "t_job_post_job_hiring_flow_disable_select_tooltip_text"
                                      )
                                    : ""
                                }>
                                <span>
                                  <NonTypeableSelect
                                    disabled={
                                      disabled || item?.key?.length !== 0
                                    }
                                    readOnly={item?.key?.length !== 0}
                                    setValue={setValue}
                                    name={`${name}.[${index}].type`}
                                    control={control}
                                    placeholder={intl.get(
                                      "t_job_post_job_hiring_flow_additional_step"
                                    )}
                                    options={options}
                                  />
                                </span>
                              </Tooltip>
                            </Box>
                            <Stack direction="row" columnGap={1}>
                              <Stack
                                justifyContent="center"
                                alignItems="center"
                                width={48}
                                height={56}
                                borderRadius={1}
                                mb={0.5}
                                border="1px solid"
                                borderColor={colorPalette.grey.base}
                                {...provided.dragHandleProps}
                                sx={{ cursor: "grab" }}>
                                <DragIndicatorIcon fontSize="small" />
                              </Stack>
                              <Stack
                                borderRadius={1}
                                justifyContent="center"
                                alignItems="center"
                                width={48}
                                height={56}
                                mb={0.5}
                                border="1px solid"
                                borderColor={colorPalette.grey.base}
                                color="error.main">
                                <Tooltip
                                  arrow
                                  enterTouchDelay={0}
                                  title={
                                    disabled || item?.key?.length !== 0
                                      ? intl.get(
                                          "t_job_post_job_hiring_flow_disable_delete_btn_tooltip_text"
                                        )
                                      : ""
                                  }>
                                  <span>
                                    <IconButton
                                      disabled={
                                        disabled || item?.key?.length !== 0
                                      }
                                      color="error"
                                      onClick={() =>
                                        handleDeleteField(index, name)
                                      }>
                                      <Icon type="delete" filled />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Stack>
                            </Stack>
                          </Stack>
                        </>
                      )}
                    </Draggable>
                  </Collapse>
                );
              })}
            </TransitionGroup>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropSelect;
