import { CheckCircleRounded as CheckCircleRoundedIcon } from "@mui/icons-material";
import { Stack } from "@mui/material";

import Typography from "@components/Typography";

interface CVFilledStatusProps {
  label: string;
  isAttached: boolean;
}

const CVFilledStatus = ({ label, isAttached }: CVFilledStatusProps) => {
  return (
    <Stack gap={1} direction="row">
      <CheckCircleRoundedIcon color={isAttached ? "primary" : "disabled"} />
      <Typography variant="body1">{label}</Typography>
    </Stack>
  );
};

export default CVFilledStatus;
