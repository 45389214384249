import { useEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Box, useMediaQuery, useTheme } from "@mui/material";

interface PieChartProps {
  containerId?: string;
  data: Array<{
    value: number;
    category: string;
    color: string;
  }>;
}

const PieChart = ({ containerId = "pie-chart", data }: PieChartProps) => {
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("md"));

  useEffect(() => {
    // Create root element
    const root = am5.Root.new(containerId);

    if (root._logo) {
      root._logo.dispose(); // Remove AMCharts watermark
    }

    // Set themes
    const myTheme = am5.Theme.new(root);
    myTheme.rule("Label").setAll({
      fill: am5.color(0x1f2431),
      fontSize: "0.75rem",
      fontFamily: "Poppins"
    });
    root.setThemes([am5themes_Animated.new(root), myTheme]);

    // Create chart
    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        radius: isSmallScreen ? 75 : 80
      })
    );

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        name: "Series",
        legendLabelText: "{category}",
        legendValueText: "",
        alignLabels: false
      })
    );

    const colors = data?.map((singleData) => am5.color(singleData.color));

    series
      ?.get("colors")
      ?.set(
        "colors",
        colors ?? [
          (am5.color(0x16b4f3),
          am5.color(0x1859fb),
          am5.color(0xffc72c),
          am5.color(0x4497f8),
          am5.color(0xff7048))
        ]
      );

    // Set data
    series.data.setAll(data);

    // Hide Ticks
    series.ticks.template.set("forceHidden", true);

    // Set labels and tooltips
    series.labels.template.setAll({
      fontSize: 12,
      fill: am5.color(0xffffff),
      text: "{valuePercentTotal.formatNumber('#.')}%",
      position: "absolute",
      inside: true,
      orientation: "inward",
      radius: 25
    });
    series.slices.template.set("tooltipText", "");

    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [isSmallScreen]);

  return <Box id={containerId} width="100%" height="100%" overflow="hidden" />;
};

export default PieChart;
