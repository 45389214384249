import { arrayUnion, doc, updateDoc } from "firebase/firestore";

import ApplicationID from "@interfaces/database/ApplicationID";
import JobApplicationInvitationAction from "@interfaces/database/JobApplicationInvitationAction";
import JobID from "@interfaces/database/JobID";
import MultiLingual from "@interfaces/database/MultiLingual";
import UserID from "@interfaces/database/UserID";

import { UserProfileContextStructure } from "@utils/components/UserProfileContext";
import {
  FIRESTORE_COLLECTIONS,
  JOB_APPLICATION_INVITATION_ACTION_TYPE
} from "@utils/config";
import { db } from "@utils/firebase";
import Timestamp from "@utils/Timestamp";

// Employer declining job application by candidate
export const handleJobDecline = async (
  applicationId: ApplicationID,
  jobId: JobID,
  userId: UserID,
  comments?: Array<MultiLingual<string>>
) => {
  const currentTimeStamp = Timestamp.now();

  const applicationAction: JobApplicationInvitationAction = {
    action_type: JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED,
    initiator_user_id: userId,
    updated_at: currentTimeStamp
  };

  if (comments && comments.length > 0) {
    applicationAction["comments"] = comments;
  }

  try {
    const applicationRef = doc(
      db,
      FIRESTORE_COLLECTIONS.JOBS,
      jobId,
      FIRESTORE_COLLECTIONS.APPLICATIONS,
      applicationId
    );

    await updateDoc(applicationRef, {
      actions: arrayUnion(applicationAction)
    });

    return true;
  } catch (error) {
    return false;
  }
};

// Candidate declining job invitation from employer
export const handleInvitedJobDecline = async (
  applicationId: ApplicationID,
  jobId: JobID,
  userId: UserID,
  userProfile: UserProfileContextStructure,
  comments?: Array<MultiLingual<string>>
) => {
  if (userProfile.value?.jobs?.invited) {
    const userInvitedJobs = userProfile.value?.jobs?.invited ?? [];
    const currentTimeStamp = Timestamp.now();

    const applicationAction: JobApplicationInvitationAction = {
      action_type: JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED_DECLINED,
      initiator_user_id: userId,
      updated_at: currentTimeStamp
    };

    if (comments && comments.length > 0) {
      applicationAction["comments"] = comments;
    }

    try {
      const index = userInvitedJobs.findIndex(
        (singleJob) => singleJob.id === jobId
      );

      //Remove declined job from invited jobs
      if (index !== -1) {
        const updatedInvitedJobs = [...userProfile.value.jobs.invited];
        updatedInvitedJobs.splice(index, 1);

        if (
          userProfile.setValue &&
          userProfile.value &&
          userProfile.value.jobs
        ) {
          await userProfile.setValue({
            ...userProfile.value,
            jobs: {
              ...userProfile.value.jobs,
              invited: updatedInvitedJobs
            }
          });

          const applicationRef = doc(
            db,
            FIRESTORE_COLLECTIONS.JOBS,
            jobId,
            FIRESTORE_COLLECTIONS.APPLICATIONS,
            applicationId
          );

          await updateDoc(applicationRef, {
            actions: arrayUnion(applicationAction)
          });

          return true;
        }
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
};
