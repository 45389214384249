import SkeletonTypography from "@skeletons/SkeletonTypography";

import Well from "@components/Well";

const SkeletonJobExperienceOverviewCard = () => {
  return (
    <Well>
      <SkeletonTypography variant="subtitle2" width="short" />
      <SkeletonTypography variant="subtitle4" width="medium" />
      <SkeletonTypography variant="body1" width="short" />
    </Well>
  );
};

export default SkeletonJobExperienceOverviewCard;
