// FIXME: Add alt text translation
import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Box,
  Container,
  Grid,
  Link as MuiLink,
  Stack,
  styled
} from "@mui/material";
import dayjs from "dayjs";
import { FirebaseError } from "firebase/app";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import * as yup from "yup";

import Button from "@components/Button";
import Loader from "@components/Loader";
import TextField from "@components/TextField";
import Typography from "@components/Typography";

import useToast from "@hooks/useToast";

import { auth } from "@utils/firebase";
import { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

import Left_Gradient_Blob from "@assets/images/Left_Gradient_Blob.svg";
import Logo_White from "@assets/images/Logo_White.png";
import Blue_Blur_Cone from "@assets/images/shapes/Blue_Blur_Cone.png";
import Blue_Blur_Round_Cube from "@assets/images/shapes/Blue_Blur_Round_Cube.png";
import Blue_Dome from "@assets/images/shapes/Blue_Dome.png";
import Blue_Round_Pie_Chart_Shape from "@assets/images/shapes/Blue_Round_Pie_Chart_Shape.png";
import Blue_Shape from "@assets/images/shapes/Blue_Shape.png";
import Blue_Torus from "@assets/images/shapes/Blue_Torus.png";
import Orange_Cone from "@assets/images/shapes/Orange_Cone.png";
import Orange_Shape from "@assets/images/shapes/Orange_Shape.png";
import Orange_Slice from "@assets/images/shapes/Orange_Slice.png";
import Orange_Torus from "@assets/images/shapes/Orange_Torus.png";
import Top_Gradient_Blob from "@assets/images/Top_Gradient_Blob.svg";

interface ChangePasswordForm {
  currentPassword: string;
  newPassword: string;
}

const StyledLogo = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: 62,
    width: 62
  },
  [theme.breakpoints.up("sm")]: {
    height: 55,
    width: 55
  },
  [theme.breakpoints.up("lg")]: {
    height: 62,
    width: 62
  },
  "& .MuiAvatar-img": {
    objectFit: "contain"
  }
}));

const StyledLeftGradientImage = styled("img")({
  transform: "translateX(-30%) translateY(-35%) rotate(7deg)",
  height: "200vh"
});

const StyledTopGradientImage = styled("img")({
  objectFit: "cover",
  height: "60vh"
});

const StyledRightContentGridContainer = styled(Grid)(({ theme }) => {
  return {
    position: "relative",
    top: "57vh",
    transform: "translateY(-50%)",
    [theme.breakpoints.up("md")]: {
      top: "calc(50vh - 1.5rem)"
    }
  };
});

const StyledOrangeShape = styled("img")(({ theme }) => {
  return {
    width: 65,
    position: "absolute",
    zIndex: 1,
    transform: "rotate(-90deg)",
    right: 20,
    [theme.breakpoints.up("md")]: {
      right: "unset",
      transform: "rotate(-80deg)",
      left: "28%",
      top: 25
    },
    [theme.breakpoints.up("lg")]: {
      width: 80
    }
  };
});

const StyledBlueRoundPieChartShape = styled("img")(({ theme }) => {
  return {
    width: 120,
    position: "absolute",
    zIndex: 1,
    top: "5%",
    left: "17%",
    [theme.breakpoints.up("md")]: {
      width: 80,
      top: "15%",
      left: "12%"
    },
    [theme.breakpoints.up("lg")]: {
      width: 120
    }
  };
});

const StyledOrangeSlice = styled("img")(({ theme }) => {
  return {
    width: 65,
    position: "absolute",
    zIndex: 1,
    left: 20,
    top: "13%",
    [theme.breakpoints.up("md")]: {
      left: "2%",
      top: "18%"
    },
    [theme.breakpoints.up("lg")]: {
      width: 80
    }
  };
});

const StyledBlueDome = styled("img")(({ theme }) => {
  return {
    width: 100,
    position: "absolute",
    zIndex: 1,
    left: "50%",
    top: -55,
    transform: "rotate(15deg)",
    [theme.breakpoints.up("md")]: {
      width: 80,
      left: 0,
      top: "40%",
      transform: "unset"
    },
    [theme.breakpoints.up("lg")]: {
      width: 120
    }
  };
});

const StyledBlueBlurCone = styled("img")(({ theme }) => {
  return {
    width: 50,
    position: "absolute",
    zIndex: 1,
    top: "2%",
    left: "35%",
    [theme.breakpoints.up("md")]: {
      top: "38%",
      left: "10%"
    },
    [theme.breakpoints.up("lg")]: {
      width: 80
    }
  };
});

const StyledBlueRoundCube = styled("img")(({ theme }) => {
  return {
    width: 50,
    position: "absolute",
    zIndex: 1,
    top: "4%",
    right: "27%",
    [theme.breakpoints.up("md")]: {
      top: "unset",
      bottom: "31%",
      left: "15%"
    },
    [theme.breakpoints.up("lg")]: {
      width: 80
    }
  };
});

const StyledOrangeTorus = styled("img")(({ theme }) => {
  return {
    width: 100,
    position: "absolute",
    zIndex: 1,
    top: "3%",
    right: "15%",
    [theme.breakpoints.up("md")]: {
      width: 110,
      top: "unset",
      bottom: "25%",
      left: "21%"
    },
    [theme.breakpoints.up("lg")]: {
      width: 180
    }
  };
});

const StyledBlueShape = styled("img")(({ theme }) => {
  return {
    width: 100,
    position: "absolute",
    zIndex: 1,
    transform: "rotate(-143.36deg)",
    left: "23%",
    top: "25%",
    [theme.breakpoints.up("lg")]: {
      width: 180
    }
  };
});

const StyledOrangeCone = styled("img")(({ theme }) => {
  return {
    width: 65,
    position: "absolute",
    zIndex: 1,
    bottom: "30%",
    left: "5%",
    [theme.breakpoints.up("lg")]: {
      width: 120
    }
  };
});

const StyledBlueTorus = styled("img")(({ theme }) => {
  return {
    width: 120,
    position: "absolute",
    zIndex: 1,
    bottom: 0,
    left: "2%",
    [theme.breakpoints.up("lg")]: {
      width: 180
    }
  };
});

const StyledOrangePieUpper = styled("img")(({ theme }) => {
  return {
    width: 55,
    position: "absolute",
    zIndex: 1,
    transform: "scaleX(-1) rotate(30deg)",
    bottom: "12%",
    left: "25%",
    [theme.breakpoints.up("lg")]: {
      width: 100
    }
  };
});

const StyledOrangePieLower = styled("img")(({ theme }) => {
  return {
    width: 55,
    position: "absolute",
    zIndex: 1,
    transform: "scaleX(-1) rotate(-70deg)",
    bottom: "3%",
    left: "22%",
    [theme.breakpoints.up("lg")]: {
      width: 100
    }
  };
});

const ChangePassword = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [user, loading] = useAuthState(auth);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!user && !loading) {
      navigate(`/${translate.getCurrentLocale()}/`);
      toast.kampai(intl.get("t_toast_warning_login_required"), "warning");
    }
  }, [loading]);

  const schema = yup.object().shape({
    currentPassword: yup.string().required(
      intl.get("t_error_required", {
        field: intl.get("t_general_current_password")
      })
    ),
    newPassword: yup
      .string()
      .notOneOf(
        [yup.ref("currentPassword"), null],
        intl.get("t_general_new_password_not_same_as_current_password")
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_new_password")
        })
      )
  });

  const methods = useForm({
    defaultValues: { currentPassword: "", newPassword: "" },
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control } = methods;

  const handlePasswordChange = async (formData: ChangePasswordForm) => {
    const { currentPassword, newPassword } = formData;
    setShowLoader(true);
    try {
      if (user?.email) {
        const credentials = EmailAuthProvider.credential(
          user.email,
          currentPassword
        );
        await reauthenticateWithCredential(user, credentials);
        if (auth.currentUser) {
          await updatePassword(auth.currentUser, newPassword);
          setShowLoader(false);
          setErrorMessage("");
          toast.kampai(intl.get("t_toast_success_password_change"), "success");
          const continueUrl = searchParams.get("continueUrl") ?? "/";
          navigate(continueUrl);
        } else {
          navigate(`/${translate.getCurrentLocale()}/`);
          toast.kampai(intl.get("t_general_internal_error"), "error");
        }
      } else {
        navigate(`/${translate.getCurrentLocale()}/`);
        toast.kampai(intl.get("t_general_internal_error"), "error");
      }
    } catch (error) {
      setShowLoader(false);
      if (error instanceof FirebaseError) {
        const code = error.code;
        if (code === "auth/weak-password") {
          setErrorMessage(intl.get("t_auth_error_weak_password"));
        } else if (
          code === "auth/user-not-found" ||
          code === "auth/wrong-password"
        ) {
          setErrorMessage(intl.get("t_auth_error_password_not_valid"));
        } else if (code === "auth/too-many-requests") {
          setErrorMessage(intl.get("t_auth_error_password_many_attempt"));
        } else {
          setErrorMessage(intl.get("t_general_internal_error"));
        }
      }
    }
  };

  return (
    <>
      {isShowLoader ? <Loader /> : false}
      <Grid container mb={6}>
        <Grid item xs={12} md={6}>
          <Box
            zIndex={1}
            position="absolute"
            mx={1.25}
            my={2.5}
            left={{ md: "5%", lg: "10%" }}>
            <MuiLink component={Link} to="/">
              <StyledLogo
                variant="square"
                src={Logo_White}
                alt="Tokhimo Jobs Logo"
              />
            </MuiLink>
          </Box>
          {/* Images that display on tablet and above screens */}
          <Box display={{ xs: "none", sm: "block" }}>
            <StyledOrangeShape src={Orange_Shape} alt="Orange Shape" />
            <StyledBlueRoundPieChartShape
              src={Blue_Round_Pie_Chart_Shape}
              alt="Blue Round Pie Chart Shape"
            />
            <StyledOrangeSlice src={Orange_Slice} alt="Orange Slice" />
            <StyledBlueDome src={Blue_Dome} alt="Blue Dome" />
            <StyledBlueBlurCone src={Blue_Blur_Cone} alt=" Blue Blur Cone" />
            <StyledBlueRoundCube
              src={Blue_Blur_Round_Cube}
              alt="Blue Blur Round Cube"
            />
            <StyledOrangeTorus src={Orange_Torus} alt="Orange Torus" />
          </Box>

          {/* Images that only show on desktop screens */}
          <Box display={{ xs: "none", md: "block" }}>
            <StyledBlueShape src={Blue_Shape} alt="Blue Shape" />
            <StyledOrangeCone src={Orange_Cone} alt="Orange Cone" />
            <StyledBlueTorus src={Blue_Torus} alt="Blue Torus" />
            <StyledOrangePieUpper src={Orange_Slice} alt="Orange Pie Upper" />
            <StyledOrangePieLower src={Orange_Slice} alt="Orange Pie Lower" />
          </Box>

          <Box
            position="absolute"
            overflow="hidden"
            top="0"
            left="0"
            maxHeight="100vh"
            maxWidth="50vw"
            display={{ xs: "none", md: "block" }}>
            <StyledLeftGradientImage
              src={Left_Gradient_Blob}
              alt="Left Gradient Blob"
            />
          </Box>
          <Box
            width="100%"
            maxHeight="35vh"
            overflow="hidden"
            display={{ xs: "block", md: "none" }}
            position="absolute">
            <StyledTopGradientImage
              src={Top_Gradient_Blob}
              alt="Forgot Password Page Tablet Background"
              width="100%"
            />
          </Box>
        </Grid>
        <StyledRightContentGridContainer item xs={12} md={4} zIndex={2}>
          <Container>
            <Typography variant="h3" mb={1}>
              {intl.get("t_forgot_password_title")}
            </Typography>
            <Typography
              variant="subtitle3"
              color={colorPalette.black.hover.base}
              my={1.5}>
              {intl.get("t_forgot_password_subtitle")}
            </Typography>
            <br />
            <Typography variant="subtitle4" color={colorPalette.red.base}>
              {errorMessage}
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(handlePasswordChange)}
              mt={1}>
              <TextField
                data-testid="change_password_current_password_input"
                type="password"
                name="currentPassword"
                required
                label={intl.get("t_general_current_password")}
                placeholder={intl.get("t_general_current_password")}
                control={control}
              />
              <TextField
                data-testid="change_password_new_password_input"
                type="password"
                name="newPassword"
                required
                label={intl.get("t_general_new_password")}
                placeholder={intl.get("t_general_new_password")}
                control={control}
              />
              <Button
                data-testid="change_password_submit_button"
                fullWidth
                type="submit">
                {intl.get("t_general_change_password")}
              </Button>
            </Box>
          </Container>
        </StyledRightContentGridContainer>
      </Grid>

      <Box position="absolute" bottom="2rem" left="0" width="100%">
        <Container>
          <Grid container>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={4}>
              <Stack direction="row" justifyContent="center">
                <Typography
                  variant="body2"
                  color={colorPalette.black[80]}
                  mt={2}>
                  {intl.get("t_general_copyright", {
                    year: dayjs().format("YYYY")
                  })}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ChangePassword;
