/**
 * Translated options in key, label format based on provided testKey for the Japanese language
 *
 * @param {string} testKey
 * @return {array} list of options in key, label format
 */

import { useOptions } from "@hooks/useOptions";

import KeyLabel from "@interfaces/components/KeyLabel";

import {
  BJT_SCORE,
  BJT_SCORE_T_LABELS,
  J_TEST_SCORE,
  J_TEST_SCORE_T_LABELS,
  JA_LANGUAGE_TEST,
  JLPT_SCORE,
  JLPT_SCORE_T_LABELS,
  NAT_SCORE,
  NAT_SCORE_T_LABELS
} from "@utils/config";

export const jaTestLevelOptionsFinder = (testKey = ""): Array<KeyLabel> => {
  const { JLPT, NAT, BJT, J_TEST } = JA_LANGUAGE_TEST;

  switch (testKey) {
    case JLPT:
      return useOptions(JLPT_SCORE, JLPT_SCORE_T_LABELS);
    case NAT:
      return useOptions(NAT_SCORE, NAT_SCORE_T_LABELS);
    case BJT:
      return useOptions(BJT_SCORE, BJT_SCORE_T_LABELS);
    case J_TEST:
      return useOptions(J_TEST_SCORE, J_TEST_SCORE_T_LABELS);
    default:
      return [{ key: "", label: "" }];
  }
};
