import { Stack } from "@mui/material";

import SkeletonInput from "@skeletons/SkeletonInput";

import Typography from "@components/Typography";

import { intl } from "@utils/translate";

const SkeletonHiringProcessForm = () => {
  return (
    <>
      <Stack mb={1}>
        <Typography variant="h6">
          {intl.get("t_general_resume_screening")}
        </Typography>
        <Stack rowGap={2} mt={1}>
          <SkeletonInput hasLabel={false} />
          <SkeletonInput hasLabel={false} />
        </Stack>
      </Stack>
      <Stack mb={1}>
        <Typography variant="h6">{intl.get("t_general_interview")}</Typography>
        <Stack rowGap={2} mt={1}>
          <SkeletonInput hasLabel={false} />
          <SkeletonInput hasLabel={false} />
        </Stack>
      </Stack>
      <Stack mb={1}>
        <Typography variant="h6">{intl.get("t_general_offer")}</Typography>
        <Stack rowGap={2} mt={1}>
          <SkeletonInput hasLabel={false} />
          <SkeletonInput hasLabel={false} />
        </Stack>
      </Stack>
    </>
  );
};

export default SkeletonHiringProcessForm;
