import AliceCarousel from "react-alice-carousel";

import { NavigateBefore as NavigateBeforeIcon } from "@mui/icons-material";
import { Box, styled } from "@mui/material";

import IconButton from "@components/IconButton";
import TestimonialCard from "@components/TestimonialCard";

import Blue_Shape from "@assets/images/shapes/Blue_Shape.png";

import "react-alice-carousel/lib/alice-carousel.css";

interface TestimonialCarouselProps {
  testimonialContent: Array<{
    fullName: string;
    jobTitle: string;
    textContent: string;
    profileImg: string;
  }>;
  avatarVariant?: "rounded" | "circular" | "square";
}

const StyledBox = styled(Box)(({ theme }) => ({
  "position": "relative",
  "li.alice-carousel__stage-item :not(.__cloned)": {
    "marginRight": 5,
    "marginLeft": 5,
    ".MuiAvatar-img": {
      margin: 0
    }
  },
  [theme.breakpoints.down("sm")]: {
    ".alice-carousel__next-btn": {
      display: "none"
    },
    ".alice-carousel__prev-btn": {
      display: "none"
    }
  }
}));

const StyledBlueShape = styled("img")(({ theme }) => {
  return {
    position: "absolute",
    width: 110,
    bottom: -90,
    right: 0,
    [theme.breakpoints.up("sm")]: {
      bottom: -50
    },
    [theme.breakpoints.up("md")]: {
      width: 150,
      bottom: -130
    },
    [theme.breakpoints.up("lg")]: {
      right: 150
    }
  };
});

const StyledCarouselButton = styled(IconButton)({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  left: -50
});

const TestimonialCarousel = ({
  testimonialContent,
  avatarVariant = "circular"
}: TestimonialCarouselProps) => {
  return (
    <StyledBox ml={{ sm: 8 }} mt={3.75} mb={{ sm: 12.5 }}>
      <StyledBlueShape src={Blue_Shape} />
      <AliceCarousel
        autoWidth
        mouseTracking
        infinite={true}
        disableDotsControls
        renderPrevButton={() => <div></div>}
        renderNextButton={() => (
          <StyledCarouselButton>
            <NavigateBeforeIcon />
          </StyledCarouselButton>
        )}>
        {testimonialContent.map((singleTestimonialContent, index) => (
          <Box
            width={{ xs: 300, sm: 480 }}
            key={index}
            mt={{ sm: index % 2 != 0 ? 24 : 0 }}>
            <TestimonialCard
              fullName={singleTestimonialContent.fullName}
              jobTitle={singleTestimonialContent.jobTitle}
              textContent={singleTestimonialContent.textContent}
              profileImg={singleTestimonialContent.profileImg}
              avatarVariant={avatarVariant}
            />
          </Box>
        ))}
      </AliceCarousel>
    </StyledBox>
  );
};

export default TestimonialCarousel;
