/**
 * Renders a table of dynamic rows and columns
 *
 * @property columns -  To define table columns.
 *    accessorKey  - the unique key for each column used to map row data with column
 *    header       - the column header/label
 *    cellWidth    - to customize the cell width to be fixed or dynamic. Eg: "10%", "100px"
 *  @example
 *  [
 *   {
 *    accessorKey: "first_name",
 *    header: "First Name",
 *    cellWidth: "20%"
 *   }
 *   ...
 * ]
 *
 * @property data - To define the data that needs to be displayed in table rows.
 * Should be an array of objects containing key-value pairs, where the key should be a valid accessorKey defined in the columns.
 *  @example
 *  [
 *    {
 *     first_name: "Nealson",
 *    },
 *    {
 *     first_name: "John",
 *    }
 *  ]
 *
 * @property cellHeight - To customize the cell height.
 * @example
 * cellHeight = "2rem"
 *
 * @property showRowBorder - To show/hide dividers for each row.
 * @property showTableBottomBorder - To show/hide bottom border of a table to achieve border collapse
 */

import { ReactNode } from "react";

import {
  Table as MuiTable,
  Paper,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";

import Email from "@interfaces/database/Email";

import { colorPalette } from "@utils/theme";

export interface ColumnProps {
  accessorKey:
    | "fullName"
    | "location"
    | "phoneNumber"
    | "email"
    | "year"
    | "month"
    | "education"
    | "workHistory";
  header: string;
  cellWidth?: string;
}

interface TableProps {
  columns: Array<ColumnProps>;
  data: Array<{
    fullName?: string | ReactNode;
    location?: string | ReactNode;
    phoneNumber?: string | ReactNode;
    email?: Email | ReactNode;
    year?: string | ReactNode;
    month?: string | ReactNode;
    education?: string | ReactNode;
    workHistory?: string | ReactNode;
  }>;
  showRowBorder?: boolean;
  showTableBottomBorder?: boolean;
  cellHeight?: string;
}

const Table = ({
  columns,
  data,
  showRowBorder = false,
  showTableBottomBorder = true,
  cellHeight = "2rem"
}: TableProps) => {
  const StyledTable = styled(MuiTable)(({ theme }) => ({
    "& .MuiTable-root": {
      lineHeight: "1.25rem"
    },
    "& .MuiTableRow-head": {
      backgroundColor: colorPalette.blue["04"],
      fontWeight: 500,
      borderBottom: `1px solid ${colorPalette.grey.base}`,
      // FIXME: Update code when font responsiveness is done
      [theme.breakpoints.down("md")]: {
        fontSize: "0.75rem"
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "0.875rem"
      }
    },
    "& .MuiTableRow-root": {
      "td": {
        border: 0,
        boxShadow: 0
      },
      ":not(:last-child) td": {
        boxShadow: showRowBorder
          ? "inset 0px -1px 0px rgba(206, 211, 224, 0.3)"
          : 0
      }
    },
    "& .MuiTableCell-root": {
      padding: "0rem 1rem",
      height: cellHeight,
      // FIXME: Update code when font responsiveness is done
      [theme.breakpoints.down("md")]: {
        fontSize: "0.75rem"
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "0.875rem"
      }
    },
    "& .MuiTableCell-head": {
      height: "2rem",
      whiteSpace: "nowrap"
    }
  }));

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        height: "100%",
        border: `1px solid ${colorPalette.grey.base}`,
        borderBottom: showTableBottomBorder
          ? `1px solid ${colorPalette.grey.base}`
          : 0,
        borderRadius: 0
      }}>
      <StyledTable aria-label="table">
        <TableHead>
          <TableRow>
            {columns?.map((singleColumn) => (
              <TableCell
                key={singleColumn.accessorKey}
                width={singleColumn?.cellWidth}>
                {/* FIXME: Add Typography component when Font responsiveness is updated */}
                {singleColumn.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data?.map((singleRow, index) => (
              <TableRow key={index}>
                {columns?.map((singleColumn) => (
                  <TableCell
                    key={singleColumn.accessorKey}
                    width={singleColumn?.cellWidth}>
                    {singleRow[singleColumn.accessorKey]}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              {columns?.map((singleColumn) => (
                <TableCell
                  key={singleColumn.accessorKey}
                  width={singleColumn?.cellWidth}></TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default Table;
