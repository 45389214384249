import { Stack, styled } from "@mui/material";

import Typography from "@components/Typography";

import { colorPalette } from "@utils/theme";

interface sectionTitleSubTextProps {
  subText: string;
}

const StyledSectionTitleSubText = styled(Stack)({
  wordBreak: "break-word"
});

const SectionTitleSubText = ({ subText }: sectionTitleSubTextProps) => {
  return (
    <StyledSectionTitleSubText>
      <Typography
        variant="subtitle3"
        paragraph={true}
        align="center"
        color={colorPalette.white[80]}
        mt={2.5}
        mb={3}>
        {subText}
      </Typography>
    </StyledSectionTitleSubText>
  );
};

export default SectionTitleSubText;
