import { Box, Divider, Grid, Paper, Stack, styled } from "@mui/material";

import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonCompanyLogo from "@skeletons/SkeletonCompanyLogo";
import SkeletonKeyword from "@skeletons/SkeletonKeyword";
import SkeletonStatus from "@skeletons/SkeletonStatus";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import JobDescriptionIcon from "@components/JobDescriptionIcon";

import { colorPalette } from "@utils/theme";

const StyledPaperHeader = styled(Paper)({
  backgroundColor: colorPalette.lightBlue.background.base,
  boxShadow: `0px 3px 5px -1px ${colorPalette.grey[20]}, 0px 6px 10px ${colorPalette.grey[14]}, 0px 1px 18px ${colorPalette.grey[12]}`,
  borderRadius: "4px 4px 0px 0px",
  padding: "1.5rem"
});

const StyledPaperBody = styled(Paper)({
  padding: "1.5rem",
  borderRadius: "0px 0px 4px 4px",
  backgroundColor: colorPalette.white.base
});

const SkeletonEmployerJobDetailsDrawer = () => {
  return (
    <Paper elevation={0}>
      <StyledPaperHeader>
        <Stack direction="column">
          <Grid
            container
            alignItems="center"
            marginY={1}
            wrap="wrap-reverse"
            rowSpacing={0.5}>
            <Grid item xs={12} md={6}>
              <SkeletonTypography variant="body1" />
            </Grid>
            <Grid item xs={12} sm="auto" ml="auto">
              <SkeletonStatus multiple />
            </Grid>
          </Grid>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            marginY={1}
            flexWrap="wrap"
            justifyItems="stretch">
            <SkeletonCompanyLogo />
            <Stack direction="column" gap={0.5} flexGrow={1}>
              <SkeletonTypography variant="body1" />
              <SkeletonTypography variant="subtitle2" />
              <SkeletonTypography variant="body1" />
            </Stack>
            <SkeletonTypography variant="subtitle2" width="medium" />
          </Stack>
          <Grid container marginY={1} alignItems="center" rowSpacing={0.5}>
            <Grid item xs={12} sm={8}>
              <Stack direction="column" justifyContent="stretch">
                <SkeletonTypography width="medium" />
                <SkeletonTypography />
              </Stack>
            </Grid>
            <Grid item xs={12} md={2} lg={1} ml="auto">
              <SkeletonButton width="full" />
            </Grid>
          </Grid>
        </Stack>
      </StyledPaperHeader>
      <StyledPaperBody elevation={0}>
        <SkeletonTypography variant="subtitle2" />
        <Grid container spacing={2} marginY={0}>
          <Grid item xs={12} md={4} xl={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <JobDescriptionIcon filled icon="edit_document" />
              <Stack direction="column" flexGrow={1}>
                <SkeletonTypography variant="subtitle4" width="medium" />
                <SkeletonTypography />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <JobDescriptionIcon filled icon="currency_yen" />
              <Stack direction="column" flexGrow={1}>
                <SkeletonTypography variant="subtitle4" width="medium" />
                <SkeletonTypography />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <JobDescriptionIcon filled icon="psychology" />
              <Stack direction="column" flexGrow={1}>
                <SkeletonTypography variant="subtitle4" width="medium" />
                <SkeletonTypography />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <JobDescriptionIcon filled icon="schedule" />
              <Stack direction="column" flexGrow={1}>
                <SkeletonTypography variant="subtitle4" width="medium" />
                <SkeletonTypography />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <JobDescriptionIcon filled icon="laptop_mac" />
              <Stack direction="column" flexGrow={1}>
                <SkeletonTypography variant="subtitle4" width="medium" />
                <SkeletonTypography />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <JobDescriptionIcon icon="forum" />
              <Stack direction="column" flexGrow={1}>
                <SkeletonTypography variant="subtitle4" width="medium" />
                <SkeletonTypography />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <JobDescriptionIcon icon="record_voice_over" />
              <Stack direction="column" flexGrow={1}>
                <SkeletonTypography variant="subtitle4" width="medium" />
                <SkeletonTypography />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Box marginY={3}>
          <Divider />
        </Box>
        <SkeletonTypography variant="subtitle2" />
        <SkeletonTypography width="full" multiLine />
        <br />
        <br />
        <SkeletonTypography variant="subtitle2" />
        <SkeletonKeyword multiple />
        <br />
        <br />
        <SkeletonTypography variant="subtitle2" />
        <SkeletonTypography width="full" multiLine />
        <br />
        <br />
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} md={2} lg={1}>
            <SkeletonButton width="full" />
          </Grid>
        </Grid>
      </StyledPaperBody>
    </Paper>
  );
};

export default SkeletonEmployerJobDetailsDrawer;
