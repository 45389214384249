import { useNavigate } from "react-router-dom";

import { Stack } from "@mui/material";

import Button from "@components/Button";
import Typography from "@components/Typography";

import translate, { intl } from "@utils/translate";

interface NoMessagesProps {
  variant?: "employer-conversation" | "candidate-conversation";
}

const NoMessages = ({ variant = "employer-conversation" }: NoMessagesProps) => {
  const navigate = useNavigate();
  return (
    <Stack rowGap={2} height="100%" justifyContent="center" alignItems="center">
      <Typography variant="h2">
        {intl.get("t_conversations_no_messages")}
      </Typography>
      <Typography variant="subtitle3" color="text.secondary" textAlign="center">
        {variant === "employer-conversation"
          ? intl.get("t_conversations_no_messages_description")
          : intl.get("t_conversations_no_messages_candidate_description")}
      </Typography>
      {variant === "employer-conversation" ? (
        <Button
          handleClick={() =>
            navigate(`/${translate.getCurrentLocale()}/employers/search`)
          }>
          {intl.get("t_conversations_no_messages_search_candidates_btn")}
        </Button>
      ) : (
        <Button
          handleClick={() =>
            navigate(`/${translate.getCurrentLocale()}/search`)
          }>
          {intl.get("t_general_find_jobs")}
        </Button>
      )}
    </Stack>
  );
};

export default NoMessages;
