import { Control, Controller } from "react-hook-form";

import { Box, InputLabel } from "@mui/material";
import { CountryCode } from "libphonenumber-js";
import { MuiTelInput } from "mui-tel-input";

import { JP_ALPHA2_CODE } from "@utils/config";
import translate from "@utils/translate";

interface TelephoneInputProps {
  "name": string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  "control": Control<any>;
  "label"?: string;
  "disabled"?: boolean;
  "required"?: boolean;
  "placeholder"?: string;
  "helperText"?: string;
  "defaultCountry"?: CountryCode;
  "data-testid"?: string;
}

const TelephoneInput = ({
  name,
  control,
  label,
  disabled = false,
  required = false,
  placeholder = "",
  helperText = "",
  defaultCountry = JP_ALPHA2_CODE,
  "data-testid": dataTestId
}: TelephoneInputProps) => {
  const locale = translate.getCurrentLocaleShort();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box mb={2}>
          {label ? (
            <InputLabel
              htmlFor={name}
              required={required}
              disabled={disabled}
              sx={{ mb: 1.5 }}>
              {label}
            </InputLabel>
          ) : (
            false
          )}
          <MuiTelInput
            data-testid={dataTestId}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            defaultCountry={defaultCountry}
            focusOnSelectCountry
            disableFormatting
            langOfCountryName={locale}
            flagSize="small"
            onChange={(e) => {
              onChange(e);
            }}
            error={!!error}
            helperText={error ? error.message : helperText}
            fullWidth
          />
        </Box>
      )}
    />
  );
};

export default TelephoneInput;
