import { ReactNode } from "react";

import { Stack } from "@mui/material";

import Typography from "@components/Typography";

interface TagProps {
  "label": string;
  "startAdornment"?: ReactNode;
  "endAdornment"?: ReactNode;
  "data-testid"?: string;
}

const Tag = ({
  label,
  startAdornment,
  endAdornment,
  "data-testid": dataTestId
}: TagProps) => {
  return (
    <Stack
      data-testid={dataTestId}
      display="inline-flex"
      direction="row"
      padding="0.5rem 0.75rem"
      borderRadius="2px"
      alignItems="center"
      gap={1}
      bgcolor="info.light"
      color="info.main">
      <Typography variant="subtitle5" position="relative" top="0.2rem">
        {startAdornment}
      </Typography>
      <Typography variant="subtitle5" data-testid="tag_item">
        {label}
      </Typography>
      {/* Not Really sure why but to adjust this pixel value and make center, we need to give this */}
      <Typography variant="subtitle5" position="relative" top="0.2rem">
        {endAdornment}
      </Typography>
    </Stack>
  );
};

export default Tag;
