import { matchPath, Outlet, useLocation } from "react-router-dom";

import RequireAuth from "@utils/components/RequireAuth";
import { USER_TYPE } from "@utils/config";
import translate from "@utils/translate";

const EmployersApp = () => {
  const { pathname: originalPathname } = useLocation();
  const pathname = translate.getNonLocalizedURL(originalPathname);
  const isEmployersHomepage = matchPath("/employers", pathname);

  if (isEmployersHomepage) {
    return <Outlet />;
  } else {
    return (
      <RequireAuth
        accessRestriction={[USER_TYPE.COMPANY, USER_TYPE.COMPANY_MANAGER]}
      />
    );
  }
};

export default EmployersApp;
