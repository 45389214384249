// NOTE: any change done here needs to be done in Candidate Notification(CandidateNotification.tsx) as well
import { useEffect, useState } from "react";

import intl from "react-intl-universal";
import { useNavigate } from "react-router-dom";

import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tab,
  useMediaQuery
} from "@mui/material";
import {
  and,
  collection,
  doc,
  orderBy,
  query,
  updateDoc,
  where
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection } from "react-firebase-hooks/firestore";
import { useHttpsCallable } from "react-firebase-hooks/functions";

import SkeletonNotificationBlock from "@skeletons/SkeletonNotificationBlock";

import Button from "@components/Button";
import Cursor from "@components/Cursor";
import NotificationBlock from "@components/NotificationBlock";
import { TabPanel, Tabs } from "@components/Tab";
import Typography from "@components/Typography";

import useToast from "@hooks/useToast";

import Notification from "@interfaces/database/Notification";

import { FIRESTORE_COLLECTIONS, NOTIFICATION_STATUS } from "@utils/config";
import { auth, db, functions } from "@utils/firebase";
import { resolveMultiLingual } from "@utils/multiLingual";
import theme from "@utils/theme";
import translate from "@utils/translate";

const TAB_VALUE_ALL_NOTIFICATION = 0;
const TAB_VALUE_UNREAD_NOTIFICATION = 1;
interface EmployerNotificationsProps {
  renderAsPage?: boolean;
}

const EmployerNotifications = ({
  renderAsPage = true
}: EmployerNotificationsProps) => {
  const [user] = useAuthState(auth);
  const [
    markAllNotificationAsRead,
    isMarkAllNotificationAsReadLoading,
    markAllNotificationAsReadError
  ] = useHttpsCallable(functions, "markAllNotificationAsRead");
  const [activeTabValue, setActiveTabValue] = useState<number>(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const toast = useToast();

  const notificationCollection = collection(
    db,
    FIRESTORE_COLLECTIONS.NOTIFICATIONS
  );
  const [notificationsData, notificationsDataLoading, notificationsDataError] =
    user?.uid
      ? useCollection(
          query(
            notificationCollection,
            and(
              where("userId", "==", user?.uid),
              ...(activeTabValue === TAB_VALUE_UNREAD_NOTIFICATION
                ? [where("status", "==", NOTIFICATION_STATUS.UNREAD)]
                : [
                    where("status", "in", [
                      NOTIFICATION_STATUS.UNREAD,
                      NOTIFICATION_STATUS.READ
                    ])
                  ])
            ),
            orderBy("updated_at", "desc")
          )
        )
      : [undefined, false, undefined];

  useEffect(() => {
    if (!isSmallScreen && renderAsPage) {
      navigate("/");
    }
  }, [isSmallScreen, renderAsPage]);

  useEffect(() => {
    if (markAllNotificationAsReadError || notificationsDataError) {
      toast.kampai("t_toast_error_something_wrong", "error");
    }
  }, [markAllNotificationAsReadError, notificationsDataError]);

  const handleTabChange = (newValue: number) => {
    setActiveTabValue(newValue);
  };

  const handleNotificationStatus = async (
    notificationId: string,
    status: typeof NOTIFICATION_STATUS[keyof typeof NOTIFICATION_STATUS]
  ) => {
    try {
      const notificationCollectionRef = doc(
        db,
        `${FIRESTORE_COLLECTIONS.NOTIFICATIONS}/${notificationId}`
      );
      await updateDoc(notificationCollectionRef, {
        status
      });
    } catch (err) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  const NoNotifications = () => {
    return (
      <Stack
        height={isSmallScreen ? "calc(100vh - 10.5rem)" : "calc(100vh - 12rem)"}
        maxHeight={isSmallScreen ? "calc(100vh - 10.5rem)" : "29.37rem"}
        justifyContent="center"
        alignItems="center">
        <Typography variant="body1" color="text.secondary">
          {intl.get("t_notification_no_notifications")}
        </Typography>
      </Stack>
    );
  };

  return (
    <Box>
      <Stack
        px={2}
        direction="row"
        justifyContent="space-between"
        mt={2}
        mb={1}>
        <Typography variant="subtitle2">
          {intl.get("t_general_notifications")}
        </Typography>
        {isSmallScreen ? (
          <IconButton onClick={() => navigate(-1)}>
            <CloseIcon />
          </IconButton>
        ) : (
          false
        )}
      </Stack>
      <Tabs
        value={activeTabValue}
        aria-label="applications-table"
        variant="fullWidth">
        <Tab
          sx={{ p: 2 }}
          value={TAB_VALUE_ALL_NOTIFICATION}
          key={TAB_VALUE_ALL_NOTIFICATION}
          onClick={() => handleTabChange(TAB_VALUE_ALL_NOTIFICATION)}
          label={intl.get("t_notification_all_notification")}
          className={
            activeTabValue === TAB_VALUE_ALL_NOTIFICATION ? "Mui-selected" : ""
          }
        />
        <Tab
          sx={{ p: 2 }}
          value={TAB_VALUE_UNREAD_NOTIFICATION}
          key={TAB_VALUE_UNREAD_NOTIFICATION}
          onClick={() => handleTabChange(TAB_VALUE_UNREAD_NOTIFICATION)}
          label={intl.get("t_notification_unread_notification")}
          className={
            activeTabValue === TAB_VALUE_UNREAD_NOTIFICATION
              ? "Mui-selected"
              : ""
          }
        />
      </Tabs>

      <Divider />

      <Box
        width="100%"
        height={isSmallScreen ? "calc(100vh - 10.5rem)" : "calc(100vh - 12rem)"}
        maxHeight={isSmallScreen ? "calc(100vh - 10.5rem)" : "29.37rem"}
        overflow="auto">
        <TabPanel
          value={activeTabValue}
          index={TAB_VALUE_ALL_NOTIFICATION}
          key={TAB_VALUE_ALL_NOTIFICATION}>
          {notificationsDataLoading ? (
            <>
              {[...Array(6)].map((_, idx) => (
                <SkeletonNotificationBlock key={idx} />
              ))}
            </>
          ) : (
            <>
              {notificationsData?.docs.length === 0 ? (
                <NoNotifications />
              ) : (
                <>
                  {notificationsData?.docs.map((singleNotification) => {
                    const notificationData =
                      singleNotification.data() as Notification;
                    const optionList = [
                      {
                        label: intl.get(
                          notificationData.status === NOTIFICATION_STATUS.READ
                            ? "t_notification_mark_as_unread"
                            : "t_notification_mark_as_read"
                        ),
                        onClick: (notificationId: string) =>
                          handleNotificationStatus(
                            notificationId,
                            NOTIFICATION_STATUS[
                              notificationData.status ===
                              NOTIFICATION_STATUS.READ
                                ? "UNREAD"
                                : "READ"
                            ]
                          )
                      },
                      {
                        label: intl.get("t_notification_deleted_notification"),
                        onClick: (notificationId: string) =>
                          handleNotificationStatus(
                            notificationId,
                            NOTIFICATION_STATUS.DELETED
                          )
                      }
                    ];
                    return (
                      <Cursor
                        key={singleNotification.id}
                        type={notificationData.link ? "pointer" : "auto"}>
                        <NotificationBlock
                          notificationId={singleNotification.id}
                          title={
                            resolveMultiLingual(notificationData.title) ?? ""
                          }
                          notificationType={notificationData.type}
                          description={
                            resolveMultiLingual(notificationData.body) ?? ""
                          }
                          timestamp={notificationData.updated_at.toDate()}
                          optionList={optionList}
                          isNotificationRead={
                            notificationData.status === NOTIFICATION_STATUS.READ
                          }
                          handleClick={() => {
                            if (
                              notificationData.status ===
                              NOTIFICATION_STATUS.UNREAD
                            ) {
                              handleNotificationStatus(
                                singleNotification.id,
                                NOTIFICATION_STATUS.READ
                              );
                            }
                            notificationData.link
                              ? navigate(
                                  notificationData.link.replace(
                                    "{$locale}",
                                    `${translate.getCurrentLocale()}`
                                  )
                                )
                              : "";
                          }}
                        />
                      </Cursor>
                    );
                  })}
                </>
              )}
            </>
          )}
        </TabPanel>
        <TabPanel
          value={activeTabValue}
          index={TAB_VALUE_UNREAD_NOTIFICATION}
          key={TAB_VALUE_UNREAD_NOTIFICATION}>
          {notificationsDataLoading ? (
            <>
              {[...Array(6)].map((_, idx) => (
                <SkeletonNotificationBlock key={idx} />
              ))}
            </>
          ) : (
            <>
              {notificationsData?.docs?.length === 0 ? (
                <NoNotifications />
              ) : (
                <>
                  {notificationsData?.docs.map((singleNotification) => {
                    const notificationData =
                      singleNotification.data() as Notification;
                    const optionList = [
                      {
                        label: intl.get(
                          notificationData.status === NOTIFICATION_STATUS.READ
                            ? "t_notification_mark_as_unread"
                            : "t_notification_mark_as_read"
                        ),
                        onClick: (notificationId: string) =>
                          handleNotificationStatus(
                            notificationId,
                            NOTIFICATION_STATUS[
                              notificationData.status ===
                              NOTIFICATION_STATUS.READ
                                ? "UNREAD"
                                : "READ"
                            ]
                          )
                      },
                      {
                        label: intl.get("t_notification_deleted_notification"),
                        onClick: (notificationId: string) =>
                          handleNotificationStatus(
                            notificationId,
                            NOTIFICATION_STATUS.DELETED
                          )
                      }
                    ];
                    return (
                      <Cursor
                        key={singleNotification.id}
                        type={notificationData.link ? "pointer" : "auto"}>
                        <NotificationBlock
                          notificationId={singleNotification.id}
                          title={
                            resolveMultiLingual(notificationData.title) ?? ""
                          }
                          notificationType={notificationData.type}
                          description={
                            resolveMultiLingual(notificationData.body) ?? ""
                          }
                          timestamp={notificationData.updated_at.toDate()}
                          optionList={optionList}
                          isNotificationRead={
                            notificationData.status === NOTIFICATION_STATUS.READ
                          }
                          handleClick={() => {
                            if (
                              notificationData.status ===
                              NOTIFICATION_STATUS.UNREAD
                            ) {
                              handleNotificationStatus(
                                singleNotification.id,
                                NOTIFICATION_STATUS.READ
                              );
                            }
                            notificationData.link
                              ? navigate(
                                  notificationData.link.replace(
                                    "{$locale}",
                                    `${translate.getCurrentLocale()}`
                                  )
                                )
                              : "";
                          }}
                        />
                      </Cursor>
                    );
                  })}
                </>
              )}
            </>
          )}
        </TabPanel>
      </Box>
      <Divider />
      <Box p={2} sx={{ cursor: "pointer" }} width="max-content">
        <Button
          handleClick={() => {
            if (notificationsData && notificationsData.docs.length > 0) {
              markAllNotificationAsRead();
            }
          }}
          variant="text"
          loading={isMarkAllNotificationAsReadLoading}>
          {intl.get("t_notification_mark_all_as_read")}
        </Button>
      </Box>
    </Box>
  );
};

export default EmployerNotifications;
