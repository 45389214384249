import { Grid, Stack } from "@mui/material";

import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonInput from "@skeletons/SkeletonInput";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Paper from "@components/Paper";
import Typography from "@components/Typography";

import { intl } from "@utils/translate";

const SkeletonJobDetailForm = () => {
  return (
    <Paper>
      <Typography variant="h3" mb={2.5}>
        {intl.get("t_resumes_job_detail_title")}
      </Typography>
      <br />
      <Typography variant="body1" color="text.secondary" mb={3}>
        {intl.get("t_resumes_add_job_detail_form_subtitle")}
      </Typography>

      <Grid container columnSpacing={3} alignItems="center">
        <Grid item xs={12}>
          <SkeletonInput />
        </Grid>
        <Grid item xs={12} md={6}>
          <SkeletonInput />
        </Grid>
        <Grid item xs={12} md={6}>
          <SkeletonInput />
        </Grid>
        <Grid item xs={12} md={6}>
          <SkeletonInput />
        </Grid>
        <Grid item xs={12} md={6}>
          <SkeletonInput />
        </Grid>
        <Grid item xs={12} mb={2}>
          <SkeletonTypography width="medium" />
        </Grid>
        <Grid item xs={12} md={6}>
          <SkeletonInput />
        </Grid>
        <Grid item xs={12} md={6}>
          <SkeletonInput />
        </Grid>
        <Grid item xs={12}>
          <SkeletonInput />
        </Grid>
      </Grid>

      <Stack justifyContent="space-between" direction="row" mt={5.5}>
        <SkeletonButton />
        <SkeletonButton />
      </Stack>
    </Paper>
  );
};

export default SkeletonJobDetailForm;
