import { Box, Stack } from "@mui/material";

import SkeletonCompanyInfo from "@skeletons/SkeletonCompanyInfo";
import SkeletonJobCategory from "@skeletons/SkeletonJobCategory";
import SkeletonStatus from "@skeletons/SkeletonStatus";
import SkeletonTypography from "@skeletons/SkeletonTypography";

const SkeletonJobCard = () => {
  return (
    <Box p={2} borderRadius={1} bgcolor="common.white">
      <SkeletonStatus multiple />
      <SkeletonCompanyInfo />
      <SkeletonTypography multiLine width="full" />
      <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap" mt={1}>
        <SkeletonJobCategory multiple />
        <SkeletonTypography variant="body2" ml="auto" />
      </Stack>
    </Box>
  );
};

export default SkeletonJobCard;
