import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable
} from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

import { ENVIRONMENT, LOCALE, LOCALE_SHORT } from "@utils/config";
import firebaseConfig from "@utils/firebase/config";
import translate from "@utils/translate";

declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

const app = initializeApp(firebaseConfig);

// No need of loading recaptcha when the app is exported as static site.
const currentPort = window.location.port;
if (currentPort !== process.env.REACT_APP_STATIC_EXPORT_PORT) {
  try {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN =
      process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN ?? false;

    initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(
        process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ""
      )
    });
  } catch (e) {
    // When Firebase is not able to verify Device, it will throw error.
    // But this should not stop execution.
  }
}

const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

if (process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.LOCAL) {
  connectAuthEmulator(auth, "http://127.0.0.1:9099/", {
    disableWarnings: true
  });
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectStorageEmulator(storage, "127.0.0.1", 9199);
}

const envOptions = {
  ...app.options,
  isEmulator: false,
  fireStoreEmulatorHost: "",
  storageProdHost: "",
  storageHost: "",
  frontendUrl: ""
};

httpsCallable(functions, "ping")
  .call({})
  .then((response: { data: unknown }) => {
    if (typeof response?.data === typeof {}) {
      const response_data = response.data as {
        emulator_mode: string;
        firestore_emulator_host: string;
        storage_host: string;
        frontend_url: string;
      };
      if (response_data.emulator_mode === "true") {
        envOptions.isEmulator = true;
        envOptions.fireStoreEmulatorHost =
          response_data.firestore_emulator_host;
      }
      envOptions.frontendUrl = response_data.frontend_url;
      envOptions.storageHost = response_data.storage_host;
    }
  });

const setAuthLocale = () => {
  const currentLocale = translate.getCurrentLocale();
  switch (currentLocale) {
    case LOCALE.EN:
      auth.languageCode = LOCALE_SHORT.EN;
      break;
    case LOCALE.JA:
      auth.languageCode = LOCALE_SHORT.JA;
      break;
    default:
      auth.languageCode = LOCALE_SHORT.EN;
      break;
  }
};

export { auth, analytics, db, functions, storage, envOptions, setAuthLocale };
