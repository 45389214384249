//Note: This code is kept for future reference

import Typography from "@components/Typography";

// import { FormEvent, useState } from "react";

// import { intl } from "@utils/translate";

// import {
//   Box,
//   FormControl,
//   FormControlLabel,
//   Radio,
//   RadioGroup,
//   TextField
// } from "@mui/material";

// import Button from "@components/Button";
// import CandidateResumeDrawer from "@components/CandidateResumeDrawer";
// import Drawer from "@components/Drawer";

// import useToast from "@hooks/useToast";

// const ViewResume = () => {
// const toast = useToast();

// const [userId, setUserId] = useState<string>("bhBh8wXNNlZ4VzMfxDqEWapfaSJ3");
// const [resumePurchaseStatus, setResumePurchaseStatus] =
//   useState<boolean>(true);
// const [isDrawerOpen, setIsDrawerOpen] = useState(false);

// const handleSubmit = (e: FormEvent) => {
//   e.preventDefault();
// };

// const handleError = () => {
//   setIsDrawerOpen(false);
//   toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
// };

// return (
// <Box component="form" onSubmit={handleSubmit}>
//   <FormControl fullWidth sx={{ my: 2 }}>
//     <TextField
//       label="User ID"
//       variant="outlined"
//       value={userId}
//       onChange={(e) => setUserId(e.target.value)}
//       required
//     />
//   </FormControl>
//   <FormControl component="fieldset" fullWidth sx={{ my: 2 }}>
//     <RadioGroup
//       aria-label="isPurchased"
//       name="isPurchased"
//       value={resumePurchaseStatus}
//       onChange={(e) => setResumePurchaseStatus(e.target.value === "true")}>
//       <FormControlLabel
//         value="true"
//         control={<Radio />}
//         label="Purchased"
//       />
//       <FormControlLabel
//         value="false"
//         control={<Radio />}
//         label="Not Purchased"
//       />
//     </RadioGroup>
//   </FormControl>

//   <Box my={2} zIndex={100}>
//     <Drawer
//       initiator={
//         <Button type="submit" variant="contained" color="primary">
//           View Resume
//         </Button>
//       }
//       drawerContent={
//         <CandidateResumeDrawer
//           userId={userId}
//           isResumePurchased={resumePurchaseStatus}
//           handleError={handleError}
//         />
//       }
//       isDrawerOpen={isDrawerOpen}
//       setIsDrawerOpen={setIsDrawerOpen}
//     />
//   </Box>
// </Box>
//   );
// };

// export default ViewResume;

const ViewResume = () => {
  return <Typography variant="body1">Code no longer maintained!</Typography>;
};

export default ViewResume;
