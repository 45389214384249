import { ReactIntlUniversal } from "react-intl-universal";

import { DialogActions, DialogContent, Stack } from "@mui/material";
import dayjs from "dayjs";

import ActionButton from "@components/ActionButton";
import Button from "@components/Button";
import Dialog from "@components/Dialog";
import InformationField from "@components/InformationField";
import Typography from "@components/Typography";
import Well from "@components/Well";

import Location from "@interfaces/database/Location";

import {
  DIALOG_ACTION,
  JOB_CONTRACT_TYPE,
  JOB_CONTRACT_TYPE_T_LABELS,
  LOCALE_SHORT
} from "@utils/config";
import { getFullLocation } from "@utils/location";
import translate, { intl as intlGlobal } from "@utils/translate";

interface JobDescriptionCardProps {
  companyName?: string;
  contractType?: typeof JOB_CONTRACT_TYPE[keyof typeof JOB_CONTRACT_TYPE];
  jobTitle?: string;
  location?: Location;
  startDate?: Date;
  endDate?: Date;
  responsibilities?: string;
  handleEdit?: () => void;
  handleDelete?: () => void;
  mode?: "edit" | "view";
  intl?: typeof intlGlobal | ReactIntlUniversal;
}

const JobDescriptionCard = ({
  companyName,
  contractType,
  jobTitle,
  location,
  startDate,
  endDate,
  responsibilities,
  handleEdit,
  handleDelete,
  mode = "view",
  intl = intlGlobal
}: JobDescriptionCardProps) => {
  const userContractType = contractType
    ? `, ${intl.get(JOB_CONTRACT_TYPE_T_LABELS[contractType])}`
    : "";

  const userLocation = getFullLocation(
    location?.city,
    location?.country,
    false,
    translate.getLocaleShort(intl.getInitOptions().currentLocale)
  );

  const handleClose = (reason: keyof typeof DIALOG_ACTION) => {
    if (reason === DIALOG_ACTION.AGREE && handleDelete) {
      handleDelete();
    }
  };

  return (
    <Well showBorder={mode === "edit" ? true : false}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height={40}
        width="100%">
        {/* Company Name, Contract Type*/}
        <InformationField
          icon="apartment"
          label={`${companyName}${userContractType}`}
          variant="subtitle4"
        />

        {/* Actions */}
        {mode === "edit" ? (
          <Stack direction="row" alignItems="flex-start">
            <ActionButton icon="edit" color="blue" handleAction={handleEdit} />
            <Dialog
              title={intlGlobal.get("t_resumes_remove_job_detail")}
              maxWidth="sm"
              initiator={<ActionButton icon="delete" color="grey" />}
              onClose={handleClose}>
              {(handleAgree, handleCancel) => (
                <>
                  <DialogContent sx={{ py: 1 }}>
                    <Typography color="text.secondary">
                      {intlGlobal.get("t_resumes_dialog_delete_title", {
                        entry: intlGlobal.get("t_resumes_job_detail_title")
                      })}
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button handleClick={handleCancel} variant="outlined">
                      {intlGlobal.get("t_resumes_dialog_delete_cancel_button")}
                    </Button>
                    <Button handleClick={handleAgree}>
                      {intlGlobal.get("t_resumes_dialog_delete_agree_button")}
                    </Button>
                  </DialogActions>
                </>
              )}
            </Dialog>
          </Stack>
        ) : (
          false
        )}
      </Stack>

      {/* Job Title, Location */}
      <Stack spacing={0.5} width="100%" textTransform="capitalize">
        <InformationField icon="work" label={jobTitle} variant="subtitle4" />
        <InformationField
          icon="pin_drop"
          label={userLocation}
          variant="subtitle4"
        />
        <Typography variant="body1">
          {startDate ? (
            <>
              {dayjs(startDate).format(
                translate.getLocaleShort(
                  intl.getInitOptions().currentLocale
                ) === LOCALE_SHORT.JA
                  ? "YYYY年M月"
                  : "MMMM YYYY"
              )}
              {endDate
                ? ` - ${dayjs(endDate).format(
                    translate.getLocaleShort(
                      intl.getInitOptions().currentLocale
                    ) === LOCALE_SHORT.JA
                      ? "YYYY年M月"
                      : "MMMM YYYY"
                  )}`
                : ` - ${intl.get(
                    "t_resumes_job_description_job_end_date_till_date"
                  )}`}
            </>
          ) : (
            ""
          )}
        </Typography>
      </Stack>

      {/* Responsibility */}
      <Typography variant="subtitle4" mt={1} mb={1}>
        {intl.get("t_resumes_job_description_subtitle")}
      </Typography>
      <Typography variant="body1" mb={1} whiteSpace="pre-line">
        {responsibilities}
      </Typography>
    </Well>
  );
};

export default JobDescriptionCard;
