import { ReactNode } from "react";

import { Badge, Tab as MuiTab } from "@mui/material";

interface CustomTabProps {
  "value": number;
  "children": ReactNode;
  "changeHandler": (newValue: number) => void;
  "showBadge"?: boolean;
  "isActive"?: boolean;
  "disabled"?: boolean;
  "data-testid"?: string;
}

const Tab = ({
  value,
  children,
  showBadge = false,
  changeHandler,
  isActive = false,
  disabled = false,
  "data-testid": dataTestId
}: CustomTabProps) => {
  return (
    <MuiTab
      data-testid={dataTestId}
      disabled={disabled}
      onClick={() => changeHandler(value)}
      className={isActive ? "Mui-selected" : ""}
      label={
        showBadge ? (
          <Badge color="secondary" variant="dot">
            {children}
          </Badge>
        ) : (
          children
        )
      }
    />
  );
};

export default Tab;
