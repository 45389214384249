import { Box, Divider, Stack } from "@mui/material";

import SkeletonAvatar from "@skeletons/SkeletonAvatar";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import { colorPalette } from "@utils/theme";

interface SkeletonConversationContainerHeaderProps {
  avatarVariant?: "circular" | "rounded";
  variant?: "employer-conversation-header" | "candidate-conversation-header";
}

const SkeletonConversationContainerHeader = ({
  variant = "employer-conversation-header",
  avatarVariant = "circular"
}: SkeletonConversationContainerHeaderProps) => {
  return (
    <Box position="sticky" top={0} mb={1} bgcolor="background.default">
      <Stack
        width="100%"
        direction="row"
        columnGap={1}
        alignItems="center"
        p={variant === "employer-conversation-header" ? 1.5 : 1.75}>
        <Box>
          <SkeletonAvatar size="medium" variant={avatarVariant} />
        </Box>
        <Stack width={{ xs: "100%" }} maxWidth={130}>
          <SkeletonTypography variant="subtitle4" color="text.primary" />
          {variant === "employer-conversation-header" ? (
            <>
              <Box display={{ xs: "none", md: "block" }}>
                <SkeletonTypography variant="body2" />
              </Box>
              <Box display={{ xs: "block", md: "none" }} width="100%">
                <SkeletonTypography variant="body2" />
              </Box>
            </>
          ) : (
            false
          )}
        </Stack>
        {variant === "employer-conversation-header" ? (
          <>
            <Box
              borderLeft={`1px solid ${colorPalette.grey.base}`}
              height={44}
              mr={2}
              display={{ xs: "none", md: "block" }}
            />
            <Box display={{ xs: "none", md: "block" }} width="100%">
              <SkeletonTypography variant="body2" />
            </Box>
          </>
        ) : (
          false
        )}
      </Stack>
      <Divider />
    </Box>
  );
};

export default SkeletonConversationContainerHeader;
