import dayjs from "dayjs";

import Timestamp from "@utils/Timestamp";

interface JSONDate {
  seconds: number;
  milliseconds: number;
}

/**
 * Accepts 2 dates and return their difference in no. of days
 *
 * @param {Timestamp | Date | JSONDate} startDate  This should be the one in later timestamp.
 * @param {Timestamp | Date | JSONDate} endDate  This should be the one in earlier timestamp.
 * @return {number} Difference of startDate and endDate. If +ve, startDate is before than endDate.
 */

const getDifferenceInDays = (
  startDate: Timestamp | Date | JSONDate,
  endDate: Timestamp | Date | JSONDate
) => {
  let startObject: Timestamp;
  let endObject: Timestamp;

  if (startDate instanceof Timestamp) {
    startObject = startDate;
  } else if (startDate instanceof Date) {
    startObject = Timestamp.fromDate(startDate);
  } else {
    startObject = Timestamp.fromMillis(
      startDate.seconds * 1000 + startDate.milliseconds
    );
  }

  if (endDate instanceof Timestamp) {
    endObject = endDate;
  } else if (endDate instanceof Date) {
    endObject = Timestamp.fromDate(endDate);
  } else {
    endObject = Timestamp.fromMillis(
      endDate.seconds * 1000 + endDate.milliseconds
    );
  }

  const firstDate = dayjs(startObject.toDate()).startOf("day");
  const secondDate = dayjs(endObject.toDate()).startOf("day");

  return firstDate.diff(secondDate, "days");
};

/**
 * Accepts a date and returns how many number of days was it ago the current date
 *
 * @param {date} Timestamp | Date | JSON
 * @return {number} Difference of date and current date
 */

const getDaysAgo = (date: Timestamp | Date | JSONDate) => {
  return getDifferenceInDays(new Date(), date);
};

export { getDifferenceInDays, getDaysAgo };
