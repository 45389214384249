import { City } from "country-state-city";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import jaLocale from "i18n-iso-countries/langs/ja.json";

import KeyLabel from "@interfaces/components/KeyLabel";

import { LOCALE, LOCALE_SHORT } from "@utils/config";
import translate from "@utils/translate";

countries.registerLocale(enLocale);
countries.registerLocale(jaLocale);

const getCountryName = (
  countryCode: string,
  locale = translate.getCurrentLocaleShort()
): string => {
  return countries.getName(countryCode, locale, { select: "official" }) ?? "";
};

const getCountryCode = (countryName: string): string => {
  const locale = translate.getCurrentLocaleShort();
  return countries.getAlpha3Code(countryName, locale) ?? "";
};

const isValidCountryCode = (countryCode: string) => {
  return countries.isValid(countryCode);
};

const isValidCountryName = (countryName: string) => {
  const locale = translate.getCurrentLocaleShort();
  const countryAlpha3 = countries.getAlpha3Code(countryName, locale);
  return countryAlpha3 ? true : false;
};

// This function is specifically to get the list of countries for country selection dropdown.
const getDropdownCountryList = (): Array<KeyLabel> => {
  const allCountryAlpha3 = countries.getAlpha3Codes();
  const countryList = Object.keys(allCountryAlpha3).map((singleAlpha3) => {
    const enName = countries.getName(singleAlpha3, LOCALE_SHORT.EN, {
      select: "official"
    });
    let localeName = "";
    if (translate.getCurrentLocale() !== LOCALE.EN) {
      switch (translate.getCurrentLocale()) {
        case LOCALE.JA:
          localeName =
            countries.getName(singleAlpha3, LOCALE_SHORT.JA, {
              select: "official"
            }) ?? "";
          break;
      }
    }
    return {
      key: singleAlpha3,
      label: enName + (localeName ? ` (${localeName})` : "")
    };
  });
  return countryList;
};

const getDropdownCityList = (
  alpha3Code: string
): Array<{ key: string; label: string }> => {
  const alpha2Code = countries.alpha3ToAlpha2(alpha3Code);
  if (alpha2Code) {
    const cityList = City.getCitiesOfCountry(alpha2Code)?.map((singleCity) => ({
      key: singleCity.name,
      label: singleCity.name
    }));
    return cityList?.length ? cityList : [];
  }
  return [];
};

const getFullLocation = (
  city?: string,
  country?: string,
  showCountryFirst = false,
  locale?: typeof LOCALE_SHORT[keyof typeof LOCALE_SHORT]
) => {
  const countryData = country ? `${getCountryName(country, locale)}` : "";
  const cityData = city ? `${city}` : "";
  const addComma = countryData !== "" && cityData !== "" ? ", " : "";

  const location = showCountryFirst
    ? `${countryData}${addComma}${cityData}`
    : `${cityData}${addComma}${countryData}`;

  return location.trim();
};

export default countries;
export {
  getCountryName,
  getCountryCode,
  getDropdownCountryList,
  getDropdownCityList,
  isValidCountryCode,
  isValidCountryName,
  getFullLocation
};
