import { RouterProvider } from "react-router-dom";

import { createRoot } from "react-dom/client";

import router from "@utils/router";

import reportWebVitals from "@src/reportWebVitals";

const rootElement = document.getElementById("root") as HTMLElement;
if (rootElement.hasChildNodes()) {
  rootElement.innerHTML = "";
}
createRoot(rootElement).render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
