import { ReactIntlUniversal } from "react-intl-universal";

import {
  DialogActions,
  DialogContent,
  Link,
  Stack,
  styled
} from "@mui/material";
import dayjs from "dayjs";

import ActionButton from "@components/ActionButton";
import Button from "@components/Button";
import Dialog from "@components/Dialog";
import Typography from "@components/Typography";
import Well from "@components/Well";

import { DIALOG_ACTION, LOCALE_SHORT } from "@utils/config";
import translate, { intl as intlGlobal } from "@utils/translate";

interface CertificationCardProps {
  title: string;
  issuingOrganization: string;
  description?: string;
  certificationUrl?: string;
  issueDate: Date;
  expiryDate?: Date;
  handleEdit?: () => void;
  handleDelete?: () => void;
  mode?: "edit" | "view";
  intl?: typeof intlGlobal | ReactIntlUniversal;
}

const StyledTypography = styled(Typography)({
  wordBreak: "break-all"
});

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: "1rem",
  fontWeight: "400",
  textDecoration: "underline"
}));

const CertificationCard = ({
  title,
  issuingOrganization,
  description,
  certificationUrl,
  issueDate,
  expiryDate,
  handleEdit,
  handleDelete,
  mode = "view",
  intl = intlGlobal
}: CertificationCardProps) => {
  const handleClose = (reason: keyof typeof DIALOG_ACTION) => {
    if (reason === DIALOG_ACTION.AGREE && handleDelete) {
      handleDelete();
    }
  };

  return (
    <Well showBorder={mode === "edit" ? true : false}>
      {/* Title */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
        height={40}
        width="100%">
        <StyledTypography variant="subtitle4">{title ?? "-"}</StyledTypography>

        {/* Actions */}
        {mode === "edit" ? (
          <Stack direction="row">
            <ActionButton icon="edit" color="blue" handleAction={handleEdit} />
            <Dialog
              title={intlGlobal.get("t_resumes_remove_certification")}
              maxWidth="sm"
              initiator={<ActionButton icon="delete" color="grey" />}
              onClose={handleClose}>
              {(handleAgree, handleCancel) => (
                <>
                  <DialogContent sx={{ py: 1 }}>
                    <Typography color="text.secondary">
                      {intlGlobal.get("t_resumes_dialog_delete_title", {
                        entry: intlGlobal.get("t_resumes_certification_title")
                      })}
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button handleClick={handleCancel} variant="outlined">
                      {intlGlobal.get("t_resumes_dialog_delete_cancel_button")}
                    </Button>
                    <Button handleClick={handleAgree}>
                      {intlGlobal.get("t_resumes_dialog_delete_agree_button")}
                    </Button>
                  </DialogActions>
                </>
              )}
            </Dialog>
          </Stack>
        ) : (
          false
        )}
      </Stack>

      {/* Other details */}
      <Stack spacing={1} width="100%">
        <StyledTypography variant="body1">
          {issuingOrganization ?? "-"}
        </StyledTypography>
        {description ? (
          <StyledTypography variant="body1" whiteSpace="pre-line">
            {description}
          </StyledTypography>
        ) : (
          false
        )}
        {certificationUrl ? (
          <StyledLink
            href={certificationUrl}
            target="_blank"
            rel="noopener noreferrer">
            {certificationUrl}
          </StyledLink>
        ) : (
          false
        )}
        <Typography variant="body1">
          {issueDate ? (
            <>
              {dayjs(issueDate).format(
                translate.getLocaleShort(
                  intl.getInitOptions().currentLocale
                ) === LOCALE_SHORT.JA
                  ? "YYYY年M月"
                  : "MMMM YYYY"
              )}
              {expiryDate
                ? ` - ${dayjs(expiryDate).format(
                    translate.getLocaleShort(
                      intl.getInitOptions().currentLocale
                    ) === LOCALE_SHORT.JA
                      ? "YYYY年M月"
                      : "MMMM YYYY"
                  )}`
                : false}
            </>
          ) : (
            ""
          )}
        </Typography>
      </Stack>
    </Well>
  );
};

export default CertificationCard;
