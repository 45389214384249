import { Box, styled } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import Typography from "@components/Typography";

import DangerousComponentFromHTML from "@utils/components/DangerousComponentFromHTML";
import theme, { colorPalette } from "@utils/theme";
import Timestamp from "@utils/Timestamp";

dayjs.extend(relativeTime);

interface MessageBlockProps {
  body: string;
  createdAt: Timestamp;
  variant?: "sender" | "receiver";
}

const StyledTypography = styled(Typography)({
  wordBreak: "break-word"
});

const MessageBlock = ({
  body = "",
  createdAt,
  variant = "sender"
}: MessageBlockProps) => {
  return (
    <Box maxWidth={{ xs: "85%", md: "75%" }} width="max-content">
      <Box
        p={1.5}
        bgcolor={
          variant === "sender"
            ? theme.palette.primary.main
            : colorPalette.blue["04"]
        }
        borderRadius={5}>
        <StyledTypography
          width="100%"
          variant="body2"
          color={variant === "sender" ? "common.white" : "text.primary"}>
          <DangerousComponentFromHTML html={`<span>${body}</span>`} />
        </StyledTypography>
      </Box>
      <Typography
        variant="inputTable"
        color="text.secondary"
        width="100%"
        textAlign={variant === "sender" ? "end" : "start"}>
        {dayjs(createdAt.toDate()).format("HH:mm")}
      </Typography>
    </Box>
  );
};

export default MessageBlock;
