import { useEffect, useState } from "react";

import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddCircle as AddCircleIcon,
  NavigateBefore as NavigateBeforeIcon
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import SkeletonJobOverviewForm from "@skeletons/SkeletonJobOverviewForm";

import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import Icon from "@components/Icon";
import NonTypeableSelect from "@components/NonTypeableSelect";
import Paper from "@components/Paper";
import Radio from "@components/Radio";
import TextField from "@components/TextField";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import {
  FREE_TEXT_FIELD_MAX_LENGTH,
  MAX_WORK_EXPERIENCES,
  PROFILE_SUMMARY_NO_EXPERIENCE_CHECKBOX_VALUES,
  YEARS_OF_EXPERIENCE,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface JobOverviewFormData {
  noExperience: number[];
  primaryJobTitle: number;
  jobOverview: Array<{
    jobTitle: string;
    yearOfExperience:
      | typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE]
      | string;
  }>;
}
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  float: "right",
  [theme.breakpoints.up("md")]: {
    marginTop: "1rem"
  }
}));

const JobOverviewForm = () => {
  const userData = useUserProfile();
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [jobOverviewFieldIdsArr, setJobOverviewFieldIdsArr] = useState<
    Array<string>
  >([uuidv4()]);

  const userJobOverview =
    userData.value?.summary?.job_experience_overview ?? [];
  const userNoExperienceFlag =
    userData.value?.summary?.no_job_experience_overview === undefined
      ? false
      : userData.value?.summary?.no_job_experience_overview;
  const userPrimaryJobTitleIndex =
    userData.value?.summary?.basic_information?.primary_job_title_index ===
    undefined
      ? 0
      : userData.value?.summary?.basic_information?.primary_job_title_index;

  useEffect(() => {
    if (userJobOverview && userJobOverview?.length > 0) {
      const additionalJobOverviews = userJobOverview.length;
      setJobOverviewFieldIdsArr(
        Array.from({ length: additionalJobOverviews }, () => uuidv4())
      );
    }
  }, [userJobOverview]);

  // FIXME: add skeleton

  // validation schema
  const schema = yup.object({
    noExperience: yup.array().test(
      "noExperience",
      intl.get("t_error_invalid", {
        field: intl.get("t_profile_job_overview_no_job_experience_check")
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (value: any) => {
        if (
          value?.length === 0 ||
          value?.includes(
            PROFILE_SUMMARY_NO_EXPERIENCE_CHECKBOX_VALUES.NO_EXPERIENCE_CHECKED
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    ),
    jobOverview: yup.array().when("noExperience", {
      is: (noExperience: Array<number>) => noExperience?.includes(1),
      then: (schema) => {
        return schema.of(
          yup.object({
            jobTitle: yup.string().trim(),
            yearOfExperience: yup.string()
          })
        );
      },
      otherwise: (schema) => {
        return schema.of(
          yup.object({
            jobTitle: yup
              .string()
              .trim()
              .max(
                FREE_TEXT_FIELD_MAX_LENGTH,
                intl.get("t_error_max_limit", {
                  field: intl.get("t_profile_job_overview_job_title"),
                  maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
                })
              )
              .required(
                intl.get("t_error_required", {
                  field: intl.get("t_profile_job_overview_job_title")
                })
              ),
            yearOfExperience: yup.string().required(
              intl.get("t_error_required", {
                field: intl.get("t_profile_job_overview_year_of_experience")
              })
            )
          })
        );
      }
    })
  });

  const formInitValues = {
    noExperience: userNoExperienceFlag ? [1] : [], // by default [] and if user checked the checkbox it will become [1]
    primaryJobTitle: userPrimaryJobTitleIndex, // by default 0 and if user checked the radio it will refer index of jobOverview array
    jobOverview:
      userJobOverview && userJobOverview?.length > 0 && !userNoExperienceFlag
        ? userJobOverview.map((singleJobOverview) => {
            return {
              jobTitle: resolveMultiLingual(singleJobOverview?.job_title) ?? "",
              yearOfExperience: singleJobOverview.years_of_experience
            };
          })
        : [
            {
              jobTitle: "",
              yearOfExperience: ""
            }
          ]
  };

  const methods = useForm({
    defaultValues: formInitValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, setValue, watch, getValues, reset } = methods;
  useEffect(() => {
    reset(formInitValues);
    setLoading(false);
  }, [userData, userNoExperienceFlag]);

  const jobOverviewFieldArr = useFieldArray({
    control,
    name: "jobOverview"
  });

  const isNoExperience = watch("noExperience")?.includes(1); // array contain 1 => no experience checkbox checked else unchecked
  const primaryJobTitleIndex = Number(watch("primaryJobTitle"));

  useEffect(() => {
    // if no field then check the checkbox and set primaryJobTitle = -1
    if (jobOverviewFieldArr.fields.length === 0) {
      reset({ noExperience: [1], primaryJobTitle: -1 });
    }
    // if the field length is zero and the user click to uncheck the checkbox then append a new field and reset the noExperience and primaryJobTitle
    if (isNoExperience && jobOverviewFieldArr.fields.length === 0) {
      jobOverviewFieldArr.append({ jobTitle: "", yearOfExperience: "" });
      setJobOverviewFieldIdsArr((oldJobOverviewFieldIdsArr) => {
        return [...oldJobOverviewFieldIdsArr, uuidv4()];
      });
      reset({ noExperience: [], primaryJobTitle: 0 });
    }
  }, [jobOverviewFieldArr.fields, isNoExperience]);

  const YEARS_OF_EXPERIENCE_OPTIONS = useOptions(
    YEARS_OF_EXPERIENCE,
    YEARS_OF_EXPERIENCE_T_LABELS
  );

  const handleJobOverviewSavedSuccess = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_success_profile_summary_saved"), "success");
  };

  const handleJobOverviewSavedFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const onSubmit = async (formData: JobOverviewFormData) => {
    setIsDisabled(true);
    const { jobOverview, primaryJobTitle, noExperience } = formData || {};

    try {
      if (
        userData.setValue &&
        userData.value &&
        userData.value?.summary?.basic_information
      ) {
        const basicInformation = {
          ...userData.value.summary?.basic_information,
          primary_job_title_index: parseInt(primaryJobTitle.toString())
        };

        // set and unset primary_job_title_index
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { primary_job_title_index, ...noJobTitleBasicInformation } =
          basicInformation;
        await userData?.setValue(
          {
            ...userData.value,
            summary: {
              ...userData.value.summary,
              basic_information:
                isNoExperience || primaryJobTitleIndex === -1
                  ? noJobTitleBasicInformation
                  : basicInformation,
              no_job_experience_overview: Boolean(noExperience?.includes(1)),
              job_experience_overview: !isNoExperience
                ? jobOverview?.map(
                    (
                      singleExperienceData: {
                        jobTitle: string;
                        yearOfExperience: string;
                      },
                      index
                    ) => {
                      return {
                        job_title:
                          userJobOverview.length > 0
                            ? prepareMultiLingual(
                                singleExperienceData?.jobTitle ?? "",
                                undefined,
                                userJobOverview[index]?.job_title
                              )
                            : prepareMultiLingual(
                                singleExperienceData?.jobTitle ?? ""
                              ),
                        years_of_experience:
                          (singleExperienceData?.yearOfExperience as typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE]) ??
                          ""
                      };
                    }
                  )
                : []
            },
            updated_at: Timestamp.now()
          },
          handleJobOverviewSavedSuccess,
          handleJobOverviewSavedFail
        );
        navigate(`/${translate.getCurrentLocale()}/profile/edit`);
      }
    } catch (e) {
      handleJobOverviewSavedFail();
    }
  };

  const handleRemoveJobOverviewField = (idx: number) => {
    setJobOverviewFieldIdsArr((oldJobOverviewFieldIdsArr) => {
      oldJobOverviewFieldIdsArr.splice(idx, 1);
      return [...oldJobOverviewFieldIdsArr];
    });
    const fieldValues = getValues("jobOverview");
    fieldValues.splice(idx, 1);
    jobOverviewFieldArr.replace(fieldValues);
    // if the user deleted the primary job title field then default select the first item as the default
    if (primaryJobTitleIndex === idx || isNoExperience) {
      setValue("primaryJobTitle", 0);
    }
  };

  if (loading || userData.loading) {
    return <SkeletonJobOverviewForm />;
  }

  return (
    <Box noValidate component="form" onSubmit={handleSubmit(onSubmit)}>
      {/* Job overview section start */}
      <Paper>
        <Typography variant="h3">
          {intl.get("t_profile_job_overview_heading")}
        </Typography>
        <Typography mt={2.5} mb={2} color="text.secondary">
          {intl.get("t_profile_job_overview_sub_heading")}
        </Typography>

        <Checkbox
          disabled={isDisabled}
          size="medium"
          name="noExperience"
          control={control}
          setValue={setValue}
          options={[
            {
              label: intl.get("t_profile_job_overview_no_job_experience"),
              value:
                PROFILE_SUMMARY_NO_EXPERIENCE_CHECKBOX_VALUES.NO_EXPERIENCE_CHECKED
            }
          ]}
        />
        {!isNoExperience ? (
          <>
            <Grid container columnSpacing={3}>
              <Grid item xs={12} md={5.5}>
                <TransitionGroup>
                  {jobOverviewFieldIdsArr.map((id, index) => {
                    return (
                      <Collapse key={id}>
                        <Grid item xs={12} md={5.5} mt={3}>
                          <TextField
                            disabled={isDisabled}
                            control={control}
                            name={`jobOverview.${index}.jobTitle`}
                            label={intl.get("t_profile_job_overview_job_title")}
                            placeholder={intl.get(
                              "t_profile_job_overview_job_title"
                            )}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={5.5}>
                          <NonTypeableSelect
                            disabled={isDisabled}
                            setValue={setValue}
                            control={control}
                            name={`jobOverview.${index}.yearOfExperience`}
                            label={intl.get(
                              "t_profile_job_overview_year_of_experience"
                            )}
                            placeholder={intl.get(
                              "t_profile_job_overview_year_of_experience"
                            )}
                            required
                            options={YEARS_OF_EXPERIENCE_OPTIONS}
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <StyledIconButton
                            disabled={isDisabled}
                            onClick={() => handleRemoveJobOverviewField(index)}>
                            <Icon type="delete" filled />
                          </StyledIconButton>
                        </Grid>
                        <Grid item xs={12}>
                          <Radio
                            disabled={isDisabled}
                            size="medium"
                            name="primaryJobTitle"
                            control={control}
                            options={[
                              {
                                label: intl.get(
                                  "t_profile_job_overview_primary_job_title"
                                ),
                                value: index
                              }
                            ]}
                          />
                        </Grid>
                      </Collapse>
                    );
                  })}
                </TransitionGroup>
              </Grid>
            </Grid>
            <Button
              disabled={isDisabled}
              color="primary"
              handleClick={() => {
                // if the max limit is exceeded, set a toast notification
                if (jobOverviewFieldArr.fields.length >= MAX_WORK_EXPERIENCES) {
                  toast.kampai(
                    intl.get("t_toast_error_limit_exceed", {
                      fieldName: intl.get("t_profile_summary_experience"),
                      maxLimit: MAX_WORK_EXPERIENCES
                    }),
                    "error"
                  );
                } else {
                  jobOverviewFieldArr.append({
                    jobTitle: "",
                    yearOfExperience: ""
                  });
                  setJobOverviewFieldIdsArr((oldJobOverviewFieldIdsArr) => {
                    return [...oldJobOverviewFieldIdsArr, uuidv4()];
                  });
                }
              }}
              size="small"
              startAdornment={<AddCircleIcon />}
              variant="text">
              {intl.get("t_general_add_another")}
            </Button>
          </>
        ) : (
          false
        )}
      </Paper>
      {/* Job overview section end */}

      <Stack justifyContent="space-between" direction="row" mt={5.5}>
        <Button
          handleClick={() =>
            navigate(`/${translate.getCurrentLocale()}/profile/edit`)
          }
          variant="outlined"
          startAdornment={<NavigateBeforeIcon />}>
          {intl.get("t_general_back")}
        </Button>
        <Button type="submit" loading={isDisabled}>
          {intl.get("t_general_save")}
        </Button>
      </Stack>
    </Box>
  );
};

export default JobOverviewForm;
