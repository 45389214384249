import languages from "@cospired/i18n-iso-languages";
import enLocale from "@cospired/i18n-iso-languages/langs/en.json";
import jaLocale from "@cospired/i18n-iso-languages/langs/ja.json";

import KeyLabel from "@interfaces/components/KeyLabel";

import { LOCALE, LOCALE_SHORT } from "@utils/config";
import translate from "@utils/translate";

languages.registerLocale(enLocale);
languages.registerLocale(jaLocale);

export const getLanguageName = (
  languageCode: string,
  locale: typeof LOCALE[keyof typeof LOCALE] = translate.getCurrentLocale()
) => {
  let languageLocale: typeof LOCALE_SHORT[keyof typeof LOCALE_SHORT] =
    LOCALE_SHORT.JA;
  switch (locale) {
    case LOCALE.EN:
      languageLocale = LOCALE_SHORT.EN;
      break;
    case LOCALE.JA:
      languageLocale = LOCALE_SHORT.JA;
      break;
    default:
      languageLocale = LOCALE_SHORT.JA;
      break;
  }
  return languages.getName(languageCode, languageLocale);
};

// This function is specifically to get the list of languages for language selection dropdown.
export const getDropdownLanguageList = (): Array<KeyLabel> => {
  const allLanguageAlpha3 = languages.getAlpha3TCodes();
  const languageList = Object.keys(allLanguageAlpha3).map((singleAlpha3) => {
    const enName = languages.getName(singleAlpha3, LOCALE_SHORT.EN);
    let localeName = "";
    if (translate.getCurrentLocale() !== LOCALE.EN) {
      switch (translate.getCurrentLocale()) {
        case LOCALE.JA:
          localeName = languages.getName(singleAlpha3, LOCALE_SHORT.JA) ?? "";
          break;
      }
    }

    return {
      key: singleAlpha3,
      label: enName + (localeName ? ` (${localeName})` : "")
    };
  });
  return languageList;
};
