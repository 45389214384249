import { KeyboardEvent, useEffect } from "react";

import { useForm, useWatch } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import SendIcon from "@mui/icons-material/Send";
import { Box, IconButton, Stack } from "@mui/material";
import * as yup from "yup";

import Button from "@components/Button";
import TextareaAutoSize from "@components/TextareaAutoSize";

import useToast from "@hooks/useToast";

import { MESSAGE_INPUT_TEXT_MAX_LENGTH } from "@utils/config";
import { intl } from "@utils/translate";

interface MessageInputProps {
  handleSendMessage: (msg: string) => void;
  disabled: boolean;
  conversationId?: string;
  handleKeyDown?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
}

const MessageInput = ({
  handleSendMessage,
  disabled = false,
  conversationId = ""
}: MessageInputProps) => {
  // validation schema
  const schema = yup.object({
    message: yup.string().trim()
  });
  const toast = useToast();

  const methods = useForm({
    defaultValues: {
      message: ""
    },
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, reset } = methods;

  const messageValue = useWatch({
    control,
    name: "message"
  });

  useEffect(() => {
    reset({ message: "" });
  }, [conversationId]);

  const isSendButtonDisabled = messageValue?.length <= 0;

  const handleFormSubmit = (formData: { message: string }) => {
    if (formData?.message?.length > MESSAGE_INPUT_TEXT_MAX_LENGTH) {
      toast.kampai(
        intl.get("t_toast_error_message_character_limit_exceed", {
          maxLimit: MESSAGE_INPUT_TEXT_MAX_LENGTH
        }),
        "error"
      );
      return;
    }
    if (formData.message.length > 1) {
      handleSendMessage(formData.message);
      reset();
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(handleFormSubmit)(); // Ref:- https://github.com/react-hook-form/react-hook-form/issues/936#issue-557292158
    }
  };

  return (
    <Stack
      direction="row"
      columnGap={1}
      alignItems="center"
      noValidate
      component="form"
      onSubmit={handleSubmit(handleFormSubmit)}>
      <TextareaAutoSize
        control={control}
        name="message"
        handleKeyDown={handleKeyDown}
        placeholder={intl.get("t_conversations_message_input_placeholder")}
        disabled={disabled}
      />
      <Box mb={0.5}>
        <Box display={{ xs: "block", md: "none" }}>
          <IconButton disabled={isSendButtonDisabled} type="submit">
            <SendIcon color={isSendButtonDisabled ? "disabled" : "primary"} />
          </IconButton>
        </Box>
        <Box display={{ xs: "none", md: "block" }}>
          <Button
            type="submit"
            disabled={isSendButtonDisabled}
            loading={disabled}>
            {intl.get("t_general_send")}
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default MessageInput;
