import { MouseEvent, ReactNode } from "react";

import { IconButton as MuiIconButton } from "@mui/material";

interface IconButtonProps {
  size?: "small" | "medium" | "large";
  color?: "primary" | "secondary";
  children?: ReactNode;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const IconButton = ({
  color = "primary",
  size = "medium",
  children,
  ...rest
}: IconButtonProps) => {
  return (
    <MuiIconButton {...rest} color={color} size={size}>
      {children}
    </MuiIconButton>
  );
};

export default IconButton;
