import { styled } from "@mui/material";

interface IconProps {
  type: string;
  filled?: boolean;
}

const Icon = ({ type, filled = false }: IconProps) => {
  const IconSpan = styled("span")(() => ({
    fontVariationSettings: filled
      ? "'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 48"
      : "'FILL' 0, 'wght' 500, 'GRAD' 0, 'opsz' 48"
  }));

  return <IconSpan className="material-symbols-rounded">{type}</IconSpan>;
};

export default Icon;
