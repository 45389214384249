import { Skeleton } from "@mui/material";

interface SkeletonProgressBarProps {
  totalProgressSteps?: number;
}

const SkeletonProgressBar = ({
  totalProgressSteps = 6
}: SkeletonProgressBarProps) => {
  return (
    <Skeleton
      animation="wave"
      variant="rounded"
      height="1.5rem"
      width={`min(calc(48px * ${totalProgressSteps}), 100%)`}
    />
  );
};

export default SkeletonProgressBar;
