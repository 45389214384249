import { useEffect } from "react";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import * as yup from "yup";

import TextField from "@components/TextField";

import { FREE_TEXT_FIELD_MAX_LENGTH, URL_REGEX } from "@utils/config";
import { intl } from "@utils/translate";

interface CompanyInfoForm {
  companyName: string;
  companyWebsiteURL: string;
}

interface CompanyInfoFormProps {
  initialValues: CompanyInfoForm;
  handleFormSubmit: (formData: CompanyInfoForm) => void;
  backBtnLink?: string;
  isDisabled?: boolean;
}

const CompanyInfoForm = ({
  initialValues,
  handleFormSubmit,
  isDisabled
}: CompanyInfoFormProps) => {
  // validation schema
  const schema = yup.object({
    companyName: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_job_post_company_information_step_company_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_job_post_company_information_step_company_name")
        })
      ),
    companyWebsiteURL: yup
      .string()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_job_post_company_information_step_company_website")
        })
      )
      .matches(URL_REGEX, {
        message: intl.get("t_error_invalid", {
          field: intl.get("t_job_post_company_information_step_company_website")
        })
      })
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, reset } = methods;

  useEffect(() => {
    reset(initialValues);
  }, [initialValues]);

  return (
    <Box
      noValidate
      component="form"
      onSubmit={handleSubmit(handleFormSubmit)}
      // id added to support form submission Ref:- https://github.com/react-hook-form/react-hook-form/issues/566#issuecomment-675515940
      id="company-information-form">
      <TextField
        data-testid="company_information_company_name_input"
        disabled={isDisabled}
        control={control}
        name="companyName"
        label={intl.get("t_job_post_company_information_step_company_name")}
        placeholder={intl.get(
          "t_job_post_company_information_step_company_name"
        )}
        required
      />
      <TextField
        data-testid="company_information_company_website_input"
        disabled={isDisabled}
        control={control}
        name="companyWebsiteURL"
        label={intl.get("t_job_post_company_information_step_company_website")}
        placeholder={intl.get(
          "t_job_post_company_information_step_company_website_placeholder"
        )}
        required
      />
    </Box>
  );
};

export default CompanyInfoForm;
