import { useEffect, useState } from "react";

import { RawDraftContentState } from "react-draft-wysiwyg";
import { useParams } from "react-router-dom";

import {
  Apartment as ApartmentIcon,
  PinDrop as PinDropIcon,
  Work as WorkIcon
} from "@mui/icons-material";
import { Box, Divider, Grid, Link, ListItem, Stack } from "@mui/material";
import dayjs from "dayjs";
import { ContentState, convertFromRaw, convertToRaw } from "draft-js";
import { doc, getDoc } from "firebase/firestore";
import { ref } from "firebase/storage";
import { useDownloadURL } from "react-firebase-hooks/storage";

import SkeletonEmployerJobDetailsDrawer from "@skeletons/SkeletonEmployerJobDetailsDrawer";
import SkeletonKeyword from "@skeletons/SkeletonKeyword";

import CompanyLogo from "@components/CompanyLogo";
import Icon from "@components/Icon";
import JobDescriptionIcon from "@components/JobDescriptionIcon";
import {
  StyledDownloadButton,
  StyledList,
  StyledPaperBody,
  StyledPaperHeader
} from "@components/JobDetailCard";
import Keyword from "@components/Keyword";
import Status from "@components/Status";
import Typography from "@components/Typography";
import WYSIWYG from "@components/WYSIWYG";

import useToast from "@hooks/useToast";

import CompanyProfile from "@interfaces/database/CompanyProfile";
import Conversation from "@interfaces/database/Conversation";
import CvFileRequirements from "@interfaces/database/CvFileRequirements";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import Language from "@interfaces/database/Language";
import Skill from "@interfaces/database/Skill";
import UploadedFileInformation from "@interfaces/database/UploadedFileInformation";

import {
  FILES_LOCATION_COMPANY_LOGO,
  FILES_LOCATION_JOB_ADDITIONAL_FILE,
  FIRESTORE_COLLECTIONS,
  JOB_ACTIVE_TAG_COMPANY_LAST_LOGIN_DAY,
  JOB_CONTRACT_TYPE,
  JOB_CONTRACT_TYPE_T_LABELS,
  JOB_NEW_TAG_LAST_UPDATED_DAY,
  JOB_REMOTE_WORK_TYPE,
  JOB_REMOTE_WORK_TYPE_T_LABELS,
  JOB_SALARY_DEPOSIT_FREQUENCY,
  JOB_SALARY_DEPOSIT_FREQUENCY_T_LABELS,
  JOB_STATUS_TAGS,
  JOB_VISA_SPONSORSHIP_AVAILABLE,
  JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS,
  LANGUAGE_PROFICIENCY,
  LANGUAGE_PROFICIENCY_T_LABELS,
  YEARS_OF_EXPERIENCE,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import { getDaysAgo } from "@utils/dateTime";
import { db, storage } from "@utils/firebase";
import { getFileURL } from "@utils/getFileURL";
import { getSkillLabel } from "@utils/keyLabelHandlers/skill";
import { getLanguageName } from "@utils/language";
import { getFullLocation } from "@utils/location";
import { resolveMultiLingual } from "@utils/multiLingual";
import { roundSalary } from "@utils/roundSalary";
import { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

interface JobDetail {
  jobId: JobID;
  updatedDate: Date;
  jobStatuses?: Array<typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]>;
  companyLogo?: string;
  companyName?: string;
  companyWebsite?: string;
  agencyName?: string;
  jobTitle?: string;
  jobLocation?: string;
  salary?: {
    min?: number;
    max: number;
    frequency: typeof JOB_SALARY_DEPOSIT_FREQUENCY[keyof typeof JOB_SALARY_DEPOSIT_FREQUENCY];
  };
  visaSponsorship?: typeof JOB_VISA_SPONSORSHIP_AVAILABLE[keyof typeof JOB_VISA_SPONSORSHIP_AVAILABLE];
  experienceRequirement?: typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE];
  cvFileRequirements?: CvFileRequirements;
  jobContractType?: typeof JOB_CONTRACT_TYPE[keyof typeof JOB_CONTRACT_TYPE];
  remoteAllowed?: typeof JOB_REMOTE_WORK_TYPE[keyof typeof JOB_REMOTE_WORK_TYPE];
  jaProficiency?: typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY];
  enProficiency?: typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY];
  jobDescription?: RawDraftContentState;
  mustRequirements?: RawDraftContentState;
  jobDescriptionPlainText?: string;
  mustRequirementsPlainText?: string;
  languageRequirement?: Array<Language>;
  requiredSkills?: Array<Skill>;
  otherDetails?: RawDraftContentState;
  otherDetailsPlainText?: string;
  additionInfoFileDetails?: UploadedFileInformation;
}

const EmployerJobDetailsDrawer = () => {
  const toast = useToast();

  const { conversation_id: paramConversationId } = useParams();
  const conversationId = paramConversationId ?? "";

  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [job, setJob] = useState<JobProfile | null>(null);
  const [company, setCompany] = useState<CompanyProfile | null>(null);
  const [agencyCompany, setAgencyCompany] = useState<CompanyProfile | null>(
    null
  );
  const [jobDetails, setJobDetails] = useState<JobDetail | null>(null);
  const [skills, setSkills] = useState<Array<Skill>>([]);
  const [isSkillsLoading, setIsSkillsLoading] = useState<boolean>(true);
  const [downloadFileUrl] = useDownloadURL(
    ref(
      storage,
      `${FILES_LOCATION_JOB_ADDITIONAL_FILE}/${jobDetails?.jobId}.${jobDetails?.additionInfoFileDetails?.extension}`
    )
  );

  useEffect(() => {
    if (conversationId) {
      fetchConversationDetails(conversationId);
    }
  }, [conversationId]);

  useEffect(() => {
    if (conversation) {
      fetchJobAndCompanyDetails(conversation?.job_id);
    }
  }, [conversation]);

  useEffect(() => {
    updateJobDetails();
  }, [job, conversation, company]);

  useEffect(() => {
    (async () => {
      if (jobDetails?.requiredSkills) {
        setIsSkillsLoading(true);
        const skills: Array<Skill> = [];
        for (const singleSkill of jobDetails.requiredSkills) {
          const skillLabel = await getSkillLabel(
            singleSkill.name,
            translate.getCurrentLocale()
          );
          skills.push({
            name: skillLabel,
            years_of_experience: singleSkill.years_of_experience
          });
        }
        setSkills(skills);
        setIsSkillsLoading(false);
      }
    })();
  }, [jobDetails?.requiredSkills]);

  const fetchConversationDetails = async (conversationId: string) => {
    try {
      const conversationRef = doc(
        db,
        FIRESTORE_COLLECTIONS.CONVERSATIONS,
        conversationId
      );
      const conversationSnap = await getDoc(conversationRef);

      if (!conversationSnap.exists()) {
        throw new Error("Conversation not found");
      }

      setConversation(conversationSnap.data() as Conversation);
    } catch (error) {
      handleError();
    }
  };

  const fetchJobAndCompanyDetails = async (jobId: JobID) => {
    try {
      const jobRef = doc(db, FIRESTORE_COLLECTIONS.JOBS, jobId);
      const jobSnap = await getDoc(jobRef);

      if (!jobSnap.exists()) {
        throw new Error("Job not found");
      }

      const jobData = jobSnap.data();
      setJob(jobData as JobProfile);

      const companyId = jobData.company_id;
      const companyRef = doc(db, FIRESTORE_COLLECTIONS.COMPANIES, companyId);
      const companySnap = await getDoc(companyRef);

      if (!companySnap.exists()) {
        throw new Error("Company not found");
      }

      let companyData = companySnap.data();
      let agencyData = undefined;

      if (jobData.client_company_id) {
        const clientCompanyRef = doc(
          db,
          FIRESTORE_COLLECTIONS.COMPANIES,
          jobData.client_company_id
        );
        const clientCompanySnap = await getDoc(clientCompanyRef);
        if (clientCompanySnap.exists()) {
          agencyData = companyData;
          companyData = clientCompanySnap.data() as CompanyProfile;
        }
      }

      setCompany(companyData as CompanyProfile);
      setAgencyCompany(agencyData as CompanyProfile);
    } catch (error) {
      handleError();
    }
  };

  const updateJobDetails = () => {
    if (conversation && job && company) {
      const companyId = conversation?.company_id;
      const companyName = resolveMultiLingual(company?.name);
      const companyLogo = getFileURL(
        FILES_LOCATION_COMPANY_LOGO,
        companyId,
        company?.logo?.extension,
        company?.logo?.uploaded_at
      );
      const companyWebsite = company?.website_url;
      const agencyName = agencyCompany
        ? resolveMultiLingual(agencyCompany?.name)
        : "";
      const jobId = conversation?.job_id;
      const jobTitle = resolveMultiLingual(job?.job_title);
      const updatedDate = new Date(job.updated_at.seconds * 1000);
      const jobStatuses = getJobStatusTags();
      const location = job.job_overview?.location;
      const jobLocation = getFullLocation(
        location?.city,
        location?.country,
        true
      );
      const salary = job.job_overview?.salary;
      const visaSponsorship = job.job_overview?.visa_sponsorship;
      const jobContractType = job.job_overview?.contract_type;
      const remoteAllowed = job.job_overview?.remote_possible;
      const jaProficiency = job.language_requirement?.[0]?.proficiency;
      const enProficiency = job.language_requirement?.[1]?.proficiency;
      const jobDescription = resolveMultiLingual(job.job_description);
      const jobDescriptionPlainText = jobDescription
        ? convertFromRaw(jobDescription).getPlainText()
        : "";
      const mustRequirements = resolveMultiLingual(job.must_requirements);
      const mustRequirementsPlainText = mustRequirements
        ? convertFromRaw(mustRequirements).getPlainText()
        : "";
      const otherDetails = resolveMultiLingual(job.additional_information);
      const otherDetailsPlainText = otherDetails
        ? convertFromRaw(otherDetails).getPlainText()
        : "";
      const languageRequirement = job.language_requirement;
      const requiredSkills = job.skills;
      const cvFileRequirements = job?.cv_file_requirements;
      const additionInfoFileDetails = job?.additional_file;

      setJobDetails({
        jobId,
        jobTitle,
        jobLocation,
        jobStatuses,
        companyName,
        agencyName,
        companyLogo,
        companyWebsite,
        salary,
        visaSponsorship,
        jobContractType,
        remoteAllowed,
        jaProficiency,
        enProficiency,
        jobDescription,
        jobDescriptionPlainText,
        mustRequirements,
        mustRequirementsPlainText,
        languageRequirement,
        requiredSkills,
        otherDetails,
        otherDetailsPlainText,
        cvFileRequirements,
        additionInfoFileDetails,
        updatedDate
      });
    }
  };

  const handleError = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const getJobStatusTags = (): Array<
    typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]
  > => {
    const statusTags: Array<
      typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]
    > = [];

    if (job && company) {
      if (job.updated_at) {
        if (getDaysAgo(job.updated_at) <= JOB_NEW_TAG_LAST_UPDATED_DAY) {
          statusTags.push(JOB_STATUS_TAGS.NEW);
        }
      }
      if (job.client_company_id) {
        statusTags.push(JOB_STATUS_TAGS.AGENCY);
      }
      if (company.last_login_at) {
        if (
          getDaysAgo(company.last_login_at) <=
          JOB_ACTIVE_TAG_COMPANY_LAST_LOGIN_DAY
        ) {
          statusTags.push(JOB_STATUS_TAGS.ACTIVE);
        }
      }
    }

    return statusTags;
  };

  return (
    <Box px={{ xs: 2.5, md: 3, lg: 4 }} mb={2}>
      {jobDetails ? (
        <>
          <StyledPaperHeader>
            <Stack>
              <Grid
                container
                alignItems="center"
                my={1}
                wrap="wrap-reverse"
                rowSpacing={0.5}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    color={colorPalette.black.hover.base}>
                    {dayjs(jobDetails?.updatedDate)
                      .locale(translate.getCurrentLocaleShort())
                      .fromNow()}
                  </Typography>
                </Grid>
                <Grid item xs="auto" ml="auto">
                  <Stack
                    direction="row"
                    gap={1}
                    ml="auto"
                    flexWrap="wrap"
                    mb={1.5}>
                    {jobDetails?.jobStatuses?.map((singleJobStatus, idx) => {
                      return <Status label={singleJobStatus} key={idx} />;
                    })}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              my={1}
              flexWrap="wrap"
              justifyItems="stretch">
              <CompanyLogo
                src={jobDetails?.companyLogo}
                alt={jobDetails?.companyName}
              />
              <Stack gap={0.5} flexGrow={1}>
                <Stack
                  direction="row"
                  gap={1}
                  color={colorPalette.black.hover.base}>
                  <ApartmentIcon color="inherit" />
                  <Typography variant="body1">
                    {jobDetails?.companyName ?? "-"}
                  </Typography>
                </Stack>
                <Stack direction="row" gap={1}>
                  <WorkIcon color="inherit" />
                  <Typography variant="subtitle2" id="data-for-ssg">
                    {jobDetails?.jobTitle ?? "-"}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  gap={1}
                  color={colorPalette.black.hover.base}>
                  <PinDropIcon color="inherit" />
                  <Typography
                    variant="body1"
                    color={colorPalette.black.hover.base}
                    textTransform="capitalize">
                    {jobDetails?.jobLocation ?? "-"}
                  </Typography>
                </Stack>
              </Stack>
              {jobDetails?.salary ? (
                <Typography variant="subtitle2">
                  ¥
                  {jobDetails?.salary.min ? (
                    <>
                      &nbsp;
                      {roundSalary(jobDetails?.salary.min)}
                      &nbsp;-
                    </>
                  ) : (
                    false
                  )}
                  &nbsp;
                  {roundSalary(jobDetails?.salary.max)}
                  &nbsp;
                  {intl.get(
                    JOB_SALARY_DEPOSIT_FREQUENCY_T_LABELS[
                      jobDetails?.salary.frequency
                    ]
                  )}
                </Typography>
              ) : (
                false
              )}
            </Stack>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              rowGap={1}
              my={1}>
              <Grid item xs={12} sm="auto">
                <Stack>
                  {jobDetails?.companyWebsite ? (
                    <Typography>
                      <Typography
                        variant="subtitle5"
                        color={colorPalette.black.hover.base}>
                        {intl.get("t_general_website")}:
                      </Typography>
                      &nbsp;
                      <Link
                        variant="body2"
                        href={jobDetails?.companyWebsite}
                        target="_blank"
                        color={colorPalette.lightBlue.base}
                        rel="noopener noreferrer">
                        {jobDetails?.companyWebsite}
                      </Link>
                    </Typography>
                  ) : (
                    false
                  )}
                  {jobDetails?.agencyName ? (
                    <Typography color={colorPalette.black.hover.base}>
                      {intl.get("t_job_posted_by", {
                        agencyName: jobDetails?.agencyName
                      })}
                    </Typography>
                  ) : (
                    false
                  )}
                </Stack>
              </Grid>
            </Grid>
          </StyledPaperHeader>
          <StyledPaperBody>
            <Typography variant="subtitle2" mt={1}>
              {intl.get("t_job_details_job_overview")}
            </Typography>
            <Grid container spacing={2} my={0} alignItems="center">
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <Stack direction="row" gap={1} alignItems="center">
                  <JobDescriptionIcon filled icon="edit_document" />
                  <Stack>
                    <Typography variant="subtitle4">
                      {intl.get("t_job_visa_sponsorship")}
                    </Typography>
                    <Typography>
                      {jobDetails?.visaSponsorship &&
                      JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS[
                        jobDetails?.visaSponsorship
                      ]
                        ? intl.get(
                            JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS[
                              jobDetails?.visaSponsorship
                            ]
                          )
                        : "-"}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <Stack direction="row" gap={1} alignItems="center">
                  <JobDescriptionIcon filled icon="currency_yen" />
                  <Stack>
                    <Typography variant="subtitle4">
                      {intl.get("t_general_salary")}
                    </Typography>
                    {jobDetails?.salary ? (
                      <Typography>
                        {jobDetails?.salary.min ? (
                          <>
                            {roundSalary(jobDetails?.salary.min)}
                            &nbsp;-
                          </>
                        ) : (
                          false
                        )}
                        &nbsp;
                        {roundSalary(jobDetails?.salary.max)}
                        &nbsp;
                        {intl.get(
                          JOB_SALARY_DEPOSIT_FREQUENCY_T_LABELS[
                            jobDetails?.salary.frequency
                          ]
                        )}
                      </Typography>
                    ) : (
                      <Typography>-</Typography>
                    )}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <Stack direction="row" gap={1} alignItems="center">
                  <JobDescriptionIcon filled icon="psychology" />
                  <Stack>
                    <Typography variant="subtitle4">
                      {intl.get("t_job_experience")}
                    </Typography>
                    <Typography>
                      {jobDetails?.experienceRequirement &&
                      YEARS_OF_EXPERIENCE_T_LABELS[
                        jobDetails?.experienceRequirement
                      ]
                        ? intl.get(
                            YEARS_OF_EXPERIENCE_T_LABELS[
                              jobDetails?.experienceRequirement
                            ]
                          )
                        : "-"}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <Stack direction="row" gap={1} alignItems="center">
                  <JobDescriptionIcon filled icon="schedule" />
                  <Stack>
                    <Typography variant="subtitle4">
                      {intl.get(
                        "t_resumes_job_detail_form_label_contract_type"
                      )}
                    </Typography>
                    <Typography>
                      {jobDetails?.jobContractType &&
                      JOB_CONTRACT_TYPE_T_LABELS[jobDetails?.jobContractType]
                        ? intl.get(
                            JOB_CONTRACT_TYPE_T_LABELS[
                              jobDetails?.jobContractType
                            ]
                          )
                        : "-"}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <Stack direction="row" gap={1} alignItems="center">
                  <JobDescriptionIcon filled icon="laptop_mac" />
                  <Stack>
                    <Typography variant="subtitle4">
                      {intl.get("t_job_remote_work")}
                    </Typography>
                    {jobDetails?.remoteAllowed &&
                    JOB_REMOTE_WORK_TYPE_T_LABELS[jobDetails?.remoteAllowed] ? (
                      <Typography>
                        {intl.get(
                          JOB_REMOTE_WORK_TYPE_T_LABELS[
                            jobDetails?.remoteAllowed
                          ]
                        )}
                      </Typography>
                    ) : (
                      "-"
                    )}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <Stack direction="row" gap={1} alignItems="center">
                  <JobDescriptionIcon icon="forum" />
                  <Stack>
                    <Typography variant="subtitle4">
                      {intl.get("t_general_japanese_level")}
                    </Typography>
                    <Typography>
                      {jobDetails?.jaProficiency &&
                      LANGUAGE_PROFICIENCY_T_LABELS[jobDetails?.jaProficiency]
                        ? intl.get(
                            LANGUAGE_PROFICIENCY_T_LABELS[
                              jobDetails?.jaProficiency
                            ]
                          )
                        : "-"}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <Stack direction="row" gap={1} alignItems="center">
                  <JobDescriptionIcon icon="record_voice_over" />
                  <Stack>
                    <Typography variant="subtitle4">
                      {intl.get("t_general_english_level")}
                    </Typography>
                    <Typography>
                      {jobDetails?.enProficiency &&
                      LANGUAGE_PROFICIENCY_T_LABELS[jobDetails?.enProficiency]
                        ? intl.get(
                            LANGUAGE_PROFICIENCY_T_LABELS[
                              jobDetails?.enProficiency
                            ]
                          )
                        : "-"}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Box my={3}>
              <Divider />
            </Box>
            {jobDetails?.jobDescriptionPlainText ? (
              <>
                <Typography variant="subtitle2" mt={2}>
                  {intl.get("t_job_details_job_description")}
                </Typography>
                <Box>
                  <WYSIWYG
                    mode="view"
                    defaultValue={
                      jobDetails?.jobDescription ??
                      convertToRaw(ContentState.createFromText(""))
                    }
                  />
                </Box>
              </>
            ) : (
              false
            )}

            {jobDetails?.mustRequirementsPlainText ? (
              <>
                <Typography variant="subtitle2" mt={2}>
                  {intl.get("t_job_post_job_information_step_must_requirement")}
                </Typography>
                <Box>
                  <WYSIWYG
                    mode="view"
                    defaultValue={
                      jobDetails?.mustRequirements ??
                      convertToRaw(ContentState.createFromText(""))
                    }
                  />
                </Box>
              </>
            ) : (
              false
            )}

            {jobDetails?.languageRequirement &&
            jobDetails?.languageRequirement?.length > 0 ? (
              <>
                <Typography variant="subtitle2" my={2}>
                  {intl.get(
                    "t_job_post_job_confirmation_step_language_requirement"
                  )}
                </Typography>
                {jobDetails?.languageRequirement?.map(
                  (singleLanguage, index) => {
                    return (
                      <>
                        {index === 2 ? (
                          <Typography variant="subtitle4" my={2}>
                            {intl.get(
                              "t_job_post_job_confirmation_step_other_languages"
                            )}
                          </Typography>
                        ) : (
                          false
                        )}
                        <StyledList key={index}>
                          <ListItem>
                            {getLanguageName(singleLanguage?.name)}:&nbsp;
                            {intl.get(
                              LANGUAGE_PROFICIENCY_T_LABELS[
                                singleLanguage?.proficiency as typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY]
                              ]
                            )}
                          </ListItem>
                        </StyledList>
                      </>
                    );
                  }
                )}
              </>
            ) : (
              false
            )}

            {jobDetails?.requiredSkills &&
            jobDetails?.requiredSkills.length > 0 ? (
              <>
                <Typography variant="subtitle2" mt={2}>
                  {intl.get("t_general_skills")}
                </Typography>
                <Stack direction="row" gap={2} flexWrap="wrap">
                  {isSkillsLoading ? (
                    <SkeletonKeyword multiple />
                  ) : (
                    <>
                      {skills.map((singleRequiredSkill, idx) => {
                        return (
                          <Keyword
                            label={`${singleRequiredSkill.name} - ${intl.get(
                              YEARS_OF_EXPERIENCE_T_LABELS[
                                singleRequiredSkill.years_of_experience
                              ]
                            )}`}
                            key={idx}
                          />
                        );
                      })}
                    </>
                  )}
                </Stack>
              </>
            ) : (
              false
            )}

            {jobDetails?.otherDetailsPlainText ? (
              <>
                <Typography variant="subtitle2" mt={2}>
                  {intl.get("t_job_details_other_details")}
                </Typography>
                <WYSIWYG
                  mode="view"
                  defaultValue={
                    jobDetails?.otherDetails ??
                    convertToRaw(ContentState.createFromText(""))
                  }
                />
              </>
            ) : (
              false
            )}

            {jobDetails?.additionInfoFileDetails?.name ? (
              <>
                <Typography variant="subtitle2" mt={2}>
                  {intl.get(
                    "t_job_post_job_confirmation_step_more_information"
                  )}
                </Typography>
                <br />
                <Typography variant="body1" mb={1} mt={2}>
                  <Stack
                    component="span"
                    direction="row"
                    alignItems="center"
                    gap={1}>
                    <Link underline="none" href={downloadFileUrl}>
                      <StyledDownloadButton
                        variant="text"
                        startAdornment={<Icon type="download" />}>
                        {jobDetails?.additionInfoFileDetails?.name}
                      </StyledDownloadButton>
                    </Link>
                  </Stack>
                </Typography>
              </>
            ) : (
              false
            )}

            {jobDetails?.cvFileRequirements?.is_en_cv_mandatory ||
            jobDetails?.cvFileRequirements?.is_ja_cv_mandatory ||
            jobDetails?.cvFileRequirements?.is_ja_rirekisho_mandatory ? (
              <>
                <Typography variant="subtitle2" my={2}>
                  {intl.get(
                    "t_job_post_job_additional_information_step_required_documents"
                  )}
                </Typography>
                <br />
                {jobDetails?.cvFileRequirements.is_en_cv_mandatory ? (
                  <StyledList>
                    <ListItem>{intl.get("t_general_english_cv")}</ListItem>
                  </StyledList>
                ) : (
                  false
                )}
                {jobDetails?.cvFileRequirements.is_ja_cv_mandatory ? (
                  <StyledList>
                    <ListItem>{intl.get("t_general_japanese_cv")}</ListItem>
                  </StyledList>
                ) : (
                  false
                )}
                {jobDetails?.cvFileRequirements.is_ja_rirekisho_mandatory ? (
                  <StyledList>
                    <ListItem>
                      {intl.get("t_general_japanese_rirekisho")}
                    </ListItem>
                  </StyledList>
                ) : (
                  false
                )}
              </>
            ) : (
              false
            )}
          </StyledPaperBody>
        </>
      ) : (
        <SkeletonEmployerJobDetailsDrawer />
      )}
    </Box>
  );
};

export default EmployerJobDetailsDrawer;
