import { useEffect, useState } from "react";

import { ReactIntlUniversal } from "react-intl-universal";

import { Stack } from "@mui/material";

import SkeletonKeyword from "@skeletons/SkeletonKeyword";

import ActionButton from "@components/ActionButton";
import Tag from "@components/Tag";
import Well from "@components/Well";

import {
  LOCALE,
  YEARS_OF_EXPERIENCE,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import { getSkillLabel } from "@utils/keyLabelHandlers/skill";
import { intl as intlGlobal } from "@utils/translate";

interface SkillsCardProps {
  skills?: Array<{
    name: string;
    yearsOfExperience: typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE];
  }>;
  handleEdit?: () => void;
  mode?: "edit" | "view";
  intl?: typeof intlGlobal | ReactIntlUniversal;
}

const SkillsCard = ({
  skills: candidateSkills = [],
  handleEdit,
  mode = "view",
  intl = intlGlobal
}: SkillsCardProps) => {
  const [skills, setSkills] = useState<
    Array<{
      name: string;
      yearsOfExperience: typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE];
    }>
  >([]);
  const [isSkillsLoading, setIsSkillsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setIsSkillsLoading(true);
      const skills = [];

      for (const singleSkill of candidateSkills) {
        const skillLabel = await getSkillLabel(
          singleSkill.name,
          intl.getInitOptions()
            .currentLocale as typeof LOCALE[keyof typeof LOCALE]
        );
        skills.push({
          name: skillLabel,
          yearsOfExperience: singleSkill.yearsOfExperience
        });
      }

      setSkills(skills);
      setIsSkillsLoading(false);
    })();
  }, [candidateSkills]);

  return (
    <>
      {candidateSkills && candidateSkills?.length > 0 ? (
        <Well showBorder={mode === "edit" ? true : false}>
          <Stack direction="row" justifyContent="space-between" width="100%">
            {/* Skills */}
            {isSkillsLoading ? (
              <SkeletonKeyword multiple />
            ) : (
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {skills?.map((singleSkill, index) => (
                  <Tag
                    label={`${singleSkill.name} (${intl.get(
                      YEARS_OF_EXPERIENCE_T_LABELS[
                        singleSkill.yearsOfExperience
                      ]
                    )})`}
                    key={index}
                  />
                ))}
              </Stack>
            )}
            {/* Actions */}
            {mode === "edit" && !isSkillsLoading ? (
              <Stack direction="row" alignItems="flex-start">
                <ActionButton
                  icon="edit"
                  color="blue"
                  handleAction={handleEdit}
                />
              </Stack>
            ) : (
              false
            )}
          </Stack>
        </Well>
      ) : (
        false
      )}
    </>
  );
};

export default SkillsCard;
