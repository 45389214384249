import { ChangeEvent, MouseEvent, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { NavigateNext as NavigateNextIcon } from "@mui/icons-material";
import {
  Stack,
  styled,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from "@mui/material";
import dayjs from "dayjs";
import {
  collection,
  doc,
  DocumentData,
  endBefore,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter
} from "firebase/firestore";

import SkeletonResumePurchaseDataTable from "@skeletons/SkeletonResumePurchaseDataTable";

import Button from "@components/Button";
import Cursor from "@components/Cursor";
import DataTableWrapper from "@components/DataTable/DataTableWrapper";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import UserID from "@interfaces/database/UserID";

import { FIRESTORE_COLLECTIONS, PAGINATION } from "@utils/config";
import { db } from "@utils/firebase";
import { resolveMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface Column {
  id: "dateOfPurchase" | "candidateName";
  label: string;
  align?: "left" | "right" | "center";
}

interface Row {
  candidateId: UserID;
  candidateName: string;
  dateOfPurchase: Timestamp;
}

const COLUMNS: ReadonlyArray<Column> = [
  {
    id: "dateOfPurchase",
    label: "t_employer_resume_purchase_table_date_of_purchase",
    align: "left"
  },
  {
    id: "candidateName",
    label: "t_employer_resume_purchase_table_candidate_name"
  }
];

const StyledTablePagination = styled(TablePagination)({
  "& .MuiTablePagination-select, & .MuiInputBase-root": {
    width: "max-content"
  }
});

const ResumePurchaseTable = () => {
  const companyProfile = useUserProfile();
  const toast = useToast();
  const navigate = useNavigate();

  const [rowData, setRowData] = useState<Array<Row>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    PAGINATION.TABLE_ROWS_PER_PAGE[0]
  );
  const [pageDirection, setPageDirection] = useState<"prev" | "next">("next");
  const [lastResumePurchaseData, setLastResumePurchaseData] =
    useState<DocumentData>();
  const [firstResumePurchaseData, setFirstResumePurchaseData] =
    useState<DocumentData>();
  const [totalResumePurchasedCount, setTotalResumePurchasedCount] =
    useState<number>(0);

  const fetchPurchasedResumes = async () => {
    try {
      setLoading(true);
      if (companyProfile.value?.company_id) {
        const purchasedResumeSubCollectionRef = collection(
          db,
          `${FIRESTORE_COLLECTIONS.COMPANIES}/${companyProfile?.value?.company_id}/${FIRESTORE_COLLECTIONS.PURCHASED_RESUMES}`
        );

        let resumePurchaseQuery;
        if (pageDirection === "next") {
          if (lastResumePurchaseData) {
            resumePurchaseQuery = query(
              purchasedResumeSubCollectionRef,
              startAfter(lastResumePurchaseData),
              orderBy("purchased_at", "desc"),
              limit(rowsPerPage)
            );
          } else {
            resumePurchaseQuery = query(
              purchasedResumeSubCollectionRef,
              orderBy("purchased_at", "desc"),
              limit(rowsPerPage)
            );
          }
        } else {
          if (firstResumePurchaseData) {
            resumePurchaseQuery = query(
              purchasedResumeSubCollectionRef,
              endBefore(firstResumePurchaseData),
              orderBy("purchased_at", "desc"),
              limitToLast(rowsPerPage)
            );
          } else {
            resumePurchaseQuery = query(
              purchasedResumeSubCollectionRef,
              orderBy("purchased_at", "desc"),
              limitToLast(rowsPerPage)
            );
          }
        }

        const purchasedResumeDocs = await getDocs(resumePurchaseQuery);
        const totalResumePurchasedCount = await getCountFromServer(
          query(purchasedResumeSubCollectionRef)
        );
        setTotalResumePurchasedCount(totalResumePurchasedCount.data().count);

        if (!purchasedResumeDocs.empty) {
          const tableRowData: Array<Row> = [];
          for (
            let index = 0;
            index < purchasedResumeDocs.docs.length;
            index++
          ) {
            const purchasedResumeDoc = purchasedResumeDocs.docs[index];
            if (purchasedResumeDoc.exists()) {
              // Fetch candidates name
              const candidateId = purchasedResumeDoc.data().candidate_id;
              const candidateDocRef = doc(
                db,
                FIRESTORE_COLLECTIONS.USERS,
                candidateId
              );
              const candidateDoc = await getDoc(candidateDocRef);
              const candidateData = candidateDoc.data();
              const candidateFirstName =
                resolveMultiLingual(
                  candidateData?.summary?.basic_information?.first_name
                ) ?? "";
              const candidateLastName =
                resolveMultiLingual(
                  candidateData?.summary?.basic_information?.last_name
                ) ?? "";
              const candidateFullName =
                `${candidateFirstName} ${candidateLastName}`.trim();
              tableRowData.push({
                candidateId: candidateDoc.id,
                candidateName: candidateFullName,
                dateOfPurchase: purchasedResumeDoc.data().purchased_at
              });
            }
          }
          setFirstResumePurchaseData(purchasedResumeDocs.docs[0]);
          setLastResumePurchaseData(
            purchasedResumeDocs.docs[purchasedResumeDocs.docs.length - 1]
          );
          setRowData(tableRowData);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (page < newPage) {
      setPageDirection("next");
    } else {
      setPageDirection("prev");
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value));
    setLastResumePurchaseData(undefined);
    setLastResumePurchaseData(undefined);
    setPage(0);
    setPageDirection("next");
  };

  useEffect(() => {
    fetchPurchasedResumes();
  }, [rowsPerPage, page]);

  if (loading) {
    return <SkeletonResumePurchaseDataTable />;
  }

  if (rowData.length === 0) {
    return (
      <Stack
        spacing={3}
        height={400}
        justifyContent="center"
        alignItems="center">
        <Typography variant="h3">
          {intl.get("t_employer_resume_purchase_not_purchased_title")}
        </Typography>
        <Typography variant="subtitle3">
          {intl.get("t_employer_resume_purchase_not_purchased_sub_title")}
        </Typography>
        <Button
          size="medium"
          endAdornment={<NavigateNextIcon />}
          handleClick={() =>
            navigate(`/${translate.getCurrentLocale()}/employers/dashboard`)
          }>
          {intl.get("t_employer_resume_purchase_not_purchased_button")}
        </Button>
      </Stack>
    );
  }

  return (
    <DataTableWrapper>
      <TableHead>
        <TableRow>
          {COLUMNS?.map((singleColumn) => (
            <TableCell
              key={singleColumn.id}
              width="20%"
              align={singleColumn?.align ?? "center"}>
              <Typography variant="subtitle5">
                {intl.get(singleColumn.label)}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rowData?.map((singleData: Row) => (
          <TableRow key={singleData.candidateId}>
            <TableCell align="left">
              <Typography variant="body2">
                <Cursor>
                  <Tooltip
                    title={dayjs(singleData.dateOfPurchase?.toDate()).format(
                      "ddd MMM D YYYY HH:mm:ss [GMT]Z (Z)"
                    )}>
                    <span>
                      {dayjs(singleData?.dateOfPurchase?.toDate())
                        .locale(translate.getCurrentLocaleShort())
                        .format("LL")}
                    </span>
                  </Tooltip>
                </Cursor>
              </Typography>
            </TableCell>
            <TableCell align="center">{singleData?.candidateName}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <StyledTablePagination
            rowsPerPageOptions={PAGINATION.TABLE_ROWS_PER_PAGE}
            count={totalResumePurchasedCount}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={intl.get("t_general_table_pagination_label")}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </DataTableWrapper>
  );
};

export default ResumePurchaseTable;
