import { User as FirebaseUser } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  Timestamp
} from "firebase/firestore";

import ApplicationID from "@interfaces/database/ApplicationID";
import AppliedInvitedJob from "@interfaces/database/AppliedInvitedJob";
import CompanyID from "@interfaces/database/CompanyID";
import JobApplicationInvitation from "@interfaces/database/JobApplicationInvitation";
import JobApplicationInvitationAction from "@interfaces/database/JobApplicationInvitationAction";
import JobID from "@interfaces/database/JobID";
import UserID from "@interfaces/database/UserID";

import { UserProfileContextStructure } from "@utils/components/UserProfileContext";
import {
  FIRESTORE_COLLECTIONS,
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  JOB_APPLY_STATUS_CODE,
  USER_TYPE
} from "@utils/config";
import { db } from "@utils/firebase";

export const canUserApply = (
  userProfile: UserProfileContextStructure,
  user: FirebaseUser | null | undefined // here null and undefined are required because the useAuthState returns the null or undefine for not login user
) => {
  if (!user?.uid || !userProfile?.value) {
    return JOB_APPLY_STATUS_CODE.UNAUTHENTICATED;
  }

  const isEnResumePresent =
    userProfile.value?.metadata?.progress_flags?.en_cv ?? false;
  const isJaResumePresent =
    userProfile.value?.metadata?.progress_flags?.ja_cv ?? false;
  const isJaRirekishoPresent =
    userProfile.value?.metadata?.progress_flags?.ja_rirekisho ?? false;

  const isProfileCompleted =
    isEnResumePresent || isJaResumePresent || isJaRirekishoPresent;
  const isEmailVerified = user?.emailVerified;

  if (userProfile.value?.user_type === USER_TYPE.INDIVIDUAL) {
    if (!isProfileCompleted) {
      return JOB_APPLY_STATUS_CODE.PROFILE_NOT_COMPLETED;
    } else if (!isEmailVerified) {
      return JOB_APPLY_STATUS_CODE.EMAIL_NOT_VERIFIED;
    } else {
      return JOB_APPLY_STATUS_CODE.CAN_APPLY;
    }
  } else {
    return JOB_APPLY_STATUS_CODE.CAN_NOT_APPLY;
  }
};

export const handleJobApply = async (
  jobId: JobID,
  companyId: CompanyID,
  userProfile: UserProfileContextStructure,
  user: FirebaseUser | null | undefined
) => {
  if (
    jobId &&
    user &&
    canUserApply(userProfile, user) === JOB_APPLY_STATUS_CODE.CAN_APPLY
  ) {
    const currentTimeStamp = Timestamp.now();

    const applicationAction: JobApplicationInvitationAction = {
      action_type: JOB_APPLICATION_INVITATION_ACTION_TYPE.APPLIED,
      initiator_user_id: user?.uid,
      updated_at: currentTimeStamp
    };

    const jobApplication: JobApplicationInvitation = {
      job_id: jobId,
      company_id: companyId,
      candidate_id: user?.uid,
      actions: [applicationAction]
    };

    try {
      const collectionReference = collection(
        db,
        `${FIRESTORE_COLLECTIONS.JOBS}/${jobId}/${FIRESTORE_COLLECTIONS.APPLICATIONS}`
      );
      const applicationDocument = await addDoc(
        collectionReference,
        jobApplication
      );

      if (applicationDocument.id && userProfile.value && userProfile.setValue) {
        if (!userProfile.value.jobs) {
          userProfile.value.jobs = {
            applied: [],
            invited: []
          };
        }

        if (!userProfile.value.jobs.applied) {
          userProfile.value.jobs.applied = [];
        }

        const newJobApplication: AppliedInvitedJob = {
          id: jobId,
          application_id: applicationDocument.id,
          status: JOB_APPLICATION_INVITATION_ACTION_TYPE.APPLIED,
          updated_at: currentTimeStamp
        };
        userProfile.value.jobs.applied.push(newJobApplication);

        //Remove applied job from invited jobs
        if (userProfile.value.jobs.invited) {
          const index = userProfile.value.jobs.invited.findIndex(
            (singleJob) => singleJob.id === jobId
          );

          if (index !== -1) {
            const updatedInvitedJobsData = [...userProfile.value.jobs.invited];
            updatedInvitedJobsData.splice(index, 1);
            userProfile.value.jobs.invited = updatedInvitedJobsData;
          }
        }

        await userProfile.setValue(userProfile.value);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
};

export const handleInvitedJobApply = async (
  jobId: JobID,
  applicationId: ApplicationID,
  userProfile: UserProfileContextStructure,
  userId: UserID
) => {
  if (jobId && applicationId && userId) {
    const currentTimeStamp = Timestamp.now();

    try {
      const applicationsRef = collection(
        db,
        `${FIRESTORE_COLLECTIONS.JOBS}/${jobId}/${FIRESTORE_COLLECTIONS.APPLICATIONS}`
      );
      const applicationDocumentRef = await doc(applicationsRef, applicationId);
      const applicationSnap = await getDoc(applicationDocumentRef);

      if (applicationSnap.exists()) {
        const applicationAction: JobApplicationInvitationAction = {
          action_type: JOB_APPLICATION_INVITATION_ACTION_TYPE.APPLIED,
          initiator_user_id: userId,
          updated_at: currentTimeStamp
        };

        const application = applicationSnap.data() as JobApplicationInvitation;
        if (!application.actions || !Array.isArray(application.actions)) {
          application.actions = [];
        }
        application.actions.push(applicationAction);

        await setDoc(applicationDocumentRef, application);

        if (userProfile.value && userProfile.setValue) {
          if (!userProfile.value.jobs) {
            userProfile.value.jobs = {
              applied: [],
              invited: []
            };
          }

          if (!userProfile.value.jobs.applied) {
            userProfile.value.jobs.applied = [];
          }

          const newJobApplication: AppliedInvitedJob = {
            id: jobId,
            application_id: applicationId,
            status: JOB_APPLICATION_INVITATION_ACTION_TYPE.APPLIED,
            updated_at: currentTimeStamp
          };
          userProfile.value.jobs.applied.push(newJobApplication);

          //Remove applied job from invited jobs
          if (userProfile.value.jobs.invited) {
            const index = userProfile.value.jobs.invited.findIndex(
              (singleJob) => singleJob.id === jobId
            );

            if (index !== -1) {
              const updatedInvitedJobsData = [
                ...userProfile.value.jobs.invited
              ];
              updatedInvitedJobsData.splice(index, 1);
              userProfile.value.jobs.invited = updatedInvitedJobsData;
            }
          }

          await userProfile.setValue(userProfile.value);
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {
      return false;
    }
  }
};
