import { useEffect, useState } from "react";

import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddCircle as AddCircleIcon,
  NavigateBefore as NavigateBeforeIcon
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import SkeletonLanguageForm from "@skeletons/SkeletonLanguageForm";

import Button from "@components/Button";
import Icon from "@components/Icon";
import NonTypeableSelect from "@components/NonTypeableSelect";
import Paper from "@components/Paper";
import TextField from "@components/TextField";
import TypeableSelect from "@components/TypeableSelect";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import KeyLabel from "@interfaces/components/KeyLabel";
import UserLanguage from "@interfaces/database/UserLanguage";

import {
  FREE_TEXT_FIELD_MAX_LENGTH,
  JA_ALPHA3_CODE,
  JA_LANGUAGE_TEST,
  JA_LANGUAGE_TEST_T_LABELS,
  LANGUAGE_PROFICIENCY,
  LANGUAGE_PROFICIENCY_T_LABELS,
  MAX_LANGUAGES,
  OTHER_LANGUAGE_PROFICIENCY,
  OTHER_LANGUAGE_PROFICIENCY_T_LABELS
} from "@utils/config";
import { jaTestLevelOptionsFinder } from "@utils/jaTestLevelOptionsFinder";
import { getDropdownLanguageList, getLanguageName } from "@utils/language";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface LanguageFormData {
  jaLanguage: {
    name: KeyLabel;
    proficiency: typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY];
    testName: string;
    testResult: string;
  };
  language: Array<{
    name: KeyLabel;
    proficiency: typeof OTHER_LANGUAGE_PROFICIENCY[keyof typeof OTHER_LANGUAGE_PROFICIENCY];
    testName: string;
    testResult: string;
  }>;
}

const StyledIconButton = styled(IconButton)(() => ({
  float: "right"
}));

const LanguageForm = () => {
  const userData = useUserProfile();
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [languageFieldIdsArr, setLanguageFieldIdsArr] = useState<Array<string>>(
    []
  );

  // validation schema
  const schema = yup.object({
    jaLanguage: yup.object({
      name: yup
        .object()
        .shape({
          key: yup.string(),
          label: yup.string()
        })
        .test(
          "name",
          intl.get("t_error_required", {
            field: intl.get("t_profile_language_heading")
          }),
          (value) => (value?.key && value?.label ? true : false)
        )
        .nullable(),
      proficiency: yup
        .string()
        .required(
          intl.get("t_error_required", {
            field: intl.get("t_profile_language_proficiency")
          })
        )
        .nullable(),
      testName: yup.string(),
      testResult: yup.string().when("testName", {
        is: (testName: string) => testName?.trim() !== "",
        then: (schema) =>
          schema.required(
            intl.get("t_error_required", {
              field: intl.get("t_profile_language_test_result")
            })
          )
      })
    }),
    language: yup
      .array()
      .of(
        yup.object().shape(
          {
            name: yup
              .object()
              .shape({
                key: yup.string(),
                label: yup.string()
              })
              .test(
                "name",
                intl.get("t_error_required", {
                  field: intl.get("t_profile_language_heading")
                }),
                (value) => (value?.key && value?.label ? true : false)
              )
              .nullable(),
            proficiency: yup
              .string()
              .required(
                intl.get("t_error_required", {
                  field: intl.get("t_profile_language_proficiency")
                })
              )
              .nullable(),
            testName: yup
              .string()
              .trim()
              .max(
                FREE_TEXT_FIELD_MAX_LENGTH,
                intl.get("t_error_max_limit", {
                  field: intl.get("t_profile_language_test"),
                  maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
                })
              )
              .when("testResult", {
                is: (testResult: string) => testResult?.trim() !== "",
                then: (schema) =>
                  schema.required(
                    intl.get("t_error_required", {
                      field: intl.get("t_profile_language_test")
                    })
                  )
              }),
            testResult: yup
              .string()
              .trim()
              .max(
                FREE_TEXT_FIELD_MAX_LENGTH,
                intl.get("t_error_max_limit", {
                  field: intl.get("t_profile_language_test_result"),
                  maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
                })
              )
              .when("testName", {
                is: (testName: string) => testName?.trim() !== "",
                then: (schema) =>
                  schema.required(
                    intl.get("t_error_required", {
                      field: intl.get("t_profile_language_test_result")
                    })
                  )
              })
          },
          [["testName", "testResult"]]
        )
      )
      .required()
  });

  const userLanguages = userData?.value?.summary?.languages ?? [];

  useEffect(() => {
    if (userLanguages && userLanguages?.length > 0) {
      const additionalLanguages = userLanguages.length - 1;
      setLanguageFieldIdsArr(
        Array.from({ length: additionalLanguages }, () => uuidv4())
      );
    }
  }, [userLanguages]);

  const formInitValues = {
    jaLanguage: {
      name: {
        key: JA_ALPHA3_CODE,
        label: getLanguageName(JA_ALPHA3_CODE) ?? ""
      },
      proficiency: userLanguages?.[0]?.proficiency ?? "",
      testName: userLanguages?.[0]?.test_name ?? "",
      testResult: userLanguages?.[0]?.test_result ?? ""
    },
    language:
      // ignore first language(Japanese) from array
      userLanguages && userLanguages?.length >= 1
        ? userLanguages
            ?.slice(1)
            .filter(
              (singleLanguageInfo) =>
                singleLanguageInfo?.name && singleLanguageInfo?.proficiency
            )
            .map((singleLanguageInfo) => {
              return {
                name: {
                  key: singleLanguageInfo?.name ?? JA_ALPHA3_CODE,
                  label:
                    getLanguageName(
                      singleLanguageInfo?.name ?? JA_ALPHA3_CODE
                    ) ?? ""
                },
                proficiency:
                  (singleLanguageInfo?.proficiency as typeof OTHER_LANGUAGE_PROFICIENCY[keyof typeof OTHER_LANGUAGE_PROFICIENCY]) ??
                  "",
                testName: singleLanguageInfo?.test_name ?? "",
                testResult: singleLanguageInfo?.test_result ?? ""
              };
            })
        : []
  };

  const methods = useForm({
    defaultValues: formInitValues,
    resolver: yupResolver(schema)
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    getValues,
    formState
  } = methods;

  const languageFieldArr = useFieldArray({
    control,
    name: "language"
  });

  useEffect(() => {
    reset(formInitValues);
    setLoading(false);
  }, []);

  const JA_LANGUAGE_TEST_OPTIONS = useOptions(
    JA_LANGUAGE_TEST,
    JA_LANGUAGE_TEST_T_LABELS
  );

  const LANGUAGE_PROFICIENCY_OPTIONS = useOptions(
    LANGUAGE_PROFICIENCY,
    LANGUAGE_PROFICIENCY_T_LABELS
  );

  const OTHER_LANGUAGE_PROFICIENCY_OPTIONS = useOptions(
    OTHER_LANGUAGE_PROFICIENCY,
    OTHER_LANGUAGE_PROFICIENCY_T_LABELS
  );

  const ALL_LANGUAGES = getDropdownLanguageList();

  const handleProfileLanguageSavedSuccess = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_success_profile_summary_saved"), "success");
  };

  const handleProfileLanguageSavedFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const onSubmit = async (formData: LanguageFormData) => {
    setIsDisabled(true);
    const { jaLanguage, language } = formData || {};
    const languages: Array<UserLanguage> = [
      {
        name: JA_ALPHA3_CODE,
        proficiency: jaLanguage.proficiency ?? LANGUAGE_PROFICIENCY.NONE,
        test_name: jaLanguage.testName ?? "",
        test_result: jaLanguage.testResult ?? ""
      },
      ...language.map(
        (singleLanguageData: {
          name: KeyLabel;
          proficiency: string;
          testName: string;
          testResult: string;
        }) => {
          return {
            name: singleLanguageData?.name?.key?.toUpperCase() ?? "",
            proficiency:
              (singleLanguageData.proficiency as typeof OTHER_LANGUAGE_PROFICIENCY[keyof typeof OTHER_LANGUAGE_PROFICIENCY]) ??
              "",
            test_name: singleLanguageData.testName ?? "",
            test_result: singleLanguageData.testResult ?? ""
          };
        }
      )
    ];

    try {
      if (userData?.setValue && userData?.value) {
        await userData.setValue(
          {
            ...userData.value,
            summary: {
              ...userData.value.summary,
              languages
            },
            updated_at: Timestamp.now()
          },
          handleProfileLanguageSavedSuccess,
          handleProfileLanguageSavedFail
        );
        navigate(`/${translate.getCurrentLocale()}/profile/edit`);
      }
    } catch (e) {
      handleProfileLanguageSavedFail();
    }
  };

  const handleRemoveLanguageField = (idx: number) => {
    setLanguageFieldIdsArr((oldLanguageFieldIdsArr) => {
      oldLanguageFieldIdsArr.splice(idx, 1);
      return [...oldLanguageFieldIdsArr];
    });
    const fieldValues = getValues("language");
    fieldValues.splice(idx, 1);
    languageFieldArr.replace(fieldValues);
  };

  const selectedJaTestName = watch("jaLanguage.testName");

  useEffect(() => {
    // check selected testName is modified or not and based on that reset the testResult value
    if (formState.dirtyFields?.jaLanguage?.testName) {
      setValue("jaLanguage.testResult", "");
    }
  }, [selectedJaTestName, setValue]);

  const JA_TEST_SCORE_OPTIONS = jaTestLevelOptionsFinder(selectedJaTestName);

  if (loading || userData.loading) {
    return <SkeletonLanguageForm />;
  }

  return (
    <Box noValidate component="form" onSubmit={handleSubmit(onSubmit)}>
      {/* Language section start */}
      <Paper>
        <Typography variant="h3">
          {intl.get("t_profile_language_heading")}
        </Typography>
        <Typography mt={2.5} mb={2} color="text.secondary">
          {intl.get("t_profile_language_sub_heading")}
        </Typography>

        <Grid container columnSpacing={3}>
          {/* form for japanese language start */}
          <Grid item xs={12} md={5.5}>
            <TypeableSelect
              disabled={isDisabled}
              control={control}
              name="jaLanguage.name"
              label={intl.get("t_profile_language_heading")}
              placeholder={intl.get("t_profile_language_heading")}
              options={[]}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={5.5}>
            <NonTypeableSelect
              disabled={isDisabled}
              setValue={setValue}
              control={control}
              name="jaLanguage.proficiency"
              label={intl.get("t_profile_language_proficiency")}
              placeholder={intl.get(
                "t_profile_language_proficiency_placeholder"
              )}
              required
              options={LANGUAGE_PROFICIENCY_OPTIONS}
            />
          </Grid>
          <Grid item xs={12} md={5.5}>
            <NonTypeableSelect
              disabled={isDisabled}
              setValue={setValue}
              control={control}
              name="jaLanguage.testName"
              required={Boolean(selectedJaTestName)}
              label={intl.get("t_profile_language_test")}
              placeholder={intl.get("t_profile_language_test")}
              options={JA_LANGUAGE_TEST_OPTIONS}
            />
          </Grid>
          <Grid item xs={12} md={5.5}>
            <NonTypeableSelect
              disabled={isDisabled || !selectedJaTestName}
              required={Boolean(selectedJaTestName)}
              setValue={setValue}
              control={control}
              name="jaLanguage.testResult"
              label={intl.get("t_profile_language_test_result")}
              placeholder={intl.get("t_profile_language_test_result")}
              options={JA_TEST_SCORE_OPTIONS}
            />
          </Grid>
          {/* form for japanese language end */}
          <Grid item xs={12} md={5.5}>
            <TransitionGroup>
              {languageFieldIdsArr.map((id, index) => {
                return (
                  <Collapse key={id}>
                    <Grid item xs={12} md={5.5} mt={3}>
                      <TypeableSelect
                        disabled={isDisabled}
                        control={control}
                        name={`language.${index}.name`}
                        label={intl.get("t_profile_language_heading")}
                        placeholder={intl.get("t_profile_language_heading")}
                        required
                        options={ALL_LANGUAGES}
                      />
                    </Grid>
                    <Grid item xs={12} md={5.5}>
                      <NonTypeableSelect
                        disabled={isDisabled}
                        setValue={setValue}
                        control={control}
                        name={`language.${index}.proficiency`}
                        label={intl.get("t_profile_language_proficiency")}
                        placeholder={intl.get(
                          "t_profile_language_proficiency_placeholder"
                        )}
                        required
                        options={OTHER_LANGUAGE_PROFICIENCY_OPTIONS}
                      />
                    </Grid>
                    <Grid item xs={12} md={5.5}>
                      <TextField
                        disabled={isDisabled}
                        control={control}
                        name={`language.${index}.testName`}
                        label={intl.get("t_profile_language_test")}
                        placeholder={intl.get("t_profile_language_test")}
                        required={
                          Boolean(watch(`language.${index}.testName`)) ||
                          Boolean(watch(`language.${index}.testResult`))
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={5.5}>
                      <TextField
                        disabled={isDisabled}
                        control={control}
                        name={`language.${index}.testResult`}
                        label={intl.get("t_profile_language_test_result")}
                        placeholder={intl.get("t_profile_language_test_result")}
                        required={
                          Boolean(watch(`language.${index}.testName`)) ||
                          Boolean(watch(`language.${index}.testResult`))
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <StyledIconButton
                        disabled={isDisabled}
                        onClick={() => handleRemoveLanguageField(index)}>
                        <Icon type="delete" filled />
                      </StyledIconButton>
                    </Grid>
                  </Collapse>
                );
              })}
            </TransitionGroup>
          </Grid>
        </Grid>
        <Button
          color="primary"
          disabled={isDisabled}
          handleClick={() => {
            // if the max limit is exceeded, set a toast notification
            if (languageFieldArr.fields.length >= MAX_LANGUAGES - 1) {
              toast.kampai(
                intl.get("t_toast_error_limit_exceed", {
                  fieldName: intl.get("t_profile_summary_language"),
                  maxLimit: MAX_LANGUAGES
                }),
                "error"
              );
            } else {
              languageFieldArr.append({
                name: { key: "", label: "" },
                proficiency: OTHER_LANGUAGE_PROFICIENCY.NATIVE,
                testName: "",
                testResult: ""
              });
              setLanguageFieldIdsArr((oldLanguageFieldIdsArr) => {
                return [...oldLanguageFieldIdsArr, uuidv4()];
              });
            }
          }}
          size="small"
          startAdornment={<AddCircleIcon />}
          variant="text">
          {intl.get("t_general_add_another")}
        </Button>
      </Paper>
      {/* Language section end */}

      <Stack justifyContent="space-between" direction="row" mt={5.5}>
        <Button
          handleClick={() =>
            navigate(`/${translate.getCurrentLocale()}/profile/edit`)
          }
          variant="outlined"
          startAdornment={<NavigateBeforeIcon />}>
          {intl.get("t_general_back")}
        </Button>
        <Button type="submit" loading={isDisabled}>
          {intl.get("t_general_save")}
        </Button>
      </Stack>
    </Box>
  );
};

export default LanguageForm;
