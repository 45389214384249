import { Box, Stack } from "@mui/material";

import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonCompanyInfo from "@skeletons/SkeletonCompanyInfo";
import SkeletonJobCategory from "@skeletons/SkeletonJobCategory";
import SkeletonStatus from "@skeletons/SkeletonStatus";
import SkeletonTypography from "@skeletons/SkeletonTypography";

const SkeletonInvitedJobCard = () => {
  return (
    <Box p={2} borderRadius={1} bgcolor="common.white">
      {/* job status and time text start */}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <SkeletonStatus />
        <SkeletonTypography variant="body2" ml="auto" />
      </Box>
      {/* job status and time text end */}

      {/* company and role information start */}
      <SkeletonCompanyInfo />
      {/* company and role information end */}

      {/* category and buttons for desktop start */}
      <Box gap={1} display="flex" alignItems="center" flexWrap="wrap">
        <SkeletonJobCategory multiple />
        <Stack direction="row" gap={1} ml="auto">
          <SkeletonButton />
          <SkeletonButton />
        </Stack>
      </Box>
      {/* category and buttons for desktop end */}
    </Box>
  );
};

export default SkeletonInvitedJobCard;
