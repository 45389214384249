import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { NavigateBefore as NavigateBeforeIcon } from "@mui/icons-material";
import { Box, Grid, Stack } from "@mui/material";
import * as yup from "yup";

import SkeletonEducationForm from "@skeletons/SkeletonEducationForm";

import Button from "@components/Button";
import DatePicker from "@components/DatePicker";
import NonTypeableSelect from "@components/NonTypeableSelect";
import Paper from "@components/Paper";
import TextField from "@components/TextField";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";

import {
  FREE_TEXT_FIELD_MAX_LENGTH,
  INDIVIDUAL_USER_DEGREE_TYPE,
  INDIVIDUAL_USER_DEGREE_TYPE_T_LABELS,
  LOCALE_SHORT
} from "@utils/config";
import { intl } from "@utils/translate";

interface EducationForm {
  institutionName?: string;
  degreeType?: string;
  fieldOfStudy?: string;
  startDate?: Date | string;
  endDate?: Date | string;
}

interface EducationFormProps {
  initialValues?: EducationForm;
  handleFormSubmit: (formData: EducationForm) => void;
  backBtnLink?: string;
  isDisabled?: boolean;
  formLanguage?: typeof LOCALE_SHORT[keyof typeof LOCALE_SHORT];
}

const EducationForm = ({
  initialValues,
  handleFormSubmit,
  backBtnLink,
  isDisabled = false,
  formLanguage = LOCALE_SHORT.JA
}: EducationFormProps) => {
  const navigate = useNavigate();
  const userData = useUserProfile();
  const [loading, setLoading] = useState<boolean>(true);

  const schema = yup.object({
    institutionName: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_resumes_education_form_label_institution"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_resumes_education_form_label_institution")
        })
      ),
    degreeType: yup.string().required(
      intl.get("t_error_required", {
        field: intl.get("t_resumes_education_form_label_degree_type")
      })
    ),
    fieldOfStudy: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_resumes_education_form_label_field_of_study"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_resumes_education_form_label_field_of_study")
        })
      ),
    startDate: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_resumes_education_form_label_start_date")
        })
      )
      .typeError(
        intl.get("t_error_invalid", {
          field: intl.get("t_resumes_education_form_label_start_date")
        })
      ),
    endDate: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .min(
        yup.ref("startDate"),
        intl.get("t_error_minimum_date", {
          endDate: intl.get("t_resumes_education_form_label_end_date"),
          startDate: intl.get("t_resumes_education_form_label_start_date")
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_resumes_education_form_label_end_date")
        })
      )
      .typeError(
        intl.get("t_error_invalid", {
          field: intl.get("t_resumes_education_form_label_end_date")
        })
      )
  });

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const DEGREE_TYPE_OPTIONS = useOptions(
    INDIVIDUAL_USER_DEGREE_TYPE,
    INDIVIDUAL_USER_DEGREE_TYPE_T_LABELS
  );

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading || userData.loading) {
    return <SkeletonEducationForm />;
  }

  return (
    <Paper>
      <Typography variant="h3">
        {intl.get("t_resumes_education_title")}
      </Typography>
      <br />
      <Typography variant="body1" color="text.secondary" mt={2.5}>
        {intl.get("t_resumes_add_education_form_subtitle")}
      </Typography>
      <br />
      <Typography mb={3} color="text.secondary">
        {formLanguage === LOCALE_SHORT.JA
          ? intl.get("t_resumes_form_subtitle_lang_japanese")
          : intl.get("t_resumes_form_subtitle_lang_english")}
      </Typography>
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container columnSpacing={3}>
          <Grid item xs={12}>
            <TextField
              data-testid="resumes_education_institution_name_input"
              disabled={isDisabled}
              name="institutionName"
              label={intl.get("t_resumes_education_form_label_institution")}
              placeholder={intl.get(
                "t_resumes_education_form_label_institution"
              )}
              control={control}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <NonTypeableSelect
              data-testid="resumes_education_degree_type_select"
              disabled={isDisabled}
              name="degreeType"
              label={intl.get("t_resumes_education_form_label_degree_type")}
              placeholder={intl.get(
                "t_resumes_education_form_label_degree_type"
              )}
              control={control}
              setValue={setValue}
              required
              options={DEGREE_TYPE_OPTIONS}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              data-testid="resumes_education_field_of_study_input"
              disabled={isDisabled}
              name="fieldOfStudy"
              label={intl.get("t_resumes_education_form_label_field_of_study")}
              placeholder={intl.get(
                "t_resumes_education_form_label_field_of_study"
              )}
              control={control}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              data-testid="resumes_education_start_date_input"
              disabled={isDisabled}
              name="startDate"
              label={intl.get("t_resumes_education_form_label_start_date")}
              control={control}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              data-testid="resumes_education_end_date_input"
              disabled={isDisabled}
              name="endDate"
              label={intl.get("t_resumes_education_form_label_end_date")}
              control={control}
              required
              disableFuture={false}
            />
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="space-between">
          {backBtnLink ? (
            <Button
              data-testid="resumes_education_back_button"
              variant="outlined"
              startAdornment={<NavigateBeforeIcon />}
              handleClick={() => navigate(backBtnLink)}>
              {intl.get("t_general_back")}
            </Button>
          ) : (
            false
          )}
          <Button
            type="submit"
            loading={isDisabled}
            data-testid="resumes_education_save_button">
            {intl.get("t_general_save")}
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};

export default EducationForm;
