import { Client } from "typesense";

import typesenseConfig from "@utils/typesense/config";

const getClient = () => {
  const client = new Client(typesenseConfig);
  return client;
};

export default getClient;
