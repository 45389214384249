/**
 * Returns the initials of the user i.e first letter from each word of the user's name.
 *
 * @param {string} fullName  The full name of the user
 *
 * @returns {string} The initials of the user.
 */

const fetchInitials = (fullName: string) => {
  const initials = fullName
    .split(" ")
    .slice(0, 2)
    .map((word) => word[0])
    .join("");

  return initials;
};

export default fetchInitials;
