import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import dayjs from "dayjs";
import { collection, getDocs, query, where } from "firebase/firestore";
import useForceUpdate from "use-force-update";

import SkeletonTypography from "@skeletons/SkeletonTypography";

import ResumePurchaseTable from "@components/DataTable/ResumePurchaseTable";
import Paper from "@components/Paper";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";

import { FIRESTORE_COLLECTIONS } from "@utils/config";
import { db } from "@utils/firebase";
import translate, { intl } from "@utils/translate";

const ResumePurchases = () => {
  const companyProfile = useUserProfile();
  const forceUpdate = useForceUpdate();

  const [monthlyResumePurchaseCount, setMonthlyResumePurchaseCount] =
    useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const languageChanged = () => {
    forceUpdate();
  };

  useEffect(() => {
    if (companyProfile.value?.company_id) {
      const getMonthlyResumePurchaseCount = async () => {
        setIsLoading(true);
        const firstDayOfMonth = dayjs().startOf("month").toDate();
        const lastDayOfMonth = dayjs().endOf("month").toDate();

        const purchasedResumeSubCollectionRef = collection(
          db,
          `${FIRESTORE_COLLECTIONS.COMPANIES}/${companyProfile?.value?.company_id}/${FIRESTORE_COLLECTIONS.PURCHASED_RESUMES}`
        );
        const monthlyResumePurchase = await getDocs(
          query(
            purchasedResumeSubCollectionRef,
            where("purchased_at", ">=", firstDayOfMonth),
            where("purchased_at", "<=", lastDayOfMonth)
          )
        );
        setMonthlyResumePurchaseCount(monthlyResumePurchase.size);
        setIsLoading(false);
      };
      getMonthlyResumePurchaseCount();
    }
  }, []);

  useEffect(() => {
    translate.addLanguageChangedListener(languageChanged);
    return () => {
      translate.removeLanguageChangedListener(languageChanged);
    };
  }, []);

  return (
    <Paper>
      <Typography variant="h3">
        {intl.get("t_employer_resume_purchase_page_heading")}
      </Typography>
      <br />
      <Box mt={2.5} mb={3}>
        {isLoading ? (
          <SkeletonTypography width="medium" />
        ) : (
          <>
            <Typography variant="body1">
              {intl.get("t_employer_resume_purchase_page_sub_heading")}:
            </Typography>
            <Typography variant="subtitle4">
              &nbsp;{monthlyResumePurchaseCount}
            </Typography>
          </>
        )}
      </Box>
      <ResumePurchaseTable />
    </Paper>
  );
};

export default ResumePurchases;
