import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { FirebaseError } from "firebase/app";
import { httpsCallable } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";

import BasicInformationForm from "@components/ResumeForms/BasicInformationForm";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import KeyLabel from "@interfaces/components/KeyLabel";

import {
  INDIVIDUAL_USER_NUMBER_OF_COMPANIES,
  INDIVIDUAL_USER_VISA_TYPE,
  INDIVIDUAL_USER_VISA_TYPE_T_LABELS,
  JP_ALPHA3_CODE,
  LOCALE,
  LOCALE_SHORT
} from "@utils/config";
import { auth, functions } from "@utils/firebase";
import { getCountryName } from "@utils/location";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface BasicInformationFormData {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  countryOfOrigin?: KeyLabel;
  visaType?: KeyLabel;
  country?: KeyLabel;
  city?: KeyLabel;
  numberOfCompanies?: typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES[keyof typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES];
  expectedSalary?: number;
}

const EnglishResumeEditBasicInfo = () => {
  const [user] = useAuthState(auth);
  const userData = useUserProfile();
  const navigate = useNavigate();
  const toast = useToast();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const userBasicInformation = userData.value?.summary?.basic_information;
  const initialValues = {
    firstName: resolveMultiLingual(userBasicInformation?.first_name, LOCALE.EN),
    lastName: resolveMultiLingual(userBasicInformation?.last_name, LOCALE.EN),
    phoneNumber: userBasicInformation?.phone_number ?? "",
    countryOfOrigin: {
      key: userBasicInformation?.immigration_details?.country ?? "",
      label:
        getCountryName(
          userBasicInformation?.immigration_details?.country ?? ""
        ) ?? ""
    },
    visaType: {
      key: userBasicInformation?.immigration_details?.visa_type ?? "",
      label: userBasicInformation?.immigration_details?.visa_type
        ? intl.get(
            INDIVIDUAL_USER_VISA_TYPE_T_LABELS[
              userBasicInformation?.immigration_details
                ?.visa_type as keyof typeof INDIVIDUAL_USER_VISA_TYPE_T_LABELS
            ]
          )
        : ""
    },
    country: {
      key: userBasicInformation?.current_location?.country ?? "",
      label:
        getCountryName(userBasicInformation?.current_location?.country ?? "") ??
        ""
    },
    city: {
      key: userBasicInformation?.current_location?.city ?? "",
      label: userBasicInformation?.current_location?.city ?? ""
    },
    numberOfCompanies: userBasicInformation?.number_of_companies,
    expectedSalary: Number(userBasicInformation?.expected_salary)
  };

  const handleBasicInfoSavedSuccess = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_success_resume_saved"), "success");
  };

  const handleBasicInfoSavedFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleFormSubmit = async (formData: BasicInformationFormData) => {
    setIsDisabled(true);
    const {
      city,
      country,
      countryOfOrigin,
      expectedSalary,
      firstName,
      lastName,
      numberOfCompanies,
      phoneNumber,
      visaType
    } = formData || {};

    try {
      if (
        userData.setValue &&
        userData.value &&
        userData.value?.summary?.basic_information
      ) {
        try {
          if (phoneNumber !== userBasicInformation?.phone_number) {
            const setUserPhoneNumber = httpsCallable(
              functions,
              "setUserPhoneNumber"
            );
            await setUserPhoneNumber(phoneNumber);
          }
        } catch (e) {
          if (e instanceof FirebaseError) {
            if (e.code === "functions/invalid-argument") {
              toast.kampai(
                intl.get("t_toast_error_phone_number_format_is_incorrect"),
                "error"
              );
            } else if (e.code === "functions/already-exists") {
              toast.kampai(
                intl.get("t_toast_error_phone_number_already_in_use"),
                "error"
              );
            } else {
              toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
            }
          }
          setIsDisabled(false);
          return;
        }

        await userData.setValue(
          {
            ...userData.value,
            summary: {
              ...userData.value.summary,
              basic_information: {
                ...userData.value?.summary?.basic_information,
                first_name: prepareMultiLingual(
                  firstName ?? "",
                  LOCALE.EN,
                  userData.value?.summary?.basic_information?.first_name
                ),
                last_name: prepareMultiLingual(
                  lastName ?? "",
                  LOCALE.EN,
                  userData.value?.summary?.basic_information?.last_name
                ),
                phone_number: phoneNumber ?? "",
                email: user?.email ?? "",
                current_location: {
                  country: country?.key ?? "",
                  city: city?.key ?? ""
                },
                immigration_details: {
                  country: countryOfOrigin?.key ?? "",
                  ...(countryOfOrigin?.key === JP_ALPHA3_CODE || !visaType?.key
                    ? {}
                    : {
                        visa_type:
                          (visaType?.key as typeof INDIVIDUAL_USER_VISA_TYPE[keyof typeof INDIVIDUAL_USER_VISA_TYPE]) ??
                          ""
                      })
                },
                number_of_companies: numberOfCompanies,
                expected_salary: expectedSalary
              }
            },
            updated_at: Timestamp.now()
          },
          handleBasicInfoSavedSuccess,
          handleBasicInfoSavedFail,
          {
            forceReloadUser: true
          }
        );
        navigate(`/${translate.getCurrentLocale()}/profile/resume/en-cv`);
      }
    } catch (e) {
      handleBasicInfoSavedFail();
    }
  };

  return (
    <BasicInformationForm
      isDisabled={isDisabled}
      initialValues={initialValues}
      handleFormSubmit={handleFormSubmit}
      backBtnLink={`/${translate.getCurrentLocale()}/profile/resume/en-cv`}
      formLanguage={LOCALE_SHORT.EN}
    />
  );
};

export default EnglishResumeEditBasicInfo;
