import { useEffect, useState } from "react";

import { Box, Stack } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { ref } from "firebase/storage";
import { useUploadFile } from "react-firebase-hooks/storage";

import CompanyInfoForm from "@pages/EmployersPostJob/CompanyInfoForm";

import SkeletonButton from "@skeletons/SkeletonButton";

import Avatar from "@components/Avatar";
import Button from "@components/Button";
import Paper from "@components/Paper";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import {
  COMPANY_LOGO_UPLOAD_CONFIG,
  FILES_LOCATION_COMPANY_LOGO
} from "@utils/config";
import { functions, storage } from "@utils/firebase";
import getFileMetaInformation from "@utils/getFileMetaInformation";
import { getFileURL } from "@utils/getFileURL";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import { intl } from "@utils/translate";

interface CompanyInformationFormData {
  companyName: string;
  companyWebsiteURL: string;
}

const CompanySettings = () => {
  const [uploadFile] = useUploadFile();
  const companyDetails = useCompanyDetails();
  const userData = useUserProfile();
  const toast = useToast();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const companyId = userData?.value?.company_id;
  const isCompanyDetailsLoading = companyDetails.loading;
  const imageURL = companyDetails?.value?.logo
    ? getFileURL(
        FILES_LOCATION_COMPANY_LOGO,
        companyId,
        companyDetails?.value?.logo?.extension,
        companyDetails?.value?.logo?.uploaded_at
      )
    : "";
  const [companyLogoUrl, setCompanyLogoUrl] = useState<string>(imageURL);
  const [companyLogoFile, setCompanyLogoFile] = useState<File>();

  const handleCompanyUpdateSuccess = async () => {
    setIsDisabled(false);
    toast.kampai(
      intl.get("t_company_setting_company_info_save_success"),
      "success"
    );

    //Sync company data in Typesense
    const syncFunction = httpsCallable(
      functions,
      "syncCompanyJobsWithTypesense"
    );
    syncFunction(companyId);
  };

  const handleCompanyUpdateFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  useEffect(() => {
    setCompanyLogoUrl(imageURL);
  }, [imageURL]);

  const handleCompanyInformationFormSubmit = async (
    formData: CompanyInformationFormData
  ) => {
    try {
      setIsDisabled(true);
      const currentTime = Timestamp.now();
      const { companyName, companyWebsiteURL } = formData || {};
      let companyLogoMetadata;
      if (companyLogoFile) {
        const { fileExt, fileName, fileSize, fileType } =
          getFileMetaInformation(companyLogoFile);
        companyLogoMetadata = {
          name: fileName,
          extension: fileExt,
          size: fileSize,
          uploaded_at: currentTime
        };

        if (companyId) {
          const path = `${FILES_LOCATION_COMPANY_LOGO}/${companyId}.${fileExt}`;
          const storageRef = ref(storage, path);

          await uploadFile(storageRef, companyLogoFile, {
            contentType: fileType,
            customMetadata: {
              v: currentTime.toDate().getTime().toString()
            }
          });
        }
      }

      if (companyDetails.setValue && companyDetails.value) {
        await companyDetails.setValue(
          {
            ...companyDetails.value,
            name: prepareMultiLingual(
              companyName,
              undefined,
              companyDetails.value.name
            ),
            website_url: companyWebsiteURL,
            ...(companyLogoFile ? { logo: companyLogoMetadata } : {}),
            updated_at: currentTime
          },
          handleCompanyUpdateSuccess,
          handleCompanyUpdateFail
        );
      }
    } catch (e) {
      handleCompanyUpdateFail();
    }
  };

  const handleCompanyLogoUpload = (imageFile: File) => {
    const companyLogo = URL.createObjectURL(imageFile);
    setCompanyLogoFile(imageFile);
    if (companyLogo) {
      setCompanyLogoUrl(companyLogo);
    }
  };

  return (
    <>
      <Paper>
        <Typography variant="h3">
          {intl.get("t_job_post_company_information_step_label")}
        </Typography>
        <br />
        <Typography variant="subtitle4" mt={3}>
          {intl.get("t_job_post_company_information_step_company_logo_label")}
        </Typography>
        <br />
        <Typography variant="body2" color="text.secondary" my={1.5}>
          {intl.get(
            "t_job_post_company_information_step_company_logo_format_label"
          )}
        </Typography>
        <Box mb={2}>
          <Avatar
            variant="rounded"
            imgSrc={companyLogoUrl}
            allowAddPhoto={!isDisabled}
            iconType="domain"
            handleImageChange={handleCompanyLogoUpload}
            config={COMPANY_LOGO_UPLOAD_CONFIG}
          />
        </Box>
        <CompanyInfoForm
          initialValues={{
            companyName: resolveMultiLingual(companyDetails.value?.name) ?? "",
            companyWebsiteURL: companyDetails.value?.website_url ?? ""
          }}
          handleFormSubmit={handleCompanyInformationFormSubmit}
          isDisabled={isDisabled}
        />
      </Paper>
      <Stack direction="row-reverse" mt={3}>
        {isCompanyDetailsLoading ? (
          <SkeletonButton />
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            formId="company-information-form">
            {intl.get("t_general_save")}
          </Button>
        )}
      </Stack>
    </>
  );
};

export default CompanySettings;
