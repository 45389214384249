import { Link, useLocation } from "react-router-dom";

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Link as MuiLink,
  styled
} from "@mui/material";

import Typography from "@components/Typography";

import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";
import { ENVIRONMENT } from "@utils/config";

interface SideMenuProps {
  menuLinks: Array<{
    label: string;
    redirectLink: string;
    openInNewTab?: boolean;
    aliasLinks?: Array<string>;
    envSpecific?: typeof ENVIRONMENT[keyof typeof ENVIRONMENT];
    id?: string;
  }>;
}

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  "paddingLeft": 16,
  "paddingRight": 16,
  "paddingTop": 8,
  "paddingBottom": 8,
  ".MuiListItemText-primary": {
    "color": theme.palette.text.secondary,
    "&: hover": {
      color: theme.palette.text.primary
    }
  },
  "&: hover": {
    background: theme.palette.background.paper
  },
  "&.Mui-selected": {
    "background": theme.palette.background.paper,
    ".MuiListItemText-primary": {
      color: theme.palette.text.primary
    },
    "&: hover": {
      background: theme.palette.background.paper
    }
  }
}));

const SideMenu = ({ menuLinks }: SideMenuProps) => {
  const currentPath = useLocation();
  const pathname = currentPath.pathname;
  return (
    <Box bgcolor="background.paper" borderRadius={1} px={2} py={1}>
      <List data-testid="side_menu_list">
        {menuLinks.map((singleMenuLink) => {
          const label = singleMenuLink.label.toLowerCase().replace(" ", "_");
          const dataTestId = `side_menu_${label}_link`;
          const linkComponent = (
            <MuiLink
              id={singleMenuLink?.id}
              key={singleMenuLink.label}
              component={Link}
              to={singleMenuLink.redirectLink}
              target={singleMenuLink?.openInNewTab ? "_blank" : ""}
              underline="none">
              <ListItem disablePadding>
                <StyledListItemButton
                  disableRipple
                  selected={
                    pathname === singleMenuLink.redirectLink ||
                    singleMenuLink.aliasLinks?.includes(pathname)
                  }>
                  <ListItemText data-testid={dataTestId}>
                    <Typography variant="subtitle2">
                      {singleMenuLink.label}
                    </Typography>
                  </ListItemText>
                </StyledListItemButton>
              </ListItem>
            </MuiLink>
          );

          return singleMenuLink.envSpecific ? (
            <EnvironmentSpecific
              env={singleMenuLink.envSpecific}
              key={singleMenuLink.label}>
              {linkComponent}
            </EnvironmentSpecific>
          ) : (
            linkComponent
          );
        })}
      </List>
    </Box>
  );
};

export default SideMenu;
