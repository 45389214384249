import { useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import * as yup from "yup";

import Button from "@components/Button";
import TextField from "@components/TextField";
import Typography from "@components/Typography";

import translate, { intl } from "@utils/translate";

interface GenerateCandidateProfileURLForm {
  candidateId: string;
}

const GenerateCandidateProfileURL = () => {
  const navigate = useNavigate();
  const [candidateProfileUrl, setCandidateProfileUrl] = useState<string>("");

  // validation schema
  const schema = yup.object({
    candidateId: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: "Candidate Id"
        })
      )
  });

  const methods = useForm({
    defaultValues: {
      candidateId: ""
    },
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control } = methods;

  const handleFormSubmit = (formData: GenerateCandidateProfileURLForm) => {
    const { candidateId } = formData;
    const baseUrl = window.location.origin;
    const profilePath = `/${translate.getCurrentLocale()}/candidate-profile/${candidateId}`;
    const fullUrl = `${baseUrl}${profilePath}`;
    setCandidateProfileUrl(fullUrl);
    navigate(profilePath);
  };

  return (
    <Box
      mb={2}
      noValidate
      component="form"
      onSubmit={handleSubmit(handleFormSubmit)}>
      <Typography variant="h5" my={2.5}>
        Generate Candidate Profile URL
      </Typography>
      <TextField
        control={control}
        name="candidateId"
        label="Candidate Id"
        placeholder="Enter candidate id"
        required
      />
      <Button type="submit">Generate URL</Button>
      <br />
      <Typography variant="body2">{candidateProfileUrl}</Typography>
    </Box>
  );
};

export default GenerateCandidateProfileURL;
