import SkeletonTypography from "@skeletons/SkeletonTypography";

import Well from "@components/Well";

interface SkeletonJobDescriptionCardProps {
  mode?: "edit" | "view";
}

const SkeletonJobDescriptionCard = ({
  mode = "view"
}: SkeletonJobDescriptionCardProps) => {
  return (
    <Well showBorder={mode === "edit" ? true : false}>
      <SkeletonTypography variant="subtitle2" width="short" />
      <SkeletonTypography variant="subtitle4" width="long" />
      <SkeletonTypography variant="subtitle4" width="long" />
      <SkeletonTypography variant="subtitle4" width="short" />
      <SkeletonTypography variant="subtitle4" width="medium" mb={1} />

      <SkeletonTypography variant="subtitle2" width="short" mb={1} />
      <SkeletonTypography variant="body1" width="full" multiLine />
    </Well>
  );
};

export default SkeletonJobDescriptionCard;
