import { Box } from "@mui/material";

import SkeletonTypography from "@skeletons/SkeletonTypography";

const SkeletonStatisticsCard = () => {
  return (
    <Box bgcolor="background.paper" borderRadius={1} p={2} width="100%">
      <SkeletonTypography variant="h5" width="long" />
      <br />
      <SkeletonTypography variant="h3" width="short" />
    </Box>
  );
};

export default SkeletonStatisticsCard;
