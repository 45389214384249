import { Stack } from "@mui/material";

import SkeletonTypography from "@skeletons/SkeletonTypography";

import Well from "@components/Well";

interface SkeletonBasicInformationCardProps {
  mode?: "edit" | "view";
  numOfFields?: number;
}

const SkeletonBasicInformationCard = ({
  mode = "view",
  numOfFields = 8
}: SkeletonBasicInformationCardProps) => {
  return (
    <Well showBorder={mode === "edit" ? true : false}>
      <Stack direction="column" width="100%">
        {[...Array(numOfFields)].map((_, idx) => {
          return (
            <SkeletonTypography key={idx} variant="body1" width="medium" />
          );
        })}
      </Stack>
    </Well>
  );
};

export default SkeletonBasicInformationCard;
