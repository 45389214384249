/**
 * Encodes the input text by representing each character using its ASCII code.
 *
 * @param {string} text The input text to be encoded.
 *
 * @returns {string} The ASCII-encoded representation of the input text.
 */

const encodeStringToAscii = (text: string): string => {
  const encodedTextArray = [];

  for (let i = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i);
    encodedTextArray.push(charCode);
  }

  const encodedText: string = encodedTextArray.join(",");
  return encodedText;
};

export default encodeStringToAscii;
