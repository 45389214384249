import { Fragment } from "react";

import { Grid, Stack } from "@mui/material";

import SkeletonInput from "@skeletons/SkeletonInput";
import SkeletonKeyword from "@skeletons/SkeletonKeyword";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Typography from "@components/Typography";

import { intl } from "@utils/translate";

const SkeletonSkillRequirementForm = () => {
  return (
    <>
      <Grid container columnSpacing={3}>
        <Fragment>
          <Grid item xs={12} md={6}>
            <SkeletonInput />
          </Grid>
          <Grid item xs={12} md={6}>
            <SkeletonInput />
          </Grid>
        </Fragment>
      </Grid>
      {/* add another text */}
      <SkeletonTypography variant="h6" />
      <br />
      <Typography
        variant="subtitle4"
        color="text.secondary"
        mt={2}
        mb={1.5}
        display="flex">
        {intl.get("t_profile_skill_selected_skills")}
      </Typography>
      <br />
      <Stack direction="row" gap={1} flexWrap="wrap">
        {[...Array(5)].map((_, index) => {
          return (
            <Fragment key={index}>
              <SkeletonKeyword />
            </Fragment>
          );
        })}
      </Stack>
    </>
  );
};

export default SkeletonSkillRequirementForm;
