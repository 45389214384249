import { Box, styled } from "@mui/material";

import BG_Header_Mobile_Screen from "@assets/images/BG_Header_Mobile_Screen.svg";
import BG_Header_PC_Screen from "@assets/images/BG_Header_PC_Screen.svg";

const StyledImage = styled("img")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    marginTop: "-75%"
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "-30%"
  },
  [theme.breakpoints.up("lg")]: {
    marginTop: "-5%"
  },
  [theme.breakpoints.up("xl")]: {
    marginTop: "-8%"
  }
}));

const HeaderBackground = () => {
  return (
    <>
      <Box position="relative" display={{ xs: "block", md: "none" }}>
        <StyledImage src={BG_Header_Mobile_Screen} />
      </Box>
      <Box position="relative" display={{ xs: "none", md: "block" }}>
        <StyledImage src={BG_Header_PC_Screen} />
      </Box>
    </>
  );
};

export default HeaderBackground;
