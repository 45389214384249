import { collection, getDocs, limit, query, where } from "firebase/firestore";
import pMemoize from "p-memoize";

import KeyLabel from "@interfaces/components/KeyLabel";
import MetaCountriesCities from "@interfaces/database/MetaCountriesCities";

import { FIRESTORE_COLLECTIONS } from "@utils/config";
import { db } from "@utils/firebase";
import { getCountryCode, isValidCountryName } from "@utils/location";

const getLocationLabelNonMemoized = async (key: string): Promise<string> => {
  let label = key;

  if (key) {
    if (isValidCountryName(key)) {
      key = getCountryCode(key);
    }

    const q = query(
      collection(db, FIRESTORE_COLLECTIONS.META_COUNTRIES_CITIES),
      where("key", "==", key),
      limit(1)
    );
    const docs = await getDocs(q);

    if (docs.empty) {
      return label;
    }

    // By limit it should be only one. But the interface is not allowing to do that.
    // So, we are using forEach.
    docs.forEach((singleDoc) => {
      if (singleDoc.data()) {
        label = singleDoc.data().name.en;
      }
    });
  }
  return label;
};

const getLocationList = async (subtext: string): Promise<Array<KeyLabel>> => {
  subtext = subtext.toLowerCase();
  if (subtext.length >= 3) {
    const q = query(
      collection(db, FIRESTORE_COLLECTIONS.META_COUNTRIES_CITIES),
      where(`metadata.name_keywords.${subtext}`, "==", true)
    );
    const docs = await getDocs(q);

    if (docs.empty) {
      return [];
    }

    const locations: Record<string, string> = {};
    docs.forEach((singleDoc) => {
      if (singleDoc.data()) {
        const data = singleDoc.data() as MetaCountriesCities;
        // FIXME: Handle dynamic showing based on Language.
        locations[data.key] = data.name.en ?? data.name.ja ?? "";
      }
    });

    return Object.keys(locations).map((singleLocationKey) => ({
      key: singleLocationKey,
      label: locations[singleLocationKey]
    }));
  }

  return [];
};

const getCityList = async (
  subtext = "",
  countryISOCode = ""
): Promise<Array<KeyLabel>> => {
  subtext = subtext.toLowerCase();
  if (subtext.length >= 3) {
    const q = query(
      collection(db, FIRESTORE_COLLECTIONS.META_COUNTRIES_CITIES),
      where("country_iso", "==", countryISOCode),
      where(`metadata.name_keywords.${subtext}`, "==", true)
    );
    const docs = await getDocs(q);

    if (docs.empty) {
      return [];
    }

    const locations: Record<string, string> = {};
    docs.forEach((singleDoc) => {
      if (singleDoc.data()) {
        const data = singleDoc.data() as MetaCountriesCities;
        // FIXME: Handle dynamic showing based on Language.
        locations[data.key] = data.name.en ?? data.name.ja ?? "";
      }
    });

    return Object.keys(locations).map((singleLocationKey) => ({
      key: singleLocationKey,
      label: locations[singleLocationKey]
    }));
  }

  return [];
};

const getCityLabelNonMemoized = async (key: string): Promise<string> => {
  let label = "";

  if (key) {
    const q = query(
      collection(db, FIRESTORE_COLLECTIONS.META_COUNTRIES_CITIES),
      where("key", "==", key),
      limit(1)
    );
    const cityDocs = await getDocs(q);

    if (cityDocs.empty) {
      return label;
    }

    cityDocs.forEach((singleDoc) => {
      if (singleDoc.data()) {
        // FIXME: Handle dynamic label based on Language.
        label = singleDoc.data().name.en;
      }
    });
  }
  return label;
};

const getLocationLabel = pMemoize(getLocationLabelNonMemoized);

const getCityLabel = pMemoize(getCityLabelNonMemoized);

export {
  getLocationLabel,
  getLocationLabelNonMemoized,
  getLocationList,
  getCityList,
  getCityLabel,
  getCityLabelNonMemoized
};
