import { useEffect } from "react";

import { Step } from "react-joyride";

import { Link, Stack, Tooltip } from "@mui/material";
import { ref } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useDownloadURL } from "react-firebase-hooks/storage";

import Button from "@components/Button";
import FTUIContainer from "@components/FTUIContainer";
import Icon from "@components/Icon";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import {
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_EN_CV,
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_CV,
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_RIREKISHO,
  USER_TYPE
} from "@utils/config";
import { auth, functions, storage } from "@utils/firebase";
import { intl } from "@utils/translate";

const GenerateCVPdf = () => {
  const tourSteps: Array<Step> = [
    {
      target: "#generate-cv-step",
      title: intl.get("t_ftui_debugger_generate_cv_title"),
      content: intl.get("t_ftui_debugger_generate_cv_description"),
      disableBeacon: true
    },
    {
      target: "#generate-cv-en-step",
      title: intl.get("t_ftui_debugger_generate_cv_en_title"),
      content: intl.get("t_ftui_debugger_generate_cv_en_description")
    },
    {
      target: "#generate-cv-ja-step",
      title: intl.get("t_ftui_debugger_generate_cv_ja_title"),
      content: intl.get("t_ftui_debugger_generate_cv_ja_description")
    },
    {
      target: "#generate-cv-reirekisho-step",
      title: intl.get("t_ftui_debugger_generate_cv_rirekisho_title"),
      content: intl.get("t_ftui_debugger_generate_cv_rirekisho_description")
    }
  ];

  const toast = useToast();
  const [user] = useAuthState(auth);
  const { value } = useUserProfile();
  const { progress_flags } = value ?? {};
  const [generateEnCvPdf, isGenerateEnCvPdfLoading, generateEnCvPdfError] =
    useHttpsCallable(functions, "generateEnCvPdf");
  const [generateJaCvPdf, isGenerateJaCvPdfLoading, generateJaCvPdfError] =
    useHttpsCallable(functions, "generateJaCvPdf");
  const [
    generateJaRirekishoPdf,
    isGenerateJaRirekishoPdfLoading,
    generateJaRirekishoPdfError
  ] = useHttpsCallable(functions, "generateJaRirekishoPdf");
  const [downloadEnCvUrl] = useDownloadURL(
    ref(
      storage,
      `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_EN_CV}/${user?.uid}.pdf`
    )
  );
  const [downloadJaCvUrl] = useDownloadURL(
    ref(
      storage,
      `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_CV}/${user?.uid}.pdf`
    )
  );
  const [downloadJaRirekishoUrl] = useDownloadURL(
    ref(
      storage,
      `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_RIREKISHO}/${user?.uid}.pdf`
    )
  );

  const isProfileCompleted =
    progress_flags?.basic_information &&
    progress_flags?.job_experience_overview &&
    progress_flags?.language &&
    progress_flags?.skills;

  useEffect(() => {
    if (generateEnCvPdfError) {
      toast.kampai("En CV is not generated, something went wrong!", "error");
    }
  }, [generateEnCvPdfError]);

  useEffect(() => {
    if (generateJaCvPdfError) {
      toast.kampai("Ja CV is not generated, something went wrong!", "error");
    }
  }, [generateJaCvPdfError]);

  useEffect(() => {
    if (generateJaRirekishoPdfError) {
      toast.kampai(
        "Ja Rirekisho is not generated, something went wrong!",
        "error"
      );
    }
  }, [generateJaRirekishoPdfError]);

  let toolTipTitle = "";
  if (user) {
    if (value?.user_type === USER_TYPE.INDIVIDUAL) {
      if (!isProfileCompleted) {
        toolTipTitle = "Complete your profile to generate cv";
      } else {
        toolTipTitle = "";
      }
    } else {
      toolTipTitle = "Employer can not generate CV";
    }
  } else {
    toolTipTitle = "Please login as candidate to generate CV";
  }

  return (
    <>
      <Typography variant="body1" my={2} id="generate-cv-step">
        Generate CV using your profile information 😄
      </Typography>
      <Stack gap={1} direction="row">
        {toolTipTitle ? (
          <Tooltip arrow enterTouchDelay={0} title={toolTipTitle}>
            <span>
              <Button disabled>Generate En CV PDF</Button>
            </span>
          </Tooltip>
        ) : (
          <div id="generate-cv-en-step">
            <Button
              disabled={!isProfileCompleted}
              loading={isGenerateEnCvPdfLoading}
              variant="outlined"
              handleClick={() => {
                generateEnCvPdf();
              }}>
              Generate En CV PDF
            </Button>
          </div>
        )}

        {downloadEnCvUrl ? (
          <Link underline="none" href={downloadEnCvUrl}>
            <Button startAdornment={<Icon type="download" />}>
              Download EN CV
            </Button>
          </Link>
        ) : (
          false
        )}

        {toolTipTitle ? (
          <Tooltip arrow enterTouchDelay={0} title={toolTipTitle}>
            <span>
              <Button disabled>Generate Ja CV PDF</Button>
            </span>
          </Tooltip>
        ) : (
          <div id="generate-cv-ja-step">
            <Button
              disabled={!isProfileCompleted}
              loading={isGenerateJaCvPdfLoading}
              variant="outlined"
              handleClick={() => {
                generateJaCvPdf();
              }}>
              Generate Ja CV PDF
            </Button>
          </div>
        )}

        {downloadJaCvUrl ? (
          <Link underline="none" href={downloadJaCvUrl}>
            <Button startAdornment={<Icon type="download" />}>
              Download JA CV
            </Button>
          </Link>
        ) : (
          false
        )}

        {toolTipTitle ? (
          <Tooltip arrow enterTouchDelay={0} title={toolTipTitle}>
            <span>
              <Button disabled>Generate Ja Rirekisho PDF</Button>
            </span>
          </Tooltip>
        ) : (
          <div id="generate-cv-reirekisho-step">
            <Button
              disabled={!isProfileCompleted}
              loading={isGenerateJaRirekishoPdfLoading}
              variant="outlined"
              handleClick={() => {
                generateJaRirekishoPdf();
              }}>
              Generate Ja Rirekisho PDF
            </Button>
          </div>
        )}

        {downloadJaRirekishoUrl ? (
          <Link underline="none" href={downloadJaRirekishoUrl}>
            <Button startAdornment={<Icon type="download" />}>
              Download JA Rirekisho
            </Button>
          </Link>
        ) : (
          false
        )}
      </Stack>
      <FTUIContainer tourSteps={tourSteps} />
    </>
  );
};

export default GenerateCVPdf;
