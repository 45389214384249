import { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import LanguageForm from "@components/ResumeForms/LanguageForm";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import KeyLabel from "@interfaces/components/KeyLabel";

import {
  JA_ALPHA3_CODE,
  LANGUAGE_PROFICIENCY,
  LOCALE_SHORT,
  MAX_LANGUAGES
} from "@utils/config";
import { getLanguageName } from "@utils/language";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface LanguageFormData {
  name?: KeyLabel;
  proficiency?: typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY];
  testName?: string;
  testResult?: string;
}

const JapaneseResumeEditLanguage = () => {
  const navigate = useNavigate();
  const { item_id } = useParams();
  const userData = useUserProfile();
  const toast = useToast();
  const itemId = Number(item_id);
  const isFirstField = itemId === 0; // japanese language

  const userLanguages = userData?.value?.summary?.languages ?? [];

  const initialValues = {
    name:
      itemId === undefined
        ? {
            key: "",
            label: ""
          }
        : itemId === 0
        ? {
            key: JA_ALPHA3_CODE,
            label: getLanguageName(JA_ALPHA3_CODE) ?? ""
          }
        : {
            key: userLanguages?.[itemId]?.name ?? "",
            label: getLanguageName(userLanguages?.[itemId]?.name ?? "") ?? ""
          },
    proficiency:
      itemId !== undefined
        ? userLanguages?.[itemId]?.proficiency
        : LANGUAGE_PROFICIENCY.NONE,
    testName:
      itemId === undefined ? "" : userLanguages?.[itemId]?.test_name ?? "",
    testResult:
      itemId === undefined ? "" : userLanguages?.[itemId]?.test_result ?? ""
  };

  useEffect(() => {
    if (item_id && (!initialValues.name || !initialValues.proficiency)) {
      navigate(
        `/${translate.getCurrentLocale()}/profile/resume/ja-cv/edit/language`,
        {
          replace: true
        }
      );
    }
  }, []);

  const handleLanguageSavedSuccess = () => {
    toast.kampai(intl.get("t_toast_success_resume_detail_saved"), "success");
  };

  const handleLanguageSavedFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleAdd = async (formData: LanguageFormData) => {
    if (userLanguages?.length >= MAX_LANGUAGES) {
      toast.kampai(
        intl.get("t_toast_error_limit_exceed", {
          fieldName: intl.get("t_profile_summary_language"),
          maxLimit: MAX_LANGUAGES
        }),
        "error"
      );
      return;
    }

    const { name, proficiency, testName, testResult } = formData || {};
    userLanguages.push({
      name: name?.key?.toUpperCase() ?? "",
      proficiency:
        (proficiency as typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY]) ??
        "",
      test_name: testName ?? "",
      test_result: testResult ?? ""
    });

    try {
      if (userData?.setValue && userData?.value) {
        await userData.setValue(
          {
            ...userData.value,
            summary: {
              ...userData.value.summary,
              languages: userLanguages
            },
            updated_at: Timestamp.now()
          },
          handleLanguageSavedSuccess,
          handleLanguageSavedFail
        );
        navigate(`/${translate.getCurrentLocale()}/profile/resume/ja-cv`);
      }
    } catch (e) {
      handleLanguageSavedFail();
    }
  };

  const handleEdit = async (formData: LanguageFormData) => {
    const { name, proficiency, testName, testResult } = formData || {};

    userLanguages[itemId] = {
      name: isFirstField ? JA_ALPHA3_CODE : name?.key?.toUpperCase() ?? "",
      proficiency:
        (proficiency as typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY]) ??
        "",
      test_name: testName ?? "",
      test_result: testResult ?? ""
    };

    try {
      if (
        userData.setValue &&
        userData.value &&
        userData.value?.summary?.languages
      ) {
        await userData.setValue(
          {
            ...userData.value,
            summary: {
              ...userData.value.summary,
              languages: userLanguages
            },
            updated_at: Timestamp.now()
          },
          handleLanguageSavedSuccess,
          handleLanguageSavedFail
        );
        navigate(`/${translate.getCurrentLocale()}/profile/resume/ja-cv`);
      }
    } catch (e) {
      handleLanguageSavedFail();
    }
  };

  return (
    <LanguageForm
      initialValues={initialValues}
      handleFormSubmit={item_id ? handleEdit : handleAdd}
      backBtnLink={`/${translate.getCurrentLocale()}/profile/resume/ja-cv`}
      formLanguage={LOCALE_SHORT.JA}
    />
  );
};

export default JapaneseResumeEditLanguage;
