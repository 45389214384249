import { useMemo, useState } from "react";

import {
  Box,
  DialogActions,
  DialogContent,
  Stack,
  Step,
  StepButton,
  Stepper,
  Typography,
  useMediaQuery
} from "@mui/material";

import Button from "@components/Button";
import Dialog from "@components/Dialog";

import HiringProcessStepStatus from "@interfaces/components/HiringProcessStepStatus";

import theme from "@utils/theme";
import { intl } from "@utils/translate";

interface SelectionProcessProps {
  hiringFlowProcessSteps: Array<HiringProcessStepStatus>;
  handleSelectionProcess: (currentActiveStepKey: string) => void;
  currentActiveStepKey?: string;
  isHired?: boolean;
}

const SelectionProcess = ({
  hiringFlowProcessSteps,
  handleSelectionProcess,
  currentActiveStepKey,
  isHired = false
}: SelectionProcessProps) => {
  const steps = useMemo(() => [...hiringFlowProcessSteps], []);
  const [activeStepKey, setActiveStepKey] = useState<string>(
    isHired
      ? ""
      : currentActiveStepKey
      ? currentActiveStepKey
      : steps?.[0]?.key ?? ""
  );
  let activeStepIndex = steps.findIndex(
    (singleStep) => activeStepKey === singleStep.key
  );
  if (activeStepIndex === -1 && !isHired) {
    activeStepIndex = 0;
  }
  const [completed, setCompleted] = useState<
    Array<{
      key: string;
      label: string;
      isCompleted: boolean;
    }>
  >(() =>
    steps
      .slice(0, isHired ? steps.length : activeStepIndex)
      .map((singleStep) => {
        return {
          ...singleStep,
          isCompleted: true
        };
      })
  );
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLastStep = activeStepIndex === steps.length - 2; // here subtract 2 because hired step is temporary step

  const handlePrevious = () => {
    if (activeStepIndex !== 0) {
      const completedSteps = steps
        .slice(
          0,
          completed.length === steps.length
            ? activeStepIndex
            : activeStepIndex - 1
        )
        .map((singleStep) => {
          return {
            ...singleStep,
            isCompleted: true
          };
        });
      const previousActiveKey =
        steps[
          completed.length === steps.length
            ? activeStepIndex
            : activeStepIndex - 1
        ].key;
      setActiveStepKey(previousActiveKey);
      handleSelectionProcess(previousActiveKey);
      setCompleted(completedSteps);
    }
  };

  const handleNext = () => {
    const completedSteps = steps
      .slice(0, isLastStep ? steps.length : activeStepIndex + 1)
      .map((singleStep) => {
        return {
          ...singleStep,
          isCompleted: true
        };
      });
    if (isLastStep) {
      setActiveStepKey(steps[steps.length - 1].key);
      handleSelectionProcess(steps[steps.length - 1].key);
    } else if (activeStepIndex < steps.length) {
      setActiveStepKey(steps[activeStepIndex + 1].key);
      handleSelectionProcess(steps[activeStepIndex + 1].key);
    }
    setCompleted(completedSteps);
  };

  const handleStepClick = (stepKey: string) => {
    const clickedStepIdx = steps.findIndex(
      (singleStep) => singleStep.key === stepKey
    );
    const isLastStep = clickedStepIdx === steps.length - 1;
    const completedSteps = steps
      .slice(0, isLastStep ? steps.length : clickedStepIdx)
      .map((singleStep) => {
        return {
          ...singleStep,
          isCompleted: true
        };
      });
    setActiveStepKey(isLastStep ? stepKey : steps[clickedStepIdx].key);
    setCompleted(completedSteps);
    handleSelectionProcess(isLastStep ? stepKey : steps[clickedStepIdx].key);
  };

  return (
    <Box width="100%">
      <Typography variant="h4" mb={2.5}>
        {intl.get("t_selection_process_title")}
      </Typography>
      <Box
        maxHeight={{ xs: 340, md: "auto" }}
        sx={{
          overflowX: isSmallScreen ? "hidden" : "auto",
          overflowY: isSmallScreen ? "auto" : "hidden"
        }}>
        <Stepper
          nonLinear
          activeStep={activeStepIndex}
          alternativeLabel={!isSmallScreen}
          orientation={isSmallScreen ? "vertical" : "horizontal"}>
          {steps.map((singleStep, index) => {
            const stepButtonSx = {
              ...(isMediumScreen
                ? { margin: 0, padding: 0 }
                : {
                    ...(index === steps.length - 1 ? { pr: { md: 0 } } : {})
                  })
            };
            return (
              <Step
                disabled={isHired}
                key={singleStep.key}
                completed={completed?.[index]?.isCompleted}>
                {isHired ? (
                  <StepButton disabled={isHired} sx={stepButtonSx}>
                    {singleStep?.label}
                  </StepButton>
                ) : (
                  <Dialog
                    isStopEventPropagation
                    title={intl.get("t_selection_process_dialog_title", {
                      statusName: singleStep?.label
                    })}
                    maxWidth="sm"
                    initiator={
                      <StepButton disabled={isHired} sx={stepButtonSx}>
                        {singleStep?.label}
                      </StepButton>
                    }>
                    {(handleAgree, handleCancel) => (
                      <>
                        <Box
                          noValidate
                          component="form"
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleStepClick(steps[index].key);
                            handleAgree();
                          }}>
                          <DialogActions>
                            <Button
                              handleClick={handleCancel}
                              variant="outlined">
                              {intl.get("t_general_no")}
                            </Button>
                            <Button type="submit">
                              {intl.get("t_general_yes")}
                            </Button>
                          </DialogActions>
                        </Box>
                      </>
                    )}
                  </Dialog>
                )}
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Stack
        direction="row"
        mt={{ xs: 2, md: 4 }}
        justifyContent="space-between">
        {/* previous button dialog start */}
        {activeStepIndex === 0 ||
        isHired ||
        completed.length === steps.length ? (
          <Button variant="outlined" disabled>
            {intl.get("t_selection_process_go_to_previous_step")}
          </Button>
        ) : (
          <Dialog
            isStopEventPropagation
            title={intl.get("t_selection_process_dialog_title", {
              statusName: steps[activeStepIndex - 1]?.label
            })}
            maxWidth="sm"
            initiator={
              <Button variant="outlined">
                {intl.get("t_selection_process_go_to_previous_step")}
              </Button>
            }>
            {(handleAgree, handleCancel) => (
              <>
                <Box
                  noValidate
                  component="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handlePrevious();
                    handleAgree();
                  }}>
                  <DialogActions>
                    <Button handleClick={handleCancel} variant="outlined">
                      {intl.get("t_general_no")}
                    </Button>
                    <Button type="submit">{intl.get("t_general_yes")}</Button>
                  </DialogActions>
                </Box>
              </>
            )}
          </Dialog>
        )}

        {/* previous button dialog start */}

        {/* next step button dialog start */}
        {completed.length === steps.length || isHired ? (
          <Button disabled>
            {completed.length === steps.length
              ? intl.get("t_general_hired")
              : intl.get("t_general_next_step")}
          </Button>
        ) : (
          <>
            <Dialog
              isStopEventPropagation
              title={
                activeStepIndex !== steps.length - 2
                  ? intl.get("t_selection_process_dialog_title", {
                      statusName: steps?.[activeStepIndex + 1]?.label
                    })
                  : intl.get("t_selection_process_dialog_finish_process_title")
              }
              maxWidth="sm"
              initiator={
                <Button>
                  {activeStepIndex >= steps.length - 2
                    ? intl.get("t_general_hired")
                    : intl.get("t_general_next_step")}
                </Button>
              }>
              {(handleAgree, handleCancel) => (
                <>
                  <Box
                    noValidate
                    component="form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleNext();
                      handleAgree();
                    }}>
                    {isLastStep ? (
                      <DialogContent>
                        <Typography color="text.secondary">
                          {intl.get(
                            "t_selection_process_dialog_finish_process_subtitle"
                          )}
                        </Typography>
                      </DialogContent>
                    ) : (
                      false
                    )}
                    <DialogActions>
                      <Button handleClick={handleCancel} variant="outlined">
                        {intl.get("t_general_no")}
                      </Button>
                      <Button type="submit">{intl.get("t_general_yes")}</Button>
                    </DialogActions>
                  </Box>
                </>
              )}
            </Dialog>
          </>
        )}
        {/* next step button dialog end */}
      </Stack>
    </Box>
  );
};

export default SelectionProcess;
