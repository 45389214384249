import { Box, Stack } from "@mui/material";

import HomePageIcon, { HomePageIconProps } from "@components/HomePageIcon";
import Typography from "@components/Typography";

import { colorPalette } from "@utils/theme";

interface BenefitCardProps {
  title: string;
  description: string;
  image: HomePageIconProps["image"];
}

const BenefitCard = ({ title, description, image }: BenefitCardProps) => {
  return (
    <Box
      borderRadius={1}
      minHeight={138}
      display="flex"
      alignItems="center"
      px={3}
      py={1.25}
      gap={3}
      bgcolor={colorPalette.lightBlue.background[30]}
      color={colorPalette.white[80]}>
      <HomePageIcon image={image} alt={`${image}_icon`} />
      <Stack>
        {/* FIXME: Fix variant for responsive font size */}
        <Typography variant="subtitle2" mb={1.5}>
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </Stack>
    </Box>
  );
};

export default BenefitCard;
