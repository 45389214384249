import { KeyboardArrowUp as KeyboardArrowUpIcon } from "@mui/icons-material";

import IconButton from "@components/IconButton";

const ScrollTopButton = () => {
  const handleClick = () => {
    window["scrollTo"]({ top: 0, behavior: "smooth" });
  };
  return (
    <IconButton onClick={handleClick}>
      <KeyboardArrowUpIcon />
    </IconButton>
  );
};

export default ScrollTopButton;
