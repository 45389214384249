import { Link, useNavigate, useParams } from "react-router-dom";

import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  NavigateBefore as NavigateBeforeIcon
} from "@mui/icons-material";
import { Box, Link as MuiLink, Stack } from "@mui/material";

import DesktopProfileSummaryForm from "@pages/DesktopProfileSummaryForm";
import BasicInformationForm from "@pages/MobileProfileSummaryForms/BasicInformationForm";
import JobOverviewForm from "@pages/MobileProfileSummaryForms/JobOverviewForm";
import LanguageForm from "@pages/MobileProfileSummaryForms/LanguageForm";
import SkillsForm from "@pages/MobileProfileSummaryForms/SkillsForm";

import Button from "@components/Button";
import Typography from "@components/Typography";

import translate, { intl } from "@utils/translate";

const ProfileEdit = () => {
  const { profile_section = "" } = useParams();
  const navigate = useNavigate();
  let currentActiveForm = <BasicInformationForm />;

  switch (profile_section) {
    case "basic-information":
      currentActiveForm = <BasicInformationForm />;
      break;
    case "job-overview":
      currentActiveForm = <JobOverviewForm />;
      break;
    case "language":
      currentActiveForm = <LanguageForm />;
      break;
    case "skills":
      currentActiveForm = <SkillsForm />;
      break;
    default:
      currentActiveForm = <BasicInformationForm />;
      break;
  }

  return (
    <>
      <Box display={{ xs: "none", md: "block" }}>
        {/* FIXME: add skeleton */}
        <DesktopProfileSummaryForm />
      </Box>
      <Box display={{ xs: "block", md: "none" }}>
        {/* edit information menu for mobile start */}
        {!profile_section ? (
          <>
            <Typography variant="h3" mb={5.5} mt={5}>
              {intl.get("t_profile_edit_info")}
            </Typography>
            <Stack
              rowGap={1.5}
              borderRadius={1}
              bgcolor="background.paper"
              p={2.25}>
              <MuiLink
                display="inline-block"
                component={Link}
                to="basic-information"
                underline="hover"
                color="text.primary">
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h4">
                    {intl.get("t_profile_basic_info_heading")}
                  </Typography>
                  <KeyboardArrowRightIcon />
                </Stack>
              </MuiLink>
              <MuiLink
                display="inline-block"
                component={Link}
                to="job-overview"
                underline="hover"
                color="text.primary">
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h4">
                    {intl.get("t_profile_job_overview_heading")}
                  </Typography>
                  <KeyboardArrowRightIcon />
                </Stack>
              </MuiLink>
              <MuiLink
                display="inline-block"
                component={Link}
                to="language"
                underline="hover"
                color="text.primary">
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h4">
                    {intl.get("t_profile_language_heading")}
                  </Typography>
                  <KeyboardArrowRightIcon />
                </Stack>
              </MuiLink>
              <MuiLink
                display="inline-block"
                component={Link}
                to="skills"
                underline="hover"
                color="text.primary">
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h4">
                    {intl.get("t_profile_skill_heading")}
                  </Typography>
                  <KeyboardArrowRightIcon />
                </Stack>
              </MuiLink>
            </Stack>
            <Box mt={3}>
              <Button
                startAdornment={<NavigateBeforeIcon />}
                variant="outlined"
                handleClick={() =>
                  navigate(`/${translate.getCurrentLocale()}/profile`)
                }>
                {intl.get("t_general_back")}
              </Button>
            </Box>
            {/* edit information menu for mobile end */}
          </>
        ) : (
          <>{currentActiveForm}</>
        )}
      </Box>
    </>
  );
};

export default ProfileEdit;
