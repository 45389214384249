import { User as FirebaseUser } from "firebase/auth";

import { UserProfileContextStructure } from "@utils/components/UserProfileContext";
import { JOB_POST_STATUS_CODE, USER_TYPE } from "@utils/config";

export const canUserPostJob = (
  userProfile: UserProfileContextStructure,
  user: FirebaseUser | null | undefined // here null and undefined are required because the useAuthState returns the null or undefine for not login user
) => {
  const isEmailVerified = user?.emailVerified;

  if (
    userProfile.value?.user_type === USER_TYPE.COMPANY ||
    userProfile.value?.user_type === USER_TYPE.COMPANY_MANAGER
  ) {
    if (!isEmailVerified) {
      return JOB_POST_STATUS_CODE.EMAIL_NOT_VERIFIED;
    } else {
      return JOB_POST_STATUS_CODE.CAN_POST;
    }
  } else {
    return JOB_POST_STATUS_CODE.CAN_NOT_POST;
  }
};
