import { useState } from "react";

import { Document, Page, pdfjs } from "react-pdf";

import {
  Close as CloseIcon,
  NavigateBeforeRounded as NavigateBeforeRoundedIcon,
  NavigateNextRounded as NavigateNextRoundedIcon,
  ZoomInRounded as ZoomInRoundedIcon,
  ZoomOutRounded as ZoomOutRoundedIcon
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Hidden,
  IconButton,
  Link,
  Stack,
  styled
} from "@mui/material";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "@assets/styles/pdf_viewer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

import Button from "@components/Button";
import Icon from "@components/Icon";
import Typography from "@components/Typography";

import { ZOOM_VALUES } from "@utils/config";
import { colorPalette } from "@utils/theme";
import { intl } from "@utils/translate";

interface PdfViewerModalProps {
  pdfURL: string;
  pdfName?: string;
  handleClose: () => void;
}

const StyledButton = styled("button")(({ theme }) => ({
  "height": 38,
  "width": 38,
  "border": `1px solid ${theme.palette.primary.main}`,
  "borderRadius": 4,
  "backgroundColor": colorPalette.white.base,
  "color": theme.palette.primary.main,
  "&:hover:enabled": {
    backgroundColor: colorPalette.blue["04"],
    cursor: "pointer"
  },
  "&:disabled": {
    color: colorPalette.grey.base,
    border: `1px solid ${colorPalette.grey.base}`
  }
}));

const StyledBox = styled(Box)({
  height: 24,
  width: 24
});

const PdfViewerModal = ({
  pdfURL = "",
  pdfName = "Resume.pdf",
  handleClose
}: PdfViewerModalProps) => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [animate, setAnimate] = useState<boolean>(false);
  const [scale, setScale] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setTotalPages(numPages);
    setCurrentPage(1);
  };

  const triggerAnimation = () => {
    setAnimate(true);

    // Optionally reset the animation after it completes
    setTimeout(() => {
      setAnimate(false);
    }, 250); // Duration of your animation
  };

  // Page Control Functions

  const goBack = () => {
    triggerAnimation();
    setCurrentPage((currentPage) => {
      if (currentPage > 1) {
        return currentPage - 1;
      } else {
        return 1;
      }
    });
  };

  const goForward = () => {
    triggerAnimation();
    setCurrentPage((currentPage) => {
      if (currentPage < totalPages) {
        return currentPage + 1;
      } else {
        return totalPages;
      }
    });
  };

  // Zoom control functions

  const zoomIn = () => {
    triggerAnimation();
    setScale((scale) => {
      if (scale < ZOOM_VALUES.MAX) {
        return scale + ZOOM_VALUES.STEP;
      } else {
        return ZOOM_VALUES.MAX;
      }
    });
  };

  const zoomOut = () => {
    triggerAnimation();
    setScale((scale) => {
      if (scale > ZOOM_VALUES.MIN) {
        return scale - ZOOM_VALUES.STEP;
      } else {
        return ZOOM_VALUES.MIN;
      }
    });
  };

  return (
    <Box width="100%" height="100%">
      <div className="pdf-modal-container">
        <Document file={pdfURL} onLoadSuccess={onDocumentLoadSuccess}>
          {/* Header Controls */}
          <Stack
            direction={{ xs: "column", md: "row" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent="space-between"
            width="100%"
            gap={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%">
              {/* PDF Name */}
              <Typography variant="h6" mr={3}>
                {pdfName}
              </Typography>
              {/* Close Button */}
              <Hidden mdUp>
                <IconButton onClick={handleClose} size="large">
                  <CloseIcon />
                </IconButton>
              </Hidden>
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              gap={{ xs: 2, sm: "unset" }}>
              {/* Page Control Buttons */}
              <Stack direction="row" gap={1} alignItems="center" mr={3}>
                <StyledButton disabled={currentPage <= 1} onClick={goBack}>
                  <StyledBox>
                    <NavigateBeforeRoundedIcon />
                  </StyledBox>
                </StyledButton>
                <Typography textAlign="center" whiteSpace="nowrap">
                  {`${intl.get("t_general_page")} ${currentPage}/${totalPages}`}
                </Typography>
                <StyledButton
                  onClick={goForward}
                  disabled={currentPage >= totalPages}>
                  <StyledBox>
                    <NavigateNextRoundedIcon />
                  </StyledBox>
                </StyledButton>
              </Stack>
              <Stack direction="row" gap={1} alignItems="center" mr={1.5}>
                {/* Download Button */}
                <Link underline="none" href={pdfURL}>
                  <Box width="max-content">
                    <Button
                      variant="outlined"
                      startAdornment={<Icon type="download" />}>
                      {intl.get("t_general_download")}
                    </Button>
                  </Box>
                </Link>
                {/* Zoom Control Buttons */}
                <StyledButton disabled={scale <= 0.5} onClick={zoomOut}>
                  <StyledBox>
                    <ZoomOutRoundedIcon />
                  </StyledBox>
                </StyledButton>
                <StyledButton disabled={scale >= 2} onClick={zoomIn}>
                  <StyledBox>
                    <ZoomInRoundedIcon />
                  </StyledBox>
                </StyledButton>
              </Stack>

              {/* Close Button */}
              <Hidden mdDown>
                <IconButton onClick={handleClose} size="large">
                  <CloseIcon />
                </IconButton>
              </Hidden>
            </Stack>
          </Stack>

          <Box my={2} width="100%">
            <Divider />
          </Box>

          {/* PDF Content */}
          <Box className="pdf-modal-page-container">
            <div
              className={
                animate ? "react-pdf__Page--loading" : "pdf-modal-page"
              }>
              <div
                style={{
                  opacity: animate ? 0 : 1,
                  overflow: "auto",
                  height: "min-content"
                }}>
                <Page
                  pageNumber={currentPage}
                  scale={scale}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              </div>
            </div>
          </Box>
        </Document>
      </div>
    </Box>
  );
};

export default PdfViewerModal;
