import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  Divider,
  Link as MuiLink,
  Paper,
  Stack,
  styled
} from "@mui/material";
import * as yup from "yup";

import AutoCompleteTextField from "@components/AutoCompleteTextField";
import Button from "@components/Button";
import Keyword from "@components/Keyword";
import TextField from "@components/TextField";
import Typography from "@components/Typography";

import KeyLabel from "@interfaces/components/KeyLabel";

import { JOB_REMOTE_WORK_TYPE, LANGUAGE_PROFICIENCY } from "@utils/config";
import { getLocationList } from "@utils/keyLabelHandlers/location";
import translate, { intl } from "@utils/translate";

interface SearchForm {
  keyword?: string;
  location?: KeyLabel | null;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "inline-flex",
  justifyContent: "stretch",
  flexDirection: "row",
  gap: "0.5rem",
  padding: "0.5rem",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column"
  },
  [theme.breakpoints.down("lg")]: {
    width: "calc(100% - 1rem)"
  }
}));

const HorizontalSeparator = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none"
  },
  margin: "0"
}));

const VerticalSeparator = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none"
  }
}));

const HomepageSearch = () => {
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(
      yup.object({
        keyword: yup.string().nullable(),
        location: yup
          .object()
          .shape({
            key: yup.string(),
            label: yup.string()
          })
          .nullable()
      })
    ),
    defaultValues: {
      query: "",
      location: null
    }
  });

  const { handleSubmit, control, setValue } = methods;

  const handleSearch = (formData: SearchForm) => {
    const queryParams: Record<string, string> = {};

    if (formData.keyword) {
      queryParams.q = formData.keyword;
    }

    if (formData.location?.key) {
      queryParams.location = formData.location.key;
    }

    const searchParams = new URLSearchParams(queryParams);
    if (queryParams.q || queryParams.location) {
      navigate(
        `/${translate.getCurrentLocale()}/search?${searchParams.toString()}&hide_search=1`
      );
    } else {
      navigate(`/${translate.getCurrentLocale()}/search`);
    }
  };

  return (
    <>
      <StyledPaper variant="outlined">
        <Stack
          component="form"
          noValidate
          flexWrap="nowrap"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          width="100%"
          gap={1}
          justifyContent={{ md: "center" }}
          onSubmit={handleSubmit(handleSearch)}>
          {/* NOTE: In future, this should be an autosuggestbox based on the company names and job titles we have */}
          <Box
            flexGrow={2}
            minWidth={{ xs: "initial", lg: "42ch" }}
            width="100%"
            display="inline-block">
            <TextField
              name="keyword"
              control={control}
              data-testid="homepage_search_keyword_input"
              placeholder={intl.get("t_job_search_placeholder_keyword")}
              sx={{
                textTransform: "capitalize",
                border: "none",
                p: 0,
                lineHeight: "2rem",
                height: "2rem"
              }}
              baseSx={{
                "p": 0,
                "m": 0,
                "top": "2px",
                "& *": {
                  p: "0px !important"
                },
                "& fieldset": { border: "none", display: "none" }
              }}
            />
          </Box>
          <VerticalSeparator orientation="vertical" variant="middle" flexItem />
          <HorizontalSeparator
            orientation="horizontal"
            variant="middle"
            flexItem
          />
          <Box
            flexGrow={1}
            minWidth={{ xs: "initial", lg: "21ch" }}
            width="100%"
            display="inline-block">
            <AutoCompleteTextField
              name="location"
              data-testid="homepage_search_location_input"
              control={control}
              setValue={setValue}
              placeholder={intl.get("t_job_search_placeholder_location")}
              getOptions={getLocationList}
              sx={{
                "p": 0,
                "m": 0,
                "top": "2px",
                "& *": {
                  p: "0px !important",
                  m: "0px !important"
                },
                "& fieldset": { border: "none", display: "none" }
              }}
            />
          </Box>
          <HorizontalSeparator
            orientation="horizontal"
            variant="middle"
            flexItem
          />
          <Stack flexShrink={0} width={{ xs: "100%", md: "initial" }}>
            <Button
              endAdornment={<SearchIcon />}
              type="submit"
              data-testid="homepage_search_button">
              {intl.get("t_general_search")}
            </Button>
          </Stack>
        </Stack>
      </StyledPaper>
      <Stack direction="row" gap={1} mt={2} alignItems="center" flexWrap="wrap">
        <Typography variant="subtitle2">
          {intl.get("t_homepage_trending_keywords")}:
        </Typography>
        <MuiLink
          underline="none"
          component={Link}
          data-testid="homepage_landing_section_trending_keyword_list_item"
          to={`/search?remote_allowed=${JOB_REMOTE_WORK_TYPE.FULLY_REMOTE}&remote_allowed=${JOB_REMOTE_WORK_TYPE.PARTIALLY_REMOTE}&hide_search=1`}>
          <Keyword
            isClickable
            label={intl.get("t_homepage_trending_keyword_remote_work")}
            startAdornment={<SearchIcon height={24} width={24} />}
          />
        </MuiLink>
        <MuiLink
          underline="none"
          component={Link}
          data-testid="homepage_landing_section_trending_keyword_list_item"
          to={`/search?language_ja_level=${LANGUAGE_PROFICIENCY.BUSINESS}&hide_search=1`}>
          <Keyword
            isClickable
            label={intl.get("t_homepage_trending_keyword_ja_level_business")}
            startAdornment={<SearchIcon height={24} width={24} />}
          />
        </MuiLink>
        <MuiLink
          underline="none"
          component={Link}
          data-testid="homepage_landing_section_trending_keyword_list_item"
          to={`/search?language_en_level=${LANGUAGE_PROFICIENCY.BUSINESS}&hide_search=1`}>
          <Keyword
            isClickable
            label={intl.get("t_homepage_trending_keyword_en_level_business")}
            startAdornment={<SearchIcon height={24} width={24} />}
          />
        </MuiLink>
        <MuiLink
          underline="none"
          component={Link}
          data-testid="homepage_landing_section_trending_keyword_list_item"
          to="/search?q=エンジニア&hide_search=1">
          <Keyword
            isClickable
            label={intl.get("t_homepage_trending_keyword_developer")}
            startAdornment={<SearchIcon height={24} width={24} />}
          />
        </MuiLink>
      </Stack>
    </>
  );
};

export default HomepageSearch;
