import { ElementType } from "react";

import {
  Badge,
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps
} from "@mui/material";

interface TypographyProps extends MuiTypographyProps {
  showBadge?: boolean;
  badgeVariant?: "dot" | "standard";
  badgeVerticalPosition?: "top" | "bottom";
  badgeHorizontalPosition?: "left" | "right";
  badgeContent?: number;
  width?: string;
  component?: ElementType;
}

const Typography = ({
  showBadge = false,
  badgeVerticalPosition = "top",
  badgeHorizontalPosition = "right",
  badgeContent,
  badgeVariant = "dot",
  width = "initial",
  component = "span",
  display = "initial",
  ...rest
}: TypographyProps) => {
  return (
    <>
      {showBadge ? (
        <Badge
          data-testid="badge"
          color="secondary"
          invisible={!showBadge}
          variant={badgeVariant}
          badgeContent={badgeContent}
          anchorOrigin={{
            vertical: badgeVerticalPosition,
            horizontal: badgeHorizontalPosition
          }}
          sx={{
            width: width
          }}>
          <MuiTypography
            {...rest}
            component="span"
            width={width}
            display={display}
          />
        </Badge>
      ) : (
        <MuiTypography
          {...rest}
          component={component}
          display={component === "span" ? "inline-block" : display}
          width={width}
        />
      )}
    </>
  );
};

export default Typography;
