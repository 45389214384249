// Note: If we change any color in the theme file then don't forget to update export_sitemap.ts accordingly
import { createTheme } from "@mui/material/styles";
import "@assets/styles/poppins.css";
import "@assets/styles/notosansjp.css";
import "@assets/styles/icons.css";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    subtitle5: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    inputLabel: React.CSSProperties;
    helperText: React.CSSProperties;
    inputText: React.CSSProperties;
    inputTable: React.CSSProperties;
  }

  // allow configuration using 'createTheme'
  interface TypographyVariantsOptions {
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    subtitle5: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    inputLabel: React.CSSProperties;
    helperText: React.CSSProperties;
    inputText: React.CSSProperties;
    inputTable: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    subtitle4: true;
    subtitle5: true;
    buttonLarge: true;
    buttonSmall: true;
    inputLabel: true;
    helperText: true;
    inputText: true;
    inputTable: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    light: true;
  }
}

export const colorPalette = Object.freeze({
  blue: {
    "base": "#1859FF",
    "04": "#1859FF0A",
    "08": "#1859FF14",
    "20": "#1859FF33",
    "30": "#1859FF4D",
    "50": "#1859FF80",
    "hover": {
      base: "#0342E3"
    },
    "linear":
      "linear-gradient(124.4deg, #144DDE 10.8%, #94BFFF 87.34%, #94BFFF 87.34%)",
    "footer-gradient":
      "linear-gradient(334.27deg, #144DDE -2.02%, #94BFFF 168.64%, #94BFFF 168.64%)"
  },
  orange: {
    "base": "#FF7048",
    "04": "#FF70480A",
    "20": "#FF704833",
    "30": "#FF70484D",
    "50": "#FF704880",
    "hover": {
      base: "#FF5F33"
    },
    "spotlight": "#D13D14",
    "linear": "linear-gradient(124.4deg, #FF6D44 10.8%, #FF9678 87.34%)"
  },
  white: {
    "base": "#FFFFFF",
    "80": "#FFFFFFCC"
  },
  black: {
    "base": "#1F2431",
    "hover": {
      "base": "#7B828B",
      "20": "#7B828B33",
      "30": "#7B828B4D",
      "50": "#7B828B80"
    },
    "25": "#0000003F",
    "80": "#1F2431CC"
  },
  grey: {
    "base": "#CED3E0",
    "12": "#CED3E01F",
    "14": "#CED3E024",
    "20": "#CED3E033",
    "placeholder": "#BBBCC0"
  },
  background: {
    base: "#F7FAFD",
    linear:
      "linear-gradient(317.89deg, #144DDE 3.56%, #94BFFF 84.29%, #94BFFF 84.29%)"
  },
  yellow: {
    base: "#FFD12E",
    background: {
      base: "#FBF9EC"
    },
    boxShadow:
      "0px 3px 5px -1px #CED3E033, 0px 6px 10px #CED3E024, 0px 1px 18px #CED3E01F"
  },
  red: {
    base: "#FF5959",
    background: {
      base: "#FBECED"
    }
  },
  green: {
    base: "#13B00F",
    background: {
      base: "#E4F4E5"
    }
  },
  lightBlue: {
    base: "#4397F8",
    background: {
      "base": "#E9F2FD",
      "30": "#E9F2FD4D"
    }
  },
  lighterBlue: {
    "base": "#16B4F2",
    "20": "#16B4F233"
  },
  specific: {
    debuggerJsonPreviewBG: "#151515"
  }
});

const theme = createTheme({
  palette: {
    common: {
      white: colorPalette.white.base
    },
    primary: {
      main: colorPalette.blue.base,
      dark: colorPalette.blue.hover.base,
      light: colorPalette.blue[30],
      contrastText: colorPalette.white.base
    },
    secondary: {
      main: colorPalette.orange.base,
      dark: colorPalette.orange.hover.base,
      light: colorPalette.orange[20],
      contrastText: colorPalette.white.base
    },
    error: {
      main: colorPalette.red.base,
      light: colorPalette.red.background.base
    },
    warning: {
      main: colorPalette.yellow.base,
      light: colorPalette.yellow.background.base
    },
    info: {
      main: colorPalette.lightBlue.base,
      light: colorPalette.lightBlue.background.base
    },
    success: {
      main: colorPalette.green.base,
      light: colorPalette.green.background.base
    },
    background: {
      default: colorPalette.background.base,
      paper: colorPalette.white.base
    },
    text: {
      primary: colorPalette.black.base,
      secondary: colorPalette.black.hover.base,
      disabled: colorPalette.black.hover.base
    }
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: "6rem",
      letterSpacing: "-1.5px"
    },
    h2: {
      fontWeight: 600,
      fontSize: "3rem",
      letterSpacing: "0px"
    },
    h3: {
      fontWeight: 600,
      fontSize: "2.25rem",
      letterSpacing: "0px"
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.5rem",
      letterSpacing: "0px"
    },
    h5: {
      fontWeight: 500,
      fontSize: "1.5rem",
      letterSpacing: "0px"
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "1.5rem",
      letterSpacing: "0px"
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "1.25rem",
      letterSpacing: "0px"
    },
    subtitle3: {
      fontWeight: 400,
      fontSize: "1.25rem",
      letterSpacing: "0px"
    },
    subtitle4: {
      fontWeight: 500,
      fontSize: "1rem",
      letterSpacing: "0px"
    },
    subtitle5: {
      fontWeight: 500,
      fontSize: "0.875rem",
      letterSpacing: "0px"
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
      letterSpacing: "0px"
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem",
      letterSpacing: "0px"
    },
    buttonSmall: {
      fontWeight: 500,
      fontSize: "0.8125rem",
      letterSpacing: "0px",
      textTransform: "capitalize"
    },
    button: {
      fontWeight: 500,
      fontSize: "0.875rem",
      letterSpacing: "0px",
      textTransform: "capitalize"
    },
    buttonLarge: {
      fontWeight: 500,
      fontSize: "1rem",
      letterSpacing: "0px",
      textTransform: "capitalize"
    },
    inputLabel: {
      fontWeight: 400,
      fontSize: "1rem",
      letterSpacing: "0px"
    },
    helperText: {
      fontWeight: 400,
      fontSize: "0.75rem",
      letterSpacing: "0px"
    },
    inputText: {
      fontWeight: 400,
      fontSize: "1rem",
      letterSpacing: "0px"
    },
    inputTable: {
      fontWeight: 400,
      fontSize: "0.875rem",
      letterSpacing: "0px"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 1101,
      lg: 1641,
      xl: 2161
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "&.Mui-disabled": {
          cursor: "not-allowed !important"
        },
        "button": {
          "&:disabled": {
            pointerEvents: "auto !important"
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "boxShadow": "none",
          ":hover": {
            boxShadow: "none"
          }
        }
      },
      variants: [
        {
          // common style
          props: { variant: "light" },
          style: {
            "borderRadius": "0.25rem",
            "color": colorPalette.blue.base,
            "minWidth": "4rem",
            "backgroundColor": colorPalette.blue["04"],
            "fontWeight": 500,
            "lineHeight": 1.75,
            ":hover": {
              backgroundColor: colorPalette.blue[20]
            }
          }
        },
        // size style
        {
          props: { size: "small" },
          style: {
            fontSize: "0.8125rem",
            padding: "0.25rem 0.625rem"
          }
        },
        {
          props: { size: "medium" },
          style: {
            fontSize: "0.875rem",
            padding: "0.375rem 1rem"
          }
        },
        {
          props: { size: "large" },
          style: {
            fontSize: "0.9375rem",
            padding: "0.5rem 1.375rem"
          }
        },
        {
          props: { variant: "text" },
          style: {
            "padding": 0,
            ":hover": {
              backgroundColor: colorPalette.white.base
            }
          }
        }
      ]
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          "borderRadius": "0.25rem",
          "color": colorPalette.white.base,
          "backgroundColor": colorPalette.blue.base,
          ":hover": {
            backgroundColor: colorPalette.blue.hover.base
          }
        },
        colorSecondary: {
          "borderRadius": "0.25rem",
          "color": colorPalette.blue.base,
          "backgroundColor": colorPalette.blue[30],
          ":hover": {
            backgroundColor: colorPalette.blue[50]
          }
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "height": "2.25rem",
          "width": "2.25rem",
          ":not(&.Mui-selected)": {
            ":hover": {
              backgroundColor: colorPalette.grey[20]
            }
          },
          "&.Mui-selected": {
            "color": colorPalette.white.base,
            "backgroundColor": colorPalette.black.base,
            ":hover": {
              backgroundColor: colorPalette.black.base
            }
          }
        },
        ellipsis: {
          display: "flex",
          justifyContent: "center",
          alignItem: "center",
          paddingTop: ".3rem"
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colorPalette.white.base,
          borderRadius: "0.25rem"
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colorPalette.black.base
        },
        asterisk: {
          color: colorPalette.red.base
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        marginDense: {
          marginTop: 12,
          marginBottom: 16
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          "color": colorPalette.black.base,
          "&.Mui-disabled": {
            color: colorPalette.black.hover[50]
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: colorPalette.black.hover[50]
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover": {
            "& > fieldset": {
              borderColor: colorPalette.black.hover.base
            }
          },
          "&.Mui-focused": {
            "& > fieldset.MuiOutlinedInput-notchedOutline": {
              borderColor: colorPalette.blue.base,
              borderWidth: "1px",
              boxShadow: `0 0 0 2px ${colorPalette.blue[20]}`
            }
          },
          "&.Mui-disabled": {
            "& > fieldset.MuiOutlinedInput-notchedOutline": {
              borderColor: colorPalette.black.hover[50]
            }
          }
        },
        input: {
          "&.Mui-disabled": {
            opacity: 1,
            WebkitTextFillColor: colorPalette.black.hover[50]
          },

          // hide up-down arrow for input type number
          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            display: "none"
          }
        },
        notchedOutline: {
          borderColor: colorPalette.grey.base
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 1500 //To show above Drawer
        },
        paper: {
          overflow: "auto !important"
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem",
          fontWeight: 500,
          lineHeight: "2.25rem"
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: "0px !important"
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: colorPalette.black.base,
          fontSize: "1rem",
          fontWeight: 400,
          lineHeight: "1.5rem"
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "backgroundColor": colorPalette.white.base,
          "color": colorPalette.blue.base,
          "borderWidth": "1px",
          "borderStyle": "solid",
          "borderColor": colorPalette.blue[20],
          "&.Mui-disabled": {
            "borderColor": colorPalette.black.hover[30],
            "color": colorPalette.black.hover.base,
            " .MuiChip-deleteIcon": {
              color: colorPalette.black.hover.base
            }
          }
        },
        deleteIcon: {
          "color": colorPalette.blue.base,
          "&:hover": {
            color: colorPalette.blue.hover.base
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "width": "100%",
          "&.Mui-readOnly": {
            " fieldset": {
              borderColor: `${colorPalette.grey.base} !important`,
              boxShadow: "none !important"
            }
          }
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: colorPalette.black.hover.base
          }
        },
        thumb: {
          height: "1.25rem",
          width: "1.25rem"
        },
        rail: {
          color: colorPalette.grey.base,
          height: "0.25rem"
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          boxShadow: `0px 3px 5px -1px ${colorPalette.grey[20]}, 0px 6px 10px ${colorPalette.grey[14]}, 0px 1px 18px ${colorPalette.grey[12]}`
        },
        filledSuccess: {
          backgroundColor: colorPalette.green.background.base,
          color: colorPalette.green.base
        },
        filledError: {
          backgroundColor: colorPalette.red.background.base,
          color: colorPalette.red.base
        },
        filledWarning: {
          backgroundColor: colorPalette.yellow.background.base,
          color: colorPalette.yellow.base
        },
        filledInfo: {
          backgroundColor: colorPalette.white.base,
          color: colorPalette.black.hover.base
        },
        action: {
          color: colorPalette.black.hover.base
        }
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          boxShadow: `0px 3px 5px -1px ${colorPalette.grey[20]}, 0px 6px 10px ${colorPalette.grey[14]}, 0px 1px 18px ${colorPalette.grey[12]}`,
          backgroundColor: colorPalette.white.base,
          color: colorPalette.black.base
        },
        action: {
          color: colorPalette.black.hover.base
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "boxShadow": "none",
          "&:before": {
            backgroundColor: "unset"
          },
          "&.Mui-expanded": {
            margin: "24px 0"
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "margin": "24px 0",
          "& .MuiAccordionSummary-expandIconWrapper": {
            color: colorPalette.orange.base,

            alignSelf: "flex-start"
          },
          "& .MuiAccordionSummary-content": {
            "margin": "unset",
            "&.Mui-expanded": {
              margin: "unset"
            }
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingTop: 0
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .Mui-focused": {
            "& .MuiAutocomplete-popupIndicator": {
              color: colorPalette.black.base
            }
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        iconOpen: { color: colorPalette.black.base },
        select: {
          paddingLeft: "calc(0.5rem + 1px)"
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: colorPalette.black.base
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: "4px",
          backgroundColor: colorPalette.black.hover.base
        },
        tooltipPlacementRight: {
          marginLeft: "0px !important"
        },
        tooltipPlacementLeft: {
          marginRight: "0px !important"
        },
        tooltipPlacementTop: {
          marginBottom: "0px !important"
        },
        tooltipPlacementBottom: {
          marginTop: "0.5rem !important"
        },
        arrow: {
          "&::before": {
            backgroundColor: colorPalette.black.hover.base
          }
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "2161px !important"
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          "& .MuiAvatar-img": {
            objectFit: "contain"
          }
        },
        circular: {
          "& .MuiAvatar-img": {
            objectFit: "cover"
          }
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          width: "max-content",
          maxWidth: "max-content",
          minWidth: "100%"
        }
      }
    },
    MuiStepButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "& .MuiStepIcon-root": {
              color: colorPalette.black.hover[50]
            }
          }
        }
      }
    }
  }
});

// Responsiveness for Typography.
theme.typography.h1 = {
  [theme.breakpoints.down("sm")]: {
    fontWeight: 600,
    fontSize: "2.25rem",
    letterSpacing: "0px"
  },
  [theme.breakpoints.up("sm")]: {
    fontWeight: 500,
    fontSize: "6rem",
    letterSpacing: "-1.5px"
  }
};
theme.typography.h2 = {
  [theme.breakpoints.down("sm")]: {
    fontWeight: 600,
    fontSize: "1.5rem",
    letterSpacing: "0px"
  },
  [theme.breakpoints.up("sm")]: {
    fontWeight: 600,
    fontSize: "3rem",
    letterSpacing: "0px"
  }
};
theme.typography.subtitle1 = {
  [theme.breakpoints.down("sm")]: {
    fontWeight: 500,
    fontSize: "1.5rem",
    letterSpacing: "0px"
  },
  [theme.breakpoints.up("sm")]: {
    fontWeight: 400,
    fontSize: "1.5rem",
    letterSpacing: "0px"
  }
};

export default theme;
