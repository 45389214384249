import {
  Pagination as MuiPagination,
  PaginationProps,
  styled
} from "@mui/material";

const StyledPagination = styled(MuiPagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    [theme.breakpoints.down("sm")]: {
      height: "1.8rem",
      width: "1.8rem"
    }
  }
}));

const Pagination = ({
  shape = "rounded",
  count = 1,
  ...rest
}: PaginationProps) => {
  return (
    <StyledPagination shape={shape} count={count} {...rest} size="small" /> // Note: Here size prop should be small, otherwise it will not be responsive on mobile devices
  );
};

export default Pagination;
