import { ReactNode } from "react";

import { Box as MuiBox, styled } from "@mui/material";

import { colorPalette } from "@utils/theme";

interface WellProps {
  children?: ReactNode;
  showBorder?: boolean;
}

const Well = ({ children, showBorder = true }: WellProps) => {
  const StyledBox = styled(MuiBox)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    background: theme.palette.common.white,
    borderRadius: "0.25rem",
    border: `${showBorder ? `1px solid ${colorPalette.grey.base}` : 0}`,
    padding: `${showBorder ? "1rem" : 0}`,
    height: "max-content"
  }));

  return <StyledBox>{children}</StyledBox>;
};

export default Well;
