import { Stack } from "@mui/material";

import SkeletonTypography from "@skeletons/SkeletonTypography";

const SkeletonNotificationBlock = () => {
  return (
    <Stack rowGap={0.5} p={2}>
      <SkeletonTypography variant="caption" width="full" />
      <SkeletonTypography variant="subtitle5" width="full" />
      <SkeletonTypography variant="caption" width="full" />
    </Stack>
  );
};

export default SkeletonNotificationBlock;
