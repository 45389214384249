import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  CancelRounded as CancelRoundedIcon,
  ErrorRounded as ErrorRoundedIcon
} from "@mui/icons-material";
import { Box, DialogActions, DialogContent, Stack } from "@mui/material";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import * as yup from "yup";

import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import Dialog from "@components/Dialog";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import {
  CANDIDATE_ACCOUNT_DEACTIVATION_REASONS,
  CANDIDATE_ACCOUNT_DEACTIVATION_REASONS_T_LABELS,
  JOB_APPLICATION_INVITATION_ACTION_TYPE
} from "@utils/config";
import { auth, functions } from "@utils/firebase";
import translate, { intl, intlEn, intlJa } from "@utils/translate";

interface DeactivateAccountData {
  deactivationReasons: Array<
    keyof typeof CANDIDATE_ACCOUNT_DEACTIVATION_REASONS_T_LABELS
  >;
}

const CandidateAccountManagement = () => {
  const userProfile = useUserProfile();
  const [user] = useAuthState(auth);
  const [signOut] = useSignOut(auth);
  const navigate = useNavigate();
  const toast = useToast();

  const [deactivateCandidateAccount, isDeactivating] = useHttpsCallable(
    functions,
    "deactivateCandidateAccount"
  );

  const hasExistingApplications = userProfile?.value?.jobs?.applied?.some(
    (singleJob) =>
      singleJob.status === JOB_APPLICATION_INVITATION_ACTION_TYPE.APPLIED
  );

  const CANDIDATE_ACCOUNT_DEACTIVATION_REASONS_OPTIONS = useOptions(
    CANDIDATE_ACCOUNT_DEACTIVATION_REASONS,
    CANDIDATE_ACCOUNT_DEACTIVATION_REASONS_T_LABELS
  ).map((singleOption) => {
    return { value: singleOption.key, label: singleOption.label };
  });

  const schema = yup.object({
    deactivationReasons: yup
      .array()
      .min(
        1,
        intl.get("t_error_required", { field: intl.get("t_general_reasons") })
      )
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      deactivationReasons: []
    },
    reValidateMode: "onChange"
  });

  const { handleSubmit, control, setValue, getValues } = methods;

  const handleAccountDelete = async (
    formData: DeactivateAccountData,
    handleAgree: () => void
  ) => {
    handleAgree(); // To close modal

    const { deactivationReasons } = formData;
    const allDeactivationReasons = [...deactivationReasons]?.map(
      (singleDeactivationReason) => {
        return {
          en: intlEn.get(
            CANDIDATE_ACCOUNT_DEACTIVATION_REASONS_T_LABELS[
              singleDeactivationReason as keyof typeof CANDIDATE_ACCOUNT_DEACTIVATION_REASONS_T_LABELS
            ]
          ),
          ja: intlJa.get(
            CANDIDATE_ACCOUNT_DEACTIVATION_REASONS_T_LABELS[
              singleDeactivationReason as keyof typeof CANDIDATE_ACCOUNT_DEACTIVATION_REASONS_T_LABELS
            ]
          )
        };
      }
    );

    try {
      const result = await deactivateCandidateAccount({
        userId: user?.uid,
        deactivationReasons: allDeactivationReasons
      });

      const data = result?.data as {
        success: boolean;
        message?: string;
      };

      if (data.success) {
        toast.kampai(intl.get("t_toast_success_delete_account"), "success");
        signOut();
      } else {
        toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
      }
    } catch (e) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  return (
    <Dialog
      title={intl.get("t_account_deletion_modal_title")}
      maxWidth="sm"
      isStopEventPropagation
      initiator={
        <Button variant="text" color="error" loading={isDeactivating}>
          {intl.get("t_account_setting_delete_account_button")}
        </Button>
      }>
      {(handleAgree, handleCancel) => (
        <>
          {hasExistingApplications ? (
            <>
              <DialogContent>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  gap={2}
                  width="100%"
                  textAlign="center">
                  <ErrorRoundedIcon color="primary" fontSize="large" />
                  <Typography variant="h5">
                    {intl.get("t_account_deletion_modal_cannot_delete_title")}
                  </Typography>
                  <Typography color="text.secondary">
                    {intl.get(
                      "t_account_deletion_modal_cannot_delete_description"
                    )}
                  </Typography>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%">
                  <Button handleClick={handleCancel} variant="outlined">
                    {intl.get("t_general_close")}
                  </Button>
                  <Button
                    handleClick={() =>
                      navigate(
                        `/${translate.getCurrentLocale()}/profile/jobs/applied`
                      )
                    }>
                    {intl.get(
                      "t_account_deletion_modal_view_application_button"
                    )}
                  </Button>
                </Stack>
              </DialogActions>
            </>
          ) : (
            <Box
              noValidate
              component="form"
              onSubmit={handleSubmit(() =>
                handleAccountDelete(getValues(), handleAgree)
              )}>
              <DialogContent>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  gap={2}
                  width="100%"
                  textAlign="center">
                  <CancelRoundedIcon color="error" fontSize="large" />
                  <Typography variant="h5">
                    {intl.get("t_account_deletion_modal_confirmation_title")}
                  </Typography>
                  <Typography color="text.secondary">
                    {intl.get(
                      "t_account_deletion_modal_confirmation_description"
                    )}
                  </Typography>
                  <Box alignSelf="flex-start">
                    <Checkbox
                      size="medium"
                      name="deactivationReasons"
                      control={control}
                      setValue={setValue}
                      options={CANDIDATE_ACCOUNT_DEACTIVATION_REASONS_OPTIONS}
                    />
                  </Box>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%">
                  <Button handleClick={handleCancel} variant="outlined">
                    {intl.get("t_general_cancel")}
                  </Button>
                  <Button handleClick={handleAgree} type="submit" color="error">
                    {intl.get("t_general_confirm")}
                  </Button>
                </Stack>
              </DialogActions>
            </Box>
          )}
        </>
      )}
    </Dialog>
  );
};

export default CandidateAccountManagement;
