import { Box, Grid, Stack } from "@mui/material";

import SkeletonAvatar from "@skeletons/SkeletonAvatar";
import SkeletonBasicInformationCard from "@skeletons/SkeletonBasicInformationCard";
import SkeletonCertificationCard from "@skeletons/SkeletonCertificationCard";
import SkeletonEducationCard from "@skeletons/SkeletonEducationCard";
import SkeletonJobDescriptionCard from "@skeletons/SkeletonJobDescriptionCard";
import SkeletonSkillsCard from "@skeletons/SkeletonSkillsCard";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Typography from "@components/Typography";

import { intl } from "@utils/translate";

const SkeletonCVDrawerTab = () => {
  return (
    <Stack>
      <Box display="flex" alignItems="center" gap={1} width="100%" mb={1}>
        <SkeletonAvatar size="large" />
        <Stack width="80%">
          <SkeletonTypography variant="subtitle4" width="medium" />
          <SkeletonTypography variant="subtitle2" width="short" />
          <SkeletonTypography variant="body1" width="short" />
        </Stack>
      </Box>

      <Stack gap={2} mb={2}>
        <SkeletonBasicInformationCard numOfFields={6} />

        <Typography variant="h4">
          {intl.get("t_resumes_job_overview_title")}
        </Typography>
        <Grid container gap={1}>
          <Grid item xs={12} md={3}>
            <Stack direction="column" width="100%">
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="column" width="100%">
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="column" width="100%">
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="column" width="100%">
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="h4">
          {intl.get("t_resumes_job_detail_title")}
        </Typography>
        <SkeletonJobDescriptionCard mode="view" />

        <Typography variant="h4">
          {intl.get("t_resumes_skills_title")}
        </Typography>
        <SkeletonSkillsCard mode="view" />

        <Typography variant="h4">
          {intl.get("t_resumes_language_title")}
        </Typography>
        <Grid container gap={1}>
          <Grid item xs={12} md={3}>
            <Stack direction="column" width="100%">
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="column" width="100%">
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="column" width="100%">
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="h4">
          {intl.get("t_resumes_education_title")}
        </Typography>
        <SkeletonEducationCard mode="view" />

        <Typography variant="h4">
          {intl.get("t_resumes_certification_title")}
        </Typography>
        <SkeletonCertificationCard mode="view" />
      </Stack>
    </Stack>
  );
};

export default SkeletonCVDrawerTab;
