import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogActions, DialogContent, Stack } from "@mui/material";
import {
  arrayUnion,
  doc,
  getDoc,
  Timestamp,
  updateDoc
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import * as yup from "yup";

import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonCVDrawerTab from "@skeletons/SkeletonCVDrawerTab";

import Button from "@components/Button";
import SelectionProcess from "@components/CandidateResumeDrawer/SelectionProcess";
import ResumeTab from "@components/CandidateResumeDrawer/Tabs/ResumeTab";
import Checkbox from "@components/Checkbox";
import Dialog from "@components/Dialog";
import DownloadFilesAsZipButton from "@components/DownloadFilesAsZipButton";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import ApplicationID from "@interfaces/database/ApplicationID";
import Conversation from "@interfaces/database/Conversation";
import JobApplicationInvitation from "@interfaces/database/JobApplicationInvitation";
import JobApplicationInvitationAction from "@interfaces/database/JobApplicationInvitationAction";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import MultiLingual from "@interfaces/database/MultiLingual";
import UserID from "@interfaces/database/UserID";
import UserProfile from "@interfaces/database/UserProfile";

import {
  DIALOG_ACTION,
  EMPLOYER_DECLINED_CANDIDATE_REASONS,
  EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS,
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_EN_CV,
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_CV,
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_RIREKISHO,
  FILES_LOCATION_INDIVIDUAL_EN_CV,
  FILES_LOCATION_INDIVIDUAL_JA_CV,
  FILES_LOCATION_INDIVIDUAL_JA_RIREKISHO,
  FIRESTORE_COLLECTIONS,
  FREE_TEXT_FIELD_MAX_LENGTH,
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  LOCALE
} from "@utils/config";
import { handleJobDecline } from "@utils/declineJob";
import { auth, db } from "@utils/firebase";
import generateHiringProcessSteps from "@utils/generateHiringProcessSteps";
import { resolveMultiLingual } from "@utils/multiLingual";
import { intl, intlEn, intlJa } from "@utils/translate";

interface DeclinedReasonsFormData {
  declinedReasons: Array<
    keyof typeof EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS
  >;
  otherDeclinedReason: string;
}

interface FileInformation {
  name: string;
  path: string;
  extension: string;
}

const CandidateResumeDrawer = () => {
  const toast = useToast();
  const userProfile = useUserProfile();
  const [user] = useAuthState(auth);
  const userId = user?.uid ?? "";
  const { conversation_id: paramConversationId } = useParams();
  const [conversationParams] = useSearchParams();

  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [candidate, setCandidate] = useState<UserProfile | null>(null);
  const [application, setApplication] =
    useState<JobApplicationInvitation | null>(null);
  const [jobData, setJobData] = useState<JobProfile>();
  const [resumeFileInfo, setResumeFileInfo] = useState<Array<FileInformation>>(
    []
  );

  const conversationId = paramConversationId ?? "";
  const isNewConversation = !conversationId || conversationId === "new";
  const companyId = userProfile.value?.company_id ?? "";
  const applicationId = isNewConversation
    ? conversationParams.get("application_id") ?? ""
    : conversation?.application_id ?? "";
  const candidateId = isNewConversation
    ? conversationParams.get("candidate_id") ?? ""
    : conversation?.candidate_id ?? "";
  const jobId = isNewConversation
    ? conversationParams.get("job_id") ?? ""
    : conversation?.job_id ?? "";

  useEffect(() => {
    if (!isNewConversation) {
      fetchConversationDetails(conversationId);
    }
  }, [conversationId]);

  useEffect(() => {
    if (conversation || isNewConversation) {
      fetchCandidateDetails(candidateId);
      fetchApplicationDetails(jobId, applicationId);
      fetchJobData(jobId);
    }
  }, [conversation]);

  useEffect(() => {
    if (candidate) {
      updateResumeFileInformation();
    }
  }, [candidate]);

  const fetchConversationDetails = async (conversationId: string) => {
    try {
      const conversationRef = doc(
        db,
        FIRESTORE_COLLECTIONS.CONVERSATIONS,
        conversationId
      );
      const conversationSnap = await getDoc(conversationRef);

      if (!conversationSnap.exists()) {
        throw new Error("Conversation not found");
      }

      setConversation(conversationSnap.data() as Conversation);
    } catch (error) {
      handleError();
    }
  };

  const fetchCandidateDetails = async (candidateId: UserID) => {
    try {
      const userRef = doc(db, FIRESTORE_COLLECTIONS.USERS, candidateId);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        throw new Error("User not found");
      }

      setCandidate(userSnap.data() as UserProfile);
    } catch (error) {
      handleError();
    }
  };

  const fetchApplicationDetails = async (
    jobId: JobID,
    applicationId: ApplicationID
  ) => {
    try {
      const applicationRef = doc(
        db,
        FIRESTORE_COLLECTIONS.JOBS,
        jobId,
        FIRESTORE_COLLECTIONS.APPLICATIONS,
        applicationId
      );
      const applicationSnap = await getDoc(applicationRef);

      if (!applicationSnap.exists()) {
        throw new Error("Application not found");
      }

      setApplication(applicationSnap.data() as JobApplicationInvitation);
    } catch (error) {
      handleError();
    }
  };

  const fetchJobData = async (jobId: JobID) => {
    try {
      const jobRef = doc(db, FIRESTORE_COLLECTIONS.JOBS, jobId);
      const jobSnap = await getDoc(jobRef);

      if (jobSnap.exists()) {
        setJobData(jobSnap.data() as JobProfile);
      }
    } catch (error) {
      handleError();
    }
  };

  const applicationStatus =
    application?.actions[application.actions.length - 1].action_type;

  const getCandidateFullName = () => {
    let candidateFullName = "";
    if (candidate?.summary?.basic_information?.first_name) {
      candidateFullName += resolveMultiLingual(
        candidate?.summary?.basic_information?.first_name
      );
    }
    if (candidate?.summary?.basic_information?.last_name) {
      candidateFullName += ` ${resolveMultiLingual(
        candidate?.summary?.basic_information?.last_name
      )}`;
    }
    return candidateFullName.trim();
  };

  const updateResumeFileInformation = () => {
    const candidateFullName = getCandidateFullName();
    const fileInformation = [
      {
        name: `CV${candidateFullName ? " " + candidateFullName : ""}.pdf`,
        path: `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_EN_CV}/${candidateId}.pdf`,
        extension: "pdf"
      },
      {
        name: `職務経歴書${
          candidateFullName ? " " + candidateFullName : ""
        }.pdf`,
        path: `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_CV}/${candidateId}.pdf`,
        extension: "pdf"
      },
      {
        name: `履歴書${candidateFullName ? " " + candidateFullName : ""}.pdf`,
        path: `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_RIREKISHO}/${candidateId}.pdf`,
        extension: "pdf"
      }
    ];

    const enCVFileInfo = resolveMultiLingual(
      candidate?.cv?.file_information,
      LOCALE.EN,
      true
    );
    const jaCVFileInfo = resolveMultiLingual(
      candidate?.cv?.file_information,
      LOCALE.JA,
      true
    );
    const jaRirekishoFileInfo = candidate?.ja_rirekisho?.file_information;

    if (enCVFileInfo?.name && enCVFileInfo?.extension) {
      fileInformation.push({
        name: enCVFileInfo.name,
        path: `${FILES_LOCATION_INDIVIDUAL_EN_CV}/${candidateId}.${enCVFileInfo.extension}`,
        extension: enCVFileInfo.extension
      });
    }

    if (jaCVFileInfo?.name && jaCVFileInfo?.extension) {
      fileInformation.push({
        name: jaCVFileInfo.name,
        path: `${FILES_LOCATION_INDIVIDUAL_JA_CV}/${candidateId}.${jaCVFileInfo.extension}`,
        extension: jaCVFileInfo.extension
      });
    }

    if (jaRirekishoFileInfo?.name && jaRirekishoFileInfo?.extension) {
      fileInformation.push({
        name: jaRirekishoFileInfo.name,
        path: `${FILES_LOCATION_INDIVIDUAL_JA_RIREKISHO}/${candidateId}.${jaRirekishoFileInfo.extension}`,
        extension: jaRirekishoFileInfo.extension
      });
    }

    setResumeFileInfo(fileInformation);
  };

  const handleError = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  /* Decline Resume */

  const EMPLOYER_DECLINED_CANDIDATE_REASONS_OPTIONS = useOptions(
    EMPLOYER_DECLINED_CANDIDATE_REASONS,
    EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS
  ).map((singleOption) => {
    return { value: singleOption.key, label: singleOption.label };
  });

  // validation schema
  const schema = yup.object({
    declinedReasons: yup.array(),
    otherDeclinedReason: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_first_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      declinedReasons: [],
      otherDeclinedReason: ""
    }
  });

  const { handleSubmit, control, setValue } = methods;

  // handle decline
  const handleDeclineResume = async (
    declinedReasons?: Array<MultiLingual<string>>
  ) => {
    try {
      if (applicationId && candidateId && jobId) {
        const success = await handleJobDecline(
          applicationId,
          jobId,
          candidateId,
          declinedReasons
        );

        if (success) {
          handleDeclineResumeSuccess();
          // Temporary update
          application?.actions.push({
            action_type: JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED,
            initiator_user_id: user ? user?.uid : "",
            updated_at: Timestamp.now()
          });
        } else {
          handleDeclineResumeFail();
        }
      }
    } catch (error) {
      handleDeclineResumeFail();
    }
  };

  const handleDeclineResumeSuccess = () => {
    toast.kampai(intl.get("t_toast_decline_candidate_success"), "success");
  };

  const handleDeclineResumeFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleFormSubmit = (formData: DeclinedReasonsFormData) => {
    const allJobDeclinedReasons = [
      ...formData.declinedReasons
      // formData.otherDeclinedReason // FIXME: add this in phase-2
    ]?.map((singleJobDeclinedReason) => {
      return {
        en: intlEn.get(
          EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS[
            singleJobDeclinedReason as keyof typeof EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS
          ]
        ),
        ja: intlJa.get(
          EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS[
            singleJobDeclinedReason as keyof typeof EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS
          ]
        )
      };
    });
    handleDeclineResume?.(allJobDeclinedReasons);
  };

  const handleDeclineResumeModalClose = async (
    reason: keyof typeof DIALOG_ACTION
  ) => {
    if (reason === DIALOG_ACTION.AGREE) {
      handleDeclineResume();
    }
  };

  const handleSelectionProcess = async (currentActiveStepKey: string) => {
    if (applicationStatus === JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED) {
      return;
    }
    const newHiringFlowStepKey =
      currentActiveStepKey === JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
        ? ""
        : currentActiveStepKey;
    const newApplicationStatus =
      currentActiveStepKey === JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
        ? JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
        : JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRING_FLOW_STEP_CHANGED;
    const applicationAction: JobApplicationInvitationAction = {
      action_type: newApplicationStatus,
      initiator_user_id: userId,
      updated_at: Timestamp.now(),
      metadata: {
        hiring_flow_active_step_key: newHiringFlowStepKey
      }
    };
    try {
      const applicationRef = doc(
        db,
        FIRESTORE_COLLECTIONS.JOBS,
        jobId,
        FIRESTORE_COLLECTIONS.APPLICATIONS,
        applicationId
      );
      await updateDoc(applicationRef, {
        actions: arrayUnion(applicationAction),
        hiring_flow_active_step_key:
          currentActiveStepKey === JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
            ? ""
            : currentActiveStepKey
      });
    } catch (err) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  // decline button
  const DeclineButton = () => {
    if (!application) {
      return <SkeletonButton />;
    }

    const lastActionIndex = application?.actions.length - 1 ?? 0;
    const applicationStatus = application?.actions[lastActionIndex].action_type;

    if (applicationStatus === JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED) {
      return (
        <Button disabled variant="contained">
          {intl.get("t_employer_dashboard_application_already_declined")}
        </Button>
      );
    } else if (
      applicationStatus === JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED ||
      applicationStatus === JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED ||
      applicationStatus ===
        JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED_DECLINED ||
      applicationStatus === JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
    ) {
      return (
        <Button disabled variant="contained">
          {intl.get("t_employer_dashboard_application_cannot_decline")}
        </Button>
      );
    } else {
      return (
        <Dialog
          isStopEventPropagation
          title={intl.get("t_dialog_decline_candidate_confirmation_title")}
          maxWidth="sm"
          initiator={
            <Button variant="contained">
              {intl.get("t_general_employer_decline")}
            </Button>
          }
          onClose={handleDeclineResumeModalClose}>
          {(handleAgree, handleCancel) => (
            <>
              <Box
                noValidate
                component="form"
                onSubmit={handleSubmit(handleFormSubmit)}>
                <DialogContent sx={{ py: 1 }}>
                  <Typography color="text.secondary" variant="body1">
                    {intl.get("t_employer_declined_candidate_dialog_subtitle")}
                  </Typography>
                  <Checkbox
                    size="medium"
                    name="declinedReasons"
                    control={control}
                    setValue={setValue}
                    options={EMPLOYER_DECLINED_CANDIDATE_REASONS_OPTIONS}
                  />
                  {/* FIXME: this is phase2 - need to handle email and phone number check */}
                  {/* <TextField
                    control={control}
                    name="otherDeclinedReason"
                    placeholder={intl.get("t_general_other_decline_reason")}
                  /> */}
                </DialogContent>
                <DialogActions>
                  <Button handleClick={handleCancel} variant="outlined">
                    {intl.get("t_dialog_decline_candidate_cancel_button")}
                  </Button>
                  <Button type="submit">
                    {intl.get("t_dialog_decline_candidate_agree_button")}
                  </Button>
                </DialogActions>
              </Box>
            </>
          )}
        </Dialog>
      );
    }
  };

  return (
    <Box px={{ xs: 2.5, md: 3, lg: 4 }} mt={4}>
      {/* Decline, Download Options */}
      <Stack
        position="absolute"
        top={18}
        left={60}
        flexDirection="row"
        justifyContent={{
          xs: "flex-end"
        }}
        alignItems="center"
        pl={{ xs: 2.5, md: 3, lg: 4 }}
        width="calc(100% - 110px)">
        <Stack direction="row" columnGap={1}>
          <DownloadFilesAsZipButton
            filesInformation={resumeFileInfo}
            zipFileName={intl.get("t_resumes")}
            btnLabel={intl.get("t_resumes_download_button_label")}
          />
          <DeclineButton />
        </Stack>
      </Stack>

      {/* selection process start*/}
      {jobData?.hiring_flow &&
      applicationStatus !== JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED ? (
        <Box px={{ xs: 2.5, md: 3, lg: 4 }} my={4}>
          <SelectionProcess
            handleSelectionProcess={handleSelectionProcess}
            hiringFlowProcessSteps={generateHiringProcessSteps(
              jobData?.hiring_flow
            )}
            currentActiveStepKey={
              applicationStatus === JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
                ? ""
                : application?.hiring_flow_active_step_key ?? ""
            }
            isHired={
              applicationStatus === JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
            }
          />
        </Box>
      ) : (
        false
      )}
      {/* selection process end*/}

      {/* Candidate Details */}
      {candidate ? (
        <ResumeTab
          userId={candidateId}
          user={candidate}
          companyId={companyId}
        />
      ) : (
        <SkeletonCVDrawerTab />
      )}
    </Box>
  );
};

export default CandidateResumeDrawer;
