import { ReactIntlUniversal } from "react-intl-universal";

import { Stack, Typography } from "@mui/material";

import ActionButton from "@components/ActionButton";
import InformationField from "@components/InformationField";
import Well from "@components/Well";

import Email from "@interfaces/database/Email";
import Location from "@interfaces/database/Location";

import {
  INDIVIDUAL_USER_NUMBER_OF_COMPANIES,
  INDIVIDUAL_USER_NUMBER_OF_COMPANIES_T_LABELS,
  INDIVIDUAL_USER_VISA_TYPE,
  INDIVIDUAL_USER_VISA_TYPE_T_LABELS
} from "@utils/config";
import { getCountryName, getFullLocation } from "@utils/location";
import { roundSalary } from "@utils/roundSalary";
import translate, { intl as intlGlobal } from "@utils/translate";

import VisaIcon from "@assets/images/Visa.svg";

interface BasicInformationCardProps {
  fullName?: {
    firstName?: string;
    lastName?: string;
  };
  email?: Email;
  phoneNumber?: string;
  countryOfOrigin?: string;
  visaType?: typeof INDIVIDUAL_USER_VISA_TYPE[keyof typeof INDIVIDUAL_USER_VISA_TYPE];
  location?: Location;
  noOfCompanies?: typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES[keyof typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES];
  expectedSalary?: number;
  handleEdit?: () => void;
  mode?: "edit" | "view";
  intl?: typeof intlGlobal | ReactIntlUniversal;
}

const BasicInformationCard = ({
  fullName,
  email,
  phoneNumber,
  countryOfOrigin,
  visaType,
  location,
  noOfCompanies,
  expectedSalary,
  handleEdit,
  mode = "view",
  intl = intlGlobal
}: BasicInformationCardProps) => {
  const firstName = fullName?.firstName ?? "";
  const lastName = fullName?.lastName ?? "";
  const userFullName = `${lastName} ${firstName}`.trim();

  const userLocation = getFullLocation(
    location?.city,
    location?.country,
    true,
    translate.getLocaleShort(intl.getInitOptions().currentLocale)
  );

  const userCountryOfOrigin = countryOfOrigin
    ? getCountryName(
        countryOfOrigin,
        translate.getLocaleShort(intl.getInitOptions().currentLocale)
      )
    : "";
  const userVisaType = visaType
    ? intl.get(INDIVIDUAL_USER_VISA_TYPE_T_LABELS[visaType])
    : "";

  const userNoOfCompanies = noOfCompanies
    ? intl.get(INDIVIDUAL_USER_NUMBER_OF_COMPANIES_T_LABELS[noOfCompanies])
    : "";

  return (
    <Well showBorder={mode === "edit" ? true : false}>
      {mode === "edit" ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          width="100%">
          <InformationField
            variant="subtitle4"
            icon="person"
            label={userFullName}
            data-testid="basic_information_full_name_field"
          />
          <ActionButton
            data-testid="basic_information_edit_button"
            icon="edit"
            color="blue"
            handleAction={handleEdit}
          />
        </Stack>
      ) : (
        false
      )}

      <Stack spacing={1} width="100%">
        <InformationField
          data-testid="basic_information_email_field"
          icon="mail"
          label={email}
        />
        <InformationField
          data-testid="basic_information_phone_number_field"
          icon="phone_enabled"
          label={phoneNumber}
        />
        <InformationField
          data-testid="basic_information_country_origin_field"
          icon="home"
          label={userCountryOfOrigin}
        />
        <InformationField
          data-testid="basic_information_visa_type_field"
          imgSrc={VisaIcon}
          imgAlt={intlGlobal.get("t_alt_visa_types")}
          label={userVisaType}
        />
        {mode === "edit" ? (
          <Typography textTransform="capitalize">
            <InformationField
              data-testid="basic_information_user_location_field"
              icon="pin_drop"
              label={userLocation}
            />
          </Typography>
        ) : (
          false
        )}
        <InformationField
          icon="apartment"
          label={
            userNoOfCompanies
              ? intl.get("t_general_number_of_company_count", {
                  noOfCompanies
                })
              : "-"
          }
        />
        <InformationField
          icon="currency_yen"
          label={
            expectedSalary
              ? intl.get("t_resumes_basic_info_rounded_salary", {
                  expectedSalary: roundSalary(expectedSalary)
                })
              : "-"
          }
        />
      </Stack>
    </Well>
  );
};

export default BasicInformationCard;
