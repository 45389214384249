import { useState } from "react";

import { getDownloadURL, ref } from "firebase/storage";
import JSZip from "jszip";

import Button from "@components/Button";

import useToast from "@hooks/useToast";

import { storage } from "@utils/firebase";
import { intl } from "@utils/translate";

interface FileInformation {
  name: string;
  path: string;
  extension: string;
}

interface DownloadFilesAsZipButtonProps {
  filesInformation: Array<FileInformation>;
  zipFileName: string;
  btnLabel: string;
}

const DownloadFilesAsZipButton = ({
  filesInformation,
  zipFileName,
  btnLabel
}: DownloadFilesAsZipButtonProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  const downloadFilesAsZip = async () => {
    try {
      setIsLoading(true);
      const zip = new JSZip();
      const promises = filesInformation.map(
        async (singleFileInformation: FileInformation) => {
          const storageRef = ref(storage, singleFileInformation.path);
          const url = await getDownloadURL(storageRef);
          if (url) {
            const response = await fetch(url);
            const fileBlob = await response.blob();
            zip.file(
              `${singleFileInformation.name}.${singleFileInformation.extension}`,
              fileBlob
            );
          }
        }
      );

      await Promise.all(promises);
      const blob = await zip.generateAsync({ type: "blob" });
      const zipBlobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = zipBlobUrl;
      link.download = `${zipFileName}.zip`;
      link.click();
      URL.revokeObjectURL(zipBlobUrl);
      setIsLoading(false);
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  return (
    <Button
      handleClick={downloadFilesAsZip}
      variant="outlined"
      loading={isLoading}>
      {btnLabel}
    </Button>
  );
};

export default DownloadFilesAsZipButton;
