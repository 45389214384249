import { Link } from "@mui/material";

import Button from "@components/Button";
import Icon from "@components/Icon";
import Typography from "@components/Typography";
import Well from "@components/Well";

import { intl } from "@utils/translate";

interface ResumeDownloadCardProps {
  downloadCvUrl?: string;
  downloadBtnText?: string;
  title?: string;
}
const ResumeDownloadCard = ({
  downloadCvUrl = "",
  downloadBtnText = intl.get("t_resumes_download"),
  title = intl.get("t_resumes_download_cv_card_title")
}: ResumeDownloadCardProps) => {
  return (
    <Well>
      <Typography variant="h4" color="text.primary" mb={2.5}>
        {title}
      </Typography>
      {downloadCvUrl ? (
        <Link underline="none" href={downloadCvUrl}>
          <Button variant="outlined" startAdornment={<Icon type="download" />}>
            {downloadBtnText}
          </Button>
        </Link>
      ) : (
        false
      )}
    </Well>
  );
};

export default ResumeDownloadCard;
