import { Grid, Stack } from "@mui/material";

import SkeletonAvatar from "@skeletons/SkeletonAvatar";
import SkeletonBasicInformationCard from "@skeletons/SkeletonBasicInformationCard";
import SkeletonSkillsCard from "@skeletons/SkeletonSkillsCard";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Typography from "@components/Typography";

import { intl } from "@utils/translate";

const SkeletonCandidateProfile = () => {
  return (
    <Stack>
      <Stack direction="row" alignItems="center" gap={1} mb={1}>
        <SkeletonAvatar size="large" />
        <Stack width="80%">
          <SkeletonTypography variant="subtitle4" width="medium" />
          <SkeletonTypography variant="subtitle2" width="short" />
          <SkeletonTypography variant="body1" width="short" />
        </Stack>
      </Stack>

      <Stack gap={2} mb={2}>
        <SkeletonBasicInformationCard numOfFields={6} />

        <Typography variant="h4">
          {intl.get("t_profile_summary_experience")}
        </Typography>
        <Grid container gap={1}>
          <Grid item xs={12} md={3}>
            <Stack>
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack>
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack>
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="h4">
          {intl.get("t_resumes_language_title")}
        </Typography>
        <Grid container gap={1}>
          <Grid item xs={12} md={3}>
            <Stack>
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack>
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack>
              <SkeletonTypography variant="subtitle4" width="medium" />
              <SkeletonTypography variant="body1" width="short" />
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="h4">
          {intl.get("t_resumes_skills_title")}
        </Typography>
        <SkeletonSkillsCard mode="view" />
      </Stack>
    </Stack>
  );
};

export default SkeletonCandidateProfile;
