import { SyntheticEvent } from "react";

import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon
} from "@mui/icons-material";
import {
  Alert,
  Snackbar,
  SnackbarCloseReason,
  SnackbarOrigin,
  styled
} from "@mui/material";

import { TOAST_DEFAULT_HIDE_DURATION_MS } from "@utils/config";

interface ToastProps {
  message: string;
  variant?: "success" | "error" | "info" | "warning" | "none";
  open: boolean;
  setOpen?: (state: boolean) => void;
  isPersistent?: boolean;
  autoHideDuration?: number | null;
  position?: SnackbarOrigin;
  handleClose?: () => void;
}

const StyledSnackbar = styled(Snackbar)(({ theme, anchorOrigin }) => ({
  [theme.breakpoints.down("sm")]: {
    left: 0,
    right: 0,
    ...(anchorOrigin?.vertical === "top" ? { top: 0 } : { bottom: 0 }) // changing position for mobile
  },
  zIndex: 2000
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%"
  }
}));

const Toast = ({
  message,
  open,
  handleClose,
  variant = "none",
  isPersistent = false,
  setOpen,
  position = { vertical: "top", horizontal: "right" },
  autoHideDuration = TOAST_DEFAULT_HIDE_DURATION_MS
}: ToastProps) => {
  const closeToast = (
    e: SyntheticEvent | Event,
    reason: SnackbarCloseReason
  ) => {
    if (isPersistent || reason == "clickaway") {
      return;
    }
    if (handleClose) {
      handleClose();
    }
    setOpen && setOpen(false);
  };

  const commonProps = {
    open,
    position,
    autoHideDuration:
      isPersistent || (open && !setOpen) ? null : autoHideDuration,
    onClose: closeToast
  };

  const closeBtnHandler = () => setOpen && setOpen(false);

  return (
    <>
      {variant !== "none" ? (
        <StyledSnackbar anchorOrigin={commonProps.position} {...commonProps}>
          <StyledAlert
            onClose={closeBtnHandler}
            severity={variant}
            variant="filled"
            iconMapping={{
              success: <CheckCircleIcon />,
              error: <ErrorIcon />,
              info: <InfoIcon color="info" />,
              warning: <InfoIcon />
            }}>
            {message}
          </StyledAlert>
        </StyledSnackbar>
      ) : (
        <StyledSnackbar
          anchorOrigin={commonProps.position}
          {...commonProps}
          message={message}
          action={<CloseIcon onClick={closeBtnHandler} fontSize="small" />}
        />
      )}
    </>
  );
};

export default Toast;
