import styled from "@emotion/styled";
import { LinearProgress as MuiLoader } from "@mui/material";

const Loader = styled(MuiLoader)({
  width: "100vw",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 100
});

export default Loader;
