import { Skeleton } from "@mui/material";

interface SkeletonButtonProps {
  width?: "medium" | "full";
}

const SkeletonButton = ({ width = "medium" }: SkeletonButtonProps) => {
  return (
    <Skeleton
      animation="wave"
      variant="rounded"
      height="2rem"
      width={width === "medium" ? "10ch" : "100%"}
    />
  );
};

export default SkeletonButton;
