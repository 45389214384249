import { useEffect, useState } from "react";

import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  CurrencyYen as CurrencyYenIcon,
  NavigateBefore as NavigateBeforeIcon
} from "@mui/icons-material";
import { Box, Grid, Stack } from "@mui/material";
import { FirebaseError } from "firebase/app";
import { httpsCallable } from "firebase/functions";
import parsePhoneNumberFromString from "libphonenumber-js";
import { useAuthState } from "react-firebase-hooks/auth";
import * as yup from "yup";

import SkeletonBasicInformationForm from "@skeletons/SkeletonBasicInformationForm";

import AutoCompleteTextField from "@components/AutoCompleteTextField";
import Button from "@components/Button";
import NonTypeableSelect from "@components/NonTypeableSelect";
import NumberInput from "@components/NumberInput";
import Paper from "@components/Paper";
import TelephoneInput from "@components/TelephoneInput";
import TextField from "@components/TextField";
import TypeableSelect from "@components/TypeableSelect";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import KeyLabel from "@interfaces/components/KeyLabel";

import {
  FREE_TEXT_FIELD_MAX_LENGTH,
  INDIVIDUAL_USER_NUMBER_OF_COMPANIES,
  INDIVIDUAL_USER_NUMBER_OF_COMPANIES_T_LABELS,
  INDIVIDUAL_USER_VISA_TYPE,
  INDIVIDUAL_USER_VISA_TYPE_T_LABELS,
  JP_ALPHA3_CODE,
  MAX_EXPECTED_SALARY
} from "@utils/config";
import { auth, functions } from "@utils/firebase";
import { getCityList } from "@utils/keyLabelHandlers/location";
import { getCountryName, getDropdownCountryList } from "@utils/location";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";
import { colorPalette } from "@utils/theme";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface BasicInformationFormData {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  countryOfOrigin?: KeyLabel;
  visaType?: KeyLabel;
  country?: KeyLabel;
  city?: KeyLabel;
  numberOfCompanies?:
    | typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES[keyof typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES]
    | string;
  expectedSalary?: number;
}

const BasicInformationForm = () => {
  const [user] = useAuthState(auth);
  const userData = useUserProfile();
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  // validation schema
  const schema = yup.object({
    firstName: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_first_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_first_name")
        })
      ),
    lastName: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_last_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_last_name")
        })
      ),
    phoneNumber: yup
      .string()
      .test(
        "phone",
        intl.get("t_error_invalid", {
          field: intl.get("t_general_phone_number")
        }),
        (value) => {
          // Empty error will be handled separately.
          if (!value) {
            return true;
          }
          const phoneNumber = parsePhoneNumberFromString(value);
          if (!phoneNumber) {
            return false;
          }
          return phoneNumber.isValid();
        }
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_phone_number")
        })
      ),
    countryOfOrigin: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .test(
        "countryOfOrigin",
        intl.get("t_error_required", {
          field: intl.get("t_profile_basic_info_nationality")
        }),
        (value) => (value?.key && value?.label ? true : false)
      )
      .nullable(),
    visaType: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .nullable(),
    country: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .test(
        "country",
        intl.get("t_error_required", {
          field: intl.get("t_general_country")
        }),
        (value) => (value?.key && value?.label ? true : false)
      )
      .nullable(),
    city: yup
      .object()
      .shape({
        key: yup.string().trim(),
        label: yup.string().trim()
      })
      .test(
        "label",
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_city"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        }),
        (value) => {
          return value && value.label
            ? value.label.length <= FREE_TEXT_FIELD_MAX_LENGTH
            : true;
        }
      )
      .nullable(),
    numberOfCompanies: yup.string().required(
      intl.get("t_error_required", {
        field: intl.get("t_profile_basic_info_number_of_companies")
      })
    ),
    expectedSalary: yup
      .number()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_profile_basic_info_expected_salary")
        })
      )
      .min(0)
      .max(
        MAX_EXPECTED_SALARY,
        intl.get("t_error_expected_salary_limit", {
          salary: MAX_EXPECTED_SALARY
        })
      )
      .nullable()
      .transform((expectedSalary) => {
        return isNaN(expectedSalary) ||
          expectedSalary === null ||
          expectedSalary === undefined
          ? null
          : expectedSalary;
      })
  });

  const userBasicInformation = userData.value?.summary?.basic_information;
  const formInitValues = {
    firstName: resolveMultiLingual(userBasicInformation?.first_name),
    lastName: resolveMultiLingual(userBasicInformation?.last_name),
    phoneNumber: userBasicInformation?.phone_number ?? "",
    countryOfOrigin: {
      key: userBasicInformation?.immigration_details?.country ?? "",
      label:
        getCountryName(
          userBasicInformation?.immigration_details?.country ?? ""
        ) ?? ""
    },
    visaType: {
      key: userBasicInformation?.immigration_details?.visa_type ?? "",
      label: userBasicInformation?.immigration_details?.visa_type
        ? intl.get(
            INDIVIDUAL_USER_VISA_TYPE_T_LABELS[
              userBasicInformation?.immigration_details
                ?.visa_type as keyof typeof INDIVIDUAL_USER_VISA_TYPE_T_LABELS
            ]
          )
        : ""
    },
    country: {
      key: userBasicInformation?.current_location?.country ?? "",
      label:
        getCountryName(userBasicInformation?.current_location?.country ?? "") ??
        ""
    },
    city: {
      key: userBasicInformation?.current_location?.city ?? "",
      label: userBasicInformation?.current_location?.city ?? ""
    },
    numberOfCompanies: userBasicInformation?.number_of_companies ?? "",
    expectedSalary: Number(userBasicInformation?.expected_salary) ?? ""
  };

  const methods = useForm({
    defaultValues: formInitValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, setValue, reset, formState } = methods;

  const selectedCountryIsoCode = useWatch({
    control,
    name: "country.key"
  });
  const selectedCountryOfOriginIsoCode = useWatch({
    control,
    name: "countryOfOrigin.key"
  });

  useEffect(() => {
    // check selected country is modified or not
    if (formState.dirtyFields.country) {
      setValue("city", { key: "", label: "" });
    }

    // clear visa type value if country of origin is Japan
    if (selectedCountryOfOriginIsoCode === JP_ALPHA3_CODE) {
      setValue("visaType", { key: "", label: "" });
    }
  }, [selectedCountryIsoCode, selectedCountryOfOriginIsoCode, setValue]);

  useEffect(() => {
    reset(formInitValues);
    setLoading(false);
  }, [userBasicInformation]);

  const VISA_TYPE_OPTIONS = useOptions(
    INDIVIDUAL_USER_VISA_TYPE,
    INDIVIDUAL_USER_VISA_TYPE_T_LABELS
  );

  const NUMBER_OF_COMPANIES_OPTIONS = useOptions(
    INDIVIDUAL_USER_NUMBER_OF_COMPANIES,
    INDIVIDUAL_USER_NUMBER_OF_COMPANIES_T_LABELS
  );

  const COUNTRY_OPTIONS = getDropdownCountryList();

  const handleProfileBasicInfoSavedSuccess = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_success_profile_summary_saved"), "success");
  };

  const handleProfileBasicInfoSavedFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const onSubmit = async (formData: BasicInformationFormData) => {
    setIsDisabled(true);
    const {
      city,
      country,
      countryOfOrigin,
      expectedSalary,
      firstName,
      lastName,
      numberOfCompanies,
      phoneNumber,
      visaType
    } = formData || {};
    try {
      if (
        userData.setValue &&
        userData.value &&
        userData.value?.summary?.basic_information
      ) {
        try {
          if (phoneNumber !== userBasicInformation?.phone_number) {
            const setUserPhoneNumber = httpsCallable(
              functions,
              "setUserPhoneNumber"
            );
            await setUserPhoneNumber(phoneNumber);
          }
        } catch (e) {
          if (e instanceof FirebaseError) {
            if (e.code === "functions/invalid-argument") {
              toast.kampai(
                intl.get("t_toast_error_phone_number_format_is_incorrect"),
                "error"
              );
            } else if (e.code === "functions/already-exists") {
              toast.kampai(
                intl.get("t_toast_error_phone_number_already_in_use"),
                "error"
              );
            } else {
              toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
            }
          }
          setIsDisabled(false);
          return;
        }
        await userData.setValue(
          {
            ...userData.value,
            summary: {
              ...userData.value.summary,
              basic_information: {
                ...userData.value?.summary?.basic_information,
                first_name: prepareMultiLingual(
                  firstName ?? "",
                  undefined,
                  userBasicInformation?.first_name
                ),
                last_name: prepareMultiLingual(
                  lastName ?? "",
                  undefined,
                  userBasicInformation?.last_name
                ),
                phone_number: phoneNumber ?? "",
                email: user?.email ?? "",
                current_location: {
                  country: country?.key ?? "",
                  city: city?.key ?? ""
                },
                immigration_details: {
                  country: countryOfOrigin?.key ?? "",
                  ...(countryOfOrigin?.key === JP_ALPHA3_CODE || !visaType?.key
                    ? {}
                    : {
                        visa_type:
                          (visaType?.key as typeof INDIVIDUAL_USER_VISA_TYPE[keyof typeof INDIVIDUAL_USER_VISA_TYPE]) ??
                          ""
                      })
                },
                number_of_companies:
                  (numberOfCompanies as typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES[keyof typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES]) ||
                  "",
                expected_salary: Number(expectedSalary)
              }
            },
            updated_at: Timestamp.now()
          },
          handleProfileBasicInfoSavedSuccess,
          handleProfileBasicInfoSavedFail,
          {
            forceReloadUser: true
          }
        );
        navigate(`/${translate.getCurrentLocale()}/profile/edit`);
      }
    } catch (e) {
      handleProfileBasicInfoSavedFail();
    }
  };

  if (loading || userData.loading) {
    return <SkeletonBasicInformationForm />;
  }

  return (
    <Box noValidate component="form" onSubmit={handleSubmit(onSubmit)}>
      {/* Basic information section start */}
      <Paper>
        <Typography variant="h3">
          {intl.get("t_profile_basic_info_heading")}
        </Typography>
        <br />
        <Typography mt={2.5} mb={3} color="text.secondary">
          {intl.get("t_profile_basic_info_sub_heading")}
        </Typography>
        <Grid container spacing={{ xs: 0, md: 3 }}>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={isDisabled}
              control={control}
              name="lastName"
              label={intl.get("t_general_last_name")}
              placeholder={intl.get("t_general_last_name")}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={isDisabled}
              control={control}
              name="firstName"
              label={intl.get("t_general_first_name")}
              placeholder={intl.get("t_general_first_name")}
              required
            />
          </Grid>
        </Grid>
        <TelephoneInput
          disabled={isDisabled}
          control={control}
          name="phoneNumber"
          label={intl.get("t_general_phone_number")}
          placeholder={intl.get("t_general_phone_number")}
          required
        />
        <TypeableSelect
          disabled={isDisabled}
          control={control}
          name="countryOfOrigin"
          label={intl.get("t_profile_basic_info_nationality")}
          placeholder={intl.get("t_profile_basic_info_nationality")}
          required
          options={COUNTRY_OPTIONS}
        />
        {selectedCountryOfOriginIsoCode !== JP_ALPHA3_CODE ? (
          <TypeableSelect
            disabled={isDisabled}
            control={control}
            name="visaType"
            label={intl.get("t_profile_basic_info_visa_type")}
            placeholder={intl.get("t_profile_basic_info_visa_type")}
            options={VISA_TYPE_OPTIONS}
          />
        ) : (
          false
        )}
        <Grid container spacing={{ xs: 0, md: 3 }}>
          <Grid item xs={12} md={6}>
            <TypeableSelect
              disabled={isDisabled}
              control={control}
              name="country"
              label={intl.get("t_general_country")}
              placeholder={intl.get(
                "t_profile_basic_info_location_country_placeholder"
              )}
              required
              options={COUNTRY_OPTIONS}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AutoCompleteTextField
              control={control}
              disabled={!selectedCountryIsoCode || isDisabled}
              name="city"
              label={intl.get("t_general_city")}
              placeholder={intl.get(
                "t_profile_basic_info_location_city_placeholder"
              )}
              setValue={setValue}
              getOptions={(subText) => {
                return getCityList(subText, selectedCountryIsoCode);
              }}
            />
          </Grid>
        </Grid>
        <NonTypeableSelect
          disabled={isDisabled}
          setValue={setValue}
          control={control}
          name="numberOfCompanies"
          label={intl.get("t_profile_basic_info_number_of_companies")}
          placeholder={intl.get(
            "t_profile_basic_info_number_of_companies_placeholder"
          )}
          required
          options={NUMBER_OF_COMPANIES_OPTIONS}
        />
        <NumberInput
          disabled={isDisabled}
          control={control}
          thousandSeparator
          startAdornment={
            <CurrencyYenIcon
              htmlColor={colorPalette.black.base}
              fontSize="small"
            />
          }
          name="expectedSalary"
          label={intl.get("t_profile_basic_info_expected_salary")}
          placeholder={intl.get("t_profile_basic_info_expected_salary")}
          required
        />
      </Paper>
      {/* Basic information section end */}

      <Stack justifyContent="space-between" direction="row" mt={5.5}>
        <Button
          data-testid="profile_basic_info_back_button"
          handleClick={() =>
            navigate(`/${translate.getCurrentLocale()}/profile/edit`)
          }
          variant="outlined"
          startAdornment={<NavigateBeforeIcon />}>
          {intl.get("t_general_back")}
        </Button>
        <Button
          data-testid="profile_basic_info_save_button"
          type="submit"
          loading={isDisabled}>
          {intl.get("t_general_save")}
        </Button>
      </Stack>
    </Box>
  );
};

export default BasicInformationForm;
