import { Step } from "react-joyride";

import { Badge } from "@mui/material";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";

import FTUIContainer from "@components/FTUIContainer";
import Typography from "@components/Typography";

import { envOptions } from "@utils/firebase";
import { intl } from "@utils/translate";

dayjs.extend(relative);

const BasicInformation = () => {
  const tourSteps: Array<Step> = [
    {
      target: "#basic-info-step",
      title: intl.get("t_ftui_debugger_basic_information_title"),
      content: intl.get("t_ftui_debugger_basic_information_description"),
      disableBeacon: true
    }
  ];

  return (
    <>
      <Typography variant="h5" id="basic-info-step">
        Basic information:
      </Typography>
      <br />
      <Typography>
        Deployed on: {dayjs(process.env.REACT_APP_RELEASED_TIMESTAMP).fromNow()}
      </Typography>
      <br />
      <Typography>Current URL: {window.location.hostname}</Typography>
      <br />
      <Typography>Current Path: {window.location.pathname}</Typography>
      <br />
      <Typography>Query Parameters: {location.search}</Typography>
      <br />
      <Typography>
        Firebase connection status:&nbsp;
        {envOptions.isEmulator ? (
          <>
            &nbsp; <Badge color="success" variant="dot" /> &nbsp; Connected to
            Firebase Emulator
          </>
        ) : (
          <>
            &nbsp; <Badge color="error" variant="dot" /> &nbsp; Not connected to
            the local emulator
          </>
        )}
      </Typography>
      <br />
      <Typography>
        Firebase connected environment information: {envOptions.appId}
      </Typography>
      <br />
      <Typography>Storage Host: {envOptions.storageHost}</Typography>
      <FTUIContainer tourSteps={tourSteps} />
    </>
  );
};

export default BasicInformation;
